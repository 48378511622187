// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type SavingType } from '@kwara/models/src';
import { fields } from '@kwara/models/src/models/Remittance';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { setRemittanceDetails } from '@kwara/lib/src/remittance';

import { Remittance as RemittanceForm } from '../../SavingAdd/components/Remittance';
import { EditableSection, Updater } from '../../../components/EditableSection/v1/EditableSection';
import { excludedRemittanceFields } from '../../../lib/modelUtils/saving';
import { Currency } from '../../../components/Currency';
import { store } from '../../../models/Store/Store';
import { usePermissions } from '../../../hooks';

type Props = {
  saving: SavingType,
  refetch: () => void,
  readOnly?: boolean
};

export const Content = ({ saving, className = '' }: { saving: SavingType, className?: string }) => {
  const { remittance = {} } = saving;
  const excludedField = excludedRemittanceFields(saving);

  const { method, amount, bankAccount } = remittance;

  return (
    <div className={className}>
      <StatisticRow widthClass="w-100" compact>
        <Statistic
          title={<Text id="SavingDetail.Remittance.method" />}
          value={method ? <Text id={`TransactionMethod.${method}`} /> : null}
        />
        <Statistic
          title={<Text id="SavingDetail.Remittance.amount" />}
          value={amount ? <Currency value={amount} /> : null}
        />
      </StatisticRow>

      <StatisticRow widthClass="w-50" compact>
        <Statistic
          title={<Text id="SavingDetail.Remittance.bank" />}
          value={get('bankBranch.bank.name', bankAccount)}
          hidden={excludedField(fields.remittance.bank)}
        />
        <Statistic
          title={<Text id="SavingDetail.Remittance.branch" />}
          value={get('bankBranch.name', bankAccount)}
          hidden={excludedField(fields.remittance.branch)}
        />
        <Statistic
          title={<Text id="SavingDetail.Remittance.account" />}
          value={get('accountNumber', bankAccount)}
          hidden={excludedField(fields.remittance.account)}
        />
        <Statistic
          title={<Text id="SavingDetail.Remittance.collectingBank" />}
          value={get('collectingBank.name', remittance)}
          hidden={excludedField(fields.remittance.collectingBank)}
        />

        {/* REMITTANCE_METHOD === PAYROLL DEDUCTION */}
        {excludedField('payrollDeductionDetails.payroll_deduction_employer_name') ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.employer" />}
            value={get('payrollDeductionDetails.payroll_deduction_employer_name', saving)}
          />
        )}
        {excludedField('payrollDeductionDetails.payroll_deduction_staff_id') ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.staffId" />}
            value={get('payrollDeductionDetails.payroll_deduction_staff_id', saving)}
          />
        )}
      </StatisticRow>
    </div>
  );
};

export function Remittance({ saving, refetch, readOnly = false }: Props) {
  const { permission, AppPermissions } = usePermissions();

  return (
    <Updater value={{ onUpdate: refetch }}>
      <EditableSection
        readOnly={readOnly || !permission.to(AppPermissions.EditSavingsRemittance)}
        initialData={saving}
        onSave={saving => {
          setRemittanceDetails({
            remittance: saving.remittance,
            data: saving,
            bankAccounts: saving.bankAccounts,
            store
          });

          return saving.save({
            with: { remittance: ['bankAccount', 'collectingBank'] }
          });
        }}
        config={{
          Component: RemittanceForm,
          validate: RemittanceForm.validateConfig.savingEdit
        }}
      >
        <Content saving={saving} />
      </EditableSection>
    </Updater>
  );
}
