import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { SavingType } from '@kwara/models/src';
import { fields } from '@kwara/models/src/models/Remittance';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';

const remittanceFields = {
  [TransactionChannels.directDebit]: [
    fields.remittance.bank,
    fields.remittance.branch,
    fields.remittance.account,
    fields.remittance.collectingBank
  ],
  [TransactionChannels.payrollDeduction]: [
    'payrollDeductionDetails.payroll_deduction_employer_name',
    'payrollDeductionDetails.payroll_deduction_staff_id'
  ]
};

export const excludedRemittanceFields = curry<SavingType, string, boolean>((saving: SavingType, fieldName: string) => {
  const repaymentMode = get(fields.remittance.mode, saving);
  return !includes(fieldName, remittanceFields[repaymentMode]);
});
