import { useQuery } from 'react-query';

import createModelErrors, { createErrorsFromApiResponse } from '@kwara/models/src/models/createModelErrors';

import { retry } from '../../../../services';
import { SavingsHistory, SavingsHistoryT } from '../../../../models';

export function useGetSavingsHistory(id: string) {
  return useQuery<SavingsHistoryT>(
    ['savings_history', id],
    async () => {
      try {
        return (await SavingsHistory.find(id)).data;
      } catch (error) {
        if (!error.response || error.response?.status === 500) {
          throw createModelErrors({ base: 'GET_SAVINGS_HISTORY_GENERIC_ERROR' });
        }

        throw createErrorsFromApiResponse({ errors: [error.response] });
      }
    },
    {
      enabled: !!id,
      retry,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  );
}
