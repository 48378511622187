import React from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectProps, SelectItem } from '@mantine/core';

import { MAXIMUM_NUMBER_Z_INDEX } from '@kwara/lib/src/zIndices';

const stylesPerSize = {
  regular: { height: '40px', borderRadius: '6px', padding: '0 12px' },
  medium: { height: '48px', borderRadius: '8px', padding: '0 16px' }
};

export type Size = 'regular' | 'medium';

export type Data = SelectItem;

type Props = SelectProps & {
  labelId?: string;
  placeholderId?: string;
  nothingFoundId?: string;
  size?: Size;
};

export function Combobox({
  labelId,
  disabled = false,
  required = false,
  size = 'regular',
  placeholderId = 'Combobox.select.item',
  nothingFoundId = 'Combobox.nothing.found',
  ...restProps
}: Props) {
  const int = useIntl();

  return (
    <Select
      {...restProps}
      searchable
      withinPortal
      withAsterisk
      clearable
      zIndex={MAXIMUM_NUMBER_Z_INDEX}
      aria-label="combobox"
      disabled={disabled}
      required={required}
      label={labelId && int.formatMessage({ id: labelId })}
      placeholder={int.formatMessage({ id: placeholderId })}
      styles={{ input: { ...stylesPerSize[size] } }}
      nothingFound={int.formatMessage({ id: nothingFoundId })}
      clearButtonProps={{ 'aria-label': 'Clear select field' }}
    />
  );
}
