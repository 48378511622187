import React from 'react';

import { If } from '@kwara/components/src/If/If';
import { InstantMobileMoneyValidator } from '@kwara/components/src/InstantMobileMoneyValidator';

import { useBaseLogic } from './useBaseLogic';

import { Container } from '../../../../../layouts';
import { LoanDisbursalPropTypes, LoanDisbursalData } from '../..';
import { DisbursementMethodForm } from './DisbursementMethodForm';
import { DisbursementStatusBanner } from './DisbursementStatusBanner';
import { AttributesRow } from '../../../../../components/AttributesRow';
import { validateDisbursementAmount } from '../../../utils/validateDisbursementAmount';

import { useAttributes } from './useAttributes';
import { FirstDisburser } from './FirstDisburser';
import { SecondDisburser } from './SecondDisburser';

import styles from './index.module.scss';

export function Approvals(props: LoanDisbursalPropTypes) {
  const {
    data: { loan, transactionAmountLimit, payoutProvider },
    addData
  } = props;

  const { attributeOne, attributeTwo, disbursement } = useAttributes(loan, addData);
  const {
    firstDisburser,
    secondDisburser,
    isFirstDisburserActive,
    disbursementAmount,
    phoneNumberProvider,
    isSecondDisburserActive,
    isSecondDisburserRequired
  } = useBaseLogic({ ...props, disbursement });

  return (
    <>
      <Container.Root>
        <Container.Body>
          <AttributesRow attributes={attributeOne} />
        </Container.Body>
      </Container.Root>
      <Container.Root>
        <Container.Heading id="LoanDisbursal.Approvals.disbursement" />
        <Container.Body>
          <AttributesRow attributes={attributeTwo} />
        </Container.Body>
      </Container.Root>
      <Container.Root>
        <Container.Body>
          <If
            condition={isFirstDisburserActive}
            do={
              <InstantMobileMoneyValidator
                loanAmount={disbursementAmount}
                payoutProvider={payoutProvider}
                phoneNumberProvider={phoneNumberProvider}
                transactionAmountLimit={transactionAmountLimit}
              />
            }
          />
          <div className={styles.grid}>
            <FirstDisburser
              {...props}
              firstDisburser={firstDisburser}
              disbursementAmount={disbursementAmount}
              isFirstDisburserActive={isFirstDisburserActive}
            />
            <SecondDisburser
              {...props}
              secondDisburser={secondDisburser}
              isFirstDisburserActive={isFirstDisburserActive}
              isSecondDisburserActive={isSecondDisburserActive}
              isSecondDisburserRequired={isSecondDisburserRequired}
            />
          </div>
          <DisbursementStatusBanner disbursement={disbursement} />
        </Container.Body>
      </Container.Root>
    </>
  );
}

export default {
  Component: Approvals,
  validate: {
    disburserOneAmount: {
      isRequired: (_: string, { loan }: LoanDisbursalData) => !loan.hasDisbursementRecord(),
      custom: validateDisbursementAmount
    },
    disburserTwoAmount: {
      isRequired: (_: string, { loan }: LoanDisbursalData) => loan.hasDisbursementRecord(),
      custom: validateDisbursementAmount
    },
    custom: (_: string, { loan }: LoanDisbursalData) => {
      if (!loan.hasDisbursementRecord()) return DisbursementMethodForm.validate;
      return null;
    }
  }
};
