import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from 'GlobalTypes';

import { formatError } from '@kwara/models/src/models/request/hooks';

import { FloatBalance, FloatBalanceType } from '.';

interface Options extends UseQueryOptions<FloatBalanceType, ApiError> {
  onStart?(): void;
}

async function queryFloatBalance(onStart?: () => void) {
  onStart?.();
  try {
    const response = await FloatBalance.find();
    return response.data;
  } catch (error) {
    return await formatError(error);
  }
}

export function useFloatBalance({ onStart, ...options }: Options = {}) {
  return useQuery<FloatBalanceType, ApiError>([FloatBalance.jsonapiType], () => queryFloatBalance(onStart), options);
}
