// @flow

import * as React from 'react';
import cx from 'classnames';
import { useFloating, offset, autoUpdate, flip, shift } from '@floating-ui/react-dom';
import zIndices from '@kwara/lib/src/zIndices';
import styles from './index.module.scss';

type Props = {
  children: React.Node
};

export const InfoPanel = ({ children }: Props) => {
  const { x, y, reference, floating } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()]
  });

  if (!children) {
    return null;
  }

  return (
    <span className={styles.Wrapper}>
      <span>
        <span ref={reference} className={cx('ba br-100', styles.Icon)}>
          i
        </span>
      </span>
      <div
        ref={floating}
        style={{
          top: y ?? 0,
          left: x ?? 0
        }}
        className={cx('bg-white br3', styles.InfoPanel, zIndices.OverlayMenus)}
      >
        {children}
      </div>
    </span>
  );
};
