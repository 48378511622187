import { useMemo } from 'react';

import { TransactionChannels } from '@kwara/models/src/models/Transactions';

export function useDisbursementModeAdapter() {
  return useMemo(() => {
    const channels = Object.values(TransactionChannels).reduce((modes, channel) => {
      modes[channel] = channel;
      return modes;
    }, {});

    const channelsAdapter = Object.assign(channels, {
      [TransactionChannels.mobileMpesaInstant]: TransactionChannels.mobile
      //Other adaptable channels
    });

    return channelsAdapter;
  }, []);
}
