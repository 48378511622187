import * as React from 'react';
import { useQueryClient } from 'react-query';

import noop from 'lodash/fp/noop';
import filter from 'lodash/fp/filter';
import { Redirect } from 'react-router-dom';
import isNull from 'lodash/isNull';
import size from 'lodash/fp/size';
import compact from 'lodash/fp/compact';

import { Checkbox } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import { LinkContext } from '@kwara/components/src/Button/linkContext';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';
import { Text } from '@kwara/components/src/Intl';
import { Loadable, RequestProps, SavingType, LoanType } from '@kwara/components/src';
import { LoadingKwara } from '@kwara/components/src/Loadable';
import { MemberType, DirectDebitOrderT } from '@kwara/models/src';
import {
  useMemberMetrics,
  useMemberLight,
  useMemberSavings,
  useMemberLoans
} from '@kwara/models/src/models/request/hooks';
import { Link } from '@kwara/components/src/Link';
import { ExpanderRow } from '@kwara/components/src';
import { PrintButton } from '@kwara/components/src';

import { useDirectDebitOrders, usePermissions } from '../../hooks';
import { loanPath, memberPath, savingPath } from '../../lib/urls';
import { Updater } from '../../components/EditableSection/v1/EditableSection';
import { Activity } from './components/Activity';
import { ButtonMenu } from '../../components/ButtonMenu';
import { DetailSection } from '../../components/Detail/DetailSection';
import Head from '../../components/Head';
import { Loans } from './components/Loans';
import Overview from './components/Overview';
import { Savings } from './components/Savings';
import { MobileBanking } from './components/MobileBanking';
import Visible from '../../components/Visible';
import DetailSubsection, { SubsectionTitle } from '../../components/DetailSubsection';
import { Remittance } from './components/Remittance';
import { DirectDebitOrders } from './components/DirectDebitOrders';
import { PageProps } from '..';

import {
  Bank,
  Documents,
  PersonalDetails,
  NextOfKin,
  Identity,
  Employment
} from '../../components/MemberInfo/components';
import { AsNavigation, BackToTopNavigation } from '../../components/DetailNavigation';
import { AppPermissionsType, ToHelperType } from '../../models/Permission/types';
import { useAuth } from '../../hooks';

type MemberBodyProps = { member: MemberType };
type StateT = {
  showClosedSavings: boolean;
  showClosedLoans: boolean;
};

type DDResponseT = RequestProps<DirectDebitOrderT>;
export type SavingsResponseT = RequestProps<SavingType[]>;
export type LoansResponseT = RequestProps<LoanType[]>;
export type MemberMetricsResponseT = RequestProps<MemberType>;

type MemberDetailProps = {
  linkContext?: LinkContext;
  showCommentCreate: boolean;
  hideButtonMenu?: boolean;
  showMargins: boolean;
  showOverviewActions: boolean;
  statusOverride?: null | 'APPLICANT' | 'GUARANTOR';
  readOnly: boolean;
  refetch?: () => void;
} & MemberBodyProps;

export const Menu = ({
  member,
  hidden,
  savingsR,
  loanR
}: {
  member: MemberType;
  hidden?: boolean;
  savingsR: SavingsResponseT;
  loanR: LoansResponseT;
}) => {
  const { AppPermissions, permission } = usePermissions();

  if (hidden) {
    return null;
  }

  const items = [
    permission.to(AppPermissions.AddDeposits) ? (
      <Loadable key="deposit" {...savingsR}>
        {savings => (
          <ButtonMenu.Item
            key="deposit"
            disabled={size(filter(s => s.transactionsPermitted(), savings)) === 0}
            to={memberPath({ id: member.id, action: 'deposit' })}
          >
            <Text id="MemberDetail.addDeposit" />
          </ButtonMenu.Item>
        )}
      </Loadable>
    ) : null,
    permission.to(AppPermissions.AddWithdrawal) ? (
      <Loadable key="withdrawal" {...savingsR}>
        {savings => (
          <ButtonMenu.Item
            key="withdrawal"
            disabled={size(filter(s => s.transactionsPermitted(), savings)) === 0}
            to={memberPath({ id: member.id, action: 'withdrawal' })}
          >
            <Text id="MemberDetail.addWithdrawal" />
          </ButtonMenu.Item>
        )}
      </Loadable>
    ) : null,
    permission.to(AppPermissions.AddRepayments) ? (
      <Loadable key="repayment" {...loanR}>
        {loans => (
          <ButtonMenu.Item
            key="repayment"
            disabled={size(filter(l => l.canMakeRepayment(), loans)) === 0}
            to={memberPath({ id: member.id, action: 'repayment' })}
          >
            <Text id="MemberDetail.addRepayment" />
          </ButtonMenu.Item>
        )}
      </Loadable>
    ) : null,
    permission.to(AppPermissions.AddSavings) ? (
      <ButtonMenu.Item key="savings" to={savingPath({ action: 'create', memberId: member.id }, { asObject: true })}>
        <Text id="MemberDetail.addSavings" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddLoans) ? (
      <ButtonMenu.Item
        // This validation is removed for KBS because eligible amount is meaningless for KBS since their eligibility is on a per product basis.
        // For more details: https://kwara.slack.com/archives/CMSBN9MLL/p1582879558000300
        // disabled={!member.isEligibleForLoan()}
        key="loan"
        to={loanPath({ action: 'create/loanPortfolio/1', memberId: member.id }, { asObject: true })}
      >
        <Text id="MemberDetail.addLoan" />
      </ButtonMenu.Item>
    ) : null
  ];

  return items.every(isNull) ? null : <ButtonMenu title="Add">{items}</ButtonMenu>;
};

const renderBody = ({
  member,
  showCommentCreate,
  readOnly: readOnlyProp,
  ddOrderR,
  savingsR,
  loanR,
  refetch = noop,
  onCheck,
  state,
  to,
  AppPermissions,
  isV1
}: MemberDetailProps &
  PageProps & {
    refetch?: () => void;
    onCheck: (e: React.SyntheticEvent<HTMLInputElement>) => void;
    member: MemberType;
    ddOrderR: DDResponseT;
    savingsR: SavingsResponseT;
    loanR: LoansResponseT;
    state: StateT;
    to: ToHelperType;
    AppPermissions: AppPermissionsType;
    isV1: boolean;
  }) => {
  const readOnly = readOnlyProp || !to(AppPermissions.EditMembers);

  return (
    <Updater
      value={{
        onUpdate: refetch
      }}
    >
      <DetailSection
        id="savings"
        title={<Text id="MemberDetail.savings" />}
        subtitle={<BackToTopNavigation />}
        headerRight={
          <div className="pt5 h-100 flex items-center">
            <Checkbox
              compact
              name="showClosedSavings"
              labelId="MemberDetail.showInactiveSavings.checkbox"
              checked={state.showClosedSavings}
              onChange={onCheck}
            />
          </div>
        }
      >
        <Savings member={member} showClosedSavings={state.showClosedSavings} savingsR={savingsR} />
      </DetailSection>
      <DetailSection
        id="loans"
        title={<Text id="MemberDetail.loans" />}
        subtitle={<BackToTopNavigation />}
        headerRight={
          <div className="pt5 h-100 flex items-center">
            <Checkbox
              compact
              name="showClosedLoans"
              labelId="MemberDetail.showInactiveLoans.checkbox"
              checked={state.showClosedLoans}
              onChange={onCheck}
            />
          </div>
        }
      >
        <Loans loanR={loanR} member={member} showClosedLoans={state.showClosedLoans} />
      </DetailSection>

      {isV1 ? null : (
        <DetailSection
          id="mobile-banking"
          title={<Text id="MemberDetail.MobileBanking" />}
          subtitle={<BackToTopNavigation />}
        >
          <MobileBanking member={member} />
        </DetailSection>
      )}

      {isV1 ? null : (
        <DetailSection id="remittance" title={<Text id="MemberDetail.remittance" />} subtitle={<BackToTopNavigation />}>
          <DetailSubsection title={<Text id="MemberDetail.remittance" />}>
            <Loadable {...loanR}>
              {loans => (
                <Loadable {...savingsR}>
                  {savings => <Remittance member={member} savings={savings} loans={loans} />}
                </Loadable>
              )}
            </Loadable>
          </DetailSubsection>
          {isV1 ? null : (
            <DetailSubsection title={<Text id="MemberDetail.ddOrder" />}>
              <Loadable {...ddOrderR}>{ddOrders => <DirectDebitOrders member={member} ddOrders={ddOrders} />}</Loadable>
            </DetailSubsection>
          )}
        </DetailSection>
      )}

      <DetailSection
        id="info"
        title={<Text id="MemberDetail.info" />}
        subtitle={
          <>
            <BackToTopNavigation />
            <Visible to={AppPermissions.EditMembers}>
              <Link to={memberPath({ id: member.id, action: 'edit' })}>Edit</Link>
            </Visible>
          </>
        }
      >
        <SubsectionTitle>
          <PersonalDetails.Title />
        </SubsectionTitle>
        <ExpanderRow ariaLabel="PersonalDetails">
          <PersonalDetails member={member} readOnly={readOnly} />
        </ExpanderRow>
        <SubsectionTitle>
          <Identity.Title />
        </SubsectionTitle>
        <ExpanderRow>
          <Identity member={member} readOnly={readOnly} />
        </ExpanderRow>
        <SubsectionTitle>
          <NextOfKin.Title />
        </SubsectionTitle>
        <ExpanderRow>
          <NextOfKin member={member} readOnly={readOnly} />
        </ExpanderRow>
        <SubsectionTitle>
          <Employment.Title />
        </SubsectionTitle>
        <ExpanderRow>
          <Employment member={member} readOnly={readOnly} config={Employment.editConfig.extra} />
        </ExpanderRow>
        {isV1 ? null : (
          <>
            <SubsectionTitle>
              <Bank.Title />
            </SubsectionTitle>
            <ExpanderRow>
              <Bank member={member} readOnly={readOnlyProp} />
            </ExpanderRow>
          </>
        )}
        <SubsectionTitle>
          <Documents.Title />
        </SubsectionTitle>
        <ExpanderRow ariaLabel="Documents">
          <Documents member={member} readOnly={readOnly} />
        </ExpanderRow>
      </DetailSection>

      {isV1 ? null : (
        <DetailSection id="activity" title={<Text id="MemberDetail.activity" />} subtitle={<BackToTopNavigation />}>
          <Activity showCommentCreate={showCommentCreate} memberId={member.id} />
        </DetailSection>
      )}
    </Updater>
  );
};

export const MemberDetail = ({
  member,
  hideButtonMenu,
  statusOverride,
  showMargins = true,
  showCommentCreate = true,
  showOverviewActions = true,
  ...props
}: MemberDetailProps) => {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const isV1 = auth.isV1();

  const { AppPermissions, permission } = usePermissions();
  const memberMetricsR = useMemberMetrics(member.id);
  const savingsR = useMemberSavings(member.id);
  const loanR = useMemberLoans(member.id);
  const ddOrderR = useDirectDebitOrders({ memberId: member.id, enabled: !isV1 });

  const [state, setState] = React.useState<StateT>({
    showClosedLoans: false,
    showClosedSavings: false
  });

  const refetch = () => {
    queryClient.invalidateQueries('member_light');
    queryClient.invalidateQueries('member_details_metrics');
    queryClient.invalidateQueries('activity');
    queryClient.invalidateQueries('direct_debit_orders');
  };

  const onCheck = e => {
    setState({
      ...state,
      [e.target.name]: e.target.checked
    });
  };

  const body = renderBody({
    ...props,
    ddOrderR,
    savingsR,
    loanR,
    showCommentCreate,
    member,
    onCheck,
    state,
    refetch,
    AppPermissions,
    to: permission.to,
    isV1
  });
  const Navigation = AsNavigation(body);

  const closeButton =
    member.canBeExited && member.canBeExited() ? (
      <Visible key="close" to={AppPermissions.ExitMembers}>
        <Button size="medium" to={memberPath({ id: member.id, action: 'close' })}>
          <Text id="MemberDetail.close" />
        </Button>
      </Visible>
    ) : null;

  const printButton = isV1 ? null : (
    <PrintButton
      ariaLabel="Download Statement"
      className="mr2"
      size={'medium'}
      to={memberPath({ id: member.id, action: 'statement' })}
    />
  );

  const buttonMenu = <Menu key="menu" member={member} hidden={hideButtonMenu} savingsR={savingsR} loanR={loanR} />;

  return (
    <PageLayoutWithOverview
      className={showMargins ? 'pt5' : ''}
      overview={
        <Overview
          navigation={<Navigation />}
          member={member}
          memberMetricsResponse={memberMetricsR}
          loansResponse={loanR}
          savingsResponse={savingsR}
          actions={showOverviewActions ? compact([closeButton, printButton, buttonMenu]) : []}
          showBack={showOverviewActions}
          statusOverride={statusOverride}
        />
      }
    >
      <Head titleId="MemberDetail.title" values={{ fullName: member.fullName && member.fullName() }} />
      {body}
    </PageLayoutWithOverview>
  );
};

export const MemberDetailRoute = (props: PageProps) => {
  const memberId = props.match.params.memberId;
  const r = useMemberLight(memberId);
  return (
    <Loadable {...r} isLoading={r.isLoading} loading={<LoadingKwara />}>
      {(member: MemberType) => {
        if (member.isPendingApproval()) {
          return <Redirect to={`/members/${member.id}/approve`} />;
        }
        return <MemberDetail readOnly={false} member={member} {...props} />;
      }}
    </Loadable>
  );
};
