import * as React from 'react';

import { Field as BasicField, TextField as BasicTextField, SubscribedPhoneField } from '@kwara/components/src/Form';

import { Grid } from '../../../components/Grid';

export const Form = ({ StackChild, TextField, formProps }) => {
  const { values } = formProps;
  const { numberOfSms, costPerSms } = values;

  const totalCost = costPerSms * (numberOfSms || 0);

  return (
    <StackChild>
      <SubscribedPhoneField disableDropdown name="phoneNumber" labelId="RenewSMS.Form.phoneNumber.label" required />
      <Grid columns={2} width={'w-50'} border={false}>
        <TextField isCurrency required name="numberOfSms" labelId="RenewSMS.Form.numberOfSms.label" />
        <TextField isCurrency name="costPerSms" labelId="RenewSMS.Form.costPerSMS.label" readOnly disabled />
      </Grid>
      <BasicField name="totalCost" size="medium" labelId="RenewSMS.Form.totalCost.label" disabled>
        <BasicTextField size="medium" name="totalCost" readOnly disabled value={totalCost} />
      </BasicField>
    </StackChild>
  );
};

Form.validate = {
  phoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  },
  numberOfSms: {
    isRequired: () => true,
    nonZero: () => true
  }
};
