import { useQuery } from 'react-query';

import { GlAccount } from '@kwara/models/src';

function fetchGlAccounts() {
  return GlAccount.all().then(res => res.data);
}

export function useGlAccounts() {
  return useQuery(['proxy_gl_accounts'], fetchGlAccounts, { placeholderData: [] });
}
