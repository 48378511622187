import { useState } from 'react';

import { useInterval } from '@kwara/models/src/models/request/hooks';

export function useDebounce<ValueType>(value: ValueType, delay = 400) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useInterval(() => {
    setDebouncedValue(value);
  }, delay);

  return debouncedValue;
}
