// @flow

import * as React from 'react';
import filter from 'lodash/fp/filter';

import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';

import { FilterableTransactionOverview } from './FilterableTransactionOverview';
import { Title } from '.';

export const TransactionsInfo = ({ StackChild, memberSavingsR, memberLoansR }) => {
  return (
    <>
      <Title StackChild={StackChild} name={<Text id="LoanApprove.Overview.transactions" />} />

      <StackChild size="widest">
        <Loadable {...memberSavingsR}>
          {savings => (
            <Loadable {...memberLoansR}>
              {loans => {
                // TODO: fetch for the active loans rather than fetch all the loans and then filter in FE
                const activeLoans = filter(loan => loan.isApproved(), loans);
                const activeSavings = filter(saving => saving.isActive(), savings);
                return (
                  <FilterableTransactionOverview StackChild={StackChild} loans={activeLoans} savings={activeSavings} />
                );
              }}
            </Loadable>
          )}
        </Loadable>
      </StackChild>
    </>
  );
};
