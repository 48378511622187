import * as React from 'react';
import lodashMap from 'lodash/map';

import { LoanType } from '@kwara/models/src/models/Loan';

import Table, * as table from '../../../../components/Table';
import { CreateGroupWithLoans } from './CreateGroupWithLoans';
import { itemsGroupedByAttribute, orderByAttribute, Groups, Orders, Group, Order } from './utils';

type LoanListTablePropTypes = {
  hasMore?: boolean;
  groupBy?: Group;
  loading: boolean;
  loans: LoanType[];
  orderBy?: Order;
  errors?: Object[];
  onLoadMoreData: () => void;
  isV1?: boolean;
};

export default function LoanListTable(props: LoanListTablePropTypes) {
  const {
    loading,
    loans,
    errors,
    onLoadMoreData,
    isV1,
    hasMore = false,
    groupBy = Groups.state,
    orderBy = Orders.updated
  } = props;
  const groupedLoans = groupBy ? itemsGroupedByAttribute(loans, groupBy) : { none: loans };

  const heading = (
    <table.Row>
      <table.Heading translationId="LoanListTable.id" />
      <table.Heading translationId="LoanListTable.member" />
      <table.Heading translationId="LoanListTable.loan" />
      <table.Heading align="right" translationId="LoanListTable.amount" />
      <table.Heading align="right" translationId="LoanListTable.interestRate" />
      <table.Heading translationId="LoanListTable.duration" />
      {isV1 ? null : <table.Heading translationId="LoanListTable.updated" />}
      <table.Heading translationId="LoanListTable.status" />
      <table.Heading translationId="LoanListTable.disbursalApprovedBy" />
    </table.Row>
  );

  const columns = heading.props.children.filter(heading => heading).length;

  return (
    <Table
      heading={heading}
      footer={
        <table.Footer
          colSpan={columns}
          isLoading={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={loans}
          translationBaseId="LoanListTable"
        />
      }
    >
      {lodashMap(groupedLoans, (loans, group) => (
        <CreateGroupWithLoans
          isV1={isV1}
          key={group}
          columns={columns}
          group={group}
          loans={orderByAttribute(loans, orderBy)}
        />
      ))}
    </Table>
  );
}
