import * as React from 'react';
import { Redirect } from 'react-router-dom';
import get from 'lodash/fp/get';
import pick from 'lodash/fp/pick';
import map from 'lodash/fp/map';
import invoke from 'lodash/fp/invoke';
import round from 'lodash/round';
import result from 'lodash/fp/result';
import compact from 'lodash/fp/compact';
import last from 'lodash/fp/last';

import {
  Loan,
  Collateral,
  LoanType,
  GuaranteeType,
  CollateralT,
  MemberType,
  LoanProductType,
  PeriodUnitsT,
  TransactionChannelT
} from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { add } from '@kwara/lib/src/currency';
import { useMemberLoans, useLoan } from '@kwara/models/src/models/request/hooks';

import { YES, isDoneAddingCollateral } from '../Loan/LoanAdd/components/Security/Collateral';
import { SubStepComponentProps } from '../../components/Wizard';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { steps } from './config';
import { WizardPageProps } from '..';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

type Props = WizardPageProps<{ loanId: string }>;

export type RefinanceFormData = {
  loan: LoanType;
  guarantors: GuaranteeType[];
  collaterals: CollateralT[];
  member: MemberType;
  product: LoanProductType;
  amount: string;
  loanDuration: string;
  repaymentPeriod: string;
  repaymentPeriodUnit: PeriodUnitsT;
  purpose: string;
  specification: string;
  disbursementMode: TransactionChannelT;
};

export type ComponentPropsLoanRefinance = SubStepComponentProps<RefinanceFormData>;

const ONE_CENT = 0.01;

export function applyRefinancingFee(original: number) {
  // TO DO: This 1.05 should not be hardcoded here.
  // It should be based on the bridging loan account's
  // interest and/or any fees that are charged during refinancing.

  return round(original * 1.05, 2);
}

export function totalBalance(loan: LoanType) {
  return Number(get('totalBalance', loan));
}

const refinanceLoan = async data => {
  const {
    amount,
    loanDuration,
    loanInstallments,
    repaymentPeriod,
    repaymentPeriodUnit,
    loanId,
    guarantors: guarantorsRaw,
    collaterals: collateralsRaw,
    payOffLoans,
    classifications
  } = data;

  const loanClassificationId = last(compact(classifications));

  // TO DO: The backend does not accept
  // the amount for a guarantor as a string.
  // Ideally it should.
  const guarantors = map(g => {
    g.amount = Number(g.amount);
    return g;
  }, guarantorsRaw);

  const collaterals = map(c => Collateral.create(c), collateralsRaw);

  const refinanceData = {
    id: loanId,
    amount,
    repaymentPeriod,
    repaymentPeriodUnit,
    loanDuration,
    loanInstallments,
    guarantors,
    payOffLoans,
    loanClassificationId,
    collaterals,
    ...pick(['purpose', 'specification', 'disbursementMode', 'disbursementBankDetails'], data)
  };

  const RefinancedLoan = Loan.refinance(loanId);
  const loan = new RefinancedLoan(refinanceData);

  const didSave = await loan.save({ with: ['collaterals', 'guarantors'] });
  if (!didSave) {
    throw loan.errors;
  }
};

const includes = ['product'];
export const LoanRefinance = (props: Props) => {
  const { baseUrl, match, history } = props;
  const r1 = useLoan(match.params.loanId);
  const r2 = useMemberLoans(get('data.member.id', r1), includes);
  const store = useSaccoProfileContext();

  return (
    <Loadable {...r1}>
      {loan => (
        <Loadable {...r2}>
          {memberLoans => {
            const { product } = loan;
            const { unit: repaymentPeriodUnit, value: repaymentPeriod } = product.toRepaymentFrequencyUI();

            const classifications = invoke('loanPurpose.classifications', loan);

            const canRefinance = store.refinanceEnabled && loan.canRefinance();

            return canRefinance ? (
              <Wizard
                analyticsId="LoanRefinance"
                baseUrl={baseUrl}
                history={history}
                initialData={{
                  loan,
                  loans: memberLoans || [],
                  payOffLoans: [loan.id],
                  product: loan.product,
                  classifications,
                  member: loan.member,
                  firstName: get('member.firstName', loan),
                  fullName: result('member.fullName', loan),
                  loanId: loan.id,
                  amount: add(applyRefinancingFee(totalBalance(loan)), ONE_CENT),
                  repaymentPeriod,
                  repaymentPeriodUnit,
                  loanDuration: loan.loanDuration,
                  purpose: loan.purpose,
                  specification: loan.specification,
                  disbursementMode: loan.disbursementMode,
                  // Added to the form data to validate wizard form for optional collaterals.
                  // See https://github.com/getkwara/webapp/pull/1529
                  [isDoneAddingCollateral]: YES
                }}
                cancelReturnsTo={loanPath({ id: loan.id })}
                currentStep={match.params.step}
                currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
                onSubmit={refinanceLoan}
                onSubmitCompletion={r1.refetch}
                steps={steps}
                startId="configure"
                titleId="LoanRefinance.shortTitle"
              />
            ) : (
              <Redirect to={loanPath({ id: loan.id })} />
            );
          }}
        </Loadable>
      )}
    </Loadable>
  );
};
