import * as React from 'react';
import cx from 'classnames';
import { ActionButton } from '@kwara/components/src/Button';

import styles from '../index.module.css';

const ENUMERATED_OPTIONS = ['spouse', 'child', 'parent', 'inlaw', 'sibling', 'other'];

export function Row({ name, index, TextField, SelectField, PhoneField, onRemove }) {
  return (
    <div className="flex  items-center" aria-label={`Next of Kin ${index + 1}`}>
      <div className={cx(styles.input, 'mr1')}>
        <TextField labelId="AddMember.NextOfKin.label.name" name={`${name}.name`} showInfo={false} />
      </div>
      <div className={cx(styles.input, 'mr1')}>
        <PhoneField labelId="AddMember.NextOfKin.label.phoneNumber" name={`${name}.phoneNumber`} showInfo={false} />
      </div>
      <div className={cx(styles.input, 'mr1')}>
        <SelectField labelId="AddMember.NextOfKin.label.relationship" name={`${name}.relationship`} showInfo={false}>
          <SelectField.Option value="" />
          {ENUMERATED_OPTIONS.map(val => {
            return (
              <SelectField.Option
                key={val}
                value={val}
                translationId={`AddMember.NextOfKin.option.relationship.${val}`}
              />
            );
          })}
        </SelectField>
      </div>
      <div className={cx(styles.icon)}>
        <ActionButton hideBorder col="red500" type="cancel" onClick={onRemove} />
      </div>
    </div>
  );
}
