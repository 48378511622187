import { useQuery } from 'react-query';
import { ReportTemplate } from '@kwara/models/src';

async function fetchTemplate({ queryKey }) {
  const [_, id] = queryKey;
  return await ReportTemplate.find(id).then(res => res.data);
}

export function useReportTemplate(id: string) {
  return useQuery(['report_template', id], fetchTemplate);
}
