import React from 'react';

import { LoanTopupUndoStepPropTypes } from '../..';

function Notes({ TextArea }: LoanTopupUndoStepPropTypes) {
  return (
    <div>
      <TextArea name="notes" labelId="LoanTopup.Undo.Notes.textArea.label" />
    </div>
  );
}

export default {
  Component: Notes,
  validate: {
    notes: {
      isRequired: () => true
    }
  }
};
