import * as React from 'react';
import cx from 'classnames';

import styles from '../index.module.scss';

///UTILS BELOW
/*****************************************************************/
export interface TdPropTypes extends React.ComponentPropsWithoutRef<'td'> {
  rowSpan?: number;
  colSpan?: number;
  width?: string;
  children: React.ReactNode;
}

function Td({ children, ...restProps }: TdPropTypes) {
  return <td {...restProps}>{children}</td>;
}

///COMPONENT BELOW
/*****************************************************************/
type PrimitiveBodyTypes = React.ComponentPropsWithoutRef<'tbody'>;
type BodyElement = React.ElementRef<'tbody'>;
interface BodyPropTypes extends Omit<PrimitiveBodyTypes, 'children'> {
  children(Td: React.FC<TdPropTypes>): React.ReactNode;
}

export const Body = React.forwardRef<BodyElement, BodyPropTypes>(function Body(
  { children, className, ...restProps },
  forwardedRef
) {
  return (
    <tbody {...restProps} ref={forwardedRef} className={cx(styles['lister-body'], className)}>
      {children(Td)}
    </tbody>
  );
});
