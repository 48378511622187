import * as React from 'react';
import map from 'lodash/fp/map';
import invoke from 'lodash/fp/invoke';
import get from 'lodash/fp/get';

import { DateTime, Text } from '@kwara/components/src/Intl';
import { TillTransactionT } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';

import { transactionPath } from '../../../../lib/urls';
import { Currency } from '../../../../components/Currency';
import Table, * as table from '../../../../components/Table';

function TransactionRow({ transaction, pathTo }: { transaction: TillTransactionT; pathTo: string }) {
  return (
    <table.Row to={pathTo}>
      <table.Cell to={pathTo}>
        <DateTime value={transaction.updatedAt} />
      </table.Cell>

      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {transaction.transactionId}
      </table.Cell>
      <table.Cell to={pathTo}>
        {transaction.isUtility() ? (
          transaction.name
        ) : (
          <>
            <div>{invoke('member.fullName', transaction)}</div>
            <div className="grey-400">{get('member.id', transaction)}</div>
          </>
        )}
      </table.Cell>
      <table.Cell to={pathTo}>
        <Text id={`TillTransactionsTable.${transaction.type}`} />
      </table.Cell>

      <table.Cell to={pathTo}>
        <Text id={`TransactionMethod.${transaction.paymentMethod}`} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <Currency value={transaction.debitAmount != null ? Math.abs(transaction.debitAmount) : null} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <Currency value={transaction.creditAmount != null ? Math.abs(transaction.creditAmount) : null} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={transaction.balance} />
      </table.Cell>
      <table.Cell to={pathTo}>{get('branch.name', transaction)}</table.Cell>
      <table.Cell to={pathTo}>
        {transaction.state ? <StatusTag size="small" state={transaction.state} /> : null}
      </table.Cell>
    </table.Row>
  );
}

export function TillTransactionsTable({
  hasMore,
  errors,
  loading,
  transactions,
  onLoadMoreData,
  isTillOpen
}: {
  hasMore: boolean;
  errors: Object[];
  loading: boolean;
  transactions: TillTransactionT[];
  onLoadMoreData: () => void;
  isTillOpen: boolean;
}) {
  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="TillTransactionsTable.lastUpdated" />
          <table.Heading translationId="TillTransactionsTable.id" />
          <table.Heading translationId="TillTransactionsTable.name" />
          <table.Heading translationId="TillTransactionsTable.type" />
          <table.Heading translationId="TillTransactionsTable.method" />
          <table.Heading translationId="TillTransactionsTable.debits" />
          <table.Heading translationId="TillTransactionsTable.credits" />
          <table.Heading align="right" translationId="TillTransactionsTable.amount" />
          <table.Heading translationId="TillTransactionsTable.branch" />
          <table.Heading translationId="TillTransactionsTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={7}
          isLoading={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={isTillOpen && errors}
          items={transactions}
          translationBaseId="TillTransactionsTable"
        />
      }
    >
      {map(
        transaction => (
          <TransactionRow
            key={transaction.id}
            transaction={transaction}
            pathTo={transactionPath({ id: transaction.id }) as string}
          />
        ),
        transactions
      )}
    </Table>
  );
}

TillTransactionsTable.defaultProps = {
  hasMore: false
};
