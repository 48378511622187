import * as React from 'react';
import { UseQueryResult } from 'react-query';
import get from 'lodash/fp/get';

import { HttpErrorObject } from 'GlobalTypes';

import { MemberType, LoanType } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Loadable } from '@kwara/components/src/Loadable';
import { Currency, Text } from '@kwara/components/src/Intl';

import { AttributesRow } from '../../../../../components/AttributesRow';
import { LoansHorizontalScroller } from '../../../../../components/LoansHorizontalScroller';
import { GuaranteedLoansHorizontalScroller } from '../../../../../components/GuaranteedLoansHorizontalScroller';
import { DefaultedLoansHorizontalScroller } from '../../../../../components/DefaultedLoansHorizontalScroller';
import { useLoansForMember } from '../../../../../hooks';
import { LoanAddPropTypes } from '../..';
import { useMember } from '../../useMember';

//UTILS BELOW
/***************************************************************************/
function getOverviewStatsRowData(memberData, isV1 = false) {
  return [
    {
      title: (
        <div>
          <span className="kw-text-small kw-weight-regular mr2">{memberData.id}</span>
          <StatusTag state={memberData.state.current} size="small" />
        </div>
      ),
      value: memberData.fullName()
    },
    isV1
      ? null
      : {
          title: <Text id="LoanAdd.LoanPortfolio.totalLoans" />,
          value: <Currency value={get('totalLoans', memberData)} format="currency" />
        },
    isV1
      ? null
      : {
          title: <Text id="LoanAdd.LoanPortfolio.eligibleAmount" />,
          value: <Currency value={get('eligibleAmount', memberData)} format="currency" />
        }
  ].filter(Boolean);
}

const includes = [];
//COMPONENT BELOW
/***************************************************************************/
export function LoanPortfolio(props: LoanAddPropTypes) {
  const memberId = props.data.member.id;
  const isV1 = props.isV1;
  const memberRequest: UseQueryResult<MemberType, HttpErrorObject | Error> = useMember({ id: memberId });
  const activeLoansR = useLoansForMember(memberId, {
    where: { state: 'ACTIVE,APPROVED,PENDING_APPROVAL' },
    includes,
    enabled: !isV1
  });
  const defaultedLoansR = useLoansForMember(memberId, {
    where: { state: 'ACTIVE_IN_ARREARS' },
    includes,
    enabled: !isV1
  });

  async function onUpdate(member: MemberType, loans: LoanType[]) {
    props.addData({
      member,
      loans
    });

    return await props.onChange({
      member,
      loans
    });
  }

  React.useEffect(() => {
    if (memberRequest.isFetched && activeLoansR.isFetched && defaultedLoansR.isFetched) {
      onUpdate(memberRequest.data, [...activeLoansR.data, ...defaultedLoansR.data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRequest.data, memberRequest.isLoading, activeLoansR.isFetched, defaultedLoansR.isFetched]);

  return (
    <>
      <Loadable {...memberRequest}>
        {(memberData: MemberType) => (
          <>
            <AttributesRow attributes={getOverviewStatsRowData(memberData, isV1)} useBackground={false} />
          </>
        )}
      </Loadable>

      {/******************  All loans section ******************/}
      {isV1 ? null : <LoansHorizontalScroller memberId={memberId} />}

      {/******************  Guaranteed loans Section ******************/}
      {isV1 ? null : <GuaranteedLoansHorizontalScroller memberId={memberId} />}

      {/******************  Defaulted loans Section ******************/}
      {isV1 ? null : <DefaultedLoansHorizontalScroller memberId={memberId} />}
    </>
  );
}

LoanPortfolio.validate = data => {
  if (data.isV1) return {};
  if (!data.loans) {
    return {
      loans: {
        isRequired: () => true
      }
    };
  }

  return {};
};
