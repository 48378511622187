// @flow

import { useQuery } from 'react-query';
import { CurrentTillSession, type IncludesT } from '@kwara/models/src';

async function fetchCurrentTillSession({ queryKey }) {
  const [_, includes] = queryKey;

  return await CurrentTillSession.includes(includes)
    .find()
    .then(res => res.data)
    // If there is no current till session, this will result in an error
    // so we can return false to signal the abscence of a current session.
    .catch(() => false);
}

const defaultIncludes = ['till'];
export function useCurrentTillSession(enabled: boolean, includes: IncludesT = defaultIncludes) {
  return useQuery(['current_till_session', includes], fetchCurrentTillSession, { retry: false, enabled });
}
