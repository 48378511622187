// @flow

import * as React from 'react';
import get from 'lodash/get';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { Loading } from '@kwara/components/src/Loadable';
import { ScrollToTop } from '@kwara/components/src/ScrollIntoFocus';

import {
  RedemptionStates,
  type RedemptionState,
  type RedemptionError
} from '@kwara/components/src/InvitationRedeem/components/Redeemer';
import RegistrationForm, {
  type FormData,
  type InitialDetails
} from '@kwara/components/src/InvitationRedeem/components/RegistrationForm';

type Props = {
  currentState: RedemptionState,
  error: RedemptionError,
  onUserDetails: (data: FormData) => any,
  userDetails: InitialDetails | FormData
};

export default class RedeemerUI extends React.Component<Props, *> {
  renderUiForCurrentState = () => {
    const { currentState, error } = this.props;

    switch (currentState) {
      case RedemptionStates.init:
      case RedemptionStates.validatingToken:
        return <Loading />;
      case RedemptionStates.invalidToken:
        return (
          <>
            <p className="grey-400 kw-text-medium mt2 mb4">
              <Text
                values={{
                  loginHere: (
                    <Link to="/">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.loginHere" />
                      </span>
                    </Link>
                  ),
                  resetItHere: (
                    <Link to="/password/forgot">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.resetItHere" />
                      </span>
                    </Link>
                  ),
                  br: <br />
                }}
                id={`InvitationRedeem.RedeemerUI.error.${get(error, 'code') || 'genericToken'}`}
              />
            </p>
          </>
        );
      case RedemptionStates.validToken:
        return (
          <RegistrationForm
            initialDetails={this.props.userDetails}
            onSubmit={this.props.onUserDetails}
            {...this.props}
          />
        );
      case RedemptionStates.submitting:
        return <Loading />;
      case RedemptionStates.submissionErrors:
        return (
          <>
            <div className="mb4">
              <Text id={`InvitationRedeem.RedeemerUI.error.${get(error, 'code') || 'genericSubmission'}`} />
            </div>

            <RegistrationForm initialDetails={this.props.userDetails} onSubmit={this.props.onUserDetails} />
          </>
        );
      case RedemptionStates.created:
        return (
          <ScrollToTop>
            <>
              <Text
                values={{
                  loginHere: (
                    <Link to="/">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.loginHere" />
                      </span>
                    </Link>
                  )
                }}
                id="InvitationRedeem.RedeemerUI.success"
              />
            </>
          </ScrollToTop>
        );
      default:
        return 'Error';
    }
  };

  render() {
    return <div>{this.renderUiForCurrentState()}</div>;
  }
}
