import * as React from 'react';
import map from 'lodash/fp/map';

import StatusTag from '@kwara/components/src/StatusTag';
import { Date, Text, DateTime } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import Table, { Row, Heading, Cell, Footer } from '../../../../components/Table';

export const DirectDebitReportsTable = ({ reports, onDownload, ...rest }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading width="75px" translationId="Finance.DDReports.Table.header.id" />
          <Heading translationId="Finance.DDReports.Table.header.collectingBank" />
          <Heading translationId="Finance.DDReports.Table.header.startDate" />
          <Heading width="95px" translationId="Finance.DDReports.Table.header.dayOfMonth" />
          <Heading translationId="Finance.DDReports.Table.header.createdBy" />
          <Heading translationId="Finance.DDReports.Table.header.createdAt" />
          <Heading translationId="Finance.DDReports.Table.header.actions" />
          <Heading translationId="Finance.DDReports.Table.header.state" />
        </Row>
      }
      footer={<Footer items={reports} colSpan={8} {...rest} />}
    >
      {map(report => {
        return (
          <Row key={report.id}>
            <Cell>{report.id}</Cell>
            <Cell>{report.collectingBank.name}</Cell>
            <Cell>
              <Date value={report.startDate} />
            </Cell>
            <Cell>{report.dayOfMonth}</Cell>
            <Cell>{report.createdByName}</Cell>
            <Cell>
              <DateTime value={report.createdAt} />
            </Cell>
            <Cell>
              {report.isDownloadable() ? (
                <Link size="small" type="primary" onClick={() => onDownload(report)}>
                  <Text id="Finance.DDReports.Table.XLS" />
                </Link>
              ) : null}
              {report.isDownloadable('batchTransactions') ? (
                <Link size="small" type="primary" onClick={() => onDownload(report, 'batchTransactions')}>
                  <Text id="Finance.DDReports.Table.Transactions" />
                </Link>
              ) : null}
            </Cell>
            <Cell>
              <StatusTag state={report.state} />
            </Cell>
          </Row>
        );
      }, reports)}
    </Table>
  );
};
