import React, { useEffect, useCallback } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

type PropsWithoutIntl = {
  titleId?: string;
  values?: Record<string, any>;
};

interface HeadPropTypes extends PropsWithoutIntl {
  intl: IntlShape;
}

function BaseHead({ titleId, intl, values }: HeadPropTypes) {
  const setTitle = useCallback(() => {
    let title = 'Kwara';

    if (titleId) {
      title += ` - ${intl.formatMessage({ id: titleId }, values)}`;
    }

    document.title = title;
  }, [intl, titleId, values]);

  useEffect(() => {
    setTitle();
  }, [setTitle]);

  return null;
}

export const Head: React.ComponentType<PropsWithoutIntl> = injectIntl(BaseHead);
