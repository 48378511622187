import React from 'react';

import { Combobox } from '@kwara/components/src/Form/Combobox/Combobox';

import { useComboboxBaseFilter } from './useComboboxBaseFilter';

type ComboboxBaseFilterPropTypes = {
  filterType: string;
  placeholderId: string;
};

export function ComboboxBaseFilter({ filterType, placeholderId }: ComboboxBaseFilterPropTypes) {
  const { value, data, onChange } = useComboboxBaseFilter(filterType);

  return <Combobox value={value} placeholderId={placeholderId} name={filterType} data={data} onChange={onChange} />;
}
