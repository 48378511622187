// @flow
import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import logoWhite from '@kwara/components/src/Logo/logo_white.svg';
import logo from '@kwara/components/src/Logo/logo.svg';
import { appName } from '@kwara/lib/src/utils';

import styles from './index.module.scss';

export const Footer = () => {
  return (
    <PoweredByKwara
      classNames={appName.isMember ? 'mt2 kw-text-small' : ''}
      col={appName.isMember ? 'white' : 'grey'}
      isShort={appName.isMember}
      size={appName.isMember ? 'small' : 'regular'}
    />
  );
};

type Props = {
  classNames?: string,
  col?: 'grey' | 'white',
  isShort?: boolean,
  size?: 'regular' | 'small' | 'small-3x'
};

// Because here we have to style the p tag and the img separately but make sure they play well
// i.e. look good together, the suggestion is to encapsulate the styles into a "size", that then
// handles the styling for both in a harmonious way. (As opposed to freely dropping in classes into
// the component.)
export const PoweredByKwara = ({ classNames, col = 'grey', isShort = false, size = 'regular' }: Props) => {
  const isWhite = col === 'white';

  const kwaraLogo = isWhite ? logoWhite : logo;
  const id = isShort ? 'General.PoweredBy.short' : 'General.PoweredBy';

  const sizeClasses = cx({
    'kw-text-3x-small': size === 'small-3x',
    'kw-text-small': size === 'small'
  });
  const colorClass = isWhite ? 'white' : 'grey-400';

  const classNamesWrapper = cx(classNames, colorClass, sizeClasses);

  const imgClasses = cx([
    styles.logo,
    {
      [styles.regular]: size === 'regular',
      [styles.small]: size === 'small',
      [styles.small3x]: size === 'small-3x'
    }
  ]);

  return (
    <p className={classNamesWrapper}>
      <Text
        id={id}
        values={{
          logo: <img alt="Kwara Logo" className={imgClasses} src={kwaraLogo} />
        }}
      />
    </p>
  );
};
