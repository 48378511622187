// @flow

import * as React from 'react';

import { Personal as PersonalBase } from '../../../../../../../MemberAdd/components/Personal';
import * as recordValidations from '../../../../../../../../lib/recordValidations';

export function Personal(props) {
  return <PersonalBase {...props} size="stretch" prefix="member" />;
}

Personal.validate = {
  'member.dateOfBirth': {
    isRequired: () => true,
    ...recordValidations.dateOfBirth
  }
};
