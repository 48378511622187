// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import isArray from 'lodash/fp/isArray';

import { GlContexts, type BankGlAccountT, type LoanType, type MemberType } from '@kwara/models/src';
import LoanTransaction, { LoanTransactionTypes } from '@kwara/models/src/models/LoanTransaction';
import { formatHumanDate, formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text, Currency } from '@kwara/components/src/Intl';
import { SubscribedPaymentSelectField, SubscribedDatePicker, GlAccountSelect } from '@kwara/components/src/Form';
import { TransactionChannels, getTransactionTypes, contexts } from '@kwara/models/src/models/Transactions';

import { Grid } from '../../../components/Grid';
import MemberPanel from '../../../components/Payment/MemberPanel';
import { Panel } from '../../../components/ActionModal';
import { type SubStepComponentProps } from '../../../components/Wizard';

import { DummyTillSelect } from '../../Till';

export const showChequeFieldsFor = [TransactionChannels.cheque];
export const showBanksFieldsFor = [TransactionChannels.bankTransfer, TransactionChannels.cheque];

export const repaymentConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.LoanRepayment).values,
  showChequeFieldsFor,
  showBanksFieldsFor
};

export function findMaximumAmount(account: ?LoanType): ?number {
  return Number(get('totalBalance', account));
}

function getSelectedAccount({ accounts, accountId, loan }) {
  const selectedAccount = loan ? loan : find<LoanType>({ id: accountId }, accounts);

  return selectedAccount;
}

type ChequeDataT = {
  chequeNumber?: string,
  drawer?: string
};

type BankDataT = {
  bankName: string,
  bankBranch: string,
  accountNumber: string
};

type NotesT = {
  notes?: string
};

export type FormData = {
  member: MemberType,
  allGlAccounts: BankGlAccountT[],
  accounts: LoanType[],
  accountId: string,
  bankGlId?: string,
  saving?: LoanType,
  amount?: string,
  method?: string,
  reference?: string,
  glTransferId?: string
} & BankDataT &
  ChequeDataT &
  NotesT;

type Props = SubStepComponentProps<FormData>;

export async function makeRepayment(data: FormData) {
  const { accountId: loanId, method, glTransferId, valueDate, ...rest } = data;

  // TODO: This can perhaps be improved when ch14895 is actioned
  //
  const paymentMethod = method === 'glTransfer' ? glTransferId : method;

  const transaction = new LoanTransaction({
    type: LoanTransactionTypes.REPAYMENT,
    paymentMethod,
    loanId,
    ...(valueDate ? { valueDate: formatIsoDate(valueDate) } : {}),
    ...rest
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
}

function useOutstandingAmount(account) {
  const [outstanding, setOutstanding] = React.useState(0);
  React.useEffect(() => {
    let ignore = false;
    fetchOutstanding();

    async function fetchOutstanding() {
      setOutstanding(0);
      if (account) {
        const outstanding = await account.getOutstandingBalance();
        if (!ignore) {
          setOutstanding(outstanding);
        }
      }
    }

    return () => {
      ignore = true;
    };
  }, [account]);

  return outstanding;
}

export const RepaymentForm = ({ StackChild, SelectField, formProps, TextField, addData, data }: Props) => {
  const Option = SelectField.Option;

  const { member, accounts, loan, isTillOpen } = data;
  const { values } = formProps;
  const { method, accountId } = values;

  const isTillCashTransaction = isTillOpen && method === TransactionChannels.cash;

  const selectedAccount = getSelectedAccount({
    accounts,
    accountId,
    loan
  });

  const outstanding = useOutstandingAmount(selectedAccount);

  const hasAccountSelection = isArray(accounts);

  // Set `glAccounts` to the array of accounts that are
  // relevant for the selected payment method

  const maximumAmount = findMaximumAmount(selectedAccount);
  const disbursementDate = get('disbursementDate', selectedAccount);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />
      <Panel>
        <div className="bb b--light-grey-400 mb4">
          {hasAccountSelection ? (
            <SelectField name="accountId" size="medium" labelId="PaymentForm.account">
              {map<LoanType, React.Element<Option>>(
                account => (
                  <Option key={account.id} value={account.id}>
                    {account.id} &mdash; {account.product.name}
                  </Option>
                ),
                accounts
              )}
            </SelectField>
          ) : null}

          {!hasAccountSelection && selectedAccount ? (
            <Statistic
              size="medium"
              title={<Text id="PaymentForm.account" />}
              value={`${selectedAccount.id} — ${selectedAccount.product.name}`}
            />
          ) : null}

          {outstanding ? (
            <p className="grey-400 mb2  pt0 mt0 kw-text-regular">
              <Text id="PaymentForm.oustandingAmount" />
              {': '}
              <Currency format="currency" value={outstanding} />
            </p>
          ) : null}
        </div>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            required
            isCurrency
            type="number"
            name="amount"
            size="medium"
            leftGlyph="Currency.orgCurrency"
            labelId="PaymentForm.amount"
            info={maximumAmount ? <Text id="LoanRepayment.maximumAmountInfo" values={{ maximumAmount }} /> : null}
          />
          <SubscribedDatePicker
            name="valueDate"
            size="medium"
            labelId="SavingPayment.valueDate"
            inputPlaceholder={formatHumanDate(getCurrentDate())}
            disabledDays={{
              before: disbursementDate ? new Date(disbursementDate) : null,
              after: getCurrentDate()
            }}
          />
        </Grid>
        <SubscribedPaymentSelectField
          name="method"
          config={repaymentConfig}
          required
          inputOnChange={() => addData({ bankGlId: '' })}
        />

        {isTillCashTransaction ? (
          <DummyTillSelect />
        ) : (
          <GlAccountSelect context={GlContexts.REPAYMENT} method={method} addData={addData} />
        )}

        {method ? <TextField name="reference" size="medium" labelId="PaymentForm.reference" /> : null}
      </Panel>
    </StackChild>
  );
};

const ifCheque = {
  isRequired: (_, allData) => showChequeFieldsFor.includes(allData.method)
};

const ifChequeOrBank = {
  isRequired: (_, allData) => showBanksFieldsFor.includes(allData.method)
};

RepaymentForm.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, data) => {
      const { accounts, accountId, saving } = data;
      const selectedAccount = getSelectedAccount({
        accounts,
        accountId,
        saving
      });

      const maximumAmount = findMaximumAmount(selectedAccount);

      if (maximumAmount && Number(target) > maximumAmount) {
        return 'rangeOverflow';
      }

      return null;
    }
  },
  method: { isRequired: () => true },
  bankName: ifChequeOrBank,
  bankBranch: ifChequeOrBank,
  accountNumber: ifChequeOrBank,
  chequeNumber: ifCheque,
  drawer: ifCheque,
  ...GlAccountSelect.validate
};
