import React from 'react';
import noop from 'lodash/fp/noop';
import invoke from 'lodash/fp/invoke';

import { TillT } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import Table, { Heading, Row, Cell, Footer } from '../../../../components/Table';
import { ButtonMenu } from '../../../../components/ButtonMenu';

type Props = {
  tills: TillT[];
  isLoading: boolean;
  onRowClick: (till: TillT) => void;
};

function IconDropdown({ onClick }: { onClick: () => void }) {
  return (
    <ButtonMenu Button={props => <ActionButton {...props} hideBorder type="more" />}>
      <ButtonMenu.Item onClick={onClick}>
        <Text id="TillsManagement.Table.button.manageAccess" />
      </ButtonMenu.Item>
    </ButtonMenu>
  );
}

export function TillsManagementTable({ tills: unsortedTills = [], isLoading, onRowClick }: Props) {
  const tills = React.useMemo(() => {
    return unsortedTills.sort((tillA, tillB) => {
      const nameA = tillA.name.toUpperCase();
      const nameB = tillB.name.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });
  }, [unsortedTills]);

  return (
    <Table
      heading={
        <Row>
          <Heading translationId="TillsManagement.Table.header.till" />
          <Heading translationId="TillsManagement.Table.header.glCode" />
          <Heading translationId="TillsManagement.Table.header.teller" />
          <Heading iconSpacer />
        </Row>
      }
      footer={<Footer onNext={noop} colSpan={4} isLoading={isLoading} hasMore={false} items={tills} />}
    >
      {tills.map(till => (
        <Row key={till.id} icon={<IconDropdown onClick={() => onRowClick(till)} />}>
          <Cell>{till.name}</Cell>
          <Cell>{till.glAccountId}</Cell>
          <Cell>
            <ul className="list pa0">
              {till.tillTellerLinks.map(link => (
                <li key={link.id}>{invoke('teller.fullName', link)}</li>
              ))}
            </ul>
          </Cell>
        </Row>
      ))}
    </Table>
  );
}
