import { useState, useCallback, useEffect, useRef } from 'react';

import { ValueOf, KeyOf } from 'GlobalTypes';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

export const INTERNET_STATES = {
  DEFAULT: 'DEFAULT',
  GONE_OFFLINE: 'GONE_OFFLINE',
  BACK_ONLINE: 'BACK_ONLINE'
} as const;

const IDS = {
  DEFAULT: {
    titleId: '',
    msgId: ''
  },
  GONE_OFFLINE: {
    titleId: 'InternetNotifier.GoneOffline.titleId',
    msgId: 'InternetNotifier.GoneOffline.msgId'
  },
  BACK_ONLINE: {
    titleId: 'InternetNotifier.BackOnline.titleId',
    msgId: 'InternetNotifier.BackOnline.msgId'
  }
};

export function useInternetNotifier() {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [state, setState] = useState<ValueOf<typeof INTERNET_STATES>>(INTERNET_STATES.DEFAULT);
  const [shownNotifier, { setToFalse: onHideNotifier, setToTrue: onShowNotifier }] = useBoolean();

  const titleId = IDS[state as KeyOf<typeof IDS>].titleId;
  const msgId = IDS[state as KeyOf<typeof IDS>].msgId;

  const automaticallyHideNotifier = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => onHideNotifier(), 10000);
  }, [onHideNotifier]);

  /**
   * offline event
   */
  const offlineListener = useCallback(
    (_ev: Event) => {
      setState(INTERNET_STATES.GONE_OFFLINE);
      onShowNotifier();

      automaticallyHideNotifier();
    },
    [automaticallyHideNotifier, onShowNotifier]
  );

  useEffect(() => {
    window.addEventListener('offline', offlineListener);

    return () => {
      window.removeEventListener('offline', offlineListener);
      clearTimeout(timeoutIdRef.current);
    };
  }, [offlineListener]);

  /**
   * online event
   */
  const onlineListener = useCallback(
    (_ev: Event) => {
      setState(INTERNET_STATES.BACK_ONLINE);
      onShowNotifier();
      automaticallyHideNotifier();
    },
    [automaticallyHideNotifier, onShowNotifier]
  );

  useEffect(() => {
    window.addEventListener('online', onlineListener);

    return () => {
      window.removeEventListener('online', onlineListener);

      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, [offlineListener, onlineListener]);

  return {
    state,
    shownNotifier,
    titleId,
    msgId,
    onHideNotifier
  };
}
