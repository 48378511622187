import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type HeaderPropTypes = {
  titleId: string;
  children?: React.ReactNode;
  subtitleId?: string | null;
  values?: Record<string, string> | null;
};

export function Header({ titleId, subtitleId, values, children }: HeaderPropTypes) {
  return (
    <div className={styles.container}>
      {titleId && (
        <h1 className="kw-weight-bold kw-text-large neutral-600">
          <Text id={titleId} values={values} />
        </h1>
      )}
      {subtitleId && (
        <p className="kw-weight-regular kw-text-medium neutral-700">
          <Text id={subtitleId} values={values} />
        </p>
      )}
      {children}
    </div>
  );
}
