import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { OperationalReportT } from '@kwara/models/src';

import Visible from '../../components/Visible';
import OperationalReports from '../../components/OperationalReports';

import { Form, Payload } from './components/Form';
import { BodyContentWrapper } from '../../layouts';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { useOperationalReportsView } from '../../components/OperationalReports/useOperationalReportsView';

type Props = {
  reports: OperationalReportT[];
  isLoadingReports: boolean;
  hasMoreReports: boolean;
  onNext(): void;
  onReportDownload(report: OperationalReportT): void;
  isReportDownloading: boolean;
  onGenerate(data: Payload): void;
};

export function Layout({
  reports,
  hasMoreReports,
  isLoadingReports,
  isReportDownloading,
  onNext,
  onReportDownload,
  onGenerate
}: Props) {
  const { AppPermissions } = usePermissions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Root>
          <Visible to={AppPermissions.CreateMemberListingReports}>
            <Form onGenerate={onGenerate} />
          </Visible>
        </BodyContentWrapper.Row.Root>

        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id={'MemberListingReports.ReportsIndex.title'} />
          </div>
          <OperationalReports.Table
            reports={reports}
            isLoading={isLoadingReports}
            hasMore={hasMoreReports}
            onNext={onNext}
            onDownload={onReportDownload}
            isDownloading={isReportDownloading}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function MemberListingReports() {
  const {
    allReports,
    isLoadingReports,
    hasMoreReports,
    onNext,
    onGenerate,
    onDownload,
    isReportDownloading
  } = useOperationalReportsView('client_listing_report');

  return (
    <Layout
      reports={allReports}
      hasMoreReports={hasMoreReports}
      isLoadingReports={isLoadingReports}
      isReportDownloading={isReportDownloading}
      onNext={onNext}
      onReportDownload={onDownload}
      onGenerate={onGenerate}
    />
  );
}
