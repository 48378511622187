import * as React from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { Text, getTranslationIdByApp } from '@kwara/components/src/Intl';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { Logger } from '@kwara/lib/src/logger';

import { getRepaymentTerms } from './utils';

type Props = LoanSubstepProps;
export function DurationField(props: Props) {
  const { TextField, Condition, data, formProps } = props;
  const { values } = formProps;
  const { repaymentPeriodUnit } = values;
  const { maxDurationUnit, maxDurationValue, minDurationUnit, minDurationValue } = getRepaymentTerms(data);

  // If the api returns a maxDurationUnit and a repaymentPeriodUnit,
  // they  should always be the same. If not, we have a problem.
  if (repaymentPeriodUnit && maxDurationUnit && repaymentPeriodUnit !== maxDurationUnit) {
    Logger.error('Repayment Unit does not equal to Maximum duration Unit');
  }

  const installmentsInfo = (
    <>
      {maxDurationValue ? (
        <>
          <Text
            id={`LoanAdd.Configure.maxDuration.${maxDurationUnit}`}
            values={{
              value: maxDurationValue,
              unit: maxDurationUnit
            }}
          />
          <br />
        </>
      ) : null}
      {minDurationValue ? (
        <>
          <Text
            id={`LoanAdd.Configure.minDuration.${minDurationUnit}`}
            values={{
              value: minDurationValue,
              unit: minDurationUnit
            }}
          />
          <br />
        </>
      ) : null}
      <Condition when="repaymentPeriod" not="">
        {({ input: { value: repaymentPeriod } }) => (
          <Condition when="repaymentPeriodUnit" not="">
            <Condition when="loanDuration" not="">
              {({ input: { value: loanDuration } }) => (
                <Text
                  id="LoanAdd.Configure.installments"
                  values={{
                    num: calculateNumberInstallments({
                      period: repaymentPeriod,
                      loanDuration
                    })
                  }}
                />
              )}
            </Condition>
          </Condition>
        )}
      </Condition>
    </>
  );

  return (
    <TextField
      name="loanDuration"
      required={true}
      type="number"
      errorBaseId="LoanAdd.Configure.LoanDuration"
      labelId={getTranslationIdByApp('LoanAdd.Configure.LoanDuration.title')}
      rightGlyph={`LoanAdd.Configure.${repaymentPeriodUnit}`}
      info={installmentsInfo}
    />
  );
}
