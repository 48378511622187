import { ValueOf, ModelError } from 'GlobalTypes';

import { _api } from '../../../../../services';

const PRIMARY_ID_DOCUMENT_INDEX = 0;

const TYPES = {
  POSTING_STARTED: '@core/members/verify_identity/POSTING_STARTED',
  POSTING_SUCCESSFUL: '@core/members/verify_identity/POSTING_SUCCESSFUL',
  POSTING_FAILED: '@core/members/verify_identity/POSTING_FAILED',
  RESET: '@core/members/verify_identity/RESET'
} as const;

export const ERROR_CODES = Object.freeze({
  RETRY: 'RETRY_ERROR',
  GENERIC: 'GENERIC_ERROR',
  IDENTITY_TAKEN: 'IDENTITY_TAKEN_ERROR',
  NOT_FOUND: 'IDENTITY_NOT_FOUND_ERROR'
});

type DataType = Record<string, unknown> | null;

type ErrorType = {
  hasError: boolean;
  code: string | null;
  namesUsingId: string | null;
  retry: boolean;
};

type CacheType = {
  actionType: 'RETRY' | 'VERIFY' | null;
  documentId: string;
};

type ActionType = {
  type: ValueOf<typeof TYPES>;
  payload?: Partial<{
    data: DataType;
    cache: CacheType;
    error: ErrorType;
  }>;
};

export type StateType = {
  isLoading: boolean;
  data: DataType;
  cache?: CacheType;
  error?: ErrorType;
};

const initialState: StateType = {
  isLoading: false,
  data: null,
  cache: {
    actionType: null,
    documentId: null
  },
  error: {
    hasError: false,
    code: null,
    retry: false,
    namesUsingId: null
  }
};

/**
 * startVerifying arguments types
 */
type StartVerifyingDataArg = {
  firstName: string;
  lastName: string;
  idDocuments: {
    type: string;
    documentId: string;
    [key: string]: any;
  }[];
};

type StartVerifyingActionArg = {
  type?: 'RETRY' | 'VERIFY';
  onIsLoading?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
};

/**
 * this is an id-verification manager built
 * using Singleton pattern which ensures the
 * verification state is tracked during retries
 */
class VerifyIdentity {
  private static instance: VerifyIdentity;

  constructor() {
    this.resetState = this.resetState.bind(this);
    this.startVerifying = this.startVerifying.bind(this);
    this.dispatchAction = this.dispatchAction.bind(this);
  }

  public static getInstance(): VerifyIdentity {
    if (!VerifyIdentity.instance) {
      VerifyIdentity.instance = new VerifyIdentity();
    }

    return VerifyIdentity.instance;
  }

  public state = { ...initialState };

  private dispatchAction(action: ActionType) {
    switch (action.type) {
      case TYPES.POSTING_STARTED:
        this.state.isLoading = true;
        this.state.cache = action.payload.cache;
        break;

      case TYPES.POSTING_SUCCESSFUL:
        this.state.isLoading = initialState.isLoading;
        this.state.data = action.payload.data;
        this.state.error = initialState.error;
        break;

      case TYPES.POSTING_FAILED:
        this.state.isLoading = initialState.isLoading;
        this.state.data = initialState.data;
        this.state.error = action.payload.error;
        this.state.error.hasError = true;
        throw this.state;

      case TYPES.RESET:
        this.state.isLoading = false;
        this.state.data = initialState.data;
        this.state.error = initialState.error;
        this.state.cache = initialState.cache;
        break;

      default:
        throw `Invalid action type "${action.type}"`;
    }
  }

  private handlerServerError(errors: ModelError[], mustRetry: boolean, GENERIC_ERROR_KEY: string) {
    const [lowerBoundError] = errors;
    const itsKYCError = ([ERROR_CODES.IDENTITY_TAKEN, ERROR_CODES.NOT_FOUND] as string[]).includes(
      lowerBoundError.code
    );

    this.dispatchAction({
      type: TYPES.POSTING_FAILED,
      payload: {
        error: ({
          retry: !itsKYCError && mustRetry,
          namesUsingId: itsKYCError ? lowerBoundError.detail : null,
          code: itsKYCError ? lowerBoundError.code : GENERIC_ERROR_KEY
        } as unknown) as ErrorType
      }
    });
  }

  private handleNonServerError(mustRetry: boolean, GENERIC_ERROR_KEY: string) {
    this.dispatchAction({
      type: TYPES.POSTING_FAILED,
      payload: {
        error: { code: GENERIC_ERROR_KEY, retry: mustRetry, namesUsingId: null } as ErrorType
      }
    });
  }

  public async startVerifying(data: StartVerifyingDataArg, action?: StartVerifyingActionArg) {
    /**
     * Ones we notice that the previous error was a generic one,
     * we resolve the promise to allow the user to proceed.
     *
     * Based on how the Wizard handles resolved promise at the time
     * of writing this program,we expect the wizard to programmatically
     * proceed to the next page
     */
    if (this.state.error.code === ERROR_CODES.GENERIC) return Promise.resolve(null);

    action?.onIsLoading?.();

    const { firstName, lastName, idDocuments } = data;
    const documentId = idDocuments[PRIMARY_ID_DOCUMENT_INDEX].documentId;
    const documentType = idDocuments[PRIMARY_ID_DOCUMENT_INDEX].type;

    this.dispatchAction({
      type: TYPES.POSTING_STARTED,
      payload: { cache: { actionType: action?.type ?? 'VERIFY', documentId } }
    });

    try {
      /**
       * Based on how the Wizard handles resolved promise at the time
       * of writing this program,we expect the wizard to programmatically
       * proceed to the next page
       */
      await _api.httpPostRequest('/members/verify_identity', {
        data: {
          attributes: {
            first_name: firstName,
            last_name: lastName,
            type: documentType,
            document_id: documentId
          }
        }
      });

      this.dispatchAction({ type: TYPES.POSTING_SUCCESSFUL, payload: { data: null } });

      action?.onSuccess?.();
    } catch (error) {
      action?.onFailure?.();

      const errors: ModelError[] | undefined = error.response.data?.errors;
      const mustRetry = this.state.cache.actionType === 'VERIFY' && (action?.type === 'RETRY' || !action?.type);
      const GENERIC_ERROR_KEY = mustRetry ? ERROR_CODES.RETRY : ERROR_CODES.GENERIC;

      if (errors) this.handlerServerError(errors, mustRetry, GENERIC_ERROR_KEY);
      else this.handleNonServerError(mustRetry, GENERIC_ERROR_KEY);
    }
  }

  public resetState() {
    this.dispatchAction({ type: TYPES.RESET });
  }
}

VerifyIdentity.getInstance();

const verifyIdentityInstance = new VerifyIdentity();

export default verifyIdentityInstance;
