// @flow

import * as React from 'react';

import { LoanType } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Currency, Text } from '@kwara/components/src/Intl';
import Empty from '@kwara/components/src/Empty';

export const Income = ({ loan }: { loan: LoanType }) => {
  const application = loan.loanApplication;

  if (!application) {
    return (
      <Empty>
        <Text id="LoanDetail.Income.empty" />
      </Empty>
    );
  }

  return (
    <section>
      <StatisticRow widthClass="w-25">
        <Statistic
          title={<Text id="LoanDetail.Income.grossIncome" />}
          value={<Currency value={application.grossIncome} />}
        />
        <Statistic
          title={<Text id="LoanDetail.Income.netIncome" />}
          value={<Currency value={application.netIncome} />}
        />
        <Statistic
          title={<Text id="LoanDetail.Income.otherIncome" />}
          value={<Currency value={application.otherIncomeAmount} />}
        />
        <Statistic title={<Text id="LoanDetail.Income.otherIncomeSource" />} value={application.incomeSource} />
      </StatisticRow>
      <StatisticRow widthClass="w-25">
        <Statistic
          title={<Text id="LoanDetail.Income.otherDeductibles" />}
          value={<Currency value={application.otherDeductibles} />}
        />
        <Statistic
          title={<Text id="LoanDetail.Income.employmentStatus" />}
          value={<Text id={`Employment.Type.${application.employmentStatus}`} />}
        />
        <Statistic title={<Text id="LoanDetail.Income.termsOfEmployment" />} value={application.termsOfService} />
        <Statistic
          title={<Text id="LoanDetail.Income.disposableIncome" />}
          value={<Currency value={application.disposableIncomeAmount} />}
        />
      </StatisticRow>
    </section>
  );
};
