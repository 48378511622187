import React from 'react';

import { useFeesForm } from '../useFeesForm';
import { AddFeeForm } from './AddFeeForm';
import { SelectedFees } from './SelectedFees';

export const Fees = ({ data, onChange, formProps }) => {
  const { product, feeApplications } = data;
  const { amount } = formProps.values;

  const {
    fees,
    selectedFees,
    getFee,
    getFeeApplication,
    onRemove,
    isFeeOptional,
    isFeeNotSelected,
    onFeeAdd
  } = useFeesForm({
    product,
    feeApplications,
    onChange,
    amount
  });

  const feesToShow = fees.filter(isFeeOptional).filter(isFeeNotSelected);

  return (
    <>
      <SelectedFees fees={selectedFees} getFeeApplication={getFeeApplication} onRemoveFee={onRemove} />
      <AddFeeForm fees={feesToShow} getFee={getFee} onAddFee={onFeeAdd} />
    </>
  );
};
