import React from 'react';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import groupBy from 'lodash/fp/groupBy';

import { LoanSubstepProps } from 'GlobalTypes';

import TabNavigation from '../../../../../components/TabNavigation';

import { Text } from '@kwara/components/src/Intl';
import { capitalizeSlug } from '@kwara/lib/src/utils/capitalizeSlug';
import { If } from '@kwara/components/src/If/If';
import { LoanApplicationNoteT } from '@kwara/models/src/models/LoanApplicationNote';

import styles from './index.module.scss';

const NotesContent = ({ notes, titleId }: { notes: LoanApplicationNoteT[]; titleId: string }): React.ReactElement => {
  const hasNotes = size(notes) > 0;
  return (
    <section className={styles.Section}>
      <h3 className={`ma0 kw-text-large kw-weight-bold pb2`}>
        <Text id={titleId} />
      </h3>
      <div className="pl1">
        <If
          condition={hasNotes}
          do={map(
            note => (
              <li className="ma0 mb1 neutral-500 kw-text-medium" key={note.id}>
                {capitalizeSlug(note.step)}
                <div className="pl4 primary-500">{note.value}</div>
              </li>
            ),
            notes
          )}
          else={<Text id={'LoanDisbursal.PreviousNotes.noNotes'} />}
        />
      </div>
    </section>
  );
};
function PreviousNotes({ data }: LoanSubstepProps) {
  const { loan } = data;
  const loanApplicationNotes = loan?.loanApplication?.loanApplicationNotes;
  const flowGroup = groupBy('flow', loanApplicationNotes);

  const tabConfig = [
    {
      title: <Text id={'LoanDisbursal.PreviousNotes.loanApplicationNotes.title'} />,
      content: (
        <NotesContent
          notes={flowGroup?.loan_application}
          titleId={'LoanDisbursal.PreviousNotes.loanApplicationNotes.title'}
        />
      )
    },
    {
      title: <Text id={'LoanDisbursal.PreviousNotes.loanAppraisalNotes.title'} />,
      content: (
        <NotesContent
          notes={flowGroup?.loan_appraisal}
          titleId={'LoanDisbursal.PreviousNotes.loanAppraisalNotes.title'}
        />
      )
    }
  ];

  return <TabNavigation.SwitchTab tabConfig={tabConfig} />;
}

export default {
  Component: PreviousNotes,
  validate: {}
};
