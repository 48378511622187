import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import styles from '../index.module.scss';

type PrimitiveTfootPropTypes = React.ComponentPropsWithoutRef<'tfoot'>;
type HeadElement = React.ElementRef<'tfoot'>;
interface HeadPropTypes extends PrimitiveTfootPropTypes {
  bases: {
    id: string;
    name: React.ReactNode;
    itsI18n?: boolean;
    rowSpan?: number;
    colSpan?: number;
    width?: string;
    condition?: boolean;
  }[];
}

export const Footer = React.forwardRef<HeadElement, HeadPropTypes>(function Head(
  { className, bases, ...restProps },
  forwardedRef
) {
  return (
    <tfoot {...restProps} ref={forwardedRef} className={cx(styles['lister-footer'], className)}>
      <tr>
        {bases
          .filter(({ condition = true }) => condition)
          .map(({ id, itsI18n, name, ...restProps }) => (
            <th key={id} scope="col" {...restProps}>
              <If condition={!!itsI18n} do={<Text id={name as string} />} else={name} />
            </th>
          ))}
      </tr>
    </tfoot>
  );
});
