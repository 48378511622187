import get from 'lodash/fp/get';
import first from 'lodash/fp/first';
import pipe from 'lodash/fp/pipe';

import { useLoanProducts } from '@kwara/models/src/models/request/hooks';
import { useMemberLoanProductStanding } from '../models/hooks';

const includes = [];
export const useLoanProductStandingWithMemberIdOnly = (memberId: string) => {
  const r = useLoanProducts(includes, 10);
  const firstProductId = pipe(get('data'), first, get('id'))(r);
  return useMemberLoanProductStanding(memberId, firstProductId, { enabled: !!firstProductId });
};
