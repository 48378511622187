interface ClientStorageI {
  get<R = any>(key: string): R | null;
  set<V = any>(key: string, value: V): void;
  remove(key: string): void;
}

/**
 * @ClientStorage a localStorage high-level API that
 * handles the serialization and deserialization for us
 */
export class ClientStorage implements ClientStorageI {
  public set<V = any>(key: string, value: V) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public get<R = any>(key: string) {
    const value = window.localStorage.getItem(key);

    if (value == null) return null;

    return JSON.parse(value) as R;
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }
}

export const clientStorage = new ClientStorage();
