import * as React from 'react';
import map from 'lodash/fp/map';
import keys from 'lodash/fp/keys';

export const Triggers = ({ StackChild, Checkbox, data }) => {
  return (
    <StackChild>
      {map(
        trigger => (
          <Checkbox name={`sms_triggers.${trigger}`} labelId={`SmsTriggerEdit.form.option.${trigger}`} />
        ),
        keys(data.sms_triggers)
      )}
    </StackChild>
  );
};

Triggers.validate = {};
