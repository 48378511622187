//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

const Reject = ({ RadioGroup, StackChild, TextArea }: SubStepComponentProps<>) => {
  return (
    <StackChild>
      <RadioGroup
        flex={false}
        name="rejectType"
        items={[
          {
            labelId: 'LoanApprove.Reject.Type.soft.label',
            titleId: 'LoanApprove.Reject.Type.soft.title',
            value: 'SOFT'
          },
          {
            labelId: 'LoanApprove.Reject.Type.hard.label',
            titleId: 'LoanApprove.Reject.Type.hard.title',
            value: 'HARD'
          }
        ]}
      />

      <TextArea name="comment" labelId="LoanApprove.Reject.Note.label" infoId="LoanApprove.Reject.Note.info" />
    </StackChild>
  );
};

Reject.validate = {
  rejectType: {
    isRequired: () => true
  }
};

export default Reject;
