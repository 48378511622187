import * as React from 'react';
import map from 'lodash/map';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import { NotFound404 } from '@kwara/components/src/NotFound404';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import SubStep from '../SubStep/SubStep';
import stepParser from '../../utils/stepParser';
import { useWizardInternalContext } from '../..';
import { LeftNavigation } from '../WizardBody/components/LeftProgress';
import { StepId, RenderableStep } from '../../types/sharedTypes';

const pathFor = (baseUrl: string, path: string) => `${baseUrl}/${path}`;
const RedirectWithQueryString = withRouter(({ to, location, ...rest }: any) => (
  <Redirect {...rest} to={{ ...location, pathname: to }} />
));

export function WizardBody() {
  const {
    data,
    currentStep,
    currentSubStep,
    steps,
    startId,
    baseUrl,
    error,
    isProcessing,
    onChange,
    handleAction,
    onError
  } = useWizardInternalContext();

  const { subSteps, firstSubStepId, ordered } = stepParser({ steps, startId });

  return (
    <Switch>
      {firstSubStepId && <RedirectWithQueryString from={baseUrl} to={pathFor(baseUrl, firstSubStepId)} exact />}

      {map(subSteps, (subStep: RenderableStep, id: StepId) => {
        const stepPath = pathFor(baseUrl, id);
        const isPermissionDenied = subStep.isPermitted === false;

        return (
          <Route
            key={stepPath}
            path={stepPath}
            render={() => (
              <If
                condition={isPermissionDenied}
                do={<PermissionDenied />}
                else={
                  <SubStep
                    fullPage
                    error={error}
                    subStep={subStep}
                    parentUrl={baseUrl}
                    setError={onError}
                    currentState={data}
                    onChange={onChange}
                    onAction={handleAction}
                    isProcessing={isProcessing}
                    leftNavigation={
                      <LeftNavigation
                        ordered={ordered}
                        subSteps={subSteps}
                        parentUrl={baseUrl}
                        currentStep={currentStep}
                        currentSubStep={currentSubStep}
                      />
                    }
                  />
                }
              />
            )}
          />
        );
      })}
      <Route path="/" component={NotFound404} />
    </Switch>
  );
}
