import * as React from 'react';
import map from 'lodash/fp/map';

import { Currency, Date, DateTime } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text } from '@kwara/components/src/Intl';

import { useJournalEntry } from '../../../../../hooks';
import Table, { Heading, Row, Cell, Footer, Collapsible } from '../../../../../components/Table';

const JournalEntryDetails = ({ transactionId }) => {
  const r = useJournalEntry(transactionId);

  return (
    <Row className="bg-light-grey-300">
      <Loadable {...r}>
        {t => (
          <>
            <Cell colSpan={2}>
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.accountId" />}
                value={t.accountId()}
              />
            </Cell>
            <Cell colSpan={2}>
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.memberId" />}
                value={t.memberId()}
              />
              <Statistic
                size="small"
                title={<Text id="Finance.JournalEntries.Table.memberName" />}
                value={t.memberName()}
              />
            </Cell>
            <Cell colSpan={4}>
              <Statistic size="small" title={<Text id="Finance.JournalEntries.Table.notes" />} value={t.notes} />
            </Cell>
          </>
        )}
      </Loadable>
    </Row>
  );
};

export const JournalEntriesTable = ({ transactions, ...r }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading width="100px" translationId="Finance.JournalEntries.Table.header.id" />
          <Heading translationId="Finance.JournalEntries.Table.header.valueDate" />
          <Heading translationId="Finance.JournalEntries.Table.header.createdAt" />
          <Heading translationId="Finance.JournalEntries.Table.header.transactionID" />
          <Heading translationId="Finance.JournalEntries.Table.header.glAccount" />
          <Heading translationId="Finance.JournalEntries.Table.header.branch" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.debit" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.credit" />
          <Heading iconSpacer />
        </Row>
      }
      footer={<Footer colSpan={9} {...r} items={transactions} />}
    >
      {map(
        t => (
          <Collapsible key={t.id}>
            <Row>
              <Cell>{t.id}</Cell>
              <Cell>
                <Date value={t.bookingDate} />
              </Cell>
              <Cell>
                <DateTime value={t.createdAt} />
              </Cell>
              <Cell>{t.transactionId}</Cell>
              <Cell>
                {t.glAccount.id} <br />
                <span className="grey-400">{t.glAccount.name}</span>
              </Cell>
              <Cell>{t.branch?.name}</Cell>
              <Cell align="right">
                {t.type === 'DEBIT' ? <Currency hideDecimals={false} value={t.amount} /> : null}
              </Cell>
              <Cell align="right">
                {t.type === 'CREDIT' ? <Currency hideDecimals={false} value={t.amount} /> : null}
              </Cell>
            </Row>
            <JournalEntryDetails transactionId={t.id} />
          </Collapsible>
        ),
        transactions
      )}
    </Table>
  );
};
