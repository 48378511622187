import * as React from 'react';
import map from 'lodash/fp/map';
import range from 'lodash/fp/range';
import { getCurrentYear, getYear, isAfter, formatHumanDate, getCurrentDate } from '@kwara/lib/src/dates';

import { Grid } from '../../../components/Grid';
import { SubscribedDatePicker as DatePicker } from '../../../components/Form';

// The feature went live on this date, so reports
// are only possible from this date.
const REPORTS_VALID_FROM = new Date('2021-08-25');

function getValidYears() {
  const firstValidYear = getYear(REPORTS_VALID_FROM);
  return range(firstValidYear, getCurrentYear() + 1);
}

export const Form = ({ StackChild, SelectField, formProps }) => {
  const { values } = formProps;
  const { startDate } = values;

  return (
    <StackChild>
      <Grid columns={2} width="w-50" border={false}>
        <SelectField required name="financialYear" labelId="SasraReport4BGenerate.Form.financialYear.label">
          {map(
            year => (
              <SelectField.Option key={year} value={year}>
                {year}
              </SelectField.Option>
            ),
            getValidYears()
          )}
        </SelectField>
      </Grid>
      <Grid columns={2} width="w-50" border={false}>
        <DatePicker
          required
          name="startDate"
          labelId="SasraReport4BGenerate.Form.startDate.label"
          disabledDays={{ before: REPORTS_VALID_FROM, after: getCurrentDate() }}
        />
        <DatePicker
          required
          name="endDate"
          labelId="SasraReport4BGenerate.Form.endDate.label"
          disabledDays={{ before: REPORTS_VALID_FROM, after: getCurrentDate() }}
          errorValues={{
            fromDate: formatHumanDate(startDate)
          }}
        />
      </Grid>
    </StackChild>
  );
};

Form.validate = {
  financialYear: {
    isRequired: () => true
  },
  startDate: {
    isRequired: () => true
  },
  endDate: {
    isRequired: () => true,
    custom: (endDate, { startDate }) => {
      if (!startDate) {
        return;
      }

      if (isAfter(startDate, endDate)) {
        return 'isBefore';
      }
    }
  }
};
