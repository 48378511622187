//@flow

import * as React from 'react';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import filter from 'lodash/fp/filter';
import forEach from 'lodash/fp/forEach';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { useSavingProducts } from '../../../../hooks';

import { type SubStepComponentProps } from '../../../../components/Wizard';
import { getName, type WizardData } from '../..';

const useSelectedProducts = data => {
  const { accounts = [] } = data;
  const r = useSavingProducts();

  const allProducts = r.data || [];
  const selectedProducts = filter(p => includes(p.id, accounts), allProducts);

  return {
    hasProducts: size(selectedProducts) > 0,
    selectedProducts,
    ...r
  };
};

const Deposits = ({ StackChild, TextField, data }: SubStepComponentProps<WizardData>) => {
  const { selectedProducts = [], hasProducts, ...r } = useSelectedProducts(data);

  return (
    <StackChild>
      <Loadable {...r}>
        {!hasProducts ? (
          <p>
            <Text id="MemberApprove.Deposits.noProduct" />
          </p>
        ) : (
          map(product => {
            const name = getName(product.id);
            return (
              <div>
                <h3 className="kw-super-large kw-weight-bold">{product.name}</h3>
                <TextField
                  errorBaseId="MemberApprove.Deposits.Amount"
                  leftGlyph="Currency.orgCurrency"
                  name={`${name}.amount`}
                  labelId="MemberApprove.Deposits.amountLabel"
                  placeholderId="MemberApprove.Deposits.amountPlaceholder"
                />
                <TextField name={`${name}.notes`} labelId="MemberApprove.Deposits.referenceLabel" />
              </div>
            );
          }, selectedProducts)
        )}
      </Loadable>
    </StackChild>
  );
};

Deposits.validate = data => {
  const { accounts } = data;

  const validation = {};
  forEach(productId => {
    validation[`${getName(productId)}.amount`] = {
      currency: true
    };
  }, accounts);
  return validation;
};

export default Deposits;
