import get from 'lodash/fp/get';
import compact from 'lodash/fp/compact';
import { minus, addMany } from '@kwara/lib/src/currency';

export function getIncomeValues(args: { netIncome: string; otherIncomeAmount: string; otherDeductibles: string }) {
  const totalMonthlyNetIncome = addMany(compact([get('netIncome', args), get('otherIncomeAmount', args)]));
  const totalAvailableForRepayment = minus(totalMonthlyNetIncome, get('otherDeductibles', args) || 0);

  return { totalMonthlyNetIncome, totalAvailableForRepayment };
}
