import * as React from 'react';
import { useIntl } from 'react-intl';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import {
  CustomizedResponsiveContainer,
  CustomizedYAxisTick,
  CustomizedXAxisTick,
  customizedMarginSetup,
  renderCustomizedTooltip,
  renderCustomizedLegend
} from '../Customized';

import styles from '../../index.module.scss';

const MOCKED_DATA = [
  { date: '2022-01-05', income: '1000', expense: '2000' },
  { date: '2022-02-10', income: '2000', expense: '400' },
  { date: '2022-03-15', income: '3000,50', expense: '300' },
  { date: '2022-04-20', income: '4000', expense: '60.25' },
  { date: '2022-05-25', income: '5000.200', expense: '100' },
  { date: '2022-06-26', income: '8000.50', expense: '500' },
  { date: '2022-07-27', income: '6000', expense: '700' },
  { date: '2022-08-28', income: '2500.50', expense: '300' },
  { date: '2022-09-29', income: '10000', expense: '600' },
  { date: '2022-10-20', income: '5000', expense: '1000' }
];

export function IncomeAndExpense() {
  const int = useIntl();

  return (
    <CustomizedResponsiveContainer className={styles['h-508']}>
      <BarChart barSize={22} barGap={0} data={MOCKED_DATA} margin={customizedMarginSetup}>
        <CartesianGrid strokeDashoffset="1 6" stroke="#F0F0F0" />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={props => <CustomizedXAxisTick {...props} />}
        />
        <YAxis axisLine={false} tickLine={false} tick={props => <CustomizedYAxisTick {...props} />} />
        <Tooltip content={props => renderCustomizedTooltip({ ...props, isCurrency: true })} />
        <Legend content={renderCustomizedLegend} verticalAlign="top" />
        <Bar
          dataKey="income"
          stackId="income"
          fill="#aaf0c4"
          radius={3}
          name={int.formatMessage({ id: 'Dashboard.IncomeAndExpense.income.name' })}
        />
        <Bar
          dataKey="expense"
          stackId="expense"
          fill="#F2D1CE"
          radius={3}
          name={int.formatMessage({ id: 'Dashboard.IncomeAndExpense.expenses.name' })}
        />
      </BarChart>
    </CustomizedResponsiveContainer>
  );
}
