import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { LoanSubstepProps, LoanFormData } from 'LoanSharedTypes';
import { LoanAppraisalLocationParams } from 'LoanAppraisalTypes';

import Wizard from '@kwara/components/src/Wizard/latest';

import { LoanType } from '@kwara/models/src/models/Loan';
import { Loadable } from '@kwara/components/src/Loadable';
import { TransactionChannelT } from '@kwara/models/src/models/Transactions';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';
import { useFullLoan } from '@kwara/models/src/models/request/hooks';

import { steps } from './config';
import { loanPath } from '../../lib/urls';
import { onDisburse } from './onDisburse';
import { Store } from '../../models/Store/Store';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

type LoanTopupDisbursalLocationParams = LoanAppraisalLocationParams;

export interface LoanTopupDisbursalData extends LoanFormData {
  fullName: string;
  floatBalance: number | null;
  transactionAmountLimit: number;
  payoutProvider: string | null;
  paymentMethod?: TransactionChannelT;
  phoneNumberProvider: string | null;
  feeApplications: Array<FeeApplicationT>;
  anticipatedPayout?: string;
}

export interface LoanTopupDisbursalStepPropTypes extends LoanSubstepProps<LoanTopupDisbursalData> {}

export default function LoanTopupDisbursal() {
  const [disbursementState, setDisbursementState] = useState(null);
  const queryClient = useQueryClient();
  const params = useParams<LoanTopupDisbursalLocationParams>();
  const loanR = useFullLoan(params.loanId);
  const {
    organisationSettings: { mobilePayoutSettings }
  }: Store = useSaccoProfileContext();

  return (
    <Loadable {...loanR}>
      {(loan: LoanType) => (
        <Wizard
          steps={steps}
          startId="review"
          analyticsId="LoanTopupDisbursal"
          titleId="LoanTopup.Disbursal.title"
          baseUrl={`/loans/${params.loanId}/topupDisbursal`}
          onSubmit={(data: Partial<LoanFormData>) => onDisburse(data, setDisbursementState)}
          currentStep={params.step}
          cancelReturnsTo={loanPath()}
          completionAutoConfirm={false}
          currentSubStep={params.subStep != undefined ? parseInt(params.subStep, 10) : null}
          initialData={{
            feeApplications: [],
            loan,
            fullName: loan.member.fullName(),
            phoneNumberProvider: null,
            floatBalance: null,
            payoutProvider: mobilePayoutSettings.payout_provider,
            transactionAmountLimit: mobilePayoutSettings.transaction_amount_limit
          }}
          onSubmitCompletion={() => queryClient.removeQueries('loan')}
          successSubtitleId={`LoanDisbursal.Approvals.completion.subtitle.${disbursementState}`}
        />
      )}
    </Loadable>
  );
}
