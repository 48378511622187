// @flow

import React from 'react';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';

import { Panel as PanelBase } from '../../../components/ActionModal';
import { useSavingProducts } from '../../../hooks';

const Panel = ({ children, headerId }) => {
  return (
    <PanelBase>
      <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
        <Text id={headerId} />
      </h2>
      {children}
    </PanelBase>
  );
};

// WIP
export const ProductForm = ({
  StackChild,
  TextField,
  SelectField,
  translationBaseId = 'LoanProductAdd'
}: {
  translationBaseId?: 'LoanProductAdd' | 'LoanProductEdit'
}) => {
  const r = useSavingProducts();

  return (
    <StackChild>
      <Panel headerId={`${translationBaseId}.Product.loanRestrictions.fullText`}>
        <Loadable {...r}>
          {products => {
            return (
              <SelectField
                name="productConfig.guarantorMultiplierSavingsProductId"
                labelId={`${translationBaseId}.Product.guarantorMultiplierAccount.labelId`}
              >
                <SelectField.Option
                  translationId={`${translationBaseId}.Product.guarantorMultiplierAccount.default.labelId`}
                />
                {map(
                  product => (
                    <SelectField.Option key={product.id} value={product.id}>
                      {product.name}
                    </SelectField.Option>
                  ),
                  products
                )}
              </SelectField>
            );
          }}
        </Loadable>

        <TextField
          name="productConfig.guarantorMultiplierFactor"
          labelId={`${translationBaseId}.Product.guarantorMultiplierFactor.labelId`}
        />
      </Panel>
    </StackChild>
  );
};

ProductForm.validate = {
  'productConfig.guarantorMultiplierFactor': {
    currency: true
  }
};
