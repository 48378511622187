import { attr, hasOne } from 'spraypaint';
import { ValueOf } from 'GlobalTypes';

import cameliseObjectKeys from '../lib/cameliseObjectKeys';
import { toDurationObjectUI, UIDurationObjectT } from './util';
import Base, { BaseModel } from './Base';
import { LoanProductConfig, LoanProductConfigT } from './LoanProductConfig';

export const ProductTypes = Object.freeze({
  FIXED_TERM_LOAN: 'FIXED_TERM_LOAN',
  DYNAMIC_TERM_LOAN: 'DYNAMIC_TERM_LOAN',
  INTEREST_FREE_LOAN: 'INTEREST_FREE_LOAN',
  TRANCHED_LOAN: 'TRANCHED_LOAN',
  REVOLVING_CREDIT: 'REVOLVING_CREDIT'
});
type ProductTypesT = ValueOf<typeof ProductTypes>;

export const ChargeFrequencies = Object.freeze({
  ANNUALIZED: 'ANNUALIZED',
  EVERY_MONTH: 'EVERY_MONTH',
  EVERY_FOUR_WEEKS: 'EVERY_FOUR_WEEKS',
  EVERY_WEEK: 'EVERY_WEEK',
  EVERY_DAY: 'EVERY_DAY'
});
type ChargeFrequencyT = ValueOf<typeof ChargeFrequencies>;

export const InterestApplicationMethods = Object.freeze({
  AFTER_DISBURSEMENT: 'AFTER_DISBURSEMENT',
  REPAYMENT_DUE_DATE: 'REPAYMENT_DUE_DATE'
});

type InterestApplicationMethodT = ValueOf<typeof InterestApplicationMethods>;

export const InterestCalculationMethods = Object.freeze({
  DECLINING_BALANCE_DISCOUNTED: 'DECLINING_BALANCE_DISCOUNTED',
  DECLINING_BALANCE: 'DECLINING_BALANCE',
  FLAT: 'FLAT'
});

type InterestCalculationMethodT = ValueOf<typeof InterestCalculationMethods>;

export const PeriodUnits = Object.freeze({
  MONTHS: 'MONTHS',
  WEEKS: 'WEEKS',
  DAYS: 'DAYS'
});

export type PeriodUnitsT = ValueOf<typeof PeriodUnits>;

export const PaymentIntervalMethod = Object.freeze({
  INTERVAL: 'INTERVAL'
});

type PaymentIntervalMethodT = ValueOf<typeof PaymentIntervalMethod>;

type AmountSettingsT = {
  default?: number;
  maximum?: number;
  minimum?: number;
};

// From the API
type InterestObjectT = {
  calculation_method: InterestCalculationMethodT;
  application_method: InterestApplicationMethodT;
  interest_charge_frequency: ChargeFrequencyT;
  default_percentage: number;
  percentage: number;
};

type DurationT = string;

type SecuritySettingsT = {
  collaterals_enabled: boolean;
  guarantors_enabled: boolean;
  required_guaranties: string | null;
};

type SecuritySettingsCamelT = {
  collateralsEnabled: boolean;
  guarantorsEnabled: boolean;
  requiredGuaranties: string | null;
};

export const States = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  EXITED: 'EXITED',
  BLACKLISTED: 'BLACKLISTED'
};

export type State = ValueOf<typeof States>;

const LoanProduct = Base.extend({
  static: {
    jsonapiType: 'loan_products'
  },
  attrs: {
    encodedKey: attr(),
    name: attr(),
    type: attr(),
    security: attr(),
    amount: attr(),
    repaymentFrequency: attr(),
    interestRate: attr(),
    defaultDuration: attr(),
    minDuration: attr(),
    maxDuration: attr(),
    paymentIntervalMethod: attr(),
    activated: attr(),
    fees: attr(),
    isBridgingProduct: attr(),
    instantLoan: attr(),
    productConfig: hasOne({ type: LoanProductConfig }),
    allowArbitraryFees: attr(),

    // Create
    defaultRepaymentPeriod: attr(),
    maximumRepaymentInstallments: attr(),
    repaymentPeriodUnit: attr(),
    feesSettings: attr()
  },
  methods: {
    hasFixedRepaymentFrequency() {
      return !!this.repaymentFrequency;
    },
    securityObject() {
      return cameliseObjectKeys(this.security);
    },
    hasCollateralsEnabled() {
      return !!this.security.collaterals_enabled;
    },
    hasGuarantorsEnabled() {
      return !!this.security.guarantors_enabled;
    },
    maxDurationUI() {
      return toDurationObjectUI(this.maxDuration);
    },
    minDurationUI() {
      return toDurationObjectUI(this.minDuration);
    },
    toRepaymentFrequencyUI() {
      return toDurationObjectUI(this.repaymentFrequency);
    },
    minimumRequiredGuaranties() {
      return Number(this.securityObject().requiredGuaranties || 0);
    }
  }
});

export const FeeTriggersMap = {
  DISBURSEMENT: 'DISBURSEMENT',
  CAPITALIZED_DISBURSEMENT: 'CAPITALIZED_DISBURSEMENT',
  UPFRONT_DISBURSEMENT: 'UPFRONT_DISBURSEMENT',
  LATE_REPAYMENT: 'LATE_REPAYMENT',
  MONTHLY_FEE: 'MONTHLY_FEE',
  PAYMENT_DUE: 'PAYMENT_DUE',
  PAYMENT_DUE_APPLIED_ON_DUE_DATES: 'PAYMENT_DUE_APPLIED_ON_DUE_DATES',
  MANUAL: 'MANUAL'
};

export const FeeCalculationMethod = Object.freeze({
  FLAT: 'FLAT',
  LOAN_AMOUNT_PERCENTAGE: 'LOAN_AMOUNT_PERCENTAGE'
});

export type FeeCalculationMethodT = ValueOf<typeof FeeCalculationMethod>;

export interface FeeType {
  amount: string | null;
  amountCalculationMethod: FeeCalculationMethodT;
  feeApplication: 'REQUIRED' | 'OPTIONAL';
  id: string;
  name: string;
  percentageAmount: string | null;
  trigger:
    | 'DISBURSEMENT'
    | 'CAPITALIZED_DISBURSEMENT'
    | 'UPFRONT_DISBURSEMENT'
    | 'LATE_REPAYMENT'
    | 'MONTHLY_FEE'
    | 'PAYMENT_DUE'
    | 'PAYMENT_DUE_APPLIED_ON_DUE_DATES'
    | 'MANUAL';
}

export interface LoanProductType extends BaseModel<LoanProductType> {
  encodedKey: string;
  allowArbitraryFees: boolean;
  amount: AmountSettingsT;
  fees: FeeType[];
  instantLoan: boolean;
  hasCollateralsEnabled: () => boolean;
  hasFixedRepaymentFrequency: () => boolean;
  hasGuarantorsEnabled: () => boolean;
  id: string;
  activated: boolean;
  interestRate: InterestObjectT;
  defaultDuration: DurationT | null;
  minDuration: DurationT | null;
  maxDuration: DurationT | null;
  name: string;
  paymentIntervalMethod: PaymentIntervalMethodT;
  repaymentFrequency: DurationT | null;
  security: SecuritySettingsT;
  securityObject: () => SecuritySettingsCamelT;
  toRepaymentFrequencyUI: () => UIDurationObjectT;
  type: ProductTypesT;
  isBridgingProduct: boolean;
  productConfig: LoanProductConfigT;
  duration: DurationT;
  minimumRequiredGuaranties(): number;
  maxDurationUI(): UIDurationObjectT;
}

export default LoanProduct;
