import React from 'react';

import { Text, type TranslationId } from '@kwara/components/src/Intl';

export const Label = ({
  translationId,
  visible,
  total
}: {
  translationId: TranslationId,
  visible: number,
  total: number
}) => (
  <p className="dib nowrap kw-text-regular grey-400 ml0 pa0">
    <Text id={translationId} values={{ visible, total }} />
  </p>
);
