/**************************************************
 * @TemporaryHider is a component for temporary
 * hiding a feature which is not ready for release
 **************************************************/

import * as React from 'react';

type TemporaryHiderPropTypes = {
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export function TemporaryHider(props: TemporaryHiderPropTypes) {
  return null;
}
