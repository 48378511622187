import mitt, { Emitter } from 'mitt';
import get from 'lodash/fp/get';

import { EMPTY } from '@kwara/models/src/models/request';
import { throwError } from '@kwara/lib/src/utils/throwError';
import { formatError } from '@kwara/models/src/models/request/hooks';

type Handler = (evt: any) => void;

export default class DataView<ModelType = any, Filter = any> {
  private _Model: ModelType;
  private _data: ModelType[];
  private _emitter: Emitter;
  private _handlers: Array<{ name: string; handler: Handler }>;
  private _hasMore: boolean;
  private _filterState: Filter | null;

  firstPage = 1;
  pageSize = 10;
  currentPage: number | null = null;
  totalPages: number | null = null;
  totalResults: number | null = null;

  constructor({ Model }: { Model: ModelType }) {
    if (Model == null) throwError('DataViewError', 'Model must be supplied.', arguments?.callee);

    this._Model = Model;
    this._emitter = mitt();
    this._handlers = [];
    this._data = [];
    this._filterState = null;
    this._hasMore = true;
  }

  public get data(): ModelType[] {
    return this._data.slice(0);
  }

  public get hasMore(): boolean {
    return this._hasMore;
  }

  public get state(): Filter | null {
    return this._filterState;
  }

  public set state(value: Filter | null) {
    this._filterState = value;
    this._emitter.emit('filterChange');
    this.reset();
  }

  private fetch(): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        this._emitter.emit('change', { loading: true, error: EMPTY });

        let scope = (this._Model as any).per(this.pageSize).page(this.currentPage);

        if (this.state != null) {
          scope = scope.where({ state: this.state });
        }

        try {
          const response = await scope.stats({ total: ['count', 'pages'] }).all();

          this._emitter.emit('responseReceived', response);
          this.totalPages = get('meta.stats.total.pages', response);
          this.totalResults = get('meta.stats.total.count', response);
          /**
           * If pagination is not supported, hasMore should be false
           */
          this._hasMore = this.currentPage !== this.totalPages && this.totalPages !== undefined;
          this._data = [...this._data, ...response.data];
          this._emitter.emit('change', { loading: false, error: EMPTY });
          resolve();
        } catch (err) {
          const formatted = await formatError(err);

          this._emitter.emit('change', { loading: false, error: formatted });
          reject();
        }
      })();
    });
  }

  public next() {
    this.currentPage = this.currentPage == null ? this.firstPage : this.currentPage + 1;

    return this.fetch();
  }

  public reset() {
    this.currentPage = null;
    this.totalPages = null;
    this.totalResults = null;
    this._data = [];
    this._emitter.emit('change', { loading: false, error: EMPTY });
    this._hasMore = true;
  }

  public on(name: string, handler: Handler) {
    this._handlers.push({ name, handler });
    this._emitter.on(name, handler);
  }

  public destroy() {
    this._handlers.map(({ name, handler }) => this._emitter.off(name, handler));
    this.reset();
  }
}
