import React from 'react';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';

import { getPaymentInfo, DataType } from '../Loan/utils/getPaymentInfo';

export async function onDisburse(
  data: LoanFormData,
  setDisbursementState: React.Dispatch<React.SetStateAction<string>>
) {
  const { loan, note, feeApplications } = data;
  const paymentInfo = getPaymentInfo(data as DataType, loan.member.phoneNumber);
  const payload = {
    amount: loan.pendingDisbursementAmount,
    notes: note,
    feeApplications,
    valueDate: formatIsoDate(new Date(loan.anticipatedDisbursementDate)),
    ...paymentInfo
  };

  const disbursementState = await loan.disburse(payload);

  if (!disbursementState) throw loan.errors;

  setDisbursementState(disbursementState);
}
