import some from 'lodash/fp/some';
import remove from 'lodash/remove';

import { Logger } from '@kwara/lib/src/logger';
import { LoanAttachment, LoanAttachmentT } from '@kwara/models/src';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { toBase64, FileNameT } from '@kwara/components/src/UploadWidgetUI';

export async function convert(
  evt: React.SyntheticEvent<HTMLInputElement>,
  fileName: FileNameT
): Promise<LoanAttachmentT | {}> {
  const [file] = (evt.target as HTMLInputElement).files;

  try {
    const content = await toBase64(file);
    return new LoanAttachment({
      attachment: {
        io: content,
        content_type: file.type,
        filename: file.name
      },
      name: fileName,
      id: `temp-id-${fileName}-${getCurrentDate().toISOString()}`,
      type: file.type
    });
  } catch (e) {
    Logger.error('Error at LoanAdd > Collaterals > UploadWidget > convert', JSON.stringify(e));
    return {};
  }
}

export function addAttachment(data: { [k: string]: any; attachments: LoanAttachmentT[] }, value: LoanAttachmentT) {
  const att = new LoanAttachment(value);

  if (!data.attachments || !Array.isArray(data.attachments)) {
    data.attachments = [];
  }

  data.attachments.push(att);

  return data;
}

export function removeAttachment(data: { [k: string]: any; attachments: LoanAttachmentT[] }, fileName: string) {
  remove(data.attachments, e => e.name === fileName);
  return data;
}

export function isDirty(attachments: LoanAttachmentT[] = [], fileName: string) {
  return some(att => att.name === fileName, attachments);
}
