import * as React from 'react';

export function IconNext() {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.12 4.91002L19.25 1.00002C19.157 0.906293 19.0464 0.831899 18.9246 0.78113C18.8027 0.730362 18.672 0.704224 18.54 0.704224C18.408 0.704224 18.2773 0.730362 18.1554 0.78113C18.0336 0.831899 17.923 0.906293 17.83 1.00002C17.6437 1.18738 17.5392 1.44084 17.5392 1.70502C17.5392 1.96921 17.6437 2.22266 17.83 2.41002L21.39 6.00002H1C0.734783 6.00002 0.480429 6.10538 0.292893 6.29291C0.105357 6.48045 0 6.7348 0 7.00002H0C0 7.26523 0.105357 7.51959 0.292893 7.70712C0.480429 7.89466 0.734783 8.00002 1 8.00002H21.45L17.83 11.61C17.7363 11.703 17.6619 11.8136 17.6111 11.9354C17.5603 12.0573 17.5342 12.188 17.5342 12.32C17.5342 12.452 17.5603 12.5827 17.6111 12.7046C17.6619 12.8265 17.7363 12.9371 17.83 13.03C17.923 13.1237 18.0336 13.1981 18.1554 13.2489C18.2773 13.2997 18.408 13.3258 18.54 13.3258C18.672 13.3258 18.8027 13.2997 18.9246 13.2489C19.0464 13.1981 19.157 13.1237 19.25 13.03L23.12 9.15002C23.6818 8.58752 23.9974 7.82502 23.9974 7.03002C23.9974 6.23502 23.6818 5.47252 23.12 4.91002Z"
        fill="#94A3B8"
      />
    </svg>
  );
}
