import { useCallback } from 'react';

import at from 'lodash/fp/at';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import lowerCase from 'lodash/fp/lowerCase';

import { LoanProductType } from '@kwara/models/src/models/LoanProduct';

export function useFilterBySearchTerm() {
  return useCallback((products: Array<LoanProductType>, rawTerm: string) => {
    const term = lowerCase(rawTerm);

    return filter(product => {
      const [name, id] = pipe(at(['name', 'id']), map(lowerCase))(product);

      return includes(term, name) || includes(term, id);
    }, products);
  }, []);
}
