import * as React from 'react';

import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

export function Purpose(props) {
  return (
    <div>
      <LoanClassificationFields {...props} />
    </div>
  );
}

Purpose.validate = LoanClassificationFields.validate;
