import React from 'react';

import { UserT } from '@kwara/models/src';

import { EditableSection } from '../../../../../components/EditableSection/v1/EditableSection';

import { Detail, DetailWrapper, SettingProps } from '..';

export const EmailF = ({ StackChild, TextField }: SettingProps) => {
  return (
    <StackChild>
      <TextField name="email" labelId="Settings.Personal.Email.label" />
    </StackChild>
  );
};

const config = {
  Component: EmailF
};

export function Email({ user }: { user: UserT }) {
  return (
    <DetailWrapper>
      <EditableSection config={config} onSave={() => {}} initialData={user} editClassNames="items-center" readOnly>
        <Detail titleId="Settings.Personal.Email.title" value={user.email} />
      </EditableSection>
    </DetailWrapper>
  );
}
