// @flow
import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { Form } from 'react-final-form';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import { ChequeTransactions } from '@kwara/models/src';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { Loadable } from '@kwara/components/src/Loadable';
import { SubscribedTextField, SubscribedDatePicker } from '@kwara/components/src/Form';

import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import { Grid } from '../../../../components/Grid';
import { TablePreview } from './TablePreview';
import { BodyContentWrapper } from '../../../../layouts';

const validationRules = {
  chequeNumber: {
    isRequired: () => true
  }
};

export const ChequeReports = () => {
  const [previewData, setPreviewData] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const r = { data: previewData, isLoading, errors };

  const formatPayload = (data: FormData) => {
    const { date, chequeNumber } = data;
    const valueDate = date ? formatIsoDate(date) : null;

    return snakeCaseObjectKeys({
      chequeNumber,
      ...(valueDate ? { valueDate } : {})
    });
  };

  const onPreview = async (data: FormData) => {
    const payload = formatPayload(data);

    try {
      setIsLoading(true);
      setPreviewData([]);

      const res = await ChequeTransactions.where(payload)
        .includes('account.member,bank,bank_branch')
        .all();

      setPreviewData(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <div className="flex justify-between items-center">
            <HeaderSubHeader titleId="Finance.ChequeReports.title" subtitleId="Finance.ChequeReports.subtitle" />
          </div>
          <Form
            validate={createValidator(validationRules)}
            onSubmit={onPreview}
            render={({ handleSubmit, invalid, values }) => (
              <form className="flex justify-center pb5" onSubmit={handleSubmit}>
                <div className="flex flex-column items-start">
                  <Grid columns={2} border={false} width="w-50">
                    <SubscribedDatePicker
                      className="pr3"
                      name={'date'}
                      labelId="ChequeReportsGenerate.Form.date.label"
                    />

                    <SubscribedTextField
                      name="chequeNumber"
                      required
                      labelId="ChequeReportsGenerate.Form.chequeNumber.label"
                    />
                  </Grid>
                  <Grid columns={2} border={false} width="w-50">
                    <Button isSubmit aria-label="Preview" disabled={invalid} type="primary">
                      <Text id="ChequeReportsGenerate.button.Preview" />
                    </Button>

                    <DownloadButton
                      size="regular"
                      key="print"
                      downloadFn={() => ChequeTransactions.download(formatPayload(values))}
                      disabled={isEmpty(previewData)}
                    />
                  </Grid>
                </div>
              </form>
            )}
          />
          {previewData ? (
            <div className="flex justify-between items-center">
              <Loadable {...r}>{chequeReports => <TablePreview data={chequeReports} />}</Loadable>
            </div>
          ) : null}
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
