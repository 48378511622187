// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import isArray from 'lodash/fp/isArray';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';

import { minus } from '@kwara/lib/src/currency';
import { Statistic } from '@kwara/components/src/Statistic';
import { formatIsoDate, formatHumanDate, getCurrentDate, yesterday } from '@kwara/lib/src/dates';
import filterEmptyValues from '@kwara/models/src/lib/filterEmptyValues';
import {
  SavingsTransaction,
  type SavingType,
  type BankGlAccountT,
  GlContexts,
  type MemberType
} from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedPaymentSelectField, SubscribedDatePicker, GlAccountSelect } from '@kwara/components/src/Form';
import { TransactionChannels, getTransactionTypes, contexts } from '@kwara/models/src/models/Transactions';

import { type SubStepComponentProps } from '../../../components/Wizard';
import MemberPanel from '../../../components/Payment/MemberPanel';
import NotesPanel from '../../../components/Payment/NotesPanel';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';
import { DummyTillSelect } from '../../Till/components/DummyTillSelect/DummyTillSelect';

type ChequeDataT = {
  chequeNumber?: string,
  drawer?: string
};

type BankDataT = {
  bankName: string,
  bankBranch: string,
  accountNumber: string
};

type NotesT = {
  notes?: string
};

export type FormData = {
  member: MemberType,
  allGlAccounts: BankGlAccountT[],
  accounts: SavingType[],
  accountId: string,
  bankGlId?: string,
  glAccountCode?: string,
  saving?: SavingType,
  amount?: string,
  method?: string,
  reference?: string,
  glTransferId?: string,
  valueDate?: Date
} & BankDataT &
  ChequeDataT &
  NotesT;

export const showChequeFieldsFor = [TransactionChannels.cheque];
export const showBanksFieldsFor = [TransactionChannels.bankTransfer, TransactionChannels.cheque];

export const depositConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.Deposit).values,
  showChequeFieldsFor,
  showBanksFieldsFor
};

export function getMaxAmount(maxOpeningBalance: ?number, account?: SavingType): ?number {
  if (maxOpeningBalance == null || account == null) {
    return null;
  }

  return Number(minus(maxOpeningBalance, account.balance));
}

function findMaximumAmount(account) {
  const maxOpeningBalance = get('product.maximumOpeningBalance', account);

  return getMaxAmount(maxOpeningBalance, account);
}

function getSelectedAccount({ accounts, accountId, saving }) {
  return saving || find<SavingType>({ id: accountId }, accounts);
}

export const addDeposit = async (data: FormData) => {
  const {
    amount,
    accountId: savingsId,
    method,
    bankGlId,
    glAccountCode,
    bankName,
    bankBranch,
    accountNumber,
    drawer,
    chequeNumber,
    notes,
    reference,
    glTransferId,
    valueDate
  } = data;

  // TODO: This can perhaps be improved when ch14895 is actioned
  //
  const paymentMethod = method === 'glTransfer' ? glTransferId : method;

  const transaction = new SavingsTransaction(
    filterEmptyValues({
      type: 'DEPOSIT',
      savingsId,
      bankGlId,
      glAccountCode,
      amount,
      paymentMethod,
      bankName,
      bankBranch,
      accountNumber,
      drawer,
      chequeNumber,
      notes,
      reference,
      ...(valueDate ? { valueDate: formatIsoDate(valueDate) } : {})
    })
  );

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
};

export const DepositForm = ({
  SelectField,
  StackChild,
  TextField,
  data,
  addData,
  formProps
}: SubStepComponentProps<FormData>) => {
  const Option = SelectField.Option;

  const { values } = formProps;
  const { method, accountId } = values;
  const { member, accounts, saving, isTillOpen } = data;

  const selectedAccount = getSelectedAccount({
    accounts,
    accountId,
    saving
  });

  const hasAccountSelection = isArray(accounts);
  const maximumAmount = findMaximumAmount(selectedAccount);
  const activationDate = get('activationDate', selectedAccount);

  const isTillCashTransaction = isTillOpen && method === TransactionChannels.cash;

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />
      <Panel>
        <div className="bb b--light-grey-400 mb4">
          {hasAccountSelection ? (
            <SelectField name="accountId" size="medium" labelId="PaymentForm.account">
              {map<SavingType, React.Element<Option>>(
                account => (
                  <Option key={account.id} value={account.id}>
                    {account.id} &mdash; {account.product.name}
                  </Option>
                ),
                accounts
              )}
            </SelectField>
          ) : null}

          {!hasAccountSelection && selectedAccount ? (
            <Statistic
              size="medium"
              title={<Text id="PaymentForm.account" />}
              value={`${selectedAccount.id} — ${selectedAccount.product.name}`}
            />
          ) : null}
        </div>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            required
            isCurrency
            name="amount"
            type="number"
            step="0.01"
            size="medium"
            leftGlyph="Currency.orgCurrency"
            labelId="PaymentForm.amount"
            info={maximumAmount ? <Text id="SavingPayment.maximumAmountInfo" values={{ maximumAmount }} /> : null}
          />
          <SubscribedDatePicker
            name="valueDate"
            size="medium"
            labelId="SavingPayment.valueDate"
            inputPlaceholder={formatHumanDate(getCurrentDate())}
            disabledDays={{
              before: activationDate ? new Date(activationDate) : null,
              after: yesterday()
            }}
          />
        </Grid>
        <SubscribedPaymentSelectField
          name="method"
          config={depositConfig}
          required
          // see ch10266
          inputOnChange={() => addData({ bankGlId: '' })}
        />

        {isTillCashTransaction ? (
          <DummyTillSelect />
        ) : (
          <GlAccountSelect method={method} addData={addData} context={GlContexts.DEPOSIT} />
        )}

        {method ? <TextField name="reference" size="medium" labelId="PaymentForm.reference" /> : null}
      </Panel>
      <NotesPanel />
    </StackChild>
  );
};

const ifCheque = {
  isRequired: (_, allData) => showChequeFieldsFor.includes(allData.method)
};

const ifChequeOrBank = {
  isRequired: (_, allData) => showBanksFieldsFor.includes(allData.method)
};

DepositForm.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, data) => {
      const { accounts, accountId, saving } = data;
      const selectedAccount = getSelectedAccount({
        accounts,
        accountId,
        saving
      });

      const maximumAmount = findMaximumAmount(selectedAccount);

      if (maximumAmount && Number(target) > maximumAmount) {
        return 'rangeOverflow';
      }

      return null;
    }
  },
  method: { isRequired: () => true },
  bankName: ifChequeOrBank,
  bankBranch: ifChequeOrBank,
  accountNumber: ifChequeOrBank,
  chequeNumber: ifCheque,
  drawer: ifCheque,
  ...GlAccountSelect.validate
};
