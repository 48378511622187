// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { useMember, useMemberSavings } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { SavingsTransaction, type SavingType, type MemberType } from '@kwara/models/src';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { useWithdrawalForm } from './useWithdrawalForm';

type BankDataT = {
  bankName: string,
  bankBranch: string,
  accountNumber: string
};

type NotesT = {
  notes?: string
};

export type FormData = {
  member: MemberType,
  accounts: SavingType[],
  accountId: string,
  bankGlId?: string,
  saving?: SavingType,
  amount?: string,
  method?: TransactionChannelT,
  reference?: string,
  glTransferId?: string,
  valueDate?: string
} & BankDataT &
  NotesT;

export const makeWithdrawal = async (data: FormData) => {
  const {
    amount,
    accountId: savingsId,
    method,
    bankName,
    bankBranch,
    accountNumber,
    bankGlId,
    glAccountCode,
    notes,
    reference,
    glTransferId,
    valueDate
  } = data;

  // TODO: This can perhaps be improved when ch14895 is actioned
  //
  const paymentMethod = method === 'glTransfer' ? glTransferId : method;

  const transaction = SavingsTransaction.create({
    type: 'WITHDRAWAL',
    savingsId,
    amount,
    paymentMethod,
    bankName,
    bankBranch,
    accountNumber,
    notes,
    reference,
    bankGlId,
    glAccountCode,
    ...(valueDate ? { valueDate: formatIsoDate(valueDate) } : {})
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
};

export const MemberWithdrawal = props => {
  const queryClient = useQueryClient();
  const memberId = props.match.params.memberId;
  const { makeWithdrawal, isTillOpen, isTillLoading } = useWithdrawalForm();
  const r = useMember(memberId);
  const savingsR = useMemberSavings(memberId);

  return (
    <Loadable isLoading={isTillLoading}>
      <Loadable {...r}>
        {member => (
          <Loadable {...savingsR}>
            {savings => {
              member.savings = savings;
              const accounts = member.savingsEligibleForTransactions();
              const initialAccountId = get('[0].id', accounts);

              return size(accounts) > 0 ? (
                <Wizard
                  analyticsId="MemberWithdrawal"
                  baseUrl={memberPath({ id: member.id })}
                  history={history}
                  initialData={{
                    member,
                    accounts,
                    accountId: initialAccountId,
                    isTillOpen
                  }}
                  cancelReturnsTo={memberPath({ id: member.id })}
                  currentStep="withdrawal"
                  onSubmit={makeWithdrawal}
                  onSubmitCompletion={() => {
                    savingsR.refetch();
                    queryClient.invalidateQueries('member_details_metrics');
                  }}
                  steps={steps}
                  startId="withdrawal"
                  titleId="MemberWithdrawal.title"
                />
              ) : (
                <Redirect to={memberPath({ id: member.id })} />
              );
            }}
          </Loadable>
        )}
      </Loadable>
    </Loadable>
  );
};
