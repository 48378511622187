// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import round from 'lodash/round';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { Text } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Button from '@kwara/components/src/Button';
import { type LoanType, type GuaranteeType, type ModelErrors } from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { Child as StackChild } from '../../../../components/Stack';
import { GuarantorItem } from '../../../Loan/LoanAdd/components/Security/Guarantor/components/GuarantorList';
import {
  GuarantorSearch,
  Loader
} from '../../../Loan/LoanAdd/components/Security/Guarantor/components/GuarantorSearch';

const rules = {
  amount: {
    isRequired: () => true,
    currency: true
  }
};

const includes = ['db_guarantees.db_loan.db_member'];
export const GuarantorForm = ({
  loan,
  guarantorToEdit,
  onCancel,
  handleSubmit,
  errors
}: {
  loan: LoanType,
  guarantorToEdit?: GuaranteeType,
  onCancel: () => void,
  handleSubmit: (data: { amount: string, memberId: string }) => Promise<void>,
  errors: ModelErrors
}) => {
  const r = useMember(get('member.id', guarantorToEdit), includes);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [guarantor, setGuarantor] = React.useState(null);

  React.useEffect(() => {
    if (r.isLoading) {
      return;
    }

    setGuarantor(r.data);
  }, [setGuarantor, r.isLoading, r.data]);

  return (
    <Loadable {...r}>
      <ModelErrorBanner errors={errors} />
      <Form
        onSubmit={data => handleSubmit({ ...data, memberId: guarantor.id })}
        initialValues={{
          loanId: loan.id,
          amount: round(get('amount', guarantorToEdit), 2) || null
        }}
        validate={createValidator(rules)}
        render={({ form, valid, submitting }) => {
          const formIsValid = valid && guarantor;

          return (
            <div className="flex justify-center items-center">
              <div>
                {guarantorToEdit ? null : (
                  <GuarantorSearch
                    size="regular"
                    onCancel={onCancel}
                    onSelect={setGuarantor}
                    data={{}}
                    onFetchStart={() => {
                      setIsLoading(true);
                    }}
                    onFetchEnd={() => {
                      setIsLoading(false);
                    }}
                    includes={['db_guarantees.loan.member']}
                  />
                )}

                <Loader isLoading={isLoading} />

                {guarantor ? (
                  <StackChild>
                    <ul className="list pa0">
                      <GuarantorItem member={guarantor} product={loan.product} inputAmountName="amount" />
                    </ul>
                  </StackChild>
                ) : null}

                <ButtonBar
                  left={[
                    <Button onClick={onCancel}>
                      <Text id="LoanDetail.Security.GuarantorForm.cancel" />
                    </Button>
                  ]}
                  right={[
                    <Button
                      aria-label="Submit"
                      disabled={!formIsValid || submitting}
                      type="primary"
                      onClick={form.submit}
                      glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                    >
                      <Text id="LoanDetail.Security.GuarantorForm.submit" />
                    </Button>
                  ]}
                />
              </div>
            </div>
          );
        }}
      />
    </Loadable>
  );
};
