import * as React from 'react';
import posed from 'react-pose';

import styles from './index.module.scss';
import { ExpanderState } from '../../Expander';

const AnimatedContainer = posed.div({
  collapsed: { height: 0, opacity: 0, flip: true },
  expanded: { height: 'auto', opacity: 1, flip: true }
});

export type BodyPropTypes = {
  state?: ExpanderState;
  children: React.ReactNode;
};

export function Body({ state, children }: BodyPropTypes) {
  return (
    <AnimatedContainer className={styles['expander']} pose={state}>
      <div className={styles['row']}>
        <div className={styles['row-content']}>{children}</div>
      </div>
    </AnimatedContainer>
  );
}
