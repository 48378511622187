import * as React from 'react';

import { LoanProductType } from '@kwara/models/src';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import { useFeeValues } from '../../../hooks';

type ArgsT = {
  product: LoanProductType;
  feeApplications: FeeApplicationT[];
  amount: string;
  onChange: any;
};

export function useFeesForm({ product, feeApplications, amount, onChange }: ArgsT) {
  const { getFeeApplication, ...rest } = useFeeValues({
    product,
    feeApplications,
    amount
  });

  const onRemove = React.useCallback(
    async (feeId: string) => {
      const feeToRemove = getFeeApplication(feeId);
      feeToRemove.isMarkedForDestruction = true;
      return await onChange({ feeApplications });
    },
    [getFeeApplication, onChange, feeApplications]
  );

  const onFeeAdd = async (feeData: { feeId: string; amount?: string }) =>
    await onChange({ feeApplications: [...feeApplications, feeData] });

  return {
    ...rest,
    onFeeAdd,
    getFeeApplication,
    onRemove
  };
}
