import * as React from 'react';

import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { Text, Currency } from '@kwara/components/src/Intl';

import { LoanSubstepProps } from 'GlobalTypes';

import Collaterals from './components/Collaterals/Collaterals';
import Guarantors from '../../../../../components/Guarantors/Guarantors';
import SelfGuarantee from './components/SelfGuarantee/SelfGuarantee';

function SecurityV2(props: LoanSubstepProps) {
  const { product, amount } = props.data;
  return (
    <>
      <h3 className="mb2 kw-text-large neutral-600">
        <Text
          id="LoanAdd.Security.productAndAmount"
          values={{ name: product.name, amount: <Currency format="currency" value={amount} /> }}
        />
      </h3>
      <Guarantors.Component {...props} />
      <Collaterals.Component {...props} />
      <SelfGuarantee.Component {...props} />
    </>
  );
}

export default {
  Component: SecurityV2,
  validate(args: LoanFormData) {
    return {
      ...Guarantors.validate({ ...args }),
      ...Collaterals.validate({ ...args }),
      ...SelfGuarantee.validate
    };
  }
};
