import { useQuery } from 'react-query';

import { DividendReports } from '../../../../../../models';

function fetchDividendReport({ queryKey }) {
  const [_, id] = queryKey;

  return DividendReports.find(id).then(res => res.data);
}

export function useDividendReport(reportId: string) {
  return useQuery(['use_dividend_report', reportId], fetchDividendReport, { retry: false });
}
