import React, { memo } from 'react';
import getOr from 'lodash/fp/getOr';
import { useIntl } from 'react-intl';
import { Location } from 'history';

import StatusTag from '@kwara/components/src/StatusTag';

import { If } from '@kwara/components/src/If/If';
import { DateTime } from '@kwara/components/src/Intl';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { MemberType, capitalize } from '@kwara/models/src/models/Member';
import { StandardIdDocumentTypes, IdDocumentType } from '@kwara/models/src/models/IdDocument';
import { maskStringUntilLastNCharacters } from '@kwara/lib/src/utils/maskStringUntilLastNCharacters/maskStringUntilLastNCharacters';

import * as Table from '../../../../components/Table';

import styles from './MemberRow.module.scss';

const getNationalId = (idDocuments?: IdDocumentType[]): string | null => {
  if (!idDocuments?.length) return null;

  const nationalId: IdDocumentType | undefined = idDocuments.find(doc => doc.type === StandardIdDocumentTypes.NATIONAL);

  return nationalId?.documentId || null;
};

type MemberRowPropTypes = {
  member: MemberType;
  pathTo?: Partial<Location> | null;
  isV1?: boolean;
};

export const MemberRow = memo(function MemberRow({ member, pathTo, isV1 }: MemberRowPropTypes) {
  const intl = useIntl();
  const [showFullNationalId, { toggle }] = useBoolean();

  const dash = intl.formatMessage({ id: 'General.Dash' });
  const nationalId = getNationalId(member.idDocuments);

  return (
    <Table.Row to={pathTo}>
      <Table.Cell to={pathTo} className="kw-numeric">
        {member.id}
      </Table.Cell>
      <Table.Cell to={pathTo} className="mw5">
        {member.fullName()}
      </Table.Cell>
      <Table.Cell to={pathTo}>{member.phoneNumber}</Table.Cell>
      <Table.Cell className={styles['national-id-cell']}>
        <If
          condition={!!nationalId}
          do={
            <div
              className="grey-400"
              title="Toggle national ID visibility"
              role="button"
              onClick={ev => {
                ev.stopPropagation();
                toggle();
              }}
            >
              <If condition={showFullNationalId} do={nationalId} else={maskStringUntilLastNCharacters(nationalId, 3)} />
            </div>
          }
          else={<div className="grey-400">{dash}</div>}
        />
      </Table.Cell>
      <Table.Cell to={pathTo}>
        <DateTime value={member.updatedAt} />
      </Table.Cell>
      {isV1 ? null : (
        <Table.Cell to={pathTo}>
          <If condition={!!member.source} do={capitalize(member.source)} else={dash} />
        </Table.Cell>
      )}
      <Table.Cell to={pathTo}>
        <StatusTag size="small" state={getOr('PENDING', 'state.current', member)} />
      </Table.Cell>
    </Table.Row>
  );
});
