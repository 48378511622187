import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DisbursementStates } from '@kwara/models/src/models/LoanDisbursement';

import { FILTER_TYPES } from '../../../../../../../components/Filter/utils/filterTypes';

export function useLoanDisbursementStatusFilter() {
  const int = useIntl();

  return useMemo(
    () => [
      {
        value: '',
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.all' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      },
      {
        value: DisbursementStates.PENDING_MAKER_CHECKER_APPROVAL,
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.pending' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      },
      {
        value: `${DisbursementStates.APPROVED},${DisbursementStates.IMPORTED}`,
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.processing' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      },
      {
        value: DisbursementStates.IMPORTED,
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.approved' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      },
      {
        value: DisbursementStates.FAILED,
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.failed' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      },
      {
        value: DisbursementStates.REJECTED,
        name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.rejected' }),
        belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
      }
    ],
    [int]
  );
}
