import React from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import { Form } from 'react-final-form';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { useActions } from './hooks/useActions';
import { BodyContentWrapper } from '../../../../layouts';
import { ConfirmModal } from './components/ConfirmModal';
import { HeaderSubHeader as Header } from '../../../../components/HeaderSubHeader';
import { PendingLoanDisbursementsList } from './components/PendingLoanDisbursementsList';
import { usePaginatedPendingLoanDisbursements } from './hooks/usePaginatedPendingLoanDisbursements';
import { useLoanDisbursementFilters } from './components/PendingLoanDisbursementsList/filters/useLoanDisbursementFilters';
function InternalPendingLoanDisbursements() {
  const {
    allData,
    isLoading,
    totalResults,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    ...restProps
  } = usePaginatedPendingLoanDisbursements();
  const { event, error, shownConfirmModal, closeModal, approve, reject, submit } = useActions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <Header titleId="Finance.LoanDisbursement.title" subtitleId="Finance.LoanDisbursement.subtitle" />
          <Form
            onSubmit={submit}
            render={({ form, submitting, ...rest }) => {
              const selectedLoans = get('loanDisbursementId', rest.values);
              const selectedLoansSize = size(selectedLoans);

              return (
                <>
                  <ConfirmModal
                    error={error}
                    event={event}
                    onClose={closeModal}
                    onSubmit={form.submit}
                    isOpen={shownConfirmModal}
                    loansCount={selectedLoansSize}
                    isSubmitting={submitting || isRefetching}
                  />
                  <PendingLoanDisbursementsList
                    {...restProps}
                    onReject={reject}
                    onApprove={approve}
                    loanDisbursements={allData}
                    selectedLoans={selectedLoans}
                    loansCount={selectedLoansSize}
                    onFetchNextPage={fetchNextPage}
                    size={totalResults || allData.length}
                    isFetching={isLoading || isFetchingNextPage}
                  />
                </>
              );
            }}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function PendingLoanDisbursements() {
  const { filters, selectedFilters } = useLoanDisbursementFilters();

  return (
    <OverviewListV2.Root filters={filters} selectedFilters={selectedFilters}>
      <InternalPendingLoanDisbursements />
    </OverviewListV2.Root>
  );
}
