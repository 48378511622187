import isEmpty from 'lodash/fp/isEmpty';

import { FormApi } from 'final-form';
import { useQueryClient } from 'react-query';
import { useState, useCallback } from 'react';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { PendingLoanDisbursement } from '@kwara/models/src/models/LoanDisbursement';

import { ModelErrors, KeyOf } from 'GlobalTypes';

import { GET_PENDING_LOAN_DISBURSEMENTS_QUERY_KEY } from './usePaginatedPendingLoanDisbursements';

enum EVENTS {
  approve = 'approve',
  reject = 'reject'
}

export type Event = KeyOf<typeof EVENTS>;

type SubmitValues = {
  loanDisbursementId: Array<string>;
  notes: string;
};

export function useActions() {
  const queryClient = useQueryClient();
  const [error, setError] = useState<ModelErrors | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const [shownConfirmModal, { setToFalse: closeModal, setToTrue: openModal }] = useBoolean();

  const approve = useCallback(() => {
    setEvent(EVENTS.approve);
    openModal();
  }, [openModal]);

  const reject = useCallback(() => {
    setEvent(EVENTS.reject);
    openModal();
  }, [openModal]);

  const onSuccess = useCallback(
    (form: FormApi) => {
      return async () => {
        try {
          await queryClient.invalidateQueries(GET_PENDING_LOAN_DISBURSEMENTS_QUERY_KEY);
        } finally {
          closeModal();
          form.restart();
        }
      };
    },
    [closeModal, queryClient]
  );

  const submit = useCallback(
    async ({ loanDisbursementId, notes }: SubmitValues, form: FormApi) => {
      const model = new PendingLoanDisbursement();
      const applyToAll = isEmpty(loanDisbursementId);

      await model.transition({
        event,
        notes,
        apply_to_all: applyToAll,
        raw_loan_disbursement_ids: loanDisbursementId,
        onSuccess: onSuccess(form),
        onError: setError
      });
    },
    [event, onSuccess]
  );

  return {
    event,
    error,
    shownConfirmModal,
    closeModal,
    approve,
    reject,
    submit
  };
}
