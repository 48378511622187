import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving, useLoan, useMemberLight } from '@kwara/models/src/models/request/hooks';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { SavingMemberStatement, LoanMemberStatement, SavingType, MemberType, LoanType } from '@kwara/models/src';
import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/latest';

import { steps } from './config';
import { SavingPageProps, LoanPageProps, MemberPageProps } from '..';
import { savingPath, loanPath, memberPath } from '../../lib/urls';

export const generateSavingStatement = async ({ from, to, saving }) => {
  const valueDate = { from: formatIsoDate(from), to: formatIsoDate(to) };
  const data = {
    ...valueDate,
    saving
  };
  await SavingMemberStatement.download(data);
};

export const SavingStatementGenerate = (props: SavingPageProps) => {
  const { match, baseUrl } = props;
  const r = useSaving(props.match.params.savingId);
  return (
    <Loadable {...r}>
      {(saving: SavingType) => {
        const redirectTo = savingPath({ id: saving.id });
        return (
          <Wizard
            analyticsId="SavingStatementGenerate"
            startId="date"
            titleId="MemberDetail.savingstatement"
            steps={steps()}
            baseUrl={baseUrl}
            initialData={{ saving }}
            cancelReturnsTo={redirectTo}
            currentStep={match.params.step}
            currentSubStep={getSubstepFromMatchParams(match.params)}
            onSubmit={generateSavingStatement}
          />
        );
      }}
    </Loadable>
  );
};

export const generateLoanStatement = async ({ from, to, loan }) => {
  const valueDate = { from: formatIsoDate(from), to: formatIsoDate(to) };
  const data = {
    ...valueDate,
    loan
  };
  await LoanMemberStatement.download(data);
};

export const LoanStatementGenerate = (props: LoanPageProps) => {
  const { baseUrl, match } = props;
  const r = useLoan(match.params.loanId);
  return (
    <Loadable {...r}>
      {(loan: LoanType) => {
        const redirectTo = loanPath({ id: loan.id });
        return (
          <Wizard
            analyticsId="LoanStatementGenerate"
            startId="date"
            titleId="MemberDetail.loanstatement"
            steps={steps()}
            baseUrl={baseUrl}
            initialData={{ loan }}
            cancelReturnsTo={redirectTo}
            currentStep={match.params.step}
            currentSubStep={getSubstepFromMatchParams(match.params)}
            onSubmit={generateLoanStatement}
          />
        );
      }}
    </Loadable>
  );
};

export const generateMemberStatement = async ({ from, to, extras, member }) => {
  const valueDate = { from: formatIsoDate(from), to: formatIsoDate(to) };
  const include_additional_states = !!extras;
  const data = {
    ...valueDate,
    include_additional_states,
    member
  };

  await member.downloadStatement(data);
};

export const MemberStatementGenerate = (props: MemberPageProps) => {
  const { baseUrl, match } = props;
  const r = useMemberLight(match.params.memberId);

  return (
    <Loadable {...r}>
      {(member: MemberType) => {
        const redirectTo = memberPath({ id: member.id });
        return (
          <Wizard
            analyticsId="MemberStatementGenerate"
            startId="date"
            titleId="MemberDetail.memberstatement"
            steps={steps(true)}
            baseUrl={baseUrl}
            initialData={{ member }}
            cancelReturnsTo={redirectTo}
            currentStep={match.params.step}
            currentSubStep={getSubstepFromMatchParams(match.params)}
            onSubmit={generateMemberStatement}
          />
        );
      }}
    </Loadable>
  );
};
