// @flow
import * as React from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import { type CollateralT } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text, getTranslationIdByApp } from '@kwara/components/src/Intl';
import { appName } from '@kwara/lib/src/utils';
import { ScrollIntoView } from '@kwara/components/src/ScrollIntoView';

import { Header } from '../Header';
import { CollateralSelect } from './components/CollateralSelect';
import { CollateralList } from './components/CollateralList';
import { type LoanSubstepProps } from '../../..';
import { useListEditor } from '../../../../../../hooks';

export const YES = 'yes';
export const isDoneAddingCollateral = 'isDoneAddingCollateral';

function toggleItem(shouldDestroy, collaterals: CollateralT[], idx: number) {
  const clone = get(idx, collaterals).dup();
  clone.isMarkedForDestruction = shouldDestroy;
  // replace the original instance with a clone that is marked for destruction
  return {
    collaterals: mapIndexed((c, i) => {
      if (i === idx) {
        return clone;
      }
      return c;
    }, collaterals)
  };
}

export const Collateral = ({ formProps, data, TextArea, StackChild, onChange }: LoanSubstepProps) => {
  const { product } = data;

  const basePath = 'collaterals';
  const { isEditing, path, setEdit, addItem, setView, editItem, index } = useListEditor(data.collaterals, basePath);

  const setIsDoneAddingCollateral = value => {
    // See https://github.com/getkwara/webapp/pull/1529
    onChange({ [isDoneAddingCollateral]: value });
    formProps.form.change(isDoneAddingCollateral, value);
  };

  const onCancel = () => {
    if (formProps.form) {
      formProps.form.reset();
    }
    setIsDoneAddingCollateral(YES);
    setView();
  };

  const onSubmit = () => {
    setIsDoneAddingCollateral(YES);
    setView();
  };

  if (!product.hasCollateralsEnabled()) {
    return (
      <StackChild>
        <div className="pb4 flex justify-between items-center">
          <p className="ma0">
            <Text id={getTranslationIdByApp('LoanAdd.Security.Collateral.disabled')} />
          </p>
        </div>
      </StackChild>
    );
  }

  const renderHeader = buttonTextId => (
    <Header
      titleId="LoanAdd.Security.Collateral.title"
      subtitleId="LoanAdd.Security.Collateral.subtitle"
      buttonTextId={buttonTextId}
      enabled={true}
      StackChild={StackChild}
      isSearching={isEditing}
      onAdd={() => {
        addItem();
        setEdit();
        setIsDoneAddingCollateral(''); //Added to the form data to invalidate wizard form.
      }}
      memberName={data.member.fullName()}
      data={data}
    />
  );

  return (
    <>
      {appName.isSacco ? renderHeader('LoanAdd.Security.Collateral.add') : null}
      {isEditing ? (
        <CollateralSelect
          StackChild={StackChild}
          onCancel={onCancel}
          TextArea={TextArea}
          onSelect={onSubmit}
          formProps={formProps}
          index={index}
          data={data}
          path={path}
        />
      ) : (
        <CollateralList
          StackChild={StackChild}
          collaterals={data.collaterals}
          onUndo={idx => onChange(toggleItem(false, data.collaterals, idx))}
          onRemove={idx => onChange(toggleItem(true, data.collaterals, idx))}
          onEdit={editItem}
        />
      )}
      {appName.isMember ? (
        <ScrollIntoView>
          {renderHeader(
            size(data.collaterals) === 0 ? 'LoanAdd.Security.Collateral.add' : 'LoanAdd.Security.Collateral.addMore'
          )}
        </ScrollIntoView>
      ) : null}
    </>
  );
};
const requiredForCollateralBank = (index, allData) => get(collateralKey(index, 'assetName'), allData) === 'bank';

const collateralKey = (index, field) => `collaterals[${index}].${field}`;

// isDoneAddingCollateral is added to the form data to track collateral form validation within the Wizard
// It is set to "yes" in initialData of the Wizard, to allow users to continue because adding collaterals
// is optional. It is set to '' at the time of add collateral to disable next
// button in the wizard until the form is valid.
// See https://github.com/getkwara/webapp/pull/1529

Collateral.validate = ({ collaterals, isDoneAddingCollateral: isDoneAddingCollateralFormData }) => {
  const validations = {
    [isDoneAddingCollateral]: {
      isRequired: () => true
    }
  };

  if (collaterals != null) {
    const newCollaterals = !isDoneAddingCollateralFormData ? [...collaterals, {}] : collaterals;

    newCollaterals.forEach((_, index) => {
      validations[collateralKey(index, 'assetName')] = {
        isRequired: () => true
      };
      validations[collateralKey(index, 'amount')] = {
        isRequired: () => true,
        currency: true,
        nonZero: true
      };
      validations[collateralKey(index, 'assetBankName')] = {
        isRequired: (_, allData): boolean => requiredForCollateralBank(index, allData)
      };
      validations[collateralKey(index, 'assetBankBranch')] = {
        isRequired: (_, allData): boolean => requiredForCollateralBank(index, allData)
      };
      validations[collateralKey(index, 'assetBankAccount')] = {
        isRequired: (_, allData): boolean => requiredForCollateralBank(index, allData)
      };
    });
  }
  return validations;
};
