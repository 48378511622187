import * as React from 'react';

import { Currency, Text } from '@kwara/components/src/Intl';
import { AccountingReportT } from '@kwara/models/src';
import { AccountType } from '@kwara/models/src/models/AccountingReport';

import Table, { Row, Cell, Heading } from '../../../../components/Table';
import { TableSection } from './components/TableSection';
import styles from '../../index.module.scss';

function Income({ income = [], total }: { income: AccountType[]; total: number }) {
  return (
    <TableSection accounts={income} total={total} totalLabelId="AccountingReports.ProfitAndLoss.Table.totals.INCOME" />
  );
}

function Expenses({ expenses = [], total }: { expenses: AccountType[]; total: number }) {
  return (
    <TableSection
      accounts={expenses}
      total={total}
      totalLabelId="AccountingReports.ProfitAndLoss.Table.totals.EXPENSES"
    />
  );
}

export function ProfitLoss({ data }: { data: AccountingReportT }) {
  const { income, expenses, totals } = data.profitAndLoss;

  return (
    <Table
      heading={
        <Row>
          <Heading width="200px" translationId="AccountingReports.ProfitAndLoss.Table.header.glCode" />
          <Heading translationId="AccountingReports.ProfitAndLoss.Table.header.accountName" />
          <Heading align="right" translationId="AccountingReports.ProfitAndLoss.Table.header.amount" />
          <Heading width="75px" />
        </Row>
      }
    >
      <Income income={income} total={totals.income} />
      <Expenses expenses={expenses} total={totals.expenses} />
      <Row className={styles.totalRow}>
        <Cell />
        <Cell className={styles.totalLabel}>
          {<Text id="AccountingReports.ProfitAndLoss.Table.totals.NET_INCOME" />}
        </Cell>
        <Cell className="kw-text-medium b" align="right">
          <Currency hideDecimals={false} value={totals.net_income} />
        </Cell>
        <Cell />
      </Row>
    </Table>
  );
}
