// @flow

import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import Redeemer from '@kwara/components/src/InvitationRedeem/components/Redeemer';
import { LogoLayout } from '@kwara/components/src/LogoLayout';

import RedeemerUI from './RedeemerUI';
import { invitation } from '../../models';
import styles from './index.module.css';

const inviteToken = (searchParams: typeof window.location.search = window.location.search) => {
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get('t');
};

export const InvitationRedeem = () => (
  <LogoLayout classNames={cx(styles.Content, 'pa4')}>
    <div className={'flex flex-column'}>
      <h1 className="kw-text-super-large kw-weight-bold mt0 mb1">
        <Text id="InvitationRedeem.RedeemerUI.title" />
      </h1>
      <Redeemer ui={RedeemerUI} invitation={invitation} inviteToken={inviteToken()} />
    </div>
  </LogoLayout>
);
