// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import cx from 'classnames';

import { Loadable } from '@kwara/components/src/Loadable';
import { Card } from '@kwara/components/src/Card';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text, Currency } from '@kwara/components/src/Intl';

import { Grid } from '../../../../components/Grid';

import { SavingsCard } from './cards/SavingsCard';
import { LoansCard } from './cards/LoansCard';
import { SecuritiesCard } from './cards/SecuritiesCard';
import styles from '../index.module.scss';
import { Title } from '.';

export const MemberInfo = ({ StackChild, data, memberLoansR, memberSavingsR }) => {
  const { loan } = data;
  const { member } = loan;
  const loanApplication = get('loanApplication', loan);

  return (
    <>
      <Title StackChild={StackChild} name={member.fullName()} id={member.id} />

      <StackChild size="widest">
        <Card border={true} className="mb3">
          <Grid columns={5} width="w-20">
            <Statistic
              title={<Text id="LoanApprove.Overview.grossIncome" />}
              value={<Currency value={get('grossIncome', loanApplication)} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.netIncome" />}
              value={<Currency value={get('netIncome', loanApplication)} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.otherIncome" />}
              value={<Currency value={get('otherIncomeAmount', loanApplication)} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.otherDeductibles" />}
              value={<Currency value={get('otherDeductibles', loanApplication)} />}
              size="small"
              compact
            />
          </Grid>
        </Card>
      </StackChild>

      <Loadable {...memberLoansR}>
        {loans => (
          <Loadable {...memberSavingsR}>
            {savings => (
              <StackChild size="widest">
                <div className={'flex'}>
                  <SavingsCard member={member} savings={savings} className={cx(styles.Card, 'mr2 w-third')} />
                  <LoansCard member={member} loans={loans} className={cx(styles.Card, 'mr2 w-third')} />
                  <SecuritiesCard loan={loan} className={cx(styles.Card, 'mr2 w-third')} />
                </div>
              </StackChild>
            )}
          </Loadable>
        )}
      </Loadable>
    </>
  );
};
