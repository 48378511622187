import React, { useState } from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { createContext } from '@kwara/lib/src/utils/createContext';

import { Store } from '../../../../models/Store/Store';
import { idVerification, IDCheckResponse } from '../../../../models';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';

export type CreditCheckContextType = {
  shouldRetry: boolean;
  hasError: boolean;
  isCrbCheckEnabled: boolean;
  shouldVerify: boolean;
  canNext: boolean;
  onVerify(): Promise<IDCheckResponse>;
  onRetry(): Promise<IDCheckResponse>;
};

const [CreditCheckProvider, useCreditCheckContext] = createContext<CreditCheckContextType>('CreditCheck');

interface CreditCheckImpPropTypes extends Partial<LoanSubstepProps> {
  data: Record<string, any>;
  children(props: CreditCheckContextType): React.ReactElement;
}

function CreditCheckImp({ data, children }: CreditCheckImpPropTypes) {
  const { identityType, identityValue, loan } = data;

  const [hasError, setHasError] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [shouldRetry, setShouldRetry] = useState(false);
  const [shouldVerify, setShouldVerify] = useState(true);
  const profileContext: Store = useSaccoProfileContext();

  const onVerify = React.useCallback(async () => {
    try {
      const response = await idVerification.check({ identityType, identityValue, member: loan.member });
      setHasError(false);
      setCanNext(true);

      return response;
    } catch (error) {
      setHasError(true);
      setShouldVerify(false);
      setShouldRetry(prev => !prev);

      throw { errors: { code: 'CREDIT_CHECK_ERROR' } };
    }
  }, [identityType, identityValue, loan.member]);

  const values = React.useMemo(
    () => ({
      isCrbCheckEnabled: profileContext.isCrbCheckEnabled,
      hasError,
      shouldRetry,
      shouldVerify,
      canNext,
      onVerify,
      onRetry: onVerify
    }),
    [profileContext.isCrbCheckEnabled, hasError, shouldRetry, shouldVerify, canNext, onVerify]
  );

  return <CreditCheckProvider value={values}>{children(values)}</CreditCheckProvider>;
}

export { useCreditCheckContext, CreditCheckImp, CreditCheckProvider };
