import { PendingSavingsTransaction } from '@kwara/models/src';

import { useFilterContext } from '../../../../components/Filter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { DATE_NAMES } from '../../../../components/Filter/pages/DateBaseFilter/useDateBaseFilter';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../../../models/hooks/base';

const GET_PENDING_SAVING_TRANSACTIONS_QUERY_KEY = 'savings_transaction_review_set.user';
const includes = ['user', 'user.branch', 'loan_transaction_review_set.user'];

function fetchPendingSavingsTransactions(opts: PaginationQueryType) {
  return fetchWithPagination(PendingSavingsTransaction, opts);
}

function useFilter() {
  const { getValueFor } = useFilterContext();

  const status = getValueFor(FILTER_TYPES.STATUS);
  const filter = {
    filterValue: status as string,
    where: {
      state: status,
      branch_id: getValueFor(FILTER_TYPES.BRANCH_ID),
      search: getValueFor(FILTER_TYPES.TRANSACTION_SEARCH),
      from: getValueFor(FILTER_TYPES.DATE, DATE_NAMES.FROM),
      to: getValueFor(FILTER_TYPES.DATE, DATE_NAMES.TO)
    }
  };

  return filter;
}

function usePaginatedPendingSavingsTransactions() {
  return useOverviewList({
    queryKey: GET_PENDING_SAVING_TRANSACTIONS_QUERY_KEY,
    fetchFn: fetchPendingSavingsTransactions,
    includes,
    useWhere: useFilter
  });
}

export { GET_PENDING_SAVING_TRANSACTIONS_QUERY_KEY, usePaginatedPendingSavingsTransactions };
