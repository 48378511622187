import { minus } from '@kwara/lib/src/currency';


import { getIncomeValues } from '../../../lib/loanUtils/getIncomeValues';

import { type TwoThirdsRuleDataT } from '../components/TwoThirdsModal';

export function getTwoThirdsRuleValues({
  netIncome,
  otherDeductibles,
  otherIncomeAmount,
  repaymentAmount,
  totalMonthlyLoanPayments
}: TwoThirdsRuleDataT) {
  const { totalMonthlyNetIncome, totalAvailableForRepayment } = getIncomeValues({
    netIncome,
    otherDeductibles,
    otherIncomeAmount
  });
  const twoThirdsAvailable = (2 * Number(totalAvailableForRepayment)) / 3;
  const takeHomeIncome = minus(twoThirdsAvailable, totalMonthlyLoanPayments);
  const passesTwoThirdsRule = Number(takeHomeIncome) > Number(repaymentAmount);
  const difference = minus(repaymentAmount, takeHomeIncome);

  return {
    difference,
    passesTwoThirdsRule,
    totalAvailableForRepayment,
    twoThirdsAvailable,
    repaymentAmount,
    takeHomeIncome,
    totalMonthlyNetIncome
  };
}
