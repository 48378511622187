// @flow
import * as React from 'react';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import { type BankGlAccountT } from '@kwara/models/src';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';
import { Loadable } from '@kwara/components/src/Loadable';
import { filterGlAccounts, type GlContextsT } from '@kwara/models/src/models/BankGlAccount';
import { isFilled } from '@kwara/lib/src/lodash';
import { useBankGlAccounts } from '@kwara/models/src/models/request/hooks';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';

import { SubscribedSelectField as SelectField } from '.';

type FormData = {
  method: TransactionChannelT,
  allGlAccounts: BankGlAccountT[],
  context: GlContextsT,
  isTillOpen?: boolean
};

// This fetches the `BankGlAccount` entities,
// stored in Kwara DB.
export const GlAccountSelect = ({ method, addData = () => {}, context }) => {
  const r = useBankGlAccounts();
  React.useEffect(() => {
    if (r.data && !r.isLoading) {
      addData({ allGlAccounts: r.data, context });
    }
  }, [r.data, r.isLoading, addData, context]);

  return (
    <Loadable {...r}>
      {allGlAccounts => {
        // Not the best, but....once the GL Accounts are fetched, they are
        // added to the  form data so they can be accessed by the validator

        const glAccounts = filterGlAccounts(allGlAccounts, method, context);
        const hasGlAccounts = isFilled(glAccounts);

        return hasGlAccounts ? (
          <SelectField
            required
            name="bankGlId"
            labelId="PaymentForm.glAccount.labelId"
            errorBaseId="PaymentForm.glAccount"
          >
            <SelectField.Option translationId="PaymentForm.glAccount.default" value="" />
            {map(
              gl => (
                <SelectField.Option key={gl.id} value={gl.id}>
                  {gl.accountName}
                </SelectField.Option>
              ),
              glAccounts
            )}
          </SelectField>
        ) : null;
      }}
    </Loadable>
  );
};

GlAccountSelect.validate = {
  bankGlId: {
    isRequired: (_: string, data: FormData) => {
      const { isTillOpen, allGlAccounts, method, paymentMethod, context } = data;

      // HOT FIX: we accept `method` or `paymentMethod`, as both are in use
      // by forms that consume this component.
      const methodData = method || paymentMethod;

      if (isTillOpen && methodData === TransactionChannels.cash) {
        return false;
      }

      return isFilled(filterGlAccounts(allGlAccounts, methodData, context));
    },
    custom: (bankGlId: string, data: FormData) => {
      const { allGlAccounts, method, paymentMethod, context, isTillOpen } = data;

      const methodData = method || paymentMethod;

      if (isTillOpen && methodData === TransactionChannels.cash) {
        return null;
      }

      const validGlAccounts = filterGlAccounts(allGlAccounts, methodData, context);
      if (!some(gl => gl.id === bankGlId, validGlAccounts)) {
        return 'invalidGlAccountType';
      }
      return null;
    }
  }
};
