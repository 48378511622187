import React from 'react';

import { LoanDisbursementType } from '@kwara/models/src/models/LoanDisbursement';

import { Footer } from '../../../../../../../components/Table';

export type TFooterPropTypes = {
  onNext(): void;
  hasMore: boolean;
  isLoading: boolean;
  items: LoanDisbursementType[];
};

export function TFooter(props: TFooterPropTypes) {
  return <Footer colSpan={11} {...props} />;
}
