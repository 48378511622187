import { ValueOf } from 'GlobalTypes';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';

export const REPORT_TYPE = Object.freeze({
  DIVIDEND: 'dividend',
  INTEREST: 'interest'
});

export type ReportType = ValueOf<typeof REPORT_TYPE>;

export function useReportType(productId: string | undefined) {
  const store = useSaccoProfileContext();
  if (!productId) {
    return REPORT_TYPE.DIVIDEND;
  }

  const shareCapitalProductId = store.shareCapitalProductId;
  return productId === shareCapitalProductId ? REPORT_TYPE.DIVIDEND : REPORT_TYPE.INTEREST;
}
