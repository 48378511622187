import React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { IconInfo } from '@kwara/components/src/Asset/IconInfo';

import styles from './index.module.scss';

export type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
export type ProductCardElement = React.ElementRef<'div'>;
/**
 * @ProductCardPropTypes
 *
 * ```ts
 * @prop {title:React.ReactNode}
 * @prop {amount:React.ReactElement | number}
 * @prop {state?: React.ReactNode}
 * @prop {info?: React.ReactNode}
 * @prop {onInfo?: React.MouseEventHandler<HTMLSpanElement>}
 *
 * @Note @onInfo and @info can't be used together. see @onInfo as a controlled InfoIcon and @info as unControlled InfoIcon
 * ...
 * ```
 */
interface ProductCardPropTypes extends Omit<PrimitiveDivProps, 'title'> {
  title: React.ReactNode;
  amount: React.ReactElement | number;
  state?: React.ReactNode;
  info?: React.ReactNode;
  onInfo?: React.MouseEventHandler<HTMLSpanElement>;
  size?: 'standard' | 'responsive';
  subValue?: React.ReactElement;
}
const widthClassToClassName = {
  standard: styles['width--standard'],
  responsive: styles['width--responsive']
};

export const ProductCard = React.forwardRef<ProductCardElement, ProductCardPropTypes>(function ProductCard(
  { title, amount, state, info, subValue, className, onInfo, size = 'standard', ...restProps },
  forwardedRef
) {
  return (
    <div {...restProps} ref={forwardedRef} className={cx(styles['card'], widthClassToClassName[size], className)}>
      <div className="w-100 flex justify-between">
        <span className={cx('kw-text-small neutral-600', styles['title'])}>
          {title}

          <If
            condition={!!onInfo}
            do={
              <span role="button" tabIndex={-1} className={styles['info']} data-testid="proCdInfoBtn" onClick={onInfo}>
                <IconInfo />
              </span>
            }
          />
        </span>

        <div>
          {state}

          {info}
        </div>
      </div>
      <div>
        <span className={cx('kw-text-x-large black', { [styles['font--responsive']]: size === 'responsive' })}>
          {amount}
        </span>
        <If
          condition={!!subValue}
          do={<span className={cx('ml2 kw-text-medium kw-weight-light neutral-800')}>{subValue}</span>}
        />
      </div>
    </div>
  );
});
