// @flow
import * as React from 'react';

import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { type TillTransactionT } from '@kwara/models/src';
import { Date, Text, Time } from '@kwara/components/src/Intl';

import { Currency } from '../../../components/Currency';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';

const renderRow = (transaction: TillTransactionT) => (
  <Row key={transaction.id}>
    <Cell>
      <div className="grey-300">{transaction.transactionId}</div>
      <div>
        <Date value={transaction.createdAt} />
      </div>
      <div>
        <Time value={transaction.createdAt} />
      </div>
    </Cell>
    <Cell>
      {transaction.isUtility() ? (
        transaction.name
      ) : (
        <>
          <div>{invoke('member.fullName', transaction)}</div>
          <div className="grey-400">{get('member.id', transaction)}</div>
        </>
      )}
    </Cell>
    <Cell>
      <div className="grey-300">{transaction.reference}</div>
      <span>{transaction.paymentMethod}</span> <Text id={`TransactionType.${transaction.type}`} />
      {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
    </Cell>
    <Cell align="right">
      <Currency value={transaction.approvedAmount ?? transaction.requestedAmount} />
    </Cell>
  </Row>
);

const heading = (
  <Row>
    <Heading translationId="TransactionsTable.date" />
    <Heading translationId="TransactionsTable.name" />
    <Heading className="w-25" translationId="TransactionsTable.type" />
    <Heading align="right" translationId="TransactionsTable.amount" />
    <Heading iconSpacer />
  </Row>
);

export const Transactions = ({ transactions }: { transactions: TillTransactionT[] }) => {
  return <TransactionsTable initiallyOpen heading={heading} renderRow={renderRow} transactions={transactions} />;
};
