import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconDashboardElement = React.ElementRef<'svg'>;
interface IconDashboardPropTypes extends PrimitiveSvgPropTypes {}

export const IconDashboard = React.forwardRef<IconDashboardElement, IconDashboardPropTypes>(function IconDashboard(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 19.9V4.1C11.5 2.6 10.86 2 9.27 2H5.23C3.64 2 3 2.6 3 4.1V19.9C3 21.4 3.64 22 5.23 22H9.27C10.86 22 11.5 21.4 11.5 19.9Z"
        fill="#475569"
      />
      <path
        d="M22 10.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1V10.9C13.5 12.4 14.14 13 15.73 13H19.77C21.36 13 22 12.4 22 10.9Z"
        fill="#475569"
      />
      <path
        d="M22 19.9V17.1C22 15.6 21.36 15 19.77 15H15.73C14.14 15 13.5 15.6 13.5 17.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9Z"
        fill="#475569"
      />
    </svg>
  );
});

IconDashboard.defaultProps = {
  width: '25',
  height: '24'
};
