//@flow
import * as React from 'react';

import ScrollIntoFocus from '@kwara/components/src/ScrollIntoFocus';
import { type TranslationId } from '@kwara/components/src/Intl';

import { ActionModal } from '../ActionModal';
import { AutoDismissableCompletion } from '../Completion';
import ModelErrorBanner from '../ModelErrorBanner';
import { type LoanType, type MemberType, type ModelErrors, type SavingType } from '@kwara/models/src';

import LoanPanel from './LoanPanel';
import MemberPanel from './MemberPanel';
import NotesPanel from './NotesPanel';
import { SavingPanel } from './SavingPanel';
export { default as LoanPanel } from './LoanPanel';
export { default as MemberPanel } from './MemberPanel';
export { SavingPanel } from './SavingPanel';
export { default as NotesPanel } from './NotesPanel';

const PaymentTypes = {
  deposit: 'deposit',
  disbursement: 'disbursement',
  repayment: 'repayment',
  withdrawal: 'withdrawal',
  penalty: 'penalty'
};

type Props = {
  children?: React.Node,
  confirmSubtitle?: React.Node,
  errors: ?ModelErrors,
  errorValues?: ?{ [key: string]: ?string | ?number },
  isProcessing?: boolean,
  loan?: LoanType,
  member?: MemberType,
  saving?: SavingType,
  invalid: boolean,
  onCancel: () => void,
  onConfirm: () => mixed,
  success: boolean,
  titleId?: TranslationId,
  type: $Values<typeof PaymentTypes>
};

const Payment = ({
  errors,
  errorValues = {},
  onCancel,
  onConfirm,
  titleId,
  confirmSubtitle,
  success,
  invalid,
  isProcessing = false,
  type,
  loan,
  member,
  saving,
  children
}: Props) => {
  const content = success ? (
    <AutoDismissableCompletion
      autoconfirm
      subtitle={confirmSubtitle}
      type={AutoDismissableCompletion.Types.good}
      onConfirm={onConfirm}
    />
  ) : (
    <>
      {errors != null ? (
        <ScrollIntoFocus>
          <ModelErrorBanner className="mv4" errors={errors} errorValues={errorValues} />
        </ScrollIntoFocus>
      ) : null}

      {member && (
        <MemberPanel
          member={member}
          showContactInfo={type === PaymentTypes.disbursement}
          showFinancialInfo={type !== PaymentTypes.disbursement}
        />
      )}

      {loan && <LoanPanel loan={loan} />}

      {saving && <SavingPanel saving={saving} />}

      {children}

      <NotesPanel />
    </>
  );

  return (
    <ActionModal
      header={!success}
      actions={!success}
      titleId={titleId || 'Payment.title'}
      onCancel={onCancel}
      onConfirm={onConfirm}
      disabled={isProcessing || invalid}
    >
      {content}
    </ActionModal>
  );
};

Payment.Type = PaymentTypes;

export default Payment;
