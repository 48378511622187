import { useMemo } from 'react';
import { addDays, isSameDay, isAfter } from 'date-fns';

export function useGetRepaymentBefore(anticipatedDisbursementDate: string | null) {
  return useMemo(() => {
    if (!anticipatedDisbursementDate) return null;

    const parsedDisbursementDate = new Date(anticipatedDisbursementDate);
    const today = new Date();
    const isDisDateGreaterOrEqualToday =
      isSameDay(parsedDisbursementDate, today) || isAfter(parsedDisbursementDate, today);

    return isDisDateGreaterOrEqualToday ? addDays(parsedDisbursementDate, 1) : addDays(today, 1);
  }, [anticipatedDisbursementDate]);
}
