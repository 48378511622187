import React from 'react';
import map from 'lodash/fp/map';
import cx from 'classnames';
import { LegendProps, ResponsiveContainer, TooltipProps } from 'recharts';

import { Currency } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { getYear, getMonthInWord } from '@kwara/lib/src/dates';

import styles from '../../index.module.scss';

type PayloadT = {
  coordinate: number;
  index: number;
  offset: number;
  value: string;
};
type TickPropTypes = {
  x: string;
  y: string;
  payload: PayloadT;
};

type CustomizedResponsiveContainerPropTypes = {
  children: React.ReactElement;
  className?: string;
};
function CustomizedResponsiveContainer({ children, className }: CustomizedResponsiveContainerPropTypes) {
  return (
    <div className={cx(styles['responsive-container'], className)}>
      <ResponsiveContainer width="100%" height="100%">
        {children}
      </ResponsiveContainer>
    </div>
  );
}

interface CustomizedXAxisTickPropTypes extends TickPropTypes {
  abbrMonth?: boolean;
}

function CustomizedXAxisTick(props: CustomizedXAxisTickPropTypes) {
  const { x, y, payload, abbrMonth } = props;
  const month = getMonthInWord(payload.value, abbrMonth);
  const year = getYear(payload.value);

  return (
    <g transform={`translate(${x},${y})`} className="kw-text-x-small kw-weight-bold">
      <text x={0} y={0} dy={16} textAnchor="end" fill="#6A707E">
        <tspan x="0" dy="1.2em">
          {month}
        </tspan>
        <tspan x="0" dy="1.2em">
          {year}
        </tspan>
      </text>
    </g>
  );
}

function CustomizedYAxisTick({ x, y, payload }: TickPropTypes) {
  return (
    <g transform={`translate(${x},${y})`} className="kw-text-x-small kw-weight-bold">
      <text x={0} y={0} textAnchor="end" fill="#6A707E">
        {payload.value}
      </text>
    </g>
  );
}

function renderCustomizedLegend(props: LegendProps) {
  const { payload } = props;

  return (
    <ul className={cx(styles.legend, 'kw-text-x-small')}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="flex items-center">
          <div className={cx(styles.square, 'mr1')} style={{ backgroundColor: `${entry.color}` }}></div>
          {entry.value}
        </li>
      ))}
    </ul>
  );
}

function renderCustomizedLegendForPieCharts(data) {
  return (
    <ul className={cx('flex flex-column justify-center kw-text-x-small')}>
      {data.map((entry, index) => (
        <li key={`item-${index}`} className="flex nowrap items-center mt2">
          <span className={cx(styles.square, 'mr1')} style={{ backgroundColor: `${entry.color}` }}></span>
          {entry.name}
        </li>
      ))}
    </ul>
  );
}

type ValueType = number | string | Array<number | string>;
type NameType = number | string;
export type CustomTooltipProps<TValue extends ValueType, TName extends NameType> = TooltipProps<TValue, TName> & {
  isCurrency: boolean;
};
function renderCustomizedTooltip(props: CustomTooltipProps<ValueType, NameType>) {
  const { active, payload, label, isCurrency = false } = props;

  return (
    <If
      condition={active}
      do={
        <div className="bg-white pa1 kw-text-small flex flex-column b--pink">
          {label}
          {map(p => {
            return (
              <div key={`${p.name}.${p.value}`}>
                <span style={{ color: p.color }}>{p.name}</span>
                {' : '}
                <If condition={isCurrency} do={<Currency value={p.value} format={'currency'} />} else={p.value} />
              </div>
            );
          }, payload)}
        </div>
      }
    />
  );
}

const customizedMarginSetup = {
  top: 0,
  right: 25,
  left: 0,
  bottom: 35
};

export {
  customizedMarginSetup,
  renderCustomizedLegend,
  renderCustomizedTooltip,
  renderCustomizedLegendForPieCharts,
  CustomizedResponsiveContainer,
  CustomizedYAxisTick,
  CustomizedXAxisTick
};
