import * as React from 'react';
import map from 'lodash/fp/map';

import { Text, getTextForIdentityType } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { MemberType } from '@kwara/models/src';

import { Grid } from '../../Grid';
import { EditableSection, EditableConfig } from '../../EditableSection/v1/EditableSection';

import IdentityForm, { IdentityPropTypes } from '../../../pages/MemberAdd/components/Identity';

const base = {
  Component: IdentityForm,
  validate: IdentityForm.validateConfig.memberAdd
};

const memberDetail = {
  Component: (props: IdentityPropTypes) => <IdentityForm {...props} memberEdit />,
  validate: IdentityForm.validateConfig.memberDetail
};

const loanAdd = {
  ...base,
  validate: IdentityForm.validateConfig.loanAdd
};

export const Identity = ({ config = memberDetail, member, readOnly }: EditableConfig<MemberType>) => {
  return (
    <EditableSection
      config={config}
      initialData={member}
      readOnly={readOnly}
      onSave={async (data: MemberType) => data.save({ with: ['idDocuments'] })}
    >
      <Grid columns={2} width="w-50">
        {map(
          document => (
            <Statistic
              key={`${document.documentId}${document.type}`}
              title={getTextForIdentityType(document.type)}
              value={document.documentId}
              size={'medium'}
            />
          ),
          member.idDocuments
        )}
        <Statistic title={<Text id={`AddMember.Identity.KRA.label`} />} value={member.kraPin} size={'medium'} />
      </Grid>
    </EditableSection>
  );
};

Identity.Title = () => <Text id="MemberDetail.identification" />;
Identity.editConfig = { memberDetail, loanAdd };
