import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import map from 'lodash/fp/map';

import { CheckableItemStates } from '@kwara/models/src/models/CheckableItem';

import { ComboboxBaseFilter } from '../../../../components/Filter/pages';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { StatusFilter } from '../../../Members/components/MemberList/StatusFilter';
import { useBranchesFilter } from '../../../../components/BranchesFilter/useBranchesFilter';

export function useCheckableJournalEntryFilters() {
  const int = useIntl();
  const branchesFilter = useBranchesFilter(FILTER_TYPES.PENDING_JOURNAL_BRANCH_ID);

  const statusFilter = useMemo(
    () =>
      map(
        state => ({
          value: state,
          name: int.formatMessage({ id: `Finance.Transactions.List.filter.${state}` }),
          belongsTo: FILTER_TYPES.STATUS
        }),
        CheckableItemStates
      ),
    [int]
  );

  return {
    filters: {
      [FILTER_TYPES.STATUS]: { data: statusFilter, page: StatusFilter },
      [FILTER_TYPES.PENDING_JOURNAL_BRANCH_ID]: {
        data: branchesFilter.allBranches,
        page: () => (
          <ComboboxBaseFilter
            filterType={FILTER_TYPES.PENDING_JOURNAL_BRANCH_ID}
            placeholderId="Filter.pendingJournalBranchId"
          />
        )
      }
    },
    selectedFilters: {
      [FILTER_TYPES.STATUS]: [
        {
          value: CheckableItemStates.pending,
          name: int.formatMessage({ id: 'Finance.Transactions.List.filter.pending_review' }),
          belongsTo: FILTER_TYPES.STATUS
        }
      ]
    }
  };
}
