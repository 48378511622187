import { LoanTopupDisbursalStepPropTypes } from '..';
import { useAnticipatedPayoutApiFetch } from '../../Loan/hooks';
import { useFeesForm } from '../../Loan/LoanAdd/components/LoanConfigure/useFeesForm';

import { FloatBalanceType } from '@kwara/models/src/models/FloatBalance';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';
import { PhoneNumberInfoType } from '@kwara/models/src/models/PhoneNumberInfo';
import { useFloatBalance } from '@kwara/models/src/models/FloatBalance/useFloatBalance';
import { useGetPhoneNumberInfo } from '@kwara/models/src/models/PhoneNumberInfo/useGetPhoneNumberInfo';

const ZERO_AMOUNT = 0;

export function useBaseLogic(arg: LoanTopupDisbursalStepPropTypes) {
  const {
    data: {
      feeApplications,
      loan: { product, pendingDisbursementAmount, member, loanApplication }
    },
    formProps: {
      values: { floatBalance, paymentMethod }
    },
    addData,
    onChange,
    enableNextButton,
    disableNextButton
  } = arg;
  const { payOffLoans = [] } = loanApplication;
  const amount = String(pendingDisbursementAmount);
  const isMobileMpesaInstant = paymentMethod === TransactionChannels.mobileMpesaInstant;
  const {
    fees,
    selectedFees,
    totalAdditionalFees,
    getFee,
    onFeeAdd,
    onRemove,
    isFeeOptional,
    isFeeNotSelected,
    getFeeApplication
  } = useFeesForm({
    onChange,
    product,
    feeApplications,
    amount
  });

  const { anticipatedPayout } = useAnticipatedPayoutApiFetch({
    amount,
    member,
    product,
    totalAdditionalFees,
    payOffLoans: payOffLoans.map(loan => loan.id),
    handlers: {
      onStart: disableNextButton,
      onError: _ => enableNextButton(),
      async onSuccess({ data: { attributes } }) {
        await addData({ anticipatedPayout: attributes.anticipated_payout_amount });
        enableNextButton();
      }
    }
  });

  useGetPhoneNumberInfo(member.phoneNumber, {
    onStart: disableNextButton,
    onError: _ => enableNextButton(),
    async onSuccess(data: PhoneNumberInfoType) {
      await addData({ phoneNumberProvider: data.carrier });
      await onChange({ phoneNumberProvider: data.carrier });
      enableNextButton();
    }
  });

  const { isLoading: isLoadingFloatBalance, error: floatBalanceError } = useFloatBalance({
    onStart: disableNextButton,
    onError: _ => enableNextButton(),
    async onSuccess(data: FloatBalanceType) {
      await addData({ floatBalance: data.amount });
      await onChange({ floatBalance: data.amount });
      enableNextButton();
    },
    enabled: isMobileMpesaInstant
  });

  const feesToShow = fees.filter(isFeeOptional).filter(isFeeNotSelected);
  const disbursementAmount = Number(pendingDisbursementAmount);
  const showMobileMoneyAmountTopError =
    isMobileMpesaInstant && disbursementAmount > floatBalance && anticipatedPayout <= ZERO_AMOUNT;

  return {
    isMobileMpesaInstant,
    feesToShow,
    selectedFees,
    disbursementAmount,
    showMobileMoneyAmountTopError,
    isLoadingFloatBalance,
    floatBalanceError,
    getFee,
    onFeeAdd,
    onRemove,
    getFeeApplication
  };
}
