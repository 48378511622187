import pick from 'lodash/fp/pick';

import { LoanFormData } from 'LoanSharedTypes';

import { TransactionChannels, TransactionChannelT } from '@kwara/models/src/models/Transactions';

export interface DataType extends LoanFormData {
  paymentMethod?: TransactionChannelT;
  [key: string]: unknown;
}

export function getPaymentInfo(data: DataType, phoneNumber: string) {
  const paymentInfo = pick(
    ['reference', 'bankName', 'bankBranch', 'chequeNumber', 'drawer', 'accountNumber', 'accountId', 'bankGlId'],
    data
  );

  if (data.paymentMethod === TransactionChannels.mobileMpesaInstant) {
    return {
      ...paymentInfo,
      onlinePayoutDetails: {
        account_number: phoneNumber
      },
      paymentMethod: 'online_mobile_payout'
    };
  }

  return { ...paymentInfo, paymentMethod: data.paymentMethod };
}
