import { useCallback, useMemo, useRef, useState } from 'react';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';

import { NotificationSystemContextType } from '..';
import { useIsAnnouncementPermitted } from './useIsAnnouncementPermitted';
import { useNotificationSystemCommands } from './useNotificationSystemCommands';
import { DataType, NotificationSystem as NotificationSystemModel } from '../models/NotificationSystem';

export function useNotificationSystemContextValues(dataParam?: DataType) {
  const { current } = useRef<NotificationSystemModel>(new NotificationSystemModel(dataParam));
  const commands = useNotificationSystemCommands();
  const [headingId, bodyId] = useUniqueIds(2);
  const checkIsPermitted = useIsAnnouncementPermitted();

  const [_, setDeleteAnnouncementIds] = useState<string[]>(() => commands.getDeletedAnnouncementsIds() ?? []);
  const [data, setData] = useState(() => {
    function getAnnouncements() {
      const announcements = current.data.announcements;
      const permittedAnnouncements = announcements ? announcements.filter(a => checkIsPermitted(a.permissions)) : null;

      if (!permittedAnnouncements || !permittedAnnouncements?.length) return null;

      const sortedAnnouncementByDate = permittedAnnouncements.sort((firstAnn, secAnn) => {
        const firstAnnDate = new Date(firstAnn.date);
        const secondAnnDate = new Date(secAnn.date);

        if (firstAnnDate > secondAnnDate) return -1;
        if (firstAnnDate < secondAnnDate) return 1;
        return 0;
      });

      return sortedAnnouncementByDate;
    }

    return { ...current.data, announcements: getAnnouncements() };
  });
  const [readIds, setReadIds] = useState<string[]>(() => current.getReadIds() ?? []);
  const iconRef = useRef<HTMLButtonElement>(null);
  const [
    shownAnnouncements,
    { setToFalse: onHideAnnouncements, setToTrue: onShowAnnouncement, toggle: onToggleShownAnnouncement }
  ] = useBoolean();

  ///
  function onDeleteAnnouncementSuccess(deletedIds: string[]) {
    setDeleteAnnouncementIds(_ => [...deletedIds]);
    setData(data => ({
      ...data,
      announcements: data.announcements.filter(announcement => !deletedIds.includes(announcement.id))
    }));
  }

  const onDeleteAnnouncement = useCallback(
    (id: string) => {
      commands.onDeleteAnnouncement(id, onDeleteAnnouncementSuccess);
    },
    [commands]
  );

  ///
  function onReadAnnouncementSuccess(id: string) {
    setReadIds(ids => [...ids, id]);
  }

  const onReadAnnouncement = useCallback(
    (id: string) => {
      commands.onReadAnnouncement(id, onReadAnnouncementSuccess);
    },
    [commands]
  );

  ///
  function onMarkAllAnnouncementAsReadSuccess(ids: string[]) {
    setReadIds(_ => [...ids]);
  }

  const onMarkAllAnnouncementAsRead = useCallback(() => {
    commands.onMarkAllAnnouncementAsRead(data.announcements, onMarkAllAnnouncementAsReadSuccess);
  }, [commands, data.announcements]);

  const hasReadAnnouncement = !!readIds.length;
  const hasAnnouncements = data.announcements != null && data.announcements.length > 0;

  ///
  const checkHasReadAllAnnouncement = useCallback(() => {
    if (!hasAnnouncements) return true;

    if (hasAnnouncements && !hasReadAnnouncement) return false;

    return data.announcements.every(a => readIds.includes(a.id));
  }, [data.announcements, hasAnnouncements, hasReadAnnouncement, readIds]);

  const values: NotificationSystemContextType = useMemo(
    () => ({
      data,
      readIds,
      headingId,
      bodyId,
      iconRef,
      shownAnnouncements,
      hasAnnouncements,
      hasReadAllAnnouncement: checkHasReadAllAnnouncement(),
      onDeleteAnnouncement,
      onReadAnnouncement,
      onHideAnnouncements,
      onShowAnnouncement,
      onToggleShownAnnouncement,
      onMarkAllAnnouncementAsRead,
      onResetNotificationSystem: commands.onResetNotificationSystem
    }),
    [
      data,
      readIds,
      headingId,
      bodyId,
      shownAnnouncements,
      hasAnnouncements,
      checkHasReadAllAnnouncement,
      onDeleteAnnouncement,
      onReadAnnouncement,
      onHideAnnouncements,
      onShowAnnouncement,
      onToggleShownAnnouncement,
      onMarkAllAnnouncementAsRead,
      commands.onResetNotificationSystem
    ]
  );

  return values;
}
