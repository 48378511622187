// @flow
import * as React from 'react';
import get from 'lodash/fp/get';
import noop from 'lodash/fp/noop';

import { LoanType } from '@kwara/models/src';
import { fields as repaymentFields } from '@kwara/models/src/models/Remittance';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { setRemittanceDetails } from '@kwara/lib/src/remittance';

import { Repayment as RepaymentForm } from '../../Loan/LoanAdd/components/Repayment';
import { excludedRepaymentFields } from '../../../lib/modelUtils/loan';
import { EditableSection, Updater } from '../../../components/EditableSection/v1/EditableSection';
import { store } from '../../../models/Store/Store';
import { usePermissions } from '../../../hooks';

const fields = repaymentFields.remittance;

export const Content = ({ loan, className = '' }: { loan: LoanType, className?: string }) => {
  const { member = {}, remittance = {} } = loan;
  const excludedField = excludedRepaymentFields(loan);

  const { method, amount, bankAccount } = remittance;

  return (
    <div className={className}>
      <StatisticRow widthClass="w-100" compact>
        <Statistic
          title={<Text id="LoanDetail.Repayment.mode" />}
          value={method ? <Text id={`TransactionMethod.${method}`} /> : null}
        />
        <Statistic
          title={<Text id="LoanDetail.Repayment.amount" />}
          value={amount ? <Currency value={amount} /> : null}
        />
      </StatisticRow>
      {excludedField('member.employer') && excludedField('member.staffId') ? null : (
        <StatisticRow widthClass="w-50" compact>
          <Statistic
            title={<Text id="LoanDetail.Repayment.employerName" />}
            value={member.employer}
            hidden={excludedField('member.employer')}
          />
          <Statistic
            title={<Text id="LoanDetail.Repayment.staffId" />}
            value={member.staffId}
            hidden={excludedField('member.staffId')}
          />
        </StatisticRow>
      )}

      <StatisticRow widthClass="w-50" compact>
        <Statistic
          title={<Text id="PaymentForm.bank" />}
          value={get('bankBranch.bank.name', bankAccount)}
          hidden={excludedField(fields.bank)}
        />
        <Statistic
          title={<Text id="PaymentForm.bankBranch" />}
          value={get('bankBranch.name', bankAccount)}
          hidden={excludedField(fields.branch)}
        />
        <Statistic
          title={<Text id="PaymentForm.accountNumber" />}
          value={get('accountNumber', bankAccount)}
          hidden={excludedField(fields.account)}
        />
        <Statistic
          title={<Text id="LoanDetail.Repayment.collectingBank" />}
          value={get('collectingBank.name', remittance)}
          hidden={excludedField(fields.collectingBank)}
        />
      </StatisticRow>
    </div>
  );
};

export const Repayment = ({
  loan,
  refetch = noop,
  readOnly: readOnlyProp
}: {
  loan: LoanType,
  refetch?: () => void,
  readOnly?: boolean
}) => {
  const { permission, AppPermissions } = usePermissions();

  const readOnly = readOnlyProp || !permission.to(AppPermissions.EditLoanRemittance);

  return (
    <Updater value={{ onUpdate: refetch }}>
      <EditableSection
        initialData={loan}
        readOnly={readOnly}
        onSave={loan => {
          setRemittanceDetails({
            remittance: loan.remittance,
            data: loan,
            bankAccounts: loan.bankAccounts,
            store
          });

          return loan.save({
            with: { remittance: ['bankAccount', 'collectingBank'] }
          });
        }}
        config={{
          Component: RepaymentForm,
          validate: RepaymentForm.validateConfig.loanEdit
        }}
      >
        <Content loan={loan} />
      </EditableSection>
    </Updater>
  );
};
