import * as React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Portal } from '@kwara/components/src/Portal/Portal';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { IconCancel } from '../../assets';
import { useInternetNotifier, INTERNET_STATES } from './hooks/useInternetNotifier';

import iconKwaraBrand from '../../assets/imgs/IconKwaraBrand.png';
import styles from './index.module.scss';

export function InternetNotifier() {
  const { shownNotifier, titleId, msgId, state, onHideNotifier } = useInternetNotifier();

  return (
    <If
      condition={shownNotifier}
      do={
        <Portal elementType="internetnotifier">
          <div className={cx(zIndices['Max'], styles['container'])}>
            <div
              className={cx(
                styles['container__row1'],
                styles[`container__row1--${state.toLocaleLowerCase().replace('_', '-')}`]
              )}
            >
              <img src={iconKwaraBrand} alt="" />
            </div>

            <div className={styles['container__row2']}>
              <div className="flex justify-end">
                <AccessibleIcon
                  role="button"
                  tabIndex={-1}
                  label={<Text id="Accessibility.clickToCloseNotification" />}
                >
                  <span role="button" tabIndex={-1} className="pointer" data-testid="closeBtn" onClick={onHideNotifier}>
                    <IconCancel />
                  </span>
                </AccessibleIcon>
              </div>

              <h1 className="neutral-900 kw-text-large kw-weight-bold pa0 ma0 pb2">
                <Text id={titleId} />
              </h1>

              <p className="kw-text-medium neutral-500 pa0 ma0">
                <Text id={msgId} />
              </p>

              <If
                condition={state === INTERNET_STATES.GONE_OFFLINE}
                do={
                  <LinkButton
                    as="button"
                    className="mt1"
                    data-testid="retryBtn"
                    onClick={() => window.location.reload()}
                  >
                    <Text id="General.Retry" />
                  </LinkButton>
                }
              />
            </div>
          </div>
        </Portal>
      }
    />
  );
}
