import * as React from 'react';
import cx from 'classnames';
import find from 'lodash/find';

import { appName } from '@kwara/lib/src/utils';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import Action from '../../utils/action';

import styles from './index.module.scss';

///UTILS BELOW
/********************************************************** */
const PRIMARY_BUTTON_TYPES = Object.freeze({
  next: 'next',
  skip: 'skip',
  close: 'close',
  print: 'print',
  verify: 'verify',
  review: 'review',
  submit: 'submit',
  approve: 'approve',
  processing: 'processing',
  retry: 'retry'
});

const PRIMARY_BUTTON_LABELS = {
  [PRIMARY_BUTTON_TYPES.skip]: <Text id="WizardActions.skip" />,
  [PRIMARY_BUTTON_TYPES.next]: <Text id="WizardActions.next" />,
  [PRIMARY_BUTTON_TYPES.print]: <Text id="WizardActions.print" />,
  [PRIMARY_BUTTON_TYPES.close]: <Text id="WizardActions.close" />,
  [PRIMARY_BUTTON_TYPES.submit]: <Text id="WizardActions.submit" />,
  [PRIMARY_BUTTON_TYPES.verify]: <Text id="WizardActions.verify" />,
  [PRIMARY_BUTTON_TYPES.review]: <Text id="WizardActions.review" />,
  [PRIMARY_BUTTON_TYPES.approve]: <Text id="WizardActions.approve" />,
  [PRIMARY_BUTTON_TYPES.retry]: <Text id="WizardActions.retry" />
};

const PRIMARY_BUTTON_GLYPH_IDS = {
  [PRIMARY_BUTTON_TYPES.print]: null,
  [PRIMARY_BUTTON_TYPES.verify]: null,
  [PRIMARY_BUTTON_TYPES.submit]: null,
  [PRIMARY_BUTTON_TYPES.submit]: null,
  [PRIMARY_BUTTON_TYPES.approve]: null,
  [PRIMARY_BUTTON_TYPES.retry]: null,
  [PRIMARY_BUTTON_TYPES.next]: Button.Glyphs.ArrowRight,
  [PRIMARY_BUTTON_TYPES.review]: Button.Glyphs.ArrowRight
};

// Hidden if not permitted or if hidden
function isVisible(action: Action | null) {
  if (action == null) {
    return false;
  }
  return action.isPermitted && !action.isHidden;
}

///COMPONENT BELOW
/********************************************************** */
type WizardFooterPropTypes = {
  actions: Action[];
  className?: string;
  nextDisabled: boolean;
  isProcessing?: boolean;
  innerClassName?: string;
  hideActions?: boolean;
  onAction: (action: Action) => void;
};

export function WizardFooter(props: WizardFooterPropTypes) {
  const { actions, isProcessing, className, innerClassName, nextDisabled, hideActions, onAction } = props;
  const [activeAction, setActiveAction] = React.useState<'approve' | 'reject' | null>(null);
  const buttonSize = appName.isMember ? 'regular' : 'medium';

  ///LEFT BUTTONS BELOW I.E SECONDARY BUTTONS
  const back = find(actions, { appearsAs: 'back' });
  const backButton =
    back != null && isVisible(back) ? (
      <Button
        type="secondary"
        size={buttonSize}
        aria-label="Back"
        disabled={isProcessing}
        onClick={() => onAction(back)}
        glyphLeftId={Button.Glyphs.ArrowLeft}
      >
        <Text id="WizardActions.back" />
      </Button>
    ) : null;

  ///
  const cancel = find(actions, { appearsAs: 'cancel' });
  const cancelButton =
    cancel != null && isVisible(cancel) ? (
      <Button
        type="secondary"
        size={buttonSize}
        aria-label="Cancel"
        disabled={isProcessing}
        onClick={() => onAction(cancel)}
      >
        <Text id="WizardActions.cancel" />
      </Button>
    ) : null;

  /// RIGHT BUTTONS BELOW I.E PRIMARY BUTTONS
  const close = find(actions, { appearsAs: 'close' });
  const closeButton =
    close != null && isVisible(close) ? (
      <Button
        type="destructive"
        aria-label="Close"
        size={buttonSize}
        onClick={() => onAction(close)}
        disabled={isProcessing || nextDisabled}
      >
        {PRIMARY_BUTTON_LABELS.close}
      </Button>
    ) : null;

  ///
  const reject = find(actions, { appearsAs: 'reject' });
  const rejectButton =
    reject != null && isVisible(reject) ? (
      <Button
        type="destructive"
        aria-label="Reject"
        size={buttonSize}
        onClick={() => {
          setActiveAction('reject');
          onAction(reject);
        }}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? activeAction === 'reject' && Button.Glyphs.Spinner : null}
      >
        <Text id="WizardActions.reject" />
      </Button>
    ) : null;

  ///
  const next = find(actions, { appearsAs: 'next' });
  const nextButton =
    next != null && isVisible(next) ? (
      <Button
        type="primary"
        aria-label="Next"
        size={buttonSize}
        onClick={() => onAction(next)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.next}
      >
        {PRIMARY_BUTTON_LABELS.next}
      </Button>
    ) : null;

  ///
  const verify = find(actions, { appearsAs: 'verify' });
  const verifyButton =
    verify != null && isVisible(verify) ? (
      <Button
        type="primary"
        size={buttonSize}
        aria-label="Verify"
        onClick={() => onAction(verify)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.verify}
      >
        {PRIMARY_BUTTON_LABELS.verify}
      </Button>
    ) : null;

  ///
  const submit = find(actions, { appearsAs: 'submit' });
  const submitButton =
    submit != null && isVisible(submit) ? (
      <Button
        type="primary"
        size={buttonSize}
        aria-label="Submit"
        onClick={() => onAction(submit)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.submit}
      >
        {PRIMARY_BUTTON_LABELS.submit}
      </Button>
    ) : null;

  ///
  const retry = find(actions, { appearsAs: 'retry' });
  const retryButton =
    retry != null && isVisible(retry) ? (
      <Button
        type="primary"
        size={buttonSize}
        aria-label="retry"
        onClick={() => onAction(retry)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.retry}
      >
        {PRIMARY_BUTTON_LABELS.retry}
      </Button>
    ) : null;

  ///
  const print = find(actions, { appearsAs: 'print' });
  const printButton =
    print != null && isVisible(print) ? (
      <Button
        type="primary"
        aria-label="Print"
        size={buttonSize}
        onClick={() => onAction(print)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.print}
      >
        {PRIMARY_BUTTON_LABELS.print}
      </Button>
    ) : null;

  ///
  const approve = find(actions, { appearsAs: 'approve' });
  const approveButton =
    approve != null && isVisible(approve) ? (
      <Button
        type="primary"
        size={buttonSize}
        aria-label="Approve"
        onClick={() => {
          setActiveAction('approve');
          onAction(approve);
        }}
        disabled={isProcessing || nextDisabled}
        glyphRightId={
          isProcessing ? activeAction === 'approve' && Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.approve
        }
      >
        {PRIMARY_BUTTON_LABELS.approve}
      </Button>
    ) : null;

  ///
  const review = find(actions, { appearsAs: 'review' });
  const reviewButton =
    review != null && isVisible(review) ? (
      <Button
        type="primary"
        size={buttonSize}
        aria-label="Review"
        onClick={() => onAction(review)}
        disabled={isProcessing || nextDisabled}
        glyphRightId={isProcessing ? Button.Glyphs.Spinner : PRIMARY_BUTTON_GLYPH_IDS.review}
      >
        {PRIMARY_BUTTON_LABELS.review}
      </Button>
    ) : null;

  ///
  const skip = find(actions, { appearsAs: 'skip' });
  const skipButton = isVisible(skip!) ? (
    <Button
      type="secondary"
      aria-label="Skip"
      size="medium"
      disabled={isProcessing}
      onClick={() => onAction(skip!)}
      glyphRightId={isProcessing ? Button.Glyphs.Spinner : null}
    >
      {PRIMARY_BUTTON_LABELS.skip}
    </Button>
  ) : null;

  const leftButtons = [backButton, cancelButton];
  const rightButtons = [
    skipButton,
    nextButton,
    closeButton,
    printButton,
    verifyButton,
    reviewButton,
    submitButton,
    rejectButton,
    approveButton,
    retryButton
  ];

  return (
    <div className={cx(styles['wizard-footer-container'], className)}>
      <If
        condition={!hideActions}
        do={
          <>
            {/* Left buttons */}
            <div className={styles['left-buttons-wrapper']}>
              {React.Children.map(leftButtons, $node => {
                if ($node) {
                  return React.cloneElement($node, {
                    className: cx(styles.LeftButton, innerClassName)
                  });
                }
              })}
            </div>

            {/* Right buttons */}
            <div className={styles['right-buttons-wrapper']}>
              {React.Children.map(rightButtons, $node => {
                if ($node) {
                  return React.cloneElement($node, {
                    className: cx(styles.RightButton, innerClassName)
                  });
                }
              })}
            </div>
          </>
        }
      />
    </div>
  );
}

WizardFooter.defaultProps = {
  actions: [],
  className: '',
  innerClassName: '',
  isProcessing: false
};
