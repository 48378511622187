//@flow
import * as React from 'react';
import { type SavingsSubStepProps } from '../..';

export const Deposit = ({ data, TextField, StackChild }: SavingsSubStepProps) => {
  const product = data.savingProduct;
  const min = product ? product.minimumOpeningBalance : null;
  return (
    <StackChild>
      <h3>{data.savingProduct.name}</h3>
      <TextField
        name="depositAmount"
        leftGlyph="Currency.orgCurrency"
        errorBaseId="SavingAdd.Deposit.Amount"
        labelId="SavingAdd.Deposit.Amount.label"
        infoId={min != null ? 'SavingAdd.Deposit.Amount.info' : null}
        values={{ min }}
        isCurrency
        type="number"
      />
    </StackChild>
  );
};

Deposit.validate = {
  depositAmount: {
    currency: true,
    nonZero: true
  }
};
