// @flow

import * as React from 'react';

import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { MemberOverviewStatus } from '@kwara/components/src/MemberOverviewStatus';
import { type MemberType } from '@kwara/models/src';

type Props = {
  actions: React.Node,
  className?: string,
  member: MemberType,
  showBack: boolean,
  statusOverride: void | 'APPLICANT' | 'GUARANTOR'
};

export const OverviewMetadata = ({ actions, member, showBack, statusOverride, className }: Props) => {
  const back = showBack && <BackButton />;
  const noButtons = actions == null && back == null;

  return (
    <div className={className}>
      {noButtons ? null : <ButtonBar className="mb4 pb3" left={[back]} right={[actions]} />}
      <MemberOverviewStatus statusOverride={statusOverride} member={member} />
    </div>
  );
};
