import { useQuery } from 'react-query';
import get from 'lodash/fp/get';

import { LoanProductType, MemberType } from '@kwara/models/src';

import { _api } from '../../../services/httpServices';
import { ApiError } from 'GlobalTypes';

type ArgType = {
  memberId: string;
  loanProductId: string;
  principalAmount: string;
  payOffLoans: Array<string>;
  totalAdditionalFees: number;
  onStart?(): void;
  isV1?: boolean;
};
function httpGetAnticipatedPayout(arg: ArgType) {
  const { memberId, loanProductId, principalAmount, payOffLoans, totalAdditionalFees, onStart, isV1 } = arg;
  const params = {
    'filter[loan_product_id]': loanProductId,
    'filter[principal_amount]': principalAmount,
    'filter[member_id]': memberId,
    'filter[additional_fees]': totalAdditionalFees,
    payoff_loan_account_ids: payOffLoans
  };

  let url = '/loans/calculations/anticipated_payout_amount';

  if (isV1) {
    url = '/v1' + url;
  }

  onStart?.();
  return _api.httpGetRequest(url, { params }).then(res => res.data);
}

export interface AnticipatedPayoutResponse {
  data: {
    attributes: {
      anticipated_payout_amount: string;
      principal_amount: string;
      mambu_fees: string;
      additional_fees: string;
      loan_payoff_amount: string;
    };
  };
}

export type Handlers = Partial<{
  onSuccess(data: AnticipatedPayoutResponse): void;
  onError(error: ApiError): void;
  onStart(): void;
}>;

interface AnticipatedPayoutApiFetch {
  member: MemberType;
  product: LoanProductType;
  amount: string;
  payOffLoans: string[];
  totalAdditionalFees: number;
  handlers?: Handlers;
  isV1?: boolean;
}

export function useAnticipatedPayoutApiFetch({
  member,
  product,
  amount,
  payOffLoans,
  totalAdditionalFees,
  handlers: { onStart, ...restHandlers } = {},
  isV1 = false
}: AnticipatedPayoutApiFetch) {
  const memberId = member.id;
  const loanProductId = product.id;
  const enabled = !!memberId && !!loanProductId && !!amount && !isV1;

  const r = useQuery<unknown, ApiError, AnticipatedPayoutResponse>(
    ['anticipated_payout', memberId, loanProductId, amount, payOffLoans, totalAdditionalFees],
    function queryAnticipatedPayout() {
      return httpGetAnticipatedPayout({
        memberId,
        loanProductId,
        principalAmount: amount,
        payOffLoans,
        totalAdditionalFees,
        onStart,
        isV1
      });
    },
    { enabled: enabled, ...restHandlers }
  );

  const anticipatedPayout = get('data.data.attributes.anticipated_payout_amount', r);
  const principalAmount = get('data.data.attributes.principal_amount', r);
  const mambuFees = get('data.data.attributes.mambu_fees', r);
  const additionalFees = get('data.data.attributes.additional_fees', r);
  const loanPayoffAmount = get('data.data.attributes.loan_payoff_amount', r);

  return { anticipatedPayout, principalAmount, mambuFees, additionalFees, loanPayoffAmount, ...r };
}
