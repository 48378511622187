import { useEffect, useState, useRef } from 'react';

export function useFilterUIPosition() {
  const headerRef = useRef<HTMLDivElement>(null);
  const [bodyPosition, setBodyPosition] = useState({ top: '-100px', left: '-100px' });

  const header = headerRef.current;

  useEffect(() => {
    if (header) {
      const rect = header.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const left = rect.left + window.pageXOffset;

      setBodyPosition({ top: top + 50 + 'px', left: left + 'px' });
    }
  }, [header]);

  return { headerRef, bodyPosition };
}
