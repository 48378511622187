// @flow

import * as React from 'react';
import some from 'lodash/fp/some';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { createModelErrors } from '@kwara/models/src';
import { type DirectDebitReportT } from '@kwara/components/src';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import Visible from '../../../../components/Visible';
import { useThrottledCallback } from '../../../../hooks';
import { financePath } from '../../../../lib/urls';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import OverviewList from '../../../../components/OverviewList';
import { AppPermissions } from '../../../../models/Permission';
import { BodyContentWrapper } from '../../../../layouts';
import { useDeprecatedPagination, useDirectDebitReports } from '../../../../hooks';

import { DirectDebitReportsTable } from './DirectDebitReportsTable';

const includes = ['collecting_bank'];
export const DirectDebitReports = () => {
  const [errors, setErrors] = React.useState(null);
  const { allData, totalResults, currentPage, refetch: _refetch, ...r } = useDeprecatedPagination<DirectDebitReportT>(
    useDirectDebitReports,
    page => [includes, page]
  );

  const refetch = () => {
    if (currentPage !== 1) {
      return;
    }
    if (some(report => !report.isFinished(), allData)) {
      _refetch();
    }

    return;
  };

  useThrottledCallback(refetch);

  async function onDownload(report, reportType = 'report') {
    setErrors(null);
    await report.onDownload(reportType).catch(() => {
      setErrors(
        createModelErrors({
          base: 'UI_DIRECT_DEBIT_DOWNLOAD_FAILURE'
        })
      );
    });
  }

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.DDReports.title" subtitleId="Finance.DDReports.subtitle" />
          {errors ? <ModelErrorBanner errors={errors} /> : null}
          <OverviewList
            actions={[
              <Visible to={AppPermissions.GenerateDirectDebitReports}>
                <Button
                  key="generate"
                  type="primary"
                  to={financePath({
                    baseExtension: 'reports',
                    action: 'generate'
                  })}
                >
                  <Text id="Finance.DDReports.button.Generate" />
                </Button>
              </Visible>
            ]}
            headerId="Finance.DDReports.List.header"
            labelId="Finance.DDReports.List.label"
            items={allData}
            totalNumResults={totalResults}
            table={<DirectDebitReportsTable onDownload={onDownload} reports={allData} {...r} />}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
