import React from 'react';
import find from 'lodash/fp/find';

import { useCloseOnOutsideClick } from './useCloseOnOutsideClick';

export function useSummarySidePanel({ summaries }) {
  const [summaryId, setSummaryId] = React.useState(null);
  const summary = find(summary => summary.id === summaryId, summaries);

  const onSetSummaryId = (id: string) => setSummaryId(id);
  const onClose = () => setSummaryId(null);

  const ref = useCloseOnOutsideClick(onClose);

  return { ref, summary, onSetSummaryId, onClose };
}
