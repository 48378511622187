import React from 'react';
import { Text } from '@kwara/components/src/Intl';

type WizardSectionTitleType = {
  id: string;
};
export const WizardSectionTitle = ({ id }: WizardSectionTitleType) => {
  return (
    <h1 className="kw-text-medium kw-weight-bold neutral-700 ml0 mv3">
      <Text id={id} />
    </h1>
  );
};
