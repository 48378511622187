//@flow
import * as React from 'react';
import cx from 'classnames';

import { type SavingProductType } from '@kwara/models/src';
import { Percent, Text, Currency } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { Card } from '@kwara/components/src/Card';

import styles from './index.module.scss';

export const SavingProductCard = ({
  product,
  isSelected,
  isSelectable = false
}: {
  product: SavingProductType,
  isSelected: boolean,
  isSelectable: boolean
}) => (
  <div data-testid="ProductCard" className={cx(styles.Product, isSelected ? styles.isSelected : '')}>
    <Card
      header={<div className={styles.Header}>{product.name}</div>}
      highlighted={isSelected}
      isHoverable
      actionType={isSelectable ? 'select' : 'go'}
      actionLabelId="ProductSelector.action"
    >
      <Statistic
        compact
        title={<Text id="ProductSelector.minimumOpening" />}
        size="small"
        value={product.minimumOpeningBalance ? <Currency value={product.minimumOpeningBalance} /> : '-'}
      />
      <Statistic
        compact
        title={<Text id="ProductSelector.maximumOpening" />}
        size="small"
        value={product.maximumOpeningBalance ? <Currency value={product.maximumOpeningBalance} /> : '-'}
      />
      <Statistic
        compact
        title={<Text id="ProductSelector.interestRate" />}
        size="small"
        value={<Percent value={product.interestRate.default / 100} />}
      />
      <Statistic
        compact
        title={<Text id="ProductSelector.interestTerms" />}
        size="small"
        value={
          product.interestRate.terms ? <Text id={`ProductSelector.interestTerms.${product.interestRate.terms}`} /> : '-'
        }
      />
      <Statistic
        compact
        title={<Text id="ProductSelector.interestType" />}
        size="small"
        value={
          product.interestRate.source ? (
            <Text id={`ProductSelector.interestType.${product.interestRate.source}`} />
          ) : (
            '-'
          )
        }
      />
    </Card>
  </div>
);
