import * as React from 'react';

import { useMpesaCredentials } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';

import { Header } from '../Header';
import { BodyContentWrapper } from '../../../../layouts';
import { MpesaCredentials } from './components/MpesaCredentials';
import { CrbMetropolCredentials } from './components/CrbMetropolCredentials';

export const ManageCredentials = () => {
  const r = useMpesaCredentials();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <Header titleId="Settings.manageCredentials.titleId" subtitleId="Settings.manageCredentials.subtitleId" />
          <CrbMetropolCredentials />
          <Loadable {...r}>{data => <MpesaCredentials credentials={data} />}</Loadable>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
