import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { segmentText } from '@kwara/lib/src/segmentText';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { FormatDate } from '../FormatDate';
import { useNotificationSystemContext } from '../..';
import { IconCheck } from '../../assets/IconCheck.svg';
import { IconDelete } from '../../assets/IconDelete.svg';

import styles from './index.module.scss';

export function Body() {
  const { data, readIds, onDeleteAnnouncement, onReadAnnouncement } = useNotificationSystemContext();

  return (
    <div role="main">
      <ul className={styles['container']}>
        {data.announcements.map(a => {
          const hasReadAnnouncement = readIds.includes(a.id);
          const announcements = segmentText(a.paragraph);

          return (
            <li key={a.id}>
              <time className={styles['container__row1']}>
                <FormatDate value={new Date(a.date)} />
              </time>

              <div className={cx(styles['container__row2'], !hasReadAnnouncement && styles['container__row2--active'])}>
                <div className={styles['heading-wrapper']}>
                  {!!a.linkPath ? (
                    <Link to={a.linkPath}>
                      <h2 className="kw-text-medium kw-weight-bold primary-400">{a.heading}</h2>
                    </Link>
                  ) : (
                    <h2 className="kw-text-medium kw-weight-bold primary-400">{a.heading}</h2>
                  )}

                  <button
                    type="button"
                    tabIndex={-1}
                    onClick={() => onDeleteAnnouncement(a.id)}
                    data-testid="deleteBtn"
                  >
                    <AccessibleIcon label={`Click to delete ${a.heading} announcement`}>
                      <IconDelete />
                    </AccessibleIcon>
                  </button>
                </div>

                {announcements.map(({ paragraph, points }, i) => (
                  <React.Fragment key={i.toString()}>
                    <If condition={!!paragraph} do={<p className="kw-text-medium">{paragraph}</p>} />
                    <If
                      condition={!!points.length}
                      do={
                        <ul className={cx('kw-text-medium', styles['points'])}>
                          {points?.map((text, i) => (
                            <li key={i.toString()}>{text}</li>
                          ))}
                        </ul>
                      }
                    />
                  </React.Fragment>
                ))}

                <If condition={!!a.imgSrc} do={<img src={a.imgSrc} alt="" />} />

                <div className={styles['check-wrapper']}>
                  <i className="kw-text-small">
                    <Text id="NotificationSystem.markAsRead" />
                  </i>

                  <button
                    type="button"
                    tabIndex={-1}
                    disabled={hasReadAnnouncement}
                    className={cx(styles['check'], hasReadAnnouncement && styles['check--read'])}
                    onClick={() => onReadAnnouncement(a.id)}
                    data-testid="markAsReadBtn"
                  >
                    <AccessibleIcon
                      label={`${
                        hasReadAnnouncement ? 'Announcement is already marked as read' : 'Mark announcement as read'
                      }`}
                    >
                      <IconCheck {...(hasReadAnnouncement ? { fill: '#94a3b8' } : {})} />
                    </AccessibleIcon>
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
