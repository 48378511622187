import React from 'react';

import { B2CTransaction, C2BTransaction, StkPushTransaction, IncludesT } from '@kwara/models/src';

import { useOverviewList, fetchWithPagination } from '../base';

export function useFilterAndSearch() {
  const [where, setWhere] = React.useState({
    search: '',
    state: ''
  });

  const onFilterChange = val => setWhere({ ...where, state: val });
  const onSearchChange = val => setWhere({ ...where, search: val });

  const filterValue = where.state;

  return { where, filterValue, onFilterChange, onSearchChange };
}

async function fetchB2CTransactions(opts) {
  return fetchWithPagination(B2CTransaction, opts);
}

const defaultB2CTransactionIncludes = [];
export function usePaginatedB2CTransactions(includes: IncludesT = defaultB2CTransactionIncludes) {
  return useOverviewList({
    queryKey: 'b2c_transactions',
    fetchFn: fetchB2CTransactions,
    includes,
    select: null,
    useWhere: useFilterAndSearch
  });
}

async function fetchC2BTransactions(opts) {
  return fetchWithPagination(C2BTransaction, opts);
}

const defaultC2BTransactionIncludes = [];
export function usePaginatedC2BTransactions(includes: IncludesT = defaultC2BTransactionIncludes) {
  return useOverviewList({
    queryKey: 'c2b_transactions',
    fetchFn: fetchC2BTransactions,
    includes,
    select: null,
    useWhere: useFilterAndSearch
  });
}

async function fetchStkPushTransactions(opts) {
  return fetchWithPagination(StkPushTransaction, opts);
}

const defaultStkPushTransactionIncludes = [];
export function usePaginatedStkPushTransactions(includes: IncludesT = defaultStkPushTransactionIncludes) {
  return useOverviewList({
    queryKey: 'stk_push_transactions',
    fetchFn: fetchStkPushTransactions,
    includes,
    select: null,
    useWhere: useFilterAndSearch
  });
}
