import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import pipe from 'lodash/fp/pipe';

import { SavingType } from '@kwara/models/src/';

export function getMainSavingsBalance(data) {
  const totalSavings = get('member.totalSavings', data);

  const mainSavingsProductId = get('product.productConfig.guarantorMultiplierSavingsProductId', data);
  const mainSavingsBalance =
    pipe(
      get('savings'),
      find((saving: SavingType) => saving.product && saving.product.id === mainSavingsProductId),
      get('balance')
    )(data) || 0;

  const balance = mainSavingsProductId ? mainSavingsBalance : totalSavings;
  const canGuaranteeUpTo = Number(balance) * 0.9;

  return { isMainSavings: !!mainSavingsProductId, balance, canGuaranteeUpTo };
}
