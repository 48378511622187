import { useReducer, useCallback } from 'react';

import { ValueOf } from 'GlobalTypes';

import { TYPES } from './types';
import { _api } from '../../../../../services';
import { verifyPhoneNumberReducer } from './reducer';

interface VerifyPhoneNumberResponse {
  data: Array<{
    id: string;
    type: string;
    attributes: {
      created_at: string;
      updated_at: string;
      first_name: string;
      middle_name: string | null;
      last_name: string;
      email: string;
      date_of_birth: string;
      phone_number: string;
      source: string | null;
      state: { current: string; permitted_events: Array<string> };
    };
  }>;
  jsonapi: { version: string };
}

const PHONE_NUMBER_VERIFICATION_ERROR_CODE = 'PHONE_NUMBER_VERIFICATION_ERROR';

const NETWORK_ERROR = [{ status: 400, title: 'Network Error', code: 'VERIFY_PHONE_NUMBER_NETWORK_ERROR' }];

const VERIFICATION_STATUS = { HAS_ERROR: 'HAS_ERROR', HAS_NO_ERROR: 'HAS_NO_ERROR' } as const;

const INITIAL_STATE = { isLoading: false, error: null, isPhoneNumberInUse: false, namesOfPeopleUsingPhoneNumber: null };

type ArgTypes = {
  name: string;
  onIsLoading?(): void;
  onSuccess?(): void;
  onFailed?(): void;
  updateVerificationStatus(key: string, value: ValueOf<typeof VERIFICATION_STATUS>): void;
};

function useVerifyPhoneNumber({ name, onIsLoading, onSuccess, onFailed, updateVerificationStatus }: ArgTypes) {
  const [state, dispatch] = useReducer(verifyPhoneNumberReducer, INITIAL_STATE);

  const getNamesOfPhoneNumberUsers = (data: VerifyPhoneNumberResponse['data']) => {
    return data.reduce((names: string[], { attributes }) => {
      const fullName = `${attributes.first_name} ${attributes.middle_name ?? ''} ${attributes.last_name}`;
      names.push(fullName);
      return names;
    }, []);
  };

  const verifyPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      try {
        dispatch({ type: TYPES.POSTING_STARTED });
        onIsLoading?.();

        const response = await _api.httpPostRequest('/members/verify_phone_number', {
          data: { attributes: { phone_number: phoneNumber } }
        });

        const data: VerifyPhoneNumberResponse = response.data;
        const reducedNames = getNamesOfPhoneNumberUsers(data.data);

        dispatch({ type: TYPES.POSTING_SUCCESSFUL, payload: { namesOfPeopleUsingPhoneNumber: reducedNames } });
        onSuccess?.();
        updateVerificationStatus(name, VERIFICATION_STATUS.HAS_ERROR);
      } catch (error) {
        const errors = error.response.data?.errors;
        const phoneNumberIsNotFoundInDatabase = errors && errors[0].code === PHONE_NUMBER_VERIFICATION_ERROR_CODE;

        if (phoneNumberIsNotFoundInDatabase) {
          dispatch({ type: TYPES.POSTING_FAILED, payload: { error: null } });
          updateVerificationStatus(name, VERIFICATION_STATUS.HAS_NO_ERROR);
        } else {
          dispatch({ type: TYPES.POSTING_FAILED, payload: { error: { errors: errors ?? NETWORK_ERROR } } });
          updateVerificationStatus(name, VERIFICATION_STATUS.HAS_ERROR);
        }
        onFailed?.();
      }
    },
    [name, onFailed, onIsLoading, onSuccess, updateVerificationStatus]
  );

  const resetState = () => {
    dispatch({ type: TYPES.POSTING_FAILED, payload: { error: null } });
    updateVerificationStatus(name, VERIFICATION_STATUS.HAS_NO_ERROR);
  };

  return {
    ...state,
    verifyPhoneNumber,
    resetState
  };
}

export { VERIFICATION_STATUS, useVerifyPhoneNumber };
