import { useQuery } from 'react-query';

import creditReport, { CreditReportParams } from '../../../../../../models/CreditReport/CreditReport';

export function useGetCreditReport(args: CreditReportParams) {
  const { isLoading, error, data } = useQuery(
    ['credit_info', args.identityType, args.identityValue],
    async () => await creditReport.get(args)
  );

  return { isLoading, error, data: data?.data };
}
