//@flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { LoanFields, LoanType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { excludedDisbursementFields } from '../../../lib/modelUtils/loan';

export const Disbursement = ({ loan }: { loan: LoanType }) => {
  const shouldHide = excludedDisbursementFields(loan);
  return (
    <>
      <StatisticRow widthClass="w-50">
        <Statistic
          title={<Text id="LoanDetail.Disbursement.method" />}
          value={<Text id={`TransactionMethod.${get(LoanFields.disbursement.mode, loan)}`} />}
        />
      </StatisticRow>
      <StatisticRow widthClass="w-50">
        <Statistic
          title={<Text id="PaymentForm.bank" />}
          value={get(LoanFields.disbursement.bank, loan)}
          hidden={shouldHide(LoanFields.disbursement.bank)}
        />
        <Statistic
          title={<Text id="PaymentForm.bankBranch" />}
          value={get(LoanFields.disbursement.branch, loan)}
          hidden={shouldHide(LoanFields.disbursement.branch)}
        />
        <Statistic
          title={<Text id="PaymentForm.accountNumber" />}
          value={get(LoanFields.disbursement.account, loan)}
          hidden={shouldHide(LoanFields.disbursement.account)}
        />
      </StatisticRow>
    </>
  );
};
