// @flow
import * as React from 'react';

import { type CollateralT } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';

import { Child } from '../../../../../../../components/Stack';

import { CollateralEntry } from './CollateralEntry';

type Props = {
  onRemove: (asset: number) => void,
  collaterals: CollateralT[],
  StackChild: typeof Child,
  onEdit: (asset: number) => void,
  onUndo: (asset: number) => void
};

export const CollateralList = ({ StackChild, collaterals, onRemove, onEdit, onUndo }: Props) => (
  <StackChild>
    <ul className="list pa0">
      {mapIndexed(
        (asset, idx) => (
          <li key={`${idx}${asset.amount}`} className="pb3">
            <CollateralEntry
              asset={asset}
              index={idx}
              onEdit={() => onEdit(idx)}
              onRemove={() => onRemove(idx)}
              onUndo={() => onUndo(idx)}
            />
          </li>
        ),
        collaterals
      )}
    </ul>
  </StackChild>
);
