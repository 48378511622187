import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import { LoanDisbursementAppraisalsType, LoanDisbursementType } from '@kwara/models/src';

import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';
import { Store } from '../../../../../models/Store/Store';

export const useHasAlreadyDisbursed = (disbursement: LoanDisbursementType | undefined) => {
  const profileContext: Store = useSaccoProfileContext();
  const hasAlreadyDisbursed = includes(
    get('profile.id', profileContext),
    map((a: LoanDisbursementAppraisalsType) => get('user.id', a), disbursement?.appraisals)
  );

  return hasAlreadyDisbursed;
};
