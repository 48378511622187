import React from 'react';
import cx from 'classnames';

import { UseFilterContextValuesArgType, FilterContextType } from 'FilterTypes';

import { createContext } from '@kwara/lib/src/utils/createContext';

import { Header } from './components/Header';
import { Body } from './components/Body';
import { useFilterContextValues } from './hooks/useFilterContextValues';

import styles from './index.module.scss';

type PrimitiveDivPropTypes = React.ComponentPropsWithoutRef<'div'>;
type FilterElement = React.ElementRef<'div'>;
type FilterPropTypes = PrimitiveDivPropTypes &
  UseFilterContextValuesArgType & {
    disabled?: boolean;
  };

const [FilterProvider, useFilterContext] = createContext<FilterContextType>('FilterContext');

const Filter = React.forwardRef<FilterElement, FilterPropTypes>(function Filter(props, forwardedRef) {
  const {
    className,
    children,
    open,
    filters,
    activeFilter,
    selectedFilters,
    disabled,
    onFilterValue,
    onReset,
    onRemoveFilterValue,
    ...restProps
  } = props;

  const values = useFilterContextValues({
    open,
    filters,
    activeFilter,
    selectedFilters,
    onFilterValue,
    onReset,
    onRemoveFilterValue
  });

  return (
    <FilterProvider value={values}>
      <div
        {...restProps}
        ref={forwardedRef}
        className={cx('relative', className, { [styles['container--disabled']]: !!disabled })}
      >
        {children}
      </div>
    </FilterProvider>
  );
});

export { useFilterContext };

export default { Root: Filter, Header, Body };
