// @flow

import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { ProductTypes, ChargeFrequency } from '@kwara/models/src/models/SavingProduct';
import { Text } from '@kwara/components/src/Intl';
import { Field } from '@kwara/components/src/Form';

import { Panel } from '../../../components/ActionModal';
import { Grid } from '../../../components/Grid';

export const ProductForm = ({
  StackChild,
  TextField,
  SelectField,
  Checkbox,
  formProps,
  translationBaseId = 'SavingProductAdd'
}) => {
  const { values } = formProps;
  const { interestPaidIntoAccount } = values;
  return (
    <StackChild>
      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.name.fullText`} />
        </h2>
        <TextField required name="name" labelId={`${translationBaseId}.Product.name.labelId`} />
      </Panel>
      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.type.fullText`} />
        </h2>
        <SelectField required name="type" labelId={`${translationBaseId}.Product.type.labelId`}>
          {map(productType => {
            return (
              <SelectField.Option
                key={productType}
                value={productType}
                translationId={`${translationBaseId}.Product.type.${productType}`}
              />
            );
          }, ProductTypes)}
        </SelectField>
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.interest.fullText`} />
        </h2>
        <Checkbox name="interestPaidIntoAccount" labelId={`${translationBaseId}.Product.interest.labelId`} />
        {interestPaidIntoAccount ? (
          <Field labelId={`${translationBaseId}.Product.interestRate.labelId`}>
            <Grid columns={2} width="w-50" border={false}>
              <TextField name="interestRate.default" rightGlyph="%" />
              <SelectField name="interestRate.chargeFrequency">
                <SelectField.Option
                  key="NONE"
                  translationId={`${translationBaseId}.Product.interestChargeFrequency.NONE`}
                  value=""
                />
                {map(
                  o => (
                    <SelectField.Option
                      key={o}
                      translationId={`${translationBaseId}.Product.interestChargeFrequency.${o}`}
                      value={o}
                    />
                  ),
                  ChargeFrequency
                )}
              </SelectField>
            </Grid>
          </Field>
        ) : null}
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.min.fullText`} />
        </h2>
        <TextField
          name="minimumOpeningBalance"
          labelId={`${translationBaseId}.Product.min.labelId`}
          infoId={`${translationBaseId}.Product.min.infoId`}
          errorBaseId={`${translationBaseId}.Product`}
          rightGlyph="Currency.orgCurrency"
          isCurrency
          type="number"
        />
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.max.fullText`} />
        </h2>
        <TextField
          name="maximumOpeningBalance"
          labelId={`${translationBaseId}.Product.max.labelId`}
          errorBaseId={`${translationBaseId}.Product`}
          rightGlyph="Currency.orgCurrency"
          isCurrency
          type="number"
        />
      </Panel>
      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.restrictions.fullText`} />
        </h2>
        <Checkbox
          name="multipleAccountsAllowed"
          labelId={`${translationBaseId}.Product.multipleAccountsAllowed.labelId`}
        />
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`${translationBaseId}.Product.fees.fullText`} />
        </h2>
        <Checkbox name="allowFees" labelId={`${translationBaseId}.Product.allowFees.labelId`} />
        <TextField name="feeName" labelId={`${translationBaseId}.Product.feeName.labelId`} />
      </Panel>
    </StackChild>
  );
};

const requiredIfInterest = {
  isRequired: (target, allData) => get('interestPaidIntoAccount', allData) === true && isEmpty(target)
};

ProductForm.validate = {
  name: {
    isRequired: () => true
  },
  type: {
    isRequired: () => true
  },
  'interestRate.default': requiredIfInterest,
  'interestRate.chargeFrequency': requiredIfInterest,
  minimumOpeningBalance: {
    currency: true
  },
  maximumOpeningBalance: {
    currency: true,
    custom: (target, allData) => {
      const { minimumOpeningBalance } = allData;
      if (!isEmpty(minimumOpeningBalance) && Number(minimumOpeningBalance) > Number(target)) {
        return 'maxExceedsMin';
      }
      return null;
    }
  }
};
