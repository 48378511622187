import { useQuery } from 'react-query';
import { Till, IncludesT } from '@kwara/models/src';

async function fetchTills({ queryKey }) {
  const [_, includes] = queryKey;
  return await Till.includes(includes)
    .all()
    .then(res => res.data);
}

const defaultIncludes = ['occupant'];
export function useTills(includes: IncludesT = defaultIncludes) {
  return useQuery(['tills', includes], fetchTills);
}
