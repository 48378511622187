import React from 'react';

import getOr from 'lodash/fp/getOr';
import toNumber from 'lodash/fp/toNumber';
import get from 'lodash/fp/get';
import isFunction from 'lodash/fp/isFunction';

import { Duration, Number, Percent } from '@kwara/components/src/Intl';

import { Currency } from '../Currency';

import styles from './index.module.css';

export function extractValue(val: { type: string; value: number | string }) {
  return getOr(0, 'value', val);
}

export function useDashboardMetricValues(metric) {
  function renderUnknown(value: number | string) {
    return <span>{value}</span>;
  }

  function renderPercentage(value: number | string) {
    return (
      <span className={styles.Numeric}>
        <Percent value={toNumber(value) / 100} />
      </span>
    );
  }

  function renderDuration(value: number | string) {
    if (value === 'PT0S') {
      return <span>{'< 1 day'}</span>;
    }

    return <Duration value={value} />;
  }

  function renderInfo(info: { type: string; value: string | number }, className?: string | null) {
    if (!info) {
      return null;
    }

    if (info) {
      return <span className={className}>{renderValue(info)}</span>;
    }
  }

  function renderValue(value: { type: string; value: string | number }) {
    switch (value.type) {
      case 'currency': {
        const currency = extractValue(value);
        return <Currency value={currency} />;
      }
      case 'number':
        return <Number value={value.value} />;
      case 'duration':
        return renderDuration(value.value);
      case 'percent':
        return renderPercentage(value.value);
      default:
        return renderUnknown(value.value);
    }
  }

  const value = isFunction(metric)
    ? metrics => renderValue(get('value', metric(metrics)))
    : renderValue(get('value', metric));

  const info = isFunction(metric)
    ? (metrics, className?: string | null) => renderInfo(get('context', metric(metrics)), className)
    : renderInfo(get('context', metric));

  return { value, info };
}
