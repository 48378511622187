//@flow

import * as React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { type Location } from '@kwara/components/src/Actionable';
import { type LoanType, type GuaranteeType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatusTag from '@kwara/components/src/StatusTag';
import { Card } from '@kwara/components/src/Card';

import { Currency } from '../../../../components/Currency';

type Props = {
  loan: LoanType,
  guarantee: GuaranteeType,
  actionTo: Location,
  onDelete?: ?() => Promise<boolean>,
  onEdit?: ?() => Promise<boolean>
};

export default ({ actionTo, loan, guarantee, onDelete, onEdit }: Props) => {
  return (
    <Card
      className="mb4"
      header={invoke('member.fullName', guarantee)}
      actionTo={actionTo}
      onDelete={onDelete}
      metadata={
        <>
          <StatusTag state={guarantee.getState(get('member.id', loan))} /> &middot; {get('member.id', guarantee)}
        </>
      }
    >
      <Statistic
        title={<Text id="GuaranteeCard.amount" />}
        value={<Currency value={guarantee.amount} />}
        compact
        onEdit={onEdit}
      />

      <Statistic title={<Text id="GuaranteeCard.balance" />} value={<Currency value={guarantee.liability} />} compact />
    </Card>
  );
};
