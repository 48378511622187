// @flow
import * as React from 'react';

export const ScrollIntoView = React.forwardRef((props, forwardedRef) => {
  const messagesRef = React.useRef(null);
  React.useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [messagesRef]);

  return <div ref={forwardedRef ?? messagesRef}>{props.children}</div>;
});
