// @flow
import * as React from 'react';

import LoanDetailBase from '../../LoanDetail';
import { type SubStepComponentProps } from '../../../components/Wizard';

type CustomProps = {
  loanId: string
};

export const LoanDetail = ({ customProps, history, StackChild, data }: SubStepComponentProps<CustomProps>) => {
  const { loan } = data;
  return (
    <StackChild size="stretch">
      <LoanDetailBase
        hiddenSections={['feesAndPenalties', 'transactions']}
        history={history}
        match={{ params: { loanId: customProps ? customProps.loanId : null } }}
        linkContext={{
          backLabel: 'Back to loan application',
          useBrowserBack: true
        }}
        showCommentCreate={false}
        showMargins={false}
        showOverviewActions={false}
        readOnly={false}
        loan={loan}
      />
    </StackChild>
  );
};
