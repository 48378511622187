import React from 'react';

import { ReportTemplateT, SasraReportT } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { BodyContentWrapper } from '../../layouts';
import Visible from '../../components/Visible';
import { GenerateReportForm } from './components/Form/GenerateReportForm';
import { ReportsTable } from './components/Table/ReportsTable';
import { ROUTES } from '../../routes';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { usePaginatedSasraReports } from './usePaginatedSasraReports';
import { useSasraCreate } from './useSasraCreate';
import { useReportTemplates } from '../../models/hooks/useReportTemplates/useReportTemplates';
import { useSasraDownload } from './useSasraDownload';

type Props = {
  templates: ReportTemplateT[];
  sasraReports: SasraReportT[];
  isLoadingReports: boolean;
  hasMoreReports: boolean;
  onNext(): void;
  onSasraCreate(data: { startDate: string; endDate: string; reportTemplateId: string }): void;
  onSasraDownload(sasra: SasraReportT): void;
  isSasraDownloading: boolean;
};

export function SasraReportsLayout({
  sasraReports = [],
  templates = [],
  isLoadingReports,
  hasMoreReports,
  onNext,
  onSasraCreate,
  onSasraDownload,
  isSasraDownloading
}: Props) {
  const { AppPermissions } = usePermissions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <div className="flex justify-between items-center">
          <GenerateReportForm onSubmit={onSasraCreate} templates={templates} />
          <Visible to={AppPermissions.ConfigureSasraReports}>
            <Button to={ROUTES.sasraReportTemplates}>
              <Text id="SasraReports.ReportsIndex.button.templates" />
            </Button>
          </Visible>
        </div>
      </BodyContentWrapper.Row.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id={'SasraReports.ReportsIndex.title'} />
          </div>
          <ReportsTable
            onDownload={onSasraDownload}
            isLoading={isLoadingReports}
            onNext={onNext}
            hasMore={hasMoreReports}
            reports={sasraReports}
            isDownloading={isSasraDownloading}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function SasraReports() {
  const r = usePaginatedSasraReports();
  const templatesResponse = useReportTemplates();
  const { onDownload, isSasraDownloading } = useSasraDownload();
  const { onSasraCreate } = useSasraCreate();

  return (
    <SasraReportsLayout
      templates={templatesResponse.data}
      sasraReports={r.allData}
      isLoadingReports={r.isLoading || r.isFetchingNextPage || r.isFetching}
      hasMoreReports={r.hasNextPage}
      onNext={r.fetchNextPage}
      onSasraCreate={onSasraCreate}
      onSasraDownload={onDownload}
      isSasraDownloading={isSasraDownloading}
    />
  );
}
