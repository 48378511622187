import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import '@kwara/components/src/foundations';
import { FreshChat } from '@kwara/components/src/Freshchat';

import { Router } from './routes';
import { Store } from './models/Store/Store';
import { useSaccoProfileContext } from './models/Profile/ProfileProvider';

import styles from './styles.module.scss';

export default function App() {
  const store: Store = useSaccoProfileContext();

  return (
    <div className={styles['app-wrapper']}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <FreshChat
        user={{
          firstName: store?.profile?.firstName,
          email: store?.profile?.email,
          externalId: store?.profile?.id,
          restoreId: store?.profile?.id
        }}
        faqTags={{ tags: ['Sacco Admins'], filterType: 'category' }}
        onInit={widget => {
          (widget.user as any).setProperties({
            from: 'Core',
            organisationName: store?.profile?.organisation.name
          });
        }}
      />
    </div>
  );
}
