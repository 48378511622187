import * as React from 'react';

export function IconDone() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#16b364" />
      <path
        d="M18.0194 7.58475L9.95834 15.6453C9.90414 15.6997 9.83973 15.7428 9.76881 15.7723C9.69789 15.8018 9.62184 15.8169 9.54505 15.8169C9.46825 15.8169 9.39221 15.8018 9.32128 15.7723C9.25036 15.7428 9.18595 15.6997 9.13175 15.6453L6.01443 12.525C5.96023 12.4706 5.89582 12.4274 5.8249 12.398C5.75397 12.3685 5.67793 12.3533 5.60113 12.3533C5.52434 12.3533 5.44829 12.3685 5.37737 12.398C5.30645 12.4274 5.24204 12.4706 5.18784 12.525C5.13343 12.5792 5.09026 12.6436 5.0608 12.7145C5.03134 12.7855 5.01617 12.8615 5.01617 12.9383C5.01617 13.0151 5.03134 13.0911 5.0608 13.1621C5.09026 13.233 5.13343 13.2974 5.18784 13.3516L8.30634 16.4695C8.63531 16.7979 9.08112 16.9823 9.54592 16.9823C10.0107 16.9823 10.4565 16.7979 10.7855 16.4695L18.846 8.41076C18.9003 8.35657 18.9434 8.2922 18.9728 8.22133C19.0022 8.15046 19.0174 8.07448 19.0174 7.99775C19.0174 7.92103 19.0022 7.84505 18.9728 7.77418C18.9434 7.70331 18.9003 7.63894 18.846 7.58475C18.7918 7.53034 18.7274 7.48717 18.6565 7.45771C18.5855 7.42825 18.5095 7.41309 18.4327 7.41309C18.3559 7.41309 18.2799 7.42825 18.2089 7.45771C18.138 7.48717 18.0736 7.53034 18.0194 7.58475Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}
