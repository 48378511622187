// @flow

import React from 'react';
import map from 'lodash/fp/map';

import { type SavingProductType } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Percent } from '@kwara/components/src/Intl';

import { settingPath } from '../../../../lib/urls';
import Table, { Cell, Row, Heading } from '../../../../components/Table';
import { CurrencyRange } from '../shared';

export const ProductTable = ({ products }: { products: SavingProductType[] }) => {
  return (
    <Table
      className="mt5"
      heading={
        <Row className="grey-400">
          <Heading translationId="Settings.Savings.Table.id" />
          <Heading translationId="Settings.Savings.Table.name" />
          <Heading translationId="Settings.Savings.Table.interest" />
          <Heading translationId="Settings.Savings.Table.openingPrincipal" />
          <Heading iconSpacer />
        </Row>
      }
    >
      {map(product => {
        return (
          <Row
            icon={
              <ActionButton
                to={settingPath({
                  baseExtension: 'saving',
                  action: 'edit',
                  id: product.id
                })}
                col="grey300"
                hideBorder={true}
                type="more"
              />
            }
            key={product.id}
          >
            <Cell>{product.id}</Cell>
            <Cell>{product.name}</Cell>
            <Cell>
              {product.interestRate.default != null ? <Percent value={product.interestRate.default / 100} /> : '-'}
            </Cell>
            <Cell>
              <CurrencyRange min={product.minimumOpeningBalance} max={product.maximumOpeningBalance} />
            </Cell>
          </Row>
        );
      }, products)}
    </Table>
  );
};
