import get from 'lodash/fp/get';
import lt from 'lodash/fp/lt';
import compact from 'lodash/fp/compact';
import pipe from 'lodash/fp/pipe';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';
import includes from 'lodash/fp/includes';
import some from 'lodash/fp/some';

import { EmploymentStatuses, MemberType } from '@kwara/models/src';
import { attachmentNames, AttachmentNameT } from '@kwara/models/src/models/Attachment';

import { store } from '../../../../models/Store/Store';
import { ValidateMemberT } from './types';

export function hasMissingValues(member: MemberType, fields: string[]) {
  return pipe(
    map((path: string) => get(path, member)),
    compact,
    size,
    s => lt(s, size(fields))
  )(fields);
}

function hasMissingEmploymentValues(member: MemberType) {
  if (member.employmentStatus === EmploymentStatuses.SELF_EMPLOYED) {
    return hasMissingValues(member, ['workEmail', 'grossIncome', 'netIncome', 'otherDeductibles']);
  }

  if (member.employmentStatus === EmploymentStatuses.EMPLOYED) {
    return hasMissingValues(member, [
      'employerEmail',
      'employerPhoneNumber',
      'workEmail',
      'grossIncome',
      'netIncome',
      'otherDeductibles',
      'termsOfService'
    ]);
  }

  if (member.employmentStatus === EmploymentStatuses.OTHER) {
    return hasMissingValues(member, ['grossIncome', 'netIncome', 'otherDeductibles', 'incomeSource']);
  }

  return true;
}

// Every first level item is required.
// If an item contains more than one document name only one of them is considered required
// For ex. either Id Document or Passport must be there.
// If none of them is present this will be marked as missing.
export const requiredDocs: AttachmentNameT[][] = [
  [attachmentNames.memberSignature],
  [attachmentNames.salarySlip],
  [attachmentNames.idDocument, attachmentNames.passport]
];
export function getMissingAttachments(member: MemberType, isV1?: boolean): AttachmentNameT[][] {
  if (isV1) return [];

  const memberAttachmentNames = pipe(get('attachments'), map('name'))(member);

  return pipe(
    reject((entry: AttachmentNameT[]) =>
      some(e => {
        return includes(e, memberAttachmentNames);
      }, entry)
    ),
    compact
  )(requiredDocs);
}

export function validateMember(member: MemberType, isV1: boolean): ValidateMemberT {
  if (!member) {
    return {};
  }

  const shouldDisableValidation = !store.fullMemberDetailsRequiredForLoanApplication;
  if (shouldDisableValidation) {
    return {};
  }

  return {
    personalDetails: {
      isRequired: () => hasMissingValues(member, ['firstName', 'lastName', 'email'])
    },
    identity: {
      isRequired: () => hasMissingValues(member, ['idDocuments[0]', 'kraPin'])
    },
    bank: {},
    documents: {
      isRequired: () => {
        if (isV1) return false;
        return size(getMissingAttachments(member, isV1)) > 0;
      }
    }
  };
}

export const validateMemberIncome = (member: MemberType) => {
  if (!member) {
    return {};
  }

  return {
    isEditing: {
      custom: isEditing => !isEditing
    },
    employment: {
      isRequired: () => hasMissingEmploymentValues(member)
    }
  };
};
