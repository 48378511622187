import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import map from 'lodash/fp/map';

import { getCurrentDate, formatIsoDate } from '@kwara/lib/src/dates';

import { useGlAccounts } from '../../../../hooks';
import { TransactionDateFilter } from '../shared/Filters';
import { ComboboxBaseFilter } from '../../../../components/Filter/pages';
import { BranchesFilter } from '../../../../components/BranchesFilter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { useBranchesFilter } from '../../../../components/BranchesFilter/useBranchesFilter';
import { DATE_NAMES } from '../../../../components/Filter/pages/DateBaseFilter/useDateBaseFilter';

const includes = [];
const glWhere = {};

export function useJournalEntryFilters() {
  const int = useIntl();
  const { data: glAccounts = [] } = useGlAccounts(includes, glWhere);
  const branchesFilter = useBranchesFilter();
  const glAccountsFilter = useMemo(
    () => [
      {
        value: '',
        name: int.formatMessage({ id: 'Finance.JournalEntries.noFilter' }),
        belongsTo: FILTER_TYPES.GL_ACCOUNT
      },
      ...map(
        account => ({
          value: account.id,
          name: `${account.id} - ${account.name}`,
          belongsTo: FILTER_TYPES.GL_ACCOUNT
        }),
        glAccounts
      )
    ],
    [glAccounts, int]
  );

  const currentDate = getCurrentDate();
  const formattedDate = formatIsoDate(currentDate);

  return {
    filters: {
      [FILTER_TYPES.GL_ACCOUNT]: {
        data: glAccountsFilter,
        page: () => <ComboboxBaseFilter filterType={FILTER_TYPES.GL_ACCOUNT} placeholderId="Filter.glAccount" />
      },
      [FILTER_TYPES.DATE]: { data: [], page: TransactionDateFilter },
      [FILTER_TYPES.BRANCH_ID]: { data: branchesFilter.allBranches, page: BranchesFilter }
    },
    selectedFilters: {
      [FILTER_TYPES.GL_ACCOUNT]: [
        {
          value: '',
          name: int.formatMessage({ id: 'Finance.JournalEntries.noFilter' }),
          belongsTo: FILTER_TYPES.GL_ACCOUNT
        }
      ],
      [FILTER_TYPES.DATE]: [
        {
          value: formattedDate,
          name: DATE_NAMES.FROM,
          belongsTo: FILTER_TYPES.DATE,
          renderWithValue: true
        },
        {
          value: formattedDate,
          name: DATE_NAMES.TO,
          belongsTo: FILTER_TYPES.DATE,
          renderWithValue: true
        }
      ]
    }
  };
}
