import { useQuery } from 'react-query';

import { SavingsTransactionsStats } from '@kwara/models/src';

const fetchSavingsTransactionsStats = async ({ queryKey }) => {
  const [_, where] = queryKey;
  const res = await SavingsTransactionsStats.where(where).find();
  return res.data;
};

export const useSavingsTransactionsStats = (where: { start_date: string; end_date: string }) =>
  useQuery(['savings_transactions_stats', where], fetchSavingsTransactionsStats);
