import * as React from 'react';
import cx from 'classnames';

import { Render } from '@kwara/components/src';
import { Text, Currency } from '@kwara/components/src/Intl';

import { useSavings } from '.';
import { Card } from '../../../../components/Card';
import { Heading } from '../../../../components/Heading';

import styles from './index.module.scss';

export function Statement() {
  const { data } = useSavings();

  const savingsStatement = data.savingsHistoryWithStatement?.savings_statement || {};
  const hasStatement = !!Object.keys(savingsStatement).length;

  return (
    <Render condition={hasStatement} fallbackId="LoanDisbursal.MemberProfile.Savings.noStatement">
      <Card className={styles['statement-container']}>
        <Heading>
          <Text id="LoanDisbursal.MemberProfile.Savings.Statement.title" />
        </Heading>

        <ul className={cx('kw-text-x-small', styles['sav-st'])}>
          {hasStatement &&
            Object.entries(savingsStatement).map(([key, value]) => (
              <li key={key}>
                <div>
                  <Text id={`Months.${key}`} />
                </div>

                <div>
                  <Currency value={value} />
                </div>
              </li>
            ))}
        </ul>
      </Card>
    </Render>
  );
}
