//@flow
import * as React from 'react';

import { Comment as CommentModel } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';

import ActivityTimeline from '../../../components/Activity';
import { usePermissions } from '../../../hooks';
import { useActivity } from '../../../hooks';

type Props = {
  loanId: string,
  showCommentCreate: boolean
};

export const Activity = ({ loanId, showCommentCreate = true }: Props) => {
  const { AppPermissions, permission } = usePermissions();
  const [errors, setErrors] = React.useState(null);
  const { refetch, ...r } = useActivity({ id: loanId });

  const onComment = async data => {
    setErrors(null);
    const Comment = CommentModel.loan(loanId);
    const url = Comment.url();
    const opts = Comment.fetchOptions();

    const comment = new Comment(data);
    const didSave = await comment.post({ url, opts });

    if (didSave) {
      refetch();
    } else {
      setErrors(comment.errors);
    }
  };
  return (
    <Loadable {...r}>
      {activities => (
        <ActivityTimeline
          activities={activities}
          disableComments={false}
          onComment={onComment}
          errors={errors}
          showCommentCreate={showCommentCreate && permission.to(AppPermissions.AddComments)}
        />
      )}
    </Loadable>
  );
};
