// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import PinInputBase from 'react-pin-input';

import createValidator from '@kwara/lib/src/validator';
import { SubscribedPinInputField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';

import { useAuth } from '../../../hooks';

const usePinInputRef = () => {
  const ref = React.useRef<?typeof PinInputBase>(null);
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return ref;
};

export const AuthMfaVerifyForm = ({ onVerify, onBack }: { onVerify: (a: ?Auth) => any, onBack?: Function }) => {
  const auth = useAuth();
  const ref = usePinInputRef();
  const [wrongCode, setWrongCode] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async ({ code }: { code: string }) => {
      setWrongCode(false);
      const verified: boolean = await auth.verifyMfa(code).then(res => res.data);

      if (verified) {
        onVerify && onVerify(auth);
      } else {
        setWrongCode(true);
      }
    },
    [auth, onVerify, setWrongCode]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      validate={createValidator({
        code: {
          isRequired: () => true,
          minlength: 6
        }
      })}
      render={({ form, valid, submitting }) => {
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              form.submit();
              form.reset();
              ref.current && ref.current.clear();
            }}
          >
            <SubscribedPinInputField disabled={submitting} ref={ref} name="code" length={6} defaultSecret={false} />
            <div className="flex justify-between">
              {onBack ? (
                <Button disabled={submitting} onClick={onBack}>
                  <Text id="Activate2FA.button.back" />
                </Button>
              ) : (
                <div />
              )}
              <Button
                disabled={submitting || !valid}
                isSubmit
                type="primary"
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="Activate2FA.button.submit" />
              </Button>
            </div>

            {wrongCode ? (
              <Banner className="mt3" state="error" text={<Text id="Activate2FA.ConfirmQR.error" />} />
            ) : null}
          </form>
        );
      }}
    />
  );
};
