// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { Duration, Text, Currency } from '@kwara/components/src/Intl';
import { Card } from '@kwara/components/src/Card';
import { Statistic } from '@kwara/components/src/Statistic';
import { InfoPanel } from '@kwara/components/src/InfoPanel';

import { Grid } from '../../../../components/Grid';
import { LoansPanel } from '../../../../components/LoansPanel';

import { Title, type SubStepComponentProps } from '.';

export const LoanInfo = ({ StackChild, data }: SubStepComponentProps) => {
  const { loan } = data;
  const { remittance, loanApplication, disbursementMode } = loan;
  const repaymentMethod = get('method', remittance);
  const payOffLoans = get('payOffLoans', loanApplication);

  return (
    <>
      <Title StackChild={StackChild} name={loan.product.name} id={loan.id} />

      <StackChild size="widest">
        <Card border={true}>
          <Grid columns={5} width="w-20">
            <Statistic
              compact
              size="small"
              title={<Text id="LoanApprove.Overview.approvedLoanAmount" />}
              value={<Currency value={loan.amount} />}
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.requestedAmount" />}
              value={
                <Currency
                  value={
                    get('requestedAmount', loanApplication) ? get('requestedAmount', loanApplication) : loan.amount
                  }
                />
              }
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.duration" />}
              value={<Duration value={loan.duration} />}
              size="small"
              compact={true}
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.frequency" />}
              value={<Duration value={loan.repaymentFrequency} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.individualInstallmentAmount" />}
              value={<Currency value={get('installmentAmount', loanApplication)} />}
              size="small"
              compact
            />
            <Statistic
              compact
              size="small"
              title={<Text id="LoanApprove.Overview.eligible" />}
              value={<Currency value={loan.member.eligibleAmount} />}
            />
            <Statistic
              compact
              size="small"
              title={
                <>
                  <Text id="LoanApprove.Overview.payOffLoans" />
                  <InfoPanel>
                    <LoansPanel loans={payOffLoans} />
                  </InfoPanel>
                </>
              }
              value={<Currency value={get('totalPayoffAmount', loanApplication)} />}
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.totalPayoffInstallmentAmount" />}
              value={<Currency value={get('totalPayoffInstallmentAmount', loanApplication)} />}
              size="small"
              compact
            />
            <Statistic
              compact
              size="small"
              title={<Text id="LoanApprove.Overview.disbursementMode" />}
              value={<Text id={`TransactionMethod.${disbursementMode}`} />}
            />
            <Statistic
              compact
              size="small"
              title={<Text id="LoanApprove.Overview.repaymentMethod" />}
              value={<Text id={`TransactionMethod.${repaymentMethod}`} />}
            />
          </Grid>
        </Card>
      </StackChild>
    </>
  );
};
