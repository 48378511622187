import { LoanFormData } from 'LoanSharedTypes';

import { ERRORS } from '@kwara/lib/src/validator';
import { TransactionChannels, TransactionChannelT } from '@kwara/models/src/models/Transactions';

const ZERO_AMOUNT = 0;

interface Data extends Partial<LoanFormData> {
  paymentMethod?: TransactionChannelT;
  anticipatedPayout: string;
  floatBalance: string;
}

export function validateDisbursementAmount(
  amount: string,
  { floatBalance, anticipatedPayout, paymentMethod }: Data
): string | null {
  const disbursalAmount = Number(amount);
  //anticipatedPayout is the payable amount after all fees deduction
  if (Number(anticipatedPayout) <= ZERO_AMOUNT) {
    return ERRORS.rangeUnderflow;
  }

  //floatBalance is the account balance
  if (paymentMethod === TransactionChannels.mobileMpesaInstant && disbursalAmount > Number(floatBalance)) {
    return ERRORS.rangeOverflow;
  }

  return null;
}
