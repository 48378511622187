import { useInfiniteQuery } from 'react-query';

import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import flatMap from 'lodash/fp/flatMap';
import first from 'lodash/fp/first';

export function usePagination(queryKey, fetchFn, includes, where, select) {
  const r = useInfiniteQuery([queryKey, includes, where, select], fetchFn, {
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = get('meta.stats.total.pages', lastPage);

      return size(allPages) < totalPages ? size(allPages) + 1 : undefined;
    }
  });

  const proxies = get('data.pages', r);
  const proxy = first(proxies);
  const totalResults = get('meta.stats.total.count', proxy);
  const allData = flatMap(proxy => proxy.data, proxies) || [];

  return { ...r, totalResults, allData };
}
