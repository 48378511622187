import React from 'react';
import reject from 'lodash/fp/reject';
import { TillTellerLink, UserT, TillT, TillTellerLinkT } from '@kwara/models/src';

import { BodyContentWrapper } from '../../layouts';
import { useTills } from '../../models/hooks/useTills';
import { usePaginatedUsers } from '../../models/hooks/Users/usePaginatedUsers';
import { TillsManagementList } from './components/List/TillsManagementList';
import { TillsManagementModal } from './components/Modal/TillsManagementModal';

type Props = {
  tills: TillT[];
  isLoading: boolean;
  refetchTills: () => void;
  users: UserT[];
};

export function TillsManagementLayout({ tills = [], isLoading, refetchTills, users }: Props) {
  const [till, setTill] = React.useState<TillT | null>(null);

  function onRowClick(till: TillT) {
    setTill(till);
  }

  function onReset() {
    setTill(null);
  }

  async function onAdd({ tellerId, tillId }) {
    const link = TillTellerLink.create({ tellerId, tillId });
    const didSave = await link.save({ returnScope: TillTellerLink.includes('teller') });

    if (didSave) {
      refetchTills();
      const updated = till.dup();
      updated.tillTellerLinks = [...till.tillTellerLinks, link];
      setTill(updated);
    }
  }

  async function onRemove(link: TillTellerLinkT) {
    const didDestroy = await link.destroy();

    if (didDestroy) {
      refetchTills();
      const updated = till.dup();
      updated.tillTellerLinks = reject(l => l.id == link.id, till.tillTellerLinks);
      setTill(updated);
    }
  }

  return (
    <BodyContentWrapper.Root headId="TillsManagement.title">
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <TillsManagementModal till={till} onBack={onReset} onRemove={onRemove} onAdd={onAdd} users={users} />
          <TillsManagementList tills={tills} isLoading={isLoading} onRowClick={onRowClick} />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

const includes = [];
function TillsManagement() {
  const tillsResponse = useTills('till_teller_links.teller');
  const usersResponse = usePaginatedUsers(includes, { pageSizeParam: 500 });

  return (
    <TillsManagementLayout
      tills={tillsResponse.data || []}
      isLoading={tillsResponse.isLoading}
      refetchTills={tillsResponse.refetch}
      users={usersResponse.allData || []}
    />
  );
}

export default TillsManagement;
