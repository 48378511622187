import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import { InputProps, FieldProps } from '@kwara/components/src/Form';

import { Field } from '..';
import { Size, Data, Combobox } from './Combobox';

type SubscribedComboboxProps = InputProps &
  FieldProps & {
    size: Size;
    data: Data;
    placeholderId?: string;
    nothingFoundId?: string;
    onChange?: (value: string) => void;
  };

export const SubscribedCombobox = ({
  data,
  size,
  placeholderId,
  nothingFoundId,
  onChange,
  disabled,
  errorBaseId,
  required,
  name,
  titleId,
  labelId,
  infoId,
  info,
  values,
  margin,
  showInfo,
  compact
}): SubscribedComboboxProps => (
  <FinalFormField
    name={name}
    render={({ input, meta }) => {
      return (
        <Field
          disabled={disabled}
          error={meta.error && meta.touched}
          name={input.name}
          required={required}
          titleId={titleId}
          labelId={labelId}
          errorBaseId={errorBaseId}
          errorCodes={meta.error}
          infoId={infoId}
          values={values}
          margin={margin}
          info={info}
          showInfo={showInfo}
          compact={compact}
        >
          <Combobox
            size={size}
            name={input.name}
            placeholderId={placeholderId}
            nothingFoundId={nothingFoundId}
            data={data}
            required={required}
            disabled={disabled}
            {...input}
            value={input.value}
            onChange={value => {
              if (value) {
                input.onChange(String(value));
                onChange && onChange(String(value));
              } else {
                input.onChange(value);
                onChange && onChange(value);
              }
            }}
          />
        </Field>
      );
    }}
  />
);
