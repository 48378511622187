import round from 'lodash/round';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { LoanType, LoanProductType, SavingProductType } from '@kwara/models/src';

export function getAmountUiState({
  loan,
  product,
  predefinedFeeKey
}: {
  loan?: LoanType;
  product: LoanProductType | SavingProductType;
  predefinedFeeKey: string;
}): { showAmountInput: boolean; showDummyAmount: boolean; dummyAmount?: string; percentageAmount?: string } {
  if (!predefinedFeeKey) {
    return { showAmountInput: false, showDummyAmount: false };
  }

  if (predefinedFeeKey === 'OTHER') {
    return { showAmountInput: true, showDummyAmount: false };
  }

  const matchingFee = find(fee => fee.id === predefinedFeeKey, product.fees);

  if (!matchingFee) {
    return { showAmountInput: false, showDummyAmount: false };
  }

  if (!!matchingFee.amount) {
    return { showAmountInput: false, showDummyAmount: true, dummyAmount: matchingFee.amount };
  }

  if (!!matchingFee.percentageAmount) {
    const disbursedAmount = get('amount', loan) || 0;
    const dummyAmount = (Number(matchingFee.percentageAmount) / 100) * Number(disbursedAmount);
    return {
      showAmountInput: false,
      showDummyAmount: true,
      dummyAmount: String(round(dummyAmount, 2)),
      percentageAmount: matchingFee.percentageAmount
    };
  }

  return { showAmountInput: true, showDummyAmount: false };
}
