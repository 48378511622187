// @flow
import * as React from 'react';

import { appName } from '@kwara/lib/src/utils';

import { Collateral } from './Collateral';
import { Guarantor } from './Guarantor';
import { type LoanSubstepProps } from '../..';
import { type LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { SKIP_WARNING_KEY, type Message } from './Guarantor/hooks/useGuarantorValidation';

export const INELIGIBLE_GUARANTOR_ERROR = 'INELIGIBLE_GUARANTOR_ERROR';

export const Security = (props: LoanSubstepProps) => {
  return (
    <>
      {appName.isSacco && <Guarantor {...props} />}
      <Collateral {...props} />
    </>
  );
};

Security.validate = data => {
  return {
    ...Collateral.validate(data),
    ...Guarantor.validate(data)
  };
};

interface Data extends LoanFormData {
  [SKIP_WARNING_KEY]: boolean | undefined;
}

Security.validateGuarantors = async (data: Data) => {
  if (!appName.isSacco || data[SKIP_WARNING_KEY] || !data.guarantors) {
    return;
  }

  const message: Message[] = [];

  data.guarantors.forEach(({ amount, canGuaranteeUpTo, member }) => {
    if (Number(amount) > canGuaranteeUpTo) {
      message.push({
        firstName: member.firstName,
        canGuaranteeUpTo: canGuaranteeUpTo
      });
    }
  });

  if (!message.length) {
    return;
  }

  throw {
    name: INELIGIBLE_GUARANTOR_ERROR,
    message
  };
};
