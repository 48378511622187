import * as React from 'react';
import get from 'lodash/fp/get';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { If } from '@kwara/components/src/If/If';
import { Text, Percent, Date, Duration } from '@kwara/components/src/Intl';
import { CollateralFields } from '@kwara/models/src';
import { Currency } from '@kwara/components/src/Intl';
import { LoanSubstepProps } from '@kwara/components/src/pages/LoanAdd';

import { WizardSummary } from '../../../../../components/WizardSummary';
import { getTwoThirdsRuleValues } from '../../../utils/getTwoThirdsRuleValues';
import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';
import { useCreditCheckContext } from '../../../components/CreditCheck/CreditCheckImp';

const currency = (value, showSymbol = false) =>
  value == null ? null : <Currency value={value} format={showSymbol && 'currency'} />;
const SummaryAndReview = ({ data, parentUrl }: LoanSubstepProps) => {
  const {
    guarantors,
    collaterals,
    member,
    product,
    amount,
    loan,
    disbursementType,
    anticipatedDisbursementDate,
    totalAdditionalFees,
    anticipatedPayout,
    recommendedAmount,
    identityType,
    identityValue,
    notes
  } = data;
  const { hasError } = useCreditCheckContext();
  const store = useSaccoProfileContext();
  const editUrl = (path: string) => `${parentUrl}/${path}`;
  const { remittance, disbursementMode, repaymentFrequency, loanApplication = {}, duration } = loan;
  const { id: memberId, totalLoansBalance, totalSavings, business } = member;
  const { netIncome, otherDeductibles, otherIncomeAmount, totalMonthlyLoanRepayment } = loanApplication;

  const repaymentMethod = get('method', remittance);
  const repaymentAmount = get('amount', remittance);

  const applicationArgs = {
    totalMonthlyLoanPayments: totalMonthlyLoanRepayment,
    netIncome,
    otherDeductibles,
    otherIncomeAmount,
    repaymentAmount
  };
  const { passesTwoThirdsRule, totalMonthlyNetIncome, totalAvailableForRepayment } = getTwoThirdsRuleValues(
    applicationArgs
  );
  const creditCheckSuccess = !hasError;

  return (
    <>
      <WizardSummary.Section titleId="LoanAppraisal.SummaryAndReview.Member.title" ariaLabel="Member">
        <WizardSummary.Record labelId="LoanAppraisal.SummaryAndReview.Member.id" value={memberId} />
        <WizardSummary.Record labelId="LoanAppraisal.SummaryAndReview.Member.name" value={member.fullName()} />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Member.loans"
          value={currency(totalLoansBalance)}
        />
        <WizardSummary.Record labelId="LoanAppraisal.SummaryAndReview.Member.deposits" value={currency(totalSavings)} />
      </WizardSummary.Section>
      <WizardSummary.Section
        titleId="LoanAppraisal.SummaryAndReview.LoanProduct.title"
        editUrl={editUrl('loanDetails/1')}
        ariaLabel="LoanProduct"
      >
        <WizardSummary.Record labelId="LoanAppraisal.SummaryAndReview.LoanProduct.name" value={product.name} />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanProduct.interest"
          value={<Percent value={product.interestRate.percentage / 100} />}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanProduct.appliedAmount"
          value={currency(amount)}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanProduct.purpose"
          value={get('loanPurpose.classification.name', loan)}
        />
      </WizardSummary.Section>
      <WizardSummary.Section titleId="LoanAppraisal.SummaryAndReview.Income.title" ariaLabel="Income">
        <If
          condition={!!loanApplication}
          do={
            <>
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.employment.type"
                value={<Text id={`Employment.Type.${get('employmentStatus', loanApplication)}`} />}
              />
              <WizardSummary.Record labelId="LoanAppraisal.SummaryAndReview.Income.business.type" value={business} />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.monthly"
                value={currency(get('netIncome', loanApplication))}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.business.secondary"
                value={get('incomeSource', loanApplication)}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.business.secondary.income"
                value={currency(get('otherIncomeAmount', loanApplication))}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.monthlyNetIncome"
                value={currency(totalMonthlyNetIncome)}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.otherDeductions"
                value={currency(get('otherDeductibles', loanApplication))}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.availableForRepayments"
                value={currency(totalAvailableForRepayment)}
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.Income.rule"
                value={<Text id={`LoanAppraisal.SummaryAndReview.Income.rule.${passesTwoThirdsRule}`} />}
              />
            </>
          }
        />
      </WizardSummary.Section>
      <WizardSummary.Section
        titleId="LoanAdd.SummaryAndReview.Security.title"
        editUrl={editUrl('incomeAndSecurity/1')}
        ariaLabel="Security"
      >
        <Text id="LoanAdd.Summary.Security.Guarantors" />
        {mapIndexed(({ member, amount: memberAmount, isMarkedForDestruction }, index) => {
          if (isMarkedForDestruction) {
            return null;
          }

          return (
            <WizardSummary.Record
              key={`${member.id}.${index}.heading`}
              labelId="LoanAdd.Summary.SecurityAmount.guarantor"
              values={{ name: member.fullName(), id: member.id }}
              value={currency(memberAmount, true)}
            />
          );
        }, guarantors)}
        <Text id="LoanAdd.Summary.Security.Collaterals" />
        {mapIndexed((collateral, i) => {
          if (collateral.isMarkedForDestruction) {
            return null;
          }

          return (
            <WizardSummary.Record
              key={`${collateral.amount}-${i}`}
              labelId={`LoanAdd.Security.Collateral.Type.${collateral[CollateralFields.type]}`}
              value={currency(collateral.amount, true)}
            />
          );
        }, collaterals)}
      </WizardSummary.Section>
      <If
        condition={store.isCrbCheckEnabled}
        do={
          <>
            <WizardSummary.Section
              titleId="LoanAppraisal.SummaryAndReview.CreditCheck.title"
              editUrl={editUrl('payOff/1')}
              ariaLabel="CreditCheck"
            >
              <If
                condition={!!identityType}
                do={
                  <WizardSummary.Record
                    labelId={`LoanAppraisal.CreditCheck.identityType.${identityType?.toUpperCase()}`}
                    value={identityValue}
                  />
                }
              />
              <WizardSummary.Record
                labelId="LoanAppraisal.SummaryAndReview.CreditCheck.metropolCRB"
                value={<Text id={`LoanAppraisal.SummaryAndReview.CreditCheck.metropolCRB.${!!creditCheckSuccess}`} />}
              />
            </WizardSummary.Section>
          </>
        }
      />
      <WizardSummary.Section
        titleId="LoanAppraisal.SummaryAndReview.LoanRepayment.title"
        editUrl={editUrl('loanDetails/1')}
        ariaLabel="LoanRepayment"
      >
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanRepayment.amount"
          value={currency(repaymentAmount)}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanRepayment.frequency"
          value={<Duration value={repaymentFrequency} />}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanRepayment.duration"
          value={<Duration value={duration} />}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.LoanRepayment.method"
          value={<Text id={`TransactionMethod.${repaymentMethod}`} />}
        />
      </WizardSummary.Section>
      <WizardSummary.Section
        titleId="LoanAppraisal.SummaryAndReview.Disbursement.title"
        editUrl={editUrl('recommendations/1')}
        ariaLabel="LoanDisbursement"
      >
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Disbursement.type"
          value={<Text id={`LoanAppraisal.Recommendations.DisbursementType.Option.${disbursementType}`} />}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Disbursement.date"
          value={<Date value={anticipatedDisbursementDate} />}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Disbursement.method"
          value={<Text id={`TransactionMethod.${disbursementMode}`} />}
        />
      </WizardSummary.Section>
      <WizardSummary.Section
        titleId="LoanAppraisal.SummaryAndReview.Recommendations.title"
        editUrl={editUrl('recommendations/1')}
        ariaLabel="Recommendations"
      >
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Recommendations.recommendedLoanAmount"
          value={currency(recommendedAmount)}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Recommendations.fees"
          value={currency(totalAdditionalFees)}
        />
        <WizardSummary.Record
          labelId="LoanAppraisal.SummaryAndReview.Recommendations.totalLoanAmount"
          value={currency(amount)}
        />
      </WizardSummary.Section>
      <WizardSummary.Section
        titleId="LoanAppraisal.SummaryAndReview.AnticipatedPayout.title"
        ariaLabel="AnticipatedPayout"
      >
        {currency(anticipatedPayout, true)}
      </WizardSummary.Section>
      <WizardSummary.Section titleId="LoanAppraisal.SummaryAndReview.Notes.title" ariaLabel="Notes">
        <If
          condition={!!notes?.loan_details?.no_section}
          do={
            <>
              <Text id="LoanAppraisal.SummaryAndReview.Notes.LoanDetails.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.loan_details?.no_section} />
            </>
          }
        />
        <If
          condition={!!notes?.income_and_security?.no_section}
          do={
            <>
              <Text id="LoanAppraisal.SummaryAndReview.Notes.IncomeAndSecurity.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.income_and_security?.no_section} />
            </>
          }
        />
        <If
          condition={!!notes?.recommendations?.no_section}
          do={
            <>
              <Text id="LoanAppraisal.SummaryAndReview.Notes.Recommendations.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.recommendations?.no_section} />
            </>
          }
        />

        <If
          condition={!!notes?.credit_check?.no_section}
          do={
            <>
              <Text id="LoanAppraisal.SummaryAndReview.Notes.CreditCheck.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.credit_check?.no_section} />
            </>
          }
        />
      </WizardSummary.Section>
    </>
  );
};

export default {
  Component: SummaryAndReview
};
