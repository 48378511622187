import React, { useMemo } from 'react';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { LoanType } from '@kwara/models/src/models/Loan';

export function useAttributes(fullName: string, loan: LoanType) {
  return useMemo(
    () => [
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.memberName" />
          </div>
        ),
        value: (
          <Link type="primary" target="_blank" underline={false} to={`/members/${loan.member.id}`}>
            {fullName}
          </Link>
        )
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.productName" />
          </div>
        ),
        value: <div className="kw-weight-bold kw-text-small">{loan.product.name}</div>
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.productType" />
          </div>
        ),
        value: (
          <div className="kw-weight-bold kw-text-small">
            <Text id={`LoanProductAdd.Product.type.${loan.product.type}`} />
          </div>
        )
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.accountId" />
          </div>
        ),
        value: <div className="kw-weight-bold kw-text-small">{loan.id}</div>
      }
    ],

    [loan.member.id, loan.product.name, loan.product.type, loan.id, fullName]
  );
}
