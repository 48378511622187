import * as React from 'react';

import { auth, AuthType } from '../../hooks';
import { useTillPage, TillPageT } from './useTillPage';

const TillUIContext = React.createContext<TillPageT | {}>({});
export const useTillUIContext = () => React.useContext(TillUIContext);

export function TillUIProvider(props: { children: React.ReactNode; auth: AuthType }) {
  const value = useTillPage(auth);

  return <TillUIContext.Provider value={value}>{props.children}</TillUIContext.Provider>;
}
