import * as React from 'react';
import cx from 'classnames';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { MemberAgeDemographicStatsT } from '@kwara/models/src/models/Dashboard/MemberAgeDemographicStats';

import { formatPieChartData } from './utils';
import { useMemberAgeDemographicStats } from './useMemberAgeDemographicStats';
import { renderCustomizedTooltip, renderCustomizedLegendForPieCharts } from '../Customized';

import styles from '../../index.module.scss';

export function AgeDemographic() {
  const r = useMemberAgeDemographicStats();

  return (
    <Loadable loading={<LoadingSkeleton />} {...r}>
      {(data: MemberAgeDemographicStatsT) => {
        const { ageDemography, totalMember } = formatPieChartData(data);

        return (
          <div className={styles['pie-chart-container']}>
            <div className="h-100">
              <span className={cx(styles['balance-sum'], 'primary-500 kw-text-xl')}>{totalMember}</span>

              <PieChart width={200} height={200}>
                <Pie
                  data={ageDemography}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  innerRadius={40}
                  outerRadius={80}
                  paddingAngle={2}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {ageDemography.map((entry, index) => (
                    <Cell key={index.toString()} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={props => renderCustomizedTooltip({ ...props, isCurrency: true })} />
              </PieChart>
            </div>

            {renderCustomizedLegendForPieCharts(ageDemography)}
          </div>
        );
      }}
    </Loadable>
  );
}
