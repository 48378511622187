import { attr } from 'spraypaint';

import Base from './Base';

export const NextOfKin = Base.extend({
  static: {
    jsonapiType: 'next_of_kins'
  },
  attrs: {
    name: attr(),
    phoneNumber: attr(),
    relationship: attr()
  }
});

export interface NextOfKinT {
  name: string;
  phoneNumber: string;
  relationship: string;
}
