import { useQuery } from 'react-query';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { SchedulesPreview } from '@kwara/models/src/models/SchedulePreview';

import { LoanTopupFormTypes } from '../..';
import { useGetNewPrincipalBalance } from './useGetNewPrincipalBalance';

export function useGetSchedule(values: LoanTopupFormTypes) {
  const {
    loan: { firstRepaymentDate },
    product,
    repaymentPeriodUnit,
    repaymentPeriod,
    loanDuration,
    anticipatedDisbursementDate
  } = values;

  const amount = useGetNewPrincipalBalance(values);

  const productId = product.id;
  const repaymentInstallments = calculateNumberInstallments({
    loanDuration: Number(loanDuration),
    period: Number(repaymentPeriod)
  });
  const params = {
    amount,
    repaymentInstallments,
    repaymentPeriod,
    repaymentPeriodUnit,
    interestRate: product.interestRate.percentage,
    firstRepaymentDate: firstRepaymentDate ? formatIsoDate(firstRepaymentDate) : null,
    anticipatedDisbursementDate: anticipatedDisbursementDate ? formatIsoDate(anticipatedDisbursementDate) : null
  };

  return useQuery(['schedules_preview', productId], () => SchedulesPreview.generate(productId, params), {
    enabled: productId != undefined,
    retry: false
  });
}
