import { useCallback } from 'react';

import { minus } from '@kwara/lib/src/currency';

const MIN_BALANCE = 0;

export function useGetWriteOffBalanceData() {
  return useCallback(
    (initialBalance: string, desiredBalance: string) => {
      const initial = Number(initialBalance);
      const desired = Number(desiredBalance);
      const isWriteOffable = desired >= MIN_BALANCE && desired < initial;

      return {
        isWriteOffable,
        value: Math.max(Number(minus(initial, desired)), MIN_BALANCE)
      };
    },

    []
  );
}
