import { useCallback, useState } from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/size';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import compact from 'lodash/fp/compact';
import { FormProps } from 'react-final-form';

import { appName } from '@kwara/lib/src/utils';
import { IdDocument } from '@kwara/models/src';
import { isStandardIdDocumentType } from '@kwara/components/src/Intl';
import { OnControlNextButton, CustomFormPropsTypes } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import verifyIdentityInstance from './utils/verifyIdentity';
import { useVerifyIdentityContext, BUTTONS_APPEAR_AS, VerifyIdentityContextType } from '../..';

type UseIdentityArg = {
  prefix: string;
  data: Record<string, any>;
  memberEdit: boolean;
  enableNextButton: (onControlledState?: OnControlNextButton) => void;
  onChange(data: Record<string, any>): Promise<Record<string, any>>;
  formProps: CustomFormPropsTypes<Record<string, any>>;
};

export function useIdentity(arg: UseIdentityArg) {
  const { prefix, data, formProps, memberEdit, enableNextButton, onChange } = arg;

  const getPath = useCallback(
    (name: string) => {
      return pipe(compact, join('.'))([prefix, name]);
    },
    [prefix]
  );

  const DOCUMENTS_PATH = getPath('idDocuments');
  const idDocuments = appName.isMember ? [{}] : get(DOCUMENTS_PATH, data);

  const [isOpen, setOpenState] = useState(() => size(idDocuments) > 1);
  const verifyIdContext: VerifyIdentityContextType = useVerifyIdentityContext();

  function isRequired(fieldName: string, config: Record<string, any>, formProps: FormProps) {
    const fieldValidate: Record<string, any> = get(`validate.${fieldName}`, config);

    if (fieldValidate?.isRequired) {
      return fieldValidate.isRequired(formProps.values[fieldName], formProps.values);
    }

    return false;
  }

  function addSecondaryIdDocument(data: Record<string, any>) {
    const idDocument = new IdDocument();

    idDocument.id = 'temp-id';

    if (!data.idDocuments) data.idDocuments = [];

    data.idDocuments.push(idDocument);

    return data;
  }

  function removeSecondaryIdDocument(data: Record<string, any>) {
    data.idDocuments = [data.idDocuments[0]];

    return data;
  }

  function onRemoveId() {
    onChange(removeSecondaryIdDocument(data));
    setOpenState(false);
  }

  function onAddId() {
    onChange(addSecondaryIdDocument(data));
    setOpenState(true);
  }

  function onClickLinkButtonHandler() {
    if (isOpen) onRemoveId();
    else onAddId();
  }

  function onSelectDocumentType(isPrimary: boolean) {
    return () => {
      /**
       * if the user re-selects the primary documents, we trigger reverification
       */
      if (isPrimary) {
        verifyIdentityInstance.resetState();

        verifyIdContext?.setAppearAs?.(BUTTONS_APPEAR_AS.VERIFY);

        enableNextButton();
      }
    };
  }

  const getProps = useCallback(
    (idDocumentIndex: number) => {
      const isPrimaryId = idDocumentIndex === 0;
      const name = `${DOCUMENTS_PATH}[${idDocumentIndex}]`;
      const idType: string = get(`values.${name}.type`, formProps);
      const showLink = !memberEdit && ((isPrimaryId && !isOpen) || (!isPrimaryId && isOpen));
      const inputName = `${name}.documentId`;
      const isStandardDocType = isStandardIdDocumentType(idType);
      const identityInputDataProp = {
        type: get(`values.${name}.type`, formProps),
        firstName: get(`values.${getPath('firstName')}`, formProps),
        lastName: formProps.values.lastName,
        documentId: get(`values.${name}.documentId`, formProps)
      };

      return { name, idType, isPrimaryId, showLink, inputName, isStandardDocType, identityInputDataProp };
    },
    [DOCUMENTS_PATH, formProps, getPath, isOpen, memberEdit]
  );

  return {
    isOpen,
    idDocuments,
    getPath,
    getProps,
    isRequired,
    onClickLinkButtonHandler,
    onSelectDocumentType
  };
}
