import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { LoanType, MemberEligibilityT } from '@kwara/models/src';

interface MemberEligibilityType {
  eligibleAmount: number;
  multiplier: number;
}
export function pickMemberEligibilityPerLoanProduct(
  loan: LoanType,
  memberEligibilityData?: MemberEligibilityT[]
): MemberEligibilityType {
  const productEligibility = find(e => e.id === loan.product.id, memberEligibilityData);
  if (!productEligibility) {
    const member = get('member', loan);
    const { eligibleAmount, eligibilityMultiplier: multiplier } = member;
    return { eligibleAmount, multiplier };
  }

  const { eligibleAmount, multiplier } = productEligibility;
  return { eligibleAmount, multiplier };
}
