import * as React from 'react';

import { Address as AddressBase } from '../../../../../../../MemberAdd/components/Address';

export function Address(props) {
  return <AddressBase {...props} size="stretch" prefix="member" />;
}

Address.validate = {
  'member.addresses[0].postalAddress': {
    isRequired: () => true
  },
  'member.addresses[0].physicalAddress': {
    isRequired: () => true
  }
};
