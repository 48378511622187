import { StepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import LoanDetails from './components/LoanDetails/LoanDetails';
import MemberProfile from './components/MemberProfile/MemberProfile';
import Attachments from './components/Attachments/Attachments';
import PreviousNotes from './components/PreviousNotes/PreviousNotes';
import Approvals from './components/Approvals/Approvals';
import CreditReport from '../components/CreditCheck/pages/CreditReport';

/**
 * this is a temporal function used to construct
 * memberProfile step for either KwaraKenya
 * or other SACCOs. Ones the AB testing is done,
 * the isKwaraKenyaOrE2E version will be released to all orgs
 */
function generateMemberProfileStep(isKwaraKenyaOrE2E: boolean, isCrbCheckEnabled: boolean) {
  if (isKwaraKenyaOrE2E) {
    return {
      memberProfile: {
        titleId: 'LoanDisbursal.MemberProfile.LeftNavigation.title',
        subtitleId: 'LoanDisbursal.MemberProfile.LeftNavigation.subTitle',
        actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'creditCheck' }],
        children: [
          {
            titleId: 'LoanDisbursal.MemberProfile.title',
            Component: MemberProfile.Component,
            actions: [
              {
                appearsAs: 'next',
                behavesAs: 'next',
                destination: isCrbCheckEnabled ? 'creditCheck' : 'attachments'
              }
            ]
          }
        ]
      }
    };
  }

  return {
    memberProfile: {
      titleId: 'LoanDisbursal.MemberProfile.LeftNavigation.title',
      subtitleId: 'LoanDisbursal.MemberProfile.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: isCrbCheckEnabled ? 'creditCheck' : 'attachments'
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.MemberProfile.title',
          Component: MemberProfile.Component
        }
      ]
    }
  };
}

type StepArg = {
  isCrbCheckEnabled: boolean;
  canSubmit: boolean;
  isKwaraKenyaOrE2E: boolean;
};

export function steps({ isCrbCheckEnabled, canSubmit, isKwaraKenyaOrE2E }: StepArg) {
  return {
    loanDetails: {
      titleId: 'LoanDisbursal.LoanDetails.LeftNavigation.title',
      subtitleId: 'LoanDisbursal.LoanDetails.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'memberProfile'
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.LoanDetails.title',
          Component: LoanDetails.Component,
          validate: LoanDetails.validate
        }
      ]
    },
    ...generateMemberProfileStep(isKwaraKenyaOrE2E, isCrbCheckEnabled),
    creditCheck: {
      titleId: 'LoanDisbursal.CreditCheck.LeftNavigation.title',
      subtitleId: `LoanDisbursal.CreditCheck.LeftNavigation.subTitle.${
        isCrbCheckEnabled ? 'ForCrbEnabledUser' : 'ForNonCrbEnabledUser'
      }`,
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'attachments'
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.CreditCheck.title',
          Component: CreditReport.Component,
          validate: CreditReport.validate
        }
      ]
    },
    attachments: {
      titleId: 'LoanDisbursal.Attachments.LeftNavigation.title',
      subtitleId: 'LoanDisbursal.Attachments.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'previousNotes'
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.Attachments.title',
          Component: Attachments.Component
        }
      ]
    },
    previousNotes: {
      titleId: 'LoanDisbursal.PreviousNotes.LeftNavigation.title',
      subtitleId: 'LoanDisbursal.PreviousNotes.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'approvals'
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.PreviousNotes.title',
          Component: PreviousNotes.Component,
          validate: PreviousNotes.validate
        }
      ]
    },
    approvals: {
      titleId: 'LoanDisbursal.Approvals.LeftNavigation.title',
      subtitleId: 'LoanDisbursal.Approvals.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'submit',
          behavesAs: 'complete',
          isPermitted: canSubmit
        }
      ],
      children: [
        {
          titleId: 'LoanDisbursal.Approvals.title',
          Component: Approvals.Component,
          validate: Approvals.validate
        }
      ]
    }
  } as StepConfig;
}
