import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import mapValues from 'lodash/mapValues';

import Action from './action';
import {
  StepConfig,
  DirectStep,
  SequentialStep,
  SequentialSubStep,
  RenderableStepConfig,
  HideActions,
  RenderableStep
} from '../types/sharedTypes';

const FirstSubStepSymbol = Symbol('FirstSubStep');

///
function pathForSequentialSubStep(id: string, index: number) {
  return `${id}/${index + 1}`;
}

///
function nextSubStep(index: number, steps: SequentialSubStep[]) {
  const value = index + 1 < steps.length ? { index: index + 1, subStep: steps[index + 1] } : null;
  return value;
}

/// Find a step by ID and work out if it's sequential or direct
function stepType(graph: StepConfig, id: string): 'sequential' | 'direct' {
  if (graph[id] == null) {
    throw new Error(`Step "${id}" not found`);
  }
  return (graph[id] as SequentialStep).children ? 'sequential' : 'direct';
}

///
function directAction(graph: StepConfig, action: Action, currentPath: string, meta: Record<string, any>): Action {
  if (!(action instanceof Action)) {
    throw new Error(`action must be an instance of Action`);
  }
  if (Action.isFinalStep(action)) {
    return action;
  }

  const nextAction = graph[action.destination!];

  if (stepType(graph, action.destination!) === 'sequential') {
    const nextPath = pathForSequentialSubStep(action.destination!, 0);

    meta[nextPath] = { prevPath: currentPath };
    action.destinationPath = nextPath;

    if (typeof nextAction.isPermitted === 'boolean') {
      action.isPermitted = nextAction.isPermitted;
    }
    return action;
  } else {
    const nextPath = action.destination;

    meta[nextPath!] = { prevPath: currentPath };
    action.destinationPath = nextPath;

    if (typeof nextAction.isPermitted === 'boolean') {
      action.isPermitted = nextAction.isPermitted;
    }
    return action;
  }
}

///
type StepReducer = {
  graph: RenderableStepConfig;
  meta: { [key: string | symbol]: any };
  ordered: any[];
};

function stepReducer(
  { graph, meta, ordered }: StepReducer,
  step: SequentialStep,
  id: string,
  original: StepConfig
): StepReducer {
  const ordering: string[] = [];

  if (step.children) {
    step.children.forEach((subStep, index, steps) => {
      const next = nextSubStep(index, steps);
      const path = pathForSequentialSubStep(id, index);
      const actions = [];

      // Process any actions within the child
      if (Array.isArray(subStep.actions)) {
        subStep.actions.forEach(action => {
          // if they have a destination
          if (typeof action.destination === 'string') {
            actions.push(directAction(original, new Action(action), path, meta));
          }
          // if they do not have a destination but do have destinationPath
          else if (!action.destination && typeof action.destinationPath === 'string' && action.isHidden === false) {
            actions.push(action);
          }
        });
      }

      const isHiddenNext =
        subStep.hideActions && typeof (subStep.hideActions as HideActions).next === 'boolean'
          ? (subStep.hideActions as HideActions).next
          : false;

      // Handle case where next substep is within sequential step
      if (next != null) {
        const nextPath = pathForSequentialSubStep(id, next.index);

        actions.push(
          new Action({
            appearsAs: 'next',
            behavesAs: 'next',
            isPermitted: true,
            isHidden: isHiddenNext,
            destination: nextPath,
            destinationPath: nextPath
          })
        );

        meta[nextPath] = { prevPath: path };

        // Handle case where existing sequential substep
      } else {
        step.actions.forEach(action => {
          if (action.behavesAs === 'next' && isHiddenNext != null) {
            action.isHidden = isHiddenNext;
          }

          const direct = directAction(original, action as Action, path, meta);
          actions.push(direct);

          if (!Action.isFinalStep(action)) {
            meta[direct.destinationPath!] = { prevPath: path };
          }
        });
      }

      if (!meta[FirstSubStepSymbol]) {
        meta[FirstSubStepSymbol] = path;
      }

      ordering.push(path);

      if (meta[path] != null && meta[path].prevPath) {
        actions.push(
          new Action({
            appearsAs: 'back',
            behavesAs: 'back',
            isPermitted: true,
            isHidden: false,
            destination: meta[path].prevPath,
            destinationPath: meta[path].prevPath
          })
        );
      }

      const parent = { ...step, id };
      delete parent.children;

      graph[path] = {
        ...subStep,
        isPermitted: step.isPermitted,
        actions,
        parent
      } as RenderableStep;
    });
  } else {
    const path = id;

    const actions = step.actions.map(action => directAction(original, action as Action, path, meta));

    if (!meta[FirstSubStepSymbol]) {
      meta[FirstSubStepSymbol] = path;
    }

    // We explicitly ignore direct steps ordering.push(path);
    if (meta[path] != null && meta[path].prevPath) {
      actions.push(
        new Action({
          appearsAs: 'back',
          behavesAs: 'back',
          isPermitted: true,
          isHidden: false,
          destination: meta[path].prevPath,
          destinationPath: meta[path].prevPath
        })
      );
    }

    graph[path] = { ...step, actions } as RenderableStep;
  }

  if (ordering.length > 0) {
    ordered.push(ordering);
  }

  return { graph, meta, ordered };
}

///
function stepsToVisitInOrder(graph: StepConfig, stepId: string, ids: string[] = []): string[] {
  const step = graph[stepId];

  if (step == null) {
    return [];
  } else if (step.actions.length === 0) {
    return [stepId];
  } else {
    return uniq([stepId, ...flatMap(step.actions, action => stepsToVisitInOrder(graph, action.destination!, ids))]);
  }
}

///
function parseActions(step: DirectStep | SequentialStep, id: string) {
  if (step && step.actions && Array.isArray(step.actions)) {
    return {
      ...step,
      actions: step.actions.map(obj => new Action(obj))
    };
  } else {
    const error = new Error(`step.actions must be an Array as step ID: ${id}`) as Record<string, any>;
    error.step = step;
    throw error;
  }
}

///
function prepare(steps: StepConfig): StepConfig {
  return mapValues(steps, parseActions);
}

///
export default ({ steps, startId }: { steps: StepConfig; startId: string | null }) => {
  // We expand the raw actions array into Action instances
  const stepsWithParsedActions = prepare(steps);
  const orderedStepIds = compact(stepsToVisitInOrder(stepsWithParsedActions, startId!));

  const initialValue = { graph: {}, meta: {}, ordered: [] } as StepReducer;
  const { graph, ordered, meta } = orderedStepIds.reduce(
    (state, id) => stepReducer(state, stepsWithParsedActions[id] as SequentialStep, id, stepsWithParsedActions),
    initialValue
  );

  return {
    subSteps: graph,
    ordered,
    firstSubStepId: meta[FirstSubStepSymbol]
  };
};

export { prepare, stepsToVisitInOrder };
