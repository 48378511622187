// @flow

import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import pipe from 'lodash/fp/pipe';
import trim from 'lodash/fp/trim';
import toLower from 'lodash/fp/toLower';

import Auth from '@kwara/models/src/lib/Auth';
import { Text } from '@kwara/components/src/Intl';
import { useNotificationContext } from '@kwara/components/src/Notification/NotificationProvider';

import {
  usePasswordExpiryAlerterContext,
  type PasswordExpiryAlerterContextType
} from '../components/PasswordExpiryAlerter';
import { passwordSegmentTrackingFacade, TYPES } from '../utils/passwordSegmentTrackingFacade';
import { useAuth } from '../../../hooks';

/// UTILS BELOW
/**************************************************/
interface HttpError extends Error {
  errors?: { status: number, code?: number, message: string, title: string }[];
}

function composeError(httpError: HttpError) {
  if (!httpError.errors) {
    return {
      errors: [
        {
          status: 502,
          code: 'APP_NETWORK_ERROR',
          message: 'Client error',
          title: 'Network Error'
        }
      ]
    };
  }

  const {
    errors: [error]
  } = httpError;

  if (error.status === 500) {
    return {
      errors: [{ ...error, code: 'INTERNAL_SERVER_ERROR' }]
    };
  }

  return { errors: [error] };
}

const PASSWORD_EXPIRED_ERROR_CODE = 'AUTH_TOKEN_DISPENSER_PASSWORD_EXPIRED_ERROR';
const processEmail = pipe(trim, toLower);

/// useHealthCheck
/**************************************************/
const useHealthCheck = (auth: Auth) => {
  const notification = useNotificationContext();

  useEffect(() => {
    async function checkHealth(auth: Auth) {
      const healthy = await auth.getHealthCheck();

      if (!healthy) {
        notification.displayError(<Text id="Errors.API_NOT_HEALTHY" />);
      }
    }
    checkHealth(auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
};

type CustomError = {
  errors: {
    status: number,
    code: string,
    ['message' | 'detail']: string,
    token?: string,
    title: string
  }[]
};

type StateT = {
  error: null | CustomError,
  email: string,
  password: string,
  loading: boolean,
  unlockSent: boolean
};

/// useLoginForm
/**************************************************/
export const useLoginForm = () => {
  const auth = useAuth();
  useHealthCheck(auth);
  const [state, setState] = useState<StateT>({
    error: null,
    email: '',
    password: '',
    loading: false,
    unlockSent: false
  });
  const passExpAlerterContext: PasswordExpiryAlerterContextType = usePasswordExpiryAlerterContext();

  /// handleLoginError
  const handleLoginError = (error: CustomError) => {
    const currentError = error.errors && error.errors[0];

    if (currentError?.code === PASSWORD_EXPIRED_ERROR_CODE) {
      passExpAlerterContext.UPDATE({ token: currentError.token, status: 'isExpired' });
      passwordSegmentTrackingFacade(TYPES.passwordExpired);
    }
  };

  /// handleEmailChange
  const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const email = get(event, 'target.value', '');

    setState(state => ({ ...state, email }));
  };

  /// handlePasswordChange
  const handlePasswordChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const password = get(event, 'target.value', '');

    setState(state => ({ ...state, password }));
  };

  /// Login
  const logIn = async (evt: SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setState(prev => ({ ...prev, loading: true }));

    try {
      await auth.logIn({ email: processEmail(state.email), password: state.password });
      window.location.replace('/');
    } catch (error) {
      setState(prev => ({ ...prev, error: composeError(error), loading: false }));
      handleLoginError(error);
    }
  };

  const resendUnlockLink = () => {
    auth
      .resendUnlockLink(state.email)
      .then(() => {
        setState(prevState => ({ ...prevState, error: null, unlockSent: true }));
        setTimeout(() => {
          setState(prevState => ({ ...prevState, unlockSent: false }));
        }, 4000);
      })
      .catch(error => {
        setState(prevState => ({ ...prevState, error }));
      });
  };

  return {
    state,
    handleEmailChange,
    handlePasswordChange,
    resendUnlockLink,
    logIn
  };
};
