//@flow

import * as React from 'react';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';

import { type LoanProductType, type SavingProductType, type MemberEligibilityT } from '@kwara/models/src';

import { SubscribedRadioLoanProductCard, SubscribedRadioSavingProductCard } from './SubscribedProductCard';

import styles from './index.module.scss';

type SupportedProductTypes = SavingProductType | LoanProductType;
type SupportedProductsTypes = SavingProductType[] | LoanProductType[];
type SelectHandler = (product: SupportedProductTypes) => void;

type BaseProps = {
  products: SupportedProductsTypes,
  onSelect?: SelectHandler,
  selectable?: boolean,
  eligibilities?: MemberEligibilityT[]
};
type Props = BaseProps & {
  productCard: any // TO DO: Type this correctly
};

export const ProductSelectorContainer = ({ children }: { children: React.Node }) => (
  <div data-testid="ProductSelector" className={styles.Container}>
    {children}
  </div>
);

// Product Selector Radio Group
const ProductSelector = ({
  products,
  onSelect,
  selectable = false,
  productCard: ProductCard,
  eligibilities = []
}: Props) => {
  return (
    <ProductSelectorContainer>
      {map(product => {
        const productEligibility = find(e => e.id === product.id, eligibilities);

        product.eligibility = productEligibility;

        return <ProductCard key={product.id} product={product} onSelect={onSelect} isSelectable={selectable} />;
      }, products)}
    </ProductSelectorContainer>
  );
};

export const LoanProductSelector = (props: BaseProps) => (
  <ProductSelector {...props} productCard={SubscribedRadioLoanProductCard} />
);

export const SavingsProductSelector = (props: BaseProps) => (
  <ProductSelector {...props} productCard={SubscribedRadioSavingProductCard} />
);
