import { useMemo } from 'react';

import { Store } from '../../models/Store/Store';
import { FILTER_TYPES } from '../Filter/utils/filterTypes';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

export function useBranchesFilter(belongsTo: string = FILTER_TYPES.BRANCH_ID) {
  const { branches, profile, branchName }: Store = useSaccoProfileContext();

  return useMemo(
    () => ({
      allBranches: branches.map(branch => ({
        name: branch.name,
        value: branch.id,
        belongsTo
      })),
      selectedBranch: [
        {
          name: branchName,
          value: profile.currentBranchId,
          belongsTo
        }
      ]
    }),
    [belongsTo, branchName, branches, profile.currentBranchId]
  );
}
