// @flow

import React from 'react';
import noop from 'lodash/fp/noop';
import map from 'lodash/fp/map';
import { Field as FinalFormField } from 'react-final-form';

import { type SavingProductType } from '@kwara/models/src';
import { SavingProductCard } from '@kwara/components/src/ProductSelector/SavingProductCard';
import { ProductSelectorContainer } from '@kwara/components/src/ProductSelector';

const SubscribedProductCheckboxCard = ({
  name,
  product,
  onSelect = noop,
  isSelectable = false,
  disabled
}: {
  name: string,
  product: SavingProductType,
  onSelect?: (product: SavingProductType) => void,
  isSelectable?: boolean,
  disabled?: boolean
}) => {
  return (
    <FinalFormField
      name={name}
      type="checkbox"
      value={product.id}
      render={({ input }) => {
        return (
          <>
            <label htmlFor={product.id}>
              <SavingProductCard product={product} isSelectable={isSelectable} isSelected={!!input.checked} />
            </label>
            <input
              hidden
              {...input}
              onChange={e => {
                onSelect(product);
                input.onChange(e);
              }}
              type="checkbox"
              id={product.id}
              name={name}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export const ProductSelector = ({ products, onSelect }: { products: SavingProductType[], onSelect: Function }) => {
  return (
    <ProductSelectorContainer>
      {map(
        product => (
          <SubscribedProductCheckboxCard
            name="accounts"
            value={product.id}
            key={product.id}
            product={product}
            onSelect={onSelect}
            isSelectable
          />
        ),
        products
      )}
    </ProductSelectorContainer>
  );
};
