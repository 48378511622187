import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { Currency, Date, Time } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import Table, { Row, Cell, Heading, Footer } from '../../../../components/Table';

export const TablePreview = ({ data }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="ChequeReportsGenerate.Form.date.label" />
          <Heading translationId="ChequeReportsGenerate.Table.memberDetails.header" />
          <Heading translationId="ChequeReportsGenerate.Table.productDetails.header" />
          <Heading translationId="ChequeReportsGenerate.Table.bankDetails.header" />
          <Heading translationId="ChequeReportsGenerate.Table.chequeDrawer.header" />
          <Heading translationId="ChequeReportsGenerate.Table.amount.header" />
        </Row>
      }
      footer={<Footer colSpan={6} items={data} />}
    >
      {map(
        chequeData => (
          <Row key={chequeData.id}>
            <Cell>
              <span className="kw-text-x-small grey-400">{chequeData.id}</span>
              <br />
              <div>
                <Date value={chequeData.createdAt} />
              </div>
              <div>
                <Time value={chequeData.createdAt} />
              </div>
            </Cell>
            <Cell>
              <span className="kw-text-x-small grey-400">{chequeData.account.member.id}</span>
              <br />
              {chequeData.account.member.fullName()}
            </Cell>
            <Cell>
              <StatusTag state={chequeData.account.state.current} />
              <br />
              <span className="kw-text-x-small grey-400">{chequeData.account.id}</span>
              <br />
              {chequeData.account.name}
            </Cell>
            <Cell>
              <div>
                <span className="kw-text-x-small grey-400">{chequeData.accountNumber}</span>
              </div>
              <div>{get('bank.attributes.name', chequeData)}</div>
              <div>{get('bankBranch.name', chequeData)}</div>
            </Cell>
            <Cell>{chequeData.chequeDrawer}</Cell>
            <Cell>
              <Currency value={chequeData.amount} />
            </Cell>
          </Row>
        ),
        data
      )}
    </Table>
  );
};
