import { LoanType } from '@kwara/models/src/models/Loan';
import { LoanApplicationT } from '@kwara/models/src/models/LoanApplication';

import { useAnticipatedPayoutApiFetch, useFeeValues, Handlers } from '../../../hooks';

export function useApprovalsValues(loan: LoanType, handlers?: Handlers) {
  const {
    member,
    product,
    disbursementMode,
    anticipatedDisbursementDate,
    amount: _amount,
    loanApplication = {} as LoanApplicationT
  } = loan;

  const amount = String(_amount);

  const {
    disbursementType,
    recommendedAmount,
    payOffLoans = [],
    loanApplicationFees = [],
    disbursement
  } = loanApplication;

  const { totalAdditionalFees } = useFeeValues({
    product,
    amount,
    feeApplications: loanApplicationFees
  });

  const {
    anticipatedPayout,
    isFetching: isFetchingAnticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount
  } = useAnticipatedPayoutApiFetch({
    member,
    amount,
    product,
    totalAdditionalFees,
    payOffLoans: payOffLoans.map((loan: LoanType) => loan.id),
    handlers
  });

  const updatedDisbursementMode = !!disbursement ? disbursement.paymentMethod : disbursementMode;
  const updatedDisbursementDate = !!disbursement ? disbursement.valueDate : anticipatedDisbursementDate;

  return {
    anticipatedPayout,
    disbursementType,
    disbursementDate: updatedDisbursementDate,
    disbursementMode: updatedDisbursementMode,
    recommendedAmount,
    isFetchingAnticipatedPayout,
    appliedAmount: amount,
    eligibleAmount: member.eligibleAmount,
    disbursement,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount
  };
}
