import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import map from 'lodash/map';

import { FilterConfig } from 'FilterTypes';

import { MemberStates, MemberStatesV1 } from '@kwara/models/src';

import { StatusFilter } from './StatusFilter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';

export function useMemberListStatusFilterConfig(isV1: boolean) {
  const states = isV1 ? MemberStatesV1 : MemberStates;
  const { formatMessage } = useIntl();
  return useMemo<FilterConfig>(() => {
    return {
      [FILTER_TYPES.STATUS]: {
        data: [
          { value: '', name: formatMessage({ id: 'MemberList.noFilter' }), belongsTo: FILTER_TYPES.STATUS },
          ...map(states, value => ({
            value,
            name: formatMessage({ id: `MemberStatus.${value}` }),
            belongsTo: FILTER_TYPES.STATUS
          }))
        ],
        page: StatusFilter
      }
    };
  }, [formatMessage, states]);
}
