import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { LoanApplicationT } from '@kwara/models/src/models/LoanApplication';
import { LoanAttachmentT } from '@kwara/models/src/models/LoanAttachment';
import { ViewerModal } from '@kwara/components/src/UploadWidgetUI';

import { useAttachment } from './useAttachments';

type VProps = {
  attachments: LoanAttachmentT[];
  loanApplication: LoanApplicationT;
  id: string;
  onCancel: () => void;
  disabled?: boolean;
  isClosed: boolean;
  details: { name?: string; filesize?: number; type: string } | LoanAttachmentT;
};

export const Viewer = ({ attachments, loanApplication, id, onCancel, isClosed, details }: VProps) => {
  const [attachment = {}] = useAttachment(id, attachments, loanApplication);

  return (
    <ViewerModal
      titleId={`DocumentUploads.${details.name}.label`}
      isClosed={isClosed}
      attachment={attachment}
      onCancel={onCancel}
      details={details}
    />
  );
};

type WVProps = {
  attachments: LoanAttachmentT[] | null;
  children: (opts: { setShownAttachment: (attachment?: LoanAttachmentT) => void }) => React.ReactElement;
  loanApplication: LoanApplicationT;
};

export const WithViewer = ({ loanApplication, attachments, children }: WVProps) => {
  const [shownAttachment, setShownAttachment] = React.useState<LoanAttachmentT>({} as LoanAttachmentT);

  return (
    <If
      condition={!attachments}
      do={children({ setShownAttachment: () => {} })}
      else={
        <>
          <Viewer
            isClosed={!shownAttachment.name}
            id={shownAttachment.id}
            details={shownAttachment}
            loanApplication={loanApplication}
            attachments={attachments}
            onCancel={() => setShownAttachment({} as LoanAttachmentT)}
          />
          {children({ setShownAttachment })}
        </>
      }
    />
  );
};
