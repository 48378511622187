import React from 'react';
import trim from 'lodash/fp/trim';

import { useOverviewList, fetchWithPagination } from '../../../../../../models/hooks/base';

import { DividendContributionSummaries } from '../../../../../../models';

function useSearch() {
  const [where, setWhere] = React.useState({
    search: ''
  });

  const onSearchChange = (val: string) => {
    setWhere({ ...where, search: trim(val) });
  };

  return { where, onSearchChange };
}

async function fetchDividendReports(reportId: string, opts) {
  const model = DividendContributionSummaries(reportId);
  return fetchWithPagination(model, opts);
}

export function useDividendContributionSummaries(reportId: string) {
  return useOverviewList({
    queryKey: `dividend_contribution_summaries_${reportId}`,
    fetchFn: opts => fetchDividendReports(reportId, opts),
    select: null,
    includes: [],
    useWhere: useSearch
  });
}
