import { ValueOf } from 'GlobalTypes';

export const VALID_WIZARD_ACTIONS_APPEARANCE = Object.freeze({
  approve: 'approve',
  close: 'close',
  back: 'back',
  cancel: 'cancel',
  nextWithPromise: 'nextWithPromise',
  next: 'next',
  reject: 'reject',
  review: 'review',
  submit: 'submit',
  skip: 'skip',
  print: 'print',
  verify: 'verify',
  retry: 'retry'
});

export const VALID_WIZARD_COMPLETION_BEHAVIORS = Object.freeze({
  cancel: 'cancel',
  complete: 'complete',
  approve: 'approve',
  reject: 'reject',
  skip: 'skip',
  print: 'print'
});

export const VALID_WIZARD_ACTIONS_BEHAVIORS = Object.freeze({
  ...VALID_WIZARD_COMPLETION_BEHAVIORS,
  back: 'back',
  next: 'next',
  nextWithPromise: 'nextWithPromise'
});

type ValidAppearance = ValueOf<typeof VALID_WIZARD_ACTIONS_APPEARANCE>;

type CompletionBehaviour = ValueOf<typeof VALID_WIZARD_COMPLETION_BEHAVIORS>;

type ValidBehaviour = ValueOf<typeof VALID_WIZARD_ACTIONS_BEHAVIORS>;

export type ActionConfig = {
  isHidden?: boolean;
  isPermitted?: boolean;
  behavesAs: ValidBehaviour;
  appearsAs: ValidAppearance;
  destination?: string | null;
  destinationPath?: string | null;
  onNext?: (
    data: Record<string, any>,
    onChange: <DataType = Record<string, any>>(updatedData: DataType) => Promise<unknown>
  ) => Promise<any>;
};

export interface ActionConfigError extends Error {
  config?: ActionConfig;
}

const ValidAppearances: ValidAppearance[] = Object.values(VALID_WIZARD_ACTIONS_APPEARANCE);

const CompletionBehaviours: CompletionBehaviour[] = Object.values(VALID_WIZARD_COMPLETION_BEHAVIORS);

const ValidBehaviours: ValidBehaviour[] = Object.values(VALID_WIZARD_ACTIONS_BEHAVIORS);

const DEFAULTS = {
  isHidden: false,
  isPermitted: true,
  destination: null,
  destinationPath: null
};

function createConfigError(message: string, config: ActionConfig, constructor?: Function) {
  const error = new Error() as Error & Record<string, Partial<ActionConfig[keyof ActionConfig]>>;
  error.message = message;

  Error.captureStackTrace(error, constructor);

  Object.keys(config).forEach(key => {
    if (config.hasOwnProperty(key)) error[key] = config[key as keyof ActionConfig];
  });

  return error;
}

function assert(test: boolean, message: string, config: ActionConfig) {
  if (test === false) throw createConfigError(message, config);
}

export default class Action {
  isHidden: boolean;
  isPermitted: boolean;
  destination: string | null;
  destinationPath: string | null;
  appearsAs: ValidAppearance;
  behavesAs: ValidBehaviour;

  constructor(params: ActionConfig) {
    const obj = { ...DEFAULTS, ...params };
    const { appearsAs, behavesAs, isHidden, isPermitted, destination } = obj;

    assert(ValidAppearances.includes(appearsAs), `appearsAs must be one of: ${ValidAppearances.join(', ')}`, obj);
    assert(ValidBehaviours.includes(behavesAs), `behavesAs must be one of: ${ValidBehaviours.join(', ')}`, obj);
    assert(typeof isPermitted === 'boolean', `isPermitted must be boolean but was '${typeof isPermitted}'`, obj);
    assert(typeof isHidden === 'boolean', `isHidden must be boolean but was '${typeof isHidden}'`, obj);
    assert(
      Action.isFinalStep(obj) || typeof destination === 'string',
      `destination must be a string but was '${typeof destination}'`,
      obj
    );
    Object.assign(this, obj);
  }

  static isFinalStep(action: Action | ActionConfig) {
    return action.destination == null && CompletionBehaviours.includes(action.behavesAs as CompletionBehaviour);
  }
}
