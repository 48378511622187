import { useMemo } from 'react';

import { Member, SavingProduct } from '@kwara/models/src';
import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';

export type SearchParams = {
  memberId?: string;
};

export function useSavingAddPromises() {
  const memberId = useSearchParams<SearchParams>().get('memberId');

  const memberPromise = useMemo(async () => {
    if (typeof memberId !== 'string') return Promise.resolve(null);

    const response = await Member.full().find(memberId);
    return response.data;
  }, [memberId]);

  const savingProductsPromise = useMemo(async () => {
    const response = await SavingProduct.all();
    return response.data;
  }, []);

  return {
    memberPromise,
    savingProductsPromise
  };
}
