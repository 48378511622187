import { SasraReportT } from '@kwara/models/src';

import { useWaitingCursor } from '../../hooks/useWaitingCursor';

export function useSasraDownload() {
  const { onWaitingStart, onWaitingEnd, isWaiting } = useWaitingCursor();

  function onDownload(report: SasraReportT) {
    onWaitingStart();
    return report.download().finally(() => {
      onWaitingEnd();
    });
  }

  return { onDownload, isSasraDownloading: isWaiting };
}
