import * as React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { Text } from '@kwara/components/src/Intl';

import { SubNavigation } from '../../utils/generateLeftNavigationRoutes';
import { IconSubNavProgress } from '../../assets/IconSubNavProgress.svg';

interface SubNavPropTypes extends SubNavigation {
  isActive: boolean;
}

export function SubNav({ path, refId, nameId, isActive }: SubNavPropTypes) {
  return (
    <li className="pa0 ma0" style={{ lineHeight: 0 }} tabIndex={0}>
      <NavLink
        data-testid={nameId}
        to={`${path}?parentId=${refId}`}
        className={cx('black flex items-center-ns pointer', { ['primary-400']: isActive })}
      >
        <span>
          <IconSubNavProgress isActive={isActive} />
        </span>

        <span className="pl1 lh-title">
          <Text id={nameId} />
        </span>
      </NavLink>
    </li>
  );
}
