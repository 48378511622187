import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconLoansElement = React.ElementRef<'svg'>;
interface IconLoansPropTypes extends PrimitiveSvgPropTypes {}

export const IconLoans = React.forwardRef<IconLoansElement, IconLoansPropTypes>(function IconLoans(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.45 13.6399V14.6399C21.45 14.9099 21.24 15.1299 20.96 15.1399H19.5C18.97 15.1399 18.49 14.7499 18.45 14.2299C18.42 13.9199 18.54 13.6299 18.74 13.4299C18.92 13.2399 19.17 13.1399 19.44 13.1399H20.95C21.24 13.1499 21.45 13.3699 21.45 13.6399Z"
        fill="#475569"
      />
      <path
        d="M17.99 12.6899C17.49 13.1799 17.25 13.9099 17.45 14.6699C17.71 15.5999 18.62 16.1899 19.58 16.1899H20.45C21 16.1899 21.45 16.6399 21.45 17.1899V17.3799C21.45 19.4499 19.76 21.1399 17.69 21.1399H6.20995C4.13995 21.1399 2.44995 19.4499 2.44995 17.3799V10.6499C2.44995 9.41989 3.03995 8.32989 3.94995 7.64989C4.57995 7.16989 5.35995 6.88989 6.20995 6.88989H17.69C19.76 6.88989 21.45 8.57989 21.45 10.6499V11.0899C21.45 11.6399 21 12.0899 20.45 12.0899H19.43C18.87 12.0899 18.36 12.3099 17.99 12.6899Z"
        fill="#475569"
      />
      <path
        d="M16.2001 4.82C16.4701 5.09 16.2401 5.51 15.8601 5.51L8.18006 5.5C7.74006 5.5 7.51006 4.96 7.83006 4.65L9.45006 3.02C10.8201 1.66 13.0401 1.66 14.4101 3.02L16.1601 4.79C16.1701 4.8 16.1901 4.81 16.2001 4.82Z"
        fill="#475569"
      />
    </svg>
  );
});

IconLoans.defaultProps = {
  width: '25',
  height: '24'
};
