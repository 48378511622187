import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconExtraActionElement = React.ElementRef<'svg'>;
interface IconExtraActionPropTypes extends PrimitiveSvgPropTypes {}

export const IconExtraAction = React.forwardRef<IconExtraActionElement, IconExtraActionPropTypes>(
  function IconExtraAction({ width, height, ...restProps }, forwardedRef) {
    return (
      <svg
        {...restProps}
        ref={forwardedRef}
        width={width}
        height={height}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.5 2.5V17.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 10L18 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
);

IconExtraAction.defaultProps = {
  width: '21',
  height: '20'
};
