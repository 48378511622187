import * as React from 'react';
import map from 'lodash/fp/map';
import { LoanSubstepProps } from 'GlobalTypes';
import { getCurrentDate, formatHumanDate } from '@kwara/lib/src/dates';
import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { AttributesRow } from '../../../../../components/AttributesRow';
import { Notes } from '../../../../../components/Notes/Notes';
import { useRecommendationsSection } from './useRecommendationsSection';
import styles from './index.module.scss';

const disbursementTypes = ['early_release', 'normal', 'staggered'];

function Recommendations({ formProps, data, SelectField, DatePicker, TextField, addData }: LoanSubstepProps) {
  const { attributesRow1 } = useRecommendationsSection(data, addData);
  return (
    <div className={styles.section}>
      <AttributesRow attributes={attributesRow1} />
      <section>
        <WizardSectionTitle id="LoanAppraisal.Recommendations.LoanRepayment.title" />
        <DatePicker
          labelId="LoanAppraisal.Recommendations.LoanRepayment.firstDateOfRepayment.label"
          disabledDays={{ before: getCurrentDate() }}
          inputPlaceholder={formatHumanDate(getCurrentDate())}
          showInfo={false}
          margin={false}
          name="anticipatedFirstRepaymentDate"
        />
      </section>
      <section>
        <WizardSectionTitle id="LoanAppraisal.Recommendations.Disbursement.title" />
        <div className={styles.disbursement}>
          <TextField
            name="loan.disbursementMode"
            labelId="LoanAppraisal.Recommendations.Disbursement.Method.label"
            disabled
          />

          <DatePicker
            labelId="LoanAppraisal.Recommendations.Disbursement.dateOfDisbursement.label"
            disabledDays={{ before: getCurrentDate() }}
            inputPlaceholder={formatHumanDate(getCurrentDate())}
            showInfo={false}
            margin={false}
            name="anticipatedDisbursementDate"
          />
          <SelectField
            showInfo={false}
            name="disbursementType"
            labelId="LoanAppraisal.Recommendations.DisbursementType.label"
            required
          >
            <SelectField.Option translationId="LoanAppraisal.Recommendations.DisbursementType.Option.choose" value="" />
            {map(
              type => (
                <SelectField.Option
                  translationId={`LoanAppraisal.Recommendations.DisbursementType.Option.${type}`}
                  value={type}
                  key={type}
                />
              ),
              disbursementTypes
            )}
          </SelectField>

          <TextField
            name="recommendedAmount"
            required
            leftGlyph="Currency.orgCurrency"
            labelId="LoanAppraisal.Recommendations.recommendedLoanAmount.label"
            isCurrency
            type="number"
          />
        </div>
      </section>
      <Notes name="notes.recommendations.no_section" className="mt3" formValues={formProps.values} />
    </div>
  );
}

export default {
  Component: Recommendations,
  validate: {
    disbursementType: { isRequired: () => true },
    recommendedAmount: { currency: true, isRequired: () => true }
  }
};
