// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { Documents as DocumentsForm } from './Form';

function DocumentsHeader() {
  return <Text id="MemberDetail.documents" />;
}

function DocumentsBody(props) {
  return <DocumentsForm {...props} stackChildSize="stretch" />;
}

export default {
  Title: DocumentsHeader,
  Body: DocumentsBody
};
