import * as React from 'react';

import { FilterValue } from 'FilterTypes';

import Asset from '@kwara/components/src/Asset';

import { If } from '@kwara/components/src/If/If';
import { TextField } from '@kwara/components/src/Form';
import { Radio } from '@kwara/components/src/Form/Radio';

import { useFilterContext } from '../..';
import { useSearchRadioBaseFilter } from './useSearchRadioBaseFilter';

interface RadioBaseFilterPropTypes extends React.ComponentPropsWithoutRef<'input'> {
  filters: FilterValue[];
  placeholderId?: string;
  withSearch?: boolean;
}

export function RadioBaseFilter({ filters, withSearch, placeholderId, ...restProps }: RadioBaseFilterPropTypes) {
  const { onFilterValue, checkIsFilterValueSelected } = useFilterContext();
  const { searchValue, filterValues, onSearch } = useSearchRadioBaseFilter(filters);

  return (
    <>
      <If
        condition={!!withSearch && placeholderId != undefined}
        do={
          <TextField
            {...restProps}
            placeholderId={placeholderId}
            size="medium"
            className="mb4"
            type="search"
            leftGlyph={Asset.Glyphs.Search}
            value={searchValue}
            onChange={onSearch}
          />
        }
      />

      <ul className="pa0 ma0 list">
        {filterValues.map(filter => {
          const { name, meta, value, belongsTo } = filter;

          return (
            <li key={name} className="pa0">
              <Radio
                name={belongsTo}
                title={name}
                label={meta}
                value={String(value)}
                data-testid={value}
                size="regular"
                checked={checkIsFilterValueSelected(belongsTo, value)}
                onChange={() => onFilterValue({ filterName: belongsTo, selectedValue: filter })}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}
