import { SavingProductType } from '@kwara/models/src';

import Terms from './components/Terms';
import Summary from './components/Summary';
import Product from './components/Product';

import { Member } from './components/Member';
import { Deposit } from './components/Deposit';
import { Remittance } from './components/Remittance';

export const steps = ({
  isV1,
  savingProductsPromise
}: {
  isV1: boolean;
  savingProductsPromise: Promise<SavingProductType>;
}) => ({
  member: {
    titleId: 'SavingAdd.Member.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'product'
      }
    ],
    children: [
      {
        titleId: 'SavingAdd.Member.title',
        Component: Member,
        hideActions: true
      }
    ]
  },
  product: {
    titleId: 'SavingAdd.Product.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'payment'
      }
    ],
    children: [
      {
        titleId: 'SavingAdd.Product.title',
        Component: Product,
        customProps: {
          savingProductsPromise
        },
        hideActions: { next: true }
      }
    ]
  },

  payment: {
    titleId: 'SavingAdd.Payment.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'terms'
      }
    ],
    children: [
      {
        titleId: 'SavingAdd.Deposit.title',
        Component: Deposit,
        validate: Deposit.validate
      },
      isV1
        ? null
        : {
            titleId: 'SavingAdd.Remittance.title',
            subtitleId: 'SavingAdd.Remittance.subtitle',
            Component: Remittance,
            validate: Remittance.validateConfig.savingAdd
          }
    ].filter(Boolean)
  },

  terms: {
    titleId: 'SavingAdd.Terms.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'SavingAdd.Terms.title',
        Component: Terms,
        validate: {
          acceptsTerms: { isRequired: () => true }
        }
      }
    ]
  },
  summary: {
    titleId: 'SavingAdd.Summary.shortTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'SavingAdd.Summary.title',
        subtitleId: 'SavingAdd.Summary.subtitle',
        Component: Summary
      }
    ]
  }
});
