// @flow
import * as React from 'react';
import cx from 'classnames';

import Actionable, { type Location } from '@kwara/components/src/Actionable';
import CardContainer from '@kwara/components/src/CardContainer';

import Button, { ActionButton } from '@kwara/components/src/Button';
import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

type Props = {
  actionLabelId?: TranslationId,
  actionTo?: Location,
  actionType?: ?$Values<typeof ActionButton.Types>,
  border?: boolean,
  className?: string,
  children: React.Node,
  header?: React.Node,
  highlighted?: boolean,
  metadata?: React.Node,
  onAction?: () => void,
  onDelete?: ?(p: ?any) => any,
  size?: 'compact' | 'small',
  isHoverable?: boolean
};

const wrap = (el, classes) => (el ? <div className={classes}>{el}</div> : null);

const wrapAll = (els, classes) => {
  const lastElIndex = React.Children.count(els) - 1;

  return React.Children.map(els, (el, index) => wrap(el, index < lastElIndex ? classes : ''));
};

// This is here so that we don't pass any weird props
// onto the <section> element, causing warnings
const Section = ({ className, children }: { className?: string, children: React.Node }) => (
  <section className={className} children={children} />
);

export const Card = ({
  actionLabelId,
  actionType = 'go',
  border = true,
  children,
  className,
  header,
  highlighted,
  metadata,
  onAction,
  onDelete,
  actionTo,
  size = 'compact',
  isHoverable
}: Props) => {
  const Container = border ? CardContainer : Section;
  const headerSpacing = size === 'compact' ? '' : 'mb4 pb3';
  let action = null;

  const deleteAction = onDelete ? (
    <Actionable onClick={onDelete} className={cx(styles.DeleteButton, 'b--none br3 pointer')}>
      <Text id="Card.Delete" />
    </Actionable>
  ) : null;

  if ((onAction || actionTo) && actionLabelId) {
    action = (
      <Button type="secondary" glyphRightId={actionType} onClick={onAction} to={actionTo}>
        <Text id={actionLabelId} />
      </Button>
    );
  } else if (onAction || actionTo) {
    action = <ActionButton type={actionType} onClick={onAction} to={actionTo} />;
  }

  return (
    <Container className={cx(className)} full isHoverable={isHoverable} highlighted={highlighted}>
      <div className={border ? styles.padded : null}>
        {wrap(metadata, 'kw-text-small grey-400 mb2')}
        {wrap(header, `kw-text-large kw-weight-bold ${headerSpacing} ${styles.title}`)}
        {wrapAll(children, 'bb b--light-grey-400')}

        {action != null && (
          <div className={'mt3 flex justify-between'}>
            {action} {deleteAction}
          </div>
        )}
      </div>
    </Container>
  );
};

export const CardAsLink = ({ actionTo, ...props }: Props) => (
  <Actionable to={actionTo} className="no-underline color-inherit">
    <Card {...props} />
  </Actionable>
);
