// @flow

import * as React from 'react';

import isFunction from 'lodash/fp/isFunction';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { RequestProps } from '@kwara/components/src';

import { Statistic } from '.';

type Props = {
  title: React.ReactNode,
  color?: string | ((data: any) => string),
  info?: React.ReactNode | ((data: any) => React.ReactNode | null),
  size: string,
  r: RequestProps<any>,
  value: React.ReactNode | ((data: any) => React.ReactNode)
};

export function LoadableStatistic({ title, color, size, r, value, info, ...props }: Props) {
  return (
    <Statistic
      title={title}
      color={isFunction(color) ? color(r.data) : color}
      size={size}
      value={
        isFunction(value) ? (
          <Loadable loading={<LoadingSkeleton />} {...r}>
            {data => value(data)}
          </Loadable>
        ) : (
          value
        )
      }
      info={isFunction(info) ? info(r.data) : info}
      {...props}
    />
  );
}
