import * as React from 'react';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src';
import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { CollateralT } from '@kwara/models/src';

const TITLES = [
  { id: 't01', name: <Text id="Collateral.typeOfAsset" />, width: '48%' },
  { id: 't02', name: <Text id="Collateral.amount" />, width: '48%' },
  { id: 't03', name: null, width: '4%' }
];

function validate(data: LoanFormData) {
  const collateralKey = (index: number, field: string) => `collaterals[${index}].${field}`;
  const requiredForCollateralBank = (index: number, allData: LoanFormData) => {
    return get(collateralKey(index, 'assetName'), allData) === 'bank';
  };

  const collaterals = data.collaterals;
  const validations = {};

  if (collaterals != null) {
    collaterals.forEach((c: CollateralT, index: number) => {
      if (c.isMarkedForDestruction) return;

      validations[collateralKey(index, 'assetName')] = { isRequired: () => true };
      validations[collateralKey(index, 'amount')] = { isRequired: () => true, currency: true, nonZero: true };
      validations[collateralKey(index, 'assetBankName')] = {
        isRequired: (_: string, allData: LoanFormData): boolean => requiredForCollateralBank(index, allData)
      };
      validations[collateralKey(index, 'assetBankBranch')] = {
        isRequired: (_: string, allData: LoanFormData): boolean => requiredForCollateralBank(index, allData)
      };
      validations[collateralKey(index, 'assetBankAccount')] = {
        isRequired: (_: string, allData: LoanFormData): boolean => requiredForCollateralBank(index, allData)
      };
    });
  }

  return validations;
}

export { TITLES, validate };
