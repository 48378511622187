import { attr } from 'spraypaint';

import { KeyOf } from 'GlobalTypes';

import Base, { BaseModel } from '@kwara/models/src/models/Base';

import I18n from '../../translations/en.json';

export const SavingsHistory = Base.extend({
  static: {
    jsonapiType: 'calculated_savings_history',
    endpoint: '/savings/calculations/savings_history'
  },
  attrs: {
    savingsHistoryWithStatement: attr()
  }
});

export type SavingsStatement = {
  [month in KeyOf<typeof I18n.Months>]: number;
};

export interface SavingsCalculations {
  mean: number;
  mode: number;
  median: number;
  minimum: number;
  maximum: number;
}

export interface SavingsHistoryT extends BaseModel<SavingsHistoryT> {
  savingsHistoryWithStatement: {
    savings_calculations: {
      last_six_months: SavingsCalculations;
      last_twelve_months: SavingsCalculations;
    };
    savings_statement: SavingsStatement;
  };
}

export interface SavingsHistoryData {
  data: {
    id: string;
    type: string;
    attributes: SavingsHistoryT;
  };
  jsonapi: { version: string };
}
