import { DividendCalculation, IncludesT } from '@kwara/models/src';

import { useOverviewList, fetchWithPagination } from '../base';

async function fetchDividendCalculations(opts) {
  return fetchWithPagination(DividendCalculation, opts);
}

const defaultDividendCalculationIncludes = ['product'];
export const usePaginatedDividendCalculations = (includes: IncludesT = defaultDividendCalculationIncludes) =>
  useOverviewList({ queryKey: 'dividend_calculations', fetchFn: fetchDividendCalculations, includes, select: null });
