import React from 'react';

import { TillT } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import OverviewList from '../../../../components/OverviewList';
import { TillsManagementTable } from '../Table/TillsManagementTable';

import { ROUTES } from '../../../../routes';

type Props = {
  tills: TillT[];
  isLoading: boolean;
  onRowClick: (till: TillT) => void;
};

export function TillsManagementList({ tills = [], isLoading, onRowClick }: Props) {
  return (
    <OverviewList
      actions={[
        <Button key="back" to={ROUTES.tellers}>
          <Text id="TillsManagement.List.button.back" />
        </Button>
      ]}
      headerId="TillsManagement.List.header"
      labelId="TillsManagement.List.label"
      items={tills}
      table={<TillsManagementTable tills={tills} isLoading={isLoading} onRowClick={onRowClick} />}
      totalNumResults={tills.length}
    />
  );
}
