import React from 'react';

import { UserT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { Link } from '@kwara/components/src/Link';

import { DetailWrapper, Detail } from '..';

export function MFA({ user }: { user: UserT }) {
  const link = (
    <Link to="/auth/2fa">
      <Text id="Settings.Personal.MFA.link" /> &#8599;
    </Link>
  );
  const padlockEmoji = (
    <span className="kw-text-2x-large" role="img" aria-label="MFA Activated">
      &#x1F512;
    </span>
  );
  const val = (
    <span>
      <If
        condition={user.mfaActivated}
        do={
          <span>
            <span>
              <Text id="Settings.Personal.MFA.activated" />
            </span>
            {padlockEmoji}
          </span>
        }
        else={<Text id="Settings.Personal.MFA.notActivated" values={{ link }} />}
      />
    </span>
  );

  return (
    <DetailWrapper>
      <Detail titleId="Settings.Personal.MFA.title" value={val} />
    </DetailWrapper>
  );
}
