import { GuarantorType } from '@kwara/models/src';
import { BASES as SETUP_BASES, TITLES, getTableTotals } from '../../GuarantorImp';

type Props = {
  guarantors?: GuarantorType[];
};

export function useGuarantorsLister({ guarantors = [] }: Props) {
  const validGuarantors = guarantors.filter(guarantor => !guarantor.isMarkedForDestruction);
  const { mainSavings: totalMainSavings, amountToGuarantee: totalAmountToGuarantee } = getTableTotals(validGuarantors);

  const BASES = SETUP_BASES(totalMainSavings, totalAmountToGuarantee);
  return { TITLES, BASES };
}
