import React from 'react';
import { useHistory } from 'react-router-dom';

import { Text } from '@kwara/components/src/Intl';

import { ActionModal } from '../../../../../components/ActionModal';
import MemberSearch from '../../../../../components/MemberSearch';
import { memberPath } from '../../../../../lib/urls';
import { useTillUIContext } from '../../..';

type Props = {
  headerId: string;
  titleId: string;
};

export const SearchModal = ({ headerId, titleId }: Props) => {
  const history = useHistory();
  const { updateModal } = useTillUIContext();

  return (
    <ActionModal titleId={titleId} hideConfirm={true} onCancel={() => updateModal({ activeModal: null })}>
      <div className="mt6 mb5">
        <span className="kw-text-4x-large kw-weight-bold">
          <Text id={headerId} />
        </span>
      </div>
      <MemberSearch
        onSelect={member => {
          history.push(memberPath({ id: member.id }));
          updateModal({ activeModal: null });
        }}
      />
    </ActionModal>
  );
};
