// @flow
import * as React from 'react';

import { useMemberLoans, useMemberSavings } from '@kwara/models/src/models/request/hooks';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import { ApprovalInfo } from './ApprovalInfo';
import { LoanInfo } from './LoanInfo';
import { MemberInfo } from './MemberInfo';
import { TransactionsInfo } from './TransactionsInfo';

export type { SubStepComponentProps };

type TitleProps = SubStepComponentProps<{}> & {
  name: React.Node,
  id?: string
};

export const Title = ({ StackChild, name, id }: TitleProps) => (
  <StackChild size="widest">
    <div className={'pt4 pb2 kw-text-3x-large kw-weight-bold'}>
      {name} {!id ? '' : `- ${id}`}
    </div>
  </StackChild>
);

export default ({ StackChild, data }: SubStepComponentProps<{}>) => {
  const loanIncludes = ['product', 'transactions', 'loan_application'];
  const savingIncludes = ['product'];

  const memberLoansR = useMemberLoans(data.loan.member.id, loanIncludes);
  const memberSavingsR = useMemberSavings(data.loan.member.id, savingIncludes);

  return (
    <>
      <ApprovalInfo StackChild={StackChild} data={data} />
      <LoanInfo StackChild={StackChild} data={data} />
      <MemberInfo StackChild={StackChild} data={data} memberLoansR={memberLoansR} memberSavingsR={memberSavingsR} />
      <TransactionsInfo StackChild={StackChild} memberLoansR={memberLoansR} memberSavingsR={memberSavingsR} />
    </>
  );
};
