import React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import head from 'lodash/fp/head';
import size from 'lodash/fp/size';
import isEmpty from 'lodash/fp/isEmpty';

import { If } from '@kwara/components/src/If/If';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';
import { MemberType } from '@kwara/models/src';

import style from './index.module.scss';
import Income from '../Income/Income';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import { getMissingAttachments } from '../../../utils';
import { store } from '../../../../../../../models/Store/Store';

function Warn({ when }: { when: string | null }) {
  return (
    <If
      condition={when && !isEmpty(when)}
      do={
        <span className={cx('red-500', style.ErrorMsg)}>
          <Text id="LoanAdd.MemberProfile.SectionIncomplete" />
        </span>
      }
    />
  );
}

function Header(props: { when: string | null; children: React.ReactNode }) {
  return (
    <>
      <header className="flex">{props.children}</header>
      <Warn when={props.when} />
    </>
  );
}

function JoinWithSeparator({ sep = 'or', items }: { sep?: 'or' | 'and'; items: React.ReactNode[] }) {
  const length = size(items);

  return mapIndexed((item, index) => {
    const itsNotLastIndex = index + 1 < length;

    return (
      <span key={index.toString()}>
        <If
          condition={itsNotLastIndex}
          do={
            <>
              {item} <Text id={`General.Separators.${sep}`} />{' '}
            </>
          }
          else={item}
        />
      </span>
    );
  }, items);
}

function MissingDocumentsWarning(props: { member: MemberType }) {
  const missingAttachments = getMissingAttachments(props.member);

  return (
    <If
      condition={!isEmpty(missingAttachments)}
      do={
        <div className={cx('red-500 mt3', style.ErrorMsg)}>
          <Text id="LoanAdd.MemberProfile.MissingDocuments.warning" />
          <ul>
            {map(
              entry => (
                <li key={head(entry)}>
                  <JoinWithSeparator
                    items={map(
                      fileName => (
                        <Text id={`DocumentUploads.${fileName}.label`} />
                      ),
                      entry
                    )}
                  />
                </li>
              ),
              missingAttachments
            )}
          </ul>
        </div>
      }
    />
  );
}

function validateMember(member: MemberType, isV1: boolean) {
  if (!member) {
    return {};
  }

  const shouldDisableValidation = !store.fullMemberDetailsRequiredForLoanApplication;

  if (shouldDisableValidation) {
    return {};
  }

  return {
    ...PersonalDetails.validate,
    ...Income.validate,
    bank: {},
    documents: { isRequired: () => (isV1 ? false : size(getMissingAttachments(member)) > 0) },
    income: {}
  };
}

export { Header, MissingDocumentsWarning, validateMember };
