import * as React from 'react';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import isEqual from 'lodash/fp/isEqual';
import some from 'lodash/fp/some';

import { MemberType } from '@kwara/models/src';

import styles from './index.module.scss';
import MemberSearch from '../../../MemberSearch';

function alreadyAdded(data, chosenMember) {
  const sameId = pipe(get('member.id'), isEqual(chosenMember.id));

  return pipe(get('guarantors'), some(sameId))(data);
}

type GuarantorSearchPropTypes = {
  data: any;
  onFetchStart?: Function;
  onFetchEnd?: Function;
  onSelect: (member: MemberType) => Promise<void>;
};

export function GuarantorSearch({ onSelect, data, onFetchStart, onFetchEnd }: GuarantorSearchPropTypes) {
  return (
    <MemberSearch
      returnFullMember
      floatResults
      onSelect={onSelect}
      searchBarSize="stretch"
      resultType="nonFinancial"
      data-cy="guarantor-search"
      onFetchEnd={onFetchEnd}
      onFetchStart={onFetchStart}
      resultListClassName={styles['result-list']}
      isItemDisabled={member => alreadyAdded(data ?? {}, member)}
      labelProps={{ id: 'LoanAdd.Security.Guarantor.searchTitle' }}
      selectedAttributes={['id', 'first_name', 'middle_name', 'last_name', 'total_self_guaranteed']}
    />
  );
}
