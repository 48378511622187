import React from 'react';

import Modal from '@kwara/components/src/Modal';
import Button from '@kwara/components/src/Button';

import { ModelErrors } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import { SubscribedTextArea as NoteTextarea } from '@kwara/components/src/Wizard/deprecated/FormFields';

import { Event } from '../../hooks/useActions';
import { Panel } from '../../../../../../components/ActionModal';

enum BUTTON_TYPE {
  reject = 'destructive',
  approve = 'primary'
}

export type ConfirmModalPropTypes = {
  isOpen: boolean;
  event: Event;
  error?: ModelErrors;
  loansCount?: number;
  isSubmitting: boolean;
  onClose(): void;
  onSubmit(): Promise<Object>;
};

export function ConfirmModal({
  isOpen,
  isSubmitting,
  error,
  event,
  loansCount,
  onClose,
  onSubmit
}: ConfirmModalPropTypes) {
  const count = loansCount || 'all';

  return (
    <Modal className="w-100 h-100" isOpen={isOpen} onCancel={onClose}>
      <ModelErrorBanner className="mv3" errors={error} />
      <Panel>
        <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
          <Text id={`Finance.LoanDisbursement.Confirm.${event}.title`} values={{ count }} />
        </h2>
        <p className="kw-weight-light mb3">
          <Text id={`Finance.LoanDisbursement.Confirm.${event}.subTitle`} values={{ count }} />
        </p>
        <NoteTextarea
          name="notes"
          labelId="Finance.LoanDisbursement.Confirm.noteLabel"
          placeholderId="Finance.LoanDisbursement.Confirm.notePlaceholder"
        />
        <ButtonBar
          className="mt4"
          left={
            <Button onClick={onClose} disabled={isSubmitting}>
              <Text id="Finance.LoanDisbursement.Confirm.cancelButtonText" />
            </Button>
          }
          right={
            <Button
              classNames="mr3"
              onClick={onSubmit}
              disabled={isSubmitting}
              type={BUTTON_TYPE[event]}
              glyphRightId={isSubmitting && Button.Glyphs.Spinner}
            >
              <Text id={`Finance.LoanDisbursement.Confirm.${event}ButtonText`} values={{ count }} />
            </Button>
          }
        />
      </Panel>
    </Modal>
  );
}
