import * as React from 'react';

import { NotFound404 } from '@kwara/components/src';
import { If } from '@kwara/components/src/If/If';

type ErrorBoundaryPropTypes = {
  children: React.ReactNode;
  fullPath: string;
};

type StateTypes = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryPropTypes, StateTypes> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  componentDidUpdate(prev: ErrorBoundaryPropTypes) {
    // Make sure changing page will hide the error and retry rendering
    if (prev.fullPath !== this.props.fullPath) {
      this.setState({ hasError: false });
    }
  }

  render() {
    return <If condition={this.state.hasError} do={<NotFound404 />} else={this.props.children} />;
  }
}
