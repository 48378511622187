// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoanProducts } from '@kwara/models/src/models/request/hooks';


import { LoanProductTable } from './LoanProductsTable';
import { Header } from '../Header';
import { BodyContentWrapper } from '../../../../layouts';


export const LoanProducts = () => {
  const r = useLoanProducts();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <Header titleId="Settings.Loans.titleId" subtitleId="Settings.Loans.subtitleId" />
          <Loadable {...r}>{products => <LoanProductTable products={products} />}</Loadable>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
