import React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text, Currency } from '@kwara/components/src/Intl';

type FloatBalanceBreakdownPropTypes = {
  shown: boolean;
  floatBalance: number;
  phoneNumberProvider: string;
};

export function FloatBalanceBreakdown({ shown, phoneNumberProvider, floatBalance }: FloatBalanceBreakdownPropTypes) {
  return (
    <If
      condition={shown}
      do={
        <div className="kw-weight-bold">
          <p className="mb0">
            <Text id="LoanDisbursal.FloatBalanceBreakdown.availableBalance" />
          </p>
          <p className="ma0">
            <span className="dib pr2">{phoneNumberProvider}</span>
            <Currency format="currency" value={floatBalance} />
          </p>
        </div>
      }
    />
  );
}
