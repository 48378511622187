import React from 'react';

import { FilterValue } from 'FilterTypes';

import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import Member from '@kwara/models/src/models/Member';

import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { DataViewWrapper, ContentProps } from '../../components/DataViewWrapper';
import { Indicators } from './components/Indicators/Indicators';
import { BodyContentWrapper } from '../../layouts';
import { MemberList } from './components/MemberList';

import { ListPageProps } from '..';

type Props = ListPageProps & ContentProps;

function Content({
  hasMore,
  loading,
  errors,
  data,
  pristine,
  onLoadMoreData,
  totalNumberResults,
  onFilterChange,
  resetData,
  isV1
}: Props) {
  const { AppPermissions, permission } = usePermissions();

  return (
    <BodyContentWrapper.Root headId="MemberList.title">
      {permission.to(AppPermissions.ViewMemberMetrics) ? (
        <BodyContentWrapper.Row.Root>
          <BodyContentWrapper.Row.Indicators>
            <Indicators />
          </BodyContentWrapper.Row.Indicators>
        </BodyContentWrapper.Row.Root>
      ) : null}

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <DeprecatedLoadable
            loader={pristine ? new Promise(() => {}) : Promise.resolve(data)}
            loaded={members => (
              <MemberList
                members={members}
                onLoadMoreData={onLoadMoreData}
                hasMore={hasMore}
                resetData={resetData}
                onFilterChange={(currentlySelectedFilter: FilterValue | null) => {
                  onFilterChange(currentlySelectedFilter?.value);
                }}
                loading={loading}
                errors={errors}
                totalNumResults={totalNumberResults}
                rowsAsLink={permission.to(AppPermissions.ShowMember)}
                withFilter={permission.to(AppPermissions.ViewMembers)}
                isV1={isV1}
              />
            )}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function Members({ location, isV1 }) {
  const { AppPermissions, permission } = usePermissions();
  const isPermitted = permission.to(AppPermissions.ViewMembers);

  if (isPermitted) {
    return (
      <DataViewWrapper
        component={props => <Content {...props} isV1={isV1} />}
        scope={Member.includes('id_documents')}
        pathname="/"
        location={location}
      />
    );
  }

  return <Content isV1={isV1} pristine={false} hasMore={false} data={[]} totalNumberResults={0} />;
}
