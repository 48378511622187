import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { AuthMfaVerifyForm } from '../../../components/Auth/VerifyMFA/Form';

export function ConfirmQr({ onSuccess, onBack }: { onSuccess: Function; onBack: Function }) {
  return (
    <>
      <h1 className="kw-text-2x-large kw-weight-bold mb2">
        <Text id="Activate2FA.ConfirmQR.title" />
      </h1>
      <p className="grey-400">
        <Text id="Activate2FA.ConfirmQR.subtitle" />
      </p>
      <AuthMfaVerifyForm onVerify={onSuccess} onBack={onBack} />
    </>
  );
}
