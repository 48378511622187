import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';
import { SavingProductType } from '@kwara/models/src';

import { yesterday } from '@kwara/lib/src/dates';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedDatePicker, SubscribedCombobox } from '@kwara/components/src/Form';

type Payload = { filters: {}; toDate: Date };

type Props = {
  onGenerate(data: Payload): void;
  products: SavingProductType[];
  isLoadingProducts: boolean;
};

const rules = {
  toDate: {
    isRequired: () => true
  }
};

export function Form({ onGenerate, products, isLoadingProducts }: Props) {
  const comboboxData = products.map(product => ({ value: product.encodedKey, label: product.name }));

  return (
    <ReactFinalForm
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      validate={createValidator(rules)}
      render={({ form, handleSubmit, valid, submitting }) => {
        return (
          <form
            className="w-100 flex justify-left items-center"
            onSubmit={handleSubmit}
            aria-label="Create Deposit Listing Report Form"
          >
            <div className="relative dib mr3">
              <SubscribedCombobox
                compact
                margin={false}
                size="medium"
                placeholderId="DepositListingReports.Form.products.option.default"
                name="filters.savings_product_key"
                data={comboboxData}
              />
            </div>
            <div className="relative dib mr3">
              <SubscribedDatePicker
                compact
                margin={false}
                showInfo={false}
                required
                name="toDate"
                leftGlyph="DepositListingReports.Form.toDate.label"
                disabledDays={{ after: yesterday() }}
              />
            </div>

            <Button disabled={!valid || submitting || isLoadingProducts} type="primary" onClick={form.submit}>
              <Text id="DepositListingReports.Form.submit.generate" />
            </Button>
          </form>
        );
      }}
    ></ReactFinalForm>
  );
}
