//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Confirm = ({ StackChild, TextArea }: SubStepComponentProps<>) => {
  return (
    <StackChild>
      <TextArea name="notes" required labelId="SavingClose.Confirm.Note.label" infoId="SavingClose.Confirm.Note.info" />
    </StackChild>
  );
};

Confirm.validate = {
  notes: {
    isRequired: () => true
  }
};
