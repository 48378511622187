// @flow

import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { Text, Percent } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Card } from '@kwara/components/src/Card';
import { getAttachmentsSizeByContexts } from '@kwara/components/src/DocumentListBase';

import { Currency } from '../../../../../components/Currency';
import { DocumentList } from '../../../../../components/DocumentList';
import styles from '../../index.module.scss';

const CardTitle = ({ loan }) => {
  const securitiesSum = loan.sumOfSecurities();
  const percentage = securitiesSum / loan.amount;

  return (
    <div>
      <div className={cx(styles.container, 'pv2')}>
        <div className={cx(styles.a, 'kw-text-large kw-weight-bold')}>
          <Text id="LoanApprove.Overview.security" />
        </div>
        <div className={cx(styles.b, 'kw-text-x-small grey-400')}>
          <Text id="LoanApprove.Overview.security.amountSecured" /> <Percent value={percentage} />
        </div>
        <div className={cx(styles.c, 'kw-text-large kw-weight-bold')}>
          <Currency format="currency" value={securitiesSum} />
        </div>
        <div className={cx(styles.d, 'kw-text-x-small grey-400')}>
          <Text id="LoanApprove.Overview.security.totalBalance" />
        </div>
      </div>
    </div>
  );
};

// @TODO will be handle in a separate story
// const OwnGuarantorCardItem = ({ member }) => (
//   <div>
//     <div className={cx(styles.container, 'pv2')}>
//       <div className={cx(styles.a, 'kw-text-small-design-system')}>
//         <Text id="LoanApprove.Overview.security.SecuritiesCard.guarantors.own" />
//       </div>
//       <div className={cx(styles.b, 'kw-text-2x-small grey-400')}>
//         {member.fullName()} - {member.id}
//       </div>
//     </div>
//     <StatisticRow widthClass="w-50" compact={true}>
//       <Statistic
//         compact
//         size="small"
//         title={<Text id="Total Savings" />}
//         value={<Currency format="currency" value={member.totalSavings} />}
//         // @TODO confirm value
//       />
//     </StatisticRow>
//   </div>
// );

const GuarantorCardItem = ({ member, guarantor }) => (
  <div>
    <div className={cx(styles.container, 'pv2')}>
      <div className={cx(styles.a, 'kw-text-small-design-system')}>{member.fullName()}</div>
      <div className={cx(styles.b, 'kw-text-2x-small grey-400')}>{member.id}</div>
    </div>
    <StatisticRow widthClass="w-50" compact={true}>
      <Statistic
        compact
        size="small"
        title={<Text id="LoanApprove.Overview.security.SecuritiesCard.guaranteeAmount" />}
        value={<Currency format="currency" value={guarantor.amount} />}
      />
    </StatisticRow>
  </div>
);

const CollateralCardItem = ({ collateral }) => (
  <StatisticRow widthClass="w-50" compact={true}>
    <Statistic
      compact
      size="small"
      title={<Text id={`LoanAdd.Security.Collateral.Type.${get('assetName', collateral)}`} />}
      value={<Currency format="currency" value={get('amount', collateral)} />}
    />
  </StatisticRow>
);

const SectionHeader = ({ title, subtitle }) => (
  <div className={'pv2 flex flex-column'}>
    <div className={cx(styles.a, 'kw-text-large kw-weight-bold')}>{title}</div>
    <div className={cx(styles.b, 'kw-text-2x-small grey-400')}>{subtitle}</div>
  </div>
);

export const SecuritiesCard = ({ loan, className }) => {
  const attachments = get('member.attachments', loan);
  return (
    <Card border={true} className={className}>
      <>
        <CardTitle loan={loan} />
        {/* Guarantors */}
        <SectionHeader
          title={<Text id="LoanApprove.Overview.security.SecuritiesCard.guarantors" />}
          subtitle={
            <Text
              id="LoanApprove.Overview.security.SecuritiesCard.totalGuarantors"
              values={{ value: size(loan.guarantees) }}
            />
          }
        />
      </>

      {map(
        guarantor => (
          <GuarantorCardItem key={guarantor.id} member={get('member', guarantor)} guarantor={guarantor} />
        ),
        loan.guarantees
      )}
      {/* Collaterals */}
      <SectionHeader
        title={<Text id="LoanApprove.Overview.security.SecuritiesCard.collaterals" />}
        subtitle={
          <Text
            id="LoanApprove.Overview.security.SecuritiesCard.totalCollaterals"
            values={{ value: size(loan.collaterals) }}
          />
        }
      />
      {map(
        collateral => (
          <CollateralCardItem key={collateral.id} collateral={collateral} />
        ),
        loan.collaterals
      )}
      {/* Documents */}
      <SectionHeader
        title={<Text id="LoanApprove.Overview.documents" />}
        subtitle={
          <Text
            id="LoanApprove.Overview.security.SecuritiesCard.totalDocuments"
            values={{
              value: getAttachmentsSizeByContexts(['members', 'loans'], attachments)
            }}
          />
        }
      />

      <div className="pt2">
        <DocumentList contexts={['members', 'loans']} member={get('member', loan)} attachments={attachments} />
      </div>
    </Card>
  );
};
