import { useCallback, useMemo, useState } from 'react';
import { addDays } from 'date-fns';

import { formatIsoDate } from '@kwara/lib/src/dates';

import { useFilterContext } from '../..';
import { SuggestDates } from '../../utils/suggestDates';
import { SELECTION_TYPES, FILTER_SELECTION_VERBS } from '../../utils/filterTypes';

export type SuggestedDate = { name: string; from: string; to: string };

export enum DATE_NAMES {
  FROM = 'From',
  TO = 'To'
}

export function useDateBaseFilter(filterType: string) {
  const {
    selectedFilters,
    hasSelectedFilters,
    onFilterValue,
    getValueFor,
    setSelectedFilters,
    setCurrentlySelectedFilter
  } = useFilterContext();
  const currentFilters = useMemo(() => (hasSelectedFilters && selectedFilters[filterType]) || [], [
    filterType,
    hasSelectedFilters,
    selectedFilters
  ]);
  const [suggestedDateName, setSuggestedDateName] = useState('');
  const [isCustom, setIsCustom] = useState(true);

  const onDateChangeHandler = useCallback(
    (name: string) => {
      return function onChange(value: Date) {
        setIsCustom(true);

        if (value) {
          onFilterValue({
            filterName: filterType,
            selectionType: SELECTION_TYPES.MULTI,
            selectedValue: { name, value: formatIsoDate(value), belongsTo: filterType, renderWithValue: true },
            verb: FILTER_SELECTION_VERBS.UPDATE
          });
        }
      };
    },
    [filterType, onFilterValue]
  );

  const onSuggestedDateChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLOptionElement>) => {
      const { name, from, to }: SuggestedDate = JSON.parse(e.target.value);

      setSuggestedDateName(name);
      setIsCustom(false);
      setSelectedFilters(prev => ({
        ...prev,
        [filterType]:
          name === SuggestDates.DATES.ALL_TIME.name
            ? [{ name: DATE_NAMES.FROM, value: from, belongsTo: filterType, renderWithValue: true }]
            : [
                { name: DATE_NAMES.FROM, value: from, belongsTo: filterType, renderWithValue: true },
                { name: DATE_NAMES.TO, value: to, belongsTo: filterType, renderWithValue: true }
              ]
      }));
      setCurrentlySelectedFilter({ name: DATE_NAMES.TO, value: to, belongsTo: filterType, renderWithValue: true });
    },
    [filterType, setCurrentlySelectedFilter, setSelectedFilters]
  );

  const fromValue = getValueFor(filterType, DATE_NAMES.FROM) as string;
  const toValue = getValueFor(filterType, DATE_NAMES.TO) as string;

  const checkIsDisabled = useCallback(
    (date: SuggestedDate) => {
      const isSameFrom = !!currentFilters.find(f => f.value === date.from && f.name === DATE_NAMES.FROM);
      const isSameTo = !!currentFilters.find(f => f.value === date.to && f.name === DATE_NAMES.TO);
      const disabled = (isSameFrom && isSameTo) || date.name === SuggestDates.DATES.CUSTOM_DATE.name;

      return disabled;
    },
    [currentFilters]
  );

  const formattedDates = {
    from: isNaN(Date.parse(fromValue)) ? '' : fromValue,
    to: isNaN(Date.parse(toValue)) ? '' : toValue,
    select: JSON.stringify(
      isCustom ? SuggestDates.DATES.CUSTOM_DATE : { name: suggestedDateName, from: fromValue, to: toValue }
    )
  };

  const toDisabledBeforeDays = !!formattedDates.from ? addDays(formattedDates.from, 1) : null;

  return {
    toDisabledBeforeDays,
    formattedDates,
    onDateChangeHandler,
    onSuggestedDateChangeHandler,
    checkIsDisabled
  };
}
