import * as React from 'react';

import { MemberType } from '@kwara/models/src';
import { Date, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { capitalize } from '@kwara/models/src/models/Member';

import { Grid } from '../../Grid';
import { Name } from '../../../pages/MemberAdd/components/Name';
import { Personal } from '../../../pages/MemberAdd/components/Personal';
import { Address } from '../../../pages/MemberAdd/components/Address';
import { Contact } from '../../../pages/MemberAdd/components/Contact';
import { EditableSection, EditableConfig } from '../../EditableSection/v1/EditableSection';

const base = {
  Component: props => {
    return (
      <>
        <Name {...props} shownIdInput={false} />
        <Personal {...props} />
        <Contact {...props} />
        <Address {...props} />
      </>
    );
  },
  validate: {
    ...Name.validate,
    ...Personal.validate
  }
};
const memberDetail = base;
const loanAdd = {
  ...base,
  validate: {
    ...base.validate,
    ...Address.validateConfig.loanAdd
  }
};

const fullName = (member: MemberType) => {
  if (!member) {
    return null;
  }

  if (member.title) {
    return `${member.title}. ${member.fullName()}`;
  }

  return member.fullName();
};

export function PersonalDetails({ config = memberDetail, member, readOnly, isV1 }: EditableConfig<MemberType>) {
  return (
    <EditableSection
      onSave={async (m: MemberType) => {
        return m.save({ with: ['addresses'] }).finally(() => saveAttachments(m, m.attachments));
      }}
      config={config}
      initialData={member}
      readOnly={readOnly}
    >
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.fullName" />}
          value={fullName(member)}
          size={'medium'}
        />
        <ProfilePhoto memberId={member.id} attachments={member.attachments} size="regular" isExpandable />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.gender" />}
          value={member.gender ? <Text id={`MemberDetail.PersonalDetails.gender.${member.gender}`} /> : null}
          size={'medium'}
        />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.dateOfBirth" />}
          value={<Date value={member.dateOfBirth} />}
          size={'medium'}
        />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.maritalStatus" />}
          value={
            member.maritalStatus ? (
              <Text id={`MemberDetail.PersonalDetails.maritalStatus.${member.maritalStatus}`} />
            ) : null
          }
          size={'medium'}
        />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.phone" />}
          value={member.phoneNumber}
          size={'medium'}
        />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.secondaryPhone" />}
          value={member.secondaryPhoneNumber}
          size={'medium'}
        />
        <Statistic title={<Text id="MemberDetail.PersonalDetails.email" />} value={member.email} size={'medium'} />
        {isV1 ? null : (
          <Statistic
            title={<Text id="MemberDetail.PersonalDetails.source" />}
            value={member.source ? capitalize(member.source) : '-'}
            size={'medium'}
          />
        )}
      </Grid>
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.postalAddress" />}
          value={member.address('postal')}
          size={'medium'}
        />
        <Statistic
          title={<Text id="MemberDetail.PersonalDetails.physicalAddress" />}
          value={member.address('physical')}
          size={'medium'}
        />
      </Grid>
    </EditableSection>
  );
}

PersonalDetails.Title = () => <Text id="MemberDetail.personalDetails" />;
PersonalDetails.editConfig = { memberDetail, loanAdd };
