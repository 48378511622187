import React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { LoanSubstepProps } from 'LoanSharedTypes';

import { appName } from '@kwara/lib/src/utils';
import { If } from '@kwara/components/src/If/If';
import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { fields as remittanceFields } from '@kwara/models/src/models/Remittance';
import { SubscribedPaymentSelectField, CollectingBank } from '@kwara/components/src/Form';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';

import { SubscribedFrequencySelectFields, SubscribedDatePicker } from '../../../../../../components/Form';

const fields = remittanceFields.remittance;
const showBankAccountSelectionFor = [TransactionChannels.directDebit, TransactionChannels.bankTransfer];

export const RepaymentMethod = ({ Condition, TextField, addData, data }: LoanSubstepProps) => {
  const { member } = data;
  const transactionContext = appName.isSacco ? contexts.LoanRemittance : contexts.LoanRemittance_Connect;

  return (
    <>
      <SubscribedPaymentSelectField
        required
        name={fields.mode}
        addData={addData}
        labelId="LoanAdd.Configure.RepaymentMode.label"
        config={{
          bank: fields.bank,
          bankLabelId: 'LoanAdd.Configure.DirectDebitBank.label',
          bankBranch: fields.branch,
          accountNumber: fields.account,
          memberBankAccountId: fields.bankAccount,
          methods: getTransactionTypes(transactionContext).values,
          showBankAccountSelectionFor,
          showBanksFieldsFor: [],
          memberId: member.id
        }}
      />

      <Condition when={fields.mode} is={TransactionChannels.directDebit}>
        <If condition={appName.isSacco} do={<CollectingBank fields={fields} />} />
        <SubscribedDatePicker required name={fields.startDate} labelId="LoanAdd.Repayment.RemittanceStartDate.label" />
      </Condition>

      <Condition when={fields.mode} is={TransactionChannels.payrollDeduction}>
        <TextField name="member.employer" labelId="LoanAdd.Configure.RepaymentMode.Employer.title" required />
        <TextField name="member.staffId" labelId="LoanAdd.Configure.RepaymentMode.StaffId.title" required />
      </Condition>
    </>
  );
};

const requiredForBankFields = {
  isRequired: (_: string, allData: LoanFormData): boolean =>
    includes(get(fields.mode, allData), showBankAccountSelectionFor)
};

const requiredForDirectDebitSacco = {
  isRequired: (_: string, allData: LoanFormData): boolean =>
    appName.isSacco && get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForDirectDebit = {
  isRequired: (_: string, allData: LoanFormData): boolean =>
    get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForPayrollDeduction = {
  isRequired: (_: string, allData: LoanFormData): boolean =>
    get(fields.mode, allData) === TransactionChannels.payrollDeduction
};

RepaymentMethod.validateConfig = {
  'member.employer': requiredForPayrollDeduction,
  'member.staffId': requiredForPayrollDeduction,
  [fields.mode]: { isRequired: () => true },
  [fields.amount]: { currency: true },
  'remittance.bankAccount.id': requiredForBankFields,
  [fields.collectingBank]: requiredForDirectDebitSacco,
  [fields.startDate]: requiredForDirectDebit,
  ...SubscribedFrequencySelectFields.validate(fields.frequency, fields.collectionDate)
};
