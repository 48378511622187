//@flow

import * as React from 'react';

import { type SubStepComponentProps, type StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { getName } from '../utils';

interface AddressPropTypes extends SubStepComponentProps<> {
  size?: StackChildSize;
}

export const Address = ({ size, StackChild, TextField, prefix }: AddressPropTypes) => {
  const nameFor = name => getName(prefix, name);

  return (
    <StackChild size={size}>
      <TextField
        name={nameFor('addresses[0].postalAddress')}
        labelId="AddMember.Address.Postal.label"
        placeholderId="AddMember.Address.Postal.placeholder"
        infoId="AddMember.Address.Postal.info"
      />
      <TextField
        name={nameFor('addresses[0].physicalAddress')}
        labelId="AddMember.Address.Physical.label"
        placeholderId="AddMember.Address.Physical.placeholder"
        infoId="AddMember.Address.Physical.info"
      />
    </StackChild>
  );
};

const loanAdd = {
  'addresses[0].postalAddress': {
    isRequired: () => true
  },
  'addresses[0].physicalAddress': {
    isRequired: () => true
  }
};

export const validateConfig = {
  memberAdd: {},
  memberDetail: {},
  loanAdd
};

Address.validateConfig = validateConfig;
