// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';
import some from 'lodash/fp/some';
import size from 'lodash/fp/size';

import { type BatchTransactionT } from '@kwara/models/src/models/BatchTransaction';
import { filterMapping, TransactionUIStates } from '@kwara/models/src/models/Transaction';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { useDeprecatedFilter, useDeprecatedPagination, useBatchTransactions, usePermissions } from '../../../hooks';
import { useThrottledCallback } from '../../../hooks';

import OverviewList from '../../../components/OverviewList';
import { BatchTransactionsTable } from './BatchTransactionsTable';

type Props = {
  onApprove: () => void,
  onApproveAll: (n: number) => void,
  onReject: () => void,
  onRejectAll: (n: number) => void,
  numberSelected: number,
  batchId: string,
  status: string
};

const batchTransactionFilters = [
  { value: '', translationId: '' },
  ...map(
    value => ({
      value: filterMapping[value],
      translationId: `BatchTransactionsList.filter.${value}`
    }),
    TransactionUIStates
  )
];

const includes = ['import_review_set.user'];
export const BatchTransactionsList = ({
  onApprove,
  onApproveAll,
  onReject,
  onRejectAll,
  numberSelected,
  batchId,
  status,
  ...rest
}: Props) => {
  const { permission, AppPermissions } = usePermissions();
  const { filterValue, onFilterChange } = useDeprecatedFilter(filter => ({
    state: filter
  }));
  const { onNext, allData, hasMore, totalResults, isLoading, ...r } = useDeprecatedPagination<BatchTransactionT>(
    useBatchTransactions,
    page => [batchId, includes, filterValue, page],
    filterValue
  );

  const conditionallyRefetch = () => {
    if (some(t => t.isProcessing(), allData)) {
      r.refetch();
    }
  };

  useThrottledCallback(conditionallyRefetch);

  const hasPendingTransactions = size(allData) > 0 && (status === 'in_review' || status === 'pending_review');

  const approve = (
    <Button key="approve" onClick={onApprove} type="primary">
      <Text id="BatchTransactionsApproval.action.approve" values={{ val: numberSelected }} />
    </Button>
  );
  const reject = (
    <Button key="reject" onClick={onReject} type="destructive">
      <Text id="BatchTransactionsApproval.action.reject" values={{ val: numberSelected }} />
    </Button>
  );
  const approveAll =
    // only show this button when the batch still has pending approvals
    hasPendingTransactions ? (
      <Button key="approveAll" onClick={() => onApproveAll(totalResults)} type="primary">
        <Text id="BatchTransactionsApproval.action.approveAll" values={{ totalResults }} />
      </Button>
    ) : null;

  const rejectAll =
    // only show this button when the batch still has pending approvals
    hasPendingTransactions ? (
      <Button key="rejectAll" onClick={() => onRejectAll(totalResults)} type="destructive">
        <Text id="BatchTransactionsApproval.action.rejectAll" values={{ totalResults }} />
      </Button>
    ) : null;

  const actions = numberSelected ? [approve, reject] : [approveAll, rejectAll];

  return (
    <OverviewList
      headerId="BatchTransactionsList.header"
      labelId="BatchTransactionsList.label"
      totalNumResults={totalResults}
      items={allData}
      actions={compact(permission.to(AppPermissions.ReviewBatchTransactionImports) ? actions : [])}
      filterBy={filterValue.state}
      filters={batchTransactionFilters}
      filterDisabled={isLoading}
      onFilterChange={onFilterChange}
      table={
        <BatchTransactionsTable transactions={allData} hasMore={hasMore} onNext={onNext} isLoading={isLoading} {...r} />
      }
      {...rest}
    />
  );
};
