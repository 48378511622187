// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import concat from 'lodash/fp/concat';
import pipe from 'lodash/fp/pipe';
import isEmpty from 'lodash/fp/isEmpty';

import { addMany } from '@kwara/lib/src/currency';
import { type LoanType } from '@kwara/models/src';
import { toDurationFromUI } from '@kwara/models/src/models/util';
import { Statistic } from '@kwara/components/src/Statistic';
import { ExpanderRow } from '@kwara/components/src';
import { Date, Text, Duration, Percent } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';

import { Currency } from '../../../components/Currency';
import { Documents } from '../../LoanDetail/components/Documents';
import { Grid } from '../../../components/Grid';

import { filterDeletedSecurities, type FormDataLoanReschedule } from '..';

export function hasNoSecurities(data: FormDataLoanReschedule) {
  const securities = concat(data.collaterals, data.guarantors);
  return pipe(filterDeletedSecurities, isEmpty)(securities);
}

export function extractLoanValues(loan: LoanType) {
  const { balance: principal } = loan.principal;
  const { balance: interest } = loan.interest;
  const { balance: fees } = loan.fees;
  const { balance: penalties } = loan.penalties;
  return { principal, interest, fees, penalties };
}

export function calculateNewPrincipalAmount(data: FormDataLoanReschedule) {
  const { loan, writeOffAmount = 0 } = data;

  const { principal, interest, fees, penalties } = extractLoanValues(loan);
  const negativeWriteOff = Number(writeOffAmount) * -1;

  return Number(addMany([principal, interest, fees, penalties, negativeWriteOff]));
}

const formatRepaymentFrequency = (repaymentPeriod, repaymentPeriodUnit) =>
  toDurationFromUI(Number(repaymentPeriod), repaymentPeriodUnit);

const Header = (props: { children: React.Node }) => <header className="flex">{props.children}</header>;

export const LoanSummary = ({
  data,
  isRescheduledInfo
}: {
  data: FormDataLoanReschedule,
  isRescheduledInfo: boolean
}) => {
  const {
    loanDuration,
    repaymentPeriod,
    repaymentPeriodUnit,
    product,
    member,
    firstRepaymentDate,
    writeOffAmount,
    loan
  } = data;

  const { principal, interest, fees, penalties } = extractLoanValues(loan);

  // Depending whether this is the preview or summary screen...
  const duration = isRescheduledInfo
    ? // duration is calculated based on the live form value (summary screen)...
      toDurationFromUI(Number(loanDuration), repaymentPeriodUnit)
    : // ...or, the duration string of the original loan (preview screen)
      loan.duration;

  const repaymentFrequency = isRescheduledInfo
    ? formatRepaymentFrequency(repaymentPeriod, repaymentPeriodUnit)
    : formatRepaymentFrequency(loan.repaymentPeriod, loan.repaymentPeriodUnit);

  return (
    <>
      <Header>
        <Text id="MemberDetail.personalDetails" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.MemberDetails.memberId" />}
            value={member.id}
            size={'medium'}
          />
          <ProfilePhoto memberId={member.id} attachments={member.attachments} size="regular" isExpandable />
          <Statistic
            title={<Text id="MemberDetail.PersonalDetails.fullName" />}
            value={member && member.nameWithTitle()}
            size={'medium'}
          />
          <Statistic
            title={<Text id="MemberDetail.PersonalDetails.dateOfBirth" />}
            value={<Date value={member.dateOfBirth} />}
            size={'medium'}
          />
        </Grid>
      </ExpanderRow>

      <Header>
        <Text id="LoanReschedule.LoanSummary.LoanDetails.Header" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.Product" />}
            value={product.name}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.Interest" />}
            value={<Percent value={product.interestRate.percentage / 100} />}
            size={'medium'}
          />
          <Statistic
            title={
              <Text
                id={`LoanReschedule.LoanSummary.LoanDetails.${isRescheduledInfo ? 'Previous' : ''}PrincipalBalance`}
              />
            }
            value={<Currency value={principal} />}
            size={'medium'}
          />
          <Statistic
            title={
              <Text
                id={`LoanReschedule.LoanSummary.LoanDetails.${isRescheduledInfo ? 'Previous' : ''}InterestBalance`}
              />
            }
            value={<Currency value={interest} />}
            size={'medium'}
          />
          <Statistic
            title={
              <Text id={`LoanReschedule.LoanSummary.LoanDetails.${isRescheduledInfo ? 'Previous' : ''}FeesBalance`} />
            }
            value={<Currency value={fees} />}
            size={'medium'}
          />
          <Statistic
            title={
              <Text
                id={`LoanReschedule.LoanSummary.LoanDetails.${isRescheduledInfo ? 'Previous' : ''}PenaltiesBalance`}
              />
            }
            value={<Currency value={penalties} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.WriteOffAmount" />}
            color="red-600"
            value={<Currency value={writeOffAmount} />}
            size={'medium'}
            hidden={!writeOffAmount || !isRescheduledInfo}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.NewPrincipalAmount" />}
            value={<Currency value={calculateNewPrincipalAmount(data)} />}
            size={'medium'}
            hidden={!isRescheduledInfo}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.FirstRepaymentDate" />}
            value={<Date value={firstRepaymentDate} />}
            size={'medium'}
            hidden={!firstRepaymentDate}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.RepaymentFrequency" />}
            value={<Duration value={repaymentFrequency} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanReschedule.LoanSummary.LoanDetails.LoanDuration" />}
            value={<Duration value={duration} />}
            size={'medium'}
          />
        </Grid>
      </ExpanderRow>
      <Header>
        <Text id="LoanReschedule.LoanSummary.Security.Header" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          {hasNoSecurities(data) ? <Text id="LoanReschedule.LoanSummary.noSecurity" /> : null}

          {map(
            guarantee => (
              <Statistic
                key={guarantee.member.id}
                title={<Currency format="currency" value={guarantee.amount} />}
                value={guarantee.member.fullName()}
                size="small"
                compact={true}
              />
            ),
            filterDeletedSecurities(loan.guarantors)
          )}

          {map(
            collateral => (
              <Statistic
                key={collateral.id}
                title={<Text id={`LoanReschedule.LoanSummary.Security.Collateral.Type.${collateral.assetName}`} />}
                value={<Currency format="currency" value={collateral.amount} />}
                size="small"
                compact={true}
              />
            ),
            filterDeletedSecurities(loan.collaterals)
          )}
        </Grid>
      </ExpanderRow>

      <Header>
        <Text id="LoanReschedule.LoanSummary.Documents.Header" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={1} width="w-100">
          <Documents member={member} />
        </Grid>
      </ExpanderRow>
    </>
  );
};
