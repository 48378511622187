import * as React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { IconArrow } from '@kwara/components/src/Asset/IcnArrow.svg';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';

import { useFilterContext } from '../..';
import { FILTER_ICONS } from '../../utils/filterTypes';

import styles from './index.module.scss';

type BodyPropTypes = {
  disabled?: boolean;
};

export function Body({ disabled }: BodyPropTypes) {
  const {
    open,
    activeFilter,
    filterNames,
    bodyPosition,
    pages,
    headerRef,
    toggleFilter,
    onNavigateFilter
  } = useFilterContext();

  const Page = pages[activeFilter];

  return (
    <If
      condition={open}
      do={
        <ModalWrapper
          isOpen={open}
          closeOnOutClick
          onClose={toggleFilter}
          exemptMouseEventRefs={[headerRef]}
          className={cx(zIndices.Max, styles['container'], { [styles['container--disabled']]: !!disabled })}
          style={{ top: bodyPosition.top, left: bodyPosition.left }}
        >
          <nav className={styles['container__nav']}>
            <ul>
              {filterNames.map((name, i) => {
                const Icon = FILTER_ICONS[name];
                const isActiveName = activeFilter === name;

                return (
                  <li key={i.toString()} className="item">
                    <Button
                      disabled={disabled}
                      className={cx(styles['item__action'], { [styles['item__action--active']]: isActiveName })}
                      onClick={() => onNavigateFilter(name)}
                    >
                      <div className={styles['name-wrapper']}>
                        <span>
                          <Icon />
                        </span>
                        <span className="kw-text-small">
                          <Text id={`Filter.${name}`} />
                        </span>
                      </div>

                      <If
                        condition={isActiveName}
                        do={
                          <div className={styles['arrow-wrapper']}>
                            <IconArrow fill="#1E293B" className={styles['arrow-icon']} />
                          </div>
                        }
                      />
                    </Button>
                  </li>
                );
              })}
            </ul>
          </nav>

          <div className={styles['container__page']}>
            <Page />
          </div>
        </ModalWrapper>
      }
    />
  );
}
