// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { BankSelect, BranchSelect } from '@kwara/components/src/Form';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Bank = ({ StackChild, TextField, formProps }: SubStepComponentProps<{}>) => {
  const { values } = formProps;

  const bankId = get('bankBranch.bank.id', values);

  return (
    <StackChild>
      <BankSelect required name="bankBranch.bank.id" labelId="AddMember.Bank.name.label" />
      <BranchSelect required name="bankBranch.id" bankId={bankId} labelId="AddMember.Bank.branch.label" />
      <TextField required name="accountNumber" labelId="AddMember.Bank.account.label" />
    </StackChild>
  );
};

Bank.validate = {
  'bankBranch.bank.id': { isRequired: () => true },
  'bankBranch.id': { isRequired: () => true },
  accountNumber: { isRequired: () => true }
};
