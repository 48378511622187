/************************************************************************
 * @COMMON_TABLE_SPANS is not a ```must``` to use this ``util``` when
 * assigning ```rowSpans``` or ```colSpan```, its  just a way to have a
 * single source of truth. In summary, your spans value depends on how
 * you want the view to scale in respect to  ```<td/>``` and ```<th/>```
 * ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/table
 */
const COMMON_TABLE_SPANS = Object.freeze({
  four: 4,
  three: 3,
  two: 2,
  one: 1,
  zero: 0
});

const COMMON_TABLE_WIDTHS = Object.freeze({
  fourColsDivision: Object.freeze({
    firstThreeCols: '32%',
    lastCol: '4%'
  }),
  threeColsDivision: Object.freeze({
    firstThreeCols: '48%',
    lastCol: '4%'
  })
});

export { COMMON_TABLE_SPANS, COMMON_TABLE_WIDTHS };
