import React from 'react';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src/Intl';
import { AccountingReportT } from '@kwara/models/src';
import { BackButton } from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import { useDownloadButton } from '@kwara/components/src/DownloadButton';

import { ButtonMenu } from '../../../../components/ButtonMenu';
import TabNavigation from '../../../../components/TabNavigation';

import { BalanceSheet, TrialBalance, ProfitLoss } from '../..';
import { useAccountingReport } from '../../useAccountingReport';

interface TabContentWrapperPropType {
  titleId: string;
  dateRange: { startDate: string; endDate: string };
  table: React.ReactNode;
  report: AccountingReportT;
}

function DownloadXLSXButton({ disabled, onDownload }: { disabled: boolean; onDownload: () => Promise<void> }) {
  return (
    <ButtonMenu.Item disabled={disabled} onClick={onDownload}>
      <Text id="AccountingReports.ReportView.button.downloadXLSX" />
    </ButtonMenu.Item>
  );
}

function DownloadButtonMenu({ report }) {
  const { isDisabled, onDownload, isLoading: isDownloading } = useDownloadButton({
    downloadFn: report.downloadXlsx,
    instance: report,
    disabled: false
  });

  return (
    <ButtonMenu disabled={isDisabled || isDownloading} type="secondary" title="Download">
      <DownloadXLSXButton key={'XLSX'} disabled={isDisabled || isDownloading} onDownload={onDownload} />
    </ButtonMenu>
  );
}

const TabContentWrapper = ({ titleId, dateRange, table, report }: TabContentWrapperPropType) => {
  return (
    <section className="bg-white pt3">
      <div className="pl3 pr3">
        <div className="flex justify-between w-100">
          <BackButton
            type="secondary"
            to={{
              url: '/accounting/reports'
            }}
          />
          <DownloadButtonMenu report={report} />
        </div>
        <div className="flex items-center pt3 pb3">
          <div className="kw-text-x-large kw-weight-bold ">
            <Text id={titleId} />
          </div>
          <div className="kw-text-small neutral-700 ml3">
            <Text id={'AccountingReports.ReportView.dateRange'} values={dateRange} />
          </div>
        </div>
      </div>
      {table}
    </section>
  );
};

interface ReportPropType {
  data: AccountingReportT;
}

function Report({ data }: ReportPropType) {
  const dateRange = { startDate: data.startDate, endDate: data.endDate };
  const tabConfig = [
    {
      title: <Text id={'AccountingReports.Tab.BalanceSheet.title'} />,
      content: (
        <TabContentWrapper
          report={data}
          titleId="AccountingReports.Tab.BalanceSheet.title"
          dateRange={dateRange}
          table={<BalanceSheet data={data} />}
        />
      )
    },
    {
      title: <Text id={'AccountingReports.Tab.TrialBalance.title'} />,
      content: (
        <TabContentWrapper
          report={data}
          titleId="AccountingReports.Tab.TrialBalance.title"
          dateRange={dateRange}
          table={<TrialBalance data={data} />}
        />
      )
    },
    {
      title: <Text id={'AccountingReports.Tab.ProfitLoss.title'} />,
      content: (
        <TabContentWrapper
          report={data}
          titleId="AccountingReports.Tab.ProfitLoss.title"
          dateRange={dateRange}
          table={<ProfitLoss data={data} />}
        />
      )
    }
  ];

  return <TabNavigation.SwitchTab tabConfig={tabConfig} />;
}

interface ReportViewPropTypes {
  match: {
    params: {
      reportId: string;
    };
  };
}

export function ReportView({ match }: ReportViewPropTypes) {
  const id = get('params.reportId', match);
  const r = useAccountingReport(id);

  return <Loadable {...r}>{(data: AccountingReportT) => <Report data={data} />}</Loadable>;
}
