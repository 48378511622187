import * as React from 'react';
import cx from 'classnames';

import { Header } from './components/Header';
import { Body } from './components/Body';
import { LeftNavigation } from './components/LeftNavigation';
import { envs } from '../../routes/components/EnvironmentBanner/EnvironmentBanner';

type AppBasePropTypes = {
  logOut(): Promise<void>;
  children: React.ReactNode;
};

export function AppBase({ children, logOut }: AppBasePropTypes) {
  const isEnvBannerRendered = !!envs.name;

  return (
    <div className={cx('flex', { mt3: isEnvBannerRendered })}>
      <LeftNavigation />

      <div>
        <Header logOut={logOut} />
        <Body>{children}</Body>
      </div>
    </div>
  );
}
