import * as React from 'react';
import cx from 'classnames';

import styles from './index.module.css';

type Props = {
  title: string,
  children: any,
  headerRight?: React.Node,
  ariaLabel?: string
};

export const SubsectionTitle = ({ children, className }: { children: React.Node, className?: string }) => (
  <h3 className={cx(`kw-text-regular bg-white pr3 grey-400 `, className)}>{children}</h3>
);

export default ({ title, children, headerRight, classNames, ariaLabel }: Props) => {
  return (
    <section aria-label={ariaLabel} className={cx([styles.Subsection, classNames])}>
      <div className="bt b--light-grey-400">
        <div className={cx(['flex items-center', styles.Header])}>
          <SubsectionTitle>{title}</SubsectionTitle>
          {headerRight ? <div className={cx([styles.HeaderRight, 'bg-white pl3'])}>{headerRight}</div> : null}
        </div>
        <div>{children}</div>
      </div>
    </section>
  );
};
