import * as React from 'react';
import cx from 'classnames';

import { Render } from '@kwara/components/src';
import { Text, Currency } from '@kwara/components/src/Intl';

import { useSavings, Current } from '.';
import { Card } from '../../../../components/Card';
import { Heading } from '../../../../components/Heading';

import styles from './index.module.scss';

function onKeydownAccessibilityHandler(current: Current, send: (current: Current) => void) {
  return function onKeydownHandler(event: React.KeyboardEvent<HTMLButtonElement>) {
    switch (event.key) {
      case 'Enter':
      case 'ArrowRight':
      case 'ArrowLeft':
        if (current === 'last_twelve_months') send('last_six_months');
        else send('last_twelve_months');
        break;
      default:
        return;
    }
  };
}

export function History() {
  const { current, data, send } = useSavings();

  const savingsCalculations = data.savingsHistoryWithStatement?.savings_calculations || {};
  const hasCalculations = !!Object.keys(savingsCalculations).length;
  const onKeydownHandler = onKeydownAccessibilityHandler(current, send);

  return (
    <Render condition={hasCalculations} fallbackId="LoanDisbursal.MemberProfile.Savings.noHistory">
      <Card className={styles['history-container']}>
        <Heading className={styles['history-container__heading']}>
          <div>
            <Text id="LoanDisbursal.MemberProfile.Savings.History.title" />
          </div>

          <div className={styles['tabs-wrapper']}>
            <button
              type="button"
              data-testid="savHis6m"
              className={cx('kw-text-medium', styles['tab'], styles['six-m'], {
                [styles['six-m--active']]: current === 'last_six_months'
              })}
              onClick={() => send('last_six_months')}
              onKeyDown={onKeydownHandler}
            >
              <Text id="LoanDisbursal.MemberProfile.Savings.History.6m" />
            </button>
            <button
              type="button"
              data-testid="savHis1yr"
              className={cx('kw-text-medium', styles['tab'], styles['one-yr'], {
                [styles['one-yr--active']]: current === 'last_twelve_months'
              })}
              onClick={() => send('last_twelve_months')}
              onKeyDown={onKeydownHandler}
            >
              <Text id="LoanDisbursal.MemberProfile.Savings.History.1yr" />
            </button>
          </div>
        </Heading>

        <ul className={styles['sav-his']}>
          {hasCalculations &&
            Object.entries(savingsCalculations[current]).map(([key, value]) => (
              <li key={key}>
                <span>
                  <Text id={`LoanDisbursal.MemberProfile.Savings.History.${key}`} />
                </span>

                <span>
                  <Currency format="currency" value={value} hideDecimals={false} />
                </span>
              </li>
            ))}
        </ul>
      </Card>
    </Render>
  );
}
