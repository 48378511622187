import React from 'react';
import map from 'lodash/fp/map';

import { C2BTransactionStates } from '@kwara/models/src/models/C2BTransaction';

import { BodyContentWrapper } from '../../../../../layouts';
import { usePaginatedC2BTransactions } from '../../../../../models/hooks';
import { HeaderSubHeader } from '../../../../../components/HeaderSubHeader';

import { MpesaList } from '../shared/List';

const filters = [
  { value: '', translationId: '' },
  ...map(
    value => ({
      value,
      translationId: `Finance.Mpesa.List.filter.${value}`
    }),
    Object.values(C2BTransactionStates)
  )
];

export const C2BTransactions = () => {
  const { allData: transactions, ...rest } = usePaginatedC2BTransactions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.Mpesa.C2B.title" subtitleId="Finance.Mpesa.C2B.subtitle" />
          <MpesaList transactions={transactions} filters={filters} hideType {...rest} />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
