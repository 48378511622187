import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import get from 'lodash/fp/get';
import last from 'lodash/fp/last';

import { totalBalance } from '@kwara/models/src/models/LoanApplication';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text, Currency } from '@kwara/components/src/Intl';

import { Grid } from '../../../../../components/Grid';
import { SubscribedLoanAccountCard } from './SubscribedLoanAccountCard';

export function getLoansFromLoanIds(loanIds, allLoans) {
  return filter(loan => includes(loan.id, loanIds), allLoans);
}

export const Loans = ({ StackChild, data, formProps }) => {
  const { values } = formProps;
  const { payOffLoans: payOffLoanIds } = values;
  const { loans, amount, loan } = data;

  const payOffLoans = getLoansFromLoanIds(payOffLoanIds, loans);
  const activeLoans = filter(loan => loan.isApproved(), loans);

  const error = last(get('errors.payOffLoans', formProps));

  if (isEmpty(activeLoans)) {
    return (
      <StackChild size="wide">
        <div className="tc">
          <Text id="LoanAdd.Loans.noOtherLoans" />
        </div>
      </StackChild>
    );
  }

  return (
    <StackChild size="wide">
      <StatisticRow widthClass="w-50">
        <Statistic size="big" title={<Text id="LoanAdd.Loans.amount" />} value={<Currency value={amount} />} />
        <div>
          <Statistic
            size="big"
            title={<Text id="LoanAdd.Loans.totalBalance" />}
            value={<Currency value={totalBalance(payOffLoans)} />}
            color={totalBalance(payOffLoans) > Number(amount) ? 'red-500' : undefined}
          />
          {error ? (
            <span className="red-500">
              <Text
                id="LoanAdd.Loans.totalBalance.error.rangeOverflow"
                values={{ val: <Currency format="currency" value={amount} /> }}
              />
            </span>
          ) : null}
        </div>
      </StatisticRow>

      <Grid border={false}>
        {map(
          l => (
            <SubscribedLoanAccountCard disabled={l.id === get('id', loan)} key={l.id} account={l} />
          ),
          activeLoans
        )}
      </Grid>
    </StackChild>
  );
};

Loans.validate = {
  payOffLoans: {
    custom: (target, allData) => {
      const { amount, loans } = allData;
      const payOffLoans = getLoansFromLoanIds(target, loans);

      if (totalBalance(payOffLoans) >= Number(amount)) {
        return 'Error';
      }
      return null;
    }
  }
};
