import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconSettingsElement = React.ElementRef<'svg'>;
interface IconSettingsPropTypes extends PrimitiveSvgPropTypes {}

export const IconSettings = React.forwardRef<IconSettingsElement, IconSettingsPropTypes>(function IconSettings(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1 9.71994C18.29 9.71994 17.55 8.43994 18.45 6.86994C18.97 5.95994 18.66 4.79994 17.75 4.27994L16.02 3.28994C15.23 2.81994 14.21 3.09994 13.74 3.88994L13.63 4.07994C12.73 5.64994 11.25 5.64994 10.34 4.07994L10.23 3.88994C9.78 3.09994 8.76 2.81994 7.97 3.28994L6.24 4.27994C5.33 4.79994 5.02 5.96994 5.54 6.87994C6.45 8.43994 5.71 9.71994 3.9 9.71994C2.86 9.71994 2 10.5699 2 11.6199V13.3799C2 14.4199 2.85 15.2799 3.9 15.2799C5.71 15.2799 6.45 16.5599 5.54 18.1299C5.02 19.0399 5.33 20.1999 6.24 20.7199L7.97 21.7099C8.76 22.1799 9.78 21.8999 10.25 21.1099L10.36 20.9199C11.26 19.3499 12.74 19.3499 13.65 20.9199L13.76 21.1099C14.23 21.8999 15.25 22.1799 16.04 21.7099L17.77 20.7199C18.68 20.1999 18.99 19.0299 18.47 18.1299C17.56 16.5599 18.3 15.2799 20.11 15.2799C21.15 15.2799 22.01 14.4299 22.01 13.3799V11.6199C22 10.5799 21.15 9.71994 20.1 9.71994ZM12 15.7499C10.21 15.7499 8.75 14.2899 8.75 12.4999C8.75 10.7099 10.21 9.24994 12 9.24994C13.79 9.24994 15.25 10.7099 15.25 12.4999C15.25 14.2899 13.79 15.7499 12 15.7499Z"
        fill="#475569"
      />
    </svg>
  );
});

IconSettings.defaultProps = {
  width: '25',
  height: '24'
};
