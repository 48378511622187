// @flow

import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { type MemberType, BankAccount, BankBranch, Bank as BankModel } from '@kwara/models/src';
import { useBankAccounts } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';

import { Grid } from '../../Grid';
import { EditableSection, Updater, type EditableConfig } from '../../EditableSection/v1/EditableSection';
import { Bank as BankForm } from '../../../pages/MemberAdd/components/Bank';

const config = {
  Component: BankForm,
  validate: BankForm.validate
};

const includes = ['bank_branch.bank'];
export const Bank = ({ member, readOnly }: EditableConfig<MemberType>) => {
  const { refetch, ...r } = useBankAccounts(member.id, includes);

  return (
    <Updater value={{ onUpdate: refetch }}>
      <Loadable {...r}>
        {bankAccounts => (
          <>
            {map(account => {
              return (
                <EditableSection
                  key={account.id}
                  containerClassNames="mb3"
                  config={config}
                  onSave={instance => instance.save({ with: ['bankBranch'] })}
                  initialData={account}
                  readOnly={readOnly}
                  onDelete={async () => {
                    const didDestroy = await account.destroy();
                    if (didDestroy) {
                      refetch();
                    }
                  }}
                >
                  <Grid columns={3} width="w-33">
                    <Statistic
                      title={<Text id="AddMember.Bank.name.label" />}
                      value={get('bankBranch.bank.name', account)}
                      size="medium"
                    />
                    <Statistic
                      title={<Text id="AddMember.Bank.branch.label" />}
                      value={get('bankBranch.name', account)}
                      size="medium"
                    />
                    <Statistic
                      title={<Text id="AddMember.Bank.account.label" />}
                      value={get('accountNumber', account)}
                      size="medium"
                    />
                  </Grid>
                </EditableSection>
              );
            }, bankAccounts)}
            <EditableSection
              containerClassNames="mt2"
              type="add"
              ariaLabel="Add"
              config={config}
              onSave={instance => instance.save({ with: ['bankBranch'] })}
              initialData={
                new BankAccount({
                  mambuId: member.id,
                  bankBranch: new BankBranch({
                    bank: new BankModel()
                  })
                })
              }
              readOnly={readOnly}
            >
              {isEmpty(bankAccounts) ? (
                <div className="h-100 flex items-center justify-center">
                  <Text id="AddMember.Bank.noAccounts" />
                </div>
              ) : null}
            </EditableSection>
          </>
        )}
      </Loadable>
    </Updater>
  );
};

Bank.Title = () => <Text id="MemberDetail.bank" />;
