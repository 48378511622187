import * as React from 'react';

export function IconCalendar() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.33333" y="2" width="13.3333" height="12.6667" rx="2" stroke="#1E293B" strokeWidth="1.33333" />
      <path
        d="M4.66667 0.666656V1.99999"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 0.666656V1.99999"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33333 5.33334H14.6667"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 8.66666H6.33333"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66667 8.66666H11"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.3333H6.33333"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66667 11.3333H11"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
