import React from 'react';
import size from 'lodash/size';

import { OnFilterValue } from 'FilterTypes';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { MemberType } from '@kwara/models/src/models/Member';

import MemberListTable from '../MemberListTable';
import Visible from '../../../../components/Visible';
import OverviewListV2 from '../../../../components/OverviewListV2';
import { memberPath } from '../../../../lib/urls';
import { usePermissions } from '../../../../hooks';
import { useMemberListStatusFilterConfig } from './useMemberListStatusFilterConfig';

function getLabelId(loading: boolean, members: MemberType[] = []) {
  if (loading) return size(members) === 1 ? 'MemberList.searchedCountSingular' : 'MemberList.searchedCount';

  return 'MemberList.filteredCount';
}

type MemberListPropTypes = {
  hasMore?: boolean;
  loading?: boolean;
  items?: MemberType[];
  members?: MemberType[];
  errors: Object[];
  totalNumResults: number | null;
  onLoadMoreData(): void;
  resetData?(): void;
  onFilterChange: OnFilterValue;
  rowsAsLink: boolean;
  withFilter: boolean;
  isV1?: boolean;
};

export function MemberList({
  hasMore = false,
  loading = false,
  members = [],
  errors,
  onLoadMoreData,
  totalNumResults,
  onFilterChange,
  resetData,
  rowsAsLink,
  withFilter,
  isV1
}: MemberListPropTypes) {
  const { AppPermissions, permission } = usePermissions();
  const filters = useMemberListStatusFilterConfig(isV1);

  return (
    <OverviewListV2.Root filters={filters} disabled={loading} onFilterValue={onFilterChange} onReset={resetData}>
      <OverviewListV2
        withFilter={withFilter}
        actions={[
          <Visible key="add" to={AppPermissions.AddMembers}>
            <Button size="regular" type="primary" to={memberPath({ action: 'create' })}>
              <Text id="MemberList.addMember" />
            </Button>
          </Visible>
        ]}
        headerId="MemberList.heading"
        labelId={getLabelId(loading, members)}
        items={members}
        totalNumResults={totalNumResults}
        table={
          <MemberListTable
            groupBy={null}
            hasMore={hasMore}
            members={members}
            orderBy={null}
            loading={loading}
            errors={errors}
            onLoadMoreData={onLoadMoreData}
            rowsAsLink={rowsAsLink}
            footerTranslationBaseId={permission.to(AppPermissions.ViewMembers) ? undefined : 'MemberListTable.Empty'}
            isV1={isV1}
          />
        }
      />
    </OverviewListV2.Root>
  );
}
