import React from 'react';
import { useQuery } from 'react-query';
import { LocationDescriptor } from 'history';

import { fetchMember } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';

import { TwoThirdsModal as TwoThirdsModalBase } from '../../../../components/TwoThirdsModal';

type TwoThirdsModalPropTypes<LocationState> = {
  data: any;
  onBypass(): void;
  to?: LocationDescriptor<LocationState>;
  btnTextId?: string;
};

const fields = ['total_monthly_loan_payments'];
export function useMemberIncome(id: string) {
  return useQuery(['member_income', id, null, fields], fetchMember);
}

export function TwoThirdsModal<LocationState>(props: TwoThirdsModalPropTypes<LocationState>) {
  const { onBypass, data, btnTextId, to = '/loans/create/member/1' } = props;
  const { member, remittance } = data;
  const { netIncome, otherIncomeAmount, otherDeductibles } = member;
  const { amount: repaymentAmount } = remittance;

  const r = useMemberIncome(member.id);

  return (
    <Loadable {...r}>
      {memberIncome => (
        <TwoThirdsModalBase
          totalMonthlyLoanPayments={memberIncome.totalMonthlyLoanPayments}
          netIncome={netIncome}
          otherIncomeAmount={otherIncomeAmount}
          otherDeductibles={otherDeductibles}
          repaymentAmount={repaymentAmount}
          onBypass={onBypass}
          btnTextId={btnTextId}
          to={to}
          member={member}
        />
      )}
    </Loadable>
  );
}
