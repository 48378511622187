import React from 'react';
import cx from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { mapIndexed } from '@kwara/lib/src/lodash';

import styles from './index.module.scss';

type TabConfig = {
  title: React.ReactNode;
  content: React.ReactNode;
};

type SwitchTabType = {
  tabConfig: TabConfig[];
  className?: string;
  ariaLabel?: string;
};

export const SwitchTab = ({ tabConfig, className, ariaLabel = 'Navigation Tabs' }: SwitchTabType) => {
  const tabs = mapIndexed(
    (config, index) => (
      <Tab
        key={index}
        className={cx('pv2 ph3 kw-text-small', styles['tab-item'])}
        selectedClassName={styles['tab-item--active']}
      >
        {config.title}
      </Tab>
    ),
    tabConfig
  );

  const tabPanels = mapIndexed(
    (config, index) => (
      <TabPanel key={index} className="mt2">
        {config.content}
      </TabPanel>
    ),
    tabConfig
  );

  return (
    <Tabs defaultIndex={0} className={`w-100 ${className}`} aria-label={ariaLabel}>
      <div className="flex justify-center">
        <TabList className={[styles['tab-list'], 'kw-text-small']}>{tabs}</TabList>
      </div>
      {tabPanels}
    </Tabs>
  );
};
