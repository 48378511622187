import * as React from 'react';

import { createContext } from '@kwara/lib/src/utils/createContext';

import { Icon } from './components/Icon';
import { Body } from './components/Body';
import { Header } from './components/Header';
import { DataType } from './models/NotificationSystem';
import { Announcement } from './components/Announcements';
import { useNotificationSystemCommands } from './hooks/useNotificationSystemCommands';
import { useNotificationSystemContextValues } from './hooks/useNotificationSystemContextValues';

export type NotificationSystemContextType = {
  data: DataType;
  readIds: string[];
  onResetNotificationSystem(): void;
  onDeleteAnnouncement(id: string): void;
  onReadAnnouncement(id: string): void;
  shownAnnouncements: boolean;
  onHideAnnouncements(): void;
  onShowAnnouncement(): void;
  onToggleShownAnnouncement(): void;
  onMarkAllAnnouncementAsRead(): void;
  headingId: string;
  bodyId: string;
  hasReadAllAnnouncement: boolean;
  iconRef: React.MutableRefObject<HTMLButtonElement>;
  hasAnnouncements: boolean;
};

const [NotificationSystemProvider, useNotificationSystemContext] = createContext<NotificationSystemContextType>(
  'NotificationSystemContext',
  true
);

type NotificationSystemPropTypes = {
  data?: DataType;
  children: React.ReactElement[] | React.ReactElement;
};

function NotificationSystem(props: NotificationSystemPropTypes) {
  const values = useNotificationSystemContextValues(props.data);

  return <NotificationSystemProvider value={values}>{props.children}</NotificationSystemProvider>;
}

export { useNotificationSystemContext, useNotificationSystemCommands };

export default {
  Root: NotificationSystem,
  Icon,
  Announcement: {
    Root: Announcement,
    Header,
    Body
  }
};
