import { SequentialStepStepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import Review from './components/Review';

export const steps: SequentialStepStepConfig = {
  review: {
    titleId: 'LoanTopup.Disbursal.Review.LeftNavigation.title',
    subtitleId: 'LoanTopup.Disbursal.Review.LeftNavigation.subtitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanTopup.Disbursal.Review.title',
        Component: Review.Component,
        validate: Review.validate
      }
    ]
  }
};
