// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { type LoanProductType } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Statistic } from '@kwara/components/src/Statistic';
import { Percent, Duration, Text } from '@kwara/components/src/Intl';

import { settingPath } from '../../../../lib/urls';
import Table, { Cell, Row, Heading, Collapsible } from '../../../../components/Table';
import { Grid } from '../../../../components/Grid';
import { ButtonMenu } from '../../../../components/ButtonMenu';

import { CurrencyRange } from '../shared';

const IconDropDown = ({ product, col = 'grey300' }: { product: LoanProductType, col: string }) => (
  <ButtonMenu Button={props => <ActionButton {...props} hideBorder col={col} type="more" />}>
    <ButtonMenu.Item disabled>{product.name}</ButtonMenu.Item>
    <ButtonMenu.Item
      to={settingPath({
        baseExtension: 'loans',
        action: 'edit',
        id: product.id
      })}
    >
      <Text id="Settings.Loans.Table.IconMenu.edit" />
    </ButtonMenu.Item>
    <ButtonMenu.Item
      to={settingPath({
        baseExtension: 'loans',
        action: 'schedule',
        id: product.id
      })}
    >
      <Text id="Settings.Loans.Table.IconMenu.schedulePreview" />
    </ButtonMenu.Item>
  </ButtonMenu>
);

export const LoanProductTable = ({ products }: { products: LoanProductType[] }) => {
  const heading = (
    <Row className="grey-400">
      <Heading translationId="Settings.Loans.Table.id" />
      <Heading translationId="Settings.Loans.Table.name" />
      <Heading translationId="Settings.Loans.Table.interest" />
      <Heading translationId="Settings.Loans.Table.amount" />
      <Heading translationId="Settings.Loans.Table.repaymentFrequency" />
      <Heading translationId="Settings.Loans.Table.maxDuration" />
      <Heading iconSpacer />
      <Heading iconSpacer />
    </Row>
  );

  const colSpan = size(heading.props.children);

  return (
    <Table className="mt5" heading={heading}>
      {map(product => {
        return (
          <Collapsible key={product.id} iconColor="grey300">
            <Row secondaryIcon={<IconDropDown product={product} />}>
              <Cell>{product.id}</Cell>
              <Cell>{product.name}</Cell>
              <Cell>
                {product.interestRate.percentage != null ? (
                  <Percent value={product.interestRate.percentage / 100} />
                ) : (
                  '-'
                )}
              </Cell>
              <Cell>
                <CurrencyRange min={product.amount.minimum} max={product.amount.maximum} />
              </Cell>
              <Cell>{product.repaymentFrequency ? <Duration value={product.repaymentFrequency} /> : '-'}</Cell>
              <Cell>{product.maxDuration ? <Duration value={product.maxDuration} /> : '-'}</Cell>
            </Row>
            <Row className="bg-light-grey-300">
              <Cell colSpan={colSpan}>
                <Grid columns={2} width="w-50">
                  {/* TO DO: These are static details.
                  Display action values when the api shape
                  is established */}
                  <Statistic
                    title={<Text id="Settings.Loans.Table.loanType" />}
                    value={<Text id={`Settings.Loans.Table.loanType.${product.type}`} />}
                  />
                  <Statistic
                    title={<Text id="Settings.Loans.Table.interestFrequency" />}
                    value={product.interestRate.frequency}
                  />

                  <Statistic
                    title={<Text id="Settings.Loans.Table.guarantorsEnabled" />}
                    value={
                      <Text
                        id={`Settings.Loans.Table.guarantorsEnabled.${
                          get('security.guarantors_enabled', product) ? 'yes' : 'no'
                        }`}
                      />
                    }
                  />
                  <Statistic
                    title={<Text id="Settings.Loans.Table.collateralsEnabled" />}
                    value={
                      <Text
                        id={`Settings.Loans.Table.collateralsEnabled.${
                          get('security.collaterals_enabled', product) ? 'yes' : 'no'
                        }`}
                      />
                    }
                  />
                </Grid>
              </Cell>
            </Row>
          </Collapsible>
        );
      }, products)}
    </Table>
  );
};
