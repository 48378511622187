import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import reduce from 'lodash/fp/reduce';
import slice from 'lodash/fp/slice';

import { add } from '@kwara/lib/src/currency';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { formatIsoDate, getCurrentDate, subMonths } from '@kwara/lib/src/dates';

const PIE_COLORS = ['#aaf0c4', '#FBBD50', '#ED8779', '#A8C8C9', '#9B87DA', '#94A3B8'];

function formatPieData(data: { product: string; value: string }[]) {
  const sortedFormatted = pipe(
    sortBy(({ value }) => -value),
    map(({ product, value }) => ({ name: product, value: Number(value) }))
  )(data);

  const top5 = slice(0, 5, sortedFormatted);
  const others = slice(5, sortedFormatted.length, sortedFormatted);
  const sumOfOthers = reduce((acc, { value }) => Number(add(acc, value)), 0, others);
  const pieData = [...top5, ...(sumOfOthers > 0 ? [{ name: 'Others', value: sumOfOthers }] : [])];
  const coloredData = mapIndexed((data, index) => ({ color: PIE_COLORS[index], ...data }), pieData);

  return coloredData;
}

function abbreviateNumber(number) {
  if (number === 0) return '0';

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const sign = number < 0 ? '-' : '';
  const absNumber = Math.abs(number);

  if (absNumber < 1) return `${sign}${parseFloat(absNumber.toFixed(2))}`;

  const suffixNum = Math.floor(Math.log10(absNumber) / 3);
  const shortNum = (absNumber / Math.pow(10, suffixNum * 3)).toFixed(2);
  const trimmedNum = parseFloat(shortNum);
  const suffix = suffixes[suffixNum];

  return `${sign}${trimmedNum}${suffix}`;
}

function getStartAndEndDates() {
  const endDate = formatIsoDate(getCurrentDate());
  const startDate = formatIsoDate(subMonths(getCurrentDate(), 12));

  return snakeCaseObjectKeys({ startDate, endDate });
}

export { PIE_COLORS, abbreviateNumber, formatPieData, getStartAndEndDates };
