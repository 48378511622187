import React from 'react';

import { If } from '@kwara/components/src/If/If';

import Filter from '../../Filter';

type ListActionsDashboardPropTypes = {
  actions: React.ReactElement | React.ReactElement[];
  withFilter?: boolean;
  filterDisabled?: boolean;
  exportButton?: React.ReactElement;
};

export function ListActionsDashboard({
  withFilter,
  actions,
  filterDisabled,
  exportButton
}: ListActionsDashboardPropTypes) {
  return (
    <div className="w-100 flex flex-wrap items-end justify-between mt3">
      {actions}

      <If
        condition={!!withFilter}
        do={
          <>
            <Filter.Header disabled={filterDisabled} />
            <Filter.Body disabled={filterDisabled} />
          </>
        }
        else={<div />}
      />

      {exportButton}
    </div>
  );
}
