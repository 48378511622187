// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { Loadable } from '@kwara/components/src/Loadable';
import { type ComponentProps } from '@kwara/components/src/Wizard/deprecated/SubStep';

import { useRoles } from '../../../hooks';
import { type FormData } from '..';

export const User = ({ StackChild, TextField, SelectField }: ComponentProps<FormData>) => {
  const r = useRoles();
  return (
    <StackChild>
      <TextField name="email" labelId="InviteUser.User.email" required />
      <Loadable {...r}>
        {roles => (
          <SelectField name="roleId" labelId="InviteUser.User.role" required>
            <SelectField.Option value="" />
            {map(
              role => (
                <SelectField.Option key={role.id} value={role.id} translationId={role.name} />
              ),
              roles
            )}
          </SelectField>
        )}
      </Loadable>
    </StackChild>
  );
};

User.validate = {
  email: {
    isRequired: () => true,
    email: true
  },
  roleId: {
    isRequired: () => true
  }
};
