import * as React from 'react';
import { isToday, isYesterday } from 'date-fns';

import { Text, Date as DateComponent } from '@kwara/components/src/Intl';

type FormatDatePropTypes = {
  value: Date;
};

export function FormatDate({ value }: FormatDatePropTypes) {
  if (isToday(value)) {
    return (
      <>
        <Text id="NotificationSystem.today" /> (<DateComponent value={value} />)
      </>
    );
  }

  if (isYesterday(value)) {
    return (
      <>
        <Text id="NotificationSystem.yesterday" /> (<DateComponent value={value} />)
      </>
    );
  }

  return <DateComponent value={value} />;
}
