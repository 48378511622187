import React, { useCallback, useMemo } from 'react';

import { JournalEntries } from '../JournalEntry';
import { store } from '../../../../models/Store/Store';
import { usePendingEntriesCount } from './usePendingEntriesCount';
import { CheckableJournalEntries } from '../CheckableJournalEntry';
import { usePaginatedJournalEntry } from '../JournalEntry/usePaginatedJournalEntry';
import { usePaginatedCheckableJournalEntry } from '../CheckableJournalEntry/usePaginatedCheckableJournalEntry';

export function useConfig() {
  const { allData: journalEntries, ...r1 } = usePaginatedJournalEntry();
  const { allData: checkableItems, filterValue: filterState, ...r2 } = usePaginatedCheckableJournalEntry();

  const pendingCount = usePendingEntriesCount({ filterState, checkableItems, isLoading: r2.isLoading });

  const refetch = useCallback(async () => {
    await r1.refetch();
    await r2.refetch();
  }, [r1, r2]);

  return useMemo(
    () => ({
      entries: {
        titleId: 'entries',
        component: <JournalEntries transactions={journalEntries} {...r1} />
      },
      ...(store.isMakerCheckerEnabledApi
        ? {
            pending: {
              titleId: 'pending',
              component: <CheckableJournalEntries transactions={checkableItems} refetch={refetch} {...r2} />,
              values: { pendingCount }
            }
          }
        : null)
    }),
    [checkableItems, journalEntries, pendingCount, r1, r2, refetch]
  );
}
