import React from 'react';
import map from 'lodash/fp/map';

import { Currency, Percent } from '@kwara/components/src/Intl';

import Table, { Row, Heading, Cell, Footer } from '../../../../../../../components/Table';
import {
  DividendContributionSummaryT,
  DividendReportsT,
  DividendCalculationMethodT
} from '../../../../../../../models';
import { ReportType } from '../../../useReportType';

export function ContributionsTable({
  reportCalculationMethod,
  reportType,
  contributionSummaries,
  onRowClick,
  report,
  isLoading,
  onNext,
  hasMore
}: {
  reportType: ReportType;
  reportCalculationMethod: undefined | DividendCalculationMethodT;
  contributionSummaries: DividendContributionSummaryT[];
  report: DividendReportsT | null;
  isLoading: boolean;
  onNext: () => void;
  hasMore: boolean;
  onRowClick: (id: string) => void;
}) {
  const heading = (
    <Row>
      <Heading translationId="Finance.DividendReports.Contributions.table.header.memberId" width="150px" />
      <Heading translationId="Finance.DividendReports.Contributions.table.header.memberName" width="150px" />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.table.header.contributions" />
      {reportCalculationMethod && (
        <Heading
          align="right"
          translationId={`Finance.DividendReports.Contributions.table.header.${reportCalculationMethod}.Contributions`}
        />
      )}
      <Heading
        align="right"
        translationId={`Finance.DividendReports.Contributions.table.header.${reportType}.dividendPercentage`}
      />
      <Heading
        align="right"
        translationId={`Finance.DividendReports.Contributions.table.header.${reportType}.grossDividends`}
      />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.table.header.taxPercentage" />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.table.header.taxesWithheld" />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.table.header.netBalance" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <Table
      heading={heading}
      footer={
        <Footer items={contributionSummaries} onNext={onNext} colSpan={9} isLoading={isLoading} hasMore={hasMore} />
      }
    >
      {map(summary => {
        return (
          <Row key={summary.id} className="pointer" onClick={() => onRowClick(summary.id)}>
            <Cell>{summary.memberId}</Cell>
            <Cell>{summary.memberName}</Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={summary.flatContributions} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={summary.contributions} />
            </Cell>
            <Cell align="right">
              <Percent value={summary.dividendPercentage} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={summary.grossDividends} />
            </Cell>
            <Cell align="right">
              <Percent value={report?.witholdingTaxPercentage} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={summary.taxesWitheld} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={summary.netDividends} />
            </Cell>
          </Row>
        );
      }, contributionSummaries)}
    </Table>
  );
}
