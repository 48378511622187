import React from 'react';

import OverviewList from '../../../../../components/OverviewList';

import { MpesaTable } from './Table';

export const MpesaList = ({
  onFilterChange,
  filters,
  filterValue,
  onSearchChange,
  searchValue,
  totalResults,
  transactions,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  refetch,
  fetchNextPage,
  ...rest
}) => {
  return (
    <OverviewList
      onFilterChange={onFilterChange}
      filterBy={filterValue}
      filters={filters}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      searchPlaceholderId="Finance.Mpesa.List.searchPlaceholder"
      labelId="Finance.Mpesa.List.label"
      totalNumResults={totalResults || transactions.length}
      items={transactions}
      table={
        <MpesaTable
          transactions={transactions}
          {...rest}
          hasMore={hasNextPage}
          isLoading={isFetchingNextPage || isLoading}
          onRefetch={refetch}
          onNext={fetchNextPage}
        />
      }
    />
  );
};
