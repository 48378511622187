import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconSavingsElement = React.ElementRef<'svg'>;
interface IconSavingsPropTypes extends PrimitiveSvgPropTypes {}

export const IconSavings = React.forwardRef<IconSavingsElement, IconSavingsPropTypes>(function IconSavings(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5C18 3.3126 16.6581 2.05416 15.1041 1.27715C13.4902 0.470229 11.3307 0 9 0C6.66928 0 4.50977 0.470229 2.89593 1.27715C1.34191 2.05416 0 3.3126 0 5V15C0 16.6874 1.34191 17.9458 2.89593 18.7229C4.50977 19.5298 6.66928 20 9 20C11.3307 20 13.4902 19.5298 15.1041 18.7229C16.6581 17.9458 18 16.6874 18 15V5ZM2.12992 12.1529C2.40214 11.6724 3.01238 11.5035 3.49291 11.7757C4.77337 12.5011 6.738 13 9.00006 13C11.2621 13 13.2267 12.5011 14.5072 11.7757C14.9877 11.5035 15.598 11.6724 15.8702 12.1529C16.1424 12.6334 15.9736 13.2436 15.493 13.5159C13.8417 14.4514 11.5168 15 9.00006 15C6.48328 15 4.15841 14.4514 2.50709 13.5159C2.02656 13.2436 1.85769 12.6334 2.12992 12.1529ZM15.4929 8.51588C15.9735 8.24365 16.1423 7.63342 15.8701 7.15289C15.5979 6.67235 14.9877 6.50349 14.5071 6.77571C13.2267 7.50111 11.262 8 8.99998 8C6.73792 8 4.77329 7.50111 3.49283 6.77571C3.0123 6.50349 2.40207 6.67235 2.12984 7.15289C1.85762 7.63342 2.02648 8.24365 2.50702 8.51588C4.15833 9.45136 6.48321 10 8.99998 10C11.5168 10 13.8416 9.45136 15.4929 8.51588Z"
        fill="#475569"
      />
    </svg>
  );
});

IconSavings.defaultProps = {
  width: '25',
  height: '24'
};
