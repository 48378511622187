import { DateRangePicker } from './components/DateRangePicker';
import { Filter } from './components/Filter';

export function steps(isMemberStatement: boolean = false) {
  return {
    date: {
      titleId: isMemberStatement ? 'MemberDetail.memberstatement.filters' : 'MemberDetail.memberstatement.dateRange',
      subtitleId: 'MemberDetail.datepicker',
      actions: [{ appearsAs: 'print', behavesAs: 'complete' }],
      children: [
        {
          titleId: isMemberStatement
            ? 'MemberDetail.memberstatement.filters'
            : 'MemberDetail.memberstatement.dateRange',
          Component: isMemberStatement ? Filter : DateRangePicker,
          validate: isMemberStatement ? Filter.validate : DateRangePicker.validate
        }
      ]
    }
  };
}
