import { SequentialStepStepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import Notes from './components/Notes/Notes';

export const steps: SequentialStepStepConfig = {
  notes: {
    titleId: 'LoanTopup.Undo.Notes.LeftNavigation.title',
    subtitleId: 'LoanTopup.Undo.Notes.LeftNavigation.subtitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanTopup.Undo.Notes.title',
        Component: Notes.Component,
        validate: Notes.validate
      }
    ]
  }
};
