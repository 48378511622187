import * as React from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/fp/get';

import { LogoLayout } from '@kwara/components/src/LogoLayout';

import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';
import { ScanQR } from './steps/ScanQr';
import { ConfirmQr } from './steps/ConfirmQr';
import { Success } from './steps/Success';
import { reducer, initialState } from './reducer';
import { useAuth } from '../../hooks';

export const Auth2faActivation = ({ onDismiss }: { onDismiss: () => void }) => {
  const auth = useAuth();
  const history = useHistory();
  const [data, setData] = React.useState(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const store = useSaccoProfileContext();

  const goHome = React.useCallback(() => history.push('/'), [history]);

  const on2faDismiss = React.useCallback(() => {
    onDismiss();
    goHome();
  }, [goHome, onDismiss]);

  React.useEffect(() => {
    const getQrData = async () => {
      const { src, secret } = await auth.getQrCode();
      setData({ src, secret });
    };

    getQrData();
  }, [auth]);

  const renderStep = React.useCallback(
    step => {
      switch (step) {
        case 'scan':
          return (
            <ScanQR
              qr={get('src', data)}
              secret={get('secret', data)}
              onDismiss={on2faDismiss}
              onNext={() => dispatch({ type: 'ON_SCAN' })}
            />
          );
        case 'confirm':
          return (
            <ConfirmQr
              onSuccess={() => dispatch({ type: 'ON_CONFIRMED' })}
              onBack={() => dispatch({ type: 'ON_BACK_TO_QR' })}
            />
          );
        case 'success':
          return (
            <Success
              onDone={() => {
                store.refetch();
                goHome();
              }}
            />
          );
        default:
          return null;
      }
    },
    [store, data, goHome, on2faDismiss]
  );

  return (
    <LogoLayout classNames="pa4">
      <div className="flex flex-column">{renderStep(state.step)}</div>
    </LogoLayout>
  );
};
