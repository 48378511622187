import * as React from 'react';

import Asset from '@kwara/components/src/Asset';
import { Text } from '@kwara/components/src/Intl';

import { useWizardInternalContext } from '../..';
import { ConfirmCancel } from './components/ConfirmCancel';
import { AccessibleIcon } from '../../../../AccessibleIcon/AccessibleIcon';

import styles from './index.module.scss';

export function WizardHeader() {
  const { titleId, onCancel, cancel, onRemain, data, showConfirmCancel } = useWizardInternalContext();

  return (
    <>
      <header className={styles['wizard-header']}>
        <h1 className="kw-weight-bold kw-text-large">
          <Text id={titleId} values={data} />
        </h1>
        <AccessibleIcon label="Click to close view">
          <span role="button" onClick={onCancel} data-testid="wizardCloseBtn">
            <Asset id={Asset.Glyphs.CrossCircle} className="my-custom-class" col={Asset.Colours.black} />
          </span>
        </AccessibleIcon>
      </header>
      <ConfirmCancel shown={showConfirmCancel} onRemain={onRemain} onCancel={cancel} />
    </>
  );
}
