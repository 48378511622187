import React from 'react';
import { Form } from 'react-final-form';

import { LoanProductType } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedCombobox } from '@kwara/components/src/Form';

type Payload = { filters: { loan_product_id?: string | null } };

type Props = {
  onGenerate(data: Payload): void;
  products?: LoanProductType[];
};
export function CreateForm({ onGenerate, products = [] }: Props) {
  const comboboxData = products.map(product => ({ value: product.id, label: product.name }));

  return (
    <Form
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      render={({ form, handleSubmit }) => {
        return (
          <form
            className="w-100 flex justify-left items-center"
            onSubmit={handleSubmit}
            aria-label="Create Guarantor Listing Report Form"
          >
            <div className="dib mr3">
              <SubscribedCombobox
                compact
                margin={false}
                size="medium"
                placeholderId="GuarantorListingReports.Form.products.option.default"
                name="filters.loan_product_id"
                data={comboboxData}
              />
            </div>

            <Button type="primary" onClick={form.submit}>
              <Text id="GuarantorListingReports.Form.submit.generate" />
            </Button>
          </form>
        );
      }}
    ></Form>
  );
}
