import React from 'react';

import { PasswordExpiryAlerterImp } from './PasswordExpiryAlerterImp';
import { PasswordExpiryAlerter } from './PasswordExpiryAlerter';

type ListenPasswordExpiryProps = {
  children: React.ReactNode;
};

export function PasswordExpiryListener(props: ListenPasswordExpiryProps) {
  return (
    <PasswordExpiryAlerterImp>
      <PasswordExpiryAlerter>{props.children}</PasswordExpiryAlerter>
    </PasswordExpiryAlerterImp>
  );
}
