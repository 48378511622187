import { Configure } from './components/Configure';
import { Summary } from './components/Summary';
import { Loans } from '../Loan/LoanAdd/components/Loans';
import { Disbursement } from '../Loan/LoanAdd/components/Disbursement';
import { Security } from '../Loan/LoanAdd/components/Security';

export const steps = {
  configure: {
    titleId: 'LoanAdd.Configure.shortTitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'summary' }],
    children: [
      {
        titleId: 'LoanRefinance.Configure.shortTitle',
        Component: Configure,
        validate: Configure.validate
      },
      {
        titleId: 'LoanRefinance.Loans.title',
        Component: Loans,
        validate: Loans.validate
      },
      // TODO: Wire up Refinance endpoint with new remittance models
      // [ch19998]
      // {
      //   titleId: 'LoanAdd.Configure.RepaymentMode.step.title',
      //   Component: Repayment,
      //   validate: Repayment.validate
      // },
      {
        titleId: 'LoanAdd.Disbursement.title',
        subtitleId: 'LoanAdd.Disbursement.subtitle',
        Component: Disbursement,
        validate: Disbursement.validate
      },
      {
        titleId: 'LoanAdd.Security.title',
        subtitleId: 'LoanAdd.Security.subtitle',
        Component: Security,
        validate: Security.validate
      }
    ]
  },
  summary: {
    titleId: 'LoanAdd.Summary.shortTitle',
    Component: Summary,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
