import { attr } from 'spraypaint';
import Base, { BaseModel } from '@kwara/models/src/models/Base';

export const DividendContributionSummary = Base.extend({
  static: {
    jsonapiType: 'dividend_contribution_summaries'
  },
  attrs: {
    id: attr(),
    memberId: attr(),
    memberName: attr(),
    flatContributions: attr(),
    contributions: attr(),
    dividendPercentage: attr(),
    grossDividends: attr(),
    taxesWitheld: attr(),
    netDividends: attr(),
    monthByMonth: attr()
  }
});

export function DividendContributionSummaries(reportId: string) {
  return Base.extend({
    static: {
      endpoint: `/dividends/reports/${reportId}/contributions`
    }
  });
}

type MonthBreakdownDataT = {
  date: string;
  value: string;
  weighted_contributions: string;
  gross_dividends: string;
  taxes_withheld: string;
  net_dividends: string;
};

export interface DividendContributionSummaryT extends BaseModel<DividendContributionSummaryT> {
  id: string;
  memberId: string;
  memberName: string;
  flatContributions: string;
  contributions: string;
  dividendPercentage: number;
  grossDividends: string;
  taxesWitheld: string;
  netDividends: string;
  monthByMonth: MonthBreakdownDataT[];
}
