import React from 'react';
import noop from 'lodash/fp/noop';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { LinkButton } from '@kwara/components/src/LinkButton';

import ModelErrorBanner from '../../../../components/ModelErrorBanner';

import { ToggleBar } from '../../../../components/ToggleBar';
import { useContactActions, ContactPropTypes } from './useContactActions';
import { useAuth } from '../../../../hooks';

type Props = ContactPropTypes;

export function Contact(props: Props) {
  const auth = useAuth();

  const { size, StackChild, PhoneField, TextField } = props;
  const isV1 = auth.isV1();

  const {
    showSecondaryNumber,
    isPrimaryNoInUse,
    isSecondaryNoInUse,
    peopleUsingPrimaryNo,
    peopleUsingSecondaryNo,
    composedError,
    mBankingChecked,
    isKBS,
    getName,
    onVerifyPhoneNumber,
    onSubscribeToMbanking,
    onChangePrimaryPhoneNumber,
    onChangeSecondaryPhoneNumber,
    onShowAddSecondaryPhoneNumber
  } = useContactActions(props);

  return (
    <StackChild size={size}>
      <If
        condition={composedError.hasError}
        do={
          <div className="mb3" data-testid="errorBanner">
            <ModelErrorBanner state="warning" errors={composedError.errors} />
          </div>
        }
      />
      <PhoneField
        required
        name={getName('phoneNumber')}
        error={isPrimaryNoInUse}
        onBlur={isV1 ? noop : onVerifyPhoneNumber}
        labelId="AddMember.Contact.Mobile.label"
        errorBaseId="AddMember.Contact.Mobile"
        info={<Text id="AddMember.Contact.Mobile.info" />}
        infoId={isPrimaryNoInUse ? 'AddMember.Contact.Mobile.error.phoneNumberIsTaken' : null}
        errorValues={{ memberName: peopleUsingPrimaryNo?.join(', ') }}
        onChange={isV1 ? noop : onChangePrimaryPhoneNumber}
      />
      <If
        condition={showSecondaryNumber}
        do={
          <TextField
            name={getName('secondaryPhoneNumber')}
            error={isSecondaryNoInUse}
            labelId="AddMember.Contact.SecondaryNumber.label"
            placeholderId="AddMember.Contact.SecondaryNumber.placeholder"
            infoId={isSecondaryNoInUse ? 'AddMember.Contact.Mobile.error.phoneNumberIsTaken' : null}
            errorValues={{ memberName: peopleUsingSecondaryNo?.join(', ') }}
            onChange={isV1 ? noop : onChangeSecondaryPhoneNumber}
          />
        }
        else={
          <div className="mb2">
            <LinkButton as="button" onClick={onShowAddSecondaryPhoneNumber}>
              <Text id="AddMember.Contact.Mobile.addSecondary" />
            </LinkButton>
          </div>
        }
      />
      <TextField
        name={getName('email')}
        labelId="AddMember.Contact.Email.label"
        placeholderId="AddMember.Contact.Email.placeholder"
      />
      <If
        condition={isKBS}
        do={
          <ToggleBar
            name="subscribedToMbanking"
            titleId="AddMember.Contact.subscribedToMbanking.label"
            className="bg-grey-100"
            checked={mBankingChecked}
            onChange={onSubscribeToMbanking}
          />
        }
      />
    </StackChild>
  );
}

Contact.validate = {
  phoneNumber: {
    isRequired: () => true,
    phoneNumber: true
  },
  secondaryPhoneNumber: {
    phoneNumber: true
  },
  email: {
    isRequired: () => false,
    email: true
  }
};
