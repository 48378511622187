// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import Empty from '@kwara/components/src/Empty';
import createValidator from '@kwara/lib/src/validator';
import { useMemberSavings, useLoanExtras } from '@kwara/models/src/models/request/hooks';
import { type LoanType, type SavingType, type LoanV2Type } from '@kwara/models/src';
import { DELETE } from '@kwara/models/src/models/LoanV2';
import { SubscribedSelectField } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { Grid } from '../../../../components/Grid';
import { SubmissionBanner } from './SubmissionBanner';

const Option = SubscribedSelectField.Option;

const includes = ['product'];
export const SettlementAccount = ({ loan }: { loan: LoanType }) => {
  const r1 = useMemberSavings(loan.member?.id, includes);
  const r2 = useLoanExtras(loan.id);

  return (
    <Loadable {...r1}>
      <Loadable {...r2}>
        <SettlementAccountBody loan={r2.data} savings={r1.data} />
      </Loadable>
    </Loadable>
  );
};

const useSettlementForm = (loan: LoanV2Type) => {
  const savingId = get('linkedSettlementAccount.id', loan) || DELETE;
  const [currentAccount, setCurrentAccount] = React.useState(savingId);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    setCurrentAccount(savingId);
  }, [savingId]);

  const resetBanners = () =>
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 1000);

  const onSubmit = async ({ loan, linkedSettlementAccount }) => {
    try {
      await loan.setSettlementAccount(linkedSettlementAccount);
      setCurrentAccount(linkedSettlementAccount);
      setSuccess(true);
    } catch {
      setError(true);
    } finally {
      resetBanners();
    }
  };

  const validate = React.useMemo(
    () =>
      createValidator({
        linkedSettlementAccount: {
          custom: target => target !== currentAccount
        }
      }),
    [currentAccount]
  );

  return {
    error,
    success,
    currentAccount,
    onSubmit,
    validate
  };
};

export const SettlementAccountBody = ({ loan, savings }: { loan: LoanV2Type, savings: SavingType[] }) => {
  const { linkableSavingsProductId } = loan.product;

  const { validate, currentAccount, onSubmit, error, success } = useSettlementForm(loan);

  const eligibleSavings = linkableSavingsProductId
    ? filter(saving => saving.product.id === linkableSavingsProductId, savings)
    : savings;

  if (!loan.product.accountLinkingEnabled) {
    return (
      <section>
        <Empty>
          <Text id="LoanDetail.SettlementAccount.notEnabled" />
        </Empty>
      </section>
    );
  }

  if (isEmpty(eligibleSavings)) {
    return (
      <section>
        <Empty>
          <Text id="LoanDetail.SettlementAccount.notEligible" />
        </Empty>
      </section>
    );
  }

  return (
    <section>
      <Form
        validate={validate}
        initialValues={{
          linkedSettlementAccount: currentAccount,
          loan
        }}
        onSubmit={onSubmit}
        render={({ form, submitting, invalid }) => {
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                form.submit();
              }}
            >
              <Grid columns={2} width="w-50" border={false}>
                <SubscribedSelectField name="linkedSettlementAccount" showInfo={false}>
                  <Option value={DELETE} translationId="LoanDetail.SettlementAccount.option.noAccount" />
                  {map(
                    saving => (
                      <Option key={saving.id} value={saving.id}>
                        {saving.name} ({saving.id})
                      </Option>
                    ),
                    eligibleSavings
                  )}
                </SubscribedSelectField>
                <Button
                  type="primary"
                  size="medium"
                  isSubmit
                  aria-label="Save"
                  disabled={invalid || submitting}
                  glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                >
                  <Text id="LoanDetail.SettlementAccount.submit" />
                </Button>
              </Grid>

              <SubmissionBanner error={error} success={success} />
            </form>
          );
        }}
      />
    </section>
  );
};
