import React from 'react';

import zIndices from '@kwara/lib/src/zIndices';
import Banner from '@kwara/components/src/Banner';
import { If } from '@kwara/components/src/If/If';
import { useNotificationContext } from '@kwara/components/src/Notification/NotificationProvider';

import styles from './index.module.scss';

export function NotificationBanner() {
  const { current, dismiss } = useNotificationContext();

  return (
    <If
      condition={!!current}
      do={
        <Banner
          className={`br0 ${styles.banner} ${zIndices.Max}`}
          innerClassName="flex justify-between items-center"
          text={current?.message}
          state={current?.level}
          onDismiss={dismiss}
        />
      }
    />
  );
}
