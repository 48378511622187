import * as React from 'react';
import { UserT } from '@kwara/models/src';

import { useWaitingCursor } from '../../../../hooks';

export function useTeamList(refetch: Function) {
  const { onWaitingStart, onWaitingEnd } = useWaitingCursor();
  const onDeactivate = React.useCallback(
    async function onDeactivate(user: UserT) {
      onWaitingStart();
      try {
        await user.deactivate();
        await refetch();
      } finally {
        onWaitingEnd();
      }
    },
    [refetch, onWaitingStart, onWaitingEnd]
  );

  const onReactivate = React.useCallback(
    async function onReactivate(user: UserT) {
      onWaitingStart();
      try {
        await user.reactivate();
        await refetch();
      } finally {
        onWaitingEnd();
      }
    },
    [refetch, onWaitingStart, onWaitingEnd]
  );

  return { onReactivate, onDeactivate };
}
