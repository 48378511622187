import { useQuery } from 'react-query';

import { ApiError, WhereT, IncludesT } from 'GlobalTypes';

import { MemberLoan } from '@kwara/models/src';
import { LoanType } from '@kwara/models/src/models/Loan';

type OptionsT = { enabled?: boolean; where?: WhereT; includes?: IncludesT };

export async function fetchLoans({ queryKey }) {
  const [_, id, where, includes] = queryKey;

  const res = await MemberLoan(id)
    .where(where)
    .includes(includes)
    .all();
  return res.data;
}

const defaultWhere = {};
const defaultIncludes = [];
export const useLoansForMember = (memberId: string, opts: OptionsT = {}) => {
  const { enabled = true, where = defaultWhere, includes = defaultIncludes } = opts;
  return useQuery<LoanType[], ApiError>(['member_loans_v2', memberId, where, includes], fetchLoans, {
    retry: (failCount, error) => ((error as any).status === 404 ? false : failCount < 3),
    enabled: enabled,
    placeholderData: []
  });
};
