import * as React from 'react';
import get from 'lodash/fp/get';

import { Text, Currency } from '@kwara/components/src/Intl';
import { LoanFormData, GuaranteeType as GuarantorType } from '@kwara/components/src/pages/LoanAdd';

function getTableTotals(guarantors: GuarantorType[]) {
  const initialState = {
    amountToGuarantee: 0,
    mainSavings: 0
  };

  if (!guarantors.length) return initialState;

  const tableTotals = guarantors.reduce(
    (prev: typeof initialState, curr) => {
      prev = {
        amountToGuarantee: Number(prev.amountToGuarantee) + Number(curr.amount ?? 0),
        mainSavings: Number(prev.mainSavings) + Number(curr.member.totalSavings ?? 0)
      };
      return prev;
    },
    { ...initialState }
  );

  return tableTotals;
}

const TITLES = [
  { id: 't01', name: <Text id="Guarantor.Table.Title.member" />, width: '27%' },
  { id: 't02', name: <Text id="Guarantor.Table.Title.mainSavings" />, width: '28%' },
  { id: 't03', name: <Text id="Guarantor.Table.Title.amountToGuarantee" />, width: '28%' },
  { id: 't04', name: null, width: '17%' }
];

const BASES = (totalMainSavings: number, totalAmountToGuarantee: number) => [
  { id: 'b01', name: <Text id="Guarantor.Table.Footer.totalAmount" />, width: '27%' },
  { id: 'b02', name: <Currency format="currency" value={totalMainSavings} />, width: '28%' },
  { id: 'b03', name: <Currency format="currency" value={totalAmountToGuarantee} />, width: '28%' },
  { id: 'b04', name: null, width: '17%' }
];

function amountKey(index: number) {
  return `guarantors[${index}].amount`;
}

function canGuaranteeUpToKey(index: number) {
  return `guarantors[${index}].canGuaranteeUpTo`;
}

function validate({ guarantors }: LoanFormData) {
  const validations = {};

  if (guarantors != null) {
    guarantors.forEach((_, index: number) => {
      validations[amountKey(index)] = {
        isRequired: (_, allData: LoanFormData) => !get(`guarantors.[${index}].isMarkedForDestruction`, allData),
        currency: true,
        nonZero: true,
        custom(target: string, allData: LoanFormData) {
          if (Number(target) > get(`guarantors.[${index}].canGuaranteeUpTo`, allData)) {
            return 'overCanGuarantee';
          }
          return null;
        }
      };

      validations[canGuaranteeUpToKey(index)] = {
        isRequired(_: string, allData: LoanFormData) {
          const isAmountInputNeedToFilled =
            !get(`guarantors.[${index}].isMarkedForDestruction`, allData) &&
            !!get(`guarantors.[${index}].canGuaranteeUpTo`, allData);

          return isAmountInputNeedToFilled;
        }
      };
    });
  }
  return validations;
}

export { getTableTotals, TITLES, BASES, canGuaranteeUpToKey, validate };
