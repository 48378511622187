import { TransactionChannels } from '@kwara/models/src/models/Transactions';

import { useTillUIContext } from './TillProvider';

// The business logic is as follows:
// - if till is open and the transaction is cash, send the glAccountCode of the till. (and not the bankGlId)
// - otherwise, send the user-selected bankGlId. (and not the glAccountCode)
export function useTransactionFormWithTill(baseFn) {
  const { currentTillSession, isTillOpen, isTillLoading, refetchTillData } = useTillUIContext();

  const addTransaction = async data => {
    if (data.method === TransactionChannels.cash && isTillOpen) {
      delete data.bankGlId;
      data.glAccountCode = currentTillSession.till.glAccountId;
      await baseFn(data);
    } else {
      delete data.glAccountCode;
      await baseFn(data);
    }
    refetchTillData();
  };

  return { addTransaction, isTillOpen, isTillLoading };
}
