import React, { useCallback } from 'react';

import { Form } from 'react-final-form';

import { CheckableItem } from '@kwara/models/src/models/CheckableItem';

import { CheckableList } from './List';

export const CheckableJournalEntries = ({ refetch, ...props }) => {
  const submitReview = useCallback(
    async data => {
      await CheckableItem.review(data);
      await refetch();
    },
    [refetch]
  );

  return (
    <Form onSubmit={submitReview} render={formProps => <CheckableList {...formProps} refetch={refetch} {...props} />} />
  );
};
