import { useEffect, useState } from 'react';
import find from 'lodash/fp/find';

import { LoanApplicationT } from '@kwara/models/src/models/LoanApplication';
import { LoanAttachmentT } from '@kwara/models/src/models/LoanAttachment';

export const useAttachment = (id: string | null, attachments: LoanAttachmentT[], loanApplication: LoanApplicationT) => {
  const [attachment, setAttachment] = useState({});

  const persisted = find(att => att.id === id, attachments);

  useEffect(() => {
    // if the attachment is already there just display it.
    if (persisted && persisted.content) {
      return setAttachment(persisted);
    }

    // otherwise just make a new request and retrieve it server side
    if (id && loanApplication) {
      loanApplication.getAttachmentFromId(id).then((att: LoanAttachmentT) => setAttachment(att));
    }

    setAttachment({});
  }, [id, persisted, loanApplication]);

  return [attachment] as LoanAttachmentT[];
};
