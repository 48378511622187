import { IncludesT } from 'GlobalTypes';

import { PendingLoanDisbursement } from '@kwara/models/src/models/LoanDisbursement';

import { useFilterContext } from '../../../../../components/Filter';
import { FILTER_TYPES } from '../../../../../components/Filter/utils/filterTypes';
import { DATE_NAMES } from '../../../../../components/Filter/pages/DateBaseFilter/useDateBaseFilter';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../../../../models/hooks/base';

const GET_PENDING_LOAN_DISBURSEMENTS_QUERY_KEY = 'pending_loan_disbursements';

const defaultPendingLoanDisbursementsIncludes = ['loan', 'user.branch', 'loan_application', 'appraisals.user'];
const select = {
  loan_applications: ['amount', 'requested_amount', 'recommended_amount', 'approved_by', 'created_by']
};

function fetchPendingLoanDisbursements(opts: PaginationQueryType) {
  return fetchWithPagination(PendingLoanDisbursement, opts);
}

function useFilter() {
  const { getValueFor } = useFilterContext();

  const status = getValueFor(FILTER_TYPES.LOAN_DISBURSEMENT_STATUS);
  const filter = {
    filterValue: status as string,
    where: {
      state: status,
      branch_id: getValueFor(FILTER_TYPES.BRANCH_ID),
      search: getValueFor(FILTER_TYPES.LOAN_DISBURSEMENT_SEARCH),
      from: getValueFor(FILTER_TYPES.LOAN_DISBURSEMENT_DATE, DATE_NAMES.FROM),
      to: getValueFor(FILTER_TYPES.LOAN_DISBURSEMENT_DATE, DATE_NAMES.TO)
    }
  };

  return filter;
}

function usePaginatedPendingLoanDisbursements(includes: IncludesT = defaultPendingLoanDisbursementsIncludes) {
  return useOverviewList({
    queryKey: GET_PENDING_LOAN_DISBURSEMENTS_QUERY_KEY,
    fetchFn: fetchPendingLoanDisbursements,
    includes,
    select,
    useWhere: useFilter
  });
}

export { GET_PENDING_LOAN_DISBURSEMENTS_QUERY_KEY, usePaginatedPendingLoanDisbursements };
