import * as React from 'react';
import get from 'lodash/fp/get';

import { BankSelect, BranchSelect } from '@kwara/components/src/Form';
import {  SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import styles from "./index.module.scss"

function BankForm({ TextField, formProps }: SubStepComponentProps<{ [key: string]: any }>) {
  const bankId = get('bankBranch.bank.id', formProps.values as Record<string, any>) as unknown as string

  return (
    <div className={styles.container}>
      <BankSelect name="bankBranch.bank.id" labelId={null} />
      <BranchSelect name="bankBranch.id" bankId={bankId} labelId={null} />
      <TextField required name="accountNumber" placeholderId="AddMember.Bank.account.label" />
    </div>
  );
}

export default {
  Component: BankForm,
  validate: {
    'bankBranch.bank.id': { isRequired: () => true },
    'bankBranch.id': { isRequired: () => true },
    accountNumber: { isRequired: () => true }
  }
};
