import * as React from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

type PrimitiveDivPropTypes = React.ComponentPropsWithoutRef<'div'>;
type CardElement = React.ElementRef<'div'>;
interface CardPropTypes extends PrimitiveDivPropTypes {}

export const Card = React.forwardRef<CardElement, CardPropTypes>(function Card(
  { className, ...restProps },
  forwardedRef
) {
  return <div {...restProps} ref={forwardedRef} className={cx(styles['container'], className)} />;
});
