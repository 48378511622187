import fpMap from 'lodash/fp/map';
import { Member } from '@kwara/models/src';

type Item = Record<string, any>;
type EntityType = 'members' | 'loans';

/**
 * Store
 */
class Store {
  private store: { [key: string]: Item };
  private isLocalStorage: boolean;

  constructor(useLocalStorage = false) {
    this.store = useLocalStorage ? localStorage : {};
    this.isLocalStorage = useLocalStorage;
  }

  setItem(type: EntityType, item: Item): void {
    this.store[`${type}_${item.id}`] = item;
  }

  setItems(type: EntityType, items: ReadonlyArray<Item>): void {
    fpMap((i: Item) => this.setItem(type, i), items);
  }

  getItem(type: EntityType, id: string): Item | Member {
    const result = this.store[`${type}_${id}`];

    if (result) return result;

    if (type === 'members') {
      const m = new Member({ id });

      m.isPersisted = true;

      return m;
    }

    return {};
  }
}

/**
 * clientCache
 */
interface ClientCache {
  _name: string;
  add: (type: EntityType, item: Item) => void;
  bulkAdd: (type: EntityType, items: Item[]) => void;
  read: (type: 'members', id: string) => Item | Member;
}

function clientCache(name: string, store: Store): ClientCache {
  return {
    _name: name,
    add(type: EntityType, item: Item): void {
      store.setItem(type, item);
    },
    bulkAdd(type: EntityType, items: Item[]): void {
      store.setItems(type, items);
    },
    read(type: 'members', id: string): Item | Member {
      return store.getItem(type, id);
    }
  };
}

export const ClientCache = clientCache('default', new Store());
