import React from 'react';
import cx from 'classnames';
import noop from 'lodash/fp/noop';

import { MenuItem } from 'rc-menu';
import { MenuItemProps } from 'rc-menu/lib/MenuItem';

import 'rc-menu/assets/index.css';

import Actionable from '@kwara/components/src/Actionable';

import { If } from '@kwara/components/src/If/If';

import styles from './index.module.scss';

interface ItemPropTypes extends MenuItemProps {
  name?: string;
  to?: string | { pathname: string; search: string };
}

export function Item({ className, to, children, disabled, ...rest }: ItemPropTypes) {
  return (
    <MenuItem
      disabled={disabled}
      onItemHover={noop}
      onClick={noop}
      className={cx(className, styles['menu-item'])}
      {...rest}
    >
      <If
        condition={to != undefined}
        do={
          <Actionable to={to} disabled={disabled}>
            {children}
          </Actionable>
        }
        else={<span>{children}</span>}
      />
    </MenuItem>
  );
}
