//@flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { Name } from './components/Name';
import { Contact } from './components/Contact';
import { Identity } from './components/Identity';
import { Address } from './components/Address';
import { Personal } from './components/Personal';

import { NextOfKin } from '../../../../../../MemberAdd/components/NextOfKin';

function PersonalDetailsHeader() {
  return <Text id="MemberDetail.personalDetails" />;
}

function PersonalDetailsBody(props) {
  return (
    <>
      <Name {...props} size="stretch" />
      <Contact {...props} />
      <Identity {...props} />
      <Personal {...props} />
      <Address {...props} />
      <NextOfKin {...props} size="stretch" name="member.nextOfKins" />
    </>
  );
}

export default {
  Title: PersonalDetailsHeader,
  Body: PersonalDetailsBody,
  validate: {
    ...Personal.validate,
    ...Identity.validate,
    ...Address.validate,
    ...Contact.validate,
    ...Name.validate
  }
};
