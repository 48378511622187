import * as React from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { If } from '@kwara/components/src/If/If';
import { LoanType, SavingType } from '@kwara/models/src';
import { Link } from '@kwara/components/src/Link';

import { useSavingsForMember } from '../../../../../hooks';
import { currency } from './LoanDetails';

function getMainSavingAccountBalance(memberSavingAccounts: SavingType[]) {
  const mainSavings = find(saving => saving.product.isDefaultSavingsProduct, memberSavingAccounts);
  return get('balance', mainSavings);
}

export function getAttributeRowsForLoan(loan: LoanType) {
  const loanAttributesRow = [
    {
      title: (
        <div>
          <span className="kw-text-small kw-weight-regular mr2">{loan.member.id}</span>
          <StatusTag state={get('member.state.current', loan)} size="small" />
        </div>
      ),
      value: (
        <Link type="primary" target="_blank" underline={false} to={`/members/${loan.member.id}`}>
          {loan.member.fullName()}
        </Link>
      )
    },
    {
      ariaLabel: 'Applied amount',
      title: <Text id="LoanAppraisal.Overview.appliedAmount" />,
      value: currency(loan.amount)
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.Overview.loanProduct" />,
      value: loan.name
    }
  ];

  return loanAttributesRow;
}
function getAttributeRows(loan: LoanType, memberSavingAccounts: SavingType[], isFetchingMemberSavingAccounts: boolean) {
  const loanAttributesRow = getAttributeRowsForLoan(loan);

  const attributesRow = [
    ...loanAttributesRow,
    {
      title: <Text id="LoanDisbursal.LoanDetails.Overview.mainSavings" />,
      value: (
        <If
          condition={isFetchingMemberSavingAccounts}
          do={<LoadingSkeleton />}
          else={memberSavingAccounts && currency(getMainSavingAccountBalance(memberSavingAccounts))}
        />
      )
    }
  ];

  return { attributesRow };
}
export function useLoanDetailsData(loan: LoanType) {
  const { data: memberSavingAccounts, isFetching: isFetchingMemberSavingAccounts } = useSavingsForMember(
    loan.member.id,
    {
      state: 'ACTIVE,LOCKED,DORMANT,MATURED,APPROVED'
    }
  );
  const { attributesRow: loanDetailsAttributesRow } = React.useMemo(
    () => getAttributeRows(loan, memberSavingAccounts, isFetchingMemberSavingAccounts),
    [loan, memberSavingAccounts, isFetchingMemberSavingAccounts]
  );

  return { loanDetailsAttributesRow };
}
