import { useQuery } from 'react-query';

import { OperationalReport } from '@kwara/models/src/models/OperationalReport';

function fetchOperationalReport({ queryKey }) {
  const [_, id] = queryKey;
  return OperationalReport.find(id).then(res => res.data);
}

export function useOperationalReport(id: string) {
  return useQuery(['operational_report', id], fetchOperationalReport, { enabled: !!id });
}
