/**
 * @AboutThiFile modules that builds up Tab Navigations
 *
 * @ExposedAPis :
 * 1. @isPermitted
 * 2. @generateTransactionsTabNav
 * 3. @generateMPesaTabNav
 * 4. @generateReportsTabNav
 * 5. @generateTabNavigationRoutes
 */

import { ROUTES } from '../../../../../routes';
import { Nav } from '../../../../../components/TabNavigation/components/LinkTab';
import { SaccoProfileContextType } from '../../../../../models/Profile/ProfileProvider';
import { AppPermissionsType, ToHelperType } from '../../../../../models/Permission/types';

export interface PermittedTabNavs extends Nav {
  permissions: string[];
  hide?: boolean;
}

type IsPermittedHOFNav = {
  permissions: string[];
  hide?: boolean;
  [key: string]: any;
};

/**
 * @isPermitted
 * @param to
 * @returns
 */
function isPermitted(to: ToHelperType) {
  return (nav: IsPermittedHOFNav) => !nav.hide && to(nav.permissions);
}

/**
 * @generatePermittedTabNavs
 * @param navs
 * @param to
 * @returns
 */
function generatePermittedTabNavs(navs: PermittedTabNavs[], to: ToHelperType): PermittedTabNavs[] {
  return navs.filter(isPermitted(to));
}

/**
 * @generateTransactionsTabNav generates Transactions tab navigations
 * @param to
 * @param appPermissions
 * @returns PermittedTabNavs[]
 */
function generateTransactionsTabNav(
  to: ToHelperType,
  appPermissions: AppPermissionsType,
  _store: SaccoProfileContextType
): PermittedTabNavs[] {
  return generatePermittedTabNavs(
    [
      {
        parentId: 'transactions',
        path: ROUTES.financeSavingTransactions,
        nameId: 'navigation.TabNav.Finance.Transaction.savings',
        permissions: appPermissions.ViewSavingTransactionsPage
      },
      {
        parentId: 'transactions',
        path: ROUTES.financeLoanTransactions,
        nameId: 'navigation.TabNav.Finance.Transaction.loans',
        permissions: appPermissions.ViewLoanTransactionsPage
      },
      {
        parentId: 'transactions',
        path: ROUTES.financeLoanDisbursementTransactions,
        nameId: 'navigation.TabNav.Finance.Transaction.loanDisbursements',
        permissions: appPermissions.ViewPendingLoanDisbursementsPage
      }
    ],
    to
  );
}

/**
 * @generateMPesaTabNav generates M-Pesa tab navigations
 * @param to
 * @param appPermissions
 * @returns PermittedTabNavs[]
 */
function generateMPesaTabNav(
  to: ToHelperType,
  appPermissions: AppPermissionsType,
  _store: SaccoProfileContextType
): PermittedTabNavs[] {
  return generatePermittedTabNavs(
    [
      {
        parentId: 'mPesa',
        path: ROUTES.financeC2b,
        nameId: 'navigation.TabNav.Finance.MPesa.c2b',
        permissions: appPermissions.ViewMpesaTransactions
      },
      {
        parentId: 'mPesa',
        path: ROUTES.financeB2c,
        nameId: 'navigation.TabNav.Finance.MPesa.b2c',
        permissions: appPermissions.ViewMpesaTransactions
      },
      {
        parentId: 'mPesa',
        path: ROUTES.financeStk,
        nameId: 'navigation.TabNav.Finance.MPesa.stk',
        permissions: appPermissions.ViewMpesaTransactions
      }
    ],
    to
  );
}

/**
 * @generateReportsTabNav generates Reports tab navigations
 * @param to
 * @param appPermissions
 * @returns PermittedTabNavs[]
 */
function generateReportsTabNav(
  to: ToHelperType,
  appPermissions: AppPermissionsType,
  store: SaccoProfileContextType
): PermittedTabNavs[] {
  return generatePermittedTabNavs(
    [
      {
        parentId: 'reports',
        nameId: 'navigation.TabNav.Finance.Reports.dividendsInterest',
        path: ROUTES.financeDividends,
        permissions: appPermissions.UseDividendReports,
        hide: !store.dividendsFeatureLive
      },
      {
        parentId: 'reports',
        path: ROUTES.financeReports,
        nameId: 'navigation.TabNav.Finance.Reports.directDebit',
        permissions: appPermissions.ViewDirectDebitReports
      },
      {
        parentId: 'reports',
        path: ROUTES.financeLoanDisbursementReport,
        nameId: 'navigation.TabNav.Finance.Reports.disbursements',
        permissions: appPermissions.ViewLoan
      },
      {
        parentId: 'reports',
        path: ROUTES.financeChequeReports,
        nameId: 'navigation.TabNav.Finance.Reports.cheque',
        permissions: appPermissions.ViewChequeTransactions
      }
    ],
    to
  );
}

/**
 * @generateTabNavigationRoutes maps a url pathName to a Tab Navigation
 * @param to
 * @param appPermissions
 * @returns
 */
function generateTabNavigationRoutes(
  to: ToHelperType,
  appPermissions: AppPermissionsType,
  store: SaccoProfileContextType
): Record<string, PermittedTabNavs[]> {
  const transactionsNav = generateTransactionsTabNav(to, appPermissions, store);
  const mPesaNav = generateMPesaTabNav(to, appPermissions, store);
  const reportsNav = generateReportsTabNav(to, appPermissions, store);

  return {
    [ROUTES.financeSavingTransactions]: transactionsNav,
    [ROUTES.financeLoanTransactions]: transactionsNav,
    [ROUTES.financeLoanDisbursementTransactions]: transactionsNav,

    [ROUTES.financeC2b]: mPesaNav,
    [ROUTES.financeB2c]: mPesaNav,
    [ROUTES.financeStk]: mPesaNav,

    [ROUTES.financeReports]: reportsNav,
    [ROUTES.financeLoanDisbursementReport]: reportsNav,
    [ROUTES.financeChequeReports]: reportsNav,
    [ROUTES.financeDividends]: reportsNav
  };
}

export {
  generateMPesaTabNav,
  generateReportsTabNav,
  generateTransactionsTabNav,
  generateTabNavigationRoutes,
  isPermitted
};
