import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { throwError } from '@kwara/lib/src/utils/throwError';

import Head from '../../components/Head';
import { Indicators, Table } from './components/Variants';

type RowPropTypes = {
  children: React.ReactNode;
  hasTabs?: boolean;
};
/**
 * @Row used to render as many content row as possible
 */
function Row(props: RowPropTypes) {
  return <div className={`mt${props.hasTabs ? '5' : '1'} mb4`}>{props.children}</div>;
}

type BodyContentWrapperPropTypes = {
  headId?: string;
  children: React.ReactElement<RowPropTypes> | React.ReactElement<RowPropTypes>[];
};
/**
 * @BodyContentWrapper this only accepts ```Row```
 * ```component``` as it ```child```
 *
 * @Note because core is a ```back office``` system and
 * we are likely to render 2 to 3 rows per
 * page-render,the complexity of looping and ensuring
 * our UI guideline is followed is pretty optimal.
 * !But feel free to do-away with the validation when performance becomes an issue
 *
 * @Important if more than 1 page uses a design,
 * the design-component should be placed under
 * the BodyContentWrapper/components folder
 * which gets to be rendered as a Compound child of Row
 *
 * e.g:
 * ```
 *  <BodyContentWrapper.Root>
 *     <BodyContentWrapper.Row.Root>
 *       <BodyContentWrapper.Row.Indicators>
 *         <h1>Row 1</h1>
 *       </BodyContentWrapper.Row.Indicators>
 *
 *     <BodyContentWrapper.Row.Root>Row 2</BodyContentWrapper.Row.Root>
 *
 *     <BodyContentWrapper.Row.Root>Row 3</BodyContentWrapper.Row.Root>
 *     </BodyContentWrapper.Row.Root>
 *   </BodyContentWrapper.Root>
 * ```
 */
function BodyContentWrapper({ children, headId }: BodyContentWrapperPropTypes): JSX.Element {
  return React.Children.map(children as any, child => {
    const validChild = child as React.ReactElement<React.PropsWithChildren<RowPropTypes>>;

    if (!child) return;

    if (child.type === Row) {
      return (
        <>
          <If condition={!!headId} do={<Head titleId={headId} />} />

          {validChild}
        </>
      );
    }

    throwError(
      'BodyContentWrapperUsageError',
      `child of type ${child.type} is not recognized as BodyContentWrapper child`,
      BodyContentWrapper
    );
  });
}

export default {
  Root: BodyContentWrapper,
  Row: {
    Root: Row,
    Indicators,
    Table
  }
};
