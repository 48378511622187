import { useCallback } from 'react';

import { NotificationSystem, Announcement } from '../models/NotificationSystem';

export function useNotificationSystemCommands() {
  return {
    onResetNotificationSystem: useCallback(() => NotificationSystem.onResetNotificationSystem(), []),
    onDeleteAnnouncement: useCallback(
      (id: string, onDeletedSuccess: (deletedIds: string[]) => void) =>
        NotificationSystem.onDeleteAnnouncement(id, onDeletedSuccess),
      []
    ),
    onReadAnnouncement: useCallback(
      (id: string, onReadSuccess: (id: string) => void) => NotificationSystem.onReadAnnouncement(id, onReadSuccess),
      []
    ),
    onMarkAllAnnouncementAsRead: useCallback(
      (announcements: Announcement[], onMarkAllAnnouncementAsReadSuccess: (ids: string[]) => void) =>
        NotificationSystem.onMarkAllAnnouncementAsRead(announcements, onMarkAllAnnouncementAsReadSuccess),
      []
    ),
    getDeletedAnnouncementsIds: useCallback(() => NotificationSystem.getDeletedAnnouncementsIds(), [])
  };
}
