import * as React from 'react';

export function IconType() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66667 6V4.66667H10.6667V2H12V4.66667H14V6H8.66667ZM10.6667 14V7.33333H12V14H10.6667ZM4 14V11.3333H2V10H7.33333V11.3333H5.33333V14H4ZM4 8.66667V2H5.33333V8.66667H4Z"
        fill="#1E293B"
      />
    </svg>
  );
}
