import React from 'react';
import includes from 'lodash/fp/includes';

import { StandardIdDocumentTypes } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

export const isStandardIdDocumentType = (type: string): boolean =>
  includes(type?.toUpperCase?.(), StandardIdDocumentTypes);

export const getTranslationIdForIdentityType = (idType: string): string | null => {
  return isStandardIdDocumentType(idType) ? `Identity.Type.${idType}` : null;
};

export const getTextForIdentityType = (idType: string): React.ReactNode => {
  const translationId = getTranslationIdForIdentityType(idType);
  return !!translationId ? <Text id={translationId} /> : idType;
};
