import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import StatusTag from '@kwara/components/src/StatusTag';
import { Date } from '@kwara/components/src/Intl';

import Table, { Row, Heading, Cell, Footer } from '../../../../components/Table';

export const ReconciliationTable = ({ reports, ...rest }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="Finance.Reconciliations.Table.header.id" />
          <Heading translationId="Finance.Reconciliations.Table.header.glAccount" />
          <Heading translationId="Finance.Reconciliations.Table.header.start" />
          <Heading translationId="Finance.Reconciliations.Table.header.end" />
          <Heading translationId="Finance.Reconciliations.Table.header.actions" />
          <Heading translationId="Finance.Reconciliations.Table.header.status" />
        </Row>
      }
      footer={<Footer items={reports} colSpan={6} {...rest} />}
    >
      {map(report => {
        return (
          <Row key={report.id}>
            <Cell>{report.id}</Cell>
            <Cell>
              <div>{get('glAccount.name', report)}</div>
              <div className="grey-400">{report.glAccountId}</div>
            </Cell>
            <Cell>
              <Date value={report.from} />
            </Cell>
            <Cell>
              <Date value={report.to} />
            </Cell>
            <Cell>
              {report.isGenerated() ? (
                <>
                  <div>
                    <Link underline={false} href={report.reportFile} Component="a" type="primary">
                      <Text id="Finance.Reconciliations.XLSX.Report" />
                    </Link>
                  </div>
                  <div>
                    <Link underline={false} href={report.uploadFile} Component="a" type="primary">
                      <Text id="Finance.Reconciliations.XLSX.Upload" />
                    </Link>
                  </div>
                </>
              ) : null}
            </Cell>
            <Cell>
              <StatusTag state={report.state} />
            </Cell>
          </Row>
        );
      }, reports)}
    </Table>
  );
};
