// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { appName } from '@kwara/lib/src/utils';
import { type LoanProductType } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import { Currency, Duration, Percent, Text } from '@kwara/components/src/Intl';

import { Card } from '../Card';

import styles from './index.module.scss';

export const LoanProductCard = ({
  product,
  isSelected,
  isSelectable
}: {
  product: LoanProductType,
  isSelected: boolean,
  isSelectable: boolean
}) => {
  const eligibleAmount = get('eligibility.eligibleAmount', product);
  return (
    <div
      data-testid="ProductCard"
      key={product.id}
      className={`${styles.Product} ${isSelected ? styles.isSelected : ''}`}
    >
      <Card
        header={<div className={styles.Header}>{product.name}</div>}
        highlighted={isSelected}
        isHoverable={appName.isSacco}
        actionType={isSelectable ? 'select' : 'go'}
        actionLabelId="ProductSelector.action"
      >
        <Statistic
          compact
          title={<Text id="ProductSelector.maximumPrincipal" />}
          size="small"
          value={product.amount && product.amount.maximum ? <Currency value={product.amount.maximum} /> : '-'}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.memberEligibleAmount" />}
          size="small"
          value={eligibleAmount ? <Currency value={eligibleAmount} /> : '-'}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.interestRate" />}
          size="small"
          value={<Percent value={product.interestRate.percentage / 100} />}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.repaymentFrequency" />}
          size="small"
          value={product.repaymentFrequency ? <Duration value={product.repaymentFrequency} /> : '-'}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.maxDuration" />}
          size="small"
          value={product.maxDuration ? <Duration value={product.maxDuration} /> : '-'}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.guarantorsEnabled" />}
          size="small"
          value={<Text id={`ProductSelector.guarantorsEnabled.${String(product.security.guarantors_enabled)}`} />}
        />
        <Statistic
          compact
          title={<Text id="ProductSelector.collateralsEnabled" />}
          size="small"
          value={<Text id={`ProductSelector.collateralsEnabled.${String(product.security.collaterals_enabled)}`} />}
        />
      </Card>
    </div>
  );
};
