import set from 'lodash/set';
import findIndex from 'lodash/fp/findIndex';
import reject from 'lodash/fp/reject';

const formEvents = Object.freeze({
  DELETE: 'ON_DELETE',
  ADD_START: 'ON_ADD_START',
  ADD_SUCCESS: 'ON_ADD_SUCCESS',
  ERROR: 'ON_ERROR',
  EDIT_START: 'ON_EDIT_START',
  EDIT_SUCCESS: 'ON_EDIT_SUCCESS',
  CANCEL: 'ON_CANCEL',
  DISMISS_BANNER: 'ON_DISMISS_BANNER'
});

export function reducer(state, event) {
  const { type, payload } = event;

  if (type === formEvents.CANCEL) {
    return {
      ...state,
      isEditing: false,
      guarantorToEdit: null,
      errors: null
    };
  }

  if (type === formEvents.ADD_START) {
    return {
      ...state,
      isEditing: true
    };
  }

  if (type === formEvents.ADD_SUCCESS) {
    const { guarantor } = payload;

    return {
      ...state,
      isEditing: false,
      guarantees: [...state.guarantees, guarantor],
      errors: null
    };
  }

  if (type === formEvents.EDIT_START) {
    const { guarantor } = payload;

    return {
      ...state,
      isEditing: true,
      guarantorToEdit: guarantor
    };
  }

  if (type === formEvents.EDIT_SUCCESS) {
    const { guarantor } = payload;

    const copy = [...state.guarantees];

    const index = findIndex(g => g.id === guarantor.id, copy);
    set(copy, `[${index}]`, guarantor);

    return {
      ...state,
      guarantorToEdit: null,
      isEditing: false,
      guarantees: copy,
      errors: null
    };
  }

  if (type === formEvents.ERROR) {
    const { errors } = payload;

    return {
      ...state,
      errors
    };
  }

  if (type === formEvents.DELETE) {
    const { guarantor } = payload;
    return {
      ...state,
      guarantees: reject(g => g.id === guarantor.id, state.guarantees),
      banner: {
        translationId: 'LoanDetail.Security.GuarantorBanner.delete',
        values: { name: guarantor.member.fullName(), id: guarantor.member.id }
      }
    };
  }

  if (type === formEvents.DISMISS_BANNER) {
    return {
      ...state,
      banner: null
    };
  }

  return state;
}

export const initialState = guarantors => ({
  guarantees: guarantors || [],
  isEditing: false,
  errors: null
});
