// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import isArray from 'lodash/fp/isArray';
import find from 'lodash/fp/find';
import min from 'lodash/fp/min';

import { GlContexts, type SavingType } from '@kwara/models/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { SubscribedPaymentSelectField, SubscribedDatePicker, GlAccountSelect } from '@kwara/components/src/Form';
import { TransactionChannels, getTransactionTypes, contexts } from '@kwara/models/src/models/Transactions';
import { ERRORS } from '@kwara/lib/src/validator';
import { getCurrentDate, formatHumanDate, yesterday } from '@kwara/lib/src/dates';

import { type SubStepComponentProps } from '../../../components/Wizard';
import MemberPanel from '../../../components/Payment/MemberPanel';
import NotesPanel from '../../../components/Payment/NotesPanel';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';

import { DummyTillSelect } from '../../Till';

export const showBanksFieldsFor = [TransactionChannels.bankTransfer];

export const withdrawalConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.Withdrawal).values,
  showBanksFieldsFor
};

function getSelectedAccount({ accounts, accountId, saving }) {
  const selectedAccount = saving ? saving : find<SavingType>({ id: accountId }, accounts);

  return selectedAccount;
}

function getMaxWithdrawalAmounts(account: SavingType): { actual: number, available: number | string } {
  return {
    actual: account.balance,
    available: account.availableBalance()
  };
}

export const PaymentForm = ({
  SelectField,
  StackChild,
  TextField,
  data,
  addData,
  formProps
}: SubStepComponentProps<FormData>) => {
  const Option = SelectField.Option;

  const { values } = formProps;
  const { method, accountId } = values;
  const { member, accounts, saving, isTillOpen } = data;

  const isTillCashTransaction = isTillOpen && method === TransactionChannels.cash;

  const selectedAccount = getSelectedAccount({
    accounts,
    accountId,
    saving
  });

  const { actual, available } = getMaxWithdrawalAmounts(selectedAccount);

  const hasAccountSelection = isArray(accounts);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />
      <Panel>
        <div className="bb b--light-grey-400 mb4">
          {hasAccountSelection ? (
            <SelectField name="accountId" size="medium" labelId="PaymentForm.account">
              {map<SavingType, React.Element<Option>>(
                account => (
                  <Option key={account.id} value={account.id}>
                    {account.id} &mdash; {account.product.name}
                  </Option>
                ),
                accounts
              )}
            </SelectField>
          ) : null}

          {!hasAccountSelection && selectedAccount ? (
            <Statistic
              size="medium"
              title={<Text id="PaymentForm.account" />}
              value={`${selectedAccount.id} — ${selectedAccount.product.name}`}
            />
          ) : null}
        </div>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            required
            isCurrency
            type="number"
            name="amount"
            size="medium"
            leftGlyph="Currency.orgCurrency"
            labelId="PaymentForm.amount"
            info={
              <div className="kw-text-small">
                <div className="flex justify-between">
                  <Text id="MemberWithdrawal.actualBalance" values={{ actual }} />
                  <Currency value={actual} />
                </div>
                <div className="flex justify-between">
                  <Text id="MemberWithdrawal.availableBalance" />
                  <Currency value={available} />
                </div>
              </div>
            }
          />
          <SubscribedDatePicker
            name="valueDate"
            size="medium"
            labelId="SavingPayment.valueDate"
            inputPlaceholder={formatHumanDate(getCurrentDate())}
            disabledDays={{ after: yesterday() }}
          />
        </Grid>
        <SubscribedPaymentSelectField
          name="method"
          config={withdrawalConfig}
          required
          // see ch10266
          inputOnChange={() => addData({ bankGlId: '' })}
        />

        {isTillCashTransaction ? (
          <DummyTillSelect />
        ) : (
          <GlAccountSelect method={method} addData={addData} context={GlContexts.WITHDRAWAL} />
        )}

        {method ? <TextField name="reference" size="medium" labelId="PaymentForm.reference" /> : null}
      </Panel>
      <NotesPanel />
    </StackChild>
  );
};

const ifBank = {
  isRequired: (_, allData) => showBanksFieldsFor.includes(allData.method)
};

PaymentForm.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, data) => {
      const { accountId, accounts, saving } = data;

      const selectedAccount = getSelectedAccount({
        accounts,
        accountId,
        saving
      });

      const { actual, available } = getMaxWithdrawalAmounts(selectedAccount);
      const amount = Number(target);

      // Sometimes the available balance returned from the api is higher than the actual
      // See comments to this CH story https://app.clubhouse.io/getkwara/story/12420/authorization-holds-improvements#activity-12548
      // The FE should validate the with the less value as the maximum withdrawable amount.
      if (amount > min([Number(available), Number(actual)])) {
        return ERRORS.rangeOverflow;
      }

      return null;
    }
  },
  method: { isRequired: () => true },
  bankName: ifBank,
  bankBranch: ifBank,
  accountNumber: ifBank,
  ...GlAccountSelect.validate
};
