//@flow

import * as React from 'react';

import {
  MemberUploadWidget,
  addAttachment,
  removeAttachment,
  isDirty,
  allowedAttachments
} from '@kwara/components/src/MemberUploadWidget';
import { type SubStepComponentProps, StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';

interface DocumentPropTypes extends SubStepComponentProps<{}> {
  stackChildSize?: StackChildSize;
}

const fieldNames = allowedAttachments.typesByContext(['members']);

export const Documents = ({ StackChild, data, onChange, stackChildSize }: DocumentPropTypes) => {
  const { member } = data;

  return (
    <StackChild size={stackChildSize}>
      {fieldNames.map(f => (
        <MemberUploadWidget
          key={f.name}
          onChange={v => onChange(addAttachment(member, v))}
          onRemove={fileName => onChange(removeAttachment(member, fileName))}
          member={member}
          fileName={f.name}
          checked={isDirty(member.attachments, f.name)}
          textId={`DocumentUploads.${f.label}.label`}
        />
      ))}
    </StackChild>
  );
};
