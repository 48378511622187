import React from 'react';

import { useInterval } from '@kwara/models/src/models/request/hooks';

import { useOperationalReport } from './useOperationalReport';

export function usePollReportDownload() {
  const [currentReportId, setCurrentReportId] = React.useState(null);
  const { data: report, refetch } = useOperationalReport(currentReportId);

  const isPolling = !!currentReportId;

  useInterval(
    () => {
      if (!isPolling) return;
      if (!report) return;

      if (report.state === 'pending') {
        return refetch();
      }

      if (report.state === 'failed') {
        return setCurrentReportId(null);
      }
      if (report.state === 'complete') {
        setCurrentReportId(null);
        return report.download();
      }

      setCurrentReportId(null);
    },
    isPolling ? 2000 : null
  );

  return { isPolling, setCurrentReportId };
}
