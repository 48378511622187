import { useQuery } from 'react-query';

import { DeactivatedGuarantee } from '@kwara/models/src';

export { useLoanOverviewMetrics } from './useLoanOverviewMetrics';

async function getDeactivatedGuarantees({ queryKey }) {
  const [_, loanId] = queryKey;

  const scope = DeactivatedGuarantee(loanId);
  const res = await scope.includes('member').all();
  return res.data;
}

export const useDeactivatedGuarantees = (loanId: string) =>
  useQuery(['deactivated_guarantees', loanId], getDeactivatedGuarantees);
