import React from 'react';

import { OrganisationSetting } from '@kwara/models/src';
import { SubscribedTextField } from '@kwara/components/src/Form';

import { SettingsSectionForm } from '../shared';
import { usePermissions } from '../../../../../hooks';

export const SaccoSocietyNumber = ({ settings = new OrganisationSetting() }) => {
  const { permission, AppPermissions } = usePermissions();

  const permitted = permission.to(AppPermissions.UpdateOrganisationSettings);

  const onSubmit = async ({ saccoSocietyNumber }) => {
    settings.saccoSocietyNumber = saccoSocietyNumber;
    await settings.update();
  };

  return (
    <SettingsSectionForm
      titleId="Settings.Sacco.saccoSocietyNumber.title"
      onSubmit={onSubmit}
      permitted={permitted}
      initialValues={settings}
      render={() => (
        <SubscribedTextField
          disabled={!permitted}
          name="saccoSocietyNumber"
          labelId="Settings.Sacco.saccoSocietyNumber.label"
        />
      )}
    />
  );
};
