import { useMutation } from 'react-query';

import { Guarantee } from '@kwara/models/src/models/Guarantee';

import { AxiosError } from '../../services';

export type ArgTypes = {
  memberId: string;
  amount: string;
  loanId: string;
};

export function useSaveGuarantor(onSuccess?: () => void, onError?: () => void) {
  const mutation = useMutation<Record<string, any>, AxiosError, ArgTypes>({
    mutationFn({ memberId, loanId, amount }: ArgTypes) {
      return Guarantee.create({ loanId, memberId, amount });
    },
    mutationKey: ['saveGuarantor'],
    onSuccess,
    onError
  });

  return mutation;
}
