//@flow

import React, { useEffect } from 'react';
import invoke from 'lodash/fp/invoke';

import { configureApiNamespace } from '@kwara/models/src/models/Base';
import { Logger } from '@kwara/lib/src/logger';
import { LoadingKwara } from '@kwara/components/src/Loadable';
import { hasErrors } from '@kwara/models/src/models/request';
import { useBanks } from '@kwara/models/src/models/request/hooks';
import { useSegmentIdentify } from '@kwara/components/src/Segment';
import { createContext } from '@kwara/lib/src/utils/createContext';

import { store, Store } from '../../Store/Store';
import { useAuth } from '../../../hooks';
import { useProfile, EMPTY, useTransactionChannels } from '../../../hooks/useRequest';

export type SaccoProfileContextType = Store;
const [SaccoProfileProvider, useSaccoProfileContext, SaccoProfileConsumer] = createContext<SaccoProfileContextType>(
  'SaccoProfileContext',
  true
);

const includes = ['bank_branches'];

function AuthorizedProfileProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  if (auth.isV1()) {
    configureApiNamespace('/v1');
  }

  const { data, error, refetch } = useProfile();
  const banksResponse = useBanks(includes);
  const transactionChannelsResponse = useTransactionChannels();
  useSegmentIdentify(auth);

  useEffect(() => {
    const handleError = async () => {
      Logger.error('[ERROR] Error fetching /profile', JSON.stringify(error));
      await auth.logOut().then(() => (window.location.href = '/'));
    };

    if (hasErrors(error)) {
      handleError();
    }
  }, [error, auth]);

  if (hasErrors(error)) {
    return null;
  }

  if (data === EMPTY) {
    return <LoadingKwara />;
  }

  const [user] = data;

  // Update the store with valid user data
  store.updateProfile(user);
  // Update the store with the refetch user function
  store.setRefetch(refetch);

  if (!banksResponse.isLoading) {
    store.setBanks(banksResponse.data);
  }

  if (!transactionChannelsResponse.isLoading) {
    store.setTransactionChannels(transactionChannelsResponse.data);
  }

  // GoogleTagManager.addDataLayer();

  // Store the user's permissions in the Auth object
  auth.setPermissions(invoke('getPermissions', user));

  return <SaccoProfileProvider value={store}>{children}</SaccoProfileProvider>;
}

export { AuthorizedProfileProvider, useSaccoProfileContext, SaccoProfileConsumer };
