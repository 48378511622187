import * as React from 'react';
import cx from 'classnames';

import { Accordion } from '@kwara/components/src/Accordion/Accordion';

import { SubNav } from './SubNav';
import { Content } from '../Content';
import { useWithSubNav } from './useWithSubNav';
import { SubNavigation } from '../../utils/generateLeftNavigationRoutes';

import styles from '../../index.module.scss';

type WithSubNavPropTypes = {
  nameId: string;
  subNavigations: SubNavigation[];
  icon: React.ForwardRefExoticComponent<any>;
  isLeftNavigationCollapsed: boolean;
  openOnMount?: boolean;
  className?: string;
  onToggleCollapsable(): void;
  closeOnNodesMouseDown?: React.MutableRefObject<any>[];
};

export function WithSubNav({
  nameId,
  className,
  onToggleCollapsable,
  icon,
  isLeftNavigationCollapsed,
  subNavigations,
  ...restProps
}: WithSubNavPropTypes) {
  const {
    itsActiveLink,
    automaticallyOpenAccordion,
    automaticallyCloseAccordion,
    checkIsPathActive,
    onClickAccordionHandler
  } = useWithSubNav(subNavigations, isLeftNavigationCollapsed, onToggleCollapsable);

  return (
    <li className="w-100" tabIndex={0}>
      <Accordion
        {...restProps}
        defaultIcon={false}
        closeOnOutClick={false}
        closeAutomatically={automaticallyCloseAccordion}
        openAutomatically={automaticallyOpenAccordion}
        onClick={onClickAccordionHandler}
        title={
          <div className={cx({ [styles['nav-item--active']]: itsActiveLink }, className)}>
            <Content nameId={nameId} icon={icon} />
          </div>
        }
      >
        <ul className={cx('pa0 pl3 ma0 list flex flex-column justify-start', styles['withsubnav-list'])}>
          {subNavigations.map((subNavProps, i) => (
            <SubNav
              key={i.toString()}
              {...subNavProps}
              isActive={checkIsPathActive(subNavProps.path, subNavProps.refId)}
            />
          ))}
        </ul>
      </Accordion>
    </li>
  );
}
