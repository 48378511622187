// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { type LoanType, type CollateralT, CollateralFields } from '@kwara/models/src';
import Tag, { Status } from '@kwara/components/src/Tag';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { Currency } from '@kwara/components/src/Intl';
import Empty from '@kwara/components/src/Empty';
import { Bank, BankBranch } from '@kwara/components/src/Bank';
import { Card } from '@kwara/components/src/Card';

import { Grid } from '../../../../components/Grid';
import { excludedFields } from '../../../../lib/modelUtils/collateral';
import { EditableSection } from '../../../../components/EditableSection/v1/EditableSection';
import { Collateral } from '../../../Loan/LoanAdd/components/Security/Collateral';

type Props = {
  loan: LoanType,
  isEditing?: boolean,
  onClose?: () => void
};

const config = {
  Component: Collateral
};

export const CollateralList = ({ loan, isEditing, onClose }: Props) => {
  return (
    <EditableSection
      onClose={onClose}
      isEditing={isEditing}
      readOnly
      initialData={loan}
      config={config}
      ariaLabel="Edit Collaterals"
      onSave={editedLoan =>
        editedLoan.save({
          with: 'collaterals'
        })
      }
    >
      {loan.hasNoCollaterals() ? (
        <Empty className="mb3">
          <Text id="LoanDetail.Security.none" />
        </Empty>
      ) : (
        <Grid columns={3}>
          {map<CollateralT, React.Node>(
            collateral => (
              <Card
                className="mb4"
                key={collateral.id}
                header={<Text id={`LoanAdd.Security.Collateral.Type.${collateral.assetName}`} />}
                metadata={<Tag id="LoanAdd.Security.Collateral" status={Status.Neutral} />}
              >
                <Statistic
                  title={<Text id="LoanAdd.Security.Collateral.Amount.label" />}
                  value={<Currency format="currency" value={collateral.amount} />}
                />

                <div>
                  <Statistic
                    title={<Text id="Forms.TransactionForm.Bank.label" />}
                    value={<Bank bankCode={collateral.assetBankName} />}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.bank)}
                  />
                  <Statistic
                    title={<Text id="Forms.TransactionForm.Branch.label" />}
                    value={<BankBranch bankCode={collateral.assetBankName} branchCode={collateral.assetBankBranch} />}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.branch)}
                  />
                  <Statistic
                    title={<Text id="Forms.TransactionForm.Account.label" />}
                    value={collateral.assetBankAccount}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.account)}
                  />

                  <Statistic
                    title={<Text id="LoanAdd.Security.Collateral.LAND.landRegistration" />}
                    value={collateral.landRegistration}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.landRegistration)}
                  />

                  <Statistic
                    title={<Text id="LoanAdd.Security.Collateral.LIFE_INSURANCE.insuranceCompany" />}
                    value={collateral.insuranceCompany}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.insuranceCompany)}
                  />
                  <Statistic
                    title={<Text id="LoanAdd.Security.Collateral.LIFE_INSURANCE.insuranceNumber" />}
                    value={collateral.insurancePolicyNumber}
                    compact
                    hidden={excludedFields(collateral)(CollateralFields.insuranceNumber)}
                  />

                  <Statistic
                    title={<Text id="LoanAdd.Security.Collateral.Narration" />}
                    value={get(CollateralFields.narration, collateral)}
                    compact
                  />
                </div>
              </Card>
            ),
            loan.collaterals
          )}
        </Grid>
      )}
    </EditableSection>
  );
};
