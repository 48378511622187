import * as React from 'react';

import { DirectDebitReport, Bank } from '@kwara/models/src';
import { useCollectingBank } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';

import { financePath } from '../../lib/urls';
import Wizard from '../../components/Wizard';
import { steps } from './config';

const onGenerate = async data => {
  const { collectingBankId, ...rest } = data;

  const collectingBank = new Bank({ id: collectingBankId });
  const report = new DirectDebitReport({ ...rest });
  report.collectingBank = collectingBank;

  const didSave = await report.save({ with: 'collectingBank' });

  if (!didSave) {
    throw report.errors;
  }
};

export const DirectDebitReportGenerate = ({ baseUrl, match }) => {
  const r = useCollectingBank();

  return (
    <Loadable {...r}>
      {collectingBanks => (
        <Wizard
          baseUrl={baseUrl}
          initialData={{ collectingBanks }}
          cancelReturnsTo={financePath({ baseExtension: 'reports' })}
          currentStep={match.params.step}
          currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
          onSubmit={onGenerate}
          steps={steps}
          startId="confirm"
          titleId="DirectDebitReportGenerate.title"
        />
      )}
    </Loadable>
  );
};
