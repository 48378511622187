import * as React from 'react';
import get from 'lodash/fp/get';

import Banner from '@kwara/components/src/Banner';
import { Card } from '@kwara/components/src/Card';
import { If } from '@kwara/components/src/If/If';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Statistic, Props } from '@kwara/components/src/Statistic';
import { Duration, Percent } from '@kwara/components/src/Intl';
import Tag, { valueToStatus } from '@kwara/components/src/Tag';
import { AccessibleLoader } from '@kwara/components/src/AccessibleLoader';

import { LoanSubstepProps } from 'GlobalTypes';

import { Container } from '../../../../../../layouts';
import { Grid } from '../../../../../../components/Grid';
import { PdfCreditReport } from '../PdfCreditReport';
import { useGetCreditReport } from './useGetCreditReport';
import { CreditReport } from '../../../../../../models/CreditReport/CreditReport';

function getBannerState(code: string): 'error' | 'warning' | 'success' {
  switch (code) {
    case '001': // Identity not found
      return 'error';
    case '003': // Identity found, no Delinquency reports
      return 'success';
    case '002': // Identity found, but no account info
    case '004': // Identity found, at least one CURRENT Delinquency report
    case '005': // Identity found, at least one HISTORICAL  Delinquency report
    default:
      return 'warning';
  }
}

function getStatisticProps(props: Props) {
  return {
    size: 'small',
    compact: true,
    ...props
  };
}

const MIN_POSSIBLE_CREDIT_SCORE = 200;
const MAX_POSSIBLE_CREDIT_SCORE = 900;

export function CreditReportResult({ Checkbox, data }: Partial<LoanSubstepProps>) {
  const r = useGetCreditReport({
    identityType: data.identityType,
    identityValue: data.identityValue,
    loanAmount: data.loan.amount
  });

  return (
    <>
      <Loadable
        {...r}
        loading={
          <AccessibleLoader
            itsTextLoader
            isLoading={r.isLoading}
            infoId="LoanAppraisal.CreditCheck.creditReport.loadingCreditInfo"
          />
        }
      >
        {result => {
          const report: CreditReport = result || {};
          const creditStatus = valueToStatus(
            [MIN_POSSIBLE_CREDIT_SCORE, MAX_POSSIBLE_CREDIT_SCORE],
            report.creditScore
          );

          return (
            <>
              <Container.Root>
                <Container.Heading id="LoanAppraisal.CreditCheck.results" />

                <Container.Body>
                  <Grid columns={1} width="w-100">
                    <div className="grey-400 mb4">
                      <Banner
                        state={getBannerState(report.delinquencyCode)}
                        text={
                          <Text id={`LoanApprove.CreditCheck.Delinquency.${report.delinquencyCode || 'Default'}`} />
                        }
                      />
                    </div>
                  </Grid>

                  <Grid columns={4} width="w-25">
                    <div>
                      <Card border={false} header={<Text id="LoanAppraisal.CreditCheck.crbCreditScore" />}>
                        <Statistic
                          {...getStatisticProps({
                            size: '4x-large',
                            value: (
                              <span className="info-700">
                                <div>
                                  <span>{report.formattedCreditScore}</span>
                                  <span className="kw-text-large"> / 900</span>
                                </div>
                                <Tag
                                  className="kw-text-medium"
                                  status={creditStatus || 'neutral'}
                                  id={`LoanApprove.CreditCheck.status.${creditStatus || 'default'}`}
                                />
                              </span>
                            )
                          })}
                        />
                      </Card>
                      <Card border={false} header={<Text id="LoanAppraisal.CreditCheck.internalCreditScore" />}>
                        <Statistic
                          {...getStatisticProps({
                            size: '4x-large',
                            value: (
                              <span className="info-700">
                                <div>
                                  <span>4</span>
                                  <span className="kw-text-large"> / 5</span>
                                </div>
                                <Tag
                                  className="kw-text-medium"
                                  status="neutral"
                                  id="LoanApprove.CreditCheck.status.neutral"
                                />
                              </span>
                            )
                          })}
                        />
                      </Card>
                    </div>
                    <Card border={false} header={<Text id="LoanAppraisal.CreditCheck.member" />}>
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.fullName" />,
                          value: data.member.fullName(),
                          'data-testid': 'fullName'
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.identification" />,
                          value: data.identityValue,
                          'data-testid': 'identification'
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.loanRequested" />,
                          value: <Currency value={data.loan.amount} format="currency" />
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.loanRepaymentPeriod" />,
                          value: <Duration value={data.loan.duration} />
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.interestRate" />,
                          value: <Percent value={data.loan.product.interestRate.percentage / 100} />
                        })}
                      />
                    </Card>
                    <Card border={false} header={<Text id="LoanAppraisal.CreditCheck.creditPerformance" />}>
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.totalAccounts" />,
                          value: report.totalAccounts,
                          'data-testid': 'totalAccounts'
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.nonPerformingAccounts" />,
                          value: report.nonPerformingAccounts,
                          'data-testid': 'accountNpa'
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.performingAccountsWithDefaultHistory" />,
                          value: report.performingAccountsWithDefault
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.performingAccountsWithoutDefaultHistory" />,
                          value: report.performingAccountsWithoutDefault
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.totalOutstandingBalance" />,
                          value: <Currency value={report.totalOutstandingBalance} format="currency" />,
                          'data-testid': 'totalOutstandingBalance'
                        })}
                      />
                    </Card>
                    <Card border={false} header={<Text id="LoanApprove.CreditCheck.stats.creditEvents" />}>
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.lessThan6" />,
                          value: get('creditApplications.last6Months', report)
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.moreThan6" />,
                          value: get('creditApplications.last12Months', report)
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.bouncedCheques" />,
                          value: get('bouncedChqeues.last12Months', report)
                        })}
                      />
                      <Statistic
                        {...getStatisticProps({
                          title: <Text id="LoanApprove.CreditCheck.stats.reportedFraud" />,
                          value: report.hasReportedFraud
                        })}
                      />
                    </Card>
                  </Grid>
                </Container.Body>
              </Container.Root>
              <Container.Root>
                <Container.Heading id="LoanAppraisal.CreditCheck.creditReport" />
                <Container.Body>
                  <PdfCreditReport
                    identityType={data.identityType}
                    identityValue={data.identityValue}
                    loanAmount={data.loan.amount}
                  />
                </Container.Body>
              </Container.Root>
            </>
          );
        }}
      </Loadable>

      <If
        condition={!r.isLoading}
        do={
          <Container.Root>
            <Container.Body>
              <h3 className="pt3">
                <Text id="LoanAppraisal.CreditCheck.creditCheckConfirm.heading" />
              </h3>
              <Checkbox name="creditCheckConfirm" labelId="LoanAppraisal.CreditCheck.creditCheckConfirm" required />
            </Container.Body>
          </Container.Root>
        }
      />
    </>
  );
}
