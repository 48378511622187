//@flow
import * as React from 'react';

import { Text, type TranslationId } from '@kwara/components/src/Intl';

type Props = {
  className?: string,
  hidden?: boolean,
  value: ?React.Node,
  valueClassName?: string,
  values?: { [string]: any },
  labelClassName?: string,
  labelId?: TranslationId,
  labelContent?: React.Node,
  labelSize?: 'x-small' | 'small' | 'regular',
  size?: 'small-design-system' | 'regular' | 'medium'
};

const Title = ({ labelSize, labelClassName, labelId, labelContent, values }) => (
  <div role="paragraph" className={`kw-text-${labelSize} grey-400 mb0 ${labelClassName}`}>
    {labelId ? <Text id={labelId} values={values} /> : labelContent}
  </div>
);

export const Attribute = ({
  className = '',
  hidden = false,
  value,
  valueClassName = '',
  values,
  labelClassName = '',
  labelId,
  labelContent,
  labelSize = 'regular',
  size = 'regular'
}: Props) =>
  hidden ? null : (
    <div className={className}>
      <Title {...{ labelSize, labelClassName, labelId, labelContent, values }} />
      <div role="paragraph" className={`kw-text-${size} mt0 ${valueClassName}`}>
        {value == null ? '-' : value}
      </div>
    </div>
  );

Attribute.Title = Title;
