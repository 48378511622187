import * as React from 'react';
import { LoanSubstepProps } from 'GlobalTypes';
import { MemberType } from '@kwara/models/src';

import styles from './index.module.scss';
import MemberSearch from '../../../../../components/MemberSearch';
import { useLoanAddContext, LoanAddContextType } from '../..';

export function FindMember(props: LoanSubstepProps) {
  const loanAddContext: LoanAddContextType = useLoanAddContext();

  return (
    <MemberSearch
      floatResults
      searchBarSize="stretch"
      resultListClassName={styles.ResultList}
      labelProps={{ id: 'LoanAdd.FindAMember.subTitle' }}
      returnFullMember
      includes={['addresses', 'next_of_kins', 'id_documents']}
      onSelect={(member: MemberType) => {
        loanAddContext.setHasAddedMember(true);

        props.addDataAndContinue({
          member: member.deserialize(),
          fullName: member.fullName(),
          firstName: member.firstName,
          standing: member.standing,
          hasMember: true
        });
      }}
    />
  );
}
