import React from 'react';
import get from 'lodash/fp/get';

import { LoanType } from '@kwara/models/src';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text } from '@kwara/components/src/Intl';

export function Classification({ loan }: { loan: LoanType }) {
  const { loanPurpose } = loan;

  const deepest = get('classification', loanPurpose);

  const subsector2 = get('name', deepest);
  const subsector1 = get('parent.name', deepest);
  const purpose = get('parent.parent.name', deepest);

  return (
    <StatisticRow widthClass="w-33">
      <Statistic title={<Text id="LoanDetail.Classification.purpose" />} value={purpose} />
      <Statistic title={<Text id="LoanDetail.Classification.subsector1" />} value={subsector1} />
      <Statistic title={<Text id="LoanDetail.Classification.subsector2" />} value={subsector2} />
    </StatisticRow>
  );
}
