// @flow
import * as React from 'react';

import { Link } from '@kwara/components/src/Link';

import { loanPath, savingPath, memberPath, batchTransactionImportsPath } from '../../lib/urls';

type Props = {
  accountId: string,
  entity: string
};

function accountPath(accountId, entity) {
  switch (entity) {
    case 'members':
    case 'members_state':
      return memberPath({ id: accountId });
    case 'saving':
    case 'savings_comment':
    case 'savings_transactions':
    case 'savings_state':
      return savingPath({ id: accountId });
    case 'loan':
    case 'loan_state':
    case 'loan_transactions':
    case 'guarantor':
      return loanPath({ id: accountId });
    case 'import_review_state':
      return batchTransactionImportsPath({ id: accountId });
    default:
      return null;
  }
}

export const AccountLink = ({ accountId, entity }: Props) => {
  const to = accountPath(accountId, entity);

  return to ? (
    <Link type="primary" to={to}>
      {accountId}
    </Link>
  ) : (
    accountId
  );
};
