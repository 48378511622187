import { useMutation, useQueryClient } from 'react-query';

import { ReportFieldGlAccountLink, ReportFieldGlAccountLinkT, ReportFieldT } from '@kwara/models/src';

function remove(args: { link: ReportFieldGlAccountLinkT; field: ReportFieldT }) {
  return args.link.destroy();
}

function add({ glAccountId, field, scalar }: { glAccountId: number; field: ReportFieldT; scalar: number }) {
  const link = ReportFieldGlAccountLink.create({ glAccountId, reportFieldId: field.id, scalar });
  return link.save({ returnScope: ReportFieldGlAccountLink.includes('gl_account') }).then((didSave: boolean) => {
    if (didSave) {
      return link;
    }
  });
}

export function useReportFields(templateId: string) {
  const queryClient = useQueryClient();

  const { mutate: onAddLink } = useMutation(add, {
    onSettled: (link, _errors, { field }) => {
      queryClient.setQueryData(['report_fields', templateId], (prevFields: ReportFieldT[]) => {
        const updated = field.dup();
        updated.reportFieldGlAccountLinks = [...updated.reportFieldGlAccountLinks, link];

        return (prevFields || []).map(prevField => {
          if (prevField.id === field.id) {
            return updated;
          }

          return prevField;
        });
      });
    }
  });

  const { mutate: onRemoveLink } = useMutation(remove, {
    onSettled: (didRemove, _errors, { field }) => {
      if (didRemove) {
        queryClient.setQueryData(['report_fields', templateId], (prevFields: ReportFieldT[]) => {
          const updated = field.dup();
          updated.reportFieldGlAccountLinks = updated.reportFieldGlAccountLinks.filter(link => link.isPersisted);

          return (prevFields || []).map(prevField => {
            if (prevField.id === field.id) {
              return updated;
            }

            return prevField;
          });
        });
      }
    }
  });

  return { onRemoveLink, onAddLink };
}
