// @flow

import * as React from 'react';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { type LoanType } from '@kwara/models/src';

import { useLoan } from '@kwara/models/src/models/request/hooks';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }>;

export const LoanWriteOff = (props: Props) => {
  const { baseUrl, match, history } = props;
  const r = useLoan(match.params.loanId);
  const queryClient = useQueryClient();

  const writeOffLoan = async ({ loan, notes }: { loan: LoanType, notes: string }) => {
    const didSave = await loan.writeOff({ notes });

    if (!didSave) {
      throw loan.errors;
    }
  };

  return (
    <Loadable {...r}>
      {loan => {
        return (
          <Wizard
            analyticsId="LoanWriteOff"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan: loan,
              loanId: loan.id,
              firstName: loan.member.firstName
            }}
            cancelReturnsTo={loanPath()}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={writeOffLoan}
            onSubmitCompletion={() => {
              r.refetch();
              queryClient.invalidateQueries('activity');
              queryClient.invalidateQueries('loan_extras');
              queryClient.invalidateQueries('loan_transactions');
            }}
            steps={steps}
            startId="confirm"
            titleId="LoanWriteOff.shortTitle"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
