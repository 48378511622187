import * as React from 'react';

import { Select, Field, DatePicker } from '@kwara/components/src/Form';

import { useDateBaseFilter, SuggestedDate, DATE_NAMES } from './useDateBaseFilter';

import styles from './index.module.scss';

type DisabledDay = Partial<Record<'before' | 'after', Date>>;

type DateBaseFilterPropTypes = {
  filterType: string;
  suggestedDatesLabelId?: string;
  suggestedDates?: SuggestedDate[];
  disabledDays?: { from?: DisabledDay; to?: DisabledDay };
};

export function DateBaseFilter({
  filterType,
  disabledDays,
  suggestedDates,
  suggestedDatesLabelId
}: DateBaseFilterPropTypes) {
  const {
    formattedDates,
    toDisabledBeforeDays,
    onSuggestedDateChangeHandler,
    onDateChangeHandler,
    checkIsDisabled
  } = useDateBaseFilter(filterType);

  const shouldRenderSuggestedDates = !!suggestedDates && !!suggestedDatesLabelId;

  return (
    <>
      {shouldRenderSuggestedDates && (
        <Field labelId={suggestedDatesLabelId}>
          <Select
            name="dateBaseFilter"
            size="regular"
            value={formattedDates.select}
            onChange={onSuggestedDateChangeHandler}
          >
            {suggestedDates.map((date, i) => {
              const disabled = checkIsDisabled(date);

              return (
                <Select.Option key={i.toString()} value={JSON.stringify(date)} disabled={disabled}>
                  {date.name}
                </Select.Option>
              );
            })}
          </Select>
        </Field>
      )}

      <div className="flex justify-between items-center">
        <div className={styles['date']}>
          <Field labelId="Filter.DateBaseFilter.from">
            <DatePicker
              name="from"
              value={formattedDates.from}
              size="regular"
              onChange={onDateChangeHandler(DATE_NAMES.FROM)}
              disabledDays={disabledDays?.from}
            />
          </Field>
        </div>

        <div className={styles['date-separator']} />

        <div className={styles['date']}>
          <Field labelId="Filter.DateBaseFilter.to">
            <DatePicker
              name="to"
              value={formattedDates.to}
              size="regular"
              disabledDays={{ before: toDisabledBeforeDays, ...disabledDays?.to }}
              onChange={onDateChangeHandler(DATE_NAMES.TO)}
            />
          </Field>
        </div>
      </div>
    </>
  );
}
