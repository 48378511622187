//@flow

import * as React from 'react';

import { type TopupRequestT, TillTransactionTypes } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { DateTime, Text } from '@kwara/components/src/Intl';

import { Panel } from '../../../components/ActionModal';
import { Currency } from '../../../components/Currency';
import { type SubStepComponentProps } from '../../../components/Wizard';

export const Review = (props: SubStepComponentProps<TopupRequestT>) => {
  const { StackChild, TextField, TextArea, data } = props;
  const translationPrefix = data.type === TillTransactionTypes.TOPUP ? 'TopupReview' : 'TillToVaultReview';
  return (
    <StackChild>
      <Panel>
        <StatisticRow widthClass="w-50" compact>
          <Statistic
            title={<Text id={`${translationPrefix}.Review.tillSessionId`} />}
            size="large"
            value={`${data.tillName} (${data.tillSession.id})`}
            compact
          />
          <Statistic
            title={<Text id={`${translationPrefix}.Review.tellerName`} />}
            size="large"
            value={data.tillSession.tellerName}
            compact
          />
        </StatisticRow>
        <StatisticRow widthClass="w-50" compact>
          <Statistic
            title={<Text id={`${translationPrefix}.Review.date`} />}
            size="large"
            value={<DateTime value={data.updatedAt} />}
            compact
          />
          <Statistic
            size="large"
            title={<Text id={`${translationPrefix}.Review.amount`} />}
            value={<Currency value={Math.abs(data.requestedAmount)} format="currency" />}
            compact
          />
        </StatisticRow>
        <div className="pt4">
          <TextField
            margin={false}
            type="number"
            pattern="\\d*"
            inputMode="decimal"
            name="approvedAmount"
            leftGlyph="Currency.orgCurrency"
            labelId={`${translationPrefix}.Approved.amount`}
            initialValue={Math.abs(data.requestedAmount)}
            isCurrency
          />
          <div className="pt4">
            <TextArea
              name="notes"
              placeholderId={`${translationPrefix}.notes.placeholder`}
              labelId={`${translationPrefix}.notes.label`}
            />
          </div>
        </div>
      </Panel>
    </StackChild>
  );
};

Review.validate = {
  approvedAmount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  }
};
