import * as React from 'react';

import Wizard from '../../components/Wizard';

import { steps } from './config';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

const analyticsId = 'SmsTriggerEdit';
export const SmsTriggerEdit = ({ baseUrl }) => {
  const store = useSaccoProfileContext();

  const onSubmit = async data => {
    const organisation = store.profile.organisation;
    const params = {
      settings: {
        ...data
      }
    };

    await organisation.update(params);
  };

  return (
    <Wizard
      analyticsId={analyticsId}
      baseUrl={baseUrl}
      initialData={{ sms_triggers: store.smsTriggers }}
      cancelReturnsTo={baseUrl}
      currentStep="edit"
      onSubmit={onSubmit}
      steps={steps}
      startId="edit"
      titleId="SmsTriggerEdit.title"
    />
  );
};
