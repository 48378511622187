// @flow

import * as React from 'react';

import { TopupRequest as TopupRequestModel } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import Wizard from '../../components/Wizard';
import { tillPath } from '../../lib/urls';
import { steps } from './config';
import type { WizardPageProps } from '..';
import { useTillUIContext } from '../Till';

type Props = WizardPageProps<{}>;

export const TopupRequest = (props: Props) => {
  const { refetchTillData } = useTillUIContext();

  const { baseUrl, match, history } = props;

  const submit = async data => {
    const topupRequest = new TopupRequestModel(data);

    const didSave = await topupRequest.save();

    if (!didSave) {
      throw topupRequest.errors;
    }

    refetchTillData();
  };

  return (
    <Wizard
      baseUrl={baseUrl}
      history={history}
      initialData={{}}
      cancelReturnsTo={tillPath()}
      currentStep={match.params.step}
      currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
      onSubmit={submit}
      steps={steps}
      startId="request"
      titleId="TopupRequest.shortTitle"
      type="submit"
      animated={false}
      bannerContent={<Text id="TopupRequest.successBanner" />}
    />
  );
};
