import React from 'react';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Currency } from '@kwara/components/src/Intl';

import { InstantMobileMoneyValidatorUtil } from '../utils/validator';

export type ArgType = {
  loanAmount: number;
  transactionAmountLimit: number;
  phoneNumberProvider: string | null;
  payoutProvider: string;
};

const currency = (value: number) => <Currency value={value} format="currency" />;

export function useValidate({ loanAmount, transactionAmountLimit, phoneNumberProvider, payoutProvider }: ArgType) {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const invalidDetails = [];
    const isPhoneNumberProviderSupported =
      phoneNumberProvider != null &&
      InstantMobileMoneyValidatorUtil.isPhoneNumberProviderSupported(phoneNumberProvider);
    const isPayoutProviderSupported = InstantMobileMoneyValidatorUtil.isPayoutProviderSupported(payoutProvider);

    if (
      isPayoutProviderSupported &&
      phoneNumberProvider != null &&
      !InstantMobileMoneyValidatorUtil.isPhoneNumberProviderSupported(phoneNumberProvider)
    ) {
      invalidDetails.push(
        formatMessage({ id: 'MobileMoneyInstant.Ineligible.provider' }, { provider: phoneNumberProvider })
      );
    }

    /**
     * We don't bother to show amount limit error if the phone
     * number provider is not supported
     */
    if (
      isPayoutProviderSupported &&
      isPhoneNumberProviderSupported &&
      !InstantMobileMoneyValidatorUtil.isTransactionAmountLimitValid(loanAmount, transactionAmountLimit)
    ) {
      invalidDetails.push(
        formatMessage(
          { id: 'MobileMoneyInstant.Ineligible.amount' },
          {
            amount: currency(loanAmount),
            transactionAmountLimit: currency(transactionAmountLimit)
          }
        )
      );
    }

    const isValid = !invalidDetails.length;
    const invalidTitle = isValid ? null : formatMessage({ id: 'MobileMoneyInstant.Ineligible.title' });

    return {
      isValid,
      invalidTitle,
      invalidDetails
    };
  }, [formatMessage, loanAmount, payoutProvider, phoneNumberProvider, transactionAmountLimit]);
}
