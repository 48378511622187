import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { SubscribedTextField } from '@kwara/components/src/Form';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { AccessibleLoader } from '@kwara/components/src/AccessibleLoader';

import { useIdentityInput, UseIdentityInputArg } from './useIdentityInput';

interface IdentityInputPropTypes extends UseIdentityInputArg {
  name: string;
  labelId: string;
  disabled?: boolean;
  placeholderId: string;
  labelContent: React.ReactNode;
  element: typeof SubscribedTextField;
}

export function IdentityInput(props: IdentityInputPropTypes) {
  const {
    isPrimaryId,
    data,
    setError,
    enableNextButton,
    disableNextButton,
    element: InputComponent,
    ...restProps
  } = props;

  const { caughtError, isVerifying, error, cache, onChangeHandler, onVerifyIdentity } = useIdentityInput({
    isPrimaryId,
    data,
    setError,
    enableNextButton,
    disableNextButton
  });

  return (
    <InputComponent
      {...restProps}
      required={isPrimaryId}
      error={caughtError}
      onChange={onChangeHandler}
      info={
        <If
          condition={isVerifying}
          do={
            <AccessibleLoader
              itsTextLoader
              isLoading={isVerifying}
              loadingTextId="AddMember.Identity.isVerifying"
              className="grey-300"
            />
          }
          else={
            <If
              condition={caughtError}
              do={
                <div className="flex items-center">
                  <ErrorTextWithI18n
                    className="pa2"
                    id={`AddMember.Identity.VerificationError.${error.code}`}
                    values={{ memberName: error.namesUsingId, documentId: cache.documentId }}
                  />

                  <If
                    condition={error.retry}
                    do={
                      <ErrorTextWithI18n
                        id="AddMember.Identity.retryText"
                        role="button"
                        tabIndex={-1}
                        onClick={onVerifyIdentity}
                        className="pointer underline"
                      />
                    }
                  />
                </div>
              }
            />
          }
        />
      }
    />
  );
}
