import React from 'react';

import { Row, Heading } from '../../../../../../../components/Table';

export function THead() {
  return (
    <Row>
      <Heading width="50px" />
      <Heading width="100px" translationId="Finance.LoanDisbursement.Table.Heading.loanId" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.member" />
      <Heading width="200px" translationId="Finance.LoanDisbursement.Table.Heading.loanProduct" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.amount" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.interestRate" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.duration" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.branch" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.disbursementState" />
      <Heading translationId="Finance.LoanDisbursement.Table.Heading.realTimePaymentStatus" />
      <Heading iconSpacer />
    </Row>
  );
}
