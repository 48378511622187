import * as React from 'react';

import { MemberType, LoanType, TillTransactionType, DividenReportsT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import Button, { ButtonSizesT } from '@kwara/components/src/Button';
import { If } from '@kwara/components/src/If/If';

import Asset from '../Asset';
import { useDownloadButton } from './useDownloadButton';

import { WrapperWithInlineError } from './components/WrapperWithInlineError';

export { useDownloadButton };

export function DownloadButton({
  className,
  ariaLabel = 'Download',
  downloadFn,
  instance,
  size = 'medium',
  disabled,
  onClick
}: {
  className?: string;
  ariaLabel?: string;
  downloadFn: () => Promise<void>;
  instance?: LoanType | MemberType | TillTransactionType;
  size?: ButtonSizesT;
  disabled?: boolean;
  onClick?: Function;
}) {
  const { isDisabled, hasErrors, onDownload, isLoading } = useDownloadButton({
    downloadFn,
    instance,
    disabled,
    onClick
  });

  return (
    <WrapperWithInlineError
      hasErrors={hasErrors}
      className={className}
      button={
        <Button aria-label={ariaLabel} disabled={isDisabled} type="secondary" size={size} onClick={onDownload}>
          <Asset
            size={size}
            col={isDisabled ? Asset.Colours.grey300 : Asset.Colours.black}
            id={isLoading ? Asset.Glyphs.Spinner : Asset.Glyphs.Printer}
          />
        </Button>
      }
    />
  );
}

export function DownloadButtonWithText({
  className,
  ariaLabel = 'Download',
  downloadFn,
  instance,
  size = 'medium',
  disabled,
  onClick,
  translationId
}: {
  className?: string;
  ariaLabel?: string;
  downloadFn: () => Promise<void>;
  instance?: DividenReportsT;
  size?: ButtonSizesT;
  disabled?: boolean;
  onClick?: Function;
  translationId: string;
}) {
  const { isDisabled, hasErrors, onDownload, isLoading } = useDownloadButton({
    downloadFn,
    instance,
    disabled,
    onClick
  });

  return (
    <WrapperWithInlineError
      hasErrors={hasErrors}
      className={className}
      button={
        <Button aria-label={ariaLabel} disabled={isDisabled} type="secondary" size={size} onClick={onDownload}>
          <If
            condition={!isLoading}
            do={<Text id={translationId} />}
            else={
              <Asset
                size={size}
                col={isDisabled ? Asset.Colours.grey300 : Asset.Colours.black}
                id={Asset.Glyphs.Spinner}
              />
            }
          />
        </Button>
      }
    />
  );
}
