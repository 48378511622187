import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import DelayedAnimation from '@kwara/components/src/Wizard/deprecated/DelayedAnimation';

import { AutoDismissableCompletion } from '../../../components/Completion';

const unanimatedSuccess = ({ onCancel }: { onCancel: () => void }) => {
  return (
    <AutoDismissableCompletion
      autoconfirm
      autoconfirmTimeoutSecs={4}
      onConfirm={onCancel}
      type="good"
      titleId="Activate2FA.success.title"
      subtitle={<Text id="Activate2FA.success.subtitle" />}
    />
  );
};

export const Success = ({ onDone }: { onDone: () => void }) => {
  return (
    <Modal isOpen>
      <DelayedAnimation onDone={onDone}>{({ hide }) => unanimatedSuccess({ onCancel: hide })}</DelayedAnimation>
    </Modal>
  );
};
