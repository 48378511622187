import React from 'react';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { CheckableTable } from './Table';
import { useActions } from './useActions';

export const CheckableList = ({
  transactions = [],
  isFetchingNextPage,
  isLoading,
  totalResults,
  fetchNextPage,
  hasNextPage,
  error,
  ...r
}) => {
  const actions = useActions(r.form, r.values);

  const loading = isLoading || isFetchingNextPage;

  return (
    <OverviewListV2
      withFilter
      totalNumResults={totalResults}
      items={transactions}
      filterDisabled={loading}
      actions={actions}
      labelId="Finance.JournalEntries.Transactions.List.label"
      table={
        <CheckableTable
          isLoading={loading}
          onNext={fetchNextPage}
          hasMore={hasNextPage}
          errors={error}
          items={transactions}
          transactions={transactions}
        />
      }
    />
  );
};
