import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconDeleteElement = React.ElementRef<'svg'>;

export const IconDelete = React.forwardRef<IconDeleteElement, PrimitiveSvgPropTypes>(function IconDelete(
  { fill = '#94A3B8', ...restProps },
  forwardedRef
) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={forwardedRef}
      {...restProps}
    >
      <path d="M7.65625 7.15625H8.59375V14.6562H7.65625V7.15625Z" fill={fill} />
      <path d="M9.53125 7.15625H10.4688V14.6562H9.53125V7.15625Z" fill={fill} />
      <path d="M11.4062 7.15625H12.3438V14.6562H11.4062V7.15625Z" fill={fill} />
      <path d="M3.90625 4.34375H16.0938V5.28125H3.90625V4.34375Z" fill={fill} />
      <path
        d="M12.3125 4.8125H11.4375V3.875C11.4375 3.59375 11.2187 3.375 10.9375 3.375H9.0625C8.78125 3.375 8.5625 3.59375 8.5625 3.875V4.8125H7.6875V3.875C7.6875 3.125 8.3125 2.5 9.0625 2.5H10.9375C11.6875 2.5 12.3125 3.125 12.3125 3.875V4.8125Z"
        fill={fill}
      />
      <path
        d="M12.8125 17.4687H7.1875C6.4375 17.4687 5.78125 16.8437 5.71875 16.0937L4.84375 4.84375L5.78125 4.78125L6.65625 16.0312C6.6875 16.3125 6.9375 16.5312 7.1875 16.5312H12.8125C13.0938 16.5312 13.3437 16.2812 13.3437 16.0312L14.2188 4.78125L15.1562 4.84375L14.2812 16.0937C14.2187 16.875 13.5625 17.4687 12.8125 17.4687Z"
        fill={fill}
      />
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke={fill} />
    </svg>
  );
});
