// @flow

import * as React from 'react';

import { MemberReport } from '@kwara/models/src';

import Wizard, { type SubStepComponentProps } from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';

type PageProps = {
  baseUrl: string,
  match: {
    params: {
      step: string,
      subStep: ?string
    }
  }
};
type FormProps = {
  email: string
};

export type SubStepProps = SubStepComponentProps<FormProps>;

export const MemberReportGenerate = (props: PageProps) => {
  const { baseUrl } = props;

  const onGenerateTemplate = async (data: FormProps) => {
    const memberReport = new MemberReport(data);
    const didSave = await memberReport.save();

    if (!didSave) {
      throw memberReport.errors;
    }
  };

  return (
    <Wizard
      baseUrl={baseUrl}
      history={history}
      initialData={{}}
      cancelReturnsTo={settingPath({ action: 'data' })}
      currentStep={props.match.params.step}
      currentSubStep={props.match.params.subStep != null ? parseInt(props.match.params.subStep, 10) : null}
      onSubmit={onGenerateTemplate}
      steps={steps}
      startId="email"
      titleId="MemberReportGenerate.titleId"
    />
  );
};
