import * as React from 'react';
import trim from 'lodash/fp/trim';
import { User, IncludesT } from '@kwara/models/src';
import { useOverviewList, fetchWithPagination } from '../base';

export function useSearch() {
  const [where, setWhere] = React.useState<{ search: string; [key: string]: string }>({
    search: ''
  });

  const onSearchChange = val => {
    setWhere({ ...where, search: trim(val) });
  };

  return { where, onSearchChange };
}

async function fetchUsers(opts) {
  return fetchWithPagination(User, opts);
}

const defaultUsersIncludes = [];
const defaultSelect = {};
export function usePaginatedUsers(includes: IncludesT = defaultUsersIncludes, fetchOpts = { pageSizeParam: 20 }) {
  return useOverviewList({
    queryKey: 'users',
    fetchFn: opts => fetchUsers({ ...opts, ...fetchOpts }),
    includes,
    select: defaultSelect,
    useWhere: useSearch
  });
}
