import * as React from 'react';

import styles from './LoadingKwara.module.scss';

export const LoadingKwara = () => {
  return (
    <div data-testid="loadingKwara" className="w-100 h-100 flex justify-center items-center">
      <svg className={styles['loading']} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <g fill="#FFF" fillRule="evenodd">
          <path d="M26.2644518,27.2093023 C29.2425249,24.482392 30.4744186,20.5475083 30.0916944,16.8398671 C29.8524917,14.4598007 28.8598007,11.4697674 27.1734219,9.58006645 C24.5541528,6.62591362 20.7986711,5.74086379 17.1388704,6.20730897 C14.7827243,6.50631229 11.9122924,7.11627907 9.9986711,8.86245847 C7.02059801,11.5774086 5.80066445,14.4 6.17142857,18.1076412 C6.54219269,21.8033223 6.92491694,24.207309 8.61129568,26.1209302 C11.0511628,28.8717608 14.8903654,30.1873754 18.3229236,29.9840532 C21.7554817,29.7568106 24.1475083,29.1468439 26.2644518,27.2093023 Z M18.0598007,35.9043189 C13.9215947,35.9043189 10.7521595,34.2059801 6.85315615,31.048505 C2.94219269,27.8910299 0.0598006645,22.676412 0.0598006645,17.2943522 C0.0598006645,13.5747508 1.94950166,10.5966777 5.08305648,6.23122924 C8.22857143,1.86578073 12.2471761,0.0239202658 18.0598007,0.0239202658 C21.8033223,0.0239202658 25.4870432,2.20066445 28.3215947,4.20996678 C32.7348837,7.33156146 35.9282392,11.9840532 35.9282392,17.7607973 C35.9282392,21.4684385 34.1342193,25.6784053 32.1249169,28.4890365 C28.979402,32.8664452 23.8843854,35.9043189 18.0598007,35.9043189 Z" />
          <polygon points="17.617 17.115 15.118 22.856 13.276 22.856 10.609 16.577 13.276 16.565 14.197 18.742 16.266 13.981 18.969 13.993 21.014 18.742 23.454 13.001 26.133 13.001 21.959 22.856 20.117 22.856" />
        </g>
      </svg>
    </div>
  );
};
