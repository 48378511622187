/**
 * @AboutThiFile modules that build up Left Navigation
 *
 * @ExposedAPis :
 * 1. @generateLeftNavigationRoutes
 */

import React from 'react';

import { ROUTES } from '../../../../../routes';
import { IconTills } from '../assets/IconTills.svg';
import { IconLoans } from '../assets/IconLoans.svg';
import { IconMembers } from '../assets/IconMembers.svg';
import { IconTellers } from '../assets/IconTellers.svg';
import { IconFinance } from '../assets/IconFinance.svg';
import { IconSavings } from '../assets/IconSavings.svg';
import { IconSettings } from '../assets/IconSettings.svg';
import { IconReports } from '../assets/IconReports.svg';
import { IconDashboard } from '../assets/IconDashboard.svg';
import {
  generateMPesaTabNav,
  generateReportsTabNav,
  generateTransactionsTabNav,
  PermittedTabNavs,
  isPermitted
} from './generateTabNavigationRoutes';
import { Store as SaccoProfileContextType } from '../../../../../models/Store/Store';

import {
  AppPermissionsType,
  ToHelperType,
  KwaraPermissionsConfigKeyType
} from '../../../../../models/Permission/types';

export type SubNavigation = {
  nameId: string;
  path: string;
  refId: string;
  hide?: boolean;
  permissions: KwaraPermissionsConfigKeyType[];
};

export type RouteShapeT = {
  id: string;
  nameId: string;
  icon: React.ForwardRefExoticComponent<any & React.RefAttributes<SVGSVGElement>>;
  permissions: KwaraPermissionsConfigKeyType[];
  path?: string;
  openOnMount?: boolean;
  hide?: boolean;
  subNavigations?: SubNavigation[];
};

type ReturnType = Array<RouteShapeT>;

type GetPermittedInitialSubNavFromTabNavsArgTypes = {
  to: ToHelperType;
  appPermissions: AppPermissionsType;
  store: SaccoProfileContextType;
  generatePermittedTabNav(
    to: ToHelperType,
    appPermissions: AppPermissionsType,
    store: SaccoProfileContextType
  ): PermittedTabNavs[];
  nameId: string;
};

/**
 * @getPermittedInitialSubNavFromTabNavs this function generates
 * a sub navigation from its first permitted tab navigation. That way
 * when you click a sub navigation with tab navs, it opens the lower bound tab nav
 * @param arg
 * @returns
 */
function getPermittedInitialSubNavFromTabNavs(arg: GetPermittedInitialSubNavFromTabNavsArgTypes): SubNavigation | null {
  const { to, appPermissions, store, generatePermittedTabNav, nameId } = arg;
  const permittedTabNavs = generatePermittedTabNav(to, appPermissions, store);

  if (!!permittedTabNavs.length) {
    const { path, parentId, permissions } = permittedTabNavs[0];

    return { nameId, path, refId: parentId, permissions };
  }

  return null;
}

type GetPermittedSubNavArgTypes = {
  to: ToHelperType;
  subnav: SubNavigation;
};

/**
 * @getPermittedSubNav
 * @param arg
 * @returns
 */
function getPermittedSubNav(arg: GetPermittedSubNavArgTypes): SubNavigation | null {
  const { to, subnav } = arg;

  if (isPermitted(to)(subnav)) return subnav;

  return null;
}

export function generateLeftNavigationRoutes(
  to: ToHelperType,
  appPermissions: AppPermissionsType,
  store: SaccoProfileContextType
): ReturnType {
  const routes: ReturnType = [
    {
      id: 'dashboard',
      nameId: 'navigation.dashboard',
      path: ROUTES.dashboard,
      icon: IconDashboard,
      permissions: appPermissions.ViewDashboardIndicators
    },
    {
      id: 'members',
      nameId: 'navigation.members',
      path: ROUTES.members,
      icon: IconMembers,
      permissions: []
    },
    {
      id: 'loans',
      nameId: 'navigation.loans',
      path: ROUTES.loans,
      icon: IconLoans,
      permissions: appPermissions.ViewLoan
    },
    {
      id: 'savings',
      nameId: 'navigation.savings',
      path: ROUTES.savings,
      icon: IconSavings,
      permissions: appPermissions.ViewSavings
    },
    {
      id: 'tills',
      nameId: 'navigation.tills',
      path: ROUTES.till,
      icon: IconTills,
      permissions: appPermissions.UseTill,
      hide: !store.isDepositTaking
    },
    {
      id: 'tellers',
      nameId: 'navigation.tellers',
      path: ROUTES.tellers,
      icon: IconTellers,
      permissions: appPermissions.UseTellerSupervisorPage,
      hide: !store.isDepositTaking
    },
    {
      id: 'reports',
      nameId: 'navigation.reports',
      path: ROUTES.reports,
      icon: IconReports,
      permissions: [],
      subNavigations: [
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'accountingReports',
            nameId: 'navigation.reports.subNavigation.accountingReports',
            path: ROUTES.accountingReports,
            permissions: appPermissions.ViewAccountingReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'sasraReports',
            nameId: 'navigation.reports.subNavigation.sasraReports',
            path: ROUTES.sasraReports,
            permissions: appPermissions.ManageSasraReports,
            hide: !store.isSasraRegulated
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'guarantorListingReports',
            nameId: 'navigation.reports.subNavigation.guarantorListingReports',
            path: ROUTES.guarantorListingReports,
            permissions: appPermissions.ViewGuarantorListingReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'loanAgeingReports',
            nameId: 'navigation.reports.subNavigation.loanAgeingReports',
            path: ROUTES.loanAgeingReports,
            permissions: appPermissions.ViewLoanAgeingReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'loanListingReports',
            nameId: 'navigation.reports.subNavigation.loanListingReports',
            path: ROUTES.loanListingReports,
            permissions: appPermissions.ViewLoanListingReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'depositListingReports',
            nameId: 'navigation.reports.subNavigation.depositListingReports',
            path: ROUTES.depositListingReports,
            permissions: appPermissions.ViewDepositListingReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'checkOffAdviceReports',
            nameId: 'navigation.reports.subNavigation.checkOffAdviceReports',
            path: ROUTES.checkOffAdviceReports,
            permissions: appPermissions.ViewCheckOffAdviceReports
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'memberListingReports',
            nameId: 'navigation.reports.subNavigation.memberListingReports',
            path: ROUTES.memberListingReports,
            permissions: appPermissions.ViewMemberListingReports
          }
        })
      ].filter(Boolean) as Array<SubNavigation>
    },
    {
      id: 'finance',
      nameId: 'navigation.finance',
      icon: IconFinance,
      permissions: [],
      subNavigations: [
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'journalEntries',
            nameId: 'navigation.finance.subNavigation.journalEntries',
            path: ROUTES.financeJournalEntries,
            permissions: appPermissions.ViewJournalEntries
          }
        }),
        getPermittedInitialSubNavFromTabNavs({
          to,
          appPermissions,
          store,
          nameId: 'navigation.finance.subNavigation.transactions',
          generatePermittedTabNav: generateTransactionsTabNav
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'reconciliations',
            nameId: 'navigation.finance.subNavigation.reconciliations',
            path: ROUTES.financeReconciliations,
            permissions: appPermissions.ViewReconciliationStatements
          }
        }),
        getPermittedInitialSubNavFromTabNavs({
          to,
          appPermissions,
          store,
          nameId: 'navigation.finance.subNavigation.mPesa',
          generatePermittedTabNav: generateMPesaTabNav
        }),
        getPermittedInitialSubNavFromTabNavs({
          to,
          appPermissions,
          store,
          nameId: 'navigation.finance.subNavigation.reports',
          generatePermittedTabNav: generateReportsTabNav
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'sasra',
            nameId: 'navigation.finance.subNavigation.sasra',
            path: ROUTES.financeSasra,
            permissions: appPermissions.ViewMpesaTransactions
          }
        })
      ].filter(Boolean) as Array<SubNavigation>
    },
    {
      id: 'settings',
      nameId: 'navigation.settings',
      path: ROUTES.allSettings,
      icon: IconSettings,
      permissions: [],
      subNavigations: [
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'general',
            nameId: 'navigation.settings.subNavigation.general',
            path: ROUTES.settingsGeneral,
            permissions: []
          }
        }),

        getPermittedSubNav({
          to,
          subnav: {
            refId: 'loanProducts',
            nameId: 'navigation.settings.subNavigation.loanProducts',
            path: ROUTES.settingsLoans,
            permissions: []
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'workspace',
            nameId: 'navigation.settings.subNavigation.workspace',
            path: ROUTES.settingsWorkspace,
            permissions: appPermissions.ViewInvitations
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'logins',
            nameId: 'navigation.settings.subNavigation.logins',
            path: ROUTES.settingsActivity,
            permissions: []
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'team',
            nameId: 'navigation.settings.subNavigation.team',
            path: ROUTES.settingsTeam,
            permissions: [],
            hide: !store.profile.isAdmin
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'activityFeed',
            nameId: 'navigation.settings.subNavigation.activityFeed',
            path: ROUTES.settingsEvents,
            permissions: appPermissions.ViewOrgActivityFeed
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'rolesAndPermissions',
            nameId: 'navigation.settings.subNavigation.rolesAndPermissions',
            path: ROUTES.settingsRolesPermissions,
            permissions: [],
            hide: !store.profile.isAdmin
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'smsSettings',
            nameId: 'navigation.settings.subNavigation.smsSettings',
            path: ROUTES.settingsSmsSettings,
            permissions: [],
            hide: !(store.isKwaraKenyaOrE2E && store.profile.isAdmin)
          }
        }),
        getPermittedSubNav({
          to,
          subnav: {
            refId: 'manageCredentials',
            nameId: 'navigation.settings.subNavigation.manageCredentials',
            path: ROUTES.settingsManageCredentials,
            permissions: [],
            hide: !store.profile.isAdmin
          }
        })
      ].filter(Boolean) as Array<SubNavigation>
    }
  ];

  const permittedRoutes = routes.filter(route => {
    const isNonSubNavsValid = to(route.permissions) && !route.subNavigations && !route.hide;
    /**
     * @isSubNavsValid ensure a subnav is not unnecessary rendered when it doesn't have a permitted sub-navs
     */
    const isSubNavsValid = to(route.permissions) && route.subNavigations && !!route.subNavigations.length;

    return isNonSubNavsValid || isSubNavsValid;
  });

  return permittedRoutes;
}
