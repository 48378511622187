import * as React from 'react';

import { LoanClassificationReport } from '@kwara/models/src';
import { getCurrentYear, formatIsoDate } from '@kwara/lib/src/dates';

import { financePath } from '../../lib/urls';
import Wizard from '../../components/Wizard';
import { steps } from './config';

const onGenerate = async ({ startDate, endDate, financialYear }) => {
  const report = new LoanClassificationReport({
    startDate: formatIsoDate(startDate),
    endDate: formatIsoDate(endDate),
    financialYear
  });

  const didSave = await report.save();

  if (!didSave) {
    throw report.errors;
  }
};

export const SasraReport4BGenerate = ({ baseUrl, match }) => {
  return (
    <Wizard
      baseUrl={baseUrl}
      initialData={{ financialYear: getCurrentYear() }}
      cancelReturnsTo={financePath({ baseExtension: 'sasra' })}
      currentStep={match.params.step}
      currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
      onSubmit={onGenerate}
      steps={steps}
      startId="confirm"
      titleId="SasraReport4BGenerate.title"
    />
  );
};
