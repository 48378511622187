// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';
import { EmploymentStatuses } from '@kwara/models/src';
import { isValid, ERRORS, VALID } from '@kwara/lib/src/validator';
import { Field as BasicField } from '@kwara/components/src/Form/Field';
import { appName } from '@kwara/lib/src/utils';
import Asset from '@kwara/components/src/Asset';

import { type SubStepComponentProps } from '../../../components/Wizard';
import { Grid } from '../../../components/Grid';

export const Employment = ({ StackChild, RadioGroup, Condition, TextField, Checkbox }: SubStepComponentProps<{}>) => (
  <StackChild>
    <div className="flex">
      <div className="flex-auto">
        <RadioGroup
          name="employmentStatus"
          labelId="AddMember.Employment.Type.label"
          required={true}
          items={[
            {
              labelId: 'Employment.Type.employed',
              value: EmploymentStatuses.EMPLOYED,
              assetId: appName.isMember ? Asset.Glyphs.Salaried : null
            },
            {
              labelId: 'Employment.Type.self_employed',
              value: EmploymentStatuses.SELF_EMPLOYED,
              assetId: appName.isMember ? Asset.Glyphs.Business : null
            },
            {
              labelId: 'Employment.Type.other',
              value: EmploymentStatuses.OTHER,
              assetId: appName.isMember ? Asset.Glyphs.Other : null
            }
          ]}
        />
        {appName.isSacco ? (
          <BasicField labelId="AddMember.Employment.PersonOfInterest.label">
            <Checkbox name="isGroup" labelId="AddMember.Employment.Group.label" margin={false} />
            <Checkbox name="isStaff" labelId="AddMember.Employment.Staff.label" margin={false} />
            <Checkbox name="isDelegate" labelId="AddMember.Employment.Delegate.label" margin={false} />
            <Checkbox name="isDirector" labelId="AddMember.Employment.Director.label" margin={false} />
            <Checkbox name="govEmployee" labelId="AddMember.Employment.GovEmployee.label" margin={false} />
          </BasicField>
        ) : null}
        <TextField
          name="profession"
          required={appName.isMember}
          errorBaseId="AddMember.Employment.Profession"
          labelId="AddMember.Employment.Profession.label"
          placeholderId="AddMember.Employment.Profession.placeholder"
        />

        <Condition when="employmentStatus" is={EmploymentStatuses.EMPLOYED}>
          <TextField
            name="employer"
            required
            errorBaseId="AddMember.Employment.Employer"
            labelId="AddMember.Employment.Employer.label"
          />
          {appName.isSacco ? (
            <TextField
              name="staffId"
              errorBaseId="AddMember.Employment.StaffId"
              labelId="AddMember.Employment.StaffId.label"
            />
          ) : null}
        </Condition>
        <Condition when="employmentStatus" is={EmploymentStatuses.SELF_EMPLOYED}>
          <TextField
            name="business"
            required
            errorBaseId="AddMember.Employment.Business"
            labelId="AddMember.Employment.Business.label"
          />
        </Condition>
      </div>
    </div>
  </StackChild>
);

// TODO: this is a duplicate, should be removed if ch6907 happens
// if not, consider extracting this and its duplicate(s) and moving
// where appropriate
function isRequired(fieldName, config, formProps) {
  const fieldValidate = get(`validate.${fieldName}`, config);
  if (fieldValidate && fieldValidate.isRequired) {
    return fieldValidate.isRequired(formProps.values[fieldName], formProps.values);
  }

  return false;
}

export const EmploymentExtras = ({
  StackChild,
  Condition,
  TextField,
  SelectField,
  config,
  formProps,
  isV1
}: SubStepComponentProps<{}>) => {
  const profileContext = useSaccoProfileContext();

  const numberInputSharedProps = {
    type: 'number',
    isCurrency: true,
    leftGlyph: profileContext?.currency
  };

  return (
    <StackChild>
      {isV1 ? null : (
        <Condition when="employmentStatus" is="employed">
          <Grid columns={2} width="w-50" border={false}>
            <TextField
              name="employerEmail"
              labelId="AddMember.Employment.EmployerEmail.label"
              required={isRequired('employerEmail', config, formProps)}
            />
            <TextField
              name="employerPhoneNumber"
              labelId="AddMember.Employment.EmployerPhoneNumber.label"
              required={isRequired('employerPhoneNumber', config, formProps)}
            />
          </Grid>
        </Condition>
      )}
      {isV1 ? null : (
        <Condition when="employmentStatus" not="other">
          <TextField
            name="workEmail"
            labelId="AddMember.Employment.MemberWorkEmail.label"
            required={isRequired('workEmail', config, formProps)}
          />
        </Condition>
      )}
      <Condition when="employmentStatus" not="other">
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            name="grossIncome"
            labelId="AddMember.Employment.GrossIncome.label"
            infoId="AddMember.Employment.GrossIncome.info"
            required={isRequired('grossIncome', config, formProps)}
            {...numberInputSharedProps}
          />
          <TextField
            name="netIncome"
            labelId="AddMember.Employment.NetIncome.label"
            infoId="AddMember.Employment.NetIncome.info"
            required={isRequired('netIncome', config, formProps)}
            errorBaseId="AddMember.Employment.NetIncome"
            {...numberInputSharedProps}
          />
        </Grid>
        <TextField
          name="otherDeductibles"
          labelId="AddMember.Employment.OtherDeductibles.label"
          infoId="AddMember.Employment.OtherDeductibles.info"
          required={isRequired('otherDeductibles', config, formProps)}
          {...numberInputSharedProps}
        />
      </Condition>
      <Grid columns={2} width="w-50" border={false}>
        <TextField
          name="otherIncomeAmount"
          labelId="AddMember.Employment.OtherIncomeAmount.label"
          infoId="AddMember.Employment.OtherIncomeAmount.info"
          required={isRequired('otherIncomeAmount', config, formProps)}
          {...numberInputSharedProps}
        />
        <TextField
          name="incomeSource"
          labelId="AddMember.Employment.IncomeSource.label"
          infoId="AddMember.Employment.IncomeSource.info"
          required={isRequired('incomeSource', config, formProps)}
        />
      </Grid>
      {isV1 ? null : (
        <TextField
          name="disposableIncomeAmount"
          labelId="AddMember.Employment.DisposableIncomeAmount.label"
          required={isRequired('disposableIncomeAmount', config, formProps)}
          {...numberInputSharedProps}
        />
      )}
      <Condition when="employmentStatus" is="employed">
        <SelectField
          name="termsOfService"
          labelId="AddMember.Employment.tos.label"
          required={isRequired('termsOfService', config, formProps)}
        >
          <SelectField.Option translationId={`AddMember.Employment.tos.NONE`} value="" />
          <SelectField.Option translationId={`AddMember.Employment.tos.Contract`} value="Contract" />
          <SelectField.Option translationId={`AddMember.Employment.tos.Permanent`} value="Permanent" />
        </SelectField>
      </Condition>
    </StackChild>
  );
};

// Validations
const checkMissingField = fieldName => (_, allData) => allData.employmentStatus === fieldName;

const base = {
  employmentStatus: {
    isRequired: () => true
  },
  business: {
    isRequired: checkMissingField(EmploymentStatuses.SELF_EMPLOYED)
  },
  employer: {
    isRequired: checkMissingField(EmploymentStatuses.EMPLOYED)
  },
  profession: {
    isRequired: () => appName.isMember
  },
  otherIncomeAmount: { currency: true },
  disposableIncomeAmount: { currency: true }
};

const isEmployed = (_, allData) => allData.employmentStatus === EmploymentStatuses.EMPLOYED;
const isWorking = (_, allData) =>
  allData.employmentStatus === EmploymentStatuses.SELF_EMPLOYED ||
  allData.employmentStatus === EmploymentStatuses.EMPLOYED;

const emailFields = target => {
  if (isEmpty(target)) {
    return null;
  }

  if (!isValid.email(target)) {
    return ERRORS.invalidEmail;
  }
};

const isValidNetIncome = (netIncome, formData) => {
  const { grossIncome } = formData;
  if (Number(netIncome) === Number(grossIncome)) {
    return 'netIncomeIsEqualToGrossIncome';
  }

  if (Number(netIncome) > Number(grossIncome)) {
    return 'netIncomeIsMoreThanGrossIncome';
  }
};

const memberDetail = {
  ...base,
  employerEmail: { email: true },
  workEmail: { email: true },
  employerPhoneNumber: { phoneNumber: true },
  grossIncome: { currency: true },
  netIncome: { currency: true, custom: isValidNetIncome },
  otherDeductibles: { currency: true }
};

const loanAdd = {
  ...base,
  employerEmail: {
    // Setting email: true breaks when `isRequired` because it will run the validation
    // even for non-employed ones making it impossible to save the form for self employed.
    // The email validation is hence moved to custom()
    // email: true,
    isRequired: isEmployed,
    custom: emailFields
  },
  workEmail: {
    isRequired: isWorking,
    custom: emailFields
  },
  employerPhoneNumber: {
    isRequired: isEmployed,
    custom(target) {
      if (isEmpty(target)) {
        return null;
      }

      if (isValid.phoneNumber(target) !== VALID) {
        return isValid.phoneNumber(target);
      }
    }
  },
  grossIncome: {
    isRequired: isWorking,
    currency: true
  },
  netIncome: {
    isRequired: isWorking,
    currency: true,
    custom: isValidNetIncome
  },
  otherDeductibles: {
    isRequired: isWorking,
    currency: true
  },
  incomeSource: {
    isRequired: checkMissingField(EmploymentStatuses.OTHER)
  },
  otherIncomeAmount: {
    isRequired: checkMissingField(EmploymentStatuses.OTHER),
    currency: true
  },
  disposableIncomeAmount: { isRequired: () => true, currency: true },
  termsOfService: {
    isRequired: isEmployed
  }
};

export const validateConfig = {
  memberAdd: base,
  memberDetail,
  loanAdd
};

Employment.validateConfig = validateConfig;
EmploymentExtras.validateConfig = validateConfig;
