// @flow

import * as React from 'react';

import { DetailSection } from '../Detail/DetailSection';

import Navigation from './Navigation';
import NavigationItem from './NavigationItem';

export { default as Navigation } from './Navigation';
export { default as NavigationItem } from './NavigationItem';

const isDetailSection = el => {
  return el.type === DetailSection;
};

export const AsNavigation = (element: React.Node) => ({ id }: { id?: ?string }) => {
  const container = React.Children.only(element);
  const sections = React.Children.toArray(container.props.children).filter(isDetailSection);

  const nav = sections.map((element, index) => {
    const hasId = !!element.props.id;

    return (
      <NavigationItem disabled={!hasId} key={index} active={id === element.props.id} to={`#${element.props.id}`}>
        {element.props.title}
      </NavigationItem>
    );
  });

  return <Navigation>{nav}</Navigation>;
};

export const BackToTopNavigation = () => (
  <Navigation padding={false}>
    <NavigationItem to="#top">Back to top</NavigationItem>
  </Navigation>
);
