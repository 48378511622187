import React from 'react';

import { Location } from 'history';

import { MemberType } from '@kwara/models/src/models/Member';

import * as Table from '../../../../components/Table';

import { MemberRow } from './MemberRow';
import { Group } from './useMemberListTableUtils';
import { memberPath } from '../../../../lib/urls';

type GroupsWithMembersPropTypes = {
  columns: number;
  group: Group | 'none';
  members: MemberType[];
  asLink?: boolean;
  isV1?: boolean;
};

export function GroupsWithMembers({ columns, group, members, isV1, asLink = true }: GroupsWithMembersPropTypes) {
  return (
    <>
      {group !== 'none' && <Table.GroupingRow cols={columns} translationId={`MemberListTableGroup.${group}`} />}

      {members.map(member => {
        const pathToMember = asLink ? memberPath({ id: member.id }) : null;
        return <MemberRow key={member.id} member={member} pathTo={pathToMember as Location} isV1={isV1} />;
      })}
    </>
  );
}
