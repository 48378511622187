// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import { useQueryClient } from 'react-query';
import filter from 'lodash/fp/filter';

import { useMember } from '@kwara/models/src/models/request/hooks';
import { type LoanType, type UserT } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { useFullLoan } from '@kwara/models/src/models/request/hooks';

import { useActivity, usePermissions } from '../../hooks';
import { Updater } from '../../components/EditableSection/v1/EditableSection';
import Wizard from '../../components/Wizard';
import { withProfile } from '../../models/Profile/ProfileProvider';
import { loanPath } from '../../lib/urls';
import { createSteps } from './config';
import type { WizardPageProps } from '..';

const reject = async ({
  loan,
  comment,
  rejectType
}: {
  loan: LoanType,
  comment: ?string,
  rejectType: 'SOFT' | 'HARD'
}) => {
  let didReject = false;

  if (rejectType === 'SOFT') {
    didReject = await loan.softReject({ comment });
  } else if (rejectType === 'HARD') {
    didReject = await loan.reject({ comment });
  }

  if (!didReject) {
    throw loan.errors;
  }
};

const approve = async ({ loan, notes }: { loan: LoanType, notes: ?string }) => {
  const didApprove = await loan.approve({ notes });
  if (!didApprove) {
    throw loan.errors;
  }
};

const includes = ['id_documents'];
export const MemberFetcher = ({ loan, comments, refetchAll, queryClient, ...rest }) => {
  const { baseUrl, match, history, profile } = { ...rest };
  const memberR = useMember(loan.member.id, includes);
  const { permission, AppPermissions } = usePermissions();

  return (
    <Loadable {...memberR}>
      {member => (
        <Updater value={{ onUpdate: refetchAll }}>
          <Wizard
            analyticsId="LoanApprove"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan: loan,
              currentUser: profile,
              comments: comments,
              fullName: loan.member.fullName(),
              firstName: loan.member.firstName,
              identityValue: get('idDocuments[0].documentId', member),
              identityType: get('idDocuments[0].type', member),
              createdAt: new Date(loan.createdAt) // TODO: Format as a relative time
            }}
            cancelReturnsTo={loanPath()}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onReject={reject}
            onSubmit={approve}
            onSubmitCompletion={() => {
              // LoanRouteDecider uses useLoan hook, but LoanApprove
              // uses useFullLoan. This ensures Loan data is
              // fetched again with useLoan after loan is approved.
              queryClient.removeQueries('loan');
            }}
            // security data is already needed in this step,
            // therefore we need to side-load guarantors/collaterals as well
            steps={createSteps(loan, profile, permission.to, AppPermissions)}
            startId="overview"
            titleId="LoanApprove.shortTitle"
            type="approval"
          />
        </Updater>
      )}
    </Loadable>
  );
};

type Props = WizardPageProps<{ loanId: string }> & { profile: UserT };

const LoanApprove = (props: Props) => {
  const { match } = props;
  const activityR = useActivity({ id: match.params.loanId });
  const loanR = useFullLoan(match.params.loanId);

  const queryClient = useQueryClient();
  const refetchAll = () => {
    activityR.refetch();
    queryClient.invalidateQueries('schedule');
    // @TODO investigate data not being deserialized
    // when loan is updated within the wizard
    // As a workaround we force wizard to reload with loanR.remove()
    loanR.remove();
  };

  return (
    <Loadable {...loanR}>
      {loan => (
        <Loadable {...activityR}>
          {activities => {
            const comments = filter(a => a.activityType === 'LOAN_ACCOUNT_COMMENT_ADDED', activities);
            return (
              <MemberFetcher
                loan={loan}
                comments={comments}
                refetchAll={refetchAll}
                queryClient={queryClient}
                {...props}
              />
            );
          }}
        </Loadable>
      )}
    </Loadable>
  );
};

export default withProfile(LoanApprove);
