import * as React from 'react';
import get from 'lodash/fp/get';

import { LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Duration, Text, Currency } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { LoanType } from '@kwara/models/src';

import { useMemberEligibility } from '../../../../../hooks/useRequest';
import { useAnticipatedPayoutApiFetch, useFeeValues } from '../../../hooks';

import { AnticipatedPayoutBreakdown } from '../../../components/AnticipatedPayoutBreakdown';
import { pickMemberEligibilityPerLoanProduct } from '../../../utils/pickMemberEligibilityPerLoanProduct';

type GetAttributeRowsArgTypes = {
  loan: LoanType;
  anticipatedPayout: number | string;
  principalAmount: number | string;
  mambuFees: number | string;
  additionalFees: number | string;
  loanPayoffAmount: number | string;
  isFetchingAnticipatedPayout: boolean;
  eligibleAmount: number;
  isFetchingMemberEligibility: boolean;
};

function getAttributeRows(arg: GetAttributeRowsArgTypes) {
  const {
    loan,
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    isFetchingAnticipatedPayout,
    eligibleAmount,
    isFetchingMemberEligibility
  } = arg;
  const { remittance, loanApplication, disbursementMode } = loan;
  const repaymentMethod = get('method', remittance);

  const attributesRow1 = [
    { title: <Text id="LoanAppraisal.Overview.loanProduct" />, value: loan.name },
    { title: <Text id="LoanAppraisal.Overview.loanID" />, value: loan.id },
    {
      title: <Text id="LoanAppraisal.Overview.eligibleAmount" />,
      value: (
        <If
          condition={isFetchingMemberEligibility}
          do={<LoadingSkeleton />}
          else={<Currency format="currency" value={eligibleAmount} />}
        />
      )
    }
  ];
  const attributesRow2 = [
    { title: <Text id="LoanAppraisal.Overview.repaymentPeriod" />, value: <Duration value={loan.duration} /> },
    {
      title: <Text id="LoanAppraisal.Overview.repaymentFrequency" />,
      value: <Duration value={loan.repaymentFrequency} />
    },
    {
      title: <Text id="LoanAppraisal.Overview.individualInstallmentAmount" />,
      value: <Currency value={get('installmentAmount', loanApplication)} format="currency" />
    }
  ];
  const attributesRow3 = [
    {
      title: <Text id="LoanAppraisal.Overview.totalLoanPayoff" />,
      value: <Currency value={get('totalPayoffAmount', loanApplication)} format="currency" />
    },
    {
      title: <Text id="LoanAppraisal.Overview.totalPayoffInstallmentAmount" />,
      value: <Currency value={get('totalPayoffInstallmentAmount', loanApplication)} format="currency" />
    },
    {
      title: <Text id="LoanAppraisal.Overview.modeOfRepayment" />,
      value: <Text id={`TransactionMethod.${repaymentMethod}`} />
    }
  ];
  const attributesRow4 = [
    {
      title: <Text id="LoanAppraisal.Overview.appliedAmount" />,
      value: <Currency value={loan.amount} format="currency" />
    },
    {
      title: (
        <>
          <Text id="LoanAppraisal.Overview.anticipatedPayout" />
          &nbsp;
          <AnticipatedPayoutBreakdown
            principalAmount={principalAmount}
            mambuFees={mambuFees}
            additionalFees={additionalFees}
            loanPayoffAmount={loanPayoffAmount}
            anticipatedPayout={anticipatedPayout}
          />
        </>
      ),
      value: (
        <If
          condition={isFetchingAnticipatedPayout}
          do={<LoadingSkeleton />}
          else={<Currency format="currency" value={anticipatedPayout} />}
        />
      )
    },
    {
      title: <Text id="LoanAppraisal.Overview.modeOfDisbursement" />,
      value: <Text id={`TransactionMethod.${disbursementMode}`} />
    }
  ];

  return { attributesRow1, attributesRow2, attributesRow3, attributesRow4 };
}

type Args = {
  loan: LoanType;
};
export function useLoanDetailSection(data: Args) {
  const { loan } = data;
  const { member, amount, product, loanApplication = {} } = loan;
  const { payOffLoans = [], loanApplicationFees = [] } = loanApplication;

  const { totalAdditionalFees } = useFeeValues({ product, feeApplications: loanApplicationFees, amount });
  const args = {
    member,
    payOffLoans: payOffLoans.map((loan: LoanType) => loan.id),
    amount,
    product,
    totalAdditionalFees
  };
  const {
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    isFetching: isFetchingAnticipatedPayout
  } = useAnticipatedPayoutApiFetch(args);

  const { data: memberEligibilityData, isFetching: isFetchingMemberEligibility } = useMemberEligibility({
    memberId: get('member.id', data)
  });

  const { eligibleAmount } = pickMemberEligibilityPerLoanProduct(loan, memberEligibilityData);

  const { attributesRow1, attributesRow2, attributesRow3, attributesRow4 } = React.useMemo(
    () =>
      getAttributeRows({
        loan,
        anticipatedPayout,
        principalAmount,
        mambuFees,
        additionalFees,
        loanPayoffAmount,
        isFetchingAnticipatedPayout,
        eligibleAmount,
        isFetchingMemberEligibility
      }),
    [
      loan,
      anticipatedPayout,
      principalAmount,
      mambuFees,
      additionalFees,
      loanPayoffAmount,
      isFetchingAnticipatedPayout,
      eligibleAmount,
      isFetchingMemberEligibility
    ]
  );

  return { attributesRow1, attributesRow2, attributesRow3, attributesRow4 };
}
