//@flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { type TranslationId } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import Visible from '../Visible';
import { type FilterValue, type Filters, type FilterChangeHandler } from './components/Filter';
import { ListActionsDashboard } from './components/ListActionsDashboard';
import { Label } from './components/Label';
import { ActionableHeading } from './components/ActionableHeading';
import styles from './index.module.css';

export { ActionableHeading };

type SearchChangeHandler = (term: string) => void;
type Props = {
  backButton?: React.Element<typeof Button>,
  actions?: (React.Element<typeof Button> | React.Element<typeof Visible>)[] | React.ReactElement,
  headerId?: TranslationId,
  headerValues?: { [k: string]: string },
  heading?: React.ReactNode,
  filterLabelId?: string,
  filterBy?: FilterValue,
  filters?: Filters,
  filterDisabled?: boolean,
  onFilterChange?: FilterChangeHandler,
  labelId: TranslationId,
  onSearchChange?: SearchChangeHandler,
  searchPlaceholderId?: TranslationId,
  searchValue?: string,
  searchClassNames?: string,
  table: React.Element<*>,
  totalNumResults: number,
  items: Array<*>,
  dateRangeConfig?: {
    onDateChange: Function,
    dateValues: { to: Date, from: Date },
    disabledDays?: { before?: Date, after?: Date }
  },
  disabledSearch?: boolean
};

const OverviewList = ({
  backButton,
  filterLabelId,
  actions,
  filterBy,
  filters,
  filterDisabled,
  searchPlaceholderId,
  onFilterChange,
  onSearchChange,
  searchValue,
  searchClassNames,
  dateRangeConfig,
  headerId,
  headerValues,
  heading,
  labelId,
  table,
  totalNumResults,
  items,
  disabledSearch = false,
  otherActions = []
}: Props) => {
  return (
    <div>
      <ActionableHeading
        heading={heading}
        headerId={headerId}
        headerValues={headerValues}
        actions={actions}
        backButton={backButton}
      />
      <div className={`flex items-end mb3 ${styles.Actions}`}>
        <If
          condition={!!labelId}
          do={<Label translationId={labelId} visible={items.length} total={totalNumResults} />}
        />
        <ListActionsDashboard
          actions={otherActions}
          filterBy={filterBy}
          filters={filters}
          filterLabelId={filterLabelId}
          onFilterChange={onFilterChange}
          filterDisabled={filterDisabled}
          onSearchChange={onSearchChange}
          searchPlaceholderId={searchPlaceholderId}
          searchValue={searchValue}
          disabledSearch={disabledSearch}
          searchClassNames={searchClassNames}
          dateRangeConfig={dateRangeConfig}
        />
      </div>
      <div>{table}</div>
    </div>
  );
};

export default OverviewList;
