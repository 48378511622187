import React from 'react';
import { Text } from '@kwara/components/src/Intl';

import { ProductCard } from '../ProductCard';

type Props = { titleId: string; value: React.ReactElement; info?: React.ReactElement };

export function DashboardIndicatorCard({ titleId, value, info }: Props) {
  return <ProductCard role="gridcell" size="responsive" amount={value} subValue={info} title={<Text id={titleId} />} />;
}
