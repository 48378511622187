//@flow
import * as React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import { Tooltip } from '@kwara/components/src/Tooltip';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import Empty from '@kwara/components/src/Empty';
import { Text, type TranslationId } from '@kwara/components/src/Intl';

import Pagination from '../Pagination';
import Collapsible from './Collapsible';
import styles from './index.module.scss';
import Actionable, { type Location } from '@kwara/components/src/Actionable';

export { default as Collapsible } from './Collapsible';

type CellProps = {
  align?: 'left' | 'right' | 'center',
  className?: string,
  to?: Location,
  children?: React.Node
};
const aligns = {
  right: 'tr',
  left: 'tl',
  center: 'tc'
};
export const Cell = ({ align = 'left', className = '', to, ...rest }: CellProps) => {
  const { children, ...props } = rest;
  // TODO this is a hacky approach and a tech debt
  // We are putting an extra element as Actionable that covers the full size area of its
  // parent so that the full cell hence the full row becomes clickable
  const content = to ? (
    <>
      <Actionable to={to} className="relative z-1 no-underline grey-400">
        {children}
      </Actionable>
      <Actionable className={styles.Actionable} to={to}>
        {' '}
      </Actionable>
    </>
  ) : (
    children
  );

  return (
    <td className={classnames(styles.Cell, className, 'bt b--light-grey-500', aligns[align] || 'tl')} {...props}>
      {content}
    </td>
  );
};

type HeadingProps = {
  align?: 'left' | 'right' | 'center',
  children?: React.Node,
  iconSpacer?: boolean,
  translationId?: TranslationId,
  className?: string,
  textSize?: string,
  width?: string,
  values?: { [string]: mixed },
  tooltipId?: string,
  sticky?: boolean
};
export const Heading = ({
  children,
  iconSpacer = false,
  translationId,
  align = 'left',
  className,
  textSize = 'small',
  width = null,
  values,
  tooltipId,
  sticky = false
}: HeadingProps) => (
  <th
    className={classnames(
      styles.Cell,
      styles.Heading,
      `tl kw-text-${textSize || 'small'} kw-weight-bold bg-table-head`,
      aligns[align] || 'tl',
      iconSpacer ? styles.hasIcon : '',
      tooltipId ? styles.hasTooltip : '',
      className,
      sticky ? styles.Sticky : ''
    )}
    style={{ width }}
  >
    <Tooltip tooltipId={tooltipId}>{translationId ? <Text id={translationId} values={values} /> : children}</Tooltip>
  </th>
);

type RowChildElements = React.Element<typeof Cell> | React.Element<typeof Heading>;

const getClassNameFromElement = (element: React.Node): string => {
  if (typeof element !== 'object') {
    return '';
  }

  const maybeClassName = get(element, 'props.className', '');

  if (typeof maybeClassName === 'string') {
    return maybeClassName;
  }

  return '';
};

type RowProps = {
  className?: string,
  children: React.ChildrenArray<RowChildElements>,
  icon?: React.Node,
  secondaryIcon?: React.Node,
  onClick?: () => void,
  onMouseEnter?: () => void,
  size?: 'regular' | 'large'
};
export const Row = ({
  className = '',
  children,
  icon,
  secondaryIcon,
  onClick,
  onMouseEnter = noop,
  size = 'regular',
  to
}: RowProps) => {
  return (
    <tr
      className={classnames(
        to ? styles.isClickable : '',
        size === 'large' ? 'kw-text-large kw-weight-light' : 'kw-text-small',
        styles.Row,
        className
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
      {icon ? (
        <Cell className={styles.hasIcon}>
          {/* $FlowFixMe: cloneElement doesn't seem to work */}
          {React.cloneElement(icon, {
            className: `${getClassNameFromElement(icon) || ''} v-mid`
          })}
        </Cell>
      ) : null}
      {secondaryIcon ? (
        <Cell className={styles.hasIcon} onClick={e => e.stopPropagation()}>
          {/* $FlowFixMe: cloneElement doesn't seem to work */}
          {React.cloneElement(secondaryIcon, {
            className: `${getClassNameFromElement(secondaryIcon) || ''} v-mid`
          })}
        </Cell>
      ) : null}
    </tr>
  );
};

type FooterProps = {
  colSpan: number,
  isLoading: boolean,
  onNext?: () => void,
  hasMore: boolean,
  errors?: Object[] | Response,
  items: any[],
  translationBaseId?: string
};

export const Footer = ({
  colSpan,
  isLoading,
  onNext,
  hasMore,
  errors,
  items,
  translationBaseId = 'Table'
}: FooterProps) => {
  let footer = null;

  if (!isEmpty(errors)) {
    footer = (
      <Empty className="mv4">
        <ModelErrorBanner errors={errors} />
      </Empty>
    );
  } else if (!isLoading && size(items) === 0) {
    footer = (
      <Empty className="mv4">
        <Text id={`${translationBaseId}.empty`} />
      </Empty>
    );
  } else if (onNext) {
    footer = <Pagination hasMore={hasMore} loading={isLoading} onNext={onNext} />;
  }

  return (
    <Row>
      <Cell colSpan={colSpan}>{footer}</Cell>
    </Row>
  );
};

type HeadingsProp = {
  children: React.ChildrenArray<*>
};
export const Headings = ({ children }: HeadingsProp) => <thead>{children}</thead>;

type GroupingRowProps = {
  cols: number,
  children?: React.Node,
  translationId?: TranslationId
};
// TODO: Better way of specifying colspan
export const GroupingRow = ({ cols, children, translationId }: GroupingRowProps) => (
  <Row className={classnames(styles.Row)}>
    <Cell className={classnames(styles.Cell, 'grey-400 kw-text-small')} colSpan={cols}>
      {translationId ? <Text id={translationId} /> : children}
    </Cell>
  </Row>
);

type TableChildElements =
  | React.Element<typeof React.Fragment>
  | React.Element<typeof GroupingRow>
  | React.Element<typeof Collapsible>
  | React.Element<typeof Row>;

type Props = {
  heading?: ?React.Element<typeof Row>,
  footer?: ?React.Element<typeof Footer>,
  emptyState?: React.Element<typeof Row> | React.Element<typeof GroupingRow>,
  children: React.ChildrenArray<TableChildElements>,
  className?: string,
  ariaLabel?: string,
  style?: React.CSSProperties
};

export default ({ footer, heading, emptyState, children, className = '', ariaLabel, style }: Props) => {
  const kids = React.Children.toArray(children);
  const isEmpty = kids.length === 0;
  const body = isEmpty ? emptyState : children;

  return (
    <table style={style} className={`w-100 ${styles.Table} ${className}`} aria-label={ariaLabel}>
      {heading ? <thead>{heading}</thead> : null}
      <tbody>{body}</tbody>
      {footer ? <tfoot>{footer}</tfoot> : null}
    </table>
  );
};
