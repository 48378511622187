import * as React from 'react';
import { Switch } from 'react-router-dom';

import { ForgetPassword, NamedRoute, PasswordReset, NotFound404 } from '@kwara/components/src';

import * as pages from '../../../pages';
import { ROUTES } from '../../utils/routes';
import { useAuth } from '../../../hooks';
import { DevOnlyRoute } from '../DevOnlyRoute/DevOnlyRoute';

export function UnprotectedRoutes() {
  const auth = useAuth();

  return (
    <Switch>
      <NamedRoute name="InvitationRedeem" path={ROUTES.invitationRedeem} component={pages.InvitationRedeem} />
      <NamedRoute name="ConfirmEmail" path={ROUTES.confirm} component={pages.ConfirmEmail} />
      <NamedRoute name="Success" path={ROUTES.success} component={pages.Success} />
      <NamedRoute
        name="ForgetPassword"
        path={ROUTES.forgetPassword}
        render={props => <ForgetPassword<null> {...props} auth={auth} />}
      />
      <NamedRoute
        name="PasswordReset"
        path={ROUTES.passwordReset}
        render={props => <PasswordReset {...props} auth={auth} />}
      />
      <NamedRoute name="Unlock" path={ROUTES.unlock} component={pages.Unlock} />
      <NamedRoute name="Login" path={ROUTES.login} component={pages.LogIn} />
      <DevOnlyRoute name="Debug" path={ROUTES.debug} component={pages.Debug} fallback={NotFound404} />
    </Switch>
  );
}
