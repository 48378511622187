import * as React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';

import { SuggestDates } from '../../../../../components/Filter/utils/suggestDates';
import { FILTER_TYPES } from '../../../../../components/Filter/utils/filterTypes';
import { TextboxBaseFilter, DateBaseFilter, ComboboxBaseFilter } from '../../../../../components/Filter/pages';

///
function ActivityFeedAccountIdFilter() {
  return <TextboxBaseFilter name="Account ID" filterName="accountId" placeholderId="Filter.accountId" />;
}

///
const ActivityFeedDateFilter = () => (
  <DateBaseFilter
    filterType={FILTER_TYPES.ACTIVITY_FEED_DATE}
    disabledDays={{ from: { after: getCurrentDate() }, to: { after: getCurrentDate() } }}
    suggestedDatesLabelId="Filter.activityFeedDate"
    suggestedDates={SuggestDates.generateSuggestedDates()}
  />
);

///
const ActivityFeedEventFilter = () => (
  <ComboboxBaseFilter filterType={FILTER_TYPES.ACTIVITY_FEED_EVENT} placeholderId="Filter.activityFeedEvent" />
);

///
function ActivityFeedMemberIdFilter() {
  return <TextboxBaseFilter name="Member ID" filterName="memberId" placeholderId="Filter.memberId" />;
}

///
const ActivityFeedUserFilter = () => (
  <ComboboxBaseFilter filterType={FILTER_TYPES.ACTIVITY_FEED_USER} placeholderId="Filter.activityFeedUser" />
);

export {
  ActivityFeedAccountIdFilter,
  ActivityFeedDateFilter,
  ActivityFeedEventFilter,
  ActivityFeedMemberIdFilter,
  ActivityFeedUserFilter
};
