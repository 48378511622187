import React from 'react';

import { LoanProductType, CollateralT, Collateral as CollateralModel } from '@kwara/models/src';
import { sumBy } from '@kwara/lib/src/currency';
import { Text, Currency } from '@kwara/components/src';

export function useCollateralsForm({
  collaterals,
  withNarration,
  product,
  onChange
}: {
  onChange: any;
  collaterals: CollateralT[];
  withNarration: boolean;
  product: LoanProductType;
}) {
  const [isEditingACollateral, setIsEditingACollateral] = React.useState(false);
  const [workingIndex, setWorkingIndex] = React.useState<number | null>(null);
  const collateralCardRef = React.useRef(null);

  const isEditingCollateralList = workingIndex != null;
  const totalCollaterals = sumBy(
    'amount',
    collaterals.filter(c => c.amount && !c.isMarkedForDestruction)
  );
  const disabledExpanderAddCollateral =
    isEditingCollateralList || isEditingACollateral || !product?.hasCollateralsEnabled();

  const BASES = React.useMemo(
    () => [
      { id: 'b01', name: <Text id="Collateral.totalAmount" />, width: '48%' },
      { id: 'b02', name: <Currency format="currency" value={totalCollaterals} />, width: '48%' },
      { id: 'b03', name: null, width: '48%' },
      { id: 'b04', name: null, width: '48%', condition: withNarration }
    ],
    [totalCollaterals, withNarration]
  );

  async function onStartAddingCollateral() {
    await onChange({ collaterals: [...collaterals, new CollateralModel()] });
    setWorkingIndex(collaterals.length - 1);
  }

  function hideCollateralEditor() {
    setIsEditingACollateral(false);
    setWorkingIndex(null);
  }

  function onEditStart(index: number) {
    setIsEditingACollateral(true);
    setWorkingIndex(index);
    if (collateralCardRef.current) {
      collateralCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }

  return {
    workingIndex,
    BASES,
    collateralCardRef,
    disabledExpanderAddCollateral,
    onStartAddingCollateral,
    hideCollateralEditor,
    onEditStart,
    isEditingCollateralList,
    isEditingACollateral
  };
}
