// @flow
import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { useCurrentTillBalance } from '../../Till';
import { Panel } from '../../../components/ActionModal';
import { type SubStepComponentProps } from '../../../components/Wizard';

export const Request = ({ TextField, TextArea }: SubStepComponentProps<>) => {
  const { currentBalance } = useCurrentTillBalance(true);

  return (
    <Panel>
      <div className="mb4">
        <span className="kw-text-large kw-weight-bold ">
          <Text id="TillToVaultTransfer.main" />
        </span>
        <br />
        <span className="kw-text-medium grey-400">
          <Text id="TillToVaultTransfer.subtitle" />
        </span>
      </div>
      <TextField
        name="amount"
        labelId="TillToVaultTransfer.CashRequest.label"
        errorBaseId="TillToVaultTransfer.CashRequest"
        leftGlyph="Currency.orgCurrency"
        required
        isCurrency
        type="number"
        infoId={'TillToVaultTransfer.CashRequest.info.label'}
        values={{ currentBalance }}
      />
      <TextArea name="notes" placeholderId="TillToVaultTransfer.Notes.placeholder" labelId="TopupRequest.Notes.label" />
    </Panel>
  );
};

Request.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  },
  notes: {
    isRequired: () => false
  }
};
