import React from 'react';
import get from 'lodash/fp/get';

import { ApiPermissionType } from '../../models/Permission/types';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

type VisiblePropTypes = {
  to?: ApiPermissionType[];
  some?: ApiPermissionType[];
  admin?: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
  checkPermission?(permission: ApiPermissionType[]): boolean;
  checkPermissionOr?(permission: ApiPermissionType[]): boolean;
};

export default function Visible({
  checkPermission,
  checkPermissionOr,
  admin,
  to,
  some,
  fallback,
  children
}: VisiblePropTypes): React.ReactElement {
  const { permission } = usePermissions();
  const store = useSaccoProfileContext();

  const isAdmin = get('profile.isAdmin', store);
  const _checkPermission = checkPermission ?? permission.to;
  const _checkPermissionOr = checkPermissionOr ?? permission.some;

  if (admin && !isAdmin) return <>{fallback || null}</>;
  if (to && _checkPermission(to)) return <>{children}</>;
  if (some && _checkPermissionOr(some)) return <>{children}</>;
  if (fallback) return <>{fallback}</>;

  return <>{null}</>;
}
