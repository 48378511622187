import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';

import { TextField, DatePicker } from '../../Form';

import Filter from './Filter';
import styles from '../index.module.css';

export const ListActionsDashboard = ({
  filters,
  filterLabelId,
  filterBy,
  onFilterChange,
  filterDisabled,
  onSearchChange,
  searchPlaceholderId,
  searchValue,
  searchClassNames,
  disabledSearch,
  dateRangeConfig,
  actions
}) => {
  const onDateChange = get('onDateChange', dateRangeConfig);
  const dateValues = get('dateValues', dateRangeConfig);
  const disabledDays = get('disabledDays', dateRangeConfig);
  const disabledCalendar = get('disabled', dateRangeConfig);

  return (
    <div className="w-100 flex flex-wrap items-end justify-end ml3">
      {actions}
      {filters && (
        <div className="mt2">
          <Filter
            filterLabelId={filterLabelId}
            filterBy={filterBy}
            filters={filters}
            onChange={onFilterChange}
            disabled={filterDisabled}
          />
        </div>
      )}

      {dateRangeConfig
        ? map(
            param => (
              <div key={param} className={cx(styles.DatePickers, 'ml3 mt3')}>
                <DatePicker
                  name={param}
                  value={dateValues[param]}
                  dateValues={dateValues}
                  onChange={val => onDateChange(param, val)}
                  disabledDays={disabledDays}
                  disabled={disabledCalendar}
                  size="regular"
                  component={props => (
                    <TextField
                      name={param}
                      border={false}
                      leftGlyph={`OverviewList.dateFilter.${param}.label`}
                      glyphColClass="grey-400"
                      size="regular"
                      {...props}
                      compact
                      onChange={noop}
                    />
                  )}
                />
              </div>
            ),
            ['from', 'to']
          )
        : null}

      {onSearchChange && (
        <div className={cx(searchClassNames, 'ml3 mt2')}>
          <TextField
            compact
            border={false}
            leftGlyph="Search"
            name="search"
            onChange={evt => {
              onSearchChange(evt.target.value);
            }}
            placeholderId={searchPlaceholderId}
            size="regular"
            value={searchValue || ''}
            disabled={disabledSearch}
          />
        </div>
      )}
    </div>
  );
};
