import * as React from 'react';
import includes from 'lodash/fp/includes';
import toLower from 'lodash/fp/toLower';
import invoke from 'lodash/fp/invoke';
import pipe from 'lodash/fp/pipe';

import { AttachmentT } from '@kwara/models/src';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

import { ActionModal, Panel } from '../../../../webapp-sacco/src/components/ActionModal';
import { toBase64Img } from '../utils';

type Props = {
  isClosed: boolean;
  onCancel: (e: MouseEvent) => void;
  titleId: string;
  attachment: AttachmentT;
  details: {
    name?: string;
    filesize?: number;
    type?: string;
    filename?: string;
  };
};

export function getIsImg(details: { type?: string }) {
  return pipe(invoke('type.toLowerCase'), val =>
    includes(val, ['png', 'jpg', 'jpeg', 'image/jpeg', 'image/jpg', 'image/png'])
  )(details);
}

export function ViewerModal({ isClosed, onCancel, titleId, attachment, details }: Props) {
  const isImg = getIsImg(details);

  return (
    <If
      condition={!isClosed}
      do={
        <ActionModal header titleId={titleId} onCancel={onCancel} actions={false} childSize="widest">
          <Panel>
            <div className="tc pt5">
              <If
                condition={isImg}
                do={
                  <If
                    condition={!!attachment.content}
                    do={<img alt="attachment" src={`${toBase64Img(attachment.content, details.type)}`} />}
                    else={<Text id="DocumentUploads.Loading.label" />}
                  />
                }
                else={
                  <>
                    <Text id="DocumentUploads.DownloadWarning" />{' '}
                    <a
                      href={`data:application/octet-stream;base64,${attachment.content}`}
                      download={
                        details.filename ? toLower(details.filename) : `${details.name}.${toLower(details.type)}`
                      }
                    >
                      <Text id="DocumentUploads.DownloadText" />{' '}
                    </a>
                  </>
                }
              />
            </div>
          </Panel>
        </ActionModal>
      }
    />
  );
}
