import React from 'react';

export function useDownloadButton({
  onClick,
  instance,
  downloadFn,
  disabled
}: {
  onClick?: Function;
  instance?: any;
  downloadFn: () => Promise<void>;
  disabled: boolean;
}) {
  const [hasErrors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const isDisabled = disabled || isLoading;

  const promise = instance ? downloadFn.bind(instance) : downloadFn;

  const onDownload = async () => {
    setErrors(false);
    setIsLoading(true);
    return await promise()
      .catch(() => {
        setErrors(true);
      })
      .finally(() => {
        onClick && onClick();
        setIsLoading(false);
      });
  };

  return { onDownload, isDisabled, hasErrors, isLoading };
}
