import partition from 'lodash/fp/partition';
import sortBy from 'lodash/fp/sortBy';
import toString from 'lodash/fp/toString';
import pipe from 'lodash/fp/pipe';

import { SavingProductType } from '@kwara/models/src/models/Base';
import { useSavingProducts } from '../../../../../../hooks';
import { useSaccoProfileContext } from '../../../../../../models/Profile/ProfileProvider';

export function useFetchAndPartitionSavingProducts() {
  const store = useSaccoProfileContext();
  const shareCapitalProductId = store.shareCapitalProductId;

  const savingsProductsResponse = useSavingProducts();
  const sortedSavingProducts = sortBy(product => product.name, savingsProductsResponse.data);

  const [shareCapitalProducts, savingProducts] = pipe(
    sortBy((product: SavingProductType) => product.name),
    partition((product: SavingProductType) => toString(product.id) === toString(shareCapitalProductId))
  )(sortedSavingProducts);

  return { ...savingsProductsResponse, data: { shareCapitalProducts, savingProducts } };
}
