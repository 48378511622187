import React from 'react';

import { sumBy } from '@kwara/lib/src/currency';
import { Text, Currency } from '@kwara/components/src';
import { CollateralType } from '@kwara/models/src';

import { TITLES } from '../../CollateralsImp';

type Props = {
  collaterals: CollateralType[];
  withNarration?: boolean;
};

export function useCollateralsLister({ collaterals, withNarration }: Props) {
  const totalCollaterals = sumBy(
    'amount',
    collaterals.filter(c => c.amount && !c.isMarkedForDestruction)
  );

  const BASES = React.useMemo(
    () => [
      { id: 'b01', name: <Text id="Collateral.totalAmount" />, width: '48%' },
      { id: 'b02', name: <Currency format="currency" value={totalCollaterals} />, width: '48%' },
      { id: 'b03', name: null, width: '48%' },
      { id: 'b04', name: null },
      { id: 'b05', name: null, width: '48%', condition: withNarration }
    ],
    [totalCollaterals, withNarration]
  );

  return { BASES, TITLES };
}
