import { useQuery } from 'react-query';

import { SavingsMetric } from '@kwara/models/src';

async function fetchSavingsOverviewMetrics() {
  const res = await SavingsMetric.all();
  return res.data;
}

export const useSavingsOverviewMetrics = () => useQuery(['savings_overview_metrics'], fetchSavingsOverviewMetrics);
