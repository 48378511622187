// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { RequiredAsterisk } from '@kwara/components/src/Form';
import { useBankAccounts } from '@kwara/models/src/models/request/hooks';
import { type MemberType, BankAccount, BankBranch, Bank as BankModel } from '@kwara/models/src';

import BankForm from './components/BankForm';
import { EditableSection } from '../../../../../../../components/EditableSection/v2/EditableSection';

const includes = ['bank_branch.bank'];
const config = {
  Component: BankForm.Component,
  validate: BankForm.validate
};

function BankHeader() {
  return <Text id="MemberDetail.bank" />;
}

type BankPropTypes = {
  member: MemberType
};
function BankBody({ member }: BankPropTypes) {
  const bankAccountsRequest = useBankAccounts(member.id, includes);

  return (
    <>
      <label className="neutral-700">
        <Text id="MemberDetail.bankDetails" />
        <RequiredAsterisk />
      </label>
      <Loadable {...bankAccountsRequest}>
        {bankAccounts => (
          <>
            {map(
              account => (
                /* Renders existing accounts in editable mode */
                <EditableSection key={account.id} config={config} initialData={account} />
              ),
              bankAccounts
            )}
          </>
        )}
      </Loadable>
      {/* Allows adding of a new account */}
      <EditableSection
        config={config}
        initialData={
          new BankAccount({
            mambuId: member.id,
            bankBranch: new BankBranch({ bank: new BankModel() })
          })
        }
      />
    </>
  );
}

export default {
  Title: BankHeader,
  Body: BankBody
};
