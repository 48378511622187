import React from 'react';
import noop from 'lodash/fp/noop';
import cx from 'classnames';

import { ReportFieldT, ReportFieldGlAccountLinkT, GlAccountT } from '@kwara/models/src';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import Table, { Heading, Row, Cell, Footer } from '../../../../components/Table';

import { ReportConfigForm } from '../Form/ReportConfigForm';
import styles from './ReportConfigTable.module.css';

function GlListItem({
  link,
  onRemove,
  field
}: {
  link: ReportFieldGlAccountLinkT;
  field: ReportFieldT;
  onRemove(args: { link: ReportFieldGlAccountLinkT; field: ReportFieldT }): void;
}) {
  return (
    <li key={link.id}>
      {`${link.glAccount.glCode} (${link.glAccount.glName})`}{' '}
      <span role="button" className="pointer red-500" onClick={() => onRemove({ link, field })}>
        <Text id="SasraReportConfig.Form.button.unlink" />
      </span>
    </li>
  );
}

function GlCellContent({ field, onAdd, glAccounts, onRemoveLink, scalar }) {
  return (
    <If
      condition={field.isGlField()}
      do={
        <div className="h-100 flex flex-column justify-between">
          <If
            condition={field.reportFieldGlAccountLinks.length > 0}
            do={
              <ul className={cx(styles['gl-list'], 'pa0 list')}>
                {field.reportFieldGlAccountLinks
                  .filter(link => link.scalar === scalar)
                  .map(link => (
                    <GlListItem key={link.id} field={field} link={link} onRemove={onRemoveLink} />
                  ))}
              </ul>
            }
          />
          <ReportConfigForm onAdd={onAdd} glAccounts={glAccounts} field={field} scalar={scalar} />
        </div>
      }
    />
  );
}

export function ReportConfigTable({
  fields = [],
  glAccounts = [],
  onRemoveLink,
  onAdd,
  isLoading
}: {
  fields: ReportFieldT[];
  glAccounts: GlAccountT[];
  onRemoveLink(link: ReportFieldGlAccountLinkT): void;
  onAdd(data): void;
  isLoading: boolean;
}) {
  return (
    <Table
      // Hack alert: https://limebrains.com/blog/2021-03-02T13:00-heigh-100-inside-table-td/
      style={{ height: '1px' }}
      heading={
        <Row>
          <Heading translationId="SasraReportConfig.Table.header.refNo" />
          <Heading translationId="SasraReportConfig.Table.header.name" />
          <Heading translationId="SasraReportConfig.Table.header.glAccountsPositive" />
          <Heading translationId="SasraReportConfig.Table.header.glAccountsNegative" />
        </Row>
      }
      footer={<Footer onNext={noop} colSpan={4} isLoading={isLoading} hasMore={false} items={fields} />}
    >
      {fields.map(field => {
        return (
          <Row key={field.id}>
            <Cell>{field.code}</Cell>
            <Cell>{field.name}</Cell>
            <Cell>
              <GlCellContent
                glAccounts={glAccounts}
                field={field}
                onRemoveLink={onRemoveLink}
                onAdd={onAdd}
                scalar={1}
              />
            </Cell>
            <Cell>
              <GlCellContent
                glAccounts={glAccounts}
                field={field}
                onRemoveLink={onRemoveLink}
                onAdd={onAdd}
                scalar={-1}
              />
            </Cell>
          </Row>
        );
      })}
    </Table>
  );
}
