import { Form } from './components/Form';

export const steps = {
  renew: {
    titleId: 'RenewSMS.Form.title',
    subtitleId: 'RenewSMS.Form.subtitle',
    Component: Form,
    validate: Form.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
