import React from 'react';

import { LoanType } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { LoanCard } from '../LoanCard';
import { HorizontalScroller } from '../HorizontalScroller';
import { useLoansForMember } from '../../hooks';

import styles from './index.module.scss';

interface PropTypes {
  memberId: string;
}

export const LoansHorizontalScroller = ({ memberId }: PropTypes) => {
  const activeLoansR = useLoansForMember(memberId, { where: { state: 'ACTIVE,APPROVED,PENDING_APPROVAL' } });
  return (
    <section className={styles.section}>
      <WizardSectionTitle id="LoanAdd.LoanPortfolio.LoanCategories.AllLoans.title" />
      <Loadable {...activeLoansR}>
        {activeLoans => {
          return (
            <HorizontalScroller<LoanType> data={activeLoans} fallbackId="LoanAdd.LoanPortfolio.Loans.noLoans">
              {({ startIndex, endingIndex }) => {
                const currentLoans = activeLoans.slice(startIndex, endingIndex);

                return (
                  <ul className={styles.loansListbox}>
                    {currentLoans.map(loan => (
                      <li key={loan.id}>
                        <LoanCard loan={loan} showState />
                      </li>
                    ))}
                  </ul>
                );
              }}
            </HorizontalScroller>
          );
        }}
      </Loadable>
    </section>
  );
};
