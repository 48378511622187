import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import { Currency, Text } from '@kwara/components/src/Intl';
import {
  AccountingReportT,
  AccountType,
  GlAccountType,
  AmountsType,
  TrialBalanceTotalsType
} from '@kwara/models/src/models/AccountingReport';
import Table, { Row, Heading, Cell } from '../../../../components/Table';

export function TrialBalance({ data }: { data: AccountingReportT }) {
  const accounts: AccountType[] = get('trialBalance.accounts', data);
  const totals: TrialBalanceTotalsType = get('trialBalance.totals', data);

  return (
    <Table
      heading={
        <Row>
          <Heading width="150px" translationId="AccountingReports.TrialBalance.Table.header.glCode" />
          <Heading width="350px" translationId="AccountingReports.TrialBalance.Table.header.accountName" />
          <Heading align="right" translationId="AccountingReports.TrialBalance.Table.header.debits" />
          <Heading align="right" translationId="AccountingReports.TrialBalance.Table.header.credits" />
        </Row>
      }
    >
      {map(account => {
        const glAccount: GlAccountType = get('gl_account', account);
        const amounts: AmountsType = get('amounts', account);
        const rowColor = glAccount.usage === 'HEADER' ? 'neutral-500' : 'black';
        return (
          <Row key={glAccount.encoded_key} className={rowColor}>
            <Cell>{glAccount.gl_code}</Cell>
            <Cell>{glAccount.name}</Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={amounts.debit_amount} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={amounts.credit_amount} />
            </Cell>
          </Row>
        );
      }, accounts)}
      <Row>
        <Cell />
        <Cell>
          <Text id="AccountingReports.TrialBalance.Cell.totals" />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={totals.total_debit_amounts} />
        </Cell>
        <Cell align="right">
          <Currency hideDecimals={false} value={totals.total_credit_amounts} />
        </Cell>
      </Row>
    </Table>
  );
}
