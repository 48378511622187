import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { LoanSubstepProps } from 'GlobalTypes';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

import { Container } from '../../../../../layouts';
import { Notes } from '../../../../../components/Notes/Notes';
import { AttributesRow } from '../../../../../components/AttributesRow';
import { CreditReportResult } from '../components/CreditReportResult';

/**
 * The @CreditReport component is rendered in loan-appraisal and loan-disbursal flow
 */
const CREDIT_REPORT_CONSUMER_PATHNAMES = {
  APPRAISALS: '/appraisal',
  DISBURSAL: '/disbursal'
};

interface CreditReportPropTypes extends LoanSubstepProps {
  children: React.ReactNode;
}

export function CreditReport({ formProps, data, Checkbox }: CreditReportPropTypes) {
  const { identityType, identityValue, member } = data;

  const { pathname } = useLocation();

  /**
   * @shownNote notes node is only rendered in loan-appraisal flow
   */
  const shownNote = pathname.includes(CREDIT_REPORT_CONSUMER_PATHNAMES.APPRAISALS);

  return (
    <>
      <Container.Root>
        <Container.Heading id="LoanAppraisal.CreditCheck.detailsRequested" />
        <Container.Body>
          <AttributesRow
            attributes={[
              { title: <Text id="LoanAppraisal.CreditCheck.fullName" />, value: member.fullName() },
              {
                title: <Text id="LoanAppraisal.CreditCheck.identityType" />,
                //Some identityType come as uppercase and vice-versa so
                // we want to ensure the format is consistent using ```toUpperCase``` below
                value: <Text id={`LoanAppraisal.CreditCheck.identityType.${identityType?.toUpperCase()}`} />
              },
              { title: <Text id="LoanAppraisal.CreditCheck.identification" />, value: identityValue }
            ]}
          />
        </Container.Body>
      </Container.Root>

      <Container.Root>
        <Container.Heading id="LoanAppraisal.CreditCheck.mcrbIdVerResults" />
        <Container.Body>
          <AttributesRow
            attributes={[
              { title: <Text id="LoanAppraisal.CreditCheck.firstName" />, value: member.firstName },
              { title: <Text id="LoanAppraisal.CreditCheck.lastName" />, value: member.lastName },
              { title: <Text id="LoanAppraisal.CreditCheck.gender" />, value: member.gender },
              { title: <Text id="LoanAppraisal.CreditCheck.dob" />, value: member.formattedDateOfBirth() }
            ]}
          />
        </Container.Body>
      </Container.Root>
      <CreditReportResult data={data} Checkbox={Checkbox} />

      <If
        condition={shownNote}
        do={<Notes name="notes.credit_check.no_section" className="mt3" formValues={formProps.values} />}
      />
    </>
  );
}

export default {
  Component: CreditReport,
  validate: {
    creditCheckConfirm: {
      isRequired: () => true
    }
  }
};
