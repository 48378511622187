// Mambu Permissions
// i.e. deprecated permissions

export const ApiPermissions = {
  // Comments
  CREATE_COMMENTS: 'CREATE_COMMENTS',
  VIEW_COMMENTS: 'VIEW_COMMENTS',

  // Journal Entries
  LOG_JOURNAL_ENTRIES: 'LOG_JOURNAL_ENTRIES',

  // Members
  APPROVE_CLIENT: 'APPROVE_CLIENT',
  CREATE_CLIENT: 'CREATE_CLIENT',
  EXIT_CLIENT: 'EXIT_CLIENT',
  REJECT_CLIENT: 'REJECT_CLIENT',
  VIEW_CLIENT_DETAILS: 'VIEW_CLIENT_DETAILS',

  // Savings
  APPLY_SAVINGS_FEES: 'APPLY_SAVINGS_FEES',
  CLOSE_SAVINGS_ACCOUNTS: 'CLOSE_SAVINGS_ACCOUNTS',
  CREATE_SAVINGS_ACCOUNT: 'CREATE_SAVINGS_ACCOUNT',
  MAKE_DEPOSIT: 'MAKE_DEPOSIT',
  MAKE_WITHDRAWAL: 'MAKE_WITHDRAWAL',

  // Loans
  APPLY_LOAN_ADJUSTMENTS: 'APPLY_LOAN_ADJUSTMENTS',
  APPLY_LOAN_FEES: 'APPLY_LOAN_FEES',
  APPROVE_LOANS: 'APPROVE_LOANS',
  CLOSE_LOAN_ACCOUNTS: 'CLOSE_LOAN_ACCOUNTS',
  CREATE_LOAN_ACCOUNT: 'CREATE_LOAN_ACCOUNT',
  DIBURSE_LOANS: 'DIBURSE_LOANS',
  EDIT_PENALTY_RATE: 'EDIT_PENALTY_RATE',
  ENTER_REPAYMENT: 'ENTER_REPAYMENT',
  PAY_OFF_LOAN: 'PAY_OFF_LOAN',
  REFINANCE_LOAN_ACCOUNT: 'REFINANCE_LOAN_ACCOUNT',
  REJECT_LOANS: 'REJECT_LOANS',
  RESCHEDULE_LOAN_ACCOUNT: 'RESCHEDULE_LOAN_ACCOUNT',
  SET_DISBURSEMENT_CONDITIONS: 'SET_DISBURSEMENT_CONDITIONS',
  VIEW_LOAN_ACCOUNT_DETAILS: 'VIEW_LOAN_ACCOUNT_DETAILS',
  VIEW_LOANS: 'VIEW_LOANS',
  WRITE_OFF_LOAN_ACCOUNTS: 'WRITE_OFF_LOAN_ACCOUNTS',

  // Loan Products
  VIEW_LOAN_PRODUCT_DETAILS: 'VIEW_LOAN_PRODUCT_DETAILS',

  // Transfers
  MAKE_INTER_CLIENTS_TRANSFERS: 'MAKE_INTER_CLIENTS_TRANSFERS',
  MAKE_TRANSFER: 'MAKE_TRANSFER'
};
