import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import noop from 'lodash/fp/noop';
import { RequireAtLeastOne } from 'GlobalTypes';

import { If } from '@kwara/components/src';
import { NamedRoute } from '@kwara/components/src/Route';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import Visible from '../../../components/Visible';
import { ApiPermissionType } from '../../../models/Permission/types';

type DefaultParamsType<ParamsType> = { [K in keyof ParamsType]?: string };
interface BasePermittedRoutePropTypes<ParamsType extends DefaultParamsType<ParamsType>> {
  admin?: boolean;
  permission: ApiPermissionType[];
  permissionSome: ApiPermissionType[];
  path?: string;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
  name: string;
  fallback?: (props: RouteComponentProps<ParamsType>) => React.ReactElement | ReturnType<typeof noop>;
  render?: (props: RouteComponentProps<ParamsType>) => React.ReactElement | ReturnType<typeof noop>;
  component?: React.ComponentType<RouteComponentProps>;
}

type PermittedRoutePropTypes<ParamsType extends DefaultParamsType<ParamsType>> = RequireAtLeastOne<
  BasePermittedRoutePropTypes<ParamsType>,
  'permission' | 'permissionSome'
>;

export function PermittedRoute<ParamsType extends DefaultParamsType<ParamsType>>({
  admin,
  permission,
  permissionSome,
  component: Component,
  render = noop,
  fallback = () => <PermissionDenied />,
  ...rest
}: PermittedRoutePropTypes<ParamsType>) {
  return (
    <NamedRoute
      {...rest}
      render={props => (
        <Visible
          to={permission}
          some={permissionSome}
          fallback={fallback(props as RouteComponentProps<ParamsType>) as React.ReactNode}
          admin={admin}
        >
          <If
            condition={Component !== undefined}
            do={Component && <Component {...props} />}
            else={render(props as RouteComponentProps<ParamsType>) as React.ReactNode}
          />
        </Visible>
      )}
    />
  );
}
