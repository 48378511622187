import * as React from 'react';
import map from 'lodash/fp/map';
import { Field as FinalFormField } from 'react-final-form';

import { composeEvents } from '@kwara/lib/src/utils/composeEvents';
import { InputProps, FieldProps } from '@kwara/components/src/Form/index.types';
import { Radio, Field, TextArea, RadioIcon } from '@kwara/components/src/Form';

type CombinedPropTypes = {} & InputProps & FieldProps;

/********************************************************************* */
interface SubscribedTextAreaProps extends CombinedPropTypes {
  placeholderId?: string;
}
function SubscribedTextArea({
  disabled,
  name,
  errorBaseId,
  required,
  titleId,
  info,
  infoId,
  labelId,
  values,
  placeholderId,
  margin,
  onChange
}: SubscribedTextAreaProps) {
  return (
    <FinalFormField
      name={name}
      labelId={labelId}
      render={({ input, meta }) => {
        const { onChange: inputOnChange } = input;

        return (
          <Field
            error={meta.error && meta.touched}
            name={input.name}
            required={required}
            size="medium"
            titleId={titleId}
            labelId={labelId}
            errorBaseId={errorBaseId}
            errorCodes={meta.error}
            infoId={infoId}
            values={values}
            margin={margin}
            info={info}
          >
            <TextArea
              disabled={disabled}
              name={name}
              placeholderId={placeholderId}
              size="medium"
              {...input}
              onChange={composeEvents(inputOnChange, onChange)}
            />
          </Field>
        );
      }}
    />
  );
}

/********************************************************************* */
interface SubscribedRadioGroupProps extends CombinedPropTypes {
  disabled?: boolean;
  flex?: boolean;
  items: Array<{
    labelId: string;
    titleId?: string;
    value: string;
    assetId?: string;
    inputOnChange?: (evt: { target: { value: string } }) => void;
  }>;
}
function SubscribedRadioGroup({
  disabled,
  flex = true,
  required,
  name,
  titleId,
  labelId,
  info,
  items,
  values
}: SubscribedRadioGroupProps) {
  return (
    <Field
      name={name}
      size="medium"
      required={required}
      titleId={titleId}
      labelId={labelId}
      info={info}
      values={values}
    >
      <div className={flex ? 'flex' : ''}>
        {map(
          ({ labelId, titleId, value, inputOnChange, assetId }) => (
            <FinalFormField
              key={value}
              name={name}
              type="radio"
              value={value}
              render={({ input }) => {
                if (assetId) {
                  return (
                    <RadioIcon
                      disabled={disabled}
                      {...input}
                      assetId={assetId}
                      labelId={labelId}
                      inputOnChange={inputOnChange}
                    />
                  );
                }
                return (
                  <Radio
                    disabled={disabled}
                    {...input}
                    size="medium"
                    labelId={labelId}
                    titleId={titleId}
                    inputOnChange={inputOnChange}
                  />
                );
              }}
            />
          ),
          items
        )}
      </div>
    </Field>
  );
}

export { SubscribedTextArea, SubscribedRadioGroup };
