import { useQuery } from 'react-query';

import { MobileBankingUsersStats } from '@kwara/models/src';

const fetchMobileBankingUsersStats = async ({ queryKey }) => {
  const [_, where] = queryKey;
  const res = await MobileBankingUsersStats.where(where).find();
  return res.data;
};

export const useMobileBankingUsersStats = (where: { start_date: string; end_date: string }) =>
  useQuery(['mobile_banking_users_stats', where], fetchMobileBankingUsersStats);
