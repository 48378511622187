// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type TillSessionT, Metric, type TillTransactionT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';

import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { useTillSession, useTillMetrics, useTillTransactions } from '../../hooks';
import { AsNavigation, BackToTopNavigation } from '../../components/DetailNavigation';
import { DetailSection } from '../../components/Detail/DetailSection';
import Head from '../../components/Head';
import { Overview } from './components/Overview';
import { Transactions } from './components/Transactions';

type Props = {
  showMargins: boolean,
  showOverviewActions: boolean,
  match: {
    params: {
      tillSessionId: string
    }
  }
};

type TillStats = {
  receipts: string,
  payments: string,
  deposits: string,
  withdrawals: string
};

export type TillWithStatsT = TillSessionT & TillStats;

// Currently the full TillSessionDetail data must come from two endpoints
// This function is responsible for merging the data into one object
// The TD story to clean this up is here [ch7258]:
// https://app.clubhouse.io/getkwara/story/7258/metrics-on-individual-tills-page
function useMergedTillData(tillSessionId) {
  const till = useTillSession(tillSessionId);
  const metrics = useTillMetrics(tillSessionId);

  const data = {
    id: get('data.id', till),
    updatedAt: get('data.updatedAt', till),
    status: get('data.status', till),
    tellerId: get('data.tellerId', till),
    currentAmount: get('data.currentAmount', till),
    tellerName: get('data.tellerName', till),
    deposits: get('value.value', Metric.findFromArray(metrics.data, 'cash_deposits')),
    withdrawals: get('value.value', Metric.findFromArray(metrics.data, 'cash_withdrawals')),
    payments: get('value.value', Metric.findFromArray(metrics.data, 'utility_payments')),
    receipts: get('value.value', Metric.findFromArray(metrics.data, 'utility_receipts')),
    session: get('data', till)
  };

  return {
    data,
    isLoading: till.isLoading || metrics.isLoading,
    error: till.error || metrics.error
  };
}

const renderBody = ({
  tillSession,
  transactions
}: {
  tillSession: TillWithStatsT,
  transactions: TillTransactionT[]
}) => {
  return (
    <>
      <Head titleId="TillSessionDetail.pageTitle" values={{ id: tillSession.tellerName }} />
      <DetailSection
        id="transactions"
        title={<Text id="TillSessionDetail.transactions" />}
        subtitle={<BackToTopNavigation />}
        headerRight={
          <DownloadButton instance={tillSession.session} downloadFn={tillSession.session.tillTransactionsPdf} />
        }
      >
        <Transactions transactions={transactions} />
      </DetailSection>
    </>
  );
};

export const TillSessionDetail = ({ showMargins = true, showOverviewActions = true, ...props }: Props) => {
  const results = useMergedTillData(props.match.params.tillSessionId);
  const r2 = useTillTransactions(props.match.params.tillSessionId);

  return (
    <Loadable {...results}>
      {tillSession => {
        return (
          <Loadable {...r2}>
            {transactions => {
              const body = renderBody({ tillSession, transactions });
              const Navigation = AsNavigation(body);
              return (
                <PageLayoutWithOverview
                  className={showMargins ? 'pt5' : ''}
                  overview={
                    <Overview
                      navigation={<Navigation />}
                      tillSession={tillSession}
                      showBack={showOverviewActions}
                      actions={[]}
                    />
                  }
                >
                  {body}
                </PageLayoutWithOverview>
              );
            }}
          </Loadable>
        );
      }}
    </Loadable>
  );
};
