import * as React from 'react';
import cx from 'classnames';

import { Currency, Text } from '@kwara/components/src/Intl';
import { AccountType } from '@kwara/models/src/models/AccountingReport';

import { If } from '@kwara/components/src/If/If';
import { Row, Cell } from '../../../../../components/Table';

import styles from '../../../index.module.scss';

export function TableSection({
  accounts = [],
  total,
  totalLabelId,
  additionalRows = []
}: {
  accounts: AccountType[];
  total: number;
  totalLabelId: string;
  additionalRows?: React.ReactElement[];
}) {
  return (
    <>
      {accounts.map((account, index) => {
        const isHeaderAccount = account.gl_account.usage === 'HEADER';

        return (
          <Row key={account.gl_account.encoded_key}>
            <Cell className="b">
              <If
                condition={index === 0}
                do={<Text id={`AccountingReports.BalanceSheet.Table.type.${account.gl_account.type}`} />}
              />
            </Cell>
            <Cell className={cx({ 'neutral-500': isHeaderAccount })}>{account.gl_account.gl_code}</Cell>
            <Cell className={cx({ 'neutral-500': isHeaderAccount })}>{account.gl_account.name}</Cell>
            <Cell className={cx({ 'neutral-500': isHeaderAccount })} align="right">
              <Currency hideDecimals={false} value={account.amounts.closing_balance} />
            </Cell>
            <Cell />
          </Row>
        );
      })}
      {additionalRows}
      <Row className={styles.totalRow}>
        <Cell />
        <Cell />
        <Cell className={styles.totalLabel}>{<Text id={totalLabelId} />}</Cell>
        <Cell className="kw-text-medium b" align="right">
          <Currency hideDecimals={false} value={total} />
        </Cell>
        <Cell />
      </Row>
    </>
  );
}
