// @flow

import * as React from 'react';

import { type UserT } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';
import { DownloadButton } from '@kwara/components/src/DownloadButton';

import { useTillTransaction } from '../../hooks';
import Head from '../../components/Head';
import Overview from './components/Overview';

type Props = {
  profile: UserT,
  showMargins?: boolean,
  showOverviewActions?: boolean,
  match: {
    params: {
      transactionId: string
    }
  }
};

const includes = ['member.attachments'];

export const TransactionDetail = (props: Props) => {
  const { showMargins = true, showOverviewActions = true } = props;

  const r = useTillTransaction(props.match.params.transactionId, includes);

  return (
    <Loadable {...r}>
      {transaction => {
        const printButton = (
          <div className="flex justify-end">
            <DownloadButton instance={transaction} downloadFn={transaction.downloadTextReceipt} />
          </div>
        );

        return (
          <PageLayoutWithOverview
            className={showMargins ? 'pt5' : ''}
            overview={<Overview transaction={transaction} showBack={showOverviewActions} actions={[printButton]} />}
          >
            <Head titleId="TransactionDetail.pageTitle" values={{ id: transaction.id }} />
          </PageLayoutWithOverview>
        );
      }}
    </Loadable>
  );
};
