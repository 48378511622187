import React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import invoke from 'lodash/fp/invoke';

import { ERRORS } from '@kwara/lib/src/validator';
import { If } from '@kwara/components/src/If/If';
import { Text, getTranslationIdByApp } from '@kwara/components/src/Intl';

import { LoanAddPropTypes, LoanAddData } from '../../..';

function getMinMaxLoanProductAmount(data: LoanAddData) {
  const { maximum: principalMax, minimum: principalMin } = getOr({}, 'product.amount', data);

  return { principalMax, principalMin };
}

export const PrincipalAmountField = ({ data, TextField }: LoanAddPropTypes) => {
  const fullName = invoke('member.fullName', data);
  const eligibleAmount = get('eligibility.eligibleAmount', data.product);
  const { principalMax, principalMin } = getMinMaxLoanProductAmount(data);

  return (
    <TextField
      required
      isCurrency
      sensitiveValidation
      margin={false}
      name="amount"
      type="number"
      pattern="\\d*"
      inputMode="decimal"
      leftGlyph="Currency.orgCurrency"
      errorBaseId="LoanAdd.Configure.PrincipalAmount"
      labelId={getTranslationIdByApp('LoanAdd.Configure.PrincipalAmount.title')}
      errorValues={{ principalMax, principalMin, fullName, eligibleAmount }}
      info={
        <If
          condition={!!principalMax || !!eligibleAmount}
          do={
            <ul>
              <If
                condition={!!principalMax}
                do={
                  <li>
                    <Text
                      id={'LoanAdd.Configure.PrincipalAmount.maximumAmount'}
                      values={{ principalMax, principalMin }}
                    />
                  </li>
                }
              />
              <If
                condition={!!eligibleAmount}
                do={
                  <li>
                    <Text id="LoanAdd.Configure.PrincipalAmount.eligibleAmount" values={{ eligibleAmount }} />
                  </li>
                }
              />
            </ul>
          }
        />
      }
    />
  );
};

PrincipalAmountField.validate = {
  amount: {
    currency: true,
    nonZero: true,
    positiveInteger: true,
    isRequired: () => true,
    custom: (target: string, allData: LoanAddData) => {
      const { principalMax, principalMin } = getMinMaxLoanProductAmount(allData);
      const num = Number(target);

      if (principalMin != null && num < principalMin) return ERRORS.rangeUnderflow;

      if (principalMax != null && num > principalMax) return ERRORS.rangeOverflow;

      return null;
    }
  }
};
