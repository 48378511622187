import * as React from 'react';
import cx from 'classnames';

import StatisticRow from '@kwara/components/src/StatisticRow';
import { DateTime, Text } from '@kwara/components/src/Intl';
import { Metric } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';

import Dashboard from '../../../../components/Dashboard';
import { DashboardMetric } from '../../../../components/DashboardMetric';
import { useTillUIContext } from '../..';

function TillActions() {
  const { updateModal, currentTillSession, isTillLoading, isTillOpen } = useTillUIContext();

  return (
    <>
      {[
        isTillOpen ? (
          <div className="tr" key="endTillSession">
            <Button
              onClick={() => updateModal({ activeModal: 'confirmClose' })}
              size="regular"
              disabled={isTillLoading}
              type="secondary"
            >
              <Text id="TillTransactionsList.endTillSession" />
            </Button>
            <div className={cx('mt2 kw-text-2x-small grey-500')}>
              <Text
                id="Till.Dashboard.sessionInfo1"
                values={{ id: currentTillSession.id, name: currentTillSession.till.name }}
              />
              <br />
              <Text
                id="Till.Dashboard.sessionInfo2"
                values={{ dateTime: <DateTime value={currentTillSession.createdAt} /> }}
              />
            </div>
          </div>
        ) : null,
        isTillOpen ? null : (
          <Button
            onClick={() => updateModal({ activeModal: 'confirmOpen' })}
            size="regular"
            className="dib"
            disabled={isTillLoading}
            type="primary"
            key="startTillSession"
          >
            <Text id="TillTransactionsList.startTillSession" />
          </Button>
        )
      ]}
    </>
  );
}

export function TillDashboard() {
  const { metrics: data, isTillOpen } = useTillUIContext();

  const placeholderMetric = { value: { value: '-', type: '-' } };
  const metrics = isTillOpen ? data : [];

  return (
    <Dashboard actions={<TillActions />}>
      <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
        <DashboardMetric
          title={<Text id="Till.Dashboard.currentBalance" />}
          metric={Metric.findFromArray(metrics, 'current_balance') || placeholderMetric}
        />
      </StatisticRow>
      <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
        <DashboardMetric
          title={<Text id="Till.Dashboard.deposits" />}
          metric={Metric.findFromArray(metrics, 'cash_deposits') || placeholderMetric}
        />
        <DashboardMetric
          title={<Text id="Till.Dashboard.utilityReceipts" />}
          metric={Metric.findFromArray(metrics, 'utility_receipts') || placeholderMetric}
        />
      </StatisticRow>
      <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
        <DashboardMetric
          color="teal-600"
          title={<Text id="Till.Dashboard.withdrawals" />}
          metric={Metric.findFromArray(metrics, 'cash_withdrawals') || placeholderMetric}
        />
        <DashboardMetric
          color="teal-600"
          title={<Text id="Till.Dashboard.utilityPayments" />}
          metric={Metric.findFromArray(metrics, 'utility_payments') || placeholderMetric}
        />
      </StatisticRow>
    </Dashboard>
  );
}
