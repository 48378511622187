//@flow
import * as React from 'react';
import size from 'lodash/fp/size';

import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';

import { useSavingProducts } from '../../../../hooks';
import { ProductSelector } from './ProductSelector';

export const Accounts = ({ StackChild }) => {
  const r = useSavingProducts();

  return (
    <StackChild size="wide">
      <Loadable {...r}>
        {products =>
          size(products) > 0 ? (
            <ProductSelector products={products} />
          ) : (
            <div className="tc">
              <Text id="MemberApprove.Accounts.noProducts" />
            </div>
          )
        }
      </Loadable>
    </StackChild>
  );
};
