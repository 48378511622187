import * as React from 'react';
import { Form } from 'react-final-form';

import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import createValidator from '@kwara/lib/src/validator';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import {
  SubscribedSelectField,
  SubscribedTextField,
  Field as BasicField,
  TextField as BasicTextField
} from '@kwara/components/src/Form';
import { FeeType } from '@kwara/models/src/models/LoanProduct';
import { If } from '@kwara/components/src/If/If';

import styles from '../index.module.scss';

type Props = {
  fees: FeeType[];
  fee?: FeeType;
  valid: boolean;
  onAddFee: () => void;
};
export function AddFeeFormInputs({ fees, fee, valid, onAddFee }: Props) {
  const isPercentageAmount = !!get('percentageAmount', fee);
  const canEditAmount = !Boolean(get('amount', fee) || isPercentageAmount);
  const glyphId = isPercentageAmount ? 'General.%' : 'Currency.orgCurrency';

  const hasFees = size(fees) > 0;

  return (
    <div aria-label="Fee Inputs" className={styles.feeInputs}>
      <SubscribedSelectField
        disabled={!hasFees}
        showInfo={false}
        name="feeId"
        labelId="LoanAdd.LoanConfigure.Fees.Fee.label"
      >
        <If
          condition={hasFees}
          do={
            <>
              <SubscribedSelectField.Option value="" translationId="LoanAdd.LoanConfigure.Fees.Fee.default" />
              {map(
                fee => (
                  <SubscribedSelectField.Option key={fee.id} value={fee.id}>
                    {fee.name}
                  </SubscribedSelectField.Option>
                ),
                fees
              )}
            </>
          }
          else={<SubscribedSelectField.Option translationId="LoanAdd.LoanConfigure.Fees.Fee.empty" value="" />}
        />
      </SubscribedSelectField>

      <If
        condition={canEditAmount && !!fee}
        do={
          <SubscribedTextField
            showInfo={false}
            leftGlyph="Currency.orgCurrency"
            isCurrency
            name="amount"
            labelId="LoanAdd.LoanConfigure.Fees.Amount.label"
          />
        }
        else={
          <BasicField name="amount" disabled size="medium" labelId="LoanAdd.LoanConfigure.Fees.Amount.label">
            <BasicTextField
              name="amount"
              leftGlyph={glyphId}
              value={get('amount', fee) || get('percentageAmount', fee) || ''}
              size="medium"
              readOnly
              disabled
            />
          </BasicField>
        }
      />

      <Button disabled={!valid} onClick={onAddFee}>
        <Text id="LoanAdd.LoanConfigure.Fees.button.addFee" />
      </Button>
    </div>
  );
}

export function AddFeeForm({ getFee, fees, onAddFee }) {
  return (
    <Form
      onSubmit={data => {
        onAddFee(data);
      }}
      validate={createValidator({
        feeId: {
          isRequired: () => true
        },
        amount: {
          isRequired: (_, { feeId }: { feeId?: string }) => {
            if (!feeId) {
              return false;
            }
            const fee = getFee(feeId);
            return !fee.amount && !fee.percentageAmount;
          },
          currency: true
        }
      })}
      render={({ form, valid, handleSubmit, values }) => {
        const { feeId } = values;
        const fee = getFee(feeId);

        return (
          <AddFeeFormInputs
            fees={fees}
            fee={fee}
            onAddFee={() => {
              handleSubmit();
              form.reset();
            }}
            valid={valid}
          />
        );
      }}
    />
  );
}
