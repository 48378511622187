import React from 'react';

import TableComponent from '../../../../../../components/Table';

import { TBody } from './TableBody';
import { THead } from './TableHeader';
import { TFooter } from './TableFooter';

type TablePropTypes = {
  thead: React.ReactElement;
  tbody: React.ReactElement;
  tfooter: React.ReactElement;
};

function Table(props: TablePropTypes) {
  return (
    <TableComponent heading={props.thead} footer={props.tfooter}>
      {props.tbody}
    </TableComponent>
  );
}

export default {
  Root: Table,
  Head: THead,
  Body: TBody,
  Footer: TFooter
};
