import * as React from 'react';
import cx from 'classnames';
import pipe from 'lodash/fp/pipe';
import chunk from 'lodash/fp/chunk';
import split from 'lodash/fp/split';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';

import Banner from '@kwara/components/src/Banner';
import { LoadingKwara, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { segmentTrack } from '@kwara/components/src/Segment';

import { Grid } from '../../../components/Grid';

import styles from '../index.module.css';

export const processSecret = (secret: string) =>
  pipe(
    split(''),
    chunk(4),
    map(c => join('', c)),
    join(' ')
  )(secret);

export function ScanQR({
  qr,
  secret,
  onDismiss,
  onNext
}: {
  qr: string | null;
  secret: string | null;
  onDismiss: Function;
  onNext: Function;
}) {
  const [showSecret, setShowSecret] = React.useState(false);
  const [showQr, setShowQr] = React.useState(false);

  const toggleSecret = () => setShowSecret(show => !show);
  const toggleQr = () => setShowQr(show => !show);

  return (
    <>
      <h1 className="kw-text-2x-large kw-weight-bold mb2">
        <Text id="Activate2FA.ScanQR.title" />
      </h1>
      <p className="grey-400">
        <Text id="Activate2FA.ScanQR.subtitle" />
      </p>
      <Banner className="mt2" state="warning" text={<Text id="Activate2FA.ScanQR.warning" />} />

      <Grid className="mt4" columns={2} width="w-50" border={false}>
        <div onClick={toggleQr} className="tc">
          <div className={cx(styles.QrContainer)}>
            {qr ? (
              <img
                className={cx({
                  [styles.blurQrImg]: !showQr
                })}
                alt="qr-code"
                src={qr}
              />
            ) : (
              <div className={styles.loadingContainer}>
                <LoadingKwara />
              </div>
            )}
          </div>
          <p className="kw-text-small grey-400">
            <Text id="Activate2FA.ScanQR.clickToReveal" />
          </p>
        </div>
        <div onClick={toggleSecret} className={styles.SecretContainer}>
          <p className="kw-text-small grey-400">
            <Text id="Activate2FA.ScanQR.qrAlternative" />
          </p>
          <span
            aria-label="qr-secret"
            className={cx('kw-weight-bold', {
              'indigo-600': showSecret,
              [styles.blurText]: !showSecret
            })}
          >
            {secret ? processSecret(secret) : <LoadingSkeleton />}
          </span>
          <p className="kw-text-small grey-400">
            <Text id="Activate2FA.ScanQR.clickToReveal" />
          </p>
        </div>
      </Grid>

      <div className="mt4 flex justify-between">
        <Button
          onClick={() => {
            onDismiss();
            segmentTrack('2FA Activation Skipped');
          }}
        >
          <Text id="Activate2FA.button.skip" />
        </Button>
        <Button type="primary" onClick={onNext}>
          <Text id="Activate2FA.button.next" />
        </Button>
      </div>
    </>
  );
}
