// @flow

import * as React from 'react';
import flatMap from 'lodash/flatMap';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import type { GuaranteeType, LoanType, MemberType } from '@kwara/models/src';
import { Link } from '@kwara/components/src/Link';

import { memberPath } from '../../../lib/urls';

const Guarantor = ({ member }: { member: MemberType }) => {
  return <Link to={memberPath({ id: member.id })}>{member.fullName()}</Link>;
};

const LoanGuarantors = ({ loan }: { loan: LoanType }) => {
  const guarantors = flatMap(loan.guarantees, (guarantee: GuaranteeType, index: number) => {
    const member = get('member', guarantee);
    if (member) {
      const node = <Guarantor key={member.id} member={member} />;
      return index === 0 ? node : [', ', node];
    }
  });

  return <>{size(guarantors) > 0 ? guarantors : '-'}</>;
};

export default LoanGuarantors;
