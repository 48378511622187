import { JournalEntry } from '@kwara/models/src';
import { getCurrentDate, formatIsoDate } from '@kwara/lib/src/dates';

import { useFilterContext } from '../../../../components/Filter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { DATE_NAMES } from '../../../../components/Filter/pages/DateBaseFilter/useDateBaseFilter';
import { useOverviewList, fetchWithPagination, PaginationQueryType } from '../../../../models/hooks/base';

const GET_JOURNAL_ENTRY_QUERY_KEY = 'journal_entries';
const includes = ['gl_account', 'branch'];

async function fetchJournalEntries(opts: PaginationQueryType) {
  return fetchWithPagination(JournalEntry, opts);
}

function useFilter() {
  const { getValueFor } = useFilterContext();

  const currentDate = formatIsoDate(getCurrentDate());
  const gl_code = getValueFor(FILTER_TYPES.GL_ACCOUNT) as string;
  const filter = {
    filterValue: gl_code,
    where: {
      gl_code,
      branch_id: getValueFor(FILTER_TYPES.BRANCH_ID),
      from: getValueFor(FILTER_TYPES.DATE, DATE_NAMES.FROM) || currentDate,
      to: getValueFor(FILTER_TYPES.DATE, DATE_NAMES.TO) || currentDate
    }
  };

  return filter;
}

function usePaginatedJournalEntry() {
  return useOverviewList({
    queryKey: GET_JOURNAL_ENTRY_QUERY_KEY,
    fetchFn: fetchJournalEntries,
    includes,
    useWhere: useFilter
  });
}

export { GET_JOURNAL_ENTRY_QUERY_KEY, usePaginatedJournalEntry };
