import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Currency } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { type DisbursedLoanT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import Table, { Row, Heading, Cell, Footer } from '../../../../components/Table';

type Props = {
  loans: DisbursedLoanT[]
};

export const LoanDisbursementTable = ({ loans, ...rest }: Props) => {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.memberId" />
          <Heading width="170px" translationId="Finance.LoanDisbursementReport.Table.header.productDetails" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.disbursementMode" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.loanAmountAppliedFor" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.loanAmountApproved" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.totalAmountForLoansPaidOff" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.disbursementFee" />
          <Heading translationId="Finance.LoanDisbursementReport.Table.header.netAmount" />
        </Row>
      }
      footer={<Footer items={loans} colSpan={8} {...rest} />}
    >
      {map(loan => {
        return (
          <Row key={loan.id}>
            <Cell>{loan.memberId}</Cell>
            <Cell>
              <StatusTag state={loan.state} />
              &middot;
              <span className="kw-text-small grey-400">{loan.id}</span>
              <br />
              {loan.productName}
            </Cell>
            <Cell align="center">
              <Text id={`TransactionMethod.${get('disbursementMode', loan)}`} />
            </Cell>
            <Cell align="center">
              <Currency hideDecimals={false} value={loan.loanAmountAppliedFor} />
            </Cell>
            <Cell align="center">
              <Currency hideDecimals={false} value={loan.loanAmountApproved} />
            </Cell>
            <Cell align="center">
              <Currency hideDecimals={false} value={loan.totalAmountForLoansPaidOff} />
            </Cell>
            <Cell align="center">
              <Currency hideDecimals={false} value={loan.disbursementFee} />
            </Cell>
            <Cell align="center">
              <Currency hideDecimals={false} value={loan.netDisbursedAmount} />
            </Cell>
          </Row>
        );
      }, loans)}
    </Table>
  );
};
