import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { CurrentRouteNameProvider } from '.';

interface NamedRoutePropTypes extends RouteProps {
  name: string;
}

const NamedRoute = ({ name, ...rest }: NamedRoutePropTypes) => (
  <CurrentRouteNameProvider value={name}>
    <Route {...rest} />
  </CurrentRouteNameProvider>
);

export default NamedRoute;
