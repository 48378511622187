import React from 'react';

import StatusTag from '@kwara/components/src/StatusTag';

import { SubscribedCheckbox } from '@kwara/components/src/Form';
import { LoanDisbursementType } from '@kwara/models/src/models/LoanDisbursement';
import { Currency, Duration, Percent, DateTime } from '@kwara/components/src/Intl';

import { Column } from './Column';
import { GenerateLoanDisbursementData } from './GenerateLoanDisbursementData';
import { Row, Cell, Collapsible } from '../../../../../../../components/Table';

export type TBodyPropTypes = {
  canReviewLoan: boolean;
  loanDisbursements: LoanDisbursementType[];
};

export function TBody({ loanDisbursements, canReviewLoan }: TBodyPropTypes) {
  return (
    <>
      {loanDisbursements.map(loanDisbursement => {
        const data = new GenerateLoanDisbursementData(loanDisbursement);

        return (
          <Collapsible key={loanDisbursement.id}>
            <Row className="kw-text-small">
              <Cell>
                <SubscribedCheckbox
                  name="loanDisbursementId"
                  showInfo={false}
                  value={loanDisbursement.id}
                  hidden={data.hideId(canReviewLoan)}
                />
              </Cell>
              <Cell title={loanDisbursement.loanId}>{loanDisbursement.loanId}</Cell>
              <Cell>{loanDisbursement.memberName}</Cell>
              <Cell>{data.loan?.name}</Cell>
              <Cell>
                <Currency value={loanDisbursement.amount} />
              </Cell>
              <Cell>
                <Percent value={data.percentage} />
              </Cell>
              <Cell>{data.loan?.duration ? <Duration value={data.loan.duration} /> : '-'}</Cell>
              <Cell>{data.branchName}</Cell>
              <Cell>
                <StatusTag state={loanDisbursement.state?.toUpperCase?.()} />
              </Cell>
              <Cell>
                <StatusTag state={loanDisbursement.onlinePayoutStatus?.toUpperCase?.()} />
              </Cell>
            </Row>
            <Row className="tbody-details-row bg-light-grey-300">
              <Column
                colSpan={4}
                titleTranslationId="LoanInitiation.title"
                listItems={[
                  { key: 'LoanInitiation.initiatedAmount', value: <Currency value={data.initiatedAmount} /> },
                  { key: 'LoanInitiation.initiatedBy', value: data.initiatedBy },
                  { key: 'LoanInitiation.initiatedAt', value: <DateTime value={data.initiatedAt} /> }
                ]}
              />
              <Column
                colSpan={2}
                titleTranslationId="LoanApproval.title"
                listItems={[
                  { key: 'LoanApproval.approvedAmount', value: <Currency value={data.approvedAmount} /> },
                  { key: 'LoanApproval.approvedBy', value: data.approvedBy },
                  { key: 'LoanApproval.approvedAt', value: <DateTime value={data.approvedAt} /> }
                ]}
              />
              <Column
                colSpan={4}
                titleTranslationId="LoanDisbursement.title"
                listItems={[
                  { key: 'LoanDisbursement.requestedAmount', value: <Currency value={data.requestedAmount} /> },
                  { key: 'LoanDisbursement.requestedBy', value: data.requestedBy },
                  { key: 'LoanDisbursement.requestedAt', value: <DateTime value={data.requestedAt} /> },
                  { key: data.stateInfo.key, value: loanDisbursement.reviewedBy, hidden: !data.stateInfo.shouldRender }
                ]}
              />
            </Row>
          </Collapsible>
        );
      })}
    </>
  );
}
