import * as React from 'react';

import { Render } from '@kwara/components/src';
import { LoanApplicationT } from '@kwara/models/src/models/LoanApplication';
import { LoanAttachmentT } from '@kwara/models/src/models/LoanAttachment';

import { DocumentItem } from '../../../DocumentList/DocumentItem';
import { WithViewer } from '../CollateralUploadWidget/components/Viewer';

type DocumentViewerV2PropTypes = {
  loanApplication: LoanApplicationT;
  attachments: LoanAttachmentT[];
};

export function CollateralDocumentList({ loanApplication, attachments }: DocumentViewerV2PropTypes) {
  return (
    <Render condition={!!attachments?.length} fallbackId="DocumentUploads.noDocuments">
      <WithViewer attachments={attachments} loanApplication={loanApplication}>
        {({ setShownAttachment }) => (
          <>
            {attachments.map(attachment => (
              <DocumentItem
                key={attachment.id}
                name={attachment.name}
                onClickHandler={() => setShownAttachment(attachment)}
              />
            ))}
          </>
        )}
      </WithViewer>
    </Render>
  );
}
