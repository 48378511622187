import { useQuery } from 'react-query';

import { Indicators } from '@kwara/models/src';

const fetchDashboardIndicators = async () => {
  const res = await Indicators.find();
  return res.data;
};

export const useDashboardIndicators = () => useQuery(['dashboard_indicator'], fetchDashboardIndicators);
