import * as React from 'react';
import posed from 'react-pose';
import { spring } from 'popmotion';

///UTILS BELOW
/************************************************* */
function createSpring(props: any) {
  return spring({
    ...props,
    stiffness: 500, // default: 500
    damping: 27, // default: 25
    restDelta: 0.5, // default: 0.5
    restSpeed: 10 // default: 10
  });
}

const Container = posed.div({
  visible: {
    y: '0',
    rotate: '0',
    delayChildren: 100,
    transition: createSpring
  },
  hidden: {
    y: window.innerHeight + 100,
    rotate: '-10deg',
    transition: createSpring
  }
});

///COMPONENT BELOW
/* **************************************************************** */
type DelayedAnimationPropTypes = {
  onDone: () => void;
  children: (props: { hide: () => void }) => React.ReactNode;
};

type StateTypes = {
  done: boolean;
  visible: boolean;
  state: 'stopped' | 'running' | 'completed';
};

export function DelayedAnimation(props: DelayedAnimationPropTypes) {
  const [state, setState] = React.useState<StateTypes>({ done: false, visible: false, state: 'stopped' });

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null!;

    timeout = setTimeout(() => setState(prevState => ({ ...prevState, visible: true })), 500);

    return function releaseMemorySpace() {
      clearTimeout(timeout);
    };
  }, []);

  function onHide() {
    setState(prevState => ({ ...prevState, done: true, visible: false }));
  }

  function onComplete() {
    if (state.done) {
      props.onDone();
    }
  }

  return (
    <Container onPoseComplete={onComplete} pose={state.visible ? 'visible' : 'hidden'}>
      {props.children({ hide: onHide })}
    </Container>
  );
}
