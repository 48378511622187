// @flow

export type ModelError = {
  code?: string,
  title?: string,
  detail?: ?string,
  attribute?: string,
  fullMessage?: string,
  rowIndex?: ?number
};

export type ModelErrors = {
  [attribute: string]: ModelError
};

export { configureModels } from './models/Base';
export type { IncludesT, WhereT } from './models/Base';

export { default as Activity } from './models/Activity';
export type { ActivityType } from './models/Activity';

export type CancellablePromise = Promise<*> & { cancel: () => void };

export const createCancellablePromise = (promise: Promise<*>, cancelSource): CancellablePromise => {
  const cancellable: any = promise.then();

  cancellable.cancel = () => {
    cancelSource.cancel();
  };

  return (cancellable: CancellablePromise);
};

export { AccountingReport } from './models/AccountingReport';
export type { AccountingReportT } from './models/AccountingReport';

export { Bank } from './models/Bank';
export type { BankT } from './models/Bank';

export { BankAccount } from './models/BankAccount';
export type { BankAccountT } from './models/BankAccount';

export { BankBranch } from './models/BankBranch';
export type { BankBranchT } from './models/BankBranch';

export { BankGlAccount, GlContexts } from './models/BankGlAccount';
export type { BankGlAccountT, GlContextsT } from './models/BankGlAccount';

export { BatchTransactionImport } from './models/BatchTransactionImport';
export type { BatchTransactionImportT, BatchTransactionPayloadT } from './models/BatchTransactionImport';

export { BatchTransaction } from './models/BatchTransaction';
export type { BatchTransactionT } from './models/BatchTransaction';

export { Branch } from './models/Branch';
export type { BranchT } from './models/Branch';

export { BranchAddress } from './models/BranchAddress';
export type { BranchAddressT } from './models/BranchAddress';

export { ConnectUser, ConnectUserBase } from './models/ConnectUser';
export type { ConnectUserT } from './models/ConnectUser';

export { CrbDataSubmission } from './models/CrbDataSubmission';
export type { CrbDataSubmissionT } from './models/CrbDataSubmission';

export { ChequeTransactions } from './models/ChequeTransactions';

export { DirectDebit } from './models/DirectDebit';
export type { DirectDebitT } from './models/DirectDebit';

export { DirectDebitReport } from './models/DirectDebitReport';
export type { DirectDebitReportT } from './models/DirectDebitReport';

export { DirectDebitOrder } from './models/DirectDebitOrder';
export type { DirectDebitOrderT } from './models/DirectDebitOrder';

export { Event } from './models/Event';
export type { EventT } from './models/Event';

export { GlAccount } from './models/GlAccount';
export type { GlAccountT } from './models/GlAccount';

export { LoanAccountCrbSubmissions } from './models/LoanAccountCrbSubmissions';
export type { LoanAccountCrbSubmissionsT } from './models/LoanAccountCrbSubmissions';

export { LoanPurpose } from './models/LoanPurpose';
export type { LoanPurposeT } from './models/LoanPurpose';

export { LoanClassification } from './models/LoanClassification';
export type { LoanClassificationT } from './models/LoanClassification';

export { LoanClassificationReport } from './models/LoanClassificationReport';
export type { LoanClassificationReportT } from './models/LoanClassificationReport';

export { default as CustomField } from './models/CustomField';
export type { CustomFieldType } from './models/CustomField';

export { DividendCalculation } from './models/DividendCalculation';
export type { DividendCalculationT } from './models/DividendCalculation';

export { IdDocument, StandardIdDocumentTypes } from './models/IdDocument';
export type { IdDocumentType, StandardIdDocumentType, CustomIdDocumentType } from './models/IdDocument';

export { GeneralLedgerAccount } from './models/GeneralLedgerAccount';
export type { GeneralLedgerAccountT } from './models/GeneralLedgerAccount';

export { default as Guarantee, Guarantor, DeactivatedGuarantee, MemberGuarantee } from './models/Guarantee';
export type { GuaranteeType, PotentialGuarantorType } from './models/Guarantee';

export { JournalEntry } from './models/JournalEntry';
export type { JournalEntryT } from './models/JournalEntry';

export { DisbursedLoan, ACTIVE_FROM } from './models/DisbursedLoan';
export type { DisbursedLoanT } from './models/DisbursedLoan';

export { LoanV2 } from './models/LoanV2';
export type { LoanV2Type } from './models/LoanV2';

export { SavingMemberStatement, LoanMemberStatement } from './models/MemberStatement';

export { default as Loan, LoanStates, LoanBaseStates, fields as LoanFields } from './models/Loan';
export type { LoanType, LoanState, LoanBaseState, LoanId, DisbursalInfo } from './models/Loan';

export { LoanApplication, Actions } from './models/LoanApplication';
export type { LoanApplicationT, FeeApplicationT, DisbursementTypeT } from './models/LoanApplication';

export { LoanApplicationFee } from './models/LoanApplicationFee';

export { LoanApplicationApproval } from './models/LoanApplicationApproval';
export type { LoanApplicationApprovalT } from './models/LoanApplicationApproval';

export { LoanApplicationNote } from './models/LoanApplicationNote';
export type { LoanApplicationNoteT } from './models/LoanApplicationNote';

export { LoanApprovalTierSet } from './models/LoanApprovalTierSet';
export type { LoanApprovalTierSetT } from './models/LoanApprovalTierSet';

export { LoanApprovalSetting } from './models/LoanApprovalSetting';
export type { LoanApprovalSettingT } from './models/LoanApprovalSetting';

export { LoanApprovalTier } from './models/LoanApprovalTier';
export type { LoanApprovalTierT } from './models/LoanApprovalTier';

export { default as LoanMetric } from './models/LoanMetric';

export { PhoneNumberVerification } from './models/PhoneNumberVerification';
export type { PhoneNumberVerificationT } from './models/PhoneNumberVerification';

export { default as LoanProduct, PeriodUnits } from './models/LoanProduct';

export type { LoanProductType, PeriodUnitsT } from './models/LoanProduct';

export { LoanProductConfig } from './models/LoanProductConfig';
export type { LoanProductConfigT } from './models/LoanProductConfig';

export { LoanProductStanding } from './models/LoanProductStanding';
export type { LoanProductStandingT } from './models/LoanProductStanding';

export { default as LoanTransaction, PendingLoanTransaction } from './models/LoanTransaction';
export type { LoanTransactionType } from './models/LoanTransaction';

export { default as MemberAddress } from './models/MemberAddress';
export type { MemberAddressType } from './models/MemberAddress';

export { Attachment, Attachments, allowedExtensions, mimeTypesMaps } from './models/Attachment';
export type { AttachmentT, FileType } from './models/Attachment';

export { LoanAttachment } from './models/LoanAttachment';
export type { LoanAttachmentT } from './models/LoanAttachment';

export { Comment } from './models/Comment';
export type { CommentT } from './models/Comment';

export { CheckableItem } from './models/CheckableItem';

export { default as Member, MemberStates, MemberStatesV1, EmploymentStatuses, Titles } from './models/Member';
export type { MemberType, MemberState, EmploymentStatusT } from './models/Member';

export { MemberLoan } from './models/MemberLoan';

export { MemberSaving } from './models/MemberSaving';

export { MemberEligibility } from './models/MemberEligibility';
export type { MemberEligibilityT } from './models/MemberEligibility';

export { default as MemberMetric } from './models/MemberMetric';

export { default as Metric } from './models/Metric';
export type { MetricType } from './models/Metric';

export { MemberReport } from './models/MemberReport';
export type { MemberReportT } from './models/MemberReport';

export { MemberSavingsHabit } from './models/MemberSavingsHabit';
export type { MemberSavingsHabitT } from './models/MemberSavingsHabit';

export { Organisation } from './models/Organisation';
export type { OrganisationT } from './models/Organisation';

export { OrganisationSetting } from './models/OrganisationSetting';
export type { OrganisationSettingT } from './models/OrganisationSetting';

export { default as Repayment } from './models/Repayment';
export type { RepaymentType } from './models/Repayment';

export { MpesaTransaction } from './models/MpesaTransaction';
export { MpesaTransactionRegistrationFee } from './models/MpesaTransactionRegistrationFee';
export type { MpesaTransactionType } from './models/Repayment';

export { B2CTransaction } from './models/B2CTransaction';
export type { B2CTransactionT } from './models/B2CTransaction';

export { C2BTransaction } from './models/C2BTransaction';
export type { C2BTransactionT } from './models/C2BTransaction';

export { StkPushTransaction } from './models/StkPushTransaction';
export type { StkPushTransactionT } from './models/StkPushTransaction';

export { MpesaLimit } from './models/MpesaLimit';
export type { MpesaLimitType } from './models/MpesaLimit';

export { MpesaCredentials } from './models/MpesaCredentials';
export type { MpesaCredentialsT } from './models/MpesaCredentials';

export { ReconciliationStatement } from './models/ReconciliationStatement';
export type { ReconciliationStatementT } from './models/ReconciliationStatement';

export { ReportField, ReportFields } from './models/ReportField';
export type { ReportFieldT } from './models/ReportField';

export { ReportFieldGlAccountLink } from './models/ReportFieldGlAccountLink';
export type { ReportFieldGlAccountLinkT } from './models/ReportFieldGlAccountLink';

export { ReportTemplate } from './models/ReportTemplate';
export type { ReportTemplateT } from './models/ReportTemplate';

export { SasraReport } from './models/SasraReport';
export type { SasraReportT } from './models/SasraReport';

export { OperationalReport } from './models/OperationalReport';
export type { OperationalReportT } from './models/OperationalReport';

export { default as Saving, SavingStates } from './models/Saving';
export type { SavingType, SavingState } from './models/Saving';

export { default as SavingsMetric } from './models/SavingsMetric';

export { SmsBundle } from './models/SmsBundle';
export type { SmsBundleT } from './models/SmsBundle';

export { SmsPlan } from './models/SmsPlan';
export type { SmsPlanT } from './models/SmsPlan';

export { SmsPayment } from './models/SmsPayment';
export type { SmsPaymentT } from './models/SmsPayment';

export { default as Schedule } from './models/Schedule';
export type { ScheduleType } from './models/Schedule';

export { default as SavingProduct } from './models/SavingProduct';
export type { SavingProductType, SavingProductId } from './models/SavingProduct';

export { NextOfKin } from './models/NextOfKin';
export type { NextOfKinT } from './models/NextOfKin';

export { Role } from './models/Role';
export type { RoleT } from './models/Role';

export { AppRole } from './models/AppRole';
export type { AppRoleT } from './models/AppRole';

export { AppPermission, KwaraPermissionsConfig } from './models/AppPermission';
export type { AppPermissionT } from './models/AppPermission';

export { default as SavingsTransaction, PendingSavingsTransaction } from './models/SavingsTransaction';
export type { SavingsTransactionType } from './models/SavingsTransaction';

export { PendingLoanDisbursement, DisbursementStates } from './models/LoanDisbursement';
export type { LoanDisbursementType, DisbursementStatesType } from './models/LoanDisbursement';

export { LoanDisbursementAppraisals } from './models/LoanDisbursementAppraisals';
export type { LoanDisbursementAppraisalsType } from './models/LoanDisbursementAppraisals';

export { SavingRemittance, LoanRemittance } from './models/Remittance';
export type { SavingRemittanceT, LoanRemittanceT } from './models/Remittance';

export { SupervisorMetric } from './models/SupervisorMetric';
export type { SupervisorMetricT } from './models/SupervisorMetric';

export { Till } from './models/Till';
export type { TillT } from './models/Till';

export { TillTellerLink } from './models/TillTellerLink';
export type { TillTellerLinkT } from './models/TillTellerLink';

export { TillSession, CurrentTillSession } from './models/TillSession';
export type { TillSessionT, TillSessionStatusT } from './models/TillSession';

export { TillTransaction, TillTransactionTypes } from './models/TillTransaction';
export type { TillTransactionT } from './models/TillTransaction';

export { TillMetric } from './models/TillMetric';
export type { TillMetricT } from './models/TillMetric';

export { TillTransactionDeprecated, getTransactionAmount } from './models/TillTransactionDeprecated';
export type { TillTransactionDeprecatedType } from './models/TillTransactionDeprecated';

export { TopupRequest, TopupRequestStatus } from './models/TopupRequest';
export type { TopupRequestT, TopupRequestStatusT } from './models/TopupRequest';

export class SearchCancellation extends Error {}

export { default as Transaction } from './models/Transaction';
export type { TransactionType } from './models/Transaction';
export { TransactionChannels } from './models/Transactions';
export type { TransactionChannelT } from './models/Transactions';

export { TransactionChannel as MambuTransactionChannel } from './models/TransactionChannel';
export type { TransactionChannelT as MambuTransactionChannelT } from './models/TransactionChannel';

export { Lead } from './models/Lead';

export { LoginActivity } from './models/LoginActivity';
export type { LoginActivityT } from './models/LoginActivity';

export { User, Profile } from './models/User';
export type { UserT } from './models/User';

export { UserTypes } from './models/userTypes';
export type { UserType } from './models/userTypes';

export { UtilityTransaction } from './models/UtilityTransaction';

export { default as Invitation } from './models/Invitation';
export type { UserDetails } from './models/Invitation';

export { InvitationModel } from './models/InvitationModel';
export type { InvitationModelT } from './models/InvitationModel';

export { Collateral, CollateralTypes, CollateralFields } from './models/Collateral';
export type { CollateralT, CollateralTypeT } from './models/Collateral';

export { default as cameliseObjectKeys } from './lib/cameliseObjectKeys';
export { snakeCaseObjectKeys } from './lib/snakeCaseObjectKeys';

export { RepaymentAmount } from './models/RepaymentAmount';
export type { RepaymentAmountT } from './models/RepaymentAmount';

export { Indicators } from './models/Dashboard/Indicators';
export type { IndicatorsT } from './models/Dashboard/Indicators';

export { SavingsTransactionsStats } from './models/Dashboard/SavingsTransactionsStats';
export type { SavingsTransactionsStatsT } from './models/Dashboard/SavingsTransactionsStats';

export { MemberBaseStats } from './models/Dashboard/MemberBaseStats';
export type { MemberBaseStatsT } from './models/Dashboard/MemberBaseStats';

export { LoanDisbursementsStats } from './models/Dashboard/LoanDisbursementsStats';
export type { LoanDisbursementsStatsT } from './models/Dashboard/LoanDisbursementsStats';

export { MobileBankingUsersStats } from './models/Dashboard/MobileBankingUsersStats';
export type { MobileBankingUsersStatsT } from './models/Dashboard/MobileBankingUsersStats';

export { BranchMembershipStats } from './models/Dashboard/BranchMembershipStats';
export type { BranchMembershipStatsT } from './models/Dashboard/BranchMembershipStats';

export { DistributionOfDepositsStats } from './models/Dashboard/DistributionOfDepositsStats';
export type { DistributionOfDepositsStatsT } from './models/Dashboard/DistributionOfDepositsStats';

export { DistributionOfLoansStats } from './models/Dashboard/DistributionOfLoansStats';
export type { DistributionOfLoansStatsT } from './models/Dashboard/DistributionOfLoansStats';

export { PhoneNumberInfo } from './models/PhoneNumberInfo';
export type { PhoneNumberInfoType } from './models/PhoneNumberInfo';

export { FloatBalance } from './models/FloatBalance';
export type { FloatBalanceType } from './models/FloatBalance';

export type ErrorDetails = Array<{
  detail: string,
  meta: { attribute: string },
  source: { pointer: string }
}>;

export { default as Auth } from './lib/Auth';

export { default as createModelErrors, createErrorsFromApiResponse } from './models/createModelErrors';
