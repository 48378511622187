//@flow
import * as React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';
import { fields as remittanceFields } from '@kwara/models/src/models/Remittance';
import { BankSelect, SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';
import { useCollectingBank } from '@kwara/models/src/models/request/hooks';

import { SubscribedFrequencySelectFields, SubscribedDatePicker } from '../../../components/Form';
import { type SavingsSubStepProps } from '..';

const fields = remittanceFields.remittance;

type Props = SavingsSubStepProps;

const showBankAccountSelectionFor = [TransactionChannels.directDebit, TransactionChannels.bankTransfer];

// Payroll Deduction fields are only shown
// during SavingAdd > Remittance step, not
// in the Remittance Editable Sections.
// We likely won't need this once ch16865
// is  actioned.
//
function showPayrollFields(fieldName, config) {
  return !!get(fieldName, config.validate);
}

export const Remittance = ({ StackChild, Condition, TextField, addData, config, data }: Props) => {
  const r = useCollectingBank();
  const { member } = data;

  const product = get('savingsProduct', data);

  return (
    <StackChild>
      {product ? <h3>{product.name}</h3> : null}
      <TextField name={fields.amount} labelId="SavingAdd.RemittanceAmount.label" isCurrency type="number" />
      <SubscribedPaymentSelectField
        required
        addData={addData}
        name={fields.mode}
        labelId="SavingAdd.Remittance.label"
        config={{
          bank: fields.bank,
          bankBranch: fields.branch,
          accountNumber: fields.account,
          memberBankAccountId: fields.bankAccount,
          methods: getTransactionTypes(contexts.SavingsRemittance).values,
          placeholderId: 'SavingAdd.Remittance.placeholder',
          showBankAccountSelectionFor,
          showBanksFieldsFor: [],
          memberId: member.id
        }}
      />

      <Condition when={fields.mode} is={TransactionChannels.directDebit}>
        <Loadable {...r}>
          {collectingBanks => (
            <BankSelect
              name={fields.collectingBank}
              banks={collectingBanks}
              titleId="SavingAdd.RemittanceCollectionBank.title"
              labelId={null}
            />
          )}
        </Loadable>
        <SubscribedDatePicker required name={fields.startDate} labelId="SavingAdd.RemittanceStartDate.label" />
      </Condition>

      <Condition when={fields.mode} is={TransactionChannels.payrollDeduction}>
        {showPayrollFields('member.employer', config) ? (
          <TextField name="member.employer" labelId="LoanAdd.Configure.RepaymentMode.Employer.title" required />
        ) : null}
        {showPayrollFields('member.staffId', config) ? (
          <TextField name="member.staffId" labelId="LoanAdd.Configure.RepaymentMode.StaffId.title" required />
        ) : null}
      </Condition>

      <SubscribedFrequencySelectFields addData={addData} frequency={fields.frequency} day={fields.collectionDate} />
    </StackChild>
  );
};

const requiredForBankChannels = {
  isRequired: (_, allData) => includes(get(fields.mode, allData), showBankAccountSelectionFor)
};

const requiredForDirectDebit = {
  isRequired: (_, allData) => get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForPayroll = {
  isRequired: (_, allData) => get(fields.mode, allData) === TransactionChannels.payrollDeduction
};

const base = {
  [fields.mode]: { isRequired: () => true },
  [fields.amount]: {
    currency: true
  },
  [fields.bankAccount]: requiredForBankChannels,
  [fields.collectingBank]: requiredForDirectDebit,
  [fields.startDate]: requiredForDirectDebit,
  ...SubscribedFrequencySelectFields.validate(fields.frequency, fields.collectionDate)
};

const savingAdd = {
  ...base,
  'member.employer': requiredForPayroll,
  'member.staffId': requiredForPayroll
};

Remittance.validateConfig = {
  savingAdd,
  savingEdit: base
};
