import * as React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import zIndices from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { createContext } from '@kwara/lib/src/utils/createContext';
import { WithNotification } from '@kwara/components/src/Notification';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';

import { WizardBody } from './components/WizardBody';
import { WizardHeader } from './components/WizardHeader';
import { getSubstepFromMatchParams } from './utils/wizard';
import { HandleCompletion } from './components/HandleCompletion';
import { useWizardImplementation } from './hooks/useWizardImplementation';
import { WizardElement, WizardPropTypes, WizardInternalContextType } from './types/wizard';

import styles from './index.module.scss';

const [WizardInternalContextProvider, useWizardInternalContext] = createContext<WizardInternalContextType>(
  'WizardInternalsContext'
);

const Wizard = React.forwardRef<WizardElement, WizardPropTypes>(function Wizard(props, forwardedRef) {
  const { EnterPIN, cancelReturnsTo: returnsTo, ...restProps } = props;

  const {
    headerId,
    bodyId,
    showPinEntry,
    hasCompleted,
    onClosePinEntry,
    wizardInternalContextValues
  } = useWizardImplementation({ ...restProps, returnsTo });

  const shouldRenderPinEntry = showPinEntry && EnterPIN != null;

  return (
    <React.Fragment>
      <Overlay>
        <ModalWrapper
          ref={forwardedRef}
          aria-label={`${restProps.analyticsId}Wizard`}
          aria-labelledby={headerId}
          aria-describedby={bodyId}
          className={cx(styles['wizard-container'], zIndices.Modal)}
        >
          <WizardInternalContextProvider value={wizardInternalContextValues}>
            <If
              condition={hasCompleted}
              do={<HandleCompletion />}
              else={
                <>
                  <WizardHeader />

                  <WizardBody />
                </>
              }
            />
          </WizardInternalContextProvider>
        </ModalWrapper>
      </Overlay>

      {shouldRenderPinEntry && <EnterPIN history={history} onClose={onClosePinEntry} />}
    </React.Fragment>
  );
});

export { getSubstepFromMatchParams, useWizardInternalContext };

export default withRouter<WizardPropTypes<{}>, any>(WithNotification<WizardPropTypes<{}>>(Wizard)) as any;
