import React from 'react';
import { IntlProvider } from 'react-intl';

const formats = {
  number: {
    currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    noDecimal: {
      style: 'decimal',
      maximumFractionDigits: 0
    }
  },
  date: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
};

function Internationalization(props: any) {
  return (
    <IntlProvider
      locale="en-KE"
      formats={formats}
      messages={props.messages}
      textComponent={React.Fragment}
      {...props}
    />
  );
}

export { Internationalization };
