import React from 'react';

import { LoanSubstepProps, ApiError } from 'GlobalTypes';

import { Loadable } from '@kwara/components/src/Loadable';

import { useGetSchedule } from './useGetSchedule';
import { Schedule as ScheduleTable } from '../../../LoanDetail/components/Schedule';

function Schedule({ formProps: { values } }: LoanSubstepProps) {
  const { isLoading, error, data } = useGetSchedule(values);

  return (
    <Loadable data={data} error={(error as ApiError)?.errors} isLoading={isLoading}>
      {scheduleData => <ScheduleTable schedule={scheduleData} initiallyOpen groupedByYear={false} />}
    </Loadable>
  );
}

export default {
  Component: Schedule
};
