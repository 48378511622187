import React from 'react';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import reject from 'lodash/fp/reject';
import invoke from 'lodash/fp/invoke';

import { UserT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { Checkbox as BasicCheckbox } from '@kwara/components/src/Form';
import Banner from '@kwara/components/src/Banner';

import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';
import { useAppRoles } from '../../../../../hooks';
import { DetailWrapper, Title } from '..';

const useRolesForm = user => {
  const store = useSaccoProfileContext();
  const { appRoles } = user;
  const userAppRoleIds = map(appRole => appRole.id, appRoles);
  const [selectedRoleIds, setSelectedRoleIds] = React.useState(userAppRoleIds);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const isCurrentUser = get('profile.id', store) === user.id;

  const isChecked = roleId => selectedRoleIds.includes(roleId);

  const triggerSuccess = () => {
    setSuccess(true);

    // If the user is changing his/her own permissions,
    // then we want to refetch the profile, so the UI elements
    // can show/hide according to the changes.
    // If the user changes another user's permissions, this instant
    // refetch is not necessary.
    if (isCurrentUser) {
      invoke('refetch', store);
    }
    setTimeout(() => setSuccess(false), 1000);
  };

  const triggerError = () => {
    setError(true);
    setTimeout(() => setError(false), 1000);
  };

  const check = id => setSelectedRoleIds(prev => [...prev, id]);
  const uncheck = id => setSelectedRoleIds(prev => reject(roleId => roleId === id, prev));

  async function onChange(e: { target: HTMLInputElement }) {
    setIsLoading(true);
    const roleId = get('target.name', e);
    const isChecked = e.target.checked;

    if (!isChecked) {
      try {
        await user.unAssignRole(roleId);
        uncheck(roleId);
        triggerSuccess();
      } catch (e) {
        triggerError();
      }
    } else if (isChecked) {
      try {
        await user.assignRole(roleId);
        check(roleId);
        triggerSuccess();
      } catch (e) {
        triggerError();
      }
    }

    setIsLoading(false);
  }

  return {
    success,
    error,
    isLoading,
    onChange,
    isChecked
  };
};

const RolesForm = ({ allAppRoles, user }) => {
  const { success, error, isLoading, isChecked, onChange } = useRolesForm(user);

  return (
    <form>
      <div className="h3">
        {success ? <Banner text={<Text id="UserEdit.Roles.onSuccess" />} state="success" /> : null}
        {error ? <Banner text={<Text id="UserEdit.Roles.onError" />} state="error" /> : null}
      </div>
      {map(appRole => {
        return (
          <BasicCheckbox
            key={appRole.id}
            size="regular"
            labelContent={appRole.name}
            name={appRole.id}
            onChange={onChange}
            checked={isChecked(appRole.id)}
            disabled={isLoading}
          />
        );
      }, allAppRoles)}
    </form>
  );
};

const RolesReadOnly = ({ user }: { user: UserT }) => {
  const { appRoles } = user;
  return isEmpty(appRoles) ? (
    <span className="grey-400">-</span>
  ) : (
    <div>
      {map(
        r => (
          <div key={r.name} className="grey-400">
            {r.name}
          </div>
        ),
        appRoles
      )}
    </div>
  );
};

export const Roles = ({ user, isEditable }: { user: UserT; isEditable: boolean }) => {
  const r = useAppRoles();
  const store = useSaccoProfileContext();
  const isAdmin = get('profile.isAdmin', store);
  const readOnly = !isEditable || !isAdmin;

  return (
    <Loadable {...r}>
      {allAppRoles => (
        <DetailWrapper>
          <Title titleId={'Settings.Personal.Roles.title'} classNames="mb1" />
          {readOnly ? <RolesReadOnly user={user} /> : <RolesForm allAppRoles={allAppRoles} user={user} />}
        </DetailWrapper>
      )}
    </Loadable>
  );
};
