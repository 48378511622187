// @flow

import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { ActionButton } from '@kwara/components/src/Button';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Card } from '@kwara/components/src/Card';

import { Currency } from '../../../../../components/Currency';
import { savingPath } from '../../../../../lib/urls';
import styles from '../../index.module.scss';

const CardTitle = ({ member, total }) => (
  <div className={cx(styles.container, 'pv2')}>
    <div className={cx(styles.a, 'kw-text-large kw-weight-bold')}>
      <Text id="LoanApprove.Overview.deposits" />
    </div>
    <div className={cx(styles.b, 'kw-text-x-small grey-400')}>
      <Text id="LoanApprove.Overview.deposits.SavingsCard.amount" values={{ value: total }} />
    </div>
    <div className={cx(styles.c, 'kw-text-large kw-weight-bold')}>
      <Currency format="currency" value={member.totalSavings} />
    </div>
    <div className={cx(styles.d, 'kw-text-x-small grey-400')}>
      <Text id="LoanApprove.Overview.deposits.SavingsCard.totalBalance" />
    </div>
  </div>
);

const SavingCardItem = ({ account }) => (
  <div className="pv2">
    <div className={cx(styles.container, 'pv2')}>
      <div className={cx(styles.a, 'kw-text-medium kw-weight-bold')}>{get('product.name', account)}</div>
      <div className={cx(styles.b, 'kw-text-2x-small grey-400')}>{account.id}</div>
      <div className={cx(styles.c)}>
        <StatusTag size="small" state={get('state.current', account)} />
      </div>
    </div>
    <StatisticRow widthClass="w-50" className="mb2" compact>
      <Statistic
        compact
        size="small"
        title={<Text id="LoanApprove.Overview.deposits.SavingsCard.balance" />}
        value={<Currency format="currency" value={account.balance} />}
      />
      {/* <Statistic
        compact
        size="small"
        title={
          <Text id="LoanApprove.Overview.deposits.SavingsCard.multiplyingFactor" />
        }
        value={null}
        // @TODO add multiplyingFactor
      /> */}
    </StatisticRow>
    <ActionButton className="mt2" to={savingPath({ id: account.id })} />
  </div>
);

const filterAccounts = (savings: SavingType[]) =>
  pipe(
    filter(s => Number(s.balance)),
    sortBy(s => -Number(s.balance))
  )(savings);

export const SavingsCard = ({ member, savings, className }) => {
  const accounts = filterAccounts(savings);

  return (
    <Card border={true} className={className}>
      <CardTitle member={member} total={size(accounts)} />
      {map(
        account => (
          <SavingCardItem key={account.id} account={account} eligibilityMultiplier={member.eligibilityMultiplier} />
        ),
        accounts
      )}
    </Card>
  );
};
