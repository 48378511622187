import * as React from 'react';

export function IconPending() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#CBD5E1" />
      <circle cx="12" cy="12" r="4" fill="white" />
    </svg>
  );
}
