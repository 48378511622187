import { useCallback, useEffect } from 'react';
import { UseQueryResult } from 'react-query';

import find from 'lodash/fp/find';

import { ApiError } from 'GlobalTypes';

import { SavingType } from '@kwara/models/src/models/Saving';
import { LoanFormData } from '@kwara/components/src/pages/LoanAdd';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';
import { MemberEligibilityT } from '@kwara/models/src/models/MemberEligibility';

interface LoanProductTypeExtended extends LoanProductType {
  eligibility: MemberEligibilityT;
}

type ArgType = {
  addData(updated: LoanFormData): void;
  addDataAndContinue(updated: LoanFormData): void;
  onChange(data: LoanFormData): Promise<LoanFormData>;
  savingsForMemberRequest: UseQueryResult<Array<SavingType>, ApiError>;
};

export function useActions({
  addData,
  onChange,
  addDataAndContinue,
  savingsForMemberRequest: { isFetched: savingsIsFetched, data: savingsData }
}: ArgType) {
  const onSelect = useCallback(
    (product: LoanProductType) => {
      /**
       * Two fields (repaymentPeriodUnit and repaymentPeriod)
       * on the next Wizard step (Configure) need to be pre-filled
       * based on the selected loan product
       */
      const { unit: repaymentPeriodUnit, value: repaymentPeriod } = product.toRepaymentFrequencyUI();
      const repaymentData = repaymentPeriodUnit || repaymentPeriod ? { repaymentPeriodUnit, repaymentPeriod } : {};

      addDataAndContinue({ amount: product.amount.default, productName: product.name, product, ...repaymentData });
    },
    [addDataAndContinue]
  );

  const onUpdate = useCallback(
    async (savings: Array<SavingType>) => {
      addData({ savings });

      const data = await onChange({ savings });

      return data;
    },
    [addData, onChange]
  );

  const addLoanProductEligibility = useCallback(
    (loanProduct: LoanProductType, eligibilities: Array<MemberEligibilityT>) => {
      const productEligibility = find(e => e.id === loanProduct.id, eligibilities);
      (loanProduct as LoanProductTypeExtended).eligibility = productEligibility;
    },
    []
  );

  useEffect(() => {
    if (savingsIsFetched) onUpdate(savingsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingsData, savingsIsFetched]);

  return {
    onSelect,
    onUpdate,
    addLoanProductEligibility
  };
}
