import React from 'react';

import { If, Text, Currency } from '@kwara/components/src';
import { GuarantorType } from '@kwara/models/src';
import { LinkButton } from '@kwara/components/src/LinkButton';

import Lister from '../../../../components/Lister';
import { useGuarantorsLister } from './useGuarantorsLister';

type Props = {
  guarantors: GuarantorType[];
  guarantorCardEditorRef?: React.MutableRefObject<HTMLDivElement>;
  setGuarantorIndexToEdit?: (index: number) => void;
  readonly?: boolean;
};

export const GuarantorsLister = ({ guarantors, guarantorCardEditorRef, setGuarantorIndexToEdit, readonly }: Props) => {
  const { TITLES, BASES } = useGuarantorsLister({ guarantors });

  return (
    <Lister.Root titleId="Guarantor.LoanGuarantors">
      <Lister.Header titles={TITLES} />

      <Lister.Body>
        {Td => {
          return guarantors.map(({ member, amount, isMarkedForDestruction }, index) => (
            <If
              key={index.toString()}
              condition={!isMarkedForDestruction && !!amount}
              do={
                <Lister.TRow>
                  <Td width="27%">{member.fullName()}</Td>

                  <Td width="28%">
                    <Currency format="currency" value={member.totalSavings} />
                  </Td>

                  <Td width="28%">
                    <Currency format="currency" value={amount} />
                  </Td>

                  <Td width="17%">
                    <If
                      condition={readonly}
                      do={
                        <LinkButton as="a" target="_blank" href={`${window.location.origin}/members/${member.id}`}>
                          <Text id="Guarantor.viewProfile" />
                        </LinkButton>
                      }
                      else={
                        <LinkButton
                          as="button"
                          onClick={() => {
                            setGuarantorIndexToEdit(index);

                            if (guarantorCardEditorRef.current) {
                              guarantorCardEditorRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'end'
                              });
                            }
                          }}
                        >
                          <Text id="Guarantor.edit" />
                        </LinkButton>
                      }
                    />
                  </Td>
                </Lister.TRow>
              }
            />
          ));
        }}
      </Lister.Body>

      <Lister.Footer bases={BASES} />
    </Lister.Root>
  );
};
