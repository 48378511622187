// @flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import DelayedAnimation from '@kwara/components/src/Wizard/deprecated/DelayedAnimation';

import { AutoDismissableCompletion } from '../../../../../components/Completion';
import { useTillUIContext } from '../../..';

const unanimatedSuccess = ({ onCancel, isTillOpen }: { onCancel: () => void, isTillOpen: boolean }) => {
  return (
    <AutoDismissableCompletion
      analyticsId={isTillOpen ? 'Till-Open' : 'Till-Close'}
      autoconfirm
      autoconfirmTimeoutSecs={3}
      type="good"
      onConfirm={onCancel}
      subtitle={isTillOpen ? <Text id="Till.Success.Modal.onOpen" /> : <Text id="Till.Success.Modal.onClose" />}
    />
  );
};

export const Success = () => {
  const { updateModal, isTillOpen } = useTillUIContext();
  const onCancel = () => updateModal({ activeModal: null });

  return (
    <Modal isOpen>
      <DelayedAnimation onDone={onCancel}>
        {({ hide }) => unanimatedSuccess({ onCancel: hide, isTillOpen })}
      </DelayedAnimation>
    </Modal>
  );
};
