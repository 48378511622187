import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { useNotificationSystemContext } from '../..';

import styles from './index.module.scss';

export function Header() {
  const { headingId, data, hasReadAllAnnouncement, onMarkAllAnnouncementAsRead } = useNotificationSystemContext();

  return (
    <header className={styles['container']}>
      <h1 id={headingId}>
        <Text id="NotificationSystem.heading" values={{ announcementCount: data.announcements.length }} />
      </h1>

      <button
        type="button"
        disabled={hasReadAllAnnouncement}
        onClick={onMarkAllAnnouncementAsRead}
        data-testid="markAllAsReadBtn"
      >
        <Text id="NotificationSystem.markAllAsRead" />
      </button>
    </header>
  );
}
