// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import pickBy from 'lodash/fp/pickBy';
import get from 'lodash/fp/get';

import { Loadable } from '@kwara/components/src/Loadable';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';
import { type MemberType, type SavingProductType, type SavingRemittanceT, type BankAccountT } from '@kwara/models/src';
import { useMemberLight } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './steps';
import { usePermissions, useAuth } from '../../hooks';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

export type WizardData = {
  member: MemberType,
  savingsAccountDeposit?: ?string,
  shareCapitalAmount?: ?string,
  shareCapital: boolean,
  savingsProduct?: ?SavingProductType,
  amount?: ?number,
  note?: ?string,
  rejectType?: 'SOFT' | 'HARD',
  comment?: ?string,
  method: TransactionChannelT,
  remittance: SavingRemittanceT,
  bankAccounts: BankAccountT[],
  accounts: string[],
  deposits: { [key: string]: { amount: string, notes: string } }
};

// See https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
// Because Product IDs are sometimes numbers, we have to ensure they are treated as strings
// with a prefix that we strip away before we submit the data.
export const PREFIX = 'KEY';
export const getName = (productId: string) => `deposits.[${PREFIX + productId}]`;

export function getAccounts(data: WizardData) {
  const { accounts, deposits } = data;

  return map<{ product_id: string, amount: number, notes: string }>(productId => {
    const o = { product_id: productId, amount: undefined, notes: undefined };
    o.amount = get([PREFIX + productId, 'amount'], deposits);
    o.notes = get([PREFIX + productId, 'notes'], deposits);
    return pickBy(k => k, o);
  }, accounts);
}

const approve = async (data: WizardData) => {
  const accounts = getAccounts(data);
  const params = { accounts };
  const shareCapitalAmount = Number(data.shareCapitalAmount);

  const { member } = data;

  if (shareCapitalAmount && !isNaN(shareCapitalAmount)) {
    params.share_capital_amount = shareCapitalAmount;
  }

  if (data.note) {
    params.comment = data.note;
  }

  const didApprove = await member.approve(params);

  if (!didApprove) {
    throw member.errors;
  }
};

const reject = async (data: { rejectType?: 'SOFT' | 'HARD', comment?: ?String, member: MemberType }) => {
  const { member } = data;
  const params = { comment: data.comment };
  let didReject = null;

  if (data.rejectType === 'SOFT') {
    didReject = await member.softReject(params);
  } else if (data.rejectType === 'HARD') {
    didReject = await member.reject(params);
  }

  if (!didReject) {
    throw member.errors;
  }
};

export const MemberApprove = props => {
  const { baseUrl, match, history } = props;
  const auth = useAuth();
  const isV1 = auth.isV1();
  const { permission, AppPermissions } = usePermissions();
  const store = useSaccoProfileContext();
  const r = useMemberLight(match.params.memberId);

  return (
    <Loadable {...r}>
      {member => (
        <Wizard
          analyticsId="MemberApprove"
          baseUrl={baseUrl}
          history={history}
          cancelReturnsTo={memberPath()}
          currentStep={match.params.step}
          currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
          initialData={{
            member,
            // TODO: These are used for translations, is there a cleaner way?
            firstName: member.firstName,
            fullName: member.fullName(),
            accounts: store.defaultSavingsAccounts
          }}
          onReject={reject}
          onSubmit={approve}
          // remove is a method from react-query,
          // to remove the query from the cache.
          // We want to forcefully rid of the old status: "PENDING"
          // https://react-query.tanstack.com/reference/useQuery
          onSubmitCompletion={r.remove}
          rejectSubtitleId="MemberApprove.reject"
          steps={steps(permission.to, AppPermissions, isV1)}
          startId="review"
          titleId="MemberApprove.title"
          successSubtitleId="MemberApprove.success"
          type="approval"
        />
      )}
    </Loadable>
  );
};

export default MemberApprove;
