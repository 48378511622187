// @flow
import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import { Text, Date } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { type DisbursedLoansT } from '@kwara/models/src';
import { DisbursedLoan, ACTIVE_FROM } from '@kwara/models/src';
import { useLoanProducts } from '@kwara/models/src/models/request/hooks';

import { BodyContentWrapper } from '../../../../layouts';
import { LoanDisbursementTable } from './LoanDisbursementTable';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import {
  useDeprecatedFilter,
  useDeprecatedPagination,
  useDisbursedLoans as useDisbursedLoansHook
} from '../../../../hooks';

import OverviewList from '../../../../components/OverviewList';

const includes = [];
const useDisbursedLoans = () => {
  const today = formatIsoDate(getCurrentDate());
  // initial filter values
  const { filterValue: filterValues, onFilterChange } = useDeprecatedFilter(filter => filter, {
    from: today,
    to: today,
    product_name: ''
  });

  const { allData, ...r } = useDeprecatedPagination<DisbursedLoansT>(
    useDisbursedLoansHook,
    page => [includes, filterValues, page],
    filterValues
  );

  const onDateChange = (key: 'to' | 'from', val: Date) => {
    if (!val) {
      return;
    }
    onFilterChange({
      ...filterValues,
      [key]: formatIsoDate(val)
    });
  };

  const onProductNameChange = (val: string) => {
    onFilterChange({
      ...filterValues,
      product_name: val
    });
  };

  return {
    filterValues,
    onDateChange,
    onProductNameChange,
    allData,
    ...r
  };
};

const createFiltersFromLoanProducts = loanProducts => {
  const alphabeticallySorted = sortBy(product => product.name, loanProducts);
  return [
    { value: '', translationId: 'Finance.LoanDisbursementReport.noFilter' },
    ...map(product => ({ value: product.name, label: product.name }), alphabeticallySorted)
  ];
};
export const LoanDisbursementReport = () => {
  // Data for filter by loan product names
  const [isGenerating, setIsGenerating] = React.useState(false);
  const { data: loanProducts, isLoading } = useLoanProducts();
  const { onDateChange, onProductNameChange, filterValues, allData: loans, ...r } = useDisbursedLoans();
  const filters = createFiltersFromLoanProducts(loanProducts);
  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader
            titleId="Finance.LoanDisbursementReport.title"
            subtitleId="Finance.LoanDisbursementReport.subtitle"
          />
          <OverviewList
            labelId="Finance.LoanDisbursementReport.List.label"
            filterLabelId="OverviewList.filterByProductLabel"
            filterBy={get('product_name', filterValues)}
            filters={isLoading ? null : filters}
            onFilterChange={onProductNameChange}
            items={loans}
            actions={[
              <Button
                key="generate"
                disabled={isGenerating}
                aria-label="Generate"
                type="primary"
                glyphRightId={isGenerating ? Button.Glyphs.Spinner : null}
                onClick={async () => {
                  try {
                    setIsGenerating(true);
                    await DisbursedLoan.generateReport(filterValues);
                  } finally {
                    setIsGenerating(false);
                  }
                }}
              >
                <Text id="Finance.DDReports.button.Generate" />
              </Button>
            ]}
            table={<LoanDisbursementTable loans={loans} {...r} />}
            dateRangeConfig={{
              dateValues: filterValues,
              onDateChange,
              disabledDays: { before: ACTIVE_FROM }
            }}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
