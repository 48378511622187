import * as React from 'react';

import { getCurrentDate, isAfter } from '@kwara/lib/src/dates';
import { Date } from '@kwara/components/src/Intl';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import styles from './index.module.scss';

export const DateRangePicker = ({ formProps }: SubStepComponentProps<{ from?: string }>) => {
  return (
    <div className={styles.details}>
      <SubscribedDatePicker
        required
        name="from"
        labelId="OverviewList.dateFilter.from.label"
        disabledDays={{ after: getCurrentDate() }}
      />
      <SubscribedDatePicker
        required
        name="to"
        labelId="OverviewList.dateFilter.to.label"
        disabledDays={{ after: getCurrentDate() }}
        errorValues={{ fromDate: <Date value={formProps.values.from} /> }}
      />
    </div>
  );
};

DateRangePicker.validate = {
  from: {
    isRequired: () => true
  },
  to: {
    isRequired: () => true,
    custom: (to, allData) => {
      const { from } = allData;

      if (isAfter(from, to)) {
        return 'isBefore';
      }

      return null;
    }
  }
};
