import * as React from 'react';
import { useQueryClient } from 'react-query';
import pickBy from 'lodash/fp/pickBy';

import { Loadable } from '@kwara/components/src/Loadable';
import { LoanType, LoanTransaction } from '@kwara/models/src';

import { useLoan } from '@kwara/models/src/models/request/hooks';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { steps } from './config';

type Props = {
  match: {
    params: {
      loanId: string;
    };
  };
};

type FormData = {
  amount: number;
  date?: Date;
  notes?: string;
  loan: LoanType;
  predefinedFeeKey?: string;
};

const makeTransaction = async (data: FormData) => {
  const { amount, notes, loan, predefinedFeeKey } = data;

  const transaction = LoanTransaction.create(
    pickBy((v, k) => !(k === 'predefinedFeeKey' && v === 'OTHER'), {
      type: 'FEE',
      loanId: loan.id,
      amount,
      notes,
      predefinedFeeKey
    })
  );

  const didSave = await transaction.save();
  if (!didSave) {
    throw transaction.errors;
  }
};

export const LoanFee = (props: Props) => {
  const r = useLoan(props.match.params.loanId);
  const queryClient = useQueryClient();

  return (
    <Loadable {...r}>
      {loan => {
        return (
          <Wizard
            analyticsId="LoanFee"
            baseUrl={loanPath({ id: loan.id })}
            history={history}
            initialData={{ loan, member: loan.member }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep="fee"
            onSubmit={makeTransaction}
            onSubmitCompletion={() => {
              queryClient.invalidateQueries('loan_extras');
              r.refetch();
            }}
            steps={steps}
            startId="fee"
            titleId="LoanPenalty.title"
          />
        );
      }}
    </Loadable>
  );
};
