import React from 'react';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

import { ButtonMenuPropTypes } from '.';

export function DefaultButton({ title, titleId, type = 'primary', ...restProps }: ButtonMenuPropTypes) {
  return (
    <Button type={type} size="medium" glyphRightId={Button.Glyphs.ChevronDown} {...restProps}>
      <If condition={titleId != undefined} do={<Text id={titleId} />} else={title} />
    </Button>
  );
}
