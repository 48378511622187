import { useQuery } from 'react-query';

import { LoanDisbursementsStats } from '@kwara/models/src';

const fetchLoanDisbursementsStats = async ({ queryKey }) => {
  const [_, where] = queryKey;
  const res = await LoanDisbursementsStats.where(where).find();
  return res.data;
};

export const useLoanDisbursementsStats = (where: { start_date: string; end_date: string }) =>
  useQuery(['loan_disbursements_stats', where], fetchLoanDisbursementsStats);
