// @flow

import * as React from 'react';

import { TillTransaction, TillTransactionTypes } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import Wizard from '../../components/Wizard';

import { tillPath } from '../../lib/urls';
import { steps } from './config';
import type { WizardPageProps } from '..';
import { useTillUIContext } from '../Till';

type Props = WizardPageProps<{}>;

export const TillToVaultTransfer = (props: Props) => {
  const { refetchTillData } = useTillUIContext();

  const { baseUrl, match, history } = props;

  const submit = async data => {
    const tillTransaction = new TillTransaction(data);
    tillTransaction.type = TillTransactionTypes.TILL_TO_VAULT;

    const didSave = await tillTransaction.save();
    if (!didSave) {
      throw tillTransaction.errors;
    }
    refetchTillData();
  };

  return (
    <Wizard
      baseUrl={baseUrl}
      history={history}
      initialData={{}}
      cancelReturnsTo={tillPath()}
      currentStep={match.params.step}
      currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
      onSubmit={submit}
      steps={steps}
      startId="request"
      titleId="TillToVaultTransfer.shortTitle"
      type="submit"
      animated={false}
      bannerContent={<Text id="TillToVaultTransfer.successBanner" />}
    />
  );
};
