import {
  startOfMonth,
  lastDayOfMonth,
  subMonths,
  startOfYear,
  lastDayOfYear,
  subYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters
} from 'date-fns';

import { formatIsoDate } from '@kwara/lib/src/dates';

import { SuggestedDate } from '../pages/DateBaseFilter/useDateBaseFilter';

/**
 * @QuarterBuilder class is responsible for building suggested date
 * ranges based on quarters. It has methods for building the current
 * quarter, the last one quarter, the last two quarters, and three
 * quarters ago. These methods calculate the start and end dates of
 * the respective quarters and return an object containing the name
 * of the suggested date range, the from date, and the to date.
 */
class QuarterBuilder {
  private getQuarterSymbol(date: Date) {
    const currentMonth = date.getMonth();

    if (currentMonth >= 0 && currentMonth <= 3) return 'Q1';
    if (currentMonth > 3 && currentMonth <= 6) return 'Q2';
    if (currentMonth >= 7 && currentMonth <= 9) return 'Q3';
    return 'Q4';
  }

  public buildCurrentQuarter() {
    const today = new Date();
    const startDate = startOfQuarter(today);
    const endDate = endOfQuarter(today);

    return {
      name: `This quarter (${this.getQuarterSymbol(endDate)} ${endDate.getFullYear()})`,
      from: formatIsoDate(startDate),
      to: formatIsoDate(today)
    };
  }

  public buildLastOneQuarter() {
    const today = new Date();
    const startDate = startOfQuarter(subQuarters(today, 1));
    const endDate = endOfQuarter(subQuarters(today, 1));

    return {
      name: `Last quarter (${this.getQuarterSymbol(endDate)} ${endDate.getFullYear()})`,
      from: formatIsoDate(startDate),
      to: formatIsoDate(endDate)
    };
  }

  public buildLastTwoQuarters() {
    const today = new Date();
    const lastTwoQuartersAgo = subQuarters(today, 2);
    const startDate = startOfQuarter(lastTwoQuartersAgo);
    const endDate = endOfQuarter(lastTwoQuartersAgo);

    return {
      name: `2 quarters ago (${this.getQuarterSymbol(endDate)} ${endDate.getFullYear()})`,
      from: formatIsoDate(startDate),
      to: formatIsoDate(endDate)
    };
  }

  public buildThreeQuarters() {
    const today = new Date();
    const startDate = startOfQuarter(subQuarters(today, 3));
    const endDate = endOfQuarter(startDate);

    return {
      name: `3 quarters ago (${this.getQuarterSymbol(endDate)} ${endDate.getFullYear()})`,
      from: formatIsoDate(startDate),
      to: formatIsoDate(endDate)
    };
  }
}

/**
 * @DateBuilder class is a utility class that provides methods for building
 * suggested date ranges. It has static methods that generate different types
 * of suggested dates, such as custom dates, last 30 days, this month, last
 * month, this quarter, last quarter, and so on. These methods return objects
 * of type `SuggestedDate`, which contain the name of the suggested date range,
 * the from date, and the to date. The `DateBuilder` class is extended by the
 * `SuggestDates` class, which adds additional static methods for generating
 * suggested dates.
 */
class DateBuilder {
  private static QUARTER_TYPES = {
    CURRENT_QUARTER: 'CURRENT_QUARTER',
    LAST_ONE_QUARTER: 'LAST_ONE_QUARTER',
    LAST_TWO_QUARTERS: 'LAST_TWO_QUARTERS',
    LAST_THREE_QUARTERS: 'LAST_THREE_QUARTERS'
  } as const;

  public static DATES = {
    CUSTOM_DATE: { name: 'Custom', from: '', to: '' },
    ALL_TIME: { name: 'All time', from: 'All time', to: '' }
  };

  private static dateQuarterMeta(type: keyof typeof DateBuilder.QUARTER_TYPES) {
    const quarterBuilder = new QuarterBuilder();

    if (type === DateBuilder.QUARTER_TYPES.CURRENT_QUARTER) return quarterBuilder.buildCurrentQuarter();

    if (type === DateBuilder.QUARTER_TYPES.LAST_ONE_QUARTER) return quarterBuilder.buildLastOneQuarter();

    if (type === DateBuilder.QUARTER_TYPES.LAST_TWO_QUARTERS) return quarterBuilder.buildLastTwoQuarters();

    if (type === DateBuilder.QUARTER_TYPES.LAST_THREE_QUARTERS) return quarterBuilder.buildThreeQuarters();
  }

  protected static buildCustom(): SuggestedDate {
    return {
      name: DateBuilder.DATES.CUSTOM_DATE.name,
      from: DateBuilder.DATES.CUSTOM_DATE.from,
      to: DateBuilder.DATES.CUSTOM_DATE.to
    };
  }

  protected static buildLastThirtyDays(): SuggestedDate {
    const from = new Date();
    from.setDate(from.getDate() - 30);
    const to = new Date();

    return { name: 'Last 30 days', from: formatIsoDate(from), to: formatIsoDate(to) };
  }

  protected static buildThisMonth(): SuggestedDate {
    const today = new Date();
    const from = formatIsoDate(startOfMonth(today));
    const to = formatIsoDate(today);

    return { name: 'This month', from, to };
  }

  protected static buildLastMonth(): SuggestedDate {
    const today = new Date();
    const from = formatIsoDate(startOfMonth(subMonths(today, 1)));
    const to = formatIsoDate(lastDayOfMonth(subMonths(today, 1)));

    return { name: 'Last month', from, to };
  }

  protected static buildThisYear(): SuggestedDate {
    const today = new Date();
    const from = formatIsoDate(new Date(today.getFullYear(), 0, 1));
    const to = formatIsoDate(today);

    return { name: 'This year', from, to };
  }

  protected static buildLastYear(): SuggestedDate {
    const today = new Date();
    const from = formatIsoDate(startOfYear(subYears(today, 1)));
    const to = formatIsoDate(lastDayOfYear(subYears(today, 1)));

    return { name: 'Last year', from, to };
  }

  protected static buildThisQuarter(): SuggestedDate {
    return this.dateQuarterMeta(DateBuilder.QUARTER_TYPES.CURRENT_QUARTER);
  }

  protected static buildLastQuarter(): SuggestedDate {
    return this.dateQuarterMeta(DateBuilder.QUARTER_TYPES.LAST_ONE_QUARTER);
  }

  protected static buildTwoQuarterAgo(): SuggestedDate {
    return this.dateQuarterMeta(DateBuilder.QUARTER_TYPES.LAST_TWO_QUARTERS);
  }

  protected static buildThreeQuarterAgo(): SuggestedDate {
    return this.dateQuarterMeta(DateBuilder.QUARTER_TYPES.LAST_THREE_QUARTERS);
  }

  protected static buildAllTime(): SuggestedDate {
    return {
      name: DateBuilder.DATES.ALL_TIME.name,
      from: DateBuilder.DATES.ALL_TIME.from,
      to: DateBuilder.DATES.ALL_TIME.to
    };
  }
}

/**
 * @SuggestDates The SuggestDates class extends the DateBuilder class.
 */
export class SuggestDates extends DateBuilder {
  public static generateSuggestedDates(): Array<SuggestedDate> {
    return [
      DateBuilder.buildCustom(),
      DateBuilder.buildLastThirtyDays(),
      DateBuilder.buildThisMonth(),
      DateBuilder.buildLastMonth(),
      DateBuilder.buildThisQuarter(),
      DateBuilder.buildLastQuarter(),
      DateBuilder.buildTwoQuarterAgo(),
      DateBuilder.buildThreeQuarterAgo(),
      DateBuilder.buildThisYear(),
      DateBuilder.buildLastYear(),
      DateBuilder.buildAllTime()
    ];
  }
}
