import * as React from 'react';
import { JoiningFeePayment } from '@kwara/components/src/pages/MemberAdd/components/JoiningFeePayment';

import { Address } from './components/Address';
import { Contact } from './components/Contact';
import { Documents } from './components/Documents';
import { Employment } from './components/Employment';
import { Name } from './components/Name';
import { NextOfKin } from './components/NextOfKin';
import { Personal } from './components/Personal';
import Identity from './components/Identity';
import Notes from './components/Notes';
import Summary from './components/Summary';
import verifyIdentityInstance from './components/Identity/utils/verifyIdentity';

export const steps = (identityAppears: string, isV1: boolean) => ({
  about: {
    titleId: 'AddMember.Step.about',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'contact'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Profile.title',
        Component: Name,
        validate: Name.validate,
        hideErrorBanner: true
      },
      {
        titleId: 'AddMember.Personal.title',
        Component: Personal,
        validate: Personal.validate,
        hideErrorBanner: true
      },
      {
        titleId: 'AddMember.Address.title',
        Component: Address,
        hideErrorBanner: true
      },
      {
        titleId: 'AddMember.Identity.title',
        Component: Identity,
        validate: Identity.validateConfig.memberAdd,
        hideErrorBanner: true,
        hideActions: { next: true },
        actions: [
          {
            appearsAs: identityAppears,
            behavesAs: 'nextWithPromise',
            destinationPath: 'about/5',
            isPermitted: true,
            isHidden: false,
            onNext: verifyIdentityInstance.startVerifying
          }
        ]
      },
      {
        titleId: 'AddMember.Employment.title',
        Component: Employment,
        validate: Employment.validateConfig.memberAdd,
        hideErrorBanner: true
      },
      {
        titleId: 'AddMember.Kin.title',
        Component: NextOfKin,
        validate: NextOfKin.validate
      }
    ]
  },
  contact: {
    titleId: 'AddMember.Step.contact',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: isV1 ? 'joiningFeePayment' : 'documents'
      }
    ],
    children: [
      {
        hideErrorBanner: true,
        titleId: 'AddMember.Contact.title',
        Component: props => <Contact {...props} isV1={isV1} />,
        validate: Contact.validate
      }
    ]
  },
  documents: {
    titleId: 'AddMember.Step.documents',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'joiningFeePayment'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Documents.title',
        subtitleId: 'AddMember.Documents.subtitle',
        hideErrorBanner: true,
        Component: Documents
      }
    ]
  },
  joiningFeePayment: {
    titleId: 'AddMember.Step.paymentFee',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: isV1 ? 'summary' : 'notes'
      }
    ],
    children: [
      {
        titleId: 'AddMember.PaymentFee.title',
        subtitleId: 'AddMember.PaymentFee.subtitle',
        Component: JoiningFeePayment,
        hideErrorBanner: true,
        validate: JoiningFeePayment.validate(true)
      }
    ]
  },
  notes: {
    titleId: 'AddMember.Step.notes',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Notes.title',
        subtitleId: 'AddMember.Notes.subtitle',
        Component: Notes,
        hideErrorBanner: true
      }
    ]
  },
  summary: {
    titleId: 'AddMember.Step.summary',
    subtitleId: 'AddMember.SubTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Summary.title',
        subtitleId: 'AddMember.Summary.subtitle',
        Component: props => <Summary {...props} isV1={isV1} />,
        progress: false
      }
    ]
  }
});
