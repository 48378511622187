import React from 'react';

import { Metric } from '@kwara/models/src';

import { useMemberOverviewMetrics } from '../../../../models/hooks';
import { DashboardMetricCard } from '../../../../components/DashboardMetric';
import { IndicatorsContainer } from '../../../../components/IndicatorsContainer';

export function Indicators() {
  const r = useMemberOverviewMetrics();

  return (
    <IndicatorsContainer>
      <DashboardMetricCard
        r={r}
        titleId="Members.Dashboard.activeMembers"
        metric={metrics => Metric.findFromArray(metrics, 'total_members')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Members.Dashboard.totalLoansBalance"
        metric={metrics => Metric.findFromArray(metrics, 'total_loans')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Members.Dashboard.totalSavings"
        metric={metrics => Metric.findFromArray(metrics, 'total_savings')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Members.Dashboard.shareCapital"
        metric={metrics => Metric.findFromArray(metrics, 'share_capital')}
      />
    </IndicatorsContainer>
  );
}
