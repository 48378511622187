// @flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { Logger } from '@kwara/lib/src/logger';
import { Notification } from '@kwara/components/src/Notification';

///UTILS BELOW
/*************************************************************/
const runningAppId = function() {
  const meta = document.querySelector('meta[name="x-app-id"]');

  if (meta) {
    return meta.getAttribute('content');
  }

  return '';
};

async function updateCheck(currentUrl = window.location) {
  try {
    const releaseUrl = new URL(currentUrl);

    releaseUrl.pathname = '/release.json';

    const response = await fetch(releaseUrl, { credentials: 'same-origin' });
    const bundle = await response.json();

    return bundle.appId;
  } catch (error) {
    return null;
  }
}

const HOURLY_MS = 1000 * 60 * 60;
const UPDATE_CHECK_INTERVAL_MS = HOURLY_MS;

///COMPONENT BELOW
/*************************************************************/
type AppUpdateCheckerPropTypes = {
  children: React.ReactNode
};

type AppUpdateCheckerStateTypes = {
  hasUpdate: boolean
};
export class AppUpdateChecker extends React.Component<AppUpdateCheckerPropTypes, AppUpdateCheckerStateTypes> {
  constructor() {
    super();

    this.runningAppId = runningAppId();

    Logger.log('AppUpdateChecker runningAppId:', this.runningAppId);
  }

  state = { hasUpdate: false };

  componentDidMount() {
    window.checkForAppUpdates = this.checkForUpdates;

    if (this.runningAppId) {
      this.scheduleAppUpdateCheck();
    } else {
      Logger.warn('Cannot find runningApId so will not poll for updates');
    }
  }

  updateIntervalMs = UPDATE_CHECK_INTERVAL_MS;
  runningAppId: ?string;
  timerId: ?number;

  scheduleAppUpdateCheck = () => {
    if (this.timerId != null) {
      window.clearTimeout(this.timerId);
    }

    this.timerId = window.setTimeout(this.checkForUpdates, this.updateIntervalMs);
  };

  checkForUpdates = async () => {
    const latestAppId = await updateCheck();

    if (latestAppId != null && this.runningAppId !== latestAppId) {
      this.setState({ hasUpdate: true });
    } else {
      this.scheduleAppUpdateCheck();
    }
  };

  render() {
    return (
      <>
        <If
          condition={this.state.hasUpdate}
          do={
            <Notification>
              <Text id="AppUpdateChecker.newRelease" />
              <Button className="ml-auto mr3" size="small" type="secondary" onClick={window.location.reload}>
                <Text id="AppUpdateChecker.refresh" />
              </Button>
            </Notification>
          }
        />

        {this.props.children}
      </>
    );
  }
}
