import * as React from 'react';
import noop from 'lodash/fp/noop';
import cx from 'classnames';

import { ActionButton } from '@kwara/components/src/Button';
import { type ValidationRules } from '@kwara/lib/src/validator';
import Action from '@kwara/components/src/Wizard/deprecated/Action';
import { SubStepContainer, patchValues } from '@kwara/components/src/Wizard/deprecated/SubStep';

import { type StepConfig } from '../../Wizard';
import { type WizardData } from '../../../pages/MemberAdd';

import styles from './index.module.css';

// For some reason Flow will not type check this interface if imported in another module.
// It will only work if defined (ie copied) within the same module it's being used
export interface EditableSectionT {
  (): React.Node;
  Title: () => React.Element<Text>;
  editConfig?: { [k: 'memberDetail' | 'loanAdd']: ValidationRules };
}
export type EditableConfig<T> = {
  config?: StepConfig,
  initialData: T,
  readOnly?: boolean
};
type updateFn = (...data: any) => Promise<void>;
type UpdaterProps = {
  children: React.Node,
  value: { onUpdate: updateFn }
};

export const EditableContext = React.createContext({ onUpdate: noop });

// Wrap with this any component that needs to be re-fetch/re-render after an edit is successful
export const Updater = ({ children, value }: UpdaterProps) => (
  <EditableContext.Provider value={value}>{children}</EditableContext.Provider>
);

type EditableSectionProps<WizardDataT> = {
  onSave: WizardDataT => Promise<?boolean>,
  onDelete: Promise<void>,
  children: React.Node,
  initialData: WizardDataT,
  config: StepConfig,
  readOnly: boolean,
  ariaLabel?: string,
  editClassNames?: string,
  isEditing?: boolean,
  canCancel?: boolean,
  overflowScroll?: boolean,
  onEditStart?: Function,
  onEditEnd?: Function
};

export const EditableSection = (props: EditableSectionProps<WizardData>) => {
  const {
    isEditing: isEditingProp,
    onClose = noop,
    onDelete,
    onSave = instance => instance.save(), // if you don't pass a different save function the instance will be automatically saved as is
    initialData,
    children,
    canCancel = true,
    config,
    readOnly,
    onEditStart,
    onEditEnd,
    type = 'edit',
    ariaLabel = 'Edit',
    containerClassNames = '',
    editClassNames,
    actions = [
      // We have to do this as we're skipping the stepParser that does
      // new Action() for us for each step. This avoids us having to define it for each and every
      // new config we add
      new Action({
        appearsAs: 'cancel',
        behavesAs: 'cancel'
      }),
      new Action({
        appearsAs: 'submit',
        behavesAs: 'complete'
      })
    ],
    overflowScroll
  } = props;
  const { onUpdate } = React.useContext(EditableContext);
  const [isEditing, setIsEditing] = React.useState(!canCancel);

  React.useEffect(() => {
    if (isEditing) {
      onEditStart && onEditStart();
    } else {
      onEditEnd && onEditEnd();
    }
  }, [onEditStart, onEditEnd, isEditing]);

  // TO DO: This is a hack to allow us to control the Collaterals
  // list edit state from outside this component. Ideally, collaterals
  // would simply not use the EditableSection and we could remove this.
  //
  React.useEffect(() => {
    if (typeof isEditingProp === 'boolean') {
      setIsEditing(isEditingProp);
    }
  }, [isEditingProp]);

  const [pristine, setPristine] = React.useState(true);
  const [data, setData] = React.useState(initialData);
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isEditing && pristine) {
      setData(initialData);
      setPristine(false);
    }
  }, [initialData, isEditing, pristine]);

  const open = () => setIsEditing(true);
  const close = () => {
    setIsEditing(false);
    onClose();
  };
  const save = edited => {
    setIsLoading(true);
    return onSave(edited)
      .then(saved => {
        // this sucks a bit.
        // As you said we have to catch the error in the then because of how Spraypaint works
        if (!saved) {
          return setErrors(edited.errors);
        }

        close();
        onUpdate(edited);
      })
      .catch(setErrors)
      .finally(() => setIsLoading(false));
  };

  const fullConfig = {
    ...config,
    actions
  };

  return (
    <div className={containerClassNames}>
      {isEditing ? (
        <SubStepContainer
          currentState={data}
          error={errors}
          onAction={({ action }) => {
            if (action.behavesAs === 'complete') {
              return save(data);
            }

            return close();
          }}
          onChange={updates => {
            setData(patchValues(data, updates));

            return Promise.resolve(data);
          }}
          subStep={fullConfig}
          isProcessing={isLoading}
          bottomMargin={false}
          canCancel={canCancel}
          overflowScroll={overflowScroll}
        />
      ) : (
        <div className={styles.ReadMode}>
          <div className={styles.Children}>{children}</div>
          {readOnly ? null : (
            <div className={cx('flex justify-end', editClassNames)}>
              {onDelete ? (
                <ActionButton aria-label="Delete" type="delete" onClick={onDelete} col={'red500'} hideBorder />
              ) : null}
              <ActionButton
                className="bg-white"
                aria-label={ariaLabel}
                hidden={isEditing}
                type={type}
                onClick={open}
                hideBorder
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
