// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import cx from 'classnames';

import { Date, Time, Text } from '@kwara/components/src/Intl';
import { PageHeading, SupportEmailLink } from '@kwara/components/src/text';

import { BodyContentWrapper } from '../../../../layouts';
import Table, { Cell, Row, Heading, Footer } from '../../../../components/Table';
import { useDeprecatedPagination, useLoginActivities, type LoginActivityT } from '../../../../hooks';

import styles from '../../index.module.scss';

export function Activity() {
  const { allData, onNext, hasMore, ...r } = useDeprecatedPagination<LoginActivityT>(useLoginActivities, page => [
    page
  ]);

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <PageHeading translationId="Settings.activity" />
          <p className="grey-400 kw-text-medium mb1">
            <Text id="Settings.Activity.text1" />
          </p>
          <p className="grey-400 kw-text-medium mt1">
            <Text id="Settings.Activity.text2" />
            <SupportEmailLink />.
          </p>
          <Table
            className="mt5"
            heading={
              <Row className="grey-400">
                <Heading translationId="Settings.Activity.Table.date" />
                <Heading translationId="Settings.Activity.Table.ip" />
                <Heading translationId="Settings.Activity.Table.location" />
                <Heading align="center" translationId="Settings.Activity.Table.success" />
                <Heading iconSpacer />
              </Row>
            }
            footer={
              <Footer
                colSpan={4}
                isLoading={r.isLoading}
                onNext={onNext}
                hasMore={hasMore}
                errors={r.errors}
                items={allData}
              />
            }
          >
            {map(
              activity => (
                <Row key={activity.id}>
                  <Cell>
                    <Date value={activity.createdAt} />
                    {' - '}
                    <Time value={activity.createdAt} />
                  </Cell>
                  <Cell>{activity.ip}</Cell>
                  <Cell>
                    {activity.city || '-'} {activity.country ? `(${activity.country})` : null}
                  </Cell>
                  <Cell align="center">
                    <div
                      className={cx(styles.Dot, {
                        [styles.DotSuccess]: activity.success,
                        [styles.DotFailure]: !activity.success
                      })}
                    />
                    {activity.success}
                  </Cell>
                </Row>
              ),
              allData
            )}
          </Table>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
