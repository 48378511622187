// @flow

import * as React from 'react';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';
import type { RouterHistory } from 'react-router-dom';

import { TillModal } from './components/TillModal';
import { useTillUIContext } from './TillProvider';
import { BodyContentWrapper } from '../../layouts';
import { TillDashboard } from './components/Dashboard';
import { TillTransactionsList } from './components/TillTransactionsList';

export { useTillUIContext };
export { useTransactionFormWithTill } from './useTransactionFormWithTill';
export { useCurrentTillBalance } from './useCurrentTillBalance';
export { DummyTillSelect } from './components/DummyTillSelect/DummyTillSelect';

export type ModalState = 'success' | 'confirmClose' | 'confirmOpen' | 'withdrawal' | 'deposit' | 'repayment' | null;
export type ModalAction = (s: { activeModal: ModalState }) => void;

export function Till({ history }: { history: RouterHistory }) {
  const { updateModal, isTillOpen, tillTransactionsLoading, transactions, error, refetchTillData } = useTillUIContext();

  React.useEffect(() => {
    refetchTillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BodyContentWrapper.Root headId="TillTransactionsList.title">
      <BodyContentWrapper.Row.Root>
        <TillDashboard />
      </BodyContentWrapper.Row.Root>

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <TillModal />
          <TillTransactionsList
            transactions={transactions || []}
            onLoadMoreData={noop}
            loading={tillTransactionsLoading}
            errors={error}
            history={history}
            updateModal={updateModal}
            isTillOpen={isTillOpen}
            totalNumResults={size(transactions)}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
