// @flow

import React from 'react';
import get from 'lodash/fp/get';

import { LoanTermsFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';
import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

import { AmountField } from './AmountField';
import { type ComponentPropsLoanRefinance } from '..';

export const Configure = (props: ComponentPropsLoanRefinance) => {
  const { StackChild, data } = props;
  const { loan } = data;
  return (
    <StackChild>
      <h3>{get('product.name', loan)}</h3>
      <AmountField {...props} />
      <LoanClassificationFields {...props} />
      <LoanTermsFields {...props} />
    </StackChild>
  );
};

Configure.validate = {
  ...AmountField.validate,
  ...LoanClassificationFields.validate,
  ...LoanTermsFields.validate
};
