import React from 'react';

export function useWaitingCursor() {
  const windowRef = React.useRef(window);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const onWaitingStart = () => setIsWaiting(true);
  const onWaitingEnd = () => setIsWaiting(false);

  React.useEffect(() => {
    const window = windowRef.current;
    if (window.document) {
      if (isWaiting) {
        window.document.body.style.cursor = 'wait';
      } else {
        window.document.body.style.cursor = 'unset';
      }
    }

    return () => {
      if (window.document) {
        window.document.body.style.cursor = 'unset';
      }
    };
  }, [isWaiting, windowRef.current.document]);

  return { onWaitingStart, onWaitingEnd, isWaiting };
}
