//@flow
import * as React from 'react';
import map from 'lodash/map';
import getOr from 'lodash/fp/getOr';
import size from 'lodash/fp/size';

import { Text } from '@kwara/components/src/Intl';
import { DeprecatedLoadable, Loading } from '@kwara/components/src/Loadable';
import { Statistic } from '@kwara/components/src/Statistic';
import { SubscribedTextField } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import ModelErrorBanner from '../../../components/ModelErrorBanner';
import CreditReport from './CreditReport';

import { identityDocumentValue } from '../../../lib/recordValidations';
import { idVerification, type ErrorDetails, type IDCheckResponse } from '../../../models';
import { type SubStepComponentProps } from '../../../components/Wizard';

const View = {
  confirm: 'confirm',
  loading: 'loading',
  report: 'report'
};

type ViewType = $Keys<typeof View>;

type State = {
  view: ViewType,
  idText: string
};

export default class CreditCheck extends React.Component<SubStepComponentProps<>, State> {
  static validate() {
    return {
      identityType: {
        isRequired: () => true
      },
      identityValue: {
        isRequired: () => true,
        ...identityDocumentValue
      },
      identityDetailsConfirm: {
        isRequired: () => true
      }
    };
  }

  render() {
    const { StackChild, TextField, RadioGroup, Checkbox, Condition } = this.props;

    return (
      <StackChild>
        <div className="mb3">
          <h2 className="ma0 mb2">
            <Text id="LoanApprove.CreditCheck.memberDetails" />
          </h2>
          <p className="ma0 grey-400">
            <Text id="LoanApprove.CreditCheck.checkDetails" />
          </p>
        </div>

        <div>
          <SubscribedTextField name="fullName" labelId="LoanApprove.CreditCheck.fullName" />

          <RadioGroup
            name={`identityType`}
            items={[
              {
                labelId: 'AddMember.Identity.Type.NATIONAL',
                value: 'NATIONAL'
              },
              {
                labelId: 'AddMember.Identity.Type.PASSPORT',
                value: 'PASSPORT'
              }
            ]}
          />
          <Condition when={`identityType`} is="NATIONAL">
            <TextField name={`identityValue`} placeholderId="AddMember.Identity.Value.NATIONAL.placeholder" />
          </Condition>

          <Condition when={`identityType`} is="PASSPORT">
            <TextField name={`identityValue`} placeholderId="AddMember.Identity.Value.PASSPORT.placeholder" />
          </Condition>

          <Condition when="identityType" is="NATIONAL">
            <Checkbox name="identityDetailsConfirm" labelId="LoanApprove.CreditCheck.SubmissionConfirm" />
          </Condition>
          <Condition when="identityType" is="PASSPORT">
            <Checkbox name="identityDetailsConfirm" labelId="LoanApprove.CreditCheck.SubmissionConfirm" />
          </Condition>
        </div>
      </StackChild>
    );
  }
}

function Result({ request, onClick }: { request: Promise<IDCheckResponse>, onClick: () => void }) {
  return (
    <DeprecatedLoadable
      loader={request}
      loaded={result => (
        <div>
          {(() => {
            if (!result) {
              return null;
            }

            if (size(result.errors)) {
              return (
                <div className="mb4">
                  <ModelErrorBanner errors={result.errors} />
                </div>
              );
            }

            return (
              <React.Fragment>
                <h2>MCRB ID verification results</h2>

                {map(exposedFields, field => (
                  <Statistic
                    key={field}
                    title={<Text id={`Info.${field}`} />}
                    value={getOr('-', `data.${field}`, result)}
                    size="small"
                    compact={true}
                  />
                ))}

                <Button className="mt3 mb3" size="medium" type="primary" onClick={onClick}>
                  Check credit
                </Button>
              </React.Fragment>
            );
          })()}
        </div>
      )}
    />
  );
}

const exposedFields = ['firstName', 'lastName', 'gender', 'dateOfBirth'];
export class IdentityCheckResult extends React.Component<
  *,
  {
    View: string,
    request?: ?Promise<IDCheckResponse>,
    errors: ErrorDetails,
    error: string
  }
> {
  request = '';
  state = {
    View: '',
    errors: [],
    error: '',
    request: null
  };

  componentDidMount() {
    const { data = {} } = this.props;
    const { identityType, identityValue, loan } = data;
    this.setState({
      request: idVerification.check({
        identityType,
        identityValue,
        member: loan.member // this NOT needed for the request, but only for DEMO
      })
    });
  }

  render() {
    const { StackChild, data } = this.props;
    const { identityType, identityValue } = data;

    if (this.state.View === 'creditReport') {
      return <CreditReport {...this.props} />;
    }

    if (this.state.View === 'loading') {
      return <Loading />;
    }

    return (
      <StackChild>
        <h2>The details you entered</h2>
        <Statistic title="Full name" value={data.loan.member.fullName()} size="small" compact={true} />

        <Statistic title="Identity Type" value={identityType} size="small" compact={true} />

        <Statistic title="Identification" value={identityValue} size="small" compact={true} />

        {identityType && identityValue ? (
          <Result
            request={this.state.request}
            onClick={() => {
              this.setState({ View: 'loading' });
              setTimeout(() => {
                this.setState({ View: 'creditReport' });
              }, 4000);
            }}
          />
        ) : (
          <p>No details entered.</p>
        )}
      </StackChild>
    );
  }
}
