import React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/merge';
import toString from 'lodash/toString';
import set from 'lodash/set';

import { CollateralT, Collateral } from '@kwara/models/src';
import { Logger } from '@kwara/lib/src/logger';

import {
  default as CollateralsBase,
  CollateralsPropTypes
} from '../../../../../../../components/Collaterals/Collaterals';
import { useCollateralFileInputLoanApplication } from './useCollateralFileInputLoanApplication';

export function commitChanges(collaterals: CollateralT[] = [], index: number, formCollaterals: CollateralT[]) {
  // this is only need to make Flow happy that we're passing a string to Lodash methods
  const idx = toString(index);
  // Duplicate the object to avoid mutating the original instance.
  const original = getOr({}, idx, collaterals);
  // Make sure we are always dealing with a SPraypaint instance
  const asInstance = original.isSpraypaintInstance ? original.dup() : Collateral.create(original);
  // Pick the collateral that have been changed just now (this is a plain object, NOT an instance)
  const changed = get(index, formCollaterals);
  // Commit the changes
  // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
  /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
  const updated = merge(asInstance, changed);
  // Replace the collateral that was edited with the newly created duplicate or add it if it's a
  // new one
  set(collaterals, idx, updated);

  return collaterals;
}

const BASE_PATH = 'collaterals';

function Collaterals(props: CollateralsPropTypes) {
  const { onChange, formProps, data } = props;

  const getPath = (index: number) => `${BASE_PATH}[${index}]`;
  const collaterals = get('values.collaterals', formProps);

  const { onFileRemove, onFileInputChange, fileName, hasFile } = useCollateralFileInputLoanApplication({
    onChange,
    collaterals
  });

  async function onCollateralRemove(index: number) {
    const path = getPath(index);

    const formCollateral = get(path, formProps.values);
    if (formCollateral) formCollateral.isMarkedForDestruction = true;
    formProps.form.change(path, formCollateral);

    try {
      await onChange(commitChanges(data.collaterals, index, collaterals));
    } catch (err) {
      Logger.error(`Cannot remove collateral`, JSON.stringify(err));
    }
  }

  async function onCollateralSave(index: number) {
    await commitChanges(data.collaterals, index, collaterals);
  }

  return (
    <CollateralsBase.Component
      {...props}
      withUploadWidget
      onCollateralRemove={onCollateralRemove}
      onCollateralSave={onCollateralSave}
      getPath={getPath}
      onFileRemove={onFileRemove}
      onFileInputChange={onFileInputChange}
      fileName={fileName}
      hasFile={hasFile}
    />
  );
}

export default {
  Component: Collaterals,
  validate: CollateralsBase.validate
};
