// @flow

import * as React from 'react';

import { type TopupRequestT, TillTransactionTypes } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';

import { useTillUIContext } from '../Till';

import Wizard from '../../components/Wizard';
import { tellerPath } from '../../lib/urls';
import { useTopupRequest } from '../../hooks';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ topupId: string, initialData: TopupRequestT }>;

export const TillTransactionReview = (props: Props) => {
  const { baseUrl, match, history } = props;
  const response = useTopupRequest(props.match.params.topupId);
  const { refetchTillData } = useTillUIContext();

  const approve = async tillTransaction => {
    tillTransaction.event = 'approve';
    const didSave = await tillTransaction.save();
    if (!didSave) {
      throw tillTransaction.errors;
    } else {
      refetchTillData();
    }
  };

  const reject = async tillTransaction => {
    tillTransaction.event = 'decline';
    const didSave = await tillTransaction.save();
    if (!didSave) {
      throw tillTransaction.errors;
    }
  };

  return (
    <Loadable {...response}>
      {tillTransaction => {
        return (
          <Wizard
            analyticsId="TillTransactionReview"
            baseUrl={baseUrl}
            history={history}
            initialData={tillTransaction}
            cancelReturnsTo={tellerPath()}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={approve}
            onReject={reject}
            steps={steps}
            startId="review"
            titleId={
              tillTransaction.type === TillTransactionTypes.TOPUP
                ? 'TopupReview.shortTitle'
                : 'TillToVaultReview.shortTitle'
            }
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
