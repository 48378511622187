import * as React from 'react';

import { GenericPolymorphic } from '@kwara/components/src/GenericPolymorphic';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { ValueOf } from 'GlobalTypes';

import { Header, HeaderPropTypes } from './components/Header/Header';
import { Body, BodyPropTypes } from './components/Body/Body';

const EXPANDER_STATE = {
  expanded: 'expanded',
  collapsed: 'collapsed'
};

export type ExpanderState = ValueOf<typeof EXPANDER_STATE>;

type FACCPropTypes = { state: ExpanderState; isExpanded: boolean; toggle: () => void };
type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ExpanderElement = React.ElementRef<'div'>;
interface ExpanderPropTypes extends Omit<PrimitiveDivProps, 'children'> {
  as?: string;
  expandOnMount?: boolean;
  isActive?: boolean;
  children: ((props: FACCPropTypes) => React.ReactElement) | any;
  Header?: React.FunctionComponent<HeaderPropTypes>;
  Body?: React.FunctionComponent<BodyPropTypes>;
}

/**
 * @Expander this component provides two ways of rendering its ```children```, namely:
 *
 *  1. FACC(Functional as Child Component): with this way, we inverse the application
 *     control by encapsulating the base control into an augmented consumer control.
 *     This is very handy when the consumer want to trigger an ```action``` defined
 *     inside the Provider/Low_Level_Component/In other words parent component e.g:
 *     ```ts
 *         <Expander.Root aria-label="Documents">
 *             {props => (
 *               <>
 *                <Expander.Header
 *                   isExpanded={props.isExpanded}
 *                   onToggle={function augmentedToggleHandler() {
 *                     props.toggle();
 *                   }}
 *                 >
 *                   <button
 *                     type="button"
 *                     onClick={() => {
 *                       props.toggle()
 *                     }}
 *                   >
 *                     Hey, do you know i can also toggle <span role="img" aria-label="">👆</span>
 *                   </button>
 *                 </Expander.Header>
 *                  <Expander.Body>Body</Expander.Body>
 *                </>
 *               )}
 *       </Expander.Root>
 *     ```
 *
 *  2. CC(Compound component): we just call the Compounded component. Note, we only
 *     need to pass in the children to the ```Body```component because the rest
 *     properties are passed in by the ```Expander Root``` component. e.g:
 *       ```ts
 *         <Expander.Root aria-label="Documents">
 *           <Expander.Header>Header</Expander.Header>
 *
 *           <Expander.Body>Body</Expander.Body>
 *         </Expander.Root>
 *       ```
 */
const Expander = React.forwardRef<ExpanderElement, ExpanderPropTypes>(function Expander(
  { expandOnMount, as, children, isActive = true, ...restProps },
  forwardedRef
) {
  const [isExpanded, { toggle }] = useBoolean(expandOnMount);
  const state = isExpanded ? EXPANDER_STATE.expanded : EXPANDER_STATE.collapsed;

  if (!isActive && typeof children !== 'function') return children;

  /// FOR INVERSION OF CONTROL TO CONSUMER
  if (typeof children === 'function') {
    return (
      <GenericPolymorphic {...restProps} as={as ?? 'div'} ref={forwardedRef}>
        {children({ isExpanded, state, toggle } as FACCPropTypes)}
      </GenericPolymorphic>
    );
  }

  return (
    <GenericPolymorphic {...restProps} as={as ?? 'div'} ref={forwardedRef}>
      {React.Children.map(children, function renderReconstructedChild(child) {
        const validChild = child as React.ReactElement<React.PropsWithChildren<HeaderPropTypes & BodyPropTypes>>;

        if (validChild.type === Header) return React.cloneElement(validChild, { isExpanded, onToggle: toggle });

        if (validChild.type === Body) return React.cloneElement(validChild, { state });

        return child;
      })}
    </GenericPolymorphic>
  );
});

export default {
  Root: Expander,
  Header,
  Body
};
