import * as React from 'react';

import { useSmsPlan } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { SmsPayment } from '@kwara/models/src';
import { formatPhoneNumber } from '@kwara/models/src/models/MpesaTransaction';
import { PollSmsPayment } from './components/PollSmsPayment';

import Wizard from '../../components/Wizard';
import { steps } from './config';
import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

const analyticsId = 'RenewSMS';
export const RenewSMS = ({ baseUrl }) => {
  const store = useSaccoProfileContext();

  const r = useSmsPlan();
  const [smsPaymentId, setSmsPaymentId] = React.useState(null);

  async function onSubmit({ phoneNumber, numberOfSms }) {
    const smsPayment = new SmsPayment({
      numberOfSms,
      purchasePhoneNumber: formatPhoneNumber(phoneNumber)
    });

    const didSave = await smsPayment.save();

    if (!didSave) {
      throw smsPayment.errors;
    }

    setSmsPaymentId(smsPayment.id);
  }

  return (
    <Loadable {...r}>
      {smsPlan => (
        <Wizard
          analyticsId={analyticsId}
          baseUrl={baseUrl}
          initialData={{
            costPerSms: smsPlan.cost()
          }}
          cancelReturnsTo={baseUrl}
          currentStep="renew"
          onSubmit={onSubmit}
          steps={steps}
          startId="renew"
          titleId="RenewSMS.title"
          onRenderCustomCompletionScreen={onCancel => (
            <PollSmsPayment
              analyticsId={analyticsId}
              id={smsPaymentId}
              onConfirm={() => {
                store.refetch();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Loadable>
  );
};
