import React from 'react';
import { MetricType, TillTransactionType, TillSessionT } from '@kwara/models/src';

import { useCurrentTillTransactions, useCurrentTillSession, useCurrentTillMetrics } from '../../models/hooks';

import { ModalState, ModalAction } from '.';
import { AuthType } from '../../hooks';

type State = {
  activeModal: ModalState;
  tillLoading: boolean;
};

export type TillPageT = {
  activeModal: ModalState;
  updateModal: ModalAction;
  endTillSession: ({ amountBackToVault }: { amountBackToVault: string }) => Promise<void>;
  startTillSession: ({ tillId }: { tillId: string }) => Promise<void>;
  metrics: MetricType[];
  transactions: TillTransactionType[];
  tillTransactionsLoading: boolean;
  error: any;
  isTillOpen: boolean;
  isTillLoading: boolean;
  currentTillSession: TillSessionT;
  refetchTillData: () => void;
};

export function useTillPage(auth: AuthType) {
  const [state, setState] = React.useState<State>({
    tillLoading: false,
    activeModal: null
  });

  const {
    data: currentTillSession,
    isLoading: currentTillIsLoading,
    refetch: refetchCurrentTillSession
  } = useCurrentTillSession(auth.isLoggedIn() && !auth.isV1());

  const isTillOpen = !!currentTillSession;

  const {
    data: transactions,
    isLoading: tillTransactionsLoading,
    error,
    refetch: refetchTillTransactions,
    remove: removeTillTransactionData
  } = useCurrentTillTransactions(isTillOpen);
  const { data: metrics, refetch: refetchTillMetrics } = useCurrentTillMetrics(isTillOpen);

  const startTillSession = async ({ tillId }: { tillId: string }) => {
    setState(prev => ({ ...prev, tillLoading: true }));
    // TO DO: startTillSession call should not live in Auth
    const success = await auth.startTillSession(tillId);

    if (!success) {
      setState(prev => ({ ...prev, tillLoading: false }));
      return;
    }

    setState(prev => ({ ...prev, tillLoading: false }));

    updateModal({ activeModal: 'success' });
    refetchCurrentTillSession();
    refetchTillMetrics();
    refetchTillTransactions();
  };

  const endTillSession = async ({ amountBackToVault }: { amountBackToVault: string }) => {
    // TO DO: endTillSession call should not live in Auth
    const success = await auth.endTillSession({ amountBackToVault });

    if (!success) {
      return;
    }

    updateModal({ activeModal: 'success' });
    refetchCurrentTillSession();
    removeTillTransactionData();
    refetchTillMetrics();
  };

  const updateModal = ({ activeModal }: { activeModal: ModalState }) => {
    setState(prev => ({
      ...prev,
      activeModal
    }));
  };

  const refetchTillData = React.useCallback(() => {
    refetchCurrentTillSession();
    refetchTillTransactions();
    refetchTillMetrics();
  }, [refetchCurrentTillSession, refetchTillTransactions, refetchTillMetrics]);

  return {
    activeModal: state.activeModal,
    updateModal,
    endTillSession,
    startTillSession,
    metrics,
    transactions,
    tillTransactionsLoading,
    error,
    isTillOpen,
    isTillLoading: state.tillLoading || currentTillIsLoading,
    currentTillSession,
    refetchTillData
  };
}
