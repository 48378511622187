import * as React from 'react';
import { withRouter } from 'react-router-dom';
import isNull from 'lodash/isNull';

import { TillTransaction, type TillTransactionT } from '@kwara/models/src';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { Text } from '@kwara/components/src/Intl';

import { topupRequestPath, tillToVaultRequestPath } from '../../../../lib/urls';
import { ButtonMenu } from '../../../../components/ButtonMenu';
import OverviewList from '../../../../components/OverviewList';
import { type ModalAction } from '../..';
import { TillTransactionsTable } from '../TillTransactionsTable';
import { usePermissions } from '../../../../hooks';

type Props = {
  hasMore?: boolean,
  loading?: boolean,
  transactions: TillTransactionT[],
  errors: Object[],
  onLoadMoreData: () => void,
  totalNumResults: ?number,
  updateModal: ModalAction,
  isTillOpen: boolean
};

const AddButtonMenu = ({ updateModal, isTillOpen }): { updateModal: ModalAction, isTillOpen: boolean } => {
  const { permission, AppPermissions } = usePermissions();

  const items = isTillOpen
    ? [
        permission.to(AppPermissions.AddDeposits) ? (
          <ButtonMenu.Item onClick={() => updateModal({ activeModal: 'deposit' })} key="deposit">
            <Text id="TillTransactionsList.addDeposit" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.AddWithdrawal) ? (
          <ButtonMenu.Item onClick={() => updateModal({ activeModal: 'withdrawal' })} key="withdrawal">
            <Text id="TillTransactionsList.addWithdrawal" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.AddRepayments) ? (
          <ButtonMenu.Item onClick={() => updateModal({ activeModal: 'repayment' })} key="repayment">
            <Text id="TillTransactionsList.addRepayment" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateTopupRequests) ? (
          <ButtonMenu.Item to={topupRequestPath()} key="topup">
            <Text id="TillTransactionsList.topup" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateUtilityTransactions) ? (
          <ButtonMenu.Item to="/till/payment" key="payment">
            <Text id="TillTransactionsList.addUtilitiesPayment" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateUtilityTransactions) ? (
          <ButtonMenu.Item to="/till/receipt" key="receipt">
            <Text id="TillTransactionsList.addUtilitiesReceipt" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateUtilityTransactions) ? (
          <ButtonMenu.Item to={tillToVaultRequestPath()} key="vault">
            <Text id="TillTransactionsList.tillToVaultTransfer" />
          </ButtonMenu.Item>
        ) : null
      ]
    : [];

  return (
    <React.Fragment>
      <ButtonMenu disabled={items.every(isNull)} title={<Text id="TillTransactionsList.add" />}>
        {items}
      </ButtonMenu>
    </React.Fragment>
  );
};

// WIP To Do: Make Button sizes consistent with other pages
// i.e. Members, Savings, Loans [ch5666]
const AddTillListButtonActions = ({ updateModal, isTillOpen }: { updateModal: ModalAction, isTillOpen: boolean }) => {
  return [
    <DownloadButton disabled={!isTillOpen} key="pdfButton" downloadFn={TillTransaction.downloadTransactionsList} />,
    <AddButtonMenu key="buttonMenu" updateModal={updateModal} isTillOpen={isTillOpen} />
  ];
};

export const TillTransactionsList = withRouter(
  ({
    hasMore = false,
    loading = false,
    transactions = [],
    errors,
    onLoadMoreData,
    totalNumResults,
    updateModal,
    isTillOpen
  }: Props) => (
    <OverviewList
      actions={AddTillListButtonActions({
        updateModal,
        isTillOpen,
        transactions
      })}
      headerId="TillTransactionsList.heading"
      labelId={
        totalNumResults ? 'TillTransactionsList.filteredCount' : 'TillTransactionsList.filteredCountWithoutTotal'
      }
      items={transactions}
      table={
        <TillTransactionsTable
          hasMore={hasMore}
          transactions={transactions}
          onLoadMoreData={onLoadMoreData}
          loading={loading}
          errors={errors}
          isTillOpen={isTillOpen}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
