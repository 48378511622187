import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { events } from '@kwara/models/src/models/Event';

import * as filters from '.';

import { useUsersFilter } from './useUsersFilter';
import { BranchesFilter } from '../../../../../components/BranchesFilter';
import { FILTER_TYPES } from '../../../../../components/Filter/utils/filterTypes';
import { useBranchesFilter } from '../../../../../components/BranchesFilter/useBranchesFilter';

export function useEventsFilters() {
  const int = useIntl();
  const usersFilter = useUsersFilter();
  const branchesFilter = useBranchesFilter();
  const eventsFilter = useMemo(
    () =>
      events.map(event => ({
        value: event,
        name: int.formatMessage({ id: `Events.${event}` }),
        belongsTo: FILTER_TYPES.ACTIVITY_FEED_EVENT
      })),
    [int]
  );

  return {
    [FILTER_TYPES.ACTIVITY_FEED_EVENT]: { data: eventsFilter, page: filters.ActivityFeedEventFilter },
    [FILTER_TYPES.ACTIVITY_FEED_USER]: { data: usersFilter, page: filters.ActivityFeedUserFilter },
    [FILTER_TYPES.ACTIVITY_FEED_DATE]: { data: [], page: filters.ActivityFeedDateFilter },
    [FILTER_TYPES.MEMBER_ID]: { data: [], page: filters.ActivityFeedMemberIdFilter },
    [FILTER_TYPES.ACCOUNT_ID]: { data: [], page: filters.ActivityFeedAccountIdFilter },
    [FILTER_TYPES.BRANCH_ID]: { data: branchesFilter.allBranches, page: BranchesFilter }
  };
}
