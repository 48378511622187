import * as React from 'react';

import find from 'lodash/fp/find';

import { CollateralT, LoanApplicationT } from '@kwara/models/src';
import { UploadWidgetUI, FileNameT } from '@kwara/components/src/UploadWidgetUI';

import { WithViewer } from './components/Viewer';

type Props = {
  labelId: string;
  infoId?: string;
  fileName: FileNameT;
  onRemove: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onFileInputChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  collateral: CollateralT;
  loanApplication: LoanApplicationT;
};

export function CollateralUploadWidget({
  labelId,
  fileName,
  onRemove,
  onFileInputChange,
  checked,
  collateral,
  loanApplication
}: Props) {
  return (
    <WithViewer attachments={collateral?.attachments} loanApplication={loanApplication}>
      {({ setShownAttachment }) => (
        <UploadWidgetUI
          checked={checked}
          onView={() => setShownAttachment(find(o => o.name === fileName, collateral.attachments))}
          onRemove={onRemove}
          onFileInputChange={onFileInputChange}
          labelId={labelId}
          fileName={fileName}
        />
      )}
    </WithViewer>
  );
}
