import React, { useCallback, useMemo } from 'react';
import { FormProps } from 'react-final-form';

import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { checkable_item_ids } from '@kwara/models/src/models/CheckableItem';

import { usePermissions } from '../../../../hooks';

export function useActions(form: FormProps, values: Record<string, string>) {
  const { permission, AppPermissions } = usePermissions();

  const onSubmit = useCallback(
    async (event: string) => {
      await form.change('event', event);
      await form.submit();
      form.reset();
    },
    [form]
  );

  const selected = size(get(checkable_item_ids, values)) > 0;
  const permitted = permission.to(AppPermissions.ReviewJournalEntries);

  return useMemo(
    () => [
      <Button
        key="reject"
        type="destructive"
        disabled={!selected}
        hidden={!permitted}
        onClick={() => onSubmit('reject')}
      >
        <Text id="Finance.JournalEntries.List.reject" />
      </Button>,
      <Button key="approve" type="primary" disabled={!selected} hidden={!permitted} onClick={() => onSubmit('approve')}>
        <Text id="Finance.JournalEntries.List.approve" />
      </Button>
    ],
    [onSubmit, permitted, selected]
  );
}
