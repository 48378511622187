import * as React from 'react';

import { MemberSubstepProps } from 'GlobalTypes';

import { If } from '@kwara/components/src/If/If';
import { IdDocument } from '@kwara/models/src';
import { appName } from '@kwara/lib/src/utils';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';

import { useIdentity } from './useIdentity';
import { IdentityInput } from './IdentityInput';
import * as recordValidations from '../../../../lib/recordValidations';

import styles from '../../index.module.css';

const DOCUMENT_TYPES = [{ value: 'PASSPORT' }, { value: 'NATIONAL' }];
const SECOND_IDENTITY_INPUT_NAME = 'idDocuments[1].documentId';

export interface IdentityPropTypes extends MemberSubstepProps {
  prefix: string;
  size?: StackChildSize;
  className?: string;
  memberEdit: boolean;
}

export default function Identity(props: IdentityPropTypes) {
  const {
    size: stackSize,
    onChange,
    SelectField,
    TextField,
    StackChild,
    config,
    formProps,
    memberEdit,
    setError,
    disableNextButton,
    enableNextButton,
    className,
    prefix = '',
    data = {}
  } = props;

  const {
    isOpen,
    idDocuments,
    getPath,
    getProps,
    isRequired,
    onClickLinkButtonHandler,
    onSelectDocumentType
  } = useIdentity({ onChange, data, memberEdit, prefix, enableNextButton, formProps });

  return (
    <StackChild size={stackSize}>
      {mapIndexed((_field: IdDocument, idx: number) => {
        const { name, idType, isPrimaryId, showLink, inputName, isStandardDocType, identityInputDataProp } = getProps(
          idx
        );

        return (
          <div key={idx.toString()} data-testid={`idDocument${idx}`}>
            <div className={className}>
              <If
                condition={!memberEdit}
                do={
                  <span className={styles.IdTypeSelect}>
                    <SelectField
                      name={`${name}.type`}
                      disabled={memberEdit}
                      required={isPrimaryId}
                      labelId={`AddMember.Identity.Type.Document.${isPrimaryId ? 'primary' : 'alternative'}.label`}
                      inputOnChange={onSelectDocumentType(isPrimaryId)}
                    >
                      <SelectField.Option translationId="AddMember.Identity.Type.NONE" value="" />
                      {DOCUMENT_TYPES.map(({ value }) => (
                        <SelectField.Option
                          key={value}
                          value={value}
                          translationId={`AddMember.Identity.Type.${value}`}
                        />
                      ))}
                    </SelectField>
                  </span>
                }
              />
              <If
                condition={!!idType}
                do={
                  <IdentityInput
                    name={inputName}
                    element={TextField}
                    setError={setError}
                    isPrimaryId={isPrimaryId}
                    data={identityInputDataProp}
                    disableNextButton={disableNextButton}
                    enableNextButton={enableNextButton}
                    labelId={isStandardDocType ? `AddMember.Identity.Value.${idType}.label` : null}
                    labelContent={isStandardDocType ? null : idType}
                    placeholderId={`AddMember.Identity.Value.${idType}.placeholder`}
                  />
                }
              />
            </div>

            <If
              condition={showLink && appName.isSacco}
              do={
                <LinkButton as="button" disabled={memberEdit} onClick={onClickLinkButtonHandler}>
                  <Text id={`AddMember.Identity.Type.Document.alternative.${isOpen ? 'remove' : 'add'}`} />
                </LinkButton>
              }
            />
          </div>
        );
      }, idDocuments)}

      <div className="pv3">
        <TextField
          name={getPath('kraPin')}
          labelId="AddMember.Identity.KRA.label"
          placeholderId="AddMember.Identity.KRA.placeholder"
          required={isRequired('kraPin', config, formProps)}
        />
      </div>
    </StackChild>
  );
}

/**
 * Configs
 */
const base = {
  ['idDocuments[0].type']: { isRequired: () => true },
  ['idDocuments[0].documentId']: {
    isRequired: () => true,
    ...recordValidations.identityDocumentValue
  },
  [SECOND_IDENTITY_INPUT_NAME]: {
    ...recordValidations.identityDocumentValue
  }
};

const extended = {
  ...base,
  kraPin: { isRequired: () => true }
};

Identity.validateConfig = {
  memberAdd: base,
  memberDetail: {
    kraPin: { isRequired: () => true }
  },
  loanAdd: extended,
  memberRegister: extended
};
