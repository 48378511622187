import * as React from 'react';

import { BaseModelPrototypeTypes, ApiError } from 'GlobalTypes';

import { LoanType } from '@kwara/models/src';

type StateType = {
  isLoading: boolean;
  error: ApiError | null;
};

const INITIAL_STATE: StateType = {
  isLoading: false,
  error: null
};

type EditedLoanTypes = LoanType & BaseModelPrototypeTypes;

export function useUpdateCollateral() {
  const [state, setState] = React.useState(INITIAL_STATE);

  async function mutate({
    editedLoan,
    onSuccess,
    onError
  }: {
    editedLoan: EditedLoanTypes;
    onSuccess?: () => void;
    onError?: () => void;
  }) {
    setState(prev => ({ ...prev, isLoading: true }));

    try {
      /**
       * @save is a prototype method defined in the spraypaint Base Model
       * and is inherited by all sub_class models of the Base Model.
       * Thats how we came about having access to the ```save prototype method```
       */
      await editedLoan.save({ with: 'collaterals' });
      setState(INITIAL_STATE);
      onSuccess?.();
    } catch (error) {
      setState({ error, isLoading: false });
      onError?.();
    }
  }

  return {
    ...state,
    mutate,
    reset() {
      setState(INITIAL_STATE);
    }
  };
}
