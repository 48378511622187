import * as React from 'react';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src/Intl';
import { PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

export const FeesAndPenalties = ({ saving }) => {
  const fees = get('fees.due', saving);
  return (
    <StatisticRow widthClass="w-50">
      <PenaltyStatistic
        title={<Text id="SavingDetail.FeesAndPenalties.feesBalance" />}
        errorValue={fees}
        size={'large'}
      />
    </StatisticRow>
  );
};
