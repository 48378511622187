import * as React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { ROUTES } from '../../../../routes';
import { WithSubNav } from './components/WithSubNav';
import { WithoutSubNav } from './components/WithoutSubNav';
import { ExtraActionModal } from './components/ExtraActionModal';
import { IconMore } from './assets/IconMore.svg';
import { IconExtraAction } from './assets/IconExtraAction.svg';
import { IconKwaraLogo } from './assets/IconKwaraLogo.svg';
import { IconCollapsable } from './assets/IconCollapsable.svg';
import { useLeftNavigation } from './hooks/useLeftNavigation';

import styles from './index.module.scss';

export type SearchParams = {
  parentId?: string;
};

export function LeftNavigation() {
  const collapserRowRef = React.useRef<HTMLLIElement | null>(null);
  const {
    isCollapsed,
    isExtraActionModalOpened,
    routes,
    extraActionModalCallee,
    collapsableIconAccessibilityLabel,
    showAddNewAction,
    showMoreAction,
    onCloseExtraActionModal,
    onOpenExtraActionModal,
    onToggleCollapsable
  } = useLeftNavigation();

  const navitemclassname = cx(styles['nav-item'], styles[isCollapsed ? 'nav-item--closed' : 'nav-item--opened']);

  return (
    <>
      <aside>
        <nav className={cx('kw-text-small kw-weight-regular', zIndices.Navigation, styles['container'])}>
          <ul className={cx('ma0 pa0 list', styles['list'])}>
            <li ref={collapserRowRef} className={styles['logo-li']}>
              <AccessibleIcon label={collapsableIconAccessibilityLabel}>
                <button title="Collapse" data-testid="collapserBtn" onClick={onToggleCollapsable}>
                  <IconCollapsable isCollapsed={isCollapsed} />
                </button>
              </AccessibleIcon>
              <Link to={ROUTES.home}>
                <span>
                  <IconKwaraLogo />
                </span>
              </Link>
            </li>

            <If
              condition={showAddNewAction}
              do={
                <WithoutSubNav
                  icon={IconExtraAction}
                  nameId="navigation.addNew.button"
                  className={styles['add-new-li']}
                  onClick={onOpenExtraActionModal('addNew')}
                />
              }
            />

            {routes.map(({ id, path, openOnMount, subNavigations, ...restNavItemProps }) => {
              const hasSubNavigations = !!subNavigations;

              return (
                <If
                  key={id}
                  condition={hasSubNavigations}
                  do={
                    <WithSubNav
                      {...restNavItemProps}
                      openOnMount={openOnMount}
                      className={navitemclassname}
                      subNavigations={subNavigations}
                      isLeftNavigationCollapsed={isCollapsed}
                      onToggleCollapsable={onToggleCollapsable}
                      closeOnNodesMouseDown={[collapserRowRef]}
                    />
                  }
                  else={<WithoutSubNav {...restNavItemProps} key={id} path={path} className={navitemclassname} />}
                />
              );
            })}

            <If
              condition={showMoreAction}
              do={
                <WithoutSubNav
                  icon={IconMore}
                  nameId="navigation.more"
                  onClick={onOpenExtraActionModal('more')}
                  className={navitemclassname}
                />
              }
            />
          </ul>
        </nav>
      </aside>

      <If
        condition={isExtraActionModalOpened}
        do={
          <ExtraActionModal
            isOpen={isExtraActionModalOpened}
            callee={extraActionModalCallee}
            onClose={onCloseExtraActionModal}
          />
        }
      />
    </>
  );
}
