import React, { useState } from 'react';
import cx from 'classnames';
import { Document, Page } from 'react-pdf';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { LinearSkeletonUi } from '@kwara/components/src/Loadable/LoadingSkeleton';

import styles from './index.module.scss';

type PdfViewerPropTypes = {
  pdfUrl: string;
  errorId?: string;
};

export function PdfViewer(props: PdfViewerPropTypes) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const shouldDisabledNextButton = pageNumber === numPages;
  const shouldDisabledPreviousButton = pageNumber <= 1;
  const hasDocument = !!numPages;

  return (
    <div className={styles['pdf-viewer']}>
      <Document
        file={props.pdfUrl}
        loading={<LinearSkeletonUi />}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
          setPageNumber(1);
        }}
        error={<ErrorTextWithI18n id={props.errorId ?? 'PdfViewer.error'} />}
      >
        <If condition={!!pageNumber} do={<Page pageNumber={pageNumber} />} />
      </Document>

      <If
        condition={hasDocument}
        do={
          <div className={styles['pdf-viewer__btn-wrappers']}>
            <button
              type="button"
              className={cx(styles['button'], styles['button--prev'])}
              disabled={shouldDisabledPreviousButton}
              onClick={() => setPageNumber(prev => prev - 1)}
            >
              <Text id="PdfViewer.prev" />
            </button>
            <p>
              <Text id="PdfViewer.pageInfo" values={{ pageNumber, numPages }} />
            </p>
            <button
              type="button"
              className={cx(styles['button'], styles['button--next'])}
              disabled={shouldDisabledNextButton}
              onClick={() => setPageNumber(prev => prev + 1)}
            >
              <Text id="PdfViewer.next" />
            </button>
          </div>
        }
      />
    </div>
  );
}
