//@flow
import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { TillSession, type TopupRequestT } from '@kwara/models/src';
import { setFavicon } from '@kwara/models/src/lib/favicon';
import { Loadable } from '@kwara/components/src/Loadable';

import TellerList from './components/TellerList';
import { topupReviewPath } from '../../lib/urls';
import { BodyContentWrapper } from '../../layouts';
import { TellerDashboard } from './components/Dashboard';
import { DataViewWrapper } from '../../components/DataViewWrapper';
import { useThrottledCallback, isEMPTY, useTopupRequests } from '../../hooks';

function formattedMessages(topupRequests: TopupRequestT[]) {
  return map(r => {
    return {
      type: r.type,
      amount: r.requestedAmount,
      updated: r.updatedAt,
      tellerName: r.tillSession.tellerName,
      tillName: r.tillName,
      tillSessionId: get('tillSession.id', r),
      topupRequest: r
    };
  }, topupRequests);
}

const Content = props => {
  const { hasMore, loading, errors, data: tillSessions, onLoadMoreData, totalNumberResults, history } = props;

  const { refetch, ...r } = useTopupRequests();

  useThrottledCallback(refetch);

  if (!r.isLoading) {
    setFavicon(!isEmpty(r.data) ? 'notification' : undefined);
  }

  return (
    <BodyContentWrapper.Root headId="TellerList.title">
      <BodyContentWrapper.Row.Root>
        <Loadable {...r} isLoading={r.isLoading && isEMPTY(r.data)}>
          {requests => (
            <TellerDashboard
              messages={formattedMessages(requests)}
              inboxAction={message => {
                const id = get('topupRequest.id', message);
                history.push(topupReviewPath({ id }));
              }}
            />
          )}
        </Loadable>
      </BodyContentWrapper.Row.Root>

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <TellerList
            tillSessions={tillSessions}
            onLoadMoreData={onLoadMoreData}
            hasMore={hasMore}
            loading={loading}
            errors={errors}
            history={history}
            totalNumResults={totalNumberResults}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};

export const Tellers = ({ location, ...rest }) => {
  return (
    <DataViewWrapper
      component={props => <Content {...rest} {...props} />}
      scope={TillSession.includes('till')}
      pathname="/tellers"
      location={location}
    />
  );
};
