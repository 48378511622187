import React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import zIndices from '@kwara/lib/src/zIndices';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { ActionButton } from '@kwara/components/src/Button';
import { DividendContributionSummaryT, DividendCalculationMethodT } from '../../../../../../../models';
import { ReportType } from '../../../useReportType';

import { MonthlyBreakdownTable } from './MonthlyBreakdownTable';
import styles from './MonthlyBreakdownPanel.module.css';

export const MonthlyBreakdownPanel = React.forwardRef<
  React.ElementRef<'div'>,
  {
    summary: DividendContributionSummaryT;
    onClose: () => void;
    reportType: ReportType;
    reportCalculationMethod: undefined | DividendCalculationMethodT;
  }
>(function MonthlyBreakdownPanel({ summary, onClose, reportType, reportCalculationMethod }, forwardedRef) {
  return (
    <If
      condition={!!summary}
      do={
        <Overlay opacityPercentage={20}>
          <div ref={forwardedRef} className={cx(zIndices.Modal, styles.wrapper)}>
            <ActionButton onClick={onClose} className="absolute top-2 right-2" type="cancel" />
            <div className="relative">
              <h2>{summary?.memberName}</h2>
              <MonthlyBreakdownTable
                summary={summary}
                reportType={reportType}
                reportCalculationMethod={reportCalculationMethod}
              />
            </div>
          </div>
        </Overlay>
      }
    />
  );
});
