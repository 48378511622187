import { Triggers } from './components/Triggers.js';

export const steps = {
  edit: {
    titleId: 'SmsTriggerEdit.form.title',
    subtitleId: 'SmsTriggerEdit.form.subtitle',
    Component: Triggers,
    validate: Triggers.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
