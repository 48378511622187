import { attr, hasOne } from 'spraypaint';
import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { sumBy } from '@kwara/lib/src/currency';
import { blobHeaders, handleDownload } from '@kwara/lib/src/fileDownload';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';

import { BranchT } from './Branch';
import { TransactionType } from './Transaction';
import Base, { BaseModel } from './Base';
import { GeneralLedgerAccount, GeneralLedgerAccountT } from './GeneralLedgerAccount';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

type CreditT = {
  credit_account: string;
  credit_amount: string;
};

type DebitT = {
  debit_account: string;
  debit_amount: string;
};

export const JournalEntry = Base.extend({
  static: {
    jsonapiType: 'journal_entries',

    downloadPDF(params: { from: string; to: string; branch_id?: string }) {
      const url = `${JournalEntry.url()}.pdf`;
      const { from, to } = params;
      const fileName = `journal_entries_${from}_to_${to}`;

      const opts = JournalEntry.fetchOptions();
      const options = { params: removeNullishValues(params), ...blobHeaders(opts) };

      return Base.downloadFileFromUrl(url, options, fileName);
    },
    downloadXLSX(params: { from: string; to: string; branch_id?: string }) {
      const url = `${JournalEntry.url()}.xlsx`;
      const { from, to } = params;
      const opts = JournalEntry.fetchOptions();
      const options = { params: removeNullishValues(params), ...(blobHeaders(opts) as AxiosRequestConfig<any>) };
      const fileName = `journal_entries_${from}_to_${to}`;

      return axios.get(url, options).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  },

  attrs: {
    type: attr(),
    credits: attr(),
    debits: attr(),
    date: attr(),
    notes: attr(),
    bookingDate: attr(),
    amount: attr(),
    transactionId: attr(),
    glAccount: hasOne({ type: GeneralLedgerAccount }),
    transaction: hasOne(),
    branch: hasOne()
  },
  methods: {
    accountId() {
      return get('transaction.account.id', this);
    },
    memberId() {
      return get('transaction.account.member.id', this);
    },
    memberName() {
      return invoke('transaction.account.member.fullName', this);
    },
    total() {
      return sumBy('credit_amount', this.credits);
    }
  }
});

export const CheckableJournalEntry = JournalEntry.extend({
  static: { jsonapiType: 'checkable_journal_entries' }
});

export interface JournalEntryT extends BaseModel<JournalEntryT> {
  credits: CreditT[];
  debits: DebitT[];
  date: Date;
  type: 'CREDIT' | 'DEBIT';
  notes: string;
  bookingDate: string;
  amount: string;
  transactionId: string;
  glAccount: GeneralLedgerAccountT;
  transaction: TransactionType;
  branch: BranchT;
}
