// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import concat from 'lodash/fp/concat';
import pipe from 'lodash/fp/pipe';
import isEmpty from 'lodash/fp/isEmpty';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { toDurationFromUI } from '@kwara/models/src/models/util';
import { Statistic } from '@kwara/components/src/Statistic';
import { ExpanderRow } from '@kwara/components/src';
import { Date, Text, Duration, Percent } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { totalBalance } from '@kwara/models/src/models/LoanApplication';
import { getLoansfromLoanIds } from '@kwara/components/src/pages/LoanAdd';

import { Currency } from '../../../components/Currency';
import { Grid } from '../../../components/Grid';
import { filterDeletedSecurities } from '../../LoanReschedule';
import { type ComponentPropsLoanRefinance, type RefinanceFormData } from '..';

const formatRepaymentFrequency = (repaymentPeriod, repaymentPeriodUnit) =>
  toDurationFromUI(Number(repaymentPeriod), repaymentPeriodUnit);

const Header = (props: { children: React.Node }) => <header className="flex">{props.children}</header>;

export function hasNoSecurities(data: RefinanceFormData) {
  const securities = concat(data.collaterals || [], data.guarantors || []);
  return pipe(filterDeletedSecurities, isEmpty)(securities);
}

const payOffLoanRecord = loan => (
  <div key={loan.id}>
    {get('product.name', loan)} - <Currency format="currency" value={loan.totalBalance} />
  </div>
);

export const Summary = ({ data, StackChild }: ComponentPropsLoanRefinance) => {
  const {
    amount,
    loanDuration,
    repaymentPeriod,
    repaymentPeriodUnit,
    product,
    member,
    loan,
    guarantors = [],
    collaterals = [],
    loans,
    payOffLoans: payOffLoanIds
  } = data;

  const duration = toDurationFromUI(Number(loanDuration), repaymentPeriodUnit);

  const repaymentFrequency = formatRepaymentFrequency(repaymentPeriod, repaymentPeriodUnit);

  const payOffLoans = getLoansfromLoanIds(payOffLoanIds, loans);

  return (
    <StackChild>
      <Header>
        <Text id="MemberDetail.personalDetails" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          <Statistic
            title={<Text id="LoanRefinance.LoanSummary.MemberDetails.memberId" />}
            value={member.id}
            size={'medium'}
          />
          <ProfilePhoto memberId={member.id} attachments={member.attachments} size="regular" isExpandable />
          <Statistic
            title={<Text id="MemberDetail.PersonalDetails.fullName" />}
            value={member && member.nameWithTitle()}
            size={'medium'}
          />
          <Statistic
            title={<Text id="MemberDetail.PersonalDetails.dateOfBirth" />}
            value={<Date value={member.dateOfBirth} />}
            size={'medium'}
          />
        </Grid>
      </ExpanderRow>

      <Header>
        <Text id="LoanRefinance.LoanSummary.LoanDetails.Header" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          <Statistic
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.Product" />}
            value={product.name}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.Interest" />}
            value={<Percent value={product.interestRate.percentage / 100} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id={`LoanRefinance.LoanSummary.LoanDetails.PreviousBalance`} />}
            value={<Currency value={get('totalBalance', loan)} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id={`LoanRefinance.LoanSummary.LoanDetails.LoanAmount`} />}
            value={<Currency value={amount} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.RepaymentFrequency" />}
            value={<Duration value={repaymentFrequency} />}
            size={'medium'}
          />
          <Statistic
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.LoanDuration" />}
            value={<Duration value={duration} />}
            size={'medium'}
          />
          <Statistic
            size={'medium'}
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.payOffLoans" />}
            value={map(loan => payOffLoanRecord(loan), payOffLoans)}
          />
          <Statistic
            size={'medium'}
            title={<Text id="LoanRefinance.LoanSummary.LoanDetails.payOffAmount" />}
            value={<Currency value={totalBalance(payOffLoans)} />}
          />
        </Grid>
      </ExpanderRow>
      <Header>
        <Text id="LoanRefinance.LoanSummary.Security.Header" />
      </Header>
      <ExpanderRow isExpanded>
        <Grid columns={2} width="w-50">
          {hasNoSecurities(data) ? <Text id="LoanRefinance.LoanSummary.noSecurity" /> : null}

          {map(
            guarantee => (
              <Statistic
                key={guarantee.member.id}
                title={<Currency format="currency" value={guarantee.amount} />}
                value={guarantee.member.fullName()}
                size="small"
                compact
              />
            ),
            filterDeletedSecurities(guarantors)
          )}

          {mapIndexed(
            (collateral, index) => (
              <Statistic
                key={index}
                title={<Text id={`LoanRefinance.LoanSummary.Security.Collateral.Type.${collateral.assetName}`} />}
                value={<Currency format="currency" value={collateral.amount} />}
                size="small"
                compact={true}
              />
            ),
            filterDeletedSecurities(collaterals)
          )}
        </Grid>
      </ExpanderRow>
    </StackChild>
  );
};
