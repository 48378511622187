import { ModelErrors, ApiError, ModelError } from 'GlobalTypes';

import { HttpService } from '@kwara/lib/src/services';

import apiConfig from '../config';

import { auth } from '../hooks';

/**
 * @_api used for accessing private endpoints
 */
const _api = new HttpService({
  baseURL: apiConfig.API_ROOT,
  authRequest: true,
  getToken: auth.getRawToken
});

/**
 * @$api used for accessing public endpoints
 */
const $api = new HttpService({
  baseURL: apiConfig.API_ROOT,
  authRequest: false
});

/**
 * @composeErrors composes various "ModelErrors | ApiError" into
 * ```ts
 *    hasError: boolean;
 *    errorCodes: string[];
 *    resetError: () => void;
 *    hasBlockingError: boolean;
 * ```
 */
function composeErrors({
  errors,
  resetters,
  blockingErrorCodes
}: {
  errors: ModelErrors | ApiError;
  blockingErrorCodes?: string[];
  resetters?: (() => void)[];
}) {
  const errorCodes = Object.keys(errors).map(key => errors[key].code);
  const hasError = !!errorCodes.length;

  function resetError() {
    resetters.forEach(internalStateResetter => internalStateResetter());
  }

  const hasBlockingError = hasError && blockingErrorCodes && blockingErrorCodes.some(code => errorCodes.includes(code));

  return {
    hasError,
    errorCodes,
    resetError,
    hasBlockingError
  };
}

/**
 * @retry The react-query retry method option.
 * Should be used as the standard
 */
function retry(failCount: number, error: ModelError & { status: number }) {
  return error.status === 404 ? false : failCount < 1;
}

export { _api, $api, composeErrors, retry };
