import { attr } from 'spraypaint';

import Base, { BaseModel } from '../Base';

export const MemberAgeDemographicStats = Base.extend({
  static: {
    jsonapiType: 'dashboard_member_age_demographic_stats',
    endpoint: '/dashboard/member_age_demographic_stats'
  },
  attrs: {
    unknownBirthdateCount: attr(),
    memberCountPerBucket: attr()
  }
});

type MemberCountT = {
  lower_bound: number;
  upper_bound: number;
  count: number;
};

export interface MemberAgeDemographicStatsT extends BaseModel<MemberAgeDemographicStatsT> {
  unknownBirthdateCount: number;
  memberCountPerBucket: MemberCountT[];
}
