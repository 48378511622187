import * as React from 'react';
import get from 'lodash/fp/get';

import { LoanType, GlContexts } from '@kwara/models/src';
import {
  getTransactionTypes,
  contexts,
  TransactionChannels,
  TransactionChannelT
} from '@kwara/models/src/models/Transactions';
import { DisbursementMethodsFilter } from '@kwara/models/src/models/Transactions/DisbursementMethodsFilter';
import { SubscribedTextField, SubscribedPaymentSelectField, GlAccountSelect } from '@kwara/components/src/Form';

import { LoanDisbursalPropTypes } from '../..';

export type DisbursementMethodPaymentDataType = {
  paymentMethod?: TransactionChannelT;
  reference?: string;
  bankName?: string;
  bankBranch?: string;
  chequeNumber?: string;
  drawer?: string;
  accountNumber?: string;
  accountId?: string;
  bankGlId?: string;
  loan: LoanType;
};

const showBanksFieldsFor: Array<string> = [TransactionChannels.bankTransfer, TransactionChannels.cheque];
const showChequeFieldsFor: Array<string> = [TransactionChannels.cheque];
const ifCheque = {
  isRequired: (_: string, allData: DisbursementMethodPaymentDataType) =>
    showChequeFieldsFor.includes(allData.paymentMethod)
};
const ifChequeOrBank = {
  isRequired: (_: string, allData: DisbursementMethodPaymentDataType) =>
    showBanksFieldsFor.includes(allData.paymentMethod)
};

export interface DisbursementMethodFormPropTypes extends Partial<LoanDisbursalPropTypes> {
  disbursementAmount: number;
}

export const DisbursementMethodForm = ({
  disbursementAmount,
  addData,
  formProps,
  data
}: DisbursementMethodFormPropTypes) => {
  return (
    <>
      <SubscribedPaymentSelectField
        name="paymentMethod"
        labelId="LoanDisburse.method.label"
        inputOnChange={() => addData({ bankGlId: '' })}
        config={{
          bank: 'bankName',
          showBanksFieldsFor,
          showChequeFieldsFor,
          showAccountSelectionFor: [TransactionChannels.fosa],
          memberId: get('loan.member.id', formProps.values),
          methods: DisbursementMethodsFilter.filterDisbursementMethods({
            payoutProvider: get('payoutProvider', data),
            phoneNumberProvider: get('phoneNumberProvider', data),
            transactionAmountLimit: get('transactionAmountLimit', data),
            methods: getTransactionTypes(contexts.LoanDisbursement).values,
            loanAmount: disbursementAmount
          })
        }}
        required
      />
      <GlAccountSelect method={formProps.values.paymentMethod} addData={addData} context={GlContexts.DISBURSAL} />
      <SubscribedTextField
        hidden={formProps.values.paymentMethod == null}
        name="reference"
        size="medium"
        labelId="PaymentForm.reference"
      />
    </>
  );
};

DisbursementMethodForm.validate = {
  paymentMethod: { isRequired: () => true },
  bankName: ifChequeOrBank,
  bankBranch: ifChequeOrBank,
  accountNumber: ifChequeOrBank,
  chequeNumber: ifCheque,
  drawer: ifCheque,
  ...GlAccountSelect.validate
};
