// @flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { DocumentList } from '../../../components/DocumentList';
import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({ Checkbox, StackChild }: SubStepComponentProps<>) => {
  return (
    <StackChild>
      <div className="mb5">
        <h4 className="kw-text-small kw-weight-normal grey-400">
          <Text id="SavingAdd.Terms.Document.title" />
        </h4>
        <DocumentList />
      </div>
      <Checkbox name="acceptsTerms" labelId="SavingAdd.Terms.Agree.label" />
    </StackChild>
  );
};
