import * as React from 'react';

import { MemberType } from '@kwara/models/src';
import { ExpanderRow } from '@kwara/components/src';
import { Loadable } from '@kwara/components/src';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { Updater } from '../../../../components/EditableSection/v1/EditableSection';

import {
  PersonalDetails,
  Identity,
  Documents,
  NextOfKin,
  Employment
} from '../../../../components/MemberInfo/components';

export const Header = (props: { children: React.ReactNode }) => <header className="flex">{props.children}</header>;

export default ({ member, onChange, isV1 }: { member: MemberType; onChange: any; isV1?: boolean }) => {
  const { isLoading, data: memberRes, error } = useMember(member.id);
  const onUpdate = editedMember => onChange({ member: editedMember });
  React.useEffect(() => {
    onUpdate(memberRes);
    // eslint-disable-next-line
  }, [memberRes]);
  return (
    <Loadable isLoading={isLoading} error={error}>
      <Updater value={{ onUpdate }}>
        <Header>
          <PersonalDetails.Title />
        </Header>
        <ExpanderRow isExpanded>
          <PersonalDetails member={member} isV1={isV1} />
        </ExpanderRow>

        <Header>
          <Identity.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Identity member={member} />
        </ExpanderRow>

        <Header>
          <NextOfKin.Title />
        </Header>
        <ExpanderRow isExpanded>
          <NextOfKin member={member} />
        </ExpanderRow>
        <Header>
          <Employment.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Employment member={member} config={Employment.editConfig.extra} isV1={isV1} />
        </ExpanderRow>

        <Header>
          <Documents.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Documents member={member} readOnly={isV1} />
        </ExpanderRow>
      </Updater>
    </Loadable>
  );
};
