import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src';

import styles from './index.module.scss';

type PrimitiveTablePropTypes = React.ComponentPropsWithoutRef<'table'>;
type ListerElement = React.ElementRef<'table'>;

interface ListerPropTypes extends PrimitiveTablePropTypes {
  titleId?: string;
}

/***********************************************************
 * @Lister this is a component used to display list of items
 * that do not contain the table utils like ```filtering,
 * sorting, and etc```
 * @Note if you want to perform table functions, go for the
 * <Table/> component and not these ```<Lister/>
 * @sampleUsage sample usage can be found in the
 * ```ìndex.stories.js```of this component folder
 */
export const Lister = React.forwardRef<ListerElement, ListerPropTypes>(function Lister(
  { className, titleId, ...restProps },
  forwardedRef
) {
  return (
    <div className={cx(styles['container'], className)}>
      <If
        condition={titleId != undefined}
        do={
          <h2 className={cx(styles['title'], 'kw-text-x-large kw-weight-bold')}>
            <Text id={titleId} />
          </h2>
        }
      />

      <table {...restProps} ref={forwardedRef} className={styles['lister']} />
    </div>
  );
});
