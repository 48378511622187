import { useQuery } from 'react-query';

import { LoanMetric } from '@kwara/models/src';

async function fetchLoanOverviewMetrics() {
  const res = await LoanMetric.all();
  return res.data;
}

export const useLoanOverviewMetrics = () => useQuery(['loan_overview_metrics'], fetchLoanOverviewMetrics);
