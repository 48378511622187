import React from 'react';
import get from 'lodash/fp/get';

import { ERRORS } from '@kwara/lib/src/validator';
import { fields as loanFields } from '@kwara/models/src/models/Loan';
import { SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';
import { DisbursementMethodsFilter } from '@kwara/models/src/models/Transactions/DisbursementMethodsFilter';

import { LoanAddPropTypes, LoanAddData } from '../../..';

const fields = loanFields.disbursement;
const DEFAULT_AMOUNT = 0;

export const Disbursement = ({ data, formProps }: LoanAddPropTypes) => (
  <div className="flex">
    <div className="flex-auto">
      <SubscribedPaymentSelectField
        labelId="LoanAdd.Disbursement.Method.label"
        name={fields.mode}
        config={{
          bank: fields.bank,
          bankBranch: fields.branch,
          accountNumber: fields.account,
          showBanksFieldsFor: [TransactionChannels.bankTransfer],
          methods: DisbursementMethodsFilter.filterDisbursementMethods({
            payoutProvider: get('payoutProvider', data),
            phoneNumberProvider: get('phoneNumberProvider', data),
            transactionAmountLimit: get('transactionAmountLimit', data),
            methods: getTransactionTypes(contexts.LoanDisbursement).values,
            loanAmount: Number(get('values.amount', formProps) || DEFAULT_AMOUNT)
          })
        }}
        required
      />
    </div>
  </div>
);

const requiredIfBank = {
  isRequired: (_field: string, allData: LoanAddData) => get(fields.mode, allData) === TransactionChannels.bankTransfer
};

Disbursement.validate = {
  [fields.mode]: {
    isRequired: () => true,
    custom(mode: string, allData: LoanAddData) {
      if (mode === TransactionChannels.mobileMpesaInstant && Number(allData.amount) > allData.transactionAmountLimit) {
        return ERRORS.rangeOverflow;
      }

      return null;
    }
  },
  [fields.bank]: requiredIfBank,
  [fields.branch]: requiredIfBank,
  [fields.account]: requiredIfBank
};
