import * as React from 'react';
import get from 'lodash/fp/get';
import { UseQueryResult } from 'react-query';

import { Text, Currency } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { MemberType } from '@kwara/models/src';
import { ExpanderRow } from '@kwara/components/src';
import { Link } from '@kwara/components/src/Link';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { Loadable } from '@kwara/components/src/Loadable';
import { LoanType } from '@kwara/models/src/models/Loan';
import { SavingType } from '@kwara/models/src/models/Saving';

import { LoanSubstepProps } from 'GlobalTypes';

import { useSavingsForMember } from '../../../../../hooks';
import { AttributesRow } from '../../../../../components/AttributesRow';
import { LoansPanel } from '../../../../../components/LoansPanel';
import { SavingsPanel } from '../../../../../components/SavingsPanel';
import { LoansHorizontalScroller } from '../../../../../components/LoansHorizontalScroller';
import { SavingsHorizontalScroller } from '../../../../../components/SavingsHorizontalScroller';
import { GuaranteedLoansHorizontalScroller } from '../../../../../components/GuaranteedLoansHorizontalScroller';
import { DefaultedLoansHorizontalScroller } from '../../../../../components/DefaultedLoansHorizontalScroller';
import { Bank, Documents, PersonalDetails, Employment } from '../../../../../components/MemberInfo/components';
import { SubsectionTitle } from '../../../../../components/DetailSubsection';

import styles from './index.module.scss';

function getOverviewStatsRowData(memberData: MemberType, savingsR: UseQueryResult<SavingType[]>, loans: LoanType[]) {
  return [
    {
      title: (
        <div>
          <span className="kw-text-small kw-weight-regular mr2">{memberData.id}</span>
          <StatusTag state={memberData.state.current} size="small" />
        </div>
      ),
      value: (
        <Link type="primary" target="_blank" underline={false} to={`/members/${memberData.id}`}>
          {memberData.fullName()}
        </Link>
      )
    },
    {
      title: (
        <>
          <Text id="LoanAppraisal.MemberProfile.totalLoans" />
          {` `}
          <InfoPanel className={styles['loans-panel']}>
            <LoansPanel loans={loans} />
          </InfoPanel>
        </>
      ),
      value: <Currency value={get('totalLoansBalance', memberData)} format="currency" />
    },
    {
      title: (
        <>
          <Text id="LoanAppraisal.MemberProfile.totalDeposits" />
          {` `}
          <InfoPanel className={styles['loans-panel']}>
            <Loadable {...savingsR}>{savings => <SavingsPanel savings={savings} />}</Loadable>
          </InfoPanel>
        </>
      ),

      value: <Currency value={get('totalSavings', memberData)} format="currency" />
    }
  ];
}
function MemberProfile({ data }: LoanSubstepProps) {
  const memberData: MemberType = get('member', data);
  const loansData: LoanType[] = get('loans', data);
  const memberId = get('id', memberData);

  const savingsR = useSavingsForMember(memberId, { state: 'ACTIVE,LOCKED,DORMANT,MATURED,APPROVED' });

  return (
    <>
      <AttributesRow attributes={getOverviewStatsRowData(memberData, savingsR, loansData)} useBackground={false} />
      <LoansHorizontalScroller memberId={memberId} />
      <SavingsHorizontalScroller savingsR={savingsR} />
      <GuaranteedLoansHorizontalScroller memberId={memberId} />
      <DefaultedLoansHorizontalScroller memberId={memberId} />
      {/* Read only Member Info Section */}
      <SubsectionTitle>
        <PersonalDetails.Title />
      </SubsectionTitle>
      <ExpanderRow ariaLabel="PersonalDetails">
        <PersonalDetails member={memberData} readOnly />
      </ExpanderRow>
      <SubsectionTitle>
        <Bank.Title />
      </SubsectionTitle>
      <ExpanderRow ariaLabel="Bank">
        <Bank member={memberData} readOnly />
      </ExpanderRow>
      <SubsectionTitle>
        <Documents.Title />
      </SubsectionTitle>
      <ExpanderRow ariaLabel="Documents">
        <Documents member={memberData} readOnly />
      </ExpanderRow>
      <SubsectionTitle>
        <Employment.Title />
      </SubsectionTitle>
      <ExpanderRow ariaLabel="EmploymentDetails">
        <Employment member={memberData} readOnly config={Employment.editConfig.extra} />
      </ExpanderRow>
    </>
  );
}

export default {
  Component: MemberProfile
};
