import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { Currency } from '@kwara/components/src/Intl';

import styles from './index.module.scss';
import { getTwoThirdsRuleValues } from '../../utils/getTwoThirdsRuleValues';
import { type TwoThirdsRuleDataT } from '.';


export function Breakdown({
  netIncome,
  otherDeductibles,
  otherIncomeAmount,
  repaymentAmount,
  totalMonthlyLoanPayments,
}: TwoThirdsRuleDataT) {

  const { totalAvailableForRepayment, twoThirdsAvailable, takeHomeIncome } = getTwoThirdsRuleValues({
    netIncome,
    otherDeductibles,
    otherIncomeAmount,
    repaymentAmount,
    totalMonthlyLoanPayments
  });

  return (
    <div className={cx('pa3', styles.Breakdown)}>
      <table className="w-100 kw-text-extra-small">
        <tbody>
          <tr className="b">
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.availableForRepayment" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={totalAvailableForRepayment} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.twoThirdsAvailableForRepayment" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={twoThirdsAvailable} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.loanPayments" />
            </td>
            <td className="tr bb b--black red-600">
              <Currency hideDecimals={false} value={totalMonthlyLoanPayments} />
            </td>
          </tr>
          <tr>
            <td>
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.takeHome" />
            </td>
            <td className="tr bb b--black">
              <Currency hideDecimals={false} value={takeHomeIncome} />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr className="b">
            <td className="red-600">
              <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.suggestedRepaymentAmount" />
            </td>
            <td className="tr">
              <Currency hideDecimals={false} value={repaymentAmount} />
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="mt2 kw-text-extra-small tc i">
        <Text id="LoanAdd.Configure.TwoThirdsModal.Breakdown.helperText" />
      </div>
    </div>
  );
}
