import React, { useCallback, useMemo } from 'react';

import { createContext } from '@kwara/lib/src/utils/createContext';

import { useAuth } from '../../../../hooks';
import { Status } from './Reducer';
import { STATUS } from './Reducer';
import { usePasswordExpiryAlerter } from './usePasswordExpiryAlerter';

type UpdateArgType = {
  status: Status;
  token: string;
  daysCount?: string;
  onContinueWithoutPasswordChange?: () => void;
};
type PasswordExpiryAlerterImpProps = {
  children: React.ReactElement;
};
export type PasswordExpiryAlerterContextType = {
  shown: boolean;
  status: Status | null;
  daysCount?: string;
  onChangePassword: () => void;
  onContinueWithoutPasswordChange?: () => void;
  UPDATE: (arg: UpdateArgType) => void;
  RESET: () => void;
};

const [PasswordExpiryAlerterProvider, usePasswordExpiryAlerterContext] = createContext<
  PasswordExpiryAlerterContextType
>('PasswordExpiryAlerterContext');

const RESET_PASSWORD_ROUTE = 'password/reset?t=';

function PasswordExpiryAlerterImp(props: PasswordExpiryAlerterImpProps) {
  const auth = useAuth();
  const { state, actions } = usePasswordExpiryAlerter();

  const UPDATE = useCallback(
    ({ status, token, daysCount, onContinueWithoutPasswordChange }: UpdateArgType) => {
      if (status === STATUS.isExpired) {
        actions.dispatchOnPasswordExpired(token);
      } else {
        actions.dispatchOnPasswordAboutToExpire(token, daysCount, onContinueWithoutPasswordChange);
      }
    },
    [actions]
  );

  const RESET = useCallback(() => {
    actions.dispatchOnReset();
  }, [actions]);

  const onChangePassword = useCallback(() => {
    const redirectTo = RESET_PASSWORD_ROUTE.concat(`${state.token}&order=seq`);

    if (auth.isLoggedIn()) {
      auth.logOut();
    }
    window.location.assign(redirectTo);
    RESET();
  }, [RESET, auth, state.token]);

  const passwordExpiryAlerterValues = useMemo(
    () => ({
      ...state,
      onChangePassword,
      UPDATE
    }),
    [state, onChangePassword, UPDATE]
  );

  return (
    <PasswordExpiryAlerterProvider value={passwordExpiryAlerterValues}>{props.children}</PasswordExpiryAlerterProvider>
  );
}

export { usePasswordExpiryAlerterContext, PasswordExpiryAlerterImp, PasswordExpiryAlerterProvider };
