import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import Button from '@kwara/components/src/Button';
import { Text, Currency } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Modal from '@kwara/components/src/Modal';
import { Panel } from '@kwara/components/src/Panel';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { fetchMember } from '@kwara/models/src/models/request/hooks';
import { segmentTrack } from '@kwara/components/src/Segment';

import styles from './index.module.scss';
import { Breakdown } from './Breakdown';
import { getTwoThirdsRuleValues } from '../../utils/getTwoThirdsRuleValues';

const fields = ['total_monthly_loan_payments'];
export function useMemberIncome(id: string) {
  return useQuery(['member_income', id, null, fields], fetchMember);
}

export function TwoThirdsModal({
  netIncome,
  otherIncomeAmount,
  otherDeductibles,
  totalMonthlyLoanPayments,
  repaymentAmount,
  onBypass,
  member,
  btnTextId,
  to
}) {
  const args = {
    totalMonthlyLoanPayments,
    netIncome,
    otherIncomeAmount,
    otherDeductibles,
    repaymentAmount
  };

  const history = useHistory();

  const { difference } = getTwoThirdsRuleValues(args);

  const onRuleBypass = () => {
    segmentTrack('Two-thirds Rule Bypassed');
    onBypass();
  };

  return (
    <Modal ariaLabel="Two-thirds Modal" isOpen>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel className={styles.Panel}>
          <div className="flex justify-between">
            <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
              <Text id="LoanAdd.Configure.TwoThirdsModal.Confirm.title" />
            </h2>
            <InfoPanel>
              <Breakdown {...args} />
            </InfoPanel>
          </div>
          <p className="kw-weight-light">
            <Text
              id="LoanAdd.Configure.TwoThirdsModal.Confirm.subtitle"
              values={{
                firstName: member.firstName,
                val: <Currency format="currency" value={difference} />
              }}
            />
          </p>
          <ButtonBar
            className="mt4"
            left={
              <Button onClick={() => history.push(to)}>
                <Text id={btnTextId ?? 'LoanAdd.Configure.TwoThirdsModal.Confirm.button.moreIncome'} />
              </Button>
            }
            right={
              <Button classNames="mr3" type="destructive" onClick={onRuleBypass}>
                <Text id="LoanAdd.Configure.TwoThirdsModal.Confirm.button.bypass" />
              </Button>
            }
          />
        </Panel>
      </div>
    </Modal>
  );
}
