import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import invoke from 'lodash/fp/invoke';
import isEmpty from 'lodash/fp/isEmpty';

import { LoanDisbursementType, DisbursementStates, LoanType } from '@kwara/models/src/models/LoanDisbursement';

export class GenerateLoanDisbursementData {
  private static STATE_TRANSLATION_ID = {
    approved: 'LoanDisbursement.approvedBy',
    imported: 'LoanDisbursement.approvedBy',
    rejected: 'LoanDisbursement.rejectedBy'
  };

  constructor(private loanDisbursement: LoanDisbursementType) {}

  public get stateInfo() {
    const state = this.loanDisbursement.state;

    return {
      key: GenerateLoanDisbursementData.STATE_TRANSLATION_ID[state],
      value: state,
      shouldRender: Object.keys(GenerateLoanDisbursementData.STATE_TRANSLATION_ID).includes(state)
    };
  }

  public get loan(): LoanType {
    return this.loanDisbursement.loan;
  }

  public get loanApplication() {
    return this.loanDisbursement.loanApplication;
  }

  public get initiatedAmount() {
    return this.loanApplication?.requestedAmount;
  }
  public get initiatedBy() {
    return this.loanApplication?.createdBy ?? '-';
  }

  public get initiatedAt() {
    return this.loanApplication?.createdAt;
  }

  public get approvedAmount() {
    return this.loanApplication?.recommendedAmount;
  }

  public get approvedBy() {
    return this.loanApplication?.approvedBy ?? '-';
  }

  public get approvedAt() {
    return this.loanApplication?.approvedAt ?? this.loanApplication?.createdAt;
  }

  public get requestedAmount() {
    return this.loanDisbursement.amount;
  }

  public get requestedAt() {
    return this.loanDisbursement.createdAt;
  }

  public get percentage() {
    const dividend = this.loan?.interestRate?.percentage ?? this.loan?.interestSettings?.interestRate ?? 0;
    const divisor = 100;
    const result = dividend / divisor;

    return result;
  }

  public get branchName() {
    return get('loanDisbursement.user.branch.name', this);
  }

  public hideId(canReviewLoan: boolean) {
    return !canReviewLoan || this.loanDisbursement.state !== DisbursementStates.PENDING_MAKER_CHECKER_APPROVAL;
  }

  public get requestedBy() {
    const loanDisbursement = this.loanDisbursement;
    const requesters = pipe(
      get('appraisals'),
      map(appraisal => invoke('createdBy', appraisal)),
      map(requester => (
        <p key={requester} className="mb1 mt1">
          {requester}
        </p>
      ))
    )(loanDisbursement);

    if (isEmpty(requesters)) {
      /**
       * `this.loanDisbursement.createdBy` is the legacy
       * `createdBy` string value that is captured
       * This will be shown for old records, before we started to capture
       * multiple disbursement appraisal records per disbursal.
       **/
      return this.loanDisbursement.createdBy;
    }

    return requesters;
  }
}
