import * as React from 'react';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { TranslationId, TranslationValues } from '@kwara/components/src/Intl';

import Visible from '../Visible';
import Filter from '../Filter';

import { Label } from './components/Label';
import { ActionableHeading } from './components/ActionableHeading';
import { ListActionsDashboard } from './components/ListActionsDashboard';

type Props = {
  backButton?: React.ReactElement<typeof Button>;
  labelId: TranslationId;
  actions?: (React.ReactElement<typeof Button> | React.ReactElement<typeof Visible>)[] | React.ReactElement;
  exportButton?: React.ReactElement;
  headerId?: TranslationId;
  headerValues?: Record<string, TranslationValues>;
  heading?: React.ReactNode;
  filterDisabled?: boolean;
  table: React.ReactElement<any>;
  totalNumResults: number;
  items: Array<any>;
  otherActions?: React.ReactElement<any>[];
  withFilter?: boolean;
};

function OverviewListV2({
  backButton,
  actions,
  exportButton,
  headerId,
  headerValues,
  heading,
  labelId,
  table,
  totalNumResults,
  items,
  withFilter,
  filterDisabled,
  otherActions = []
}: Props) {
  return (
    <div>
      <ActionableHeading
        heading={heading}
        headerId={headerId}
        headerValues={headerValues}
        actions={actions}
        backButton={backButton}
      />
      <div className="ml3 mb3">
        <If
          condition={!!labelId}
          do={<Label translationId={labelId} visible={items.length} total={totalNumResults} />}
        />
        <ListActionsDashboard
          withFilter={withFilter}
          filterDisabled={filterDisabled}
          actions={otherActions}
          exportButton={exportButton}
        />
      </div>

      <div>{table}</div>
    </div>
  );
}

OverviewListV2.Root = Filter.Root;

export { ActionableHeading };
export default OverviewListV2;
