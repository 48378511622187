import * as React from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import { useMemberEligibility } from '../../../../../hooks/useRequest';
import { pickMemberEligibilityPerLoanProduct } from '../../../utils/pickMemberEligibilityPerLoanProduct';
import { currency } from './LoanDetails';

function getAttributeRows(
  selfGuaranteeAmount: number,
  eligibleAmount: number,
  multiplier: number,
  isFetchingMemberEligibility: boolean
) {
  const attributesRow = [
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.eligibility.attributesRow.multiplyingFactor" />,
      value: multiplier
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.eligibility.attributesRow.selfGuaranteeAmount" />,
      value: currency(selfGuaranteeAmount)
    },
    {
      title: <Text id="LoanDisbursal.LoanDetails.NavigationTabs.eligibility.attributesRow.eligibleAmount" />,
      value: <If condition={isFetchingMemberEligibility} do={<LoadingSkeleton />} else={currency(eligibleAmount)} />
    }
  ];

  return { attributesRow };
}
export function useEligibilityTabData(loan) {
  const { member } = loan;

  const { data: memberEligibilityData, isFetching: isFetchingMemberEligibility } = useMemberEligibility({
    memberId: get('id', member)
  });

  const { eligibleAmount, multiplier } = pickMemberEligibilityPerLoanProduct(loan, memberEligibilityData);
  const guarantor = find(e => e.memberId === member.id, loan.guarantors);
  const selfGuaranteeAmount = get('amount', guarantor) ?? 0;

  const { attributesRow: eligibilityAttributesRow } = React.useMemo(
    () => getAttributeRows(selfGuaranteeAmount, eligibleAmount, multiplier, isFetchingMemberEligibility),
    [selfGuaranteeAmount, eligibleAmount, multiplier, isFetchingMemberEligibility]
  );

  return { eligibilityAttributesRow };
}
