import React from 'react';
import { Form } from 'react-final-form';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import cx from 'classnames';

import { ReportTemplateT } from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';
import { isAfter, differenceInDays, addDays, getCurrentDate, min, formatIsoDate } from '@kwara/lib/src/dates';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import { ButtonMenu } from '../../../../components/ButtonMenu';

import styles from './GenerateReportForm.module.scss';

const MAX_DATE_RANGE = 366;

interface Payload {
  startDate: string;
  endDate: string;
  reportTemplateId: string;
}

export function GenerateReportForm({
  onSubmit,
  templates
}: {
  templates: ReportTemplateT[];
  onSubmit(data: Payload): void;
}) {
  const disabled = size(templates) === 0;

  return (
    <Form
      validate={createValidator({
        startDate: {
          isRequired: () => true,
          isNotFuture: true
        },
        endDate: {
          isRequired: () => true,
          isNotFuture: true,
          custom: (endDate: string, { startDate }: { startDate?: string }) => {
            if (isAfter(startDate, endDate)) {
              return 'START_DATE_AFTER_END_DATE';
            }

            if (differenceInDays(startDate, endDate) > MAX_DATE_RANGE) {
              return 'MAXIMUM_RANGE_EXCEEDED';
            }

            return null;
          }
        }
      })}
      onSubmit={({ startDate, endDate, reportTemplateId }: Payload, form) => {
        onSubmit({ reportTemplateId, startDate: formatIsoDate(startDate), endDate: formatIsoDate(endDate) });
        form.reset();
      }}
      render={({ hasValidationErrors, handleSubmit, hasSubmitErrors, submitting, values, form }) => {
        const { startDate } = values;
        const daysInFuture = addDays(startDate, MAX_DATE_RANGE);
        const maxEndDate = min(getCurrentDate(), daysInFuture);

        return (
          <form
            className="w-100 flex justify-left items-center"
            aria-label="Account Report Form"
            onSubmit={handleSubmit}
          >
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting || disabled}
                disabledDays={{ after: getCurrentDate() }}
                showInfo={false}
                compact
                margin={false}
                required
                name="startDate"
                leftGlyph="SasraReports.Form.StartDate.leftGlyph"
              />
            </div>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting || disabled}
                disabledDays={{ after: maxEndDate }}
                showInfo={false}
                compact
                margin={false}
                required
                name="endDate"
                leftGlyph="SasraReports.Form.EndDate.leftGlyph"
              />
            </div>

            <div className={styles.submit}>
              <ButtonMenu
                disabled={hasValidationErrors || submitting || disabled}
                type="primary"
                key="buttonmenu"
                title={<Text id="SasraReports.Form.submit.generate" />}
              >
                {map(
                  template => (
                    <ButtonMenu.Item
                      key={template.id}
                      name={template.id}
                      className="flex-auto"
                      onClick={() => {
                        form.change('reportTemplateId', template.id);
                        handleSubmit();
                      }}
                    >
                      {template.name}
                    </ButtonMenu.Item>
                  ),
                  templates
                )}
              </ButtonMenu>
              <If
                condition={hasSubmitErrors}
                do={
                  <span className={cx('red-500', styles.submit__error)}>
                    <Text id="AccountingReports.Form.submit.error" />
                  </span>
                }
              />
            </div>
          </form>
        );
      }}
    />
  );
}
