import * as React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { Text, Currency } from '@kwara/components/src/Intl';
import { LoadingSkeleton } from '@kwara/components/src/Loadable';

import styles from './index.module.scss';

/**
 * @Heading
 */
type HeadingPropTypes = {
  title: string;
};

function Heading(props: HeadingPropTypes) {
  return (
    <h3 className={cx('kw-text-small kw-weight-regular', styles['heading'])}>
      <Text id={`LoanAdd.PayOff.${props.title}`} />
    </h3>
  );
}

/**
 * @Body
 */
type BodyPropTypes = {
  amount: number | string;
  isFetching?: boolean;
};

function Body(props: BodyPropTypes) {
  return (
    <div className="kw-text-x-large kw-weight-bold">
      <If
        condition={props.isFetching}
        do={<LoadingSkeleton />}
        else={<Currency format="currency" value={props.amount} />}
      />
    </div>
  );
}

/**
 * @AmountList
 */
type AmountListPropTypes = {
  children: React.ReactElement[];
};

export function AmountList(props: AmountListPropTypes) {
  return (
    <li className={styles['amountItem']}>
      <section>{props.children}</section>
    </li>
  );
}

export default {
  Root: AmountList,
  Heading,
  Body
};
