import * as React from 'react';

export function IconActive() {
  return (
    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="13.5" fill="#16b364" stroke="#CBD5E1" strokeWidth="3" />
      <circle cx="15" cy="15" r="4" fill="white" />
    </svg>
  );
}
