// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';
import size from 'lodash/fp/size';

import { SubscribedCheckbox } from '@kwara/components/src/Form';
import { type BatchTransactionT } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import Banner from '@kwara/components/src/Banner';

import { Currency } from '../../../components/Currency';
import { Grid } from '../../../components/Grid';
import Table, * as table from '../../../components/Table';
import { usePermissions } from '../../../hooks';

type Props = {
  transactions: BatchTransactionT[],
  hasMore: boolean,
  isLoading: boolean,
  onNext: () => void,
  error: any
};

const ErrorItems = ({ errors }) => {
  return <Banner state="error" text={<ul className="ma0 pa1 list">{map(error => error.details, errors)}</ul>} />;
};

export const BatchTransactionsTable = ({ transactions, hasMore, isLoading, error, onNext }: Props) => {
  const { permission, AppPermissions } = usePermissions();

  const userCanReview = permission.to(AppPermissions.ReviewBatchTransactionImports);

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading width="50px" />
          <table.Heading width="75px" translationId="BatchTransactionsList.table.row" />
          <table.Heading translationId="BatchTransactionsList.table.id" />
          <table.Heading translationId="BatchTransactionsList.table.name" />
          <table.Heading translationId="BatchTransactionsList.table.memberId" />
          <table.Heading translationId="BatchTransactionsList.table.accountId" />
          <table.Heading translationId="BatchTransactionsList.table.type" />
          <table.Heading translationId="BatchTransactionsList.table.amount" />
          <table.Heading translationId="BatchTransactionsList.table.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          onNext={onNext}
          hasMore={hasMore}
          isLoading={isLoading}
          errors={error.messages}
          colSpan={10}
          items={transactions}
        />
      }
    >
      {map(t => {
        const event = get('importReviewSet.event', t);
        const notes = get('importReviewSet.notes', t);
        const errors = get('importResultsErrors', t);

        const isRejection = event === 'reject';

        // Don't show checkboxes if user does not have permission
        // to review, or if the transaction is not pending
        const isSelectable = userCanReview && t.isPending();

        return (
          <table.Collapsible key={t.id}>
            {/* $FlowFixMe: @TODO: Collapsible props should be fixed */}
            <table.Row>
              <table.Cell>{isSelectable ? <SubscribedCheckbox name={`transactions.${t.id}`} /> : null}</table.Cell>
              <table.Cell>{t.rowIndex}</table.Cell>
              <table.Cell>{t.id}</table.Cell>
              <table.Cell>{t.name}</table.Cell>
              <table.Cell>{t.memberId}</table.Cell>
              <table.Cell>{t.accountId}</table.Cell>
              <table.Cell>{t.type}</table.Cell>
              <table.Cell>
                <Currency value={invoke('formattedAmount', t)} />
              </table.Cell>
              <table.Cell>
                <StatusTag state={t.status()} />
              </table.Cell>
            </table.Row>
            {/* $FlowFixMe: @TODO: Collapsible props should be fixed */}
            <table.Row className="bg-light-grey-300">
              <table.Cell />
              <table.Cell />
              <table.Cell colSpan={8}>
                <Grid border={false} columns={3} width="w-33">
                  <Statistic
                    title={<Text id={`BatchTransactionsList.table.event.${isRejection ? 'reject' : 'approve'}`} />}
                    value={invoke('importReviewSet.user.fullName', t)}
                  />

                  {notes ? <Statistic title={<Text id="BatchTransactionsList.table.notes" />} value={notes} /> : null}
                  {size(errors) > 0 ? <ErrorItems errors={errors} /> : null}
                </Grid>
              </table.Cell>
            </table.Row>
          </table.Collapsible>
        );
      }, transactions)}
    </Table>
  );
};
