import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import assignAll from 'lodash/fp/assignAll';


import { Loadable } from '@kwara/components/src/Loadable';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { sumBy, equals } from '@kwara/lib/src/currency';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { useGlAccounts } from '../../../hooks';
import Table, { Heading, Row, Cell } from '../../../components/Table';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';
import { GeneralLedgerCombobox, SubscribedDatePicker, DatePicker, Field as BasicField } from '../../../components/Form';

export const useListenForChanges = callback => {
  const [changesDetected, setChangesDetected] = React.useState(false);

  if (changesDetected) {
    callback && callback();
    setChangesDetected(false);
  }

  const triggerReset = () => setChangesDetected(true);
  return { triggerReset };
};

const heading = (
  <Row>
    <Heading translationId="JournalEntryAdd.Form.Header.amount" />
    <Heading translationId="JournalEntryAdd.Form.Header.account" />
    <Heading width="90px" />
  </Row>
);

type Props = SubStepComponentProps<{}> & { resetValidation: Function };

export const JournalEntryForm = ({ StackChild, TextField, TextArea, resetValidation }: Props) => {
  const { triggerReset } = useListenForChanges(resetValidation);
  const r = useGlAccounts();

  return (
    <StackChild size="wide">
      <Panel>
        <FieldArray name="debits">
          {({ fields }) => (
            <div key={fields.name} className="mt3">
              <span className="kw-text-medium kw-weight-bold">
                <Text id="JournalEntryAdd.Form.debits" />
              </span>
              <Table heading={heading} ariaLabel="Debits">
                {fields.map((name, index) => (
                  <Row key={name}>
                    <Cell>
                      <TextField name={`${name}.debit_amount`} compact margin={false} isCurrency type="number" />
                    </Cell>
                    <Cell>
                      <Loadable {...r}>
                        {accounts => <GeneralLedgerCombobox name={`${name}.debit_account`} accounts={accounts} />}
                      </Loadable>
                    </Cell>
                    <Cell>
                      {fields.length > 1 && (
                        <Link
                          type="destructive"
                          onClick={() => {
                            fields.remove(index);
                            triggerReset();
                          }}
                        >
                          <Text id="JournalEntryAdd.Form.remove" />
                        </Link>
                      )}
                    </Cell>
                  </Row>
                ))}
              </Table>
              <Link
                aria-label="Add Debit"
                type="primary"
                onClick={() => {
                  fields.push({ debit_amount: 0 });
                  triggerReset();
                }}
              >
                <Text id="JournalEntryAdd.Form.add" />
              </Link>
            </div>
          )}
        </FieldArray>

        <FieldArray name="credits">
          {({ fields }) => {
            return (
              <div key={fields.name} className="mt3">
                <span className="kw-text-medium kw-weight-bold">
                  <Text id="JournalEntryAdd.Form.credits" values={{ credits: fields.length }} />
                </span>
                <Table heading={heading} ariaLabel="Credits">
                  {fields.map((name, index) => (
                    <Row key={name}>
                      <Cell>
                        <TextField
                          name={`${name}.credit_amount`}
                          compact
                          margin={false}
                          errorBaseId="JournalEntryAdd.Form.credits"
                          isCurrency
                          type="number"
                        />
                      </Cell>
                      <Cell>
                        <Loadable {...r}>
                          {accounts => <GeneralLedgerCombobox name={`${name}.credit_account`} accounts={accounts} />}
                        </Loadable>
                      </Cell>
                      <Cell>
                        {fields.length > 1 && (
                          <Link
                            type="destructive"
                            onClick={() => {
                              fields.remove(index);
                              triggerReset();
                            }}
                          >
                            <Text id="JournalEntryAdd.Form.remove" />
                          </Link>
                        )}
                      </Cell>
                    </Row>
                  ))}
                </Table>
                <Link
                  aria-label="Add Credit"
                  type="primary"
                  onClick={() => {
                    fields.push({ credit_amount: 0 });
                    triggerReset();
                  }}
                >
                  <Text id="JournalEntryAdd.Form.add" />
                </Link>
              </div>
            );
          }}
        </FieldArray>
      </Panel>

      <Panel>
        <Grid columns={2} width="w-50" border={false}>
          <SubscribedDatePicker
            name="date"
            labelId="JournalEntryAdd.Form.valueDate.label"
            disabledDays={{ after: getCurrentDate() }}
          />
          <BasicField
            required
            disabled
            name="createdAt"
            size="medium"
            labelId="JournalEntryAdd.Form.createdAtDate.label"
          >
            <DatePicker name="createdAt" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>
        <TextArea name="notes" labelId="JournalEntryAdd.Form.Notes.label" />
      </Panel>
    </StackChild>
  );
};

JournalEntryForm.validate = data => {
  return {
    date: {
      isNotFuture: true
    },
    ...assignAll(
      mapIndexed((_, i) => {
        return {
          [`debits[${i}].debit_amount`]: {
            isRequired: () => true,
            currency: true,
            nonZero: true
          },
          [`debits[${i}].debit_account`]: {
            isRequired: () => true
          }
        };
      }, data.debits)
    ) as any,
    ...assignAll(
      mapIndexed((_, i) => {
        return {
          [`credits[${i}].credit_amount`]: {
            isRequired: () => true,
            currency: true,
            nonZero: true,
            custom: (_, allData) => {
              const { credits, debits } = allData;

              const totalCredits = sumBy('credit_amount', credits);
              const totalDebits = sumBy('debit_amount', debits);

              if (!equals(totalCredits, totalDebits)) {
                return 'creditsMustEqualDebits';
              }

              return null;
            }
          },
          [`credits[${i}].credit_account`]: {
            isRequired: () => true
          }
        };
      }, data.credits)
    ) as any
  };
};
