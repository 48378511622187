import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoanProduct } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { WizardPageProps } from '..';
import { steps } from './config';

type Props = WizardPageProps<{ productId: string }>;

export function LoanSchedulePreview(props: Props) {
  const { baseUrl, match, history } = props;
  const response = useLoanProduct(match.params.productId);

  return (
    <Loadable {...response}>
      {product => {
        const { unit, value } = product.toRepaymentFrequencyUI();
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              product,
              repaymentPeriod: value,
              repaymentPeriodUnit: unit || 'MONTHS',

              // For translations
              productName: product.name
            }}
            cancelReturnsTo={settingPath({ action: 'loans' })}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={() => Promise.resolve()}
            steps={steps}
            startId="preview"
            titleId="LoanSchedulePreview.title"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
}
