import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';

import { LoanType, MemberType } from '@kwara/models/src';
import { FeeType } from '@kwara/models/src/models/LoanProduct';
import { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { If } from '@kwara/components/src/If/If';
import { toCurrency } from '@kwara/components/src/Form/SubscribedTextField';

import { Panel } from '../../../components/ActionModal';
import { Field as BasicField, TextField as BasicTextField } from '../../../components/Form';
import { Grid } from '../../../components/Grid';
import { MemberPanel, LoanPanel, NotesPanel } from '../../../components/Payment';

import { getAmountUiState } from '../../../lib/accountFeeUtils/getAmountUiState';

export type PaymentData = {
  amount?: number;
  date?: Date;
};

type WizardData = PaymentData & { loan: LoanType; member: MemberType };

export const PaymentForm = ({
  TextField,
  StackChild,
  SelectField,
  data,
  formProps
}: SubStepComponentProps<WizardData>) => {
  const { loan, member } = data;
  const fees = pipe(
    get('product.fees'),
    filter((fee: FeeType) => fee.trigger === 'MANUAL')
  )(loan);
  const predefinedFeeKey = get('values.predefinedFeeKey', formProps);

  const { showAmountInput, showDummyAmount, dummyAmount } = getAmountUiState({
    loan: loan,
    product: loan.product,
    predefinedFeeKey
  });

  return (
    <StackChild>
      <MemberPanel member={member} />
      <LoanPanel loan={loan} />
      <Panel>
        <SelectField labelId="LoanFee.fee.select.label" name="predefinedFeeKey">
          <SelectField.Option value="" translationId="LoanFee.fee.select.default" />
          {map(
            (fee: FeeType) => (
              <SelectField.Option key={fee.id} value={fee.id}>
                {fee.name}
              </SelectField.Option>
            ),
            fees
          )}
          <SelectField.Option
            disabled={!loan.product.allowArbitraryFees}
            value="OTHER"
            translationId="LoanFee.fee.select.other"
          />
        </SelectField>

        <Grid columns={2} width="w-50" border={false}>
          <If
            condition={showAmountInput}
            do={
              <TextField
                required
                name="amount"
                size="medium"
                leftGlyph="Currency.orgCurrency"
                labelId="PaymentForm.amount"
                isCurrency
                type="number"
              />
            }
            else={
              <If
                condition={showDummyAmount}
                do={
                  <BasicField name="dummyAmount" size="medium" labelId="PaymentForm.amount" isCurrency type="number">
                    <BasicTextField
                      name="dummyAmount"
                      value={toCurrency(Number(dummyAmount))}
                      leftGlyph="Currency.orgCurrency"
                      size="medium"
                      readOnly
                      disabled
                    />
                  </BasicField>
                }
              />
            }
          />
        </Grid>
      </Panel>
      <NotesPanel />
    </StackChild>
  );
};

PaymentForm.validate = {
  predefinedFeeKey: {
    isRequired: () => true
  },
  amount: {
    isRequired: (_, { predefinedFeeKey, loan }) =>
      getAmountUiState({ loan, product: loan.product, predefinedFeeKey }).showAmountInput,
    currency: true,
    nonZero: true
  }
};
