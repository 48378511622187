import * as React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import { Text } from '@kwara/components/src/Intl';

import style from '../../index.module.css';
import { useAuth } from '../../../../hooks';

type State = { loading: boolean; success: boolean; error: boolean };

const INITIAL_STATE: State = { loading: false, success: false, error: false };

export function ConfirmEmail() {
  const location = useLocation();
  const auth = useAuth();
  const [{ loading, success, error }, setState] = React.useState(INITIAL_STATE);

  const { token } = queryString.parse(location.search);

  React.useEffect(() => {
    setState({ ...INITIAL_STATE, loading: true });
    auth
      .confirmEmail(token)
      .then(_ => setState({ ...INITIAL_STATE, success: true }))
      .catch(_ => setState({ ...INITIAL_STATE, error: true }));
  }, [auth, token]);

  return (
    <div className={style.Content}>
      <If
        condition={loading}
        do={
          <h2>
            <Text id="SignUp.Confirm.loading" />
          </h2>
        }
      />

      <If
        condition={success}
        do={
          <>
            <h1>
              <Text id="SignUp.Confirm.success.title" />
            </h1>
            <p>
              <Text id="SignUp.Confirm.success.message" />
            </p>
            <Button to="/" primary>
              <Text id="SignUp.Confirm.success.button" />
            </Button>
          </>
        }
      />

      <If condition={error} do={<Banner state="error" text="Something went wrong" />} />
    </div>
  );
}
