import { ERRORS } from '@kwara/lib/src/validator';

import { LoanTopupFormTypes } from '..';

const MIN_TOPUP_AMOUNT = 0;

export function validateLoanAmount(_: string, allData: LoanTopupFormTypes) {
  const { loan, product, desiredInterestBalance, desiredFeesBalance, desiredPenaltiesBalance, topupAmount } = allData;
  const productMaxAmount = product.amount.maximum;

  if (!productMaxAmount) return null;

  const loanAmount =
    Number(loan.principal.balance) +
    Number(desiredInterestBalance) +
    Number(desiredFeesBalance) +
    Number(desiredPenaltiesBalance) +
    Number(topupAmount || MIN_TOPUP_AMOUNT);

  if (loanAmount < productMaxAmount) return null;

  return ERRORS.rangeOverflow;
}
