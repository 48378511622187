import * as React from 'react';

import { ExpanderRow } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';

import { SubsectionTitle } from '../../../../../components/DetailSubsection';

export const CrbMetropolCredentials = () => {
  return (
    <>
      <SubsectionTitle>
        <Text id="Settings.manageCredentials.crbMetropol.titleId" />
      </SubsectionTitle>
      <ExpanderRow ariaLabel={'CrbMetropolCredentials'}></ExpanderRow>
    </>
  );
};
