import React from 'react';

import { SavingType } from '@kwara/models/src';

import List from './components/List';
import { BodyContentWrapper } from '../../layouts';
import { Indicators } from './components/Indicators/Indicators';
import { useDeprecatedFilter, useSavings, useDeprecatedPagination } from '../../hooks';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';

const includes = ['member', 'product'];

export function Savings() {
  const { AppPermissions, permission } = usePermissions();
  const f = useDeprecatedFilter(filter => ({ state: filter }), null);
  const { onNext, allData, hasMore, isLoading, error, totalResults } = useDeprecatedPagination<SavingType>(
    useSavings,
    page => [includes, f.filterValue, page],
    f.filterValue
  );

  return (
    <BodyContentWrapper.Root headId="SavingList.title">
      {permission.to(AppPermissions.ViewSavingsMetrics) ? (
        <BodyContentWrapper.Row.Root>
          <BodyContentWrapper.Row.Indicators>
            <Indicators />
          </BodyContentWrapper.Row.Indicators>
        </BodyContentWrapper.Row.Root>
      ) : null}

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <List
            filterBy={f.filterValue.state}
            items={allData}
            onLoadMoreData={onNext}
            hasMore={hasMore}
            onFilterChange={f.onFilterChange}
            loading={isLoading}
            errors={error.messages}
            totalNumResults={totalResults}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
