// @flow

import * as React from 'react';
import cx from 'classnames';
import invokeArgs from 'lodash/fp/invokeArgs';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';
import join from 'lodash/fp/join';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import pipe from 'lodash/fp/pipe';

import zIndices from '@kwara/lib/src/zIndices';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { TextField } from '.';
import { Field } from './Field';
import { SubscribedCheckboxGroup } from './CheckboxGroup';
import styles from './SubscribedSelectCheckboxes.module.scss';

const useDropdown = onClose => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const body = document.body;
    const outsideClick = (e: MouseEvent) => {
      if (ref.current) {
        const isOutside = !ref.current.contains(e.target);
        if (isOutside) {
          onClose();
        }
      }
    };

    if (body) {
      body.addEventListener('click', outsideClick, true);
    }

    return () => {
      if (body) {
        body.removeEventListener('click', outsideClick, true);
      }
    };
  }, [onClose]);

  return { ref };
};

const DropdownMenu = ({
  name,
  options,
  onClear,
  onClose
}: {
  name: string,
  options: { labelId: string, value: string }[],
  onClear: Function,
  onClose: Function
}) => {
  const { ref } = useDropdown(onClose);

  return (
    <div
      aria-label="DropdownMenu"
      ref={ref}
      style={{ position: 'fixed', bottom: '32px', left: 'auto' }}
      className={cx(styles.menu, 'bg-white', zIndices.OverlayMenus)}
    >
      <div className="pa3">
        <SubscribedCheckboxGroup name={name} options={options} />
      </div>
      <div className={cx(styles.buttonBar, 'pa3')}>
        <Button onClick={onClear}>
          <Text id="SubscribedSelectCheckboxes.clear" />
        </Button>
        <Button onClick={onClose} type="primary">
          <Text id="SubscribedSelectCheckboxes.okay" />
        </Button>
      </div>
    </div>
  );
};

const getSelected = (options, values) => filter(opts => includes(opts.value, values), options);

const getValue = options =>
  pipe(
    map(opt => opt.labelId),
    join(', ')
  )(options);

type Props = {
  disabled?: boolean,
  labelId?: string,
  titleId?: string,
  name: string,
  onChange?: (evt: SyntheticEvent<>) => void,
  size?: 'regular' | 'medium',
  required?: boolean,
  options: { labelId: string, value: string }[],
  currentValues: { [name: string]: any },
  formProps: { change: Function }
};

export const SubscribedSelectCheckboxes = ({
  name,
  options,
  disabled,
  required,
  titleId,
  labelId,
  currentValues,
  size = 'medium',
  formProps
}: Props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggle = () => setIsExpanded(prev => !prev);
  const close = () => setIsExpanded(false);
  const clear = () => invokeArgs('change', [name, []], formProps);

  const arrayOfValues = get(name, currentValues);
  const selectedOptions = getSelected(options, arrayOfValues);
  const displayValue = getValue(selectedOptions);

  return (
    <div className={styles.wrapper}>
      <Field size={size} name={name} required={required} titleId={titleId} labelId={labelId}>
        <TextField
          disabled={disabled}
          readOnly
          appearAsSelect
          size={size}
          name={name}
          onClick={disabled ? noop : toggle}
          value={displayValue}
        />
      </Field>
      {isExpanded ? (
        <DropdownMenu onClear={clear} isExpanded={isExpanded} options={options} onClose={close} name={name} />
      ) : null}
    </div>
  );
};
