import React from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';
import find from 'lodash/fp/find';

import { LoanSubstepProps } from 'GlobalTypes';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { Currency } from '@kwara/components/src/Intl';
import { Text } from '@kwara/components/src/Intl';
import { CollateralFields } from '@kwara/models/src';

import { Notes } from '../../../../components/Notes/Notes';
import { WizardSummary } from '../../../../components/WizardSummary';
import { useGetNewPrincipalBalance } from '../Schedule/useGetNewPrincipalBalance';

function Summary({ parentUrl, formProps: { values } }: LoanSubstepProps) {
  const intl = useIntl();
  const newPrincipalBalance = useGetNewPrincipalBalance(values);

  const {
    loan,
    fullName,
    product,
    topupAmount,
    anticipatedDisbursementDate,
    desiredInterestBalance,
    loanDuration,
    desiredFeesBalance,
    desiredPenaltiesBalance,
    repaymentPeriodUnit,
    guarantors,
    collaterals,
    topLevelClassifications,
    classifications
  } = values;

  const editUrl = (path: string) => `${parentUrl}/${path}`;
  const classification = find(c => c.id === first(classifications), topLevelClassifications);

  return (
    <>
      <WizardSummary.Section
        titleId="LoanTopup.AccountAndMemberDetails.title"
        editUrl={editUrl('accountAndMemberDetails/1')}
      >
        <WizardSummary.Record labelId="LoanTopup.AccountMemberAndDetails.memberName" value={fullName} />
        <WizardSummary.Record labelId="LoanTopup.AccountMemberAndDetails.productName" value={product.name} />
        <WizardSummary.Record
          labelId="LoanTopup.AccountMemberAndDetails.productType"
          value={intl.formatMessage({ id: `LoanProductAdd.Product.type.${product.type}` })}
        />
        <WizardSummary.Record labelId="LoanTopup.AccountMemberAndDetails.accountId" value={loan.id} />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanTopup.Balances.title" editUrl={editUrl('balances/1')}>
        <WizardSummary.Record
          labelId="LoanTopup.Balances.principalBalance"
          value={<Currency format="currency" value={loan.principal.balance} />}
        />
        <WizardSummary.Record
          labelId="LoanTopup.Balances.interest"
          value={<Currency format="currency" value={desiredInterestBalance} />}
        />
        <WizardSummary.Record
          labelId="LoanTopup.Balances.fee"
          value={<Currency format="currency" value={desiredFeesBalance} />}
        />
        <WizardSummary.Record
          labelId="LoanTopup.Balances.penalty"
          value={<Currency format="currency" value={desiredPenaltiesBalance} />}
        />
      </WizardSummary.Section>

      <WizardSummary.Section
        titleId="LoanTopup.TopupAmountAndAccountTerms.title"
        editUrl={editUrl('topupAmountAndAccountTerms/1')}
      >
        <WizardSummary.Record
          labelId="LoanTopup.TopupAmountAndAccountTerms.topupAmount"
          value={<Currency format="currency" value={topupAmount} />}
        />
        <WizardSummary.Record
          labelId="LoanTopup.Balances.newPrincipalBalance"
          value={
            <span className="b">
              <Currency format="currency" value={newPrincipalBalance} />
            </span>
          }
        />
        <WizardSummary.Record
          labelId="LoanAdd.Configure.LoanDuration.title"
          value={
            <>
              {loanDuration}
              {` `}
              <Text id={`LoanAdd.Configure.${repaymentPeriodUnit}`} />
            </>
          }
        />
      </WizardSummary.Section>

      <WizardSummary.Section
        titleId="LoanTopup.DisbursementAndRepayment.title"
        editUrl={editUrl('disbursementAndRepayment/1')}
      >
        <WizardSummary.Record
          labelId="LoanTopup.DisbursementAndRepayment.anticipatedDisbursementDate"
          value={formatIsoDate(new Date(anticipatedDisbursementDate))}
        />
        <WizardSummary.Record
          labelId="LoanTopup.DisbursementAndRepayment.anticipatedFirstRepaymentDate"
          value={formatIsoDate(new Date(loan.firstRepaymentDate))}
        />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.Summary.Security.title" editUrl={editUrl('security/1')}>
        <Text id="LoanTopup.Summary.Guarantors" />
        {mapIndexed(
          ({ member, amount: memberAmount, isMarkedForDestruction }, index) =>
            !isMarkedForDestruction && (
              <WizardSummary.Record
                key={`${member.id}.${index}.heading`}
                labelId="LoanTopup.Summary.SecurityAmount.guarantor"
                values={{ name: member.fullName(), id: member.id }}
                value={<Currency format="currency" value={memberAmount} />}
              />
            ),
          guarantors
        )}
        <Text id="LoanTopup.Summary.Collaterals" />
        {mapIndexed(
          (collateral, i) =>
            !collateral.isMarkedForDestruction && (
              <WizardSummary.Record
                key={`${collateral.amount}-${i}`}
                labelId={`LoanAdd.Security.Collateral.Type.${collateral[CollateralFields.type]}`}
                value={<Currency format="currency" value={collateral.amount} />}
              />
            ),
          collaterals
        )}
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanTopup.Summary.Purpose.title" editUrl={editUrl('loanPurpose/1')}>
        <WizardSummary.Record labelId="LoanTopup.Summary.Classification.label" value={get('name', classification)} />
      </WizardSummary.Section>

      <Notes name="notes" className="mt3" formValues={values} />
    </>
  );
}

export default {
  Component: Summary
};
