import { pathWithBase } from '@kwara/models/src/lib/urls';

export const loanPath = pathWithBase('loans');

export const memberPath = pathWithBase('members', true);

export const profilePath = pathWithBase('profile');

export const savingPath = pathWithBase('savings');

export const settingPath = pathWithBase('settings');

export const financePath = pathWithBase('finance');

export const transactionPath = pathWithBase('transactions');

export const tellerPath = pathWithBase('tellers');

export const tillPath = pathWithBase('till');

export const topupReviewPath = pathWithBase('topup');

export const topupRequestPath = pathWithBase('topup/new');

export const tillToVaultRequestPath = pathWithBase('tillToVault/new');

export const batchTransactionImportsPath = pathWithBase('batch_transaction_imports');

export const creditPath = pathWithBase('credit');

export const currentSection = (path: string = window.location.pathname) => {
  if (path === '/') {
    return 'members';
  }

  try {
    return path.split('/')[1];
  } catch (error) {
    return null;
  }
};
