// @flow

import * as React from 'react';
import size from 'lodash/fp/size';

import { removeAt } from '@kwara/lib/src/lodash';
import { type CollateralT } from '@kwara/models/src';
import { useInterval } from '@kwara/models/src/models/request/hooks';

const { useState } = React;

type Shapes = CollateralT;

// Extracts the functionality to deal with adding/removing/editing items within an array
// that is part of a form model.
const VIEW = 'view';
const EDIT = 'edit';
export function useListEditor(products: Shapes, basePath: string) {
  const itemsLen = size(products);
  const [state, setState] = useState(VIEW);
  const [index, setIndex] = useState<number>(itemsLen);
  const editItem = (idx: number) => {
    setIndex(idx);
    setState(EDIT);
  };
  const onRemove = (idx: number) => removeAt(idx, products);
  const addItem = () => editItem(itemsLen);
  const setEdit = () => setState(EDIT);
  const setView = () => setState(VIEW);
  return {
    isEditing: state === EDIT,
    state,
    setEdit,
    setView,
    index,
    setIndex,
    addItem,
    editItem,
    itemsLen,
    onRemove,
    path: `${basePath}[${index}]`
  };
}

const TEN_SECONDS = 10 * 1000;
const TWO_MINUTES = 2 * 60 * 1000;

// See: https://dev.to/darthknoppix/harnessing-the-page-visibility-api-with-react-bi5
const _document:Partial<{
  hidden:boolean,
  msHidden:boolean,
  webkitHidden:boolean
}> = document

export function getBrowserVisibilityProp() {


  if (typeof _document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof _document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof _document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof _document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof _document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof _document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    window.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, []);

  return isVisible;
}

export function useThrottledCallback(
  callback: () => void,
  visibleInterval: number = TEN_SECONDS,
  invisibleInterval: number = TWO_MINUTES
) {
  const isVisible = usePageVisibility();
  const [intervalLength, setIntervalLength] = React.useState(visibleInterval);

  React.useEffect(() => {
    if (isVisible) {
      setIntervalLength(visibleInterval);
    } else {
      setIntervalLength(invisibleInterval);
    }
  }, [isVisible, visibleInterval, invisibleInterval]);

  useInterval(callback, intervalLength);
}
