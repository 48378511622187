import { attr, hasOne } from 'spraypaint';

import { InferredModel } from 'GlobalTypes';

import Base from './Base';
import LoanProduct, { LoanProductType } from './LoanProduct';

export const MemberEligibility = Base.extend({
  static: {
    jsonapiType: 'loan_eligibility',

    id(id: string) {
      return MemberEligibility.extend({
        static: { endpoint: `/members/${id}/loan_eligibility` }
      });
    }
  },
  attrs: {
    eligibleAmount: attr(),
    multiplier: attr(),
    product: hasOne({ type: LoanProduct })
  }
});

export interface MemberEligibilityT extends InferredModel<MemberEligibilityT> {
  eligibleAmount: number;
  multiplier: number;
  product: LoanProductType;
}
