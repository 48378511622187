import every from 'lodash/fp/every';
import some from 'lodash/fp/some';

import { ApiPermissions } from './ApiPermissions';
import { KwaraPermissions } from './KwaraPermissions';
import { KwaraPermissionType, ApiPermissionType, GetPermissionsType } from './types';

export const AppPermissions = {
  ManageUserAccess: [KwaraPermissions.MANAGE_USER_ACCESS],

  // Comments
  AddComments: [],

  // Members
  ViewMembers: [KwaraPermissions.VIEW_MEMBER],
  ShowMember: [
    KwaraPermissions.VIEW_MEMBER,
    KwaraPermissions.VIEW_SAVINGS_ACCOUNTS,
    KwaraPermissions.VIEW_LOAN_ACCOUNTS
  ],
  AddMembers: [KwaraPermissions.CREATE_MEMBER],
  ApproveMembers: [KwaraPermissions.APPROVE_MEMBER],
  RejectMembers: [KwaraPermissions.REJECT_MEMBER],
  EditMembers: [KwaraPermissions.UPDATE_MEMBER],
  ExitMembers: [KwaraPermissions.EXIT_MEMBER],
  ViewMemberMetrics: [KwaraPermissions.VIEW_MEMBER_METRICS],

  // Savings
  ViewSavings: [KwaraPermissions.VIEW_SAVINGS_ACCOUNTS],
  AddSavings: [KwaraPermissions.CREATE_SAVINGS_ACCOUNTS],
  AddDeposits: [KwaraPermissions.CREATE_DEPOSIT],
  AddSavingsFee: [KwaraPermissions.CREATE_SAVINGS_FEE],
  AddWithdrawal: [KwaraPermissions.CREATE_WITHDRAWAL],
  CloseSavings: [KwaraPermissions.CLOSE_SAVINGS_ACCOUNTS],
  ReopenSavings: [KwaraPermissions.REOPEN_SAVINGS_ACCOUNTS],
  EditSavingsRemittance: [KwaraPermissions.UPDATE_SAVINGS_ACCOUNTS],
  AdjustSavingsTransactions: [KwaraPermissions.ADJUST_SAVINGS_TRANSACTIONS],
  ViewSavingsMetrics: [KwaraPermissions.VIEW_SAVINGS_METRICS],

  // Loans
  ViewLoan: [KwaraPermissions.VIEW_LOAN_ACCOUNTS],
  AddLoans: [KwaraPermissions.CREATE_LOAN_ACCOUNTS],
  ApproveLoans: [KwaraPermissions.APPROVE_LOAN_ACCOUNTS],
  RejectLoans: [KwaraPermissions.REJECT_LOAN_ACCOUNTS],
  AppraiseLoans: [KwaraPermissions.APPROVE_LOAN_ACCOUNTS, KwaraPermissions.REJECT_LOAN_ACCOUNTS],
  DisburseLoans: [KwaraPermissions.DISBURSE_LOAN_ACCOUNTS],
  AddPenalties: [KwaraPermissions.CREATE_LOAN_FEE],
  AddRepayments: [KwaraPermissions.CREATE_LOAN_REPAYMENT],
  PayOffLoan: [KwaraPermissions.PAYOFF_LOAN_ACCOUNTS],
  TopupLoan: [KwaraPermissions.TOPUP_LOAN_ACCOUNTS],
  RescheduleLoans: [KwaraPermissions.RESCHEDULE_LOAN_ACCOUNTS, KwaraPermissions.CREATE_LOAN_ACCOUNTS],
  RefinanceLoans: [KwaraPermissions.REFINANCE_LOAN_ACCOUNTS, KwaraPermissions.CREATE_LOAN_ACCOUNTS],
  WriteOffLoans: [KwaraPermissions.WRITEOFF_LOAN_ACCOUNTS],
  EditLoanDetails: [KwaraPermissions.UPDATE_LOAN_ACCOUNTS],
  EditLoanRemittance: [KwaraPermissions.UPDATE_LOAN_ACCOUNTS],
  AdjustLoanTransactions: [KwaraPermissions.ADJUST_LOAN_TRANSACTIONS],
  ViewLoanMetrics: [KwaraPermissions.VIEW_LOAN_METRICS],

  // Submit to CRB
  CreditSubmit: [
    KwaraPermissions.CREATE_LOAN_REPAYMENT,
    KwaraPermissions.CREATE_DEPOSIT,
    KwaraPermissions.CREATE_WITHDRAWAL
  ],

  // Mobile Banking
  ConfigureMobileBanking: [KwaraPermissions.UPDATE_MEMBER],

  // Make transfer
  MakeTransfer: [KwaraPermissions.CREATE_TRANSFER],

  ViewOrgActivityFeed: [KwaraPermissions.VIEW_SACCO_EVENTS],

  // Batch Transaction Imports
  ViewBatchTransactionImports: [KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS],
  CreateBatchTransactionImports: [
    KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS,
    KwaraPermissions.CREATE_BATCH_TRANSACTION_IMPORTS
  ],
  ReviewBatchTransactionImports: [
    KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS,
    KwaraPermissions.REVIEW_BATCH_TRANSACTION_IMPORTS
  ],

  // Invitations
  ViewInvitations: [KwaraPermissions.VIEW_INVITATIONS],
  InviteUser: [KwaraPermissions.VIEW_INVITATIONS, KwaraPermissions.CREATE_INVITATIONS],

  // Journal Entries
  ViewJournalEntries: [KwaraPermissions.VIEW_JOURNAL_ENTRIES],
  CreateJournalEntries: [KwaraPermissions.VIEW_JOURNAL_ENTRIES, KwaraPermissions.CREATE_JOURNAL_ENTRIES],
  ReviewJournalEntries: [KwaraPermissions.VIEW_JOURNAL_ENTRIES, KwaraPermissions.REVIEW_CHECKABLE_JOURNAL_ENTRIES],

  // View Till Transactions
  ViewTillTransactions: [KwaraPermissions.VIEW_TILL_TRANSACTIONS],

  // View Tills
  ViewTills: [KwaraPermissions.VIEW_TILLS],

  // Use Till
  UseTill: [KwaraPermissions.OPEN_TILL, KwaraPermissions.CLOSE_TILL, KwaraPermissions.VIEW_TILL_TRANSACTIONS],

  ManageTillAccess: [KwaraPermissions.MANAGE_TILL_ACCESS],

  // Topup Requests
  CreateTopupRequests: [KwaraPermissions.CREATE_TOPUP_REQUESTS],

  // Utility Transactions
  CreateUtilityTransactions: [KwaraPermissions.CREATE_UTILITY_TRANSACTIONS],

  // Topup Review
  ReviewTopupRequest: [
    KwaraPermissions.VIEW_ALL_TOPUP_REQUESTS,
    KwaraPermissions.APPROVE_TOPUP_REQUESTS,
    KwaraPermissions.REJECT_TOPUP_REQUESTS
  ],

  // Teller Supervisor
  UseTellerSupervisorPage: [
    KwaraPermissions.VIEW_ALL_TILLS,
    KwaraPermissions.VIEW_ALL_TOPUP_REQUESTS,
    KwaraPermissions.APPROVE_TOPUP_REQUESTS,
    KwaraPermissions.REJECT_TOPUP_REQUESTS,
    KwaraPermissions.VIEW_TILL_TRANSACTIONS,
    KwaraPermissions.VIEW_SUPERVISOR_METRICS
  ],

  ViewSavingTransactionsPage: [KwaraPermissions.VIEW_SAVINGS_TRANSACTIONS],

  //Loan Disbursement View in Finance page
  ViewPendingLoanDisbursementsPage: [KwaraPermissions.VIEW_LOAN_DISBURSEMENTS],
  ReviewLoanDisbursement: [
    KwaraPermissions.VIEW_LOAN_DISBURSEMENTS,
    KwaraPermissions.APPROVE_LOAN_DISBURSEMENTS,
    KwaraPermissions.REJECT_LOAN_DISBURSEMENTS
  ],

  ReviewSavingTransactions: [
    KwaraPermissions.VIEW_SAVINGS_TRANSACTIONS,
    KwaraPermissions.APPROVE_SAVINGS_TRANSACTIONS,
    KwaraPermissions.REJECT_SAVINGS_TRANSACTIONS
  ],

  // Maker checker on Pending Transactions
  ViewLoanTransactionsPage: [KwaraPermissions.VIEW_LOAN_TRANSACTIONS],

  ReviewLoanTransactions: [
    KwaraPermissions.VIEW_LOAN_TRANSACTIONS,
    KwaraPermissions.APPROVE_LOAN_TRANSACTIONS,
    KwaraPermissions.REJECT_LOAN_TRANSACTIONS
  ],

  ViewReconciliationStatements: [KwaraPermissions.VIEW_RECONCILIATION_STATEMENTS],

  CreateReconciliationStatements: [
    KwaraPermissions.CREATE_RECONCILIATION_STATEMENTS,
    KwaraPermissions.VIEW_RECONCILIATION_STATEMENTS
  ],

  //Cheque Reports
  ViewChequeTransactions: [KwaraPermissions.VIEW_CHEQUE_TRANSACTIONS],

  //Mpesa Credentials
  ManageMpesaCredentials: [KwaraPermissions.VIEW_MPESA_CREDENTIALS, KwaraPermissions.MANAGE_MPESA_CREDENTIALS],
  ViewMpesaCredentials: [KwaraPermissions.VIEW_MPESA_CREDENTIALS],

  ViewMpesaTransactions: [KwaraPermissions.VIEW_MPESA_TRANSACTIONS],

  SafaricomVerify: [KwaraPermissions.SAFARICOM_IMSI_VERIFY_PHONE],

  ViewDirectDebitReports: [KwaraPermissions.VIEW_DIRECT_DEBIT_REPORTS],
  GenerateDirectDebitReports: [
    KwaraPermissions.VIEW_DIRECT_DEBIT_REPORTS,
    KwaraPermissions.GENERATE_DIRECT_DEBIT_REPORTS
  ],

  Finance: [
    KwaraPermissions.VIEW_JOURNAL_ENTRIES,
    KwaraPermissions.CREATE_JOURNAL_ENTRIES,
    KwaraPermissions.VIEW_SAVINGS_TRANSACTIONS,
    KwaraPermissions.APPROVE_SAVINGS_TRANSACTIONS,
    KwaraPermissions.REJECT_SAVINGS_TRANSACTIONS,
    KwaraPermissions.VIEW_LOAN_TRANSACTIONS,
    KwaraPermissions.APPROVE_LOAN_TRANSACTIONS,
    KwaraPermissions.REJECT_LOAN_TRANSACTIONS,
    KwaraPermissions.VIEW_DIRECT_DEBIT_REPORTS,
    KwaraPermissions.VIEW_LOAN_ACCOUNTS,
    KwaraPermissions.VIEW_CHEQUE_TRANSACTIONS,
    KwaraPermissions.VIEW_MPESA_TRANSACTIONS
  ],
  UpdateOrganisationSettings: [KwaraPermissions.UPDATE_ORGANISATION_SETTINGS],

  ViewAccountingReports: [KwaraPermissions.VIEW_ACCOUNTING_REPORTS],

  ManageSasraReports: [KwaraPermissions.MANAGE_SASRA_REPORTS],
  ConfigureSasraReports: [KwaraPermissions.MANAGE_SASRA_REPORT_CONFIG],

  ViewGuarantorListingReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateGuarantorListingReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewLoanAgeingReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateLoanAgeingReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewLoanListingReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateLoanListingReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewDepositListingReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateDepositListingReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewCheckOffAdviceReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateCheckOffAdviceReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewMemberListingReports: [KwaraPermissions.VIEW_OPERATIONAL_REPORTS],
  CreateMemberListingReports: [KwaraPermissions.CREATE_OPERATIONAL_REPORTS],

  ViewDashboardIndicators: [KwaraPermissions.VIEW_DASHBOARD_INDICATORS],
  ViewSavingsTransactionsStats: [KwaraPermissions.VIEW_DASHBOARD_SAVINGS_TRANSACTIONS_STATS],
  ViewMemberBaseStats: [KwaraPermissions.VIEW_DASHBOARD_MEMBER_BASE_STATS],
  ViewLoanDisbursementsStats: [KwaraPermissions.VIEW_DASHBOARD_LOAN_DISBURSEMENTS_STATS],
  ViewMobileBankingUsersStats: [KwaraPermissions.VIEW_DASHBOARD_MOBILE_BANKING_USERS_STATS],

  UseDividendReports: [KwaraPermissions.VIEW_DIVIDEND_REPORTS]
};

export class Permission {
  static isValid(permission: KwaraPermissionType | ApiPermissionType) {
    return Object.keys({ ...ApiPermissions, ...KwaraPermissions }).includes(permission as string);
  }

  private _getPermissions: GetPermissionsType;

  constructor(getPermissions: GetPermissionsType) {
    this._getPermissions = getPermissions;
  }

  ensureValidPermissions = (permissions: (ApiPermissionType | KwaraPermissionType)[]) => {
    permissions.forEach(p => {
      if (!Permission.isValid(p)) {
        throw new Error(`Invalid permission "${String(p)}"`);
      }
    });
  };

  to = (param: ApiPermissionType | KwaraPermissionType | (ApiPermissionType | KwaraPermissionType)[]) => {
    const permissions = Array.isArray(param) ? param : [param];
    this.ensureValidPermissions(permissions);

    const userPermissions = this._getPermissions();

    if (!Array.isArray(userPermissions)) {
      throw new Error("Couldn't fetch permissions");
    }

    return every(p => userPermissions.includes(p), permissions);
  };

  some = (permissions: string[]) => {
    this.ensureValidPermissions(permissions);

    const userPermissions = this._getPermissions();

    if (!Array.isArray(userPermissions)) {
      throw new Error("Couldn't fetch permissions");
    }

    return some(p => userPermissions.includes(p), permissions);
  };

  checkIsCTO = () => {
    const userPermissions = this._getPermissions();

    if (!Array.isArray(userPermissions) || userPermissions.length !== 1) {
      return false;
    }

    const itsCTO = userPermissions[0] === AppPermissions.ViewDashboardIndicators[0];

    return itsCTO;
  };
}
