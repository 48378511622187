import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import { Segment, DetectUserActivity } from '@kwara/components/src';

import { useAuth } from '../hooks';
import { Body } from './components/Body/Body';
import { Head } from './components/Head/Head';
import { InternetNotifier } from '../layouts';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { ProtectedRoutes } from './components/ProtectedRoutes/ProtectedRoutes';
import { AppUpdateChecker } from './components/AppUpdateChecker/AppUpdateChecker';
import { UnprotectedRoutes } from './components/UnprotectedRoutes/UnprotectedRoutes';
import { EnvironmentBanner } from './components/EnvironmentBanner/EnvironmentBanner';
import { NotificationBanner } from './components/NotificationBanner/NotificationBanner';

export function Router() {
  const auth = useAuth();
  const { pathname, search } = useLocation();
  const { current: activityChecker } = useRef<DetectUserActivity>(new DetectUserActivity());

  useEffect(() => {
    // GoogleTagManager.initialize();

    return () => {
      activityChecker.destroy();
    };
  }, [activityChecker]);

  return (
    <ErrorBoundary fullPath={`${pathname}${search}`}>
      <InternetNotifier />
      <Segment auth={auth}>
        <EnvironmentBanner>
          <AppUpdateChecker>
            <Head />
            <Body />
            <NotificationBanner />

            <If
              condition={auth.isLoggedIn()}
              do={<ProtectedRoutes activityChecker={activityChecker} />}
              else={<UnprotectedRoutes />}
            />
          </AppUpdateChecker>
        </EnvironmentBanner>
      </Segment>
    </ErrorBoundary>
  );
}
