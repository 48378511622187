import { useQuery } from 'react-query';

import {
  MemberAgeDemographicStats,
  MemberAgeDemographicStatsT
} from '@kwara/models/src/models/Dashboard/MemberAgeDemographicStats';

const fetchMemberAgeDemographicStats = async () => {
  const res = await MemberAgeDemographicStats.extraParams({ buckets: '0,18,35,100' }).find();
  return res.data;
};

export const useMemberAgeDemographicStats = () => {
  return useQuery<MemberAgeDemographicStatsT>(['member_age_demographic_stats'], fetchMemberAgeDemographicStats);
};
