// @flow

import * as React from 'react';

import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { type MemberType } from '@kwara/models/src';

import { EditableSection, type EditableConfig } from '../../../components/EditableSection/v1/EditableSection';
import { Documents as DocumentsForm } from '../../Loan/LoanAdd/components/Documents';
import { DocumentList } from '../../../components/DocumentList';

const config = { Component: DocumentsForm };

export const Documents = ({ member, readOnly }: EditableConfig<MemberType>) => {
  return (
    <EditableSection
      initialData={{ member }}
      readOnly={readOnly}
      config={config}
      onSave={data => {
        // this one should work except it doesn't
        // await data.save({ with: ['attachments'] });
        // so we use this hack
        return saveAttachments(member, data.attachments);
      }}
    >
      <DocumentList member={member} contexts={['loans']} attachments={member.attachments} />
    </EditableSection>
  );
};
