import React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/merge';
import toString from 'lodash/toString';
import set from 'lodash/set';

import { Logger } from '@kwara/lib/src/logger';
import { CollateralT, Collateral } from '@kwara/models/src';

import { EditableContext } from '../../../../../../../components/EditableSection/v1/EditableSection';
import { default as CollateralsBase } from '../../../../../../../components/Collaterals/Collaterals';

import { useUpdateCollateral } from './useUpdateCollateral';
import { useCollateralFileInputLoanAppraisal } from './useCollateralFileInputLoanAppraisal';

export function commitChanges(collaterals: CollateralT[] = [], index: number, formCollaterals: CollateralT[]) {
  // this is only need to make Flow happy that we're passing a string to Lodash methods
  const idx = toString(index);
  // Duplicate the object to avoid mutating the original instance.
  const original = getOr({}, idx, collaterals);
  // Make sure we are always dealing with a SPraypaint instance
  const asInstance = original.isSpraypaintInstance ? original.dup() : Collateral.create(original);
  // Pick the collateral that have been changed just now (this is a plain object, NOT an instance)
  const changed = get(index, formCollaterals);
  // Commit the changes
  // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
  /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
  const updated = merge(asInstance, changed);
  // Replace the collateral that was edited with the newly created duplicate or add it if it's a
  // new one
  set(collaterals, idx, updated);

  return collaterals;
}

const BASE_PATH = 'collaterals';

function Collaterals(props) {
  const { data, formProps, onChange } = props;
  const { onUpdate } = React.useContext<{ onUpdate: (data: Record<string, any>) => Promise<void> }>(EditableContext);
  const { isLoading, error, mutate, reset } = useUpdateCollateral();

  const getPath = (index: number) => `${BASE_PATH}[${index}]`;

  const collaterals = get('values.collaterals', formProps);

  const { fileName, hasFile } = useCollateralFileInputLoanAppraisal({
    onChange,
    collaterals
  });

  async function onSuccessfulChange(shouldSaveAsServer: boolean) {
    if (shouldSaveAsServer) {
      await mutate({
        editedLoan: data.loan,
        onSuccess() {
          onUpdate(data.loan);
        }
      });
    }
  }

  async function onCollateralRemove(index: number, isEditingACollateral: boolean) {
    const path = getPath(index);

    const formCollateral = get(path, formProps.values);
    if (formCollateral) formCollateral.isMarkedForDestruction = true;
    formProps.form.change(path, formCollateral);

    try {
      await onChange(commitChanges(data.collaterals, index, collaterals));
      await onSuccessfulChange(isEditingACollateral);
    } catch (err) {
      Logger.error(`Cannot remove collateral`, JSON.stringify(err));
    }
  }

  async function onCollateralSave(index: number) {
    commitChanges(data.collaterals, index, collaterals);
    try {
      await onSuccessfulChange(true);
    } catch (err) {
      Logger.error(`Cannot save collateral`, JSON.stringify(err));
    }
  }

  return (
    <CollateralsBase.Component
      {...props}
      withControls
      withNarration
      onCollateralRemove={onCollateralRemove}
      onCollateralSave={onCollateralSave}
      isLoading={isLoading}
      patchError={error}
      reset={reset}
      getPath={getPath}
      fileName={fileName}
      hasFile={hasFile}
    />
  );
}

export default {
  Component: Collaterals,
  validate: CollateralsBase.validate
};
