import { useCallback, useState, useMemo } from 'react';

import { FilterValue } from 'FilterTypes';

export function useSearchRadioBaseFilter(filters: FilterValue[]) {
  const [searchValue, setSearchValue] = useState('');

  const onSearch = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(ev.target.value);
  }, []);

  const filterValues = useMemo(
    () => filters.filter(fil => fil.name.toLowerCase().includes(searchValue.toLowerCase())),
    [filters, searchValue]
  );

  return { searchValue, filterValues, onSearch };
}
