import { LoanClassificationReport, IncludesT } from '@kwara/models/src';

import { useOverviewList, fetchWithPagination } from '../base';

const defaultLoanClassificationReportsIncludes = [];
async function fetchLoanClassificationReports(opts) {
  return fetchWithPagination(LoanClassificationReport, opts);
}

export function usePaginatedLoanClassificationReports(includes: IncludesT = defaultLoanClassificationReportsIncludes) {
  return useOverviewList({
    queryKey: 'loan_classification_reports',
    fetchFn: fetchLoanClassificationReports,
    includes,
    select: null
  });
}
