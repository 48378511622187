export class InstantMobileMoneyValidatorUtil {
  public static readonly SUPPORTED_MOBILE_MONEY_INSTANT_PROVIDERS = ['Safaricom'];
  public static readonly SUPPORTED_PAYOUT_PROVIDERS = ['cellulant', 'cellulant_c2b'];

  public static isTransactionAmountLimitValid(amount: number, transactionAmountLimit: number): boolean {
    return amount <= transactionAmountLimit;
  }

  public static isPhoneNumberProviderSupported(provider: string): boolean {
    return InstantMobileMoneyValidatorUtil.SUPPORTED_MOBILE_MONEY_INSTANT_PROVIDERS.includes(provider);
  }

  public static isPayoutProviderSupported(payoutProvider: string): boolean {
    return InstantMobileMoneyValidatorUtil.SUPPORTED_PAYOUT_PROVIDERS.includes(payoutProvider);
  }
}
