import * as React from 'react';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';

import { LoanProductType } from '@kwara/models/src';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import { useRepaymentAmount } from '../useRepaymentAmount';
import { useFeeValues } from '../../../../hooks';
import { getRepaymentTerms } from './utils';

declare interface ArgsT {
  product: LoanProductType;
  feeApplications: FeeApplicationT[];
  amount: string;
  loanDuration: number;
  repaymentPeriodUnit: 'MONTHS' | 'WEEKS' | 'DAYS';
}

export function useLoanRepaymentFields(
  { product, feeApplications, amount = '0', loanDuration = 1, repaymentPeriodUnit }: ArgsT,
  isV1?: boolean
) {
  const { totalAdditionalFees } = useFeeValues({ product, feeApplications, amount });
  const { fixedRepayment } = getRepaymentTerms({ product });
  const repaymentAmountPayload = React.useMemo(
    () =>
      omitBy(isNil, {
        principal_amount: amount,
        loan_duration: loanDuration,
        repayment_frequency_unit: repaymentPeriodUnit,
        additional_fees: totalAdditionalFees
      }),
    [amount, loanDuration, repaymentPeriodUnit, totalAdditionalFees]
  );

  const repaymentAmountR = useRepaymentAmount(product.id, repaymentAmountPayload, !isV1);

  return { fixedRepayment, repaymentAmountR };
}
