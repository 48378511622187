import React from 'react';

import { UserT } from '@kwara/models/src';
import { EditableSection } from '../../../../../components/EditableSection/v1/EditableSection';

import { Detail, DetailWrapper, SettingProps } from '..';

export const PhoneNumberF = ({ StackChild, TextField }: SettingProps) => {
  return (
    <StackChild>
      <TextField name="phoneNumber" labelId="Settings.Personal.PhoneNumber.label" />
    </StackChild>
  );
};

const config = {
  Component: PhoneNumberF
};

export function PhoneNumber({ user }: { user: UserT }) {
  return (
    <DetailWrapper>
      <EditableSection config={config} onSave={() => {}} initialData={user} editClassNames="items-center" readOnly>
        <Detail titleId="Settings.Personal.PhoneNumber.title" value={user.phoneNumber} />
      </EditableSection>
    </DetailWrapper>
  );
}
