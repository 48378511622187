/* eslint-disable no-console */
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import getOr from 'lodash/fp/getOr';
import values from 'lodash/fp/values';

import { ValueOf } from 'GlobalTypes';

export const contexts = Object.freeze({
  Deposit: 'Deposit',
  Utility: 'Utility',
  LoanPayOff: 'LoanPayOff',
  Withdrawal: 'Withdrawal',
  LoanRepayment: 'LoanRepayment',
  LoanRemittance: 'LoanRemittance',
  LoanDisbursement: 'LoanDisbursement',
  SavingsRemittance: 'SavingsRemittance',
  LoanRemittance_Connect: 'LoanRemittance_Connect',
  InstantLoanDisbursement: 'InstantLoanDisbursement'
});

type ContextT = ValueOf<typeof contexts>;

export const TransactionChannels = Object.freeze({
  cash: 'cash',
  fosa: 'fosa',
  mpesa: 'mpesa',
  cheque: 'cheque',
  mobile: 'mobile_money',
  glTransfer: 'glTransfer',
  directDebit: 'direct_debit',
  bankDeposit: 'bank_deposit',
  standingOrder: 'standing_order',
  bankTransfer: 'bank_transfer_eft',
  payrollDeduction: 'payroll_deduction',
  mobileMpesaInstant: 'mobile_money_mpesa_instant'
});

export type TransactionChannelT = ValueOf<typeof TransactionChannels>;

function asValues(pickedChannels) {
  return pipe(
    values,
    map<TransactionChannelT, { value: TransactionChannelT }>(value => ({ value }))
  )(pickedChannels);
}

/**
 * This object drives the options available for each type of transaction.
 * See the reference table at https://kwara-eng-docs.netlify.com/mambu/transaction_channels/
 */
const validTransactionTypesByContext = {
  [contexts.SavingsRemittance]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  /**
   * `contexts.LoanRemittance` Used for the "Repayment" step of LoanAdd
   */
  [contexts.LoanRemittance]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  /**
   * `contexts.LoanRemittance_Connect` Used for the "Repayment" step of LoanAdd
   */
  [contexts.LoanRemittance_Connect]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  [contexts.LoanDisbursement]: {
    values: asValues([
      TransactionChannels.mobileMpesaInstant,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.fosa,
      TransactionChannels.mobile
    ])
  },
  [contexts.InstantLoanDisbursement]: {
    values: asValues([TransactionChannels.mpesa])
  },
  /**
   * `contexts.LoanRepayment` Used for Repayment form
   */
  [contexts.LoanRepayment]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder,
      TransactionChannels.glTransfer
    ])
  },
  [contexts.LoanPayOff]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  [contexts.Deposit]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.mobile,
      TransactionChannels.glTransfer
    ])
  },
  [contexts.Withdrawal]: {
    values: asValues([
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.mobile,
      TransactionChannels.glTransfer
    ])
  },
  [contexts.Utility]: {
    values: asValues([TransactionChannels.cash])
  }
};

type GetTransactionTypesReturnType = Record<'values', Array<Record<'value', string>>>;
export function getTransactionTypes(context: ContextT): GetTransactionTypesReturnType {
  return getOr(
    () =>
      console.error('Pass a valid context to get a list of transaction channels', `Can't find a list for ${context}`),
    context,
    validTransactionTypesByContext
  ) as GetTransactionTypesReturnType;
}
