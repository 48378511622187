//@flow

import { segmentTrack } from '@kwara/components/src/Segment';
import { isTest as __testEnv__ } from '@kwara/lib/src/utils';

const TYPES = Object.freeze({
  setWhenPasswordToExpireRequestResolved: 'setWhenPasswordToExpireRequestResolved',
  setWhenPasswordToExpireRequestRejected: 'setWhenPasswordToExpireConfigRejected',
  setPasswordResetRequestResolved: 'setPasswordResetRequestResolved',
  setPasswordResetRequestRejected: 'setPasswordResetRequestRejected',
  passwordExpired: 'passwordExpired'
});

type $Types = $Keys<typeof TYPES>;
type Args = {
  whenPasswordToExpire?: string
};

function passwordSegmentTrackingFacade(type: $Types, args: Args) {
  let event: ?string = null;
  const properties: Record<string, string> | {} = {};

  switch (type) {
    case TYPES.setWhenPasswordToExpireRequestResolved:
      event = 'Successfully Changed Password Expiry Period';
      properties.whenPasswordToExpire = args.whenPasswordToExpire;
      break;

    case TYPES.setWhenPasswordToExpireRequestRejected:
      event = 'Error During Password Expiry Change Detected';
      properties.whenPasswordToExpire = args.whenPasswordToExpire;
      break;

    case TYPES.setPasswordResetRequestResolved:
      event = 'Password Reset Successful';
      break;

    case TYPES.setPasswordResetRequestRejected:
      event = 'Password Reset Failed';
      break;

    case TYPES.passwordExpired:
      event = 'Password Has Expired';
      break;

    default:
      return;
  }

  if (__testEnv__ && event) {
    return {
      event,
      properties
    };
  }

  segmentTrack(event, properties);
}

export { passwordSegmentTrackingFacade, TYPES };
