import * as React from 'react';
import { Redirect, useParams, RouteProps } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import { LoanType } from '@kwara/models/src/models/Loan';
import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import LoanDetail from '../LoanDetail';

import { Loader } from './Loader';

function shouldRedirectToLoanAppraisal(loan: LoanType) {
  return loan.isPendingApproval();
}

type ParamsType = {
  loanId: string;
};

export function LoanRouteDecider(props: RouteProps<string, ParamsType>) {
  const params = useParams<ParamsType>();
  const r = useLoan(params.loanId);

  return (
    <Loadable {...r} loading={<Loader />}>
      {(loan: LoanType) => (
        <If
          condition={shouldRedirectToLoanAppraisal(loan)}
          do={<Redirect to={`/loans/${loan.id}/appraisal`} />}
          else={<LoanDetail loan={loan} refetchLoan={r.refetch} {...props} />}
        />
      )}
    </Loadable>
  );
}
