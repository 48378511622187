import React from 'react';
import { Form } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import { Text } from '@kwara/components/src/Intl';

import { Stack } from '../../../../components/Stack';

const SubmissionBanner = ({ success, failed }) => {
  return success || failed ? (
    <div className="h3">
      {success ? <Banner size text={<Text id="UserEdit.Roles.onSuccess" />} state="success" /> : null}
      {failed ? <Banner size text={<Text id="UserEdit.Roles.onError" />} state="error" /> : null}
    </div>
  ) : null;
};

export const SettingsSectionForm = ({ initialValues, titleId, permitted, render, onSubmit: onSubmitPromise }) => {
  const [success, setSuccess] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  const resetBanners = () =>
    window.setTimeout(() => {
      setSuccess(false);
      setFailed(false);
    }, 1000);

  const onSubmit = async data => {
    try {
      await onSubmitPromise(data);
      setSuccess(true);
    } catch {
      setFailed(true);
    } finally {
      resetBanners();
    }
  };

  return (
    <Form
      onSubmit={data => onSubmit(data)}
      initialValues={initialValues}
      render={({ form, handleSubmit, values, submitting }) => {
        return (
          <form className="mb5" onSubmit={handleSubmit}>
            <Stack.Child>
              <SubmissionBanner success={success} failed={failed} />

              <p className="kw-text-large kw-weight-bold">
                <Text id={titleId} />
              </p>

              {render({ formProps: form, values })}
              <Button
                hidden={!permitted}
                type="primary"
                isSubmit
                disabled={submitting}
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="Settings.manageCredentials.save" />
              </Button>
            </Stack.Child>
          </form>
        );
      }}
    />
  );
};
