// @flow

import * as React from 'react';
import cx from 'classnames';

import logotype from '@kwara/components/src/LogIn/logotype.svg';
import { Footer } from '@kwara/components/src/Footer';

import styles from './index.module.scss';

export const LogoLayout = ({ children, classNames }: { children: React.Node, classNames?: string }) => {
  return (
    <div className={styles.Container}>
      <div className={cx(styles.Image, 'h-100')}>
        <img alt="Kwara" className={styles.Logotype} src={logotype} />
      </div>

      <div className={cx(styles.Content, classNames)}>
        {children}
        <Footer />
      </div>
    </div>
  );
};
