import React from 'react';
import { useIntl } from 'react-intl';

import { LoanSubstepProps } from 'GlobalTypes';

import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import { useGetRepaymentBefore } from './useGetRepaymentBefore';

const sharedProps = { showInfo: false, required: true };

function DisbursementAndRepayment({
  DatePicker,
  formProps: {
    values: {
      anticipatedDisbursementDate,
      loan: { firstRepaymentDate }
    }
  }
}: LoanSubstepProps) {
  const intl = useIntl();
  const repaymentBefore = useGetRepaymentBefore(anticipatedDisbursementDate);

  const inputPlaceholder = intl.formatMessage({ id: 'LoanTopup.DisbursementAndRepayment.datePlaceholder' });

  return (
    <>
      <DatePicker
        {...sharedProps}
        labelId="LoanTopup.DisbursementAndRepayment.anticipatedDisbursementDate"
        name="anticipatedDisbursementDate"
        inputPlaceholder={inputPlaceholder}
        value={anticipatedDisbursementDate ? formatIsoDate(new Date(anticipatedDisbursementDate)) : null}
        disabledDays={{ before: getCurrentDate() }}
      />

      <DatePicker
        {...sharedProps}
        labelId="LoanTopup.DisbursementAndRepayment.anticipatedFirstRepaymentDate"
        name="loan.firstRepaymentDate"
        inputPlaceholder={inputPlaceholder}
        value={firstRepaymentDate ? formatIsoDate(new Date(firstRepaymentDate)) : null}
        disabledDays={{ before: repaymentBefore }}
      />
    </>
  );
}

export default {
  Component: DisbursementAndRepayment,
  validate: {
    ['anticipatedDisbursementDate']: {
      isRequired: () => true
    },
    ['loan.firstRepaymentDate']: {
      isRequired: () => true
    }
  }
};
