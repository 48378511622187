// @flow
import * as React from 'react';
import concat from 'lodash/concat';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src/Intl';
import { type MemberType, Guarantor as GuarantorModel } from '@kwara/models/src';

import { Header } from '../Header';
import { type LoanSubstepProps } from '../../..';
import { useListEditor } from '../../../../../../hooks';
import { GuarantorList } from './components/GuarantorList';
import { GuarantorSearch, Loader } from './components/GuarantorSearch';
import { GuarantorValidationModal } from './components/GuarantorValidationModal';

const basePath = 'guarantors';

export const Guarantor = (props: LoanSubstepProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { formProps, data, error, StackChild, onChange, addData, addDataAndContinue } = props;
  const { product } = data;
  const { isEditing, setEdit, addItem, setView } = useListEditor(data.guarantors, basePath);

  const onAddMember = async (member: MemberType) => {
    const inst = new GuarantorModel({ member, memberId: member.id });

    await onChange({
      guarantors: concat(data.guarantors || [], inst)
    });
  };

  if (!product.hasGuarantorsEnabled()) {
    return (
      <StackChild>
        <div className="pb4 flex justify-between items-center">
          <p className="ma0">
            <Text id="LoanAdd.Security.Guarantor.disabled" />
          </p>
        </div>
      </StackChild>
    );
  }

  return (
    <>
      <Header
        titleId="LoanAdd.Security.Guarantor.title"
        subtitleId="LoanAdd.Security.Guarantor.subtitle"
        buttonTextId="LoanAdd.Security.Guarantor.add"
        enabled={true}
        StackChild={StackChild}
        isSearching={isEditing}
        onAdd={() => {
          addItem();
          setEdit();
        }}
        memberName={data.member.fullName()}
        data={data}
      />

      {isEditing ? (
        <GuarantorSearch
          onSelect={onAddMember}
          StackChild={StackChild}
          formProps={formProps}
          data={data}
          onCancel={setView}
          onFetchStart={() => {
            setView();
            setIsLoading(true);
          }}
          onFetchEnd={() => setIsLoading(false)}
        />
      ) : (
        <>
          <Loader isLoading={isLoading} />
          <GuarantorList product={product} guarantors={data.guarantors} formProps={formProps} addData={addData} />
          <GuarantorValidationModal addDataAndContinue={addDataAndContinue} error={error} />
        </>
      )}
    </>
  );
};

const amountKey = index => `guarantors[${index}].amount`;
const canGuaranteeUpToKey = index => `guarantors[${index}].canGuaranteeUpTo`;

Guarantor.validate = ({ guarantors }) => {
  const validations = {};

  if (guarantors != null) {
    guarantors.forEach((_, index) => {
      validations[amountKey(index)] = {
        isRequired: (_, allData) => !get(`guarantors.[${index}].isMarkedForDestruction`, allData),
        // max: _.member.standing,
        // We (temporarily) allow members to "overguarantee", see:
        // https://app.clubhouse.io/getkwara/story/10186/remove-restrictions-on-guarantor-addition-even-if-they-are-over-guaranteed
        currency: true,
        nonZero: true
      };

      validations[canGuaranteeUpToKey(index)] = {
        isRequired: (_, allData) =>
          !get(`guarantors.[${index}].isMarkedForDestruction`, allData) &&
          !!get(`guarantors.[${index}].canGuaranteeUpTo`, allData)
      };
    });
  }
  return validations;
};
