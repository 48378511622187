import { useState } from 'react';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';

import { SearchParams } from '..';
import { usePermissions } from '../../../../../hooks';
import {
  Key,
  getAddNewRoutes,
  getMoreRoutes,
  getVisibleRoutes
} from '../components/ExtraActionModal/generateModalRoutes';
import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';
import { generateLeftNavigationRoutes } from '../utils/generateLeftNavigationRoutes';

function setCssRootProperty(key: string, value: string) {
  document.documentElement.style.setProperty(key, value);
}

function getCssRootProperty(key: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(key);
}

export function useLeftNavigation() {
  const { permission, AppPermissions } = usePermissions();
  const searchParams = useSearchParams<SearchParams>();
  const [extraActionModalCallee, setExtraActionModalCallee] = useState<Key | null>(null);
  const store = useSaccoProfileContext();

  const [isCollapsed, { toggle: toggleCollapsable }] = useBoolean(() => {
    const isOnSubNavigation = searchParams.get('parentId') != null;

    if (isOnSubNavigation) {
      setCssRootProperty('--appbase-leftnavigation-width', getCssRootProperty('--appbase-opened-leftnavigation-width'));
    }

    return !isOnSubNavigation;
  });
  const [
    isExtraActionModalOpened,
    { toggle: onToggleExtraActionModal, setToFalse: onCloseExtraActionModal }
  ] = useBoolean();

  const routes = generateLeftNavigationRoutes(permission.to, AppPermissions, store);
  const showAddNewAction = !!getVisibleRoutes(permission.to, getAddNewRoutes(AppPermissions)).length;
  const showMoreAction = !!getVisibleRoutes(permission.to, getMoreRoutes(AppPermissions, store.isCrbCheckEnabled))
    .length;

  function onOpenExtraActionModal(callee: Key) {
    return function onClickHandler() {
      setExtraActionModalCallee(callee);
      onToggleExtraActionModal();
    };
  }

  function onToggleCollapsable() {
    const width = getCssRootProperty(
      isCollapsed ? '--appbase-opened-leftnavigation-width' : '--appbase-closed-leftnavigation-width'
    );

    setCssRootProperty('--appbase-leftnavigation-width', width);

    toggleCollapsable();
  }

  return {
    extraActionModalCallee,
    routes,
    isExtraActionModalOpened,
    isCollapsed,
    onToggleCollapsable,
    onOpenExtraActionModal,
    onCloseExtraActionModal,
    showAddNewAction,
    showMoreAction,
    collapsableIconAccessibilityLabel: `Click to ${isCollapsed ? 'expand' : 'condense'} the navigation UI`
  };
}
