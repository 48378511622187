import { useCallback } from 'react';

import lodashGroupBy from 'lodash/groupBy';
import lodashOrderBy from 'lodash/orderBy';

import { ValueOf } from 'GlobalTypes';

import { MemberType } from '@kwara/models/src/models/Member';

const Groups = { state: 'state' };

const Orders = { updated: 'updated_at' };

export type Group = ValueOf<typeof Groups>;
export type Order = ValueOf<typeof Orders>;
export type GroupedMembers = { [id: Group]: MemberType[] };

function useMemberListTableUtils() {
  const membersGroupedByAttribute = useCallback(
    (members: MemberType[], attribute: Group): GroupedMembers => lodashGroupBy(members, member => member[attribute]),
    []
  );

  const orderMembersByAttribute = useCallback(
    (members: MemberType[], attribute: Order | null): MemberType[] =>
      lodashOrderBy(members, [member => [member[attribute]]], ['desc']),
    []
  );

  return {
    membersGroupedByAttribute,
    orderMembersByAttribute
  };
}

export { useMemberListTableUtils, Groups, Orders };
