import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { OperationalReportT, SavingProductType } from '@kwara/models/src';

import Visible from '../../components/Visible';
import OperationalReports from '../../components/OperationalReports';

import { Form } from './components/Form';
import { BodyContentWrapper } from '../../layouts';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { useOperationalReportsView } from '../../components/OperationalReports/useOperationalReportsView';
import { useSavingProducts } from '../../hooks/useRequest';

type Payload = { filters: { loan_product_id?: string | null } };

type LayoutPropTypes = {
  reports: OperationalReportT[];
  isLoadingReports: boolean;
  hasMoreReports: boolean;
  onNext(): void;
  onReportDownload(report: OperationalReportT): void;
  isReportDownloading: boolean;
  onGenerate(data: Payload): void;
  products: SavingProductType[];
  isLoadingProducts: boolean;
};

export function Layout({
  reports,
  hasMoreReports,
  isLoadingReports,
  isReportDownloading,
  onNext,
  onReportDownload,
  onGenerate,
  products,
  isLoadingProducts
}: LayoutPropTypes) {
  const { AppPermissions } = usePermissions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Root>
          <div className="flex justify-between">
            <div />
            <Visible to={AppPermissions.CreateDepositListingReports}>
              <Form products={products} isLoadingProducts={isLoadingProducts} onGenerate={onGenerate} />
            </Visible>
          </div>
        </BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id={'DepositListingReports.ReportsIndex.title'} />
          </div>
          <OperationalReports.Table
            reports={reports}
            isLoading={isLoadingReports}
            hasMore={hasMoreReports}
            onNext={onNext}
            onDownload={onReportDownload}
            isDownloading={isReportDownloading}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function DepositListingReports() {
  const {
    allReports,
    isLoadingReports,
    hasMoreReports,
    onNext,
    onGenerate,
    onDownload,
    isReportDownloading
  } = useOperationalReportsView('deposit_listing_report');
  const { data: products = [], isLoading: isLoadingProducts } = useSavingProducts();

  return (
    <Layout
      reports={allReports}
      hasMoreReports={hasMoreReports}
      isLoadingReports={isLoadingReports}
      isReportDownloading={isReportDownloading}
      onNext={onNext}
      onReportDownload={onDownload}
      onGenerate={onGenerate}
      products={isLoadingProducts ? [] : products}
      isLoadingProducts={isLoadingProducts}
    />
  );
}
