import React from 'react';
import get from 'lodash/fp/get';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { injectIntl, IntlShape } from 'react-intl';

import { getTranslation } from '@kwara/components/src/Intl';
import { MobileBankingUsersStatsT } from '@kwara/models/src';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { If } from '@kwara/components/src/If/If';

import {
  CustomizedResponsiveContainer,
  CustomizedXAxisTick,
  CustomizedYAxisTick,
  renderCustomizedLegend,
  customizedMarginSetup,
  renderCustomizedTooltip
} from '../Customized';
import { useMobileBankingUsersStats } from './useMobileBankingUsersStats';
import { getStartAndEndDates } from '../../utils';

import styles from '../../index.module.scss';

export const MobileBankingUsersWithIntl = ({ intl }: { intl: IntlShape }) => {
  const r = useMobileBankingUsersStats(getStartAndEndDates());

  return (
    <Loadable loading={<LoadingSkeleton />} {...r}>
      {(data: MobileBankingUsersStatsT) => {
        const totalMonthlyMobileBankingUsers = get('totalMonthlyMobileBankingUsers', data);
        return (
          <If
            condition={!!totalMonthlyMobileBankingUsers}
            do={
              <CustomizedResponsiveContainer className={styles['h-300']}>
                <AreaChart data={totalMonthlyMobileBankingUsers} margin={customizedMarginSetup}>
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="date"
                    tick={props => <CustomizedXAxisTick {...props} abbrMonth />}
                    interval={0}
                  />

                  <YAxis axisLine={false} tickLine={false} tick={props => <CustomizedYAxisTick {...props} />} />
                  <Tooltip content={renderCustomizedTooltip} />
                  <Legend content={renderCustomizedLegend} verticalAlign="top" />
                  <Area
                    type="monotone"
                    dataKey="running_total"
                    name={getTranslation(intl, 'Dashboard.MemberBase.runningTotal.name')}
                    fill="#aaf0c4"
                    stroke="#aaf0c4"
                  />
                  <Area
                    type="monotone"
                    dataKey="activations"
                    name={getTranslation(intl, 'Dashboard.MemberBase.activations.name')}
                    fill="#3ccb7f"
                    stroke="#3ccb7f"
                  />
                </AreaChart>
              </CustomizedResponsiveContainer>
            }
          />
        );
      }}
    </Loadable>
  );
};

export const MobileBankingUsers: React.ComponentType = injectIntl(MobileBankingUsersWithIntl);
