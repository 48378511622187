// @flow

import * as React from 'react';

import { type MemberType, type ConnectUserT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import Empty from '@kwara/components/src/Empty';
import { Statistic } from '@kwara/components/src/Statistic';

import { useConnectUser } from '../../../hooks';
import { usePermissions } from '../../../hooks';
import DetailSubsection from '../../../components/DetailSubsection';
import { ToggleBar } from '../../../components/ToggleBar';

const MBankingToggles = ({ connectUser }: { connectUser: ConnectUserT }) => {
  const [checked, setChecked] = React.useState<boolean>(connectUser.isPinLocked());
  const [isPending, setIsPending] = React.useState(false);
  const { permission, AppPermissions } = usePermissions();

  const onChange = e => {
    if (e.target.checked) {
      return;
    }

    setIsPending(true);

    connectUser
      .unlockPin()
      .then(updatedConnectUser => {
        setChecked(updatedConnectUser.isPinLocked());
      })
      .finally(() => setIsPending(false));
  };

  return (
    <ToggleBar
      titleId="MemberDetail.MobileBanking.Subsection.blockedUSSD.title"
      subtitleId={checked ? 'MemberDetail.MobileBanking.Subsection.blockedUSSD.subtitle' : null}
      name="ussd-pin"
      checked={checked}
      onChange={onChange}
      disabled={isPending || !checked || !permission.to(AppPermissions.ConfigureMobileBanking)}
    />
  );
};

export const MobileBanking = ({ member }: { member: MemberType }) => {
  const r = useConnectUser(member.id);

  return (
    <DetailSubsection title={<Text id="MemberDetail.MobileBanking.Subsection.title" />}>
      <Statistic
        title={<Text id="MemberDetail.MobileBanking.SubscribedToMbanking.title" />}
        value={<Text id={`MemberDetail.MobileBanking.SubscribedToMbanking.${member.mbankingStatus || 'NO'}`} />}
        size="medium"
      />

      <Loadable {...r}>
        {connectUser => {
          if (!connectUser) {
            return (
              <Empty>
                <Text id="MemberDetail.MobileBanking.Subsection.empty" />
              </Empty>
            );
          }

          return <MBankingToggles connectUser={connectUser} />;
        }}
      </Loadable>
    </DetailSubsection>
  );
};
