import { OperationalReportT } from '@kwara/models/src';

import { useWaitingCursor } from '../../hooks/useWaitingCursor';

export function useReportDownload() {
  const { onWaitingStart, onWaitingEnd, isWaiting } = useWaitingCursor();

  function onDownload(report: OperationalReportT) {
    onWaitingStart();
    return report.download().finally(onWaitingEnd);
  }

  return { onDownload, isDownloading: isWaiting };
}
