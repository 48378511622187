import { calculatePercentage } from '@kwara/lib/src/utils/calculatePercentage';
import { MemberAgeDemographicStatsT } from '@kwara/models/src/models/Dashboard/MemberAgeDemographicStats';

/**
 * constructMemberData
 */
function constructMemberData(data: MemberAgeDemographicStatsT) {
  const value = data.memberCountPerBucket.reduce(
    (acc, curr) => {
      acc.totalMember += curr.count;

      if (curr.upper_bound < 36) acc.eighteenToThirtyFive += curr.count;
      else acc.thirtySixToHundred += curr.count;

      return acc;
    },
    { totalMember: data.unknownBirthdateCount, eighteenToThirtyFive: 0, thirtySixToHundred: 0 }
  );

  return value;
}

/**
 * formatPieChartData
 */
export function formatPieChartData(data: MemberAgeDemographicStatsT) {
  const { eighteenToThirtyFive, thirtySixToHundred, totalMember } = constructMemberData(data);
  const calculateMemberCountPercentage = calculatePercentage(totalMember);

  return {
    totalMember,
    ageDemography: [
      {
        name: `18-35 (${calculateMemberCountPercentage(eighteenToThirtyFive)}%)`,
        value: eighteenToThirtyFive,
        color: '#aaf0c4'
      },
      {
        name: `36-100 (${calculateMemberCountPercentage(thirtySixToHundred)}%)`,
        value: thirtySixToHundred,
        color: '#CBD5E1'
      },
      {
        name: `Uncategorized (${calculateMemberCountPercentage(data.unknownBirthdateCount)}%)`,
        value: data.unknownBirthdateCount,
        color: '#94A3B8'
      }
    ]
  };
}
