//@flow
import * as React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import { fields } from '@kwara/models/src/models/Remittance';
import { Percent, Text } from '@kwara/components/src/Intl';

import { useAuth } from '../../../hooks';
import { Currency } from '../../../components/Currency';
import { Record, Section } from '../../../components/Summary';
import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({ data, StackChild, parentUrl }: SubStepComponentProps<>) => {
  const auth = useAuth();
  const isV1 = auth.isV1();

  const editUrl = path => `${parentUrl}/${path}`;

  return (
    <StackChild>
      <Section titleId="SavingAdd.Summary.member">
        <Record labelId="SavingAdd.Summary.memberId" value={data.member.id} />
        <Record labelId="SavingAdd.Summary.memberName" value={data.member.fullName()} />
      </Section>

      <Section titleId="SavingAdd.Summary.product" editUrl={editUrl('product/1')}>
        <Record labelId="SavingAdd.Summary.productName" value={data.savingProduct.name} />
        <Record
          labelId="SavingAdd.Summary.interestRate"
          value={
            data.savingProduct.interestRate.default ? (
              <Percent value={data.savingProduct.interestRate.default / 100} />
            ) : null
          }
        />
        <Record
          labelId="SavingAdd.Summary.depositAmount"
          value={data.depositAmount ? <Currency value={data.depositAmount} format="currency" /> : null}
        />
      </Section>

      {isV1 ? null : (
        <Section titleId="SavingAdd.Summary.remittance" editUrl={editUrl('payment/2')}>
          <Record
            labelId="SavingAdd.Summary.remittanceMethod"
            value={<Text id={`TransactionMethod.${getOr('null', fields.remittance.mode, data)}`} />}
          />
          <Record
            labelId="SavingAdd.Summary.remittanceAmount"
            value={<Currency value={get(fields.remittance.amount, data)} format="currency" />}
          />
        </Section>
      )}
    </StackChild>
  );
};
