import React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

export const Tooltip = ({ children, tooltipId, noIcon }) => {
  if (!children) {
    return null;
  }

  return tooltipId ? (
    <span className={styles.TooltipWrapper}>
      {children} {noIcon ? null : <span className={cx('tc w1 h1 dib ba br-100')}>i</span>}
      <span className={cx('white br3 pa2', styles.Tooltip)}>
        <Text id={tooltipId} />
      </span>
    </span>
  ) : (
    children
  );
};
