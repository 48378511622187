import * as React from 'react';

export function IconPrevious() {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.879985 9.09002L4.74999 13C4.84295 13.0937 4.95355 13.1681 5.07541 13.2189C5.19727 13.2697 5.32797 13.2958 5.45999 13.2958C5.592 13.2958 5.7227 13.2697 5.84456 13.2189C5.96642 13.1681 6.07702 13.0937 6.16999 13C6.26371 12.9071 6.33811 12.7965 6.38888 12.6746C6.43965 12.5527 6.46578 12.422 6.46578 12.29C6.46578 12.158 6.43965 12.0273 6.38888 11.9054C6.33811 11.7836 6.26371 11.673 6.16999 11.58L2.60998 8.00002H23C23.2652 8.00002 23.5196 7.89466 23.7071 7.70712C23.8946 7.51959 24 7.26523 24 7.00002C24 6.7348 23.8946 6.48045 23.7071 6.29291C23.5196 6.10538 23.2652 6.00002 23 6.00002H2.54998L6.16999 2.38002C6.34727 2.19404 6.44618 1.94696 6.44618 1.69002C6.44618 1.43308 6.34727 1.186 6.16999 1.00002C6.07702 0.906293 5.96642 0.831899 5.84456 0.78113C5.7227 0.730362 5.592 0.704224 5.45999 0.704224C5.32797 0.704224 5.19727 0.730362 5.07541 0.78113C4.95355 0.831899 4.84295 0.906293 4.74999 1.00002L0.879985 4.85002C0.318183 5.41252 0.00262451 6.17502 0.00262451 6.97002C0.00262451 7.76502 0.318183 8.52752 0.879985 9.09002Z"
        fill="#94A3B8"
      />
    </svg>
  );
}
