import React from 'react';

type Ref = React.MutableRefObject<HTMLDivElement>;

export class StyleUtils {
  private transforms: Array<string> = [];
  private parentRef: Ref;
  private menuRef: Ref;

  constructor(parentRef: Ref, menuRef: Ref) {
    this.parentRef = parentRef;
    this.menuRef = menuRef;
  }

  private get parentPosition() {
    return this.parentRef.current.getBoundingClientRect();
  }

  private get menuPosition() {
    return this.menuRef.current.getBoundingClientRect();
  }

  private onMenuDoesOverlapY() {
    const menuDoesOverlapY = this.menuPosition.top < this.parentPosition.bottom;

    if (menuDoesOverlapY) {
      this.transforms.push(`translateY(${this.parentPosition.bottom - this.menuPosition.top}px)`);
    }
  }

  private onMenuIsOffscreen() {
    const menuIsOffscreenRight = window.innerWidth < this.menuPosition.right;
    const menuIsOffscreenLeft = this.menuPosition.left < 0;

    if (menuIsOffscreenRight) {
      this.transforms.push(`translateX(${window.innerWidth - this.menuPosition.right}px)`);
    } else if (menuIsOffscreenLeft) {
      this.transforms.push(`translateX(-${this.menuPosition.left}px)`);
    }
  }

  private transformMenu() {
    if (this.transforms.length > 0) {
      this.menuRef.current.style.transform = this.transforms.join(' ');
    }
  }

  public adjustMenuPosition() {
    if (!this.parentRef.current || !this.menuRef.current) return;

    this.onMenuDoesOverlapY();
    this.onMenuIsOffscreen();
    this.transformMenu();
  }
}
