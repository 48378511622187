//@flow
import * as React from 'react';

import { DatePickerInputs } from '@kwara/components/src/Form';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Approve = ({ StackChild, TextArea, formProps }: SubStepComponentProps<>) => {
  const { values } = formProps;
  const { firstPaymentDate } = values;
  return (
    <StackChild>
      {/** Currently disabled, ch6526 */}
      <div hidden>
        <DatePickerInputs
          name="disbursementDate"
          required={firstPaymentDate}
          placeholderId="LoanApprove.Approve.DisbursementDate.placeholder"
          labelId="LoanApprove.Approve.DisbursementDate.label"
          errorBaseId="LoanApprove.Approve.DisbursementDate"
        />
        <DatePickerInputs
          name="firstPaymentDate"
          placeholderId="LoanApprove.Approve.FirstPaymentDate.placeholder"
          infoId="LoanApprove.Approve.FirstPaymentDate.info"
          labelId="LoanApprove.Approve.FirstPaymentDate.label"
          errorBaseId="LoanApprove.Approve.FirstPaymentDate"
        />
      </div>
      <TextArea name="notes" labelId="LoanApprove.Approve.Note.label" infoId="LoanApprove.Approve.Note.info" />
    </StackChild>
  );
};
