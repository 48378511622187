import React from 'react';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import createValidator from '@kwara/lib/src/validator';
import { yesterday } from '@kwara/lib/src/dates';

type Payload = { toDate: Date; filters: {} };

type Props = {
  onGenerate(data: Payload): void;
};

const rules = {
  toDate: {
    isRequired: () => true
  }
};

export function Form({ onGenerate }: Props) {
  return (
    <ReactFinalForm
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      validate={createValidator(rules)}
      render={({ form, handleSubmit, valid, submitting }) => {
        return (
          <form
            className="w-100 flex justify-left items-center"
            onSubmit={handleSubmit}
            aria-label="Create Check Off Advice Form"
          >
            <div className="relative dib mr3">
              <SubscribedDatePicker
                compact
                margin={false}
                showInfo={false}
                required
                name="toDate"
                leftGlyph="CheckOffAdviceReports.Form.toDate.label"
                disabledDays={{ after: yesterday() }}
              />
            </div>

            <Button disabled={!valid || submitting} type="primary" onClick={form.submit}>
              <Text id="CheckOffAdviceReports.Form.submit.generate" />
            </Button>
          </form>
        );
      }}
    ></ReactFinalForm>
  );
}
