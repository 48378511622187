import * as React from 'react';

import Modal from '@kwara/components/src/Modal';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Panel } from '@kwara/components/src/Panel';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

import styles from './index.module.scss';

type ConfirmCancelPropTypes = {
  shown: boolean;
  onRemain: () => void;
  onCancel: React.MouseEventHandler;
};

export function ConfirmCancel({ shown, onCancel, onRemain }: ConfirmCancelPropTypes) {
  return (
    <Modal isOpen={shown} overlayClassName={styles.overlay}>
      <div className="w-100 h-100 flex justify-center items-center">
        <Panel>
          <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
            <Text id="WizardConfirmCancel.title" />
          </h2>

          <p className="kw-weight-light">
            <Text id="WizardConfirmCancel.subtitle" />
          </p>

          <ButtonBar
            className="mt4"
            left={
              <Button onClick={onRemain}>
                <Text id="WizardConfirmCancel.remain" />
              </Button>
            }
            right={
              <Button classNames="mr3" type="destructive" onClick={onCancel}>
                <Text id="WizardConfirmCancel.exit" />
              </Button>
            }
          />
        </Panel>
      </div>
    </Modal>
  );
}
