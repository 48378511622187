import * as React from 'react';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Date, Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import Modal from '@kwara/components/src/Modal';
import { If } from '@kwara/components/src/If/If';

import { Currency } from '../Currency';
import { Panel } from '../ActionModal';
import { AdjustmentTransactionType } from '.';

import styles from './index.module.scss';

type PropTypes = {
  transaction: AdjustmentTransactionType;
  onClose: Function;
};

function getData(transaction: AdjustmentTransactionType) {
  const data = [
    {
      title: <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.type" />,
      value: <Text id={`TransactionType.${transaction.type}`} />
    },
    {
      title: <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.dateCreated" />,
      value: <Date value={transaction.createdAt} />
    },
    {
      title: <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.valueDate" />,
      value: <Date value={transaction.valueDate} />
    },
    {
      title: <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.amount" />,
      value: <Currency value={transaction.amount} format="currency" />
    },
    {
      title: <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.totalBalance" />,
      value: <Currency value={transaction.balance} format="currency" />
    }
  ];
  return data;
}

export function TransactionViewModal({ transaction, onClose }: PropTypes) {
  return (
    <If
      condition={!!transaction}
      do={
        <Modal isOpen overlayClassName={styles['overlay']}>
          <div className="w-100 h-100 flex justify-center items-center">
            <Panel>
              <h2 className="kw-text-large kw-weight-bold neutral-900">
                <Text id={'MemberDetail.Transactions.Subsection.Actions.View.Modal.title'} />
              </h2>
              <ul className="flex flex-column neutral-900 kw-text-medium pa0">
                {mapIndexed((item: { title: React.ReactNode; value: React.ReactNode }, i: number) => {
                  return (
                    <li key={i.toString()} className={styles['items']}>
                      <div>{item.title}</div>
                      <div>{item.value}</div>
                    </li>
                  );
                }, transaction && getData(transaction))}
              </ul>
              <Button className="fr" type="primary" size="medium" onClick={onClose}>
                <Text id="MemberDetail.Transactions.Subsection.Actions.View.Modal.Button.close" />
              </Button>
            </Panel>
          </div>
        </Modal>
      }
    />
  );
}
