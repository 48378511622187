import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import styles from '../index.module.scss';

type PrimitiveTheadPropTypes = React.ComponentPropsWithoutRef<'thead'>;
type HeadElement = React.ElementRef<'thead'>;
interface HeadPropTypes extends PrimitiveTheadPropTypes {
  titles: {
    id: string;
    name: React.ReactNode;
    itsI18n?: boolean;
    rowSpan?: number;
    colSpan?: number;
    width?: string;
  }[];
}

export const Head = React.forwardRef<HeadElement, HeadPropTypes>(function Head(
  { className, titles, ...restProps },
  forwardedRef
) {
  return (
    <thead {...restProps} ref={forwardedRef} className={cx(styles['lister-header'], className)}>
      <tr>
        {titles.map(({ id, itsI18n, name, ...restProps }) => (
          <th key={id} scope="col" {...restProps}>
            <If condition={!!itsI18n} do={<Text id={name as string} />} else={name} />
          </th>
        ))}
      </tr>
    </thead>
  );
});
