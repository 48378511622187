import get from 'lodash/fp/get';
import join from 'lodash/fp/join';

import { useCallback, useState, useEffect } from 'react';

import { ModelError, ValueOf, MemberSubstepProps } from 'GlobalTypes';

import { VALID } from '@kwara/lib/src/validator';
import { PhoneNumberVerification } from '@kwara/models/src';
import { StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { phoneNumber as validatePhoneNumber } from '@kwara/lib/src/validator/records';

import { usePermissions } from '../../../../hooks';
import { Store } from '../../../../models/Store/Store';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';
import { VERIFICATION_STATUS, useVerifyPhoneNumber } from './verifyPhoneNumberState/useVerifyPhoneNumber';

export interface ContactPropTypes extends MemberSubstepProps {
  size?: StackChildSize;
  prefix?: string;
  isV1?: boolean;
}

export function useContactActions({
  data,
  addData,
  enableNextButton,
  disableNextButton,
  prefix = '',
  isV1
}: ContactPropTypes) {
  const getName = (name: string) => (prefix ? join('.', [prefix, name]) : name);

  const [mBankingChecked, setMBankingChecked] = useState(() => data.subscribedToMbanking === 'YES');
  const { permission, AppPermissions } = usePermissions();
  const [phoneNumbersVerificationStatus, setPhoneNumbersVerificationStatus] = useState<
    Record<string, ValueOf<typeof VERIFICATION_STATUS>>
  >({});
  const [{ errors: safricomPhoneNoVerError, showSecondaryNumber }, setState] = useState(() => ({
    errors: null,
    success: false,
    showSecondaryNumber: get(getName('secondaryPhoneNumber'), data) != null
  }));
  const store = useSaccoProfileContext() as Store;

  useEffect(() => {
    const values = Object.values(phoneNumbersVerificationStatus);
    const didAnyFailVerificationFail = values.indexOf(VERIFICATION_STATUS.HAS_ERROR) >= 0;
    const didAllVerificationPass = !!values.length && !didAnyFailVerificationFail;

    if (didAnyFailVerificationFail) disableNextButton();
    else if (didAllVerificationPass) enableNextButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumbersVerificationStatus]);

  const updateVerificationStatus = useCallback((key: string, value: ValueOf<typeof VERIFICATION_STATUS>) => {
    setPhoneNumbersVerificationStatus(prev => ({ ...prev, [key]: value }));
  }, []);

  const {
    error: primaryNoError,
    verifyPhoneNumber: verifyPrimaryNo,
    isPhoneNumberInUse: isPrimaryNoInUse,
    namesOfPeopleUsingPhoneNumber: peopleUsingPrimaryNo
  } = useVerifyPhoneNumber({
    name: getName('phoneNumber'),
    onIsLoading: disableNextButton,
    updateVerificationStatus
  });

  const {
    error: secondaryNoError,
    verifyPhoneNumber: verifySecondaryNo,
    resetState: resetSecondaryNoState,
    isPhoneNumberInUse: isSecondaryNoInUse,
    namesOfPeopleUsingPhoneNumber: peopleUsingSecondaryNo
  } = useVerifyPhoneNumber({
    name: getName('secondaryPhoneNumber'),
    onIsLoading: disableNextButton,
    updateVerificationStatus
  });

  const composeErrors = useCallback((...errors: Array<ModelError>) => {
    const reducedErrors = errors.reduce((acc, curr) => {
      if (curr) acc.push(curr);
      return acc;
    }, []);
    return { hasError: !!reducedErrors.length, errors: reducedErrors };
  }, []);

  const onVerifyPhoneNumber = useCallback(
    async ({ target: { value: phoneNumber } }: React.FocusEvent<HTMLInputElement>) => {
      const notVerifiable =
        isV1 ||
        !permission.to(AppPermissions.SafaricomVerify) ||
        !phoneNumber ||
        !store.isSafaricomPhoneNumberValidationLive;

      if (notVerifiable) return;

      const verification = new PhoneNumberVerification({ phoneNumber });

      setState(prevState => ({ ...prevState, errors: null }));

      const didSave = await verification.save();

      if (didSave) setState(prevState => ({ ...prevState, success: true }));
      else setState(prevState => ({ ...prevState, errors: verification.errors, success: false }));
    },
    [AppPermissions.SafaricomVerify, permission, store.isSafaricomPhoneNumberValidationLive, isV1]
  );

  const onSubscribeToMbanking = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setMBankingChecked(target.checked);
      addData({ subscribedToMbanking: target.checked ? 'YES' : 'NO' });
    },
    [addData]
  );

  const onChangePrimaryPhoneNumber = useCallback(
    (phoneNumber: string) => {
      if (validatePhoneNumber(phoneNumber) === VALID) verifyPrimaryNo(phoneNumber);
    },
    [verifyPrimaryNo]
  );

  const onChangeSecondaryPhoneNumber = useCallback(
    ({ target: { value: phoneNumber } }: React.ChangeEvent<HTMLInputElement>) => {
      if (validatePhoneNumber(phoneNumber) === VALID) verifySecondaryNo(phoneNumber);
      else if (!phoneNumber.length) resetSecondaryNoState();
    },
    [resetSecondaryNoState, verifySecondaryNo]
  );

  const onShowAddSecondaryPhoneNumber = useCallback(() => {
    setState(prevState => ({ ...prevState, showSecondaryNumber: true }));
  }, []);

  const composedError = composeErrors(
    safricomPhoneNoVerError,
    ...(primaryNoError?.errors || []),
    ...(secondaryNoError?.errors || [])
  );

  return {
    isKBS: store.isKBS,
    showSecondaryNumber,
    isPrimaryNoInUse,
    isSecondaryNoInUse,
    peopleUsingPrimaryNo,
    peopleUsingSecondaryNo,
    composedError,
    mBankingChecked,
    getName,
    onVerifyPhoneNumber,
    onSubscribeToMbanking,
    onChangePrimaryPhoneNumber,
    onChangeSecondaryPhoneNumber,
    onShowAddSecondaryPhoneNumber
  };
}
