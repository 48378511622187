import React from 'react';
import { Loadable } from '@kwara/components/src/Loadable';
import { useCollectingBank } from '@kwara/models/src/models/request/hooks';

import { BankSelect } from '@kwara/components/src/Form';

export function CollectingBank({ fields }: { fields: { collectingBank: string } }) {
  const r = useCollectingBank();
  return (
    <Loadable {...r}>
      {collectingBanks => (
        <BankSelect
          name={fields.collectingBank}
          banks={collectingBanks}
          titleId="LoanAdd.Repayment.CollectingBank.title"
          labelId={null}
        />
      )}
    </Loadable>
  );
}
