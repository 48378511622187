// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import invoke from 'lodash/fp/invoke';
import invokeArgs from 'lodash/fp/invokeArgs';

import { getTranslationIdForIdentityType } from '@kwara/components/src/Intl';

import { Record, Section } from '../../../components/Summary';
import { type SubStepComponentProps } from '../../../components/Wizard';
import { DocumentList } from '../../../components/DocumentList';

type Props = SubStepComponentProps<{}> & { isV1: boolean };

export default ({ StackChild, data: member, parentUrl, isV1 }: Props) => {
  const editUrl = path => `${parentUrl}/${path}`;

  return (
    <StackChild>
      <Section titleId="AddMember.Step.about" editUrl={editUrl('about/1')}>
        <Record labelId="AddMember.Profile.MemberTitle.label" value={member.title} />
        <Record labelId="AddMember.Profile.Name.label" value={invoke('fullName', member)} />
      </Section>
      <Section titleId="AddMember.Step.personal" editUrl={editUrl('about/2')}>
        {member.gender && (
          <Record labelId="AddMember.Personal.Gender.label" valueId={`AddMember.Personal.Gender.${member.gender}`} />
        )}
        <Record labelId="AddMember.Personal.DateOfBirth.label" value={invoke('formattedDateOfBirth', member)} />
        {member.maritalStatus && (
          <Record
            labelId="AddMember.Personal.MaritalStatus.label"
            valueId={`AddMember.Personal.MaritalStatus.${member.maritalStatus}`}
          />
        )}
      </Section>
      <Section titleId="AddMember.Step.identity" editUrl={editUrl('about/4')}>
        {map(
          r => (
            <Record labelId={getTranslationIdForIdentityType(r.type)} value={r.documentId} key={r.documentId} />
          ),
          member.idDocuments
        )}

        <Record labelId="AddMember.Identity.KRA.label" value={member.kraPin} />
      </Section>
      <Section titleId="AddMember.Step.employment" editUrl={editUrl('about/5')}>
        <Record labelId="AddMember.Employment.Profession.label" value={member.profession} />
        {member.currentlyWorking ? (
          <Record
            labelId="AddMember.Employment.CurrentlyWorking.label"
            valueId={`AddMember.Employment.CurrentlyWorking.${member.currentlyWorking}`}
          />
        ) : null}
        {member.employmentStatus ? (
          <Record
            labelId="AddMember.Employment.EmploymentStatus.label"
            valueId={`Employment.Type.${member.employmentStatus}`}
          />
        ) : null}
        <Record labelId="AddMember.Employment.Employer.label" value={member.employer} />
      </Section>
      <Section titleId="AddMember.Step.kin" editUrl={editUrl('about/6')}>
        {member.nextOfKins &&
          member.nextOfKins
            .filter(kin => !kin.isMarkedForDestruction)
            .map((kin, index) => (
              <Record
                key={index}
                labelId="AddMember.Kin.position"
                values={{ position: index + 1 }}
                value={
                  <span className="pre">
                    {'\n\r'}
                    {kin.name} {'\n\r'}
                    {kin.phoneNumber} {'\n\r'}
                    {kin.relationship}
                  </span>
                }
              />
            ))}
      </Section>
      <Section titleId="AddMember.Step.contact" editUrl={editUrl('contact/1')}>
        <Record labelId="AddMember.Contact.Mobile.label" value={member.phoneNumber} />
        <Record labelId="AddMember.Contact.SecondaryNumber.label" value={member.secondaryPhoneNumber} />
        <Record labelId="AddMember.Contact.Email.label" value={member.email} />
      </Section>
      <Section titleId="AddMember.Step.address" editUrl={editUrl('about/3')}>
        <Record labelId="AddMember.Address.Postal.label" value={invokeArgs('address', ['postal'], member)} />
        <Record labelId="AddMember.Address.Physical.label" value={invokeArgs('address', ['physical'], member)} />
      </Section>
      {isV1 ? null : (
        <Section titleId="AddMember.Step.documents" editUrl={editUrl('documents/1')}>
          <Record value={<DocumentList member={member} contexts={['members']} attachments={member.attachments} />} />
        </Section>
      )}
      {isV1 ? null : (
        <Section titleId="AddMember.Step.notes" editUrl={editUrl('notes/1')}>
          <Record value={member.notes} />
        </Section>
      )}
      <Section titleId="AddMember.Step.paymentFee" editUrl={editUrl('joiningFeePayment/1')}>
        <Record value={member.joiningFeeReference} />
      </Section>
    </StackChild>
  );
};
