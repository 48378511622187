// @flow

import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import range from 'lodash/fp/range';
import reject from 'lodash/fp/reject';

import { type OrganisationT } from '@kwara/models/src';
import { SubscribedCheckbox, SubscribedSelectField, SubscribedSelectCheckboxes } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';
import { useLoanProducts } from '@kwara/models/src/models/request/hooks';

import { useSavingProducts, usePermissions } from '../../../../../hooks';
import { SettingsSectionForm } from '../shared';

const Option = SubscribedSelectField.Option;

const toOptions = products => map(p => ({ value: p.id, labelId: p.name }), products);

export const LoanRecovery = ({ refetch, organisation }: { refetch: () => void, organisation: OrganisationT }) => {
  const r1 = useSavingProducts();
  const r2 = useLoanProducts();
  const { permission, AppPermissions } = usePermissions();

  const permitted = permission.to(AppPermissions.UpdateOrganisationSettings);
  const initialValues = get('settings.loan_recovery', organisation) || {
    enabled_loan_product_ids: []
  };

  const onSubmit = async data => {
    const params = {
      settings: {
        loan_recovery: data
      }
    };

    await organisation.update(params);
    refetch();
  };

  return (
    <SettingsSectionForm
      onSubmit={onSubmit}
      permitted={permitted}
      initialValues={initialValues}
      titleId="Settings.Sacco.LoanRecovery.title"
      render={({ formProps, values }) => (
        <>
          <SubscribedCheckbox
            disabled={!permitted}
            name="enabled"
            labelId="Settings.Sacco.LoanRecovery.enableLoanRecovery.label"
          />
          <fieldset disabled={!values.enabled} className="bn">
            <Loadable {...r1}>
              {savingProducts => (
                <SubscribedSelectField
                  name="savings_recovery_product_id"
                  labelId="Settings.Sacco.LoanRecovery.savings_recovery_product_id.label"
                >
                  <Option
                    value=""
                    translationId="Settings.Sacco.LoanRecovery.savings_recovery_product_id.options.default"
                  />
                  {map(
                    product => (
                      <Option key={product.id} value={product.id}>
                        {product.name}
                      </Option>
                    ),
                    reject(product => product.isBridgingProduct || !product.activated, savingProducts)
                  )}
                </SubscribedSelectField>
              )}
            </Loadable>
            <Loadable {...r2}>
              {loanProducts => (
                <SubscribedSelectCheckboxes
                  disabled={!values.enabled}
                  labelId="Settings.Sacco.LoanRecovery.enabled_loan_product_ids.label"
                  name="enabled_loan_product_ids"
                  options={toOptions(loanProducts)}
                  formProps={formProps}
                  currentValues={values}
                />
              )}
            </Loadable>
            <SubscribedSelectField
              name="threshold_duration"
              labelId="Settings.Sacco.LoanRecovery.threshold_duration.label"
            >
              <Option translationId="Settings.Sacco.LoanRecovery.threshold_duration.options.default" />
              {map(
                v => (
                  <Option value={v} key={v}>
                    {v}
                  </Option>
                ),
                range(0, 31)
              )}
            </SubscribedSelectField>
          </fieldset>
        </>
      )}
    />
  );
};
