import { useMemo, useCallback } from 'react';

import { Data } from '@kwara/components/src/Form/Combobox/Combobox';

import { useFilterContext } from '../..';

export function useComboboxBaseFilter(filterType: string) {
  const { filters, onFilterValue, getValueFor } = useFilterContext();

  const currentFilters = filters[filterType];
  const value = getValueFor(filterType) as string;

  const data = useMemo<Data[]>(
    () => currentFilters.map(({ name, value }) => ({ label: name, value: value.toString() })),
    [currentFilters]
  );

  const onChange = useCallback(
    (value: string) => {
      const selectedFilter = currentFilters.find(data => data.value.toString() === value);

      onFilterValue({ filterName: filterType, selectedValue: selectedFilter });
    },
    [currentFilters, filterType, onFilterValue]
  );

  return { value, data, onChange };
}
