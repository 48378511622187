import React from 'react';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { useJournalEntryFilters } from '../JournalEntry/useJournalEntryFilters';

type PropTypes = {
  children: React.ReactNode;
};

export const EntriesOverviewListV2Root = ({ children }: PropTypes) => {
  const filter = useJournalEntryFilters();

  return <OverviewListV2.Root {...filter}>{children}</OverviewListV2.Root>;
};
