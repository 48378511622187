// @flow

import * as React from 'react';

import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { Text } from '@kwara/components/src/Intl';
import { type MemberType } from '@kwara/models/src';

import { EditableSection, type EditableConfig } from '../../EditableSection/v1/EditableSection';
import { Documents as DocumentsForm } from '../../../pages/MemberAdd/components/Documents';
import { DocumentList } from '../../../components/DocumentList';

const config = { Component: DocumentsForm };

export const Documents = ({ member, readOnly }: EditableConfig<MemberType>) => {
  return (
    <EditableSection
      initialData={member}
      readOnly={readOnly}
      config={config}
      onSave={data => {
        // this one should work except it doesn't
        //await data.save({ with: ['attachments'] });
        // so we use this hack
        return saveAttachments(member, data.attachments);
      }}
    >
      <DocumentList member={member} contexts={['members', 'loans']} attachments={member.attachments} />
    </EditableSection>
  );
};

Documents.Title = () => <Text id="MemberDetail.documents" />;
