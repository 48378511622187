import React from 'react';
import invoke from 'lodash/fp/invoke';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { SasraReportT } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Date, DateTime, Text } from '@kwara/components/src/Intl';
import Table, { Row, Heading, Footer, Cell } from '../../../../components/Table';

interface ReportsTableT {
  isLoading: boolean;
  hasMore: boolean;
  onNext: () => void;
  reports: SasraReportT[];
  onDownload(report: SasraReportT): void;
  isDownloading: boolean;
}

export function ReportsTable({ reports = [], isLoading, hasMore, onNext, onDownload, isDownloading }: ReportsTableT) {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="SasraReports.Table.header.sasraReport" />
          <Heading translationId="SasraReports.Table.header.startDate" />
          <Heading translationId="SasraReports.Table.header.endDate" />
          <Heading translationId="SasraReports.Table.header.user" />
          <Heading translationId="SasraReports.Table.header.createdAt" />
          <Heading translationId="SasraReports.Table.header.state" />
          <Heading translationId="SasraReports.Table.header.actions" />
        </Row>
      }
      footer={<Footer colSpan={7} hasMore={hasMore} isLoading={isLoading} onNext={onNext} items={reports} />}
    >
      {reports.map(report => (
        <Row key={report.id}>
          <Cell>{report.name}</Cell>
          <Cell>
            <Date value={report.startDate} />
          </Cell>
          <Cell>
            <Date value={report.endDate} />
          </Cell>

          <Cell>{invoke('createdBy.fullName', report)}</Cell>
          <Cell>
            <DateTime value={report.createdAt} />
          </Cell>
          <Cell>
            <StatusTag state={report.state} />
          </Cell>
          <Cell>
            <If
              condition={report.state === 'complete'}
              do={
                <button
                  disabled={isDownloading}
                  onClick={() => onDownload(report)}
                  className={cx('bn', { pointer: !isDownloading, 'o-70': isDownloading })}
                >
                  <Text id="SasraReports.Table.button.download" />
                </button>
              }
            />
          </Cell>
        </Row>
      ))}
    </Table>
  );
}
