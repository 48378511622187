import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';

import styles from './index.module.scss';

type HeaderSubHeaderPropTypes = {
  titleId: string;
  subtitleId?: string;
  action?: React.ReactNode;
};

export const HeaderSubHeader = ({ titleId, subtitleId, action }: HeaderSubHeaderPropTypes) => {
  return (
    <div className={styles['header']}>
      <div>
        <PageHeading translationId={titleId} />
        <If
          condition={!!subtitleId}
          do={
            <p className="grey-400 kw-text-medium mb1">
              <Text id={subtitleId} />
            </p>
          }
        />
      </div>

      <If condition={!!action} do={<div className="ml-auto">{action}</div>} />
    </div>
  );
};
