import * as React from 'react';
import { Helmet } from 'react-helmet';

export function Body() {
  /*********************************************************************
   * The key below is to prevent a maximum call stack exceeded error
   * that would happen in some e2e tests.
   * Ref: https://github.com/nfl/react-helmet/issues/373
   *********************************************************************/
  return (
    <Helmet key={window.location.href}>
      <body />
    </Helmet>
  );
}
