//@flow
import * as React from 'react';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import _includes from 'lodash/fp/includes';

import { getCurrentDate, formatIsoDate, subDays } from '@kwara/lib/src/dates';
import { Date } from '@kwara/components/src/Intl';
import { Card } from '@kwara/components/src/Card';

import { MemberTransactionsTable } from '../MemberTransactionsTable';
import OverviewList from '../../../../components/OverviewList';
import { useDeprecatedFilter, useFilteredLoanTransactions, useFilteredSavingTransactions } from '../../../../hooks';

function formatFilters(filterValues, isLoan) {
  const copy = { ...filterValues };
  if (isLoan) {
    copy.loan_id = copy.account_id;
  } else {
    copy.savings_id = copy.account_id;
  }
  return copy;
}

const useFilteredTransactions = (includes, _where, isLoan) => {
  const activeAccountId = _where.account_id;
  const where = formatFilters(_where, isLoan(activeAccountId));

  const loanTransactionsR = useFilteredLoanTransactions(includes, where);
  const savingsTransactionsR = useFilteredSavingTransactions(includes, where);

  return isLoan(activeAccountId) ? loanTransactionsR : savingsTransactionsR;
};

const includes = [];

const activeLoanProducts = loans => {
  return loans.length === 0;
};

const useTransactions = (loans, savings) => {
  const accounts = [...loans, ...savings];

  const prior = formatIsoDate(subDays(getCurrentDate(), 30));
  const today = formatIsoDate(getCurrentDate());

  // initial filter values
  const defaultAccountId = get('[0].id', accounts);
  const { filterValue: filterValues, onFilterChange } = useDeprecatedFilter(filter => filter, {
    from: prior,
    to: today,
    account_id: defaultAccountId
  });

  const isLoan = id =>
    _includes(
      id,
      map(loan => loan.id, loans)
    );
  const r = useFilteredTransactions(includes, filterValues, isLoan);

  const onDateChange = (key: 'to' | 'from', val: Date) => {
    if (!val) {
      return;
    }

    onFilterChange({
      ...filterValues,
      [key]: formatIsoDate(val)
    });
  };

  const onAccountIdChange = (val: string) => {
    onFilterChange({
      ...filterValues,
      account_id: val
    });
  };

  return {
    filterValues,
    onDateChange,
    onAccountIdChange,
    r
  };
};

const createFiltersFromAccounts = (loans, savings) => {
  const sorted = sortBy(account => account.product.name, [...loans, ...savings]);

  if (size(sorted) > 0) {
    return [
      ...map(
        loan => ({
          value: loan.id,
          label: `${loan.product.name} [${loan.id}]`
        }),
        sorted
      )
    ];
  } else {
    return [{ value: '', translationId: 'TransactionsTable.filter.NoTransactions' }];
  }
};

export const FilterableTransactionOverview = ({ StackChild, loans, savings }) => {
  const { filterValues, onAccountIdChange, onDateChange, r } = useTransactions(loans, savings);

  const items = !r.isLoading && !activeLoanProducts(loans) ? r.data : [];

  return (
    <StackChild size="widest">
      <Card border={true} className="nowrap tl">
        <OverviewList
          labelId="TillTransactionsList.filteredCount"
          filterLabelId="OverviewList.filterBy"
          filterBy={get('account_id', filterValues)}
          filters={createFiltersFromAccounts(loans, savings)}
          totalNumResults={size(items)}
          onFilterChange={onAccountIdChange}
          items={items}
          table={<MemberTransactionsTable transactions={items} {...r} />}
          dateRangeConfig={{
            dateValues: filterValues,
            disabled: !filterValues.account_id,
            onDateChange,
            disabledDays: {
              after: getCurrentDate()
            }
          }}
        />
      </Card>
    </StackChild>
  );
};
