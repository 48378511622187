import * as React from 'react';

import { isNonProdEnv } from '@kwara/lib/src/utils';
import { If } from '@kwara/components/src/If/If';

type Props = {
  children: React.ReactNode;
};

export function VisibleOnlyToSandbox(props: Props) {
  return <If condition={isNonProdEnv} do={props.children} />;
}
