import { ValueOf } from 'GlobalTypes';

const passwordExpiryAlerterTypes = {
  ON_PASSWORD_EXPIRED: '@core/ON_PASSWORD_EXPIRED',
  ON_PASSWORD_ABOUT_TO_EXPIRE: '@core/ON_PASSWORD_ABOUT_TO_EXPIRE',
  ON_RESET: '@core/ON_RESET'
};

export const STATUS = Object.freeze({
  isExpired: 'isExpired',
  isAboutToExpire: 'isAboutToExpire'
});

type Status = ValueOf<typeof STATUS>;
type PasswordExpiryAlerterState = {
  shown: boolean;
  token: string | null;
  status: Status | null;
  daysCount?: string;
  onContinueWithoutPasswordChange?: Function;
};
type OnPasswordAboutToExpirePayloadType = {
  token?: string;
  daysCount?: string;
  onContinueWithoutPasswordChange?: Function;
};
type OnPasswordExpiredPayloadType = {
  token: string;
};
type ActionType = {
  type: ValueOf<typeof passwordExpiryAlerterTypes>;
  payload?: OnPasswordAboutToExpirePayloadType;
};

function passwordExpiryAlerterReducer(_: PasswordExpiryAlerterState, { type, payload }: ActionType) {
  switch (type) {
    case passwordExpiryAlerterTypes.ON_PASSWORD_EXPIRED: {
      return {
        token: payload.token,
        status: STATUS.isExpired,
        shown: true
      };
    }

    case passwordExpiryAlerterTypes.ON_PASSWORD_ABOUT_TO_EXPIRE: {
      return {
        status: STATUS.isAboutToExpire,
        token: payload.token,
        daysCount: payload.daysCount,
        onContinueWithoutPasswordChange: payload.onContinueWithoutPasswordChange,
        shown: true
      };
    }

    case passwordExpiryAlerterTypes.ON_RESET: {
      return {
        shown: false,
        token: null,
        status: null,
        daysCount: undefined,
        onContinueWithoutPasswordChange: undefined
      };
    }

    default: {
      const error = new Error();
      error.name = 'PasswordExpiryAlerterReactReducerTypeError';
      error.message = `🚨 Unhandled type ${type}`;
      Error.captureStackTrace?.(passwordExpiryAlerterReducer);
      throw error;
    }
  }
}

export type { Status, OnPasswordExpiredPayloadType, OnPasswordAboutToExpirePayloadType };

export default {
  Reducer: passwordExpiryAlerterReducer,
  Types: passwordExpiryAlerterTypes
};
