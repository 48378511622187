import * as React from 'react';
import get from 'lodash/fp/get';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { LoanDisbursementType, DisbursementStates } from '@kwara/models/src/models/LoanDisbursement';
import Banner from '@kwara/components/src/Banner';

import { useHasAlreadyDisbursed } from './useHasAlreadyDisbursed';

export function DisbursementStatusBanner({ disbursement }: { disbursement: LoanDisbursementType | undefined }) {
  const hasAlreadyDisbursed = useHasAlreadyDisbursed(disbursement);
  const disbursementState = get('state', disbursement);

  let translationId: string = get('state', disbursement);
  if (disbursementState === DisbursementStates.PENDING_INITIAL_APPROVALS) {
    if (hasAlreadyDisbursed) {
      translationId = 'same_disburser';
    } else {
      return null;
    }
  }

  return (
    <If
      condition={!!disbursement}
      do={
        <Banner text={<Text id={`LoanDisbursal.Approvals.completion.subtitle.${translationId}`} />} state="warning" />
      }
    />
  );
}
