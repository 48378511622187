// @flow

import React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import style from './index.module.scss';

type FileDropzonePropTypes = {
  file: File,
  onChange: any => Promise<void>,
  disabled?: boolean,
  name?: string,
  accept?: string[],
  classNames?: string,
  title?: string,
  label?: React.ReactNode
};

export const FileDropzone = ({
  file,
  onChange,
  name = 'file',
  accept,
  title,
  classNames,
  label,
  disabled
}: FileDropzonePropTypes) => {
  return (
    <div className={classNames} title={title} disabled={disabled}>
      <label htmlFor={name} className={cx('grey-400 kw-text-regular')}>
        {label ?? <Text id="FileDropzone.selectFileLabel" />}
      </label>
      <div
        className={cx(style.Dropzone, 'br2 grey-400 kw-text-regular mb4', {
          [style.DropzoneDirty]: file
        })}
      >
        <input
          type="file"
          name={name}
          className={style.InputFile}
          accept={accept}
          disabled={disabled}
          onChange={onChange}
        />
        {file ? (
          <Text id="FileDropzone.uploadedFile" values={{ fileName: file.name }} />
        ) : (
          <> {disabled ? <Text id="FileDropzone.selectFileInfo" /> : <Text id="FileDropzone.selectFileText" />}</>
        )}
      </div>
    </div>
  );
};
