import includes from 'lodash/fp/includes';
import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';

import { LoanType, LoanFields, TransactionChannels } from '@kwara/models/src';
import { fields as remittanceFields } from '@kwara/models/src/models/Remittance';

const fields = remittanceFields.remittance;

const { disbursement } = LoanFields;

const bankFields = [fields.bank, fields.branch, fields.account];

const repaymentFields = {
  [TransactionChannels.bankTransfer]: bankFields,
  [TransactionChannels.directDebit]: [...bankFields, fields.collectingBank],
  [TransactionChannels.payrollDeduction]: ['member.employer', 'member.staffId']
};

const excludedRepaymentFields = curry<LoanType, string, boolean>((loan: LoanType, fieldName: string) => {
  const repaymentMode = get(fields.mode, loan);
  return !includes(fieldName, repaymentFields[repaymentMode]);
});

const disbursementFields = {
  [TransactionChannels.bankTransfer]: [disbursement.bank, disbursement.branch, disbursement.account]
};

const excludedDisbursementFields = curry<LoanType, string, boolean>((loan: LoanType, fieldName: string) => {
  const disbursementMode = get(LoanFields.disbursement.mode, loan);
  return !includes(fieldName, disbursementFields[disbursementMode]);
});

export { excludedRepaymentFields, excludedDisbursementFields };
