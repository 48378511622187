import * as React from 'react';
import { Form } from 'react-final-form';

import { ExpanderRow } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { Stack } from '@kwara/components/src/Stack';
import Button from '@kwara/components/src/Button';
import { SubscribedPasswordField } from '@kwara/components/src/Form';
import createValidator from '@kwara/lib/src/validator';
import { MpesaCredentials as MpesaCred, MpesaCredentialsT } from '@kwara/models/src/models/MpesaCredentials';

import { SubsectionTitle } from '../../../../../components/DetailSubsection';

const DownloadInstructions = () => (
  <div className="kw-text-medium">
    <Text id="Settings.manageCredentials.mpesa.downloadGuide.help" />{' '}
    <Link size="medium" type="primary" underline={false} onClick={null}>
      <Text id="Settings.manageCredentials.mpesa.downloadGuide.title" />
    </Link>
  </div>
);

const SectionTitle = ({ titleId }) => (
  <h1 className={'kw-text-super-large kw-weight-regular grey-400'}>
    <Text id={titleId} />
  </h1>
);

export const MpesaCredentials = ({ credentials }: { credentials: MpesaCredentialsT }) => {
  /* eslint-disable */
  const [errors, setErrors] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const formatPayload = (data: FormData) => {
    const {
      apiPublicKey,
      apiPrivateKey,
      safcomPassKey,
      c2bPaybillNo,
      c2bTransactionChannel,
      b2cPaybillNo,
      initiatorUser,
      initiatorPass
    } = data;

    return {
      c2b_public_key: c2bPublicKey,
      c2b_private_key: c2bPrivateKey,
      api_passkey: apiPasskey,
      business_short_code: businessShortCode,
      settings: {
        c2b_channel: c2bTransactionChannel
      },
      b2c_paybill: b2cPaybillNo,
      initiator_username: initiatorUser,
      initiator_password: initiatorPassword
    };
  };

  const onCredentials = async (data: FormData) => {
    const payload = formatPayload(data);

    try {
      setIsLoading(true);

      const mpesaCreds = new MpesaCred();
      const didSave = await mpesaCreds.sendCredentials(credentials.id, payload);

      if (!didSave) {
        throw mpesaCreds.errors;
      }
    } catch (err) {
      setErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const validationRules = {
    apiPublicKey: {
      isRequired: () => true
    },
    apiPrivateKey: {
      isRequired: () => !credentials.isC2bPrivateKeyFilled
    }
  };

  return (
    <>
      <SubsectionTitle>
        <Text id="Settings.manageCredentials.mpesa.titleId" />
      </SubsectionTitle>
      <ExpanderRow ariaLabel={'MpesaCredentials'}>
        <DownloadInstructions />
        <br />
        <Stack.Child>
          <Form
            validate={createValidator(validationRules)}
            initialValues={credentials}
            onSubmit={onCredentials}
            render={({ handleSubmit, invalid }) => (
              <form className="pb5" onSubmit={handleSubmit}>
                <fieldset style={{ border: 'none' }} disabled="disabled">
                  <div className="flex flex-column items-start">
                    <SectionTitle titleId="Settings.manageCredentials.mpesa.API.section.titleId" />

                    <SubscribedPasswordField
                      name="c2bPublicKey"
                      titleId="Settings.manageCredentials.mpesa.apiPublicKey.titleId"
                      size="regular"
                      className="black"
                      required
                    />

                    <SubscribedPasswordField
                      name="c2bPrivateKey"
                      titleId="Settings.manageCredentials.mpesa.apiPrivateKey.titleId"
                      size="regular"
                      className="black"
                      required={!credentials.isC2bPrivateKeyFilled}
                    />

                    <SectionTitle titleId="Settings.manageCredentials.mpesa.C2B.section.titleId" />

                    <SubscribedPasswordField
                      name="apiPasskey"
                      titleId="Settings.manageCredentials.mpesa.safcomPassKey.titleId"
                      size="regular"
                      className="black"
                    />

                    <SubscribedPasswordField
                      name="businessShortCode"
                      titleId="Settings.manageCredentials.mpesa.c2bPaybillNo.titleId"
                      size="regular"
                      className="black"
                    />

                    {/* defaults to mobile_money if empty */}
                    <SubscribedPasswordField
                      name="settings.c2b_channel"
                      titleId="Settings.manageCredentials.mpesa.c2bTransactionChannel.titleId"
                      size="regular"
                      className="black"
                    />
                    <SectionTitle titleId="Settings.manageCredentials.mpesa.B2C.section.titleId" />

                    <SubscribedPasswordField
                      name="b2cPaybill"
                      titleId="Settings.manageCredentials.mpesa.b2cPaybillNo.titleId"
                      size="regular"
                      className="black"
                    />

                    <SubscribedPasswordField
                      name="initiatorUsername"
                      titleId="Settings.manageCredentials.mpesa.initiatorUser.titleId"
                      size="regular"
                      className="black"
                    />

                    <SubscribedPasswordField
                      name="initiatorPassword"
                      titleId="Settings.manageCredentials.mpesa.initiatorPass.titleId"
                      size="regular"
                      className="black"
                    />
                    <Button
                      hidden
                      type="primary"
                      isSubmit
                      disabled={isLoading || invalid}
                      size="medium"
                      glyphRightId={isLoading ? Button.Glyphs.Spinner : null}
                    >
                      <Text id="Settings.manageCredentials.save" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            )}
          />
        </Stack.Child>
      </ExpanderRow>
    </>
  );
};
