// @flow
import * as React from 'react';
import cx from 'classnames';

import { Completion } from '@kwara/components/src/Completion';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import styles from './Completion.module.css';

export const LoanCompletion = ({ onConfirm, onCancel, analyticsId, data }) => {
  const { member } = data;

  return (
    <Completion
      onConfirm={onConfirm}
      titleId="LoanAdd.Completion.title"
      subtitle={<Text id="LoanAdd.Completion.subtitle" values={{ val: member.fullName() }} />}
      type="good"
      analyticsId={analyticsId}
      buttonTextId="LoanAdd.Completion.Print.button"
    >
      <Button className={cx(styles.Button, 'mt3')} onClick={onCancel} type="secondary">
        <Text id="LoanAdd.Completion.Skip.button" />
      </Button>
    </Completion>
  );
};
