import React from 'react';

import Button from '@kwara/components/src/Button';
import { Text, TranslationId } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import Visible from '../../Visible';

import styles from '../index.module.css';

export function Heading({
  translationId,
  heading,
  ...props
}: {
  translationId?: TranslationId;
  heading?: React.ReactNode;
  values?: { [id: string]: string | number | null };
}) {
  return (
    <h1 className="kw-text-extra-large kw-weight-bold mb0">
      {translationId ? <Text id={translationId} {...props} /> : heading}
    </h1>
  );
}

export function ActionableHeading({
  headerId,
  headerValues,
  heading,
  actions,
  subheading,
  backButton
}: {
  headerId?: string;
  headerValues?: { [id: string]: any };
  heading?: React.ReactNode;
  actions: (React.ReactElement<typeof Button> | React.ReactElement<typeof Visible>)[] | React.ReactElement;
  subheading?: React.ReactNode;
  backButton?: React.ReactElement<typeof Button>;
}) {
  return (
    <div className={`flex items-center ${styles.ActionableHeading}`}>
      <div>
        <div className="mb4">{backButton}</div>
        <If
          condition={!!headerId}
          do={<Heading translationId={headerId} values={headerValues} />}
          else={<If condition={!!heading} do={<Heading heading={heading} />} />}
        />
        {subheading}
      </div>
      {actions ? <div className={`flex ml-auto ${styles.ActionsBar}`}>{actions}</div> : null}
    </div>
  );
}
