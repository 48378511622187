import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';
import { useIntl } from 'react-intl';

import { FilterConfig, OnFilterValue } from 'FilterTypes';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { LoanBaseStates, LoanType } from '@kwara/models/src';

import LoanListTable from '../LoanListTable';
import Visible from '../../../../components/Visible';
import OverviewListV2 from '../../../../components/OverviewListV2';

import { loanPath } from '../../../../lib/urls';
import { usePermissions } from '../../../../hooks';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { ExportButton } from '../ExportButton/ExportButton';

import { StatusFilter } from '../../../Members/components/MemberList/StatusFilter';

const CreateLoanButtonWithPermissions = withRouter(() => {
  const { AppPermissions } = usePermissions();
  return (
    <Visible to={AppPermissions.AddLoans}>
      <Button size="regular" type="primary" to={loanPath({ action: 'create' })}>
        <Text id="LoanList.create" />
      </Button>
    </Visible>
  );
});

type Props = {
  hasMore?: boolean;
  loans: LoanType[];
  loading?: boolean;
  errors: Object[];
  totalNumResults: number;
  onLoadMoreData(): void;
  onFilterChange: OnFilterValue;
  isV1?: boolean;
};

export default function LoanList({
  hasMore = false,
  loading = false,
  loans = [],
  totalNumResults,
  onFilterChange,
  isV1,
  ...rest
}: Props) {
  const { formatMessage } = useIntl();
  const filters = useMemo<FilterConfig>(() => {
    if (isV1) return {};

    return {
      [FILTER_TYPES.STATUS]: {
        data: [
          {
            value: '',
            name: formatMessage({ id: 'LoanList.noFilter' }),
            belongsTo: FILTER_TYPES.STATUS
          },
          ...map(LoanBaseStates, value => ({
            value,
            name: formatMessage({ id: `StatusTag.${value}` }),
            belongsTo: FILTER_TYPES.STATUS
          }))
        ],
        page: StatusFilter
      }
    };
  }, [formatMessage, isV1]);

  return (
    <OverviewListV2.Root filters={filters} disabled={loading} onFilterValue={onFilterChange}>
      <OverviewListV2
        withFilter={!isV1}
        actions={[<CreateLoanButtonWithPermissions key="create-loan-button" />]}
        headerId="LoanList.heading"
        labelId="LoanList.filteredCount"
        exportButton={<ExportButton />}
        items={loans}
        table={
          <LoanListTable
            {...rest}
            hasMore={hasMore}
            groupBy={null}
            loading={loading}
            loans={loans}
            orderBy={null}
            isV1={isV1}
          />
        }
        totalNumResults={totalNumResults}
      />
    </OverviewListV2.Root>
  );
}
