import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

type ContentPropTypes = { nameId: string; icon: React.ForwardRefExoticComponent<any> };

export function Content({ icon: Icon, nameId }: ContentPropTypes) {
  return (
    <>
      <span>
        <Icon />
      </span>

      <span>
        <Text id={nameId} />
      </span>
    </>
  );
}
