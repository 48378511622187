import React from 'react';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { useCheckableJournalEntryFilters } from '../CheckableJournalEntry/useCheckableJournalEntryFilters';

type PropTypes = {
  children: React.ReactNode;
};

export const PendingOverviewListV2Root = ({ children }: PropTypes) => {
  const filter = useCheckableJournalEntryFilters();

  return <OverviewListV2.Root {...filter}>{children}</OverviewListV2.Root>;
};
