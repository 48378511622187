import { ReconciliationStatement, IncludesT } from '@kwara/models/src';

import { useOverviewList, fetchWithPagination } from '../base';

const defaultReconciliationStatementsIncludes = ['gl_account'];
async function fetchReconciliationStatements(opts) {
  return fetchWithPagination(ReconciliationStatement, opts);
}

export function usePaginatedReconciliationStatements(includes: IncludesT = defaultReconciliationStatementsIncludes) {
  return useOverviewList({
    queryKey: 'reconciliation_statements',
    fetchFn: fetchReconciliationStatements,
    includes,
    select: null
  });
}
