//@flow
import React from 'react';

import Asset from '@kwara/components/src/Asset';
import { useDynamicPlaceholder } from '@kwara/lib/src/hooks/useDynamicPlaceholder';

import { TextField } from '../Form';

export interface Props extends HTMLInputElement {
  name: string,
  value: string,
  searching: boolean,
  onChange: (evt: SyntheticInputEvent<>) => void
}

export default ({ searching, ...props }: Props) => {
  const placeholderId = useDynamicPlaceholder([
    'MemberSearch.Placeholder.name',
    'MemberSearch.Placeholder.phoneNumber',
    'MemberSearch.Placeholder.id'
  ]);

  return (
    <TextField
      clearable
      size="regular"
      className="bg-white"
      placeholderId={placeholderId}
      leftGlyph={Asset.Glyphs.Search}
      rightGlyph={searching ? Asset.Glyphs.Spinner : null}
      {...props}
    />
  );
};
