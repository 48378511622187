import React, { useMemo } from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import Banner from '@kwara/components/src/Banner';

import { ERRORS } from '@kwara/lib/src/validator';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Currency } from '@kwara/components/src/Intl';

import { LoanTopupFormTypes } from '../..';
import { checkIsSecuritiesValid, getSecurityAmounts } from './utils';

import Guarantors from '../../../../components/Guarantors/Guarantors';
import Collaterals from '../../../Loan/LoanAdd/components/SecurityV2/components/Collaterals/Collaterals';

function Security(props: LoanSubstepProps) {
  const { securitiesTotal, requiredSecuritiesThreshold } = getSecurityAmounts(props.formProps.values);

  const errorValues = useMemo(() => {
    const securityAmounts = <Currency format="currency" value={securitiesTotal} />;
    const minSecurityAmount = <Currency format="currency" value={requiredSecuritiesThreshold} />;

    return { securityAmounts, minSecurityAmount };
  }, [requiredSecuritiesThreshold, securitiesTotal]);

  return (
    <>
      <If
        condition={!checkIsSecuritiesValid(props.formProps.values)}
        do={
          <Banner
            state="error"
            className="mb3"
            text={<Text id="LoanTopup.Security.Error.lowerSecuritiesAmounts" values={errorValues} />}
          />
        }
      />
      <Guarantors.Component {...props} withControls />
      <Collaterals.Component {...props} />
    </>
  );
}

export default {
  Component: Security,
  validate(args: LoanTopupFormTypes) {
    return {
      securities: {
        custom(_: string, allData: LoanTopupFormTypes) {
          if (!checkIsSecuritiesValid?.(allData)) return ERRORS.rangeUnderflow;
          return null;
        }
      },
      ...Guarantors.validate({ ...args }),
      ...Collaterals.validate({ ...args })
    };
  }
};
