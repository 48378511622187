//@flow

import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import { type TillSessionT, type TillSessionStatusT } from '@kwara/models/src';
import { DateTime } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import { tellerPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';
import { Currency } from '../../../../components/Currency';

export const Orders = {
  updated: 'updatedAt'
};

type Order = $Values<typeof Orders>;

type FormattedTillT = {
  id: string,
  tellerName: string,
  updated: string,
  openingBalance: number,
  currentBalance: number,
  closingBalance: number,
  status: TillSessionStatusT
};

const orderTillSessionsByAttribute = (tillSessions: TillSessionT[], attribute: Order): TillSessionT[] =>
  orderBy<TillSessionT>(tillSessions, [(tillSession: TillSessionT) => [tillSession[attribute]]], ['desc']);

const formattedTill = (tillSession: TillSessionT): FormattedTillT => ({
  id: tillSession.id,
  tillName: get('till.name', tillSession),
  tellerName: tillSession.tellerName,
  updated: tillSession.updatedAt,
  openingBalance: tillSession.openingBalance,
  currentBalance: tillSession.currentBalance,
  closingBalance: tillSession.closingBalance,
  status: tillSession.status
});

const TillRow = ({ tillSession, pathTo }: { tillSession: TillSessionT, pathTo: string }) => {
  const t = formattedTill(tillSession);

  return (
    <table.Row className="kw-text-small" to={pathTo}>
      <table.Cell width="60px" to={pathTo} className="grey-400 kw-numeric">
        {t.id}
      </table.Cell>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {t.tillName}
      </table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {t.tellerName}
      </table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={t.updated} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={t.openingBalance} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={t.currentBalance} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={t.closingBalance} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <StatusTag state={t.status} />
      </table.Cell>
    </table.Row>
  );
};

export const TellerListTable = ({
  hasMore,
  errors,
  loading,
  tillSessions = [],
  onLoadMoreData
}: {
  hasMore: boolean,
  errors: Object[],
  loading: boolean,
  tillSessions: TillSessionT[],
  onLoadMoreData: () => void
}) => {
  const orderTillSessions = orderTillSessionsByAttribute(tillSessions, 'updatedAt');

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="TellerListTable.id" />
          <table.Heading translationId="TellerListTable.till" />
          <table.Heading translationId="TellerListTable.name" />
          <table.Heading translationId="TellerListTable.lastUpdated" />
          <table.Heading align="right" translationId="TellerListTable.openingBalance" />
          <table.Heading align="right" translationId="TellerListTable.currentBalance" />
          <table.Heading align="right" translationId="TellerListTable.closingBalance" />
          <table.Heading translationId="TellerListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={8}
          isLoading={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={tillSessions}
          translationBaseId="TellerListTable"
        />
      }
    >
      {map(orderTillSessions, (tillSession: TillSessionT) => (
        <TillRow key={tillSession.id} tillSession={tillSession} pathTo={tellerPath({ id: tillSession.id })} />
      ))}
    </Table>
  );
};
