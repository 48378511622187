import React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoanProduct } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';

const onSubmit = async product => {
  await product.save({ with: ['productConfig'] });
};

export const LoanProductEdit = props => {
  const r = useLoanProduct(props.match.params.productId);

  return (
    <Loadable {...r}>
      {product => {
        return (
          <Wizard
            baseUrl={props.baseUrl}
            history={history}
            initialData={product}
            cancelReturnsTo={settingPath({ action: 'loans' })}
            currentStep={props.match.params.step}
            currentSubStep={props.match.params.subStep != null ? parseInt(props.match.params.subStep, 10) : null}
            onSubmit={onSubmit}
            steps={steps}
            startId="product"
            titleId="LoanProductEdit.titleId"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
