import React from 'react';
import capitalize from 'lodash/fp/capitalize';

import { Loadable, LoadingSkeleton, RequestProps } from '@kwara/components/src/Loadable';
import { Currency, Number, Percent } from '@kwara/components/src/Intl';

import { DashboardIndicatorCard } from '../../../../../../../components/DashboardIndicatorCard';
import { IndicatorsContainer } from '../../../../../../../components/IndicatorsContainer';
import { DividendReportsT } from '../../../../../../../models';
import { ReportType } from '../../../useReportType';

export function Indicators({
  reportResponse,
  reportType
}: {
  reportResponse: RequestProps<DividendReportsT>;
  reportType: ReportType;
}) {
  return (
    <IndicatorsContainer>
      <DashboardIndicatorCard
        titleId={`Finance.DividendReports.Contributions.indicators.${reportType}.calculatedNetDividendAmount`}
        value={
          <Loadable loading={<LoadingSkeleton />} {...reportResponse}>
            {(report: DividendReportsT) => <Currency format="currency" value={report.calculatedNetDividendAmount} />}
          </Loadable>
        }
      />
      <DashboardIndicatorCard
        titleId={`Finance.DividendReports.Contributions.indicators.${reportType}.allocatedDividendAmount`}
        value={
          <Loadable loading={<LoadingSkeleton />} {...reportResponse}>
            {(report: DividendReportsT) => <Currency format="currency" value={report.allocatedDividendAmount} />}
          </Loadable>
        }
      />
      <DashboardIndicatorCard
        titleId="Finance.DividendReports.Contributions.indicators.totalNumberOfMembers"
        value={
          <Loadable loading={<LoadingSkeleton />} {...reportResponse}>
            {(report: DividendReportsT) => <Number value={report.totalNumberOfMembers} />}
          </Loadable>
        }
      />
      <DashboardIndicatorCard
        titleId={`Finance.DividendReports.Contributions.indicators.${reportType}.percentage`}
        value={
          <Loadable loading={<LoadingSkeleton />} {...reportResponse}>
            {(report: DividendReportsT) => <Percent value={report.dividendPercentage} />}
          </Loadable>
        }
      />
      <DashboardIndicatorCard
        titleId="Finance.DividendReports.Contributions.indicators.calculationMethod"
        value={
          <Loadable loading={<LoadingSkeleton />} {...reportResponse}>
            {(report: DividendReportsT) => <span>{capitalize(report.calculationMethod)}</span>}
          </Loadable>
        }
      />
    </IndicatorsContainer>
  );
}
