import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconMoreElement = React.ElementRef<'svg'>;
interface IconMorePropTypes extends PrimitiveSvgPropTypes {}

export const IconMore = React.forwardRef<IconMoreElement, IconMorePropTypes>(function IconMore(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.19 0.5H5.81C2.17 0.5 0 2.67 0 6.31V14.68C0 18.33 2.17 20.5 5.81 20.5H14.18C17.82 20.5 19.99 18.33 19.99 14.69V6.31C20 2.67 17.83 0.5 14.19 0.5ZM5 11.81C4.28 11.81 3.69 11.22 3.69 10.5C3.69 9.78 4.28 9.19 5 9.19C5.72 9.19 6.31 9.78 6.31 10.5C6.31 11.22 5.72 11.81 5 11.81ZM10 11.81C9.28 11.81 8.69 11.22 8.69 10.5C8.69 9.78 9.28 9.19 10 9.19C10.72 9.19 11.31 9.78 11.31 10.5C11.31 11.22 10.72 11.81 10 11.81ZM15 11.81C14.28 11.81 13.69 11.22 13.69 10.5C13.69 9.78 14.28 9.19 15 9.19C15.72 9.19 16.31 9.78 16.31 10.5C16.31 11.22 15.72 11.81 15 11.81Z"
        fill="#475569"
      />
    </svg>
  );
});

IconMore.defaultProps = {
  width: '25',
  height: '24'
};
