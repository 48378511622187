import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { useTransactionAdjustment, AdjustmentTransactionType } from '.';

export type AdjustmentButtonsPropTypes = {
  transaction: AdjustmentTransactionType;
};

export const AdjustmentButtons = React.memo(function AdjustmentButtons({ transaction }: AdjustmentButtonsPropTypes) {
  const {
    permission,
    adjustTransactionsPermission,
    setCurrentTransaction,
    setShowView,
    setShowAdjust
  } = useTransactionAdjustment();

  const hideAdjustButton =
    !transaction.isAdjustable ||
    transaction.isAdjustment ||
    transaction.wasAdjusted ||
    !permission.to(adjustTransactionsPermission);

  function handleViewClick(transaction: AdjustmentTransactionType) {
    setCurrentTransaction(transaction);
    setShowView(true);
  }

  function handleAdjustClick(transaction: AdjustmentTransactionType) {
    setCurrentTransaction(transaction);
    setShowAdjust(true);
  }

  return (
    <>
      <Button aria-label="View" type="primary" size="small" onClick={() => handleViewClick(transaction)}>
        <Text id="MemberDetail.Transactions.Subsection.Actions.View.button" />
      </Button>
      <If
        condition={!hideAdjustButton}
        do={
          <Button
            className="ml1"
            aria-label="Adjust"
            type="primary"
            size="small"
            onClick={() => handleAdjustClick(transaction)}
          >
            <Text id="MemberDetail.Transactions.Subsection.Actions.Adjust.button" />
          </Button>
        }
      />
    </>
  );
});
