import React from 'react';

import { LoanDisbursementType, DisbursementStates } from '@kwara/models/src/models/LoanDisbursement';

import Table from '../Table';
import OverviewListV2 from '../../../../../../components/OverviewListV2';

import { usePermissions } from '../../../../../../hooks';
import { getButtonUtils } from '../../../utils/getButtonUtils';
import { ActionButtons, ActionButtonsPropTypes } from './ActionButtons';

export interface PendingLoanDisbursementsListPropTypes
  extends Omit<ActionButtonsPropTypes, 'disabled' | 'isPermitted'> {
  size: number;
  searchValue: string;
  filterValue: string;
  isFetching: boolean;
  hasNextPage: boolean;
  selectedLoans: string[];
  loanDisbursements: LoanDisbursementType[];
  onFetchNextPage(): void;
}

export function PendingLoanDisbursementsList({
  size,
  onApprove,
  onReject,
  isFetching,
  loansCount,
  hasNextPage,
  filterValue,
  onFetchNextPage,
  loanDisbursements,
  selectedLoans
}: PendingLoanDisbursementsListPropTypes) {
  const { permission, AppPermissions } = usePermissions();

  const canReviewLoan = permission.to(AppPermissions.ReviewLoanDisbursement);
  const buttonUtils = getButtonUtils({
    data: loanDisbursements,
    values: selectedLoans,
    filterBy: filterValue,
    state: DisbursementStates.PENDING_MAKER_CHECKER_APPROVAL
  });
  const disabled = isFetching || !size || buttonUtils.disable;

  return (
    <OverviewListV2
      withFilter
      totalNumResults={size}
      items={loanDisbursements}
      filterDisabled={isFetching}
      labelId="Finance.LoanDisbursement.List.label"
      actions={[
        <ActionButtons
          key="actionBtn"
          isPermitted={canReviewLoan}
          disabled={disabled}
          loansCount={loansCount}
          onApprove={onApprove}
          onReject={onReject}
        />
      ]}
      table={
        <Table.Root
          thead={<Table.Head />}
          tbody={<Table.Body loanDisbursements={loanDisbursements} canReviewLoan={canReviewLoan} />}
          tfooter={
            <Table.Footer
              isLoading={isFetching}
              onNext={onFetchNextPage}
              items={loanDisbursements}
              hasMore={hasNextPage}
            />
          }
        />
      }
    />
  );
}
