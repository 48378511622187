import { useRef } from 'react';
import map from 'lodash/fp/map';

import { usePaginatedUsers } from '../../../../../models/hooks';
import { FILTER_TYPES } from '../../../../../components/Filter/utils/filterTypes';

export function useUsersFilter() {
  const { current: includes } = useRef([]);
  const { current: fetchParams } = useRef({ pageSizeParam: 500 });
  const { allData: users = [] } = usePaginatedUsers(includes, fetchParams);

  const filters = map(
    user => ({
      value: user.id,
      name: user.fullName(),
      belongsTo: FILTER_TYPES.ACTIVITY_FEED_USER
    }),
    users
  );

  return filters;
}
