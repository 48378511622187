import pipe from 'lodash/fp/pipe';
import pick from 'lodash/fp/pick';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import size from 'lodash/fp/size';
import toInteger from 'lodash/fp/toInteger';
import fpMap from 'lodash/fp/map';
import values from 'lodash/fp/values';
import sum from 'lodash/fp/sum';

import { cameliseObjectKeys, createModelErrors } from '@kwara/models/src';

import apiConfig from '../../config';
import { auth } from '../../hooks';
import { IdentityType } from '../../lib/types';
import { _api } from '../../services';

export type CreditReportParams = {
  identityType: IdentityType;
  identityValue: string;
  loanAmount: number;
  reportReason?: number;
};

const sumAccounts = (key: string, instance): number =>
  pipe(get('lenderSector'), values, fpMap(get(key)), sum)(instance);

export class CreditReport {
  accounts = [];
  lenderSector = {};
  hasFraud: 'Yes' | 'No';
  creditScore: number | null;
  delinquencyCode?: string;

  constructor(data) {
    Object.assign(this, data);
  }

  get hasReportedFraud() {
    return this.hasFraud ? 'Yes' : 'No';
  }
  get formattedCreditScore() {
    const { creditScore } = this;

    if (creditScore == null) {
      return '-';
    }

    return toInteger(creditScore);
  }
  get nonPerformingAccounts() {
    return sumAccounts('accountNpa', this);
  }
  get performingAccountsWithDefault() {
    return sumAccounts('accountPerforming', this);
  }
  get performingAccountsWithoutDefault() {
    return sumAccounts('accountPerformingNpaHistory', this);
  }
  get totalAccounts() {
    return size(this.accounts);
  }
  get totalOutstandingBalance() {
    return pipe(
      fpMap(v => parseInt(getOr(0, 'currentBalance', v))),
      sum
    )(this.accounts);
  }
}

async function getReport(params: CreditReportParams) {
  const { identityType: identity_type = 'national', identityValue: identity_value, loanAmount: loan_amount } = params;

  try {
    const qparams = [
      // For example loan_amount=10000&report_reason=1&identity_type=NATIONAL&identity_value=880000088
      `loan_amount=${loan_amount}`,
      `identity_type=${identity_type}`,
      `identity_value=${identity_value}`,
      'report_reason=1' // yes, we hardcode this one
    ];
    const res = await fetch(`${apiConfig.API_ROOT}/credit_info?${qparams.join('&')}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.getRawToken()}`
      },
      method: 'GET'
    }).then(r => r.json());

    const result = pipe(
      pick(['data', 'errors']),
      r => ({ ...r, data: get('data.attributes', r) }),
      cameliseObjectKeys,
      (r: any) => ({ ...r, data: new CreditReport(r.data) })
    )(res);

    return result;
  } catch (e) {
    return { errors: [createModelErrors(e.response)] };
  }
}

export interface PdfCreditReportData {
  data: {
    id: string;
    type: string;
    attributes: {
      report: string;
      [key: string]: any;
    };
  };
  jsonapi: { version: string };
}

export async function asyncGetPdfCreditReport(params: CreditReportParams) {
  const { identityType, identityValue, loanAmount, reportReason = '1' } = params;

  const queryParams = [
    `loan_amount=${loanAmount}`,
    `identity_type=${identityType}`,
    `identity_value=${identityValue}`,
    `report_reason=${reportReason}`
  ].join('&');

  try {
    const response = await _api.httpGetRequest<PdfCreditReportData>(`pdf_credit_report?${queryParams}`);
    return response.data;
  } catch (e) {
    return { errors: [createModelErrors({ base: 'PDF_GENERATION_GENERIC_ERROR' })] };
  }
}

export default { get: getReport };
