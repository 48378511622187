import React from 'react';
import map from 'lodash/fp/map';

import { B2CTransactionStates } from '@kwara/models/src/models/B2CTransaction';

import { BodyContentWrapper } from '../../../../../layouts';
import { usePaginatedB2CTransactions } from '../../../../../models/hooks';
import { HeaderSubHeader } from '../../../../../components/HeaderSubHeader';

import { MpesaList } from '../shared/List';

const filters = [
  { value: '', translationId: '' },
  ...map(
    value => ({
      value,
      translationId: `Finance.Mpesa.List.filter.${value}`
    }),
    Object.values(B2CTransactionStates)
  )
];

export const B2CTransactions = () => {
  const { allData: transactions, ...rest } = usePaginatedB2CTransactions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.Mpesa.B2C.title" subtitleId="Finance.Mpesa.B2C.subtitle" />
          <MpesaList transactions={transactions} filters={filters} {...rest} />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
