// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import lowerCase from 'lodash/fp/lowerCase';
import includes from 'lodash/fp/includes';
import pipe from 'lodash/fp/pipe';
import at from 'lodash/fp/at';

import { useMemberLoans, useMemberSavings } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';

import { Grid } from '../../../../components/Grid';
import { SubscribedSavingAccountCard, SubscribedLoanAccountCard } from './AccountCard';
import { type SubstepProps } from '../..';

function filterBySearchTerm(accounts, rawTerm) {
  const term = lowerCase(rawTerm);

  return filter(account => {
    const [name, id] = pipe(at(['product.name', 'id']), map(lowerCase))(account);

    return includes(term, name) || includes(term, id);
  }, accounts);
}

const accountIncludes = ['product'];

export const Account = ({ StackChild, TextField, data, formProps }: SubstepProps) => {
  const { recipient, saving: fromAccount } = data;

  const { values } = formProps;
  const { search: term } = values;

  const r1 = useMemberSavings(recipient.id, accountIncludes);
  const r2 = useMemberLoans(recipient.id, accountIncludes);

  return (
    <StackChild size="wide">
      <Loadable {...r1}>
        {savings => (
          <Loadable {...r2}>
            {loans => {
              const transactableSavings = filter(s => s.transactionsPermitted() && s.id !== fromAccount.id, savings);
              const transactableLoans = filter(l => l.canMakeRepayment(), loans);

              recipient.savings = transactableSavings;
              recipient.loans = transactableLoans;

              const visibleSavings = filterBySearchTerm(transactableSavings, term);
              const visibleLoans = filterBySearchTerm(transactableLoans, term);

              return (
                <>
                  <TextField name="search" leftGlyph="Search" placeholderId="MakeTransfer.Account.search.placeholder" />
                  <Grid border={false}>
                    {map(
                      saving => (
                        <SubscribedSavingAccountCard account={saving} key={saving.id} />
                      ),
                      visibleSavings
                    )}
                    {map(
                      loan => (
                        <SubscribedLoanAccountCard account={loan} key={loan.id} />
                      ),
                      visibleLoans
                    )}
                  </Grid>
                </>
              );
            }}
          </Loadable>
        )}
      </Loadable>
    </StackChild>
  );
};

Account.validate = {
  linkedAccountId: {
    isRequired: () => true
  }
};
