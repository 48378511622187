import * as React from 'react';

import { If } from '@kwara/components/src/If/If';

export function IconSubNavProgress(props: { isActive: boolean }) {
  return (
    <If
      condition={props.isActive}
      do={
        <svg width="12" height="52" viewBox="0 0 12 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="5" stroke="#3ccb7f" strokeWidth="2" strokeLinejoin="round" />
          <circle cx="6" cy="6" r="3" fill="#aaf0c4" />
          <rect x="5" y="12" width="2" height="40" fill="#CBD5E1" />
        </svg>
      }
      else={
        <svg width="12" height="52" viewBox="0 0 12 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="5" stroke="#CBD5E1" strokeWidth="2" strokeLinejoin="round" />
          <rect x="5" y="12" width="2" height="40" fill="#CBD5E1" />
        </svg>
      }
    />
  );
}
