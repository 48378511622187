import * as React from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { RequireAtLeastOne } from 'GlobalTypes';

import { Content } from '../Content';

import styles from '../../index.module.scss';

type WithoutSubNavPropTypes = RequireAtLeastOne<
  {
    path: string;
    onClick: React.MouseEventHandler;
    nameId: string;
    icon: React.ForwardRefExoticComponent<any>;
    className?: string;
  },
  'path' | 'onClick'
>;

export function WithoutSubNav({ nameId, path, className, icon, onClick }: WithoutSubNavPropTypes) {
  const { pathname } = useLocation();

  const asPath = !!path;
  const itsActiveLink = path === pathname;
  const classNames = cx('pointer', { [styles['nav-item--active']]: itsActiveLink }, className);

  return (
    <li className="w-100" tabIndex={0}>
      {asPath ? (
        <NavLink to={path} className={classNames}>
          <Content nameId={nameId} icon={icon} />
        </NavLink>
      ) : (
        <div role="button" tabIndex={0} className={classNames} onClick={onClick}>
          <Content nameId={nameId} icon={icon} />
        </div>
      )}
    </li>
  );
}
