import React from 'react';
import map from 'lodash/fp/map';
import {
  CustomFormPropsTypes,
  SubStepComponentProps,
  StackChildSize
} from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import { Titles } from '@kwara/models/src';

import { ProfilePhotoUpload } from '../../../components/ProfilePhotoUpload';
import * as recordValidations from '../../../lib/recordValidations';
import style from '../index.module.css';
import { useServerValidation, useAuth } from '../../../hooks';

const NameFields = ({ SelectField, TextField }: SubStepComponentProps<{}>) => {
  return (
    <>
      <span className={style.TitleSelect}>
        <SelectField name="title" labelId="AddMember.Profile.MemberTitle.label" required={true}>
          <SelectField.Option translationId="" value="" />
          {map(
            title => (
              <SelectField.Option key={title} translationId={`AddMember.Profile.MemberTitle.${title}`} value={title} />
            ),
            Titles
          )}
        </SelectField>
      </span>
      <TextField
        name="firstName"
        errorBaseId="AddMember.Profile.Name"
        labelId="AddMember.Profile.FirstName.label"
        required={true}
        autoComplete="nope"
      />
      <TextField
        name="middleName"
        errorBaseId="AddMember.Profile.Name"
        labelId="AddMember.Profile.MiddleName.label"
        autoComplete="nope"
      />
      <TextField
        name="lastName"
        errorBaseId="AddMember.Profile.Name"
        labelId="AddMember.Profile.LastName.label"
        required={true}
        autoComplete="nope"
      />
    </>
  );
};

NameFields.validate = {
  title: {
    isRequired: () => true
  },
  firstName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  middleName: { ...recordValidations.personName },
  lastName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  }
};

interface NameProps extends SubStepComponentProps<{}> {
  shownIdInput?: boolean;
  size?: StackChildSize;
  formProps: CustomFormPropsTypes<Record<string, any>>;
}

export function Name(props: NameProps) {
  const auth = useAuth();
  const [shouldValidate, setShouldValidate] = React.useState(false);

  const { shownIdInput = true, ...restProps } = props;

  const isV1 = auth.isV1();
  const actuallyShowIdInput = shownIdInput && !isV1;

  const canIdBeUsed = useServerValidation({
    resource: `members/${props.formProps.values.id}?fields[member]=id`,
    queryKey: ['@core/members_basic'],
    onError: props.enableNextButton,
    onSuccess: props.disableNextButton,
    onIsLoading: props.disableNextButton,
    options: { enabled: actuallyShowIdInput && shouldValidate }
  });

  return (
    <props.StackChild size={props.size}>
      <div className="flex">
        {isV1 ? null : <ProfilePhotoUpload onChange={props.onChange} data={props.data} />}
        <div className="flex-auto">
          {actuallyShowIdInput && (
            <props.TextField
              name="id"
              error={!canIdBeUsed}
              autoComplete="nope"
              errorBaseId="AddMember.Profile.Id"
              labelId="AddMember.Profile.MemberNo.label"
              infoId={!canIdBeUsed ? 'AddMember.Profile.Id.error.duplicateId' : null}
              onKeyDown={() => setShouldValidate(true)}
            />
          )}
          <NameFields {...restProps} />
        </div>
      </div>
    </props.StackChild>
  );
}
Name.validate = {
  ...NameFields.validate,
  id: {
    isRequired: () => false,
    pattern: recordValidations.alphaNumericWithForwardSlashAndDash.pattern
  }
};
