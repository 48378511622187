import * as React from 'react';

import styles from '../index.module.scss';

type TRowPropTypes = {
  children: React.ReactNode;
};

export function TRow(props: TRowPropTypes) {
  return <tr className={styles['lister-trow']}>{props.children}</tr>;
}
