// @flow

import { useQuery } from 'react-query';
import { TillMetric } from '@kwara/models/src';

async function fetchCurrentTillMetrics() {
  return await TillMetric.all().then(res => res.data);
}

export function useCurrentTillMetrics(enabled: boolean) {
  return useQuery(['current_till_metrics'], fetchCurrentTillMetrics, { retry: false, enabled });
}
