// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { savingPath } from '../../lib/urls';

import { useDepositForm } from '../MemberDeposit/useDepositForm';
import { steps } from './steps';

export const SavingPayment = ({ match, history }) => {
  const { addDeposit, isTillOpen, isTillLoading } = useDepositForm();
  const r = useSaving(match.params.savingId);

  return (
    <Loadable isLoading={isTillLoading}>
      <Loadable {...r}>
        {saving => {
          const { member } = saving;
          return (
            <Wizard
              analyticsId="SavingPayment"
              baseUrl={savingPath({ id: saving.id })}
              history={history}
              initialData={{
                member,
                accountId: saving.id,
                saving,
                isTillOpen
              }}
              cancelReturnsTo={savingPath({ id: saving.id })}
              currentStep="deposit"
              onSubmit={addDeposit}
              steps={steps}
              startId="deposit"
              titleId="SavingPayment.title"
            />
          );
        }}
      </Loadable>
    </Loadable>
  );
};
