import { useIntl } from 'react-intl';

import { DisbursementStates } from '@kwara/models/src/models/LoanDisbursement';

import * as FilterPages from '.';

import { BranchesFilter } from '../../../../../../../components/BranchesFilter';
import { useLoanDisbursementStatusFilter } from './useLoanDisbursementStatusFilter';
import { FILTER_TYPES } from '../../../../../../../components/Filter/utils/filterTypes';
import { useBranchesFilter } from '../../../../../../../components/BranchesFilter/useBranchesFilter';

export function useLoanDisbursementFilters() {
  const int = useIntl();
  const statusFilters = useLoanDisbursementStatusFilter();
  const branchesFilter = useBranchesFilter();

  return {
    filters: {
      [FILTER_TYPES.LOAN_DISBURSEMENT_STATUS]: { data: statusFilters, page: FilterPages.LoanDisbursementStatusFilter },
      [FILTER_TYPES.LOAN_DISBURSEMENT_DATE]: { data: [], page: FilterPages.LoanDisbursementDateFilter },
      [FILTER_TYPES.LOAN_DISBURSEMENT_SEARCH]: { data: [], page: FilterPages.LoanDisbursementSearchFilter },
      [FILTER_TYPES.BRANCH_ID]: { data: branchesFilter.allBranches, page: BranchesFilter }
    },
    selectedFilters: {
      [FILTER_TYPES.LOAN_DISBURSEMENT_STATUS]: [
        {
          value: DisbursementStates.PENDING_MAKER_CHECKER_APPROVAL,
          name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.pending' }),
          belongsTo: FILTER_TYPES.LOAN_DISBURSEMENT_STATUS
        }
      ],
      [FILTER_TYPES.BRANCH_ID]: branchesFilter.selectedBranch
    }
  };
}
