import * as React from 'react';

import { PendingSavingsTransaction } from '@kwara/models/src';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { usePermissions } from '../../../../hooks';
import { BodyContentWrapper } from '../../../../layouts';
import { TransactionsForm } from '../shared/TransactionsForm';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import { useTransactionsFilter } from '../shared/useTransactionsFilter';
import { usePaginatedPendingSavingsTransactions } from './usePaginatedPendingSavingsTransactions';

const InternalPendingSavingTransactions = () => {
  const { permission, AppPermissions } = usePermissions();
  const {
    allData,
    isFetchingNextPage,
    isLoading,
    filterValue,
    totalResults,
    fetchNextPage,
    hasNextPage,
    refetch
  } = usePaginatedPendingSavingsTransactions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.Transactions.title" subtitleId="Finance.Transactions.subtitle" />
          <TransactionsForm
            transactions={allData}
            onRefetch={refetch}
            onNext={fetchNextPage}
            isLoading={isLoading || isFetchingNextPage}
            hasMore={hasNextPage}
            totalResults={totalResults}
            model={PendingSavingsTransaction}
            filterBy={filterValue}
            canReview={permission.to(AppPermissions.ReviewSavingTransactions)}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};

export function PendingSavingTransactions() {
  const { filters, selectedFilters } = useTransactionsFilter();

  return (
    <OverviewListV2.Root filters={filters} selectedFilters={selectedFilters}>
      <InternalPendingSavingTransactions />
    </OverviewListV2.Root>
  );
}
