// @flow

import * as React from 'react';
import classnames from 'classnames';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { Field, TextField, PasswordField } from '@kwara/components/src/Form';

import { useLoginForm } from './hooks/useLoginForm';

import styles from './index.module.scss';
import iconKwaraGreenLogo from '../../assets/imgs/IconKwaraGreenLogo.png';

export { Unlock } from './components/Unlock';

const LogIn = () => {
  const { state, handleEmailChange, handlePasswordChange, logIn, resendUnlockLink } = useLoginForm();

  const { email, error, loading, password, unlockSent } = state;

  return (
    <div className={classnames(styles['container'], 'fl w-100 bg-white')}>
      <div className={classnames(styles['col-one'], 'fl h-100 flex items-center')}>
        <div className={classnames(styles['form'])}>
          <img src={iconKwaraGreenLogo} alt="Kwara Logo" className={styles['logo']} />
          <h1 className={'kw-text-super-large kw-weight-bold'}>
            <Text id="LogIn.title" />
          </h1>
          <Banner
            state="error"
            className="mb3"
            text={
              <>
                {map(
                  err => (
                    <React.Fragment key={err.code}>
                      <Text
                        id={`Errors.${err.code}`}
                        values={{
                          br: <br />,
                          clickHere: (
                            <Link onClick={resendUnlockLink}>
                              <Text id="General.clickHere" />
                            </Link>
                          )
                        }}
                      />
                    </React.Fragment>
                  ),
                  getOr([], 'errors', error)
                )}
              </>
            }
            hidden={!error}
          />
          <Banner hidden={!unlockSent} className="mb3" state="error" text={<Text id="LogIn.unlockSent" />} />
          <form className="mb3" onSubmit={logIn}>
            <Field name="email" labelId="LogIn.email" size="medium">
              <TextField
                autoFocus
                required
                name="email"
                autoComplete="email"
                size="regular"
                value={email}
                onChange={handleEmailChange}
              />
            </Field>
            <Field name="password" labelId="LogIn.password" size="medium">
              <PasswordField
                name="password"
                size="regular"
                autoComplete="current-password"
                required
                value={password}
                onChange={handlePasswordChange}
              />
            </Field>
            <Button
              isSubmit
              type="primary"
              size="medium"
              className="fr"
              glyphRightId={loading ? Button.Glyphs.Spinner : null}
              disabled={loading}
              data-testid="loginBtn"
            >
              <Text id="LogIn.login" />
            </Button>
            <div className="cf" />
          </form>
          <Link className="fr" to="/password/forgot">
            <Text id="Password.Forget.title" />
          </Link>
        </div>
      </div>
      <div className={styles['col-two']}>
        <div className={styles['col-two__row-one']}>
          <div className={styles['inner-content']}>
            <h1 className={styles['inner-content__row-one']}>
              <Text id="LogIn.redefineTitle" values={{ br: <br /> }} />
            </h1>
            <div className={styles['inner-content__row-two']}>
              <img src={iconKwaraGreenLogo} alt="Kwara logo" />
              <div />
            </div>
          </div>
          <div className={styles['bg-pattern']} />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
