import * as React from 'react';
import getOr from 'lodash/fp/getOr';

import { type MemberType, EmploymentStatusT } from '@kwara/models/src';
import {
  isDirty,
  MemberUploadWidget,
  addAttachment,
  removeAttachment,
  allowedAttachments
} from '@kwara/components/src/MemberUploadWidget';
import { attachmentNames } from '@kwara/models/src/models/Attachment';

const getMember = getOr({}, 'member');

interface DocumentsUploaderPropTypes {
  data: MemberType;
  onChange: (data: { [key: string]: any }) => Promise<{ [key: string]: any }>;
  employmentStatus: EmploymentStatusT;
}

export function DocumentsUploader({ employmentStatus, data, onChange }: DocumentsUploaderPropTypes) {
  const fileNameNotToRender = employmentStatus !== 'employed' ? attachmentNames.salaryPayslip : undefined;
  const fieldNames = allowedAttachments.typesByContext('income', fileNameNotToRender);

  return (
    <>
      {fieldNames.map(f => (
        <MemberUploadWidget
          key={f.name}
          fileName={f.name}
          member={getMember(data)}
          textId={`DocumentUploads.${f.label}.label`}
          checked={isDirty(getMember(data).attachments, f.name)}
          onChange={v => onChange({ member: addAttachment(getMember(data), v) })}
          onRemove={fileName => onChange({ member: removeAttachment(getMember(data), fileName) })}
        />
      ))}
    </>
  );
}
