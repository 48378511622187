import * as React from 'react';
import { useQueryClient } from 'react-query';
import get from 'lodash/fp/get';
import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { steps } from './config';

async function payOffLoan({
  loan,
  method,
  notes,
  bankGlId,
  bankBranch,
  bankName,
  accountNumber,
  accountId,
  chequeNumber,
  drawer,
  fees
}) {
  const params = {
    method,
    notes,
    bankGlId,
    bankName,
    bankBranch,
    accountNumber,
    chequeNumber,
    drawer,
    depositAccount: accountId,
    fees
  };
  const didSave = await loan.payOff(params);

  if (!didSave) {
    throw loan.errors;
  }
}

export const LoanPayOff = props => {
  const { baseUrl, match, history } = props;
  const r = useLoan(match.params.loanId);
  const queryClient = useQueryClient();

  return (
    <Loadable {...r}>
      {loan => {
        return (
          <Wizard
            analyticsId="LoanPayOff"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              firstName: get('member.firstName', loan),
              loanId: loan.id,
              loan,
              amount: loan.totalBalance,
              fees: []
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={payOffLoan}
            onSubmitCompletion={() => {
              r.refetch();
              queryClient.invalidateQueries('activity');
              queryClient.invalidateQueries('loan_extras');
              queryClient.invalidateQueries('loan_transactions');
            }}
            steps={steps}
            startId="confirm"
            titleId="LoanPayOff.shortTitle"
          />
        );
      }}
    </Loadable>
  );
};
