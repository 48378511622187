import * as React from 'react';
import get from 'lodash/fp/get';

import { Select, Field } from '@kwara/components/src/Form';

import { useTillUIContext } from '../../TillProvider';
import { TillPageT } from '../../useTillPage';

export function DummyTillSelect({ labelId = 'Till.DummyTillSelect.label' }: { labelId?: string }) {
  const { currentTillSession } = useTillUIContext() as TillPageT;

  return (
    <Field name="activeTill" required size="medium" labelId={labelId}>
      <Select name="activeTill" size="medium" disabled>
        <Select.Option>{`${get('till.name', currentTillSession)} (${get(
          'till.glAccountId',
          currentTillSession
        )})`}</Select.Option>
      </Select>
    </Field>
  );
}
