import * as React from 'react';
import isFunction from 'lodash/fp/isFunction';

import { RequestProps } from '@kwara/components/src';
import { MetricType } from '@kwara/models/src';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { If } from '@kwara/components/src/If/If';

import { useDashboardMetricValues } from './useDashboardMetricValues';
import { DashboardIndicatorCard } from '../DashboardIndicatorCard';

type PropsNew = {
  titleId: string;
  metric: MetricType;
  r?: RequestProps<MetricType>;
};
export function DashboardMetricCard({ metric, titleId, r }: PropsNew) {
  const { value, info } = useDashboardMetricValues(metric);

  return (
    <DashboardIndicatorCard
      titleId={titleId}
      value={
        isFunction(value) ? (
          <Loadable loading={<LoadingSkeleton />} {...r}>
            {data => value(data)}
          </Loadable>
        ) : (
          value
        )
      }
      info={isFunction(info) ? <If condition={!r.isLoading} do={info(r.data)} /> : info}
    />
  );
}
