import React from 'react';
import { Form } from 'react-final-form';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import Modal from '@kwara/components/src/Modal';
import { TillT, TillTellerLinkT, UserT } from '@kwara/models/src';
import { Panel } from '@kwara/components/src/Panel';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Button from '@kwara/components/src/Button';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedSelectField } from '@kwara/components/src/Form';

import styles from './TillsManagement.module.css';

const Option = SubscribedSelectField.Option;

type FormData = {
  tellerId: string;
  tillId: string;
};

export function TillsManagementModal({
  till,
  onBack,
  onAdd,
  onRemove,
  users = []
}: {
  till: TillT | null;
  onBack: () => void;
  onAdd: ({ tellerId, tillId }: { tellerId: string; tillId: string }) => void;
  onRemove: (link: TillTellerLinkT) => void;
  users: UserT[];
}) {
  const relevantUsers = React.useMemo(() => {
    if (!till) return [];

    const permittedTellerIds = till.tillTellerLinks.map(link => get('teller.id', link));

    return users
      .sort((userA, userB) => {
        const nameA = userA.firstName.toUpperCase();
        const nameB = userB.firstName.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;

        return 0;
      })
      .filter(user => !includes(user.id, permittedTellerIds))
      .filter(user => !user.deactivatedAt);
  }, [till, users]);

  if (!till) return null;

  return (
    <Modal ariaLabel="Manage Till Access" isOpen>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel>
          <h3>
            <Text id="TillsManagement.Modal.header" values={{ name: till.name }} />
          </h3>
          <div className="mb4">
            <If
              condition={size(till.tillTellerLinks) > 0}
              do={
                <ul className="list">
                  {till.tillTellerLinks.map(link => {
                    return (
                      <li key={link.id}>
                        {link.teller.fullName()}{' '}
                        <span onClick={() => onRemove(link)} role="button" className="red-500 pointer">
                          <Text id="TillsManagement.Modal.button.remove" />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              }
              else={
                <span className="grey-500">
                  <Text id="TillsManagement.Modal.tillTellerLinks.empty" />
                </span>
              }
            />
          </div>
          <Form
            onSubmit={(data: FormData, form) => {
              onAdd(data);
              form.reset();
            }}
            initialValues={{ tillId: till.id }}
            render={({ handleSubmit }) => {
              return (
                <form className={styles['form-container']}>
                  <SubscribedSelectField margin={false} name="tellerId">
                    <Option value="" translationId="TillsManagement.Modal.teller.option.default.label" />
                    {relevantUsers.map(user => (
                      <Option key={user.id} value={user.id}>
                        {user.fullName()} ({user.email})
                      </Option>
                    ))}
                  </SubscribedSelectField>
                  <Button onClick={handleSubmit}>
                    <Text id="TillsManagement.Modal.button.add" />
                  </Button>
                </form>
              );
            }}
          />

          <ButtonBar
            className="mt4"
            left={[
              <Button onClick={onBack}>
                <Text id="TillsManagement.Modal.button.back" />
              </Button>
            ]}
          />
        </Panel>
      </div>
    </Modal>
  );
}
