import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { PageHeading, SupportEmailLink } from '@kwara/components/src/text';

import Pagination from '../../../../components/Pagination';
import OverviewListV2 from '../../../../components/OverviewListV2';

import { BodyContentWrapper } from '../../../../layouts';
import { usePaginatedEvents } from './usePaginatedEvents';
import { EventTimeline } from '../../../../components/EventTimeline';
import { useEventsFilters } from './filters/useEventsFilters';

function InternalEvents() {
  const { isLoading, isFetchingNextPage, allData, fetchNextPage, totalResults, hasNextPage } = usePaginatedEvents();

  const loading = isLoading || isFetchingNextPage;

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <PageHeading translationId="Settings.events" />
          <p className="grey-400 kw-text-medium mb1">
            <Text id="Settings.Events.text1" />
          </p>
          <p className="grey-400 kw-text-medium mt1">
            <Text id="Settings.Events.text2" />
            <SupportEmailLink />.
          </p>
          <OverviewListV2
            withFilter
            totalNumResults={totalResults}
            items={allData}
            filterDisabled={loading}
            labelId="Settings.Events.OverviewList.label"
            table={
              <>
                <section className="mt4">
                  <EventTimeline events={allData} />
                </section>
                <Pagination onNext={fetchNextPage} loading={loading} hasMore={hasNextPage} />
              </>
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function Events() {
  const filters = useEventsFilters();

  return (
    <OverviewListV2.Root filters={filters}>
      <InternalEvents />
    </OverviewListV2.Root>
  );
}
