import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { Stack } from '@kwara/components/src/Stack';

import { settingPath } from '../../lib/urls';
import { ActionModal } from '../../components/ActionModal';
import { useUser } from '../../hooks';
import { ProfileContent } from '../Settings/components/Personal';

const includes = ['role', 'app_roles'];
export function UserEdit(props) {
  const userId = props.match.params.userId;
  const r = useUser(userId, includes);

  return (
    <Loadable {...r}>
      {user => {
        return (
          <ActionModal
            titleId="UserEdit.title"
            onCancel={() => props.history.push(settingPath({ baseExtension: 'team' }))}
            hideConfirm
          >
            <Stack>
              <Stack.Child>
                <ProfileContent user={user} hiddenSections={['mfa']} editableSections={['roles']} />
              </Stack.Child>
            </Stack>
          </ActionModal>
        );
      }}
    </Loadable>
  );
}
