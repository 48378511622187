import { useMutation, useQueryClient } from 'react-query';
import pickBy from 'lodash/fp/pickBy';
import identity from 'lodash/fp/identity';

import { OperationalReport } from '@kwara/models/src';

type ArgType = {
  toDate?: Date;
  filters: {
    loan_product_id?: string | null;
    state?: string | null;
  };
};

export function useReportCreate(templateKey: string, cacheKey: string) {
  const queryClient = useQueryClient();

  function createReport({ toDate, filters }: ArgType) {
    const compactFilters = pickBy(identity, filters);

    const report = OperationalReport.create({ toDate, filters: compactFilters, reportTemplateKey: templateKey });
    return report.save();
  }

  const { mutate: onGenerate } = useMutation(createReport, {
    onSettled(didSave: boolean) {
      if (didSave) {
        queryClient.invalidateQueries(cacheKey);
      }
    }
  });

  return { onGenerate };
}
