// @flow

import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { Toggle, type TogglePropsT } from '@kwara/components/src/Form';
import styles from './index.module.scss';

type Props = { titleId: string, subtitleId?: ?string, className?: string } & TogglePropsT;

export const ToggleBar = ({ titleId, subtitleId, name, disabled, className, ...props }: Props) => {
  return (
    <div className={cx(styles.ToggleBar, 'pa3 bg-grey-200', className)}>
      <div className={styles.Titles}>
        <span className="kw-text-medium black">
          <Text id={titleId} />
        </span>
        {subtitleId ? (
          <div>
            <span className="kw-text-small-design-system grey-500-design-system">
              <Text id={subtitleId} />
            </span>
          </div>
        ) : null}
      </div>

      <Toggle name={name} disabled={disabled} {...props} />
    </div>
  );
};
