import * as React from 'react';

import styles from './index.module.scss';

type IndicatorsContainerPropTypes = {
  children: React.ReactNode;
};

export function IndicatorsContainer(props: IndicatorsContainerPropTypes) {
  return (
    <div role="row" className={styles['indicators']}>
      {props.children}
    </div>
  );
}
