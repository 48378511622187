// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';

import { Loadable } from '@kwara/components/src/Loadable';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { useLoanExtras } from '@kwara/models/src/models/request/hooks';
import { type LoanType, type LoanV2Type } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Currency, Text, Percent, Date } from '@kwara/components/src/Intl';

import { AccountThumbnail, NoAccounts } from '../../../components/AccountThumbnail';

const LoansPanel = ({ loans = [] }) => {
  if (isEmpty(loans)) {
    return (
      <NoAccounts>
        <Text id="LoanDetail.Detail.totalPaidOffLoan.noLoans" />
      </NoAccounts>
    );
  }

  return (
    <div className="ph3">
      {map(
        account => (
          <AccountThumbnail key={account.id} account={account} amount={account.amount} />
        ),
        loans
      )}
    </div>
  );
};

export const Details = ({ loan }: { loan: LoanType }) => {
  const r = useLoanExtras(loan.id);

  return <Loadable {...r}>{loanExtras => <DetailsBody loanV2={loanExtras} loan={loan} />}</Loadable>;
};

export const DetailsBody = ({ loanV2, loan }: { loanV2: LoanV2Type, loan: LoanType }) => {
  const size = 'medium';

  return (
    <section>
      <StatisticRow compact widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.principalBalance.title" />}
          value={<Currency value={loanV2.principal.balance} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.principalArrears.title" />}
          value={<Currency value={loanV2.principal.due} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.principalPaid.title" />}
          value={<Currency value={loanV2.principal.paid} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.interestBalance.title" />}
          value={<Currency value={loanV2.interest.balance} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.interestArrears.title" />}
          value={<Currency value={loanV2.interest.due} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.interestPaid.title" />}
          value={<Currency value={loanV2.interest.paid} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.feesBalance.title" />}
          value={<Currency value={loanV2.fees.balance} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.feesArrears.title" />}
          value={<Currency value={loanV2.fees.due} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.feesPaid.title" />}
          value={<Currency value={loanV2.fees.paid} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.penaltiesBalance.title" />}
          value={<Currency value={loanV2.penalties.balance} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.penaltiesArrears.title" />}
          value={<Currency value={loanV2.penalties.due} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.penaltiesPaid.title" />}
          value={<Currency value={loanV2.penalties.paid} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.accruedInterest.title" />}
          value={<Currency value={loanV2.accruedInterest} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.activationDate.title" />}
          value={<Date value={loanV2.createdAt} />}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.taxRate.title" />}
          value={<Percent value={loanV2.taxRate / 100} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic title={<Text id="LoanDetail.Detail.daysLate.title" />} value={loanV2.daysLate} size={size} />
        <Statistic
          title={<Text id="LoanDetail.Detail.arrearsTolerancePeriod.title" />}
          value={loanV2.arrearsTolerancePeriod}
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.daysInArrears.title" />}
          value={loanV2.daysInArrears}
          size={size}
          color={loanV2.daysInArrears > 0 ? 'red-500' : ''}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.collectPrincipalEvery.title" />}
          value={
            <Text
              id="LoanDetail.Detail.collectPrincipalEvery.value"
              values={{
                val: get('scheduleSettings.principal_repayment_interval', loanV2)
              }}
            />
          }
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.interestCalculationMethod.title" />}
          value={
            <Text
              id={`LoanDetail.Detail.interestCalculationMethod.${get(
                'interestSettings.interestCalculationMethod',
                loanV2
              )}`}
            />
          }
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.paymentAllocationMethod.title" />}
          value={<Text id={`LoanDetail.Detail.paymentAllocationMethod.${loanV2.paymentMethod}`} />}
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact border widthClass="w-33">
        <Statistic
          title={<Text id="LoanDetail.Detail.accruedInterestPosting.title" />}
          value={
            <Text
              id={`LoanDetail.Detail.accruedInterestPosting.${get(
                'interestSettings.interestApplicationMethod',
                loanV2
              )}`}
            />
          }
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.prepaymentAccepted.title" />}
          value={
            <Text
              id={`LoanDetail.Detail.prepaymentAccepted.${get(
                'product.paymentSettings.prepayment_settings.prepayment_acceptance',
                loanV2
              )}`}
            />
          }
          size={size}
        />
        <Statistic
          title={<Text id="LoanDetail.Detail.prepaymentsOfFutureInterest.title" />}
          value={
            <Text
              id={`LoanDetail.Detail.prepaymentsOfFutureInterest.${get(
                'product.paymentSettings.prepayment_settings.futurePayments_acceptance',
                loanV2
              )}`}
            />
          }
          size={size}
        />
      </StatisticRow>
      <StatisticRow compact widthClass="w-33">
        <Statistic
          size={size}
          title={
            <Text
              id="LoanDetail.Detail.totalPaidOffLoan.title"
              values={{
                info: (
                  <InfoPanel>
                    <LoansPanel loans={invoke('loanApplication.paidOffLoans', loan)} />
                  </InfoPanel>
                )
              }}
            />
          }
          value={<Currency value={invoke('loanApplication.totalPaidOffLoans', loan)} />}
        />
      </StatisticRow>
    </section>
  );
};
