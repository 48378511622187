import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Field } from '@kwara/components/src/Form';
import { PeriodUnits } from '@kwara/models/src';
import { fields as remittanceFields } from '@kwara/models/src/models/Remittance';
import Asset from '@kwara/components/src/Asset';

import { RepaymentMethod } from '../RepaymentMethod';
import { DurationField } from './DurationField';
import styles from '../index.module.scss';

const fields = remittanceFields.remittance;

export function RepaymentFieldsBase(props) {
  const { TextField, SelectField, fixedRepayment, repaymentAmountR, isV1 } = props;
  return (
    <>
      {/* Fixed repayment frequency fields */}
      <Field
        margin={false}
        name="repaymentPeriod"
        size="medium"
        required={true}
        disabled={fixedRepayment}
        labelId="LoanAdd.Configure.Repayment.label"
      >
        <div className={styles.field}>
          <TextField disabled={fixedRepayment} size="medium" name="repaymentPeriod" />
          <SelectField disabled={fixedRepayment} name="repaymentPeriodUnit" size="medium">
            {map(
              unit => (
                <SelectField.Option key={unit} translationId={`LoanAdd.Configure.Repayment.${unit}`} value={unit} />
              ),
              PeriodUnits
            )}
          </SelectField>
        </div>
      </Field>
      {/* Loan duration and repayment amount */}
      <div className={`${styles.field} `}>
        <DurationField {...props} />
        {isV1 ? null : (
          <TextField
            name={fields.amount}
            labelId="LoanAdd.Repayment.Amount.label"
            isCurrency
            type="number"
            disabled
            rightGlyph={repaymentAmountR.isLoading ? Asset.Glyphs.Spinner : null}
            initialValue={get('repaymentAmount', repaymentAmountR.data)}
          />
        )}
      </div>
      {/* Repayment method*/}
      {isV1 ? null : <RepaymentMethod {...props} />}
    </>
  );
}
