import React from 'react';

import { Text, TranslationId } from '@kwara/components/src/Intl';

type LabelPropTypes = {
  translationId: TranslationId;
  visible: number;
  total: number;
};

export function Label({ translationId, visible, total }: LabelPropTypes) {
  return (
    <p className="dib nowrap kw-text-regular grey-400 ml0 pa0">
      <Text id={translationId} values={{ visible, total }} />
    </p>
  );
}
