// @flow

import * as React from 'react';
import { Redirect } from 'react-router';

import { useSaccoProfileContext } from '../../models/Profile/ProfileProvider';

// @TODO: sc35568
const TEMPORARILY_DISABLE = true;

export const Redirect2FA = () => {
  const store = useSaccoProfileContext();

  if (TEMPORARILY_DISABLE) {
    return null;
  }

  if (store.is2faActivated) {
    return null;
  }

  return <Redirect to="/auth/2fa" />;
};
