import React from 'react';
import cx from 'classnames';
import Menu from 'rc-menu';

import zIndices from '@kwara/lib/src/zIndices';

import { Item } from './Item';
import { DefaultButton } from './DefaultButton';
import { useButtonMenuActions } from './useButtonMenuUtils';

import styles from './index.module.scss';

export type ButtonMenuPropTypes = {
  title?: React.ReactNode;
  titleId?: string;
  onClick?(): void;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  type?: 'primary' | 'secondary' | 'destructive';
  Button?: React.ComponentType<any>;
};

function ButtonMenu({
  Button = DefaultButton,
  className,
  children,
  disabled,
  title,
  titleId,
  type
}: ButtonMenuPropTypes) {
  const { parentRef, menuRef, isOpen, toggle, close, getPopupContainer } = useButtonMenuActions();

  return (
    <div ref={parentRef} className={styles['container']}>
      <Button disabled={disabled} type={type} title={title} titleId={titleId} onClick={toggle} />
      <div
        ref={menuRef}
        hidden={!isOpen}
        aria-label={'Button Menu'}
        className={cx(styles['menu-container'], zIndices.OverlayMenus)}
      >
        <Menu getPopupContainer={getPopupContainer} className={cx(className, styles['menu'])}>
          <div onClick={close}>{children}</div>
        </Menu>
      </div>
    </div>
  );
}

ButtonMenu.Item = Item;
ButtonMenu.DefaultButton = DefaultButton;

export { ButtonMenu };
