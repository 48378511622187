import React, { useState } from 'react';

import { Logger } from '@kwara/lib/src/logger';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import Banner from '@kwara/components/src/Banner';
import Button from '@kwara/components/src/Button';
import Asset from '@kwara/components/src/Asset/index';
import { Loadable } from '@kwara/components/src/Loadable';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';
import { PdfViewer } from '@kwara/components/src/PdfViewer';

import styles from './index.module.scss';
import { useGetPdfCreditReport } from './useGetPdfCreditReport';
import { CreditReportParams } from '../../../../../../models/CreditReport/CreditReport';

interface PdfCreditReportPropTypes extends CreditReportParams {}

export function PdfCreditReport(props: PdfCreditReportPropTypes) {
  const [hasShowPdfError, setHasShowPdfError] = useState(false);
  const { pdfUrl, ...r } = useGetPdfCreditReport(props);

  return (
    <Loadable {...r}>
      {_ => {
        return (
          <>
            <If
              condition={hasShowPdfError}
              do={
                <Banner
                  className="mt4 mb4"
                  text={<Text id="LoanAppraisal.CreditCheck.showReportError" />}
                  state="error"
                />
              }
            />

            <div className={styles['pdf-credit-report-row1']}>
              <PdfViewer pdfUrl={pdfUrl} errorId="LoanAppraisal.CreditCheck.PdfViewer.loadingError" />

              <Button
                type="secondary"
                data-testid="downloadCreditReport"
                disabled={!pdfUrl}
                onClick={function downloadPdfCreditReport() {
                  try {
                    setHasShowPdfError(false);
                    window.open(pdfUrl, '_blank');
                  } catch (e) {
                    setHasShowPdfError(true);
                    Logger.log(`Error reading credit_report.pdf response`, JSON.stringify(e));
                  }
                }}
              >
                <AccessibleIcon label={<Text id="LoanAppraisal.CreditCheck.Accessibility.downloadReport" />}>
                  <Asset size="medium" col={Asset.Colours.black} id={Asset.Glyphs.Printer} />
                </AccessibleIcon>
              </Button>
            </div>
          </>
        );
      }}
    </Loadable>
  );
}
