// @flow
import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { useCurrentTillBalance } from '../../Till';
import { Panel } from '../../../components/ActionModal';
import { type SubStepComponentProps } from '../../../components/Wizard';

export const Request = ({ TextField, TextArea }: SubStepComponentProps<>) => {
  const { currentBalance } = useCurrentTillBalance(true);

  return (
    <Panel>
      <div className="mb4">
        <span className="kw-text-large kw-weight-bold ">
          <Text id="TopupRequest.main" />
        </span>
        <br />
        <span className="kw-text-medium grey-400">
          <Text id="TopupRequest.subtitle" />
        </span>
      </div>
      <TextField
        name="requestedAmount"
        labelId="TopupRequest.CashRequest.label"
        errorBaseId="TopupRequest.CashRequest"
        leftGlyph="Currency.orgCurrency"
        required
        isCurrency
        type="number"
        infoId={'TillToVaultTransfer.CashRequest.info.label'}
        values={{ currentBalance }}
      />
      <TextArea name="comment" placeholderId="TopupRequest.Notes.placeholder" labelId="TopupRequest.Notes.label" />
    </Panel>
  );
};

Request.validate = {
  requestedAmount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  },
  comment: {
    isRequired: () => false
  }
};
