import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { TillTransactionType } from '@kwara/models/src';

import OverviewList from '../../../../components/OverviewList';

import { TellerListTable } from '../TellerListTable';

type Props = {
  hasMore?: boolean,
  loading?: boolean,
  tellers: TillTransactionType[], //TO DO Update Prop for Teller
  errors: Object[],
  onLoadMoreData: () => void,
  totalNumResults: ?number
};

export default withRouter(
  ({ hasMore = false, loading = false, tillSessions = [], errors, onLoadMoreData, totalNumResults }: Props) => (
    <OverviewList
      headerId="TellerList.heading"
      labelId={totalNumResults ? 'TellerList.filteredCount' : 'TellerList.filteredCountWithoutTotal'}
      items={tillSessions}
      table={
        <TellerListTable
          hasMore={hasMore}
          tillSessions={tillSessions}
          onLoadMoreData={onLoadMoreData}
          loading={loading}
          errors={errors}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
