import { useMemo } from 'react';
import { addMany } from '@kwara/lib/src/currency';

import { LoanTopupFormTypes } from '../..';

export function getNewPrincipalBalance(values: LoanTopupFormTypes) {
  const { loan, topupAmount, desiredInterestBalance, desiredFeesBalance, desiredPenaltiesBalance } = values;
  const principalBalance = addMany([
    loan.principal.balance,
    topupAmount,
    desiredInterestBalance,
    desiredFeesBalance,
    desiredPenaltiesBalance
  ]);

  return Number(principalBalance);
}

export function useGetNewPrincipalBalance(values: LoanTopupFormTypes) {
  return useMemo(() => getNewPrincipalBalance(values), [values]);
}
