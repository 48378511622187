import { useMemo } from 'react';

import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';

import { LoanAddData } from '../..';

export function useRelevantFees(data: LoanAddData) {
  return useMemo(
    () =>
      pipe(
        get('product.fees'),
        filter((fee: { feeApplication: 'OPTIONAL' | 'REQUIRED' }) => fee.feeApplication === 'OPTIONAL')
      )(data),
    [data]
  );
}
