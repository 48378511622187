// @flow

import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';

import Banner from '@kwara/components/src/Banner';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

export const ApprovalInfo = ({ StackChild, data }) => {
  const { loan, currentUser } = data;
  const loanApplication = get('loanApplication', loan);

  return (
    <StackChild>
      {loanApplication && !isEmpty(loanApplication.loanApplicationApprovals) ? (
        <>
          {loanApplication.alreadyApprovedBy(currentUser) ? (
            <Banner className="mb3" state="warning" text={<Text id="LoanApprove.Overview.warning.alreadyApproved" />} />
          ) : null}

          {!loanApplication.canBeApprovedBy(currentUser) ? (
            <Banner className="mb3" state="warning" text={<Text id="LoanApprove.Overview.warning.mayNotApprove" />} />
          ) : null}

          <StatisticRow widthClass="w-50">
            <Statistic title={<Text id="LoanApprove.Overview.approverRole" />} value={loanApplication.role()} />
            <Statistic
              containerClassName="mb3"
              title={<Text id="LoanApprove.Overview.approvalsSoFar" />}
              value={`${loanApplication.approvals()} of ${loanApplication.approversNeeded()}`}
            />
          </StatisticRow>
        </>
      ) : null}
    </StackChild>
  );
};
