import { usePermissions } from '../../../hooks';

const GENERIC_PERMISSION = '*';

export function useIsAnnouncementPermitted() {
  const { permission, KwaraPermissions } = usePermissions();

  return function checkIsPermitted(permissions: string[]) {
    if (permissions.includes(GENERIC_PERMISSION)) return true;

    return permissions.some(perm => permission.to([KwaraPermissions[perm]]));
  };
}
