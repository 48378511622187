import map from 'lodash/fp/map';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { filterMapping, TransactionUIStates } from '@kwara/models/src/models/Transaction';

import * as FilterPages from './Filters';

import { BranchesFilter } from '../../../../components/BranchesFilter';
import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { useBranchesFilter } from '../../../../components/BranchesFilter/useBranchesFilter';

export function useTransactionsFilter() {
  const int = useIntl();
  const branchesFilter = useBranchesFilter();
  const statusFilters = useMemo(
    () => [
      {
        value: '',
        name: int.formatMessage({ id: 'Finance.Transactions.List.filter.all' }),
        belongsTo: FILTER_TYPES.STATUS
      },
      ...map(
        value => ({
          value: filterMapping[value],
          name: int.formatMessage({ id: `Finance.Transactions.List.filter.${value}` }),
          belongsTo: FILTER_TYPES.STATUS
        }),
        TransactionUIStates
      )
    ],
    [int]
  );

  return {
    filters: {
      [FILTER_TYPES.STATUS]: { data: statusFilters, page: FilterPages.TransactionStatusFilter },
      [FILTER_TYPES.DATE]: { data: [], page: FilterPages.TransactionDateFilter },
      [FILTER_TYPES.TRANSACTION_SEARCH]: { data: [], page: FilterPages.TransactionSearchFilter },
      [FILTER_TYPES.BRANCH_ID]: { data: branchesFilter.allBranches, page: BranchesFilter }
    },
    selectedFilters: {
      [FILTER_TYPES.STATUS]: [
        {
          value: filterMapping[TransactionUIStates.PENDING],
          name: int.formatMessage({ id: 'Finance.LoanDisbursement.Filter.Status.pending' }),
          belongsTo: FILTER_TYPES.STATUS
        }
      ],
      [FILTER_TYPES.BRANCH_ID]: branchesFilter.selectedBranch
    }
  };
}
