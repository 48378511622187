import React from 'react';

import { Text } from '@kwara/components/src/Intl';

type SubtitlePropTypes = {
  id: string;
};

export const Subtitle = (props: SubtitlePropTypes) => (
  <span className="kw-text-medium kw-weight-bold neutral-700">
    <Text id={props.id} />
  </span>
);
