import React from 'react';

import { FilterValue } from 'FilterTypes';

import { Location } from '@kwara/components/src/Actionable';
import Loan from '@kwara/models/src/models/Loan';

import LoanList from './components/LoanList';
import { LoanPageProps } from '..';
import { BodyContentWrapper } from '../../layouts';
import { DataViewWrapper } from '../../components/DataViewWrapper';
import { Indicators } from './components/Indicators/Indicators';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { useAuth } from '../../hooks';

type Props = LoanPageProps;

function Content(props: Props) {
  const { AppPermissions, permission } = usePermissions();
  const auth = useAuth();
  const { hasMore, loading, errors, data, onLoadMoreData, totalNumberResults, onFilterChange } = props;

  return (
    <BodyContentWrapper.Root headId="LoanList.title">
      {permission.to(AppPermissions.ViewLoanMetrics) ? (
        <BodyContentWrapper.Row.Root>
          <BodyContentWrapper.Row.Indicators>
            <Indicators />
          </BodyContentWrapper.Row.Indicators>
        </BodyContentWrapper.Row.Root>
      ) : null}

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <LoanList
            hasMore={hasMore}
            loading={loading}
            errors={errors}
            loans={data}
            onFilterChange={(currentlySelectedFilter: FilterValue | null) => {
              onFilterChange(currentlySelectedFilter?.value);
            }}
            onLoadMoreData={onLoadMoreData}
            totalNumResults={totalNumberResults}
            isV1={auth.isV1()}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function Loans({ location }: { location: Location }) {
  return (
    <DataViewWrapper
      component={Content}
      scope={Loan.includes(['member,loan_application.disbursement.appraisals.user'])}
      pathname="/loans"
      location={location}
    />
  );
}
