import * as React from 'react';

import { SubscribedTextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';

import { Panel } from '../ActionModal';

const Notes = () => (
  <Panel>
    <SubscribedTextArea name="notes" border={false} compact size="medium" placeholderId="Payment.Notes.placeholder" />
  </Panel>
);

export default Notes;
