import * as React from 'react';
import cx from 'classnames';
import posed from 'react-pose';

import { ActionButton } from '@kwara/components/src';

import styles from './index.module.scss';

const AnimatedContainer = posed.div({
  collapsed: { opacity: 1 },
  expanded: { opacity: 0 }
});

export type HeaderPropTypes = {
  isExpanded?: boolean;
  onToggle?: () => void;
  children?: React.ReactNode;
  'data-testid'?: string;
};

export function Header({ isExpanded, onToggle, children, ...restProps }: HeaderPropTypes) {
  return (
    <>
      {children}
      <AnimatedContainer
        className={cx(
          styles['expand-bar'],
          'bt w-100 flex justify-center',
          isExpanded ? 'primary-500' : 'b--light-grey-400'
        )}
      >
        <ActionButton
          {...restProps}
          type="expand"
          className={cx(styles['expand-btn'], isExpanded && styles['expand-btn--open'])}
          onClick={onToggle}
        />
      </AnimatedContainer>
    </>
  );
}
