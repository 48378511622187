// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import { LoanType } from '@kwara/models/src';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { If } from '@kwara/components/src/If/If';
import { Guarantee } from '@kwara/models/src/models/Guarantee';

import Visible from '../../../../components/Visible';
import { AppPermissions } from '../../../../models/Permission';

import { GuaranteeList } from './GuaranteeList';
import { CollateralList } from './CollateralList';
import DetailSubsection from '../../../../components/DetailSubsection';

import { reducer, initialState } from './reducer';

type Props = { loan: LoanType };

export const Securities = ({ loan }: Props) => {
  const [isEditingCollaterals, setIsEditingCollaterals] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState(loan.guarantors));
  const { guarantees, isEditing, errors, guarantorToEdit, banner } = state;

  const handleCreate = async data => {
    const guarantor = await Guarantee.create(data);
    dispatch({ type: 'ON_ADD_SUCCESS', payload: { guarantor } });
  };

  const handleEdit = async data => {
    const guarantor = await guarantorToEdit.edit(data);
    dispatch({ type: 'ON_EDIT_SUCCESS', payload: { guarantor } });
  };

  const handleSubmit = async data => {
    try {
      if (guarantorToEdit) {
        await handleEdit(data);
      } else {
        await handleCreate(data);
      }
    } catch (errResponse) {
      dispatch({
        type: 'ON_ERROR',
        payload: { errors: errResponse.response.data.errors }
      });
    }
  };

  const handleDelete = async guarantee => {
    await guarantee.remove(loan.id);
    dispatch({ type: 'ON_DELETE', payload: { guarantor: guarantee } });
  };

  return (
    <>
      <DetailSubsection
        title="Guarantors"
        ariaLabel="Guarantors"
        headerRight={
          <Visible to={AppPermissions.EditLoanDetails}>
            <div className="flex nowrap">
              <Button className="ml-auto mr3" onClick={() => dispatch({ type: 'ON_ADD_START' })}>
                <Text id="LoanDetail.Security.Guarantors.add" />
              </Button>
              <DownloadButton size="regular" instance={loan} downloadFn={loan.downloadGuarantorPDF} />
            </div>
          </Visible>
        }
      >
        {banner ? (
          <Banner
            className="mb2"
            text={<Text id={banner.translationId} values={banner.values} />}
            state="error"
            onDismiss={() => dispatch({ type: 'ON_DISMISS_BANNER' })}
          />
        ) : null}
        <GuaranteeList
          loan={loan}
          guarantees={guarantees}
          isEditing={isEditing}
          guarantorToEdit={guarantorToEdit}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          onEdit={guarantor => dispatch({ type: 'ON_EDIT_START', payload: { guarantor } })}
          onCancel={() => dispatch({ type: 'ON_CANCEL' })}
          errors={errors}
        />
      </DetailSubsection>

      <DetailSubsection
        title="Collaterals"
        headerRight={
          <If
            condition={loan.isEditable()}
            do={
              <Visible to={AppPermissions.EditLoanDetails}>
                <Button onClick={() => setIsEditingCollaterals(true)}>
                  <Text id="LoanDetail.Security.Collaterals.edit" />
                </Button>
              </Visible>
            }
          />
        }
      >
        <CollateralList isEditing={isEditingCollaterals} loan={loan} onClose={() => setIsEditingCollaterals(false)} />
      </DetailSubsection>
    </>
  );
};
