import React from 'react';
import find from 'lodash/fp/find';

import { Text, Currency, If } from '@kwara/components/src';
import { CollateralType, LoanApplicationT } from '@kwara/models/src';
import { LinkButton } from '@kwara/components/src/LinkButton';

import { InfoPanel } from '../../../InfoPanel';
import Lister from '../../../Lister';

import styles from '../../index.module.scss';
import { useCollateralsLister } from './useCollateralsLister';

import { WithViewer } from '../CollateralUploadWidget/components/Viewer';

type Props = {
  collaterals: CollateralType[];
  withNarration?: boolean;
  loanApplication: LoanApplicationT;
  fileName: string;
  onEditStart?: (index: number) => void;
};

export function CollateralsLister({ collaterals, onEditStart, withNarration, loanApplication, fileName }: Props) {
  const { BASES, TITLES } = useCollateralsLister({ collaterals, withNarration });
  const isEditable = !!onEditStart;
  return (
    <Lister.Root className="mt2 mb2" titleId="Collateral.LoanCollaterals">
      <Lister.Header titles={TITLES} />

      <Lister.Body>
        {Td => {
          return collaterals.map((collateral, index: number) => {
            const { isMarkedForDestruction, assetName, amount, collateralNarration } = collateral;
            return (
              <If
                key={index.toString()}
                condition={!isMarkedForDestruction && amount}
                do={
                  <Lister.TRow>
                    <Td width="48%">{assetName}</Td>

                    <Td width="48%">
                      <Currency format="currency" value={amount} />
                    </Td>

                    <If
                      condition={isEditable}
                      do={
                        <Td width="4%">
                          <LinkButton as="button" onClick={() => onEditStart && onEditStart(index)}>
                            <Text id="Collateral.edit" />
                          </LinkButton>
                        </Td>
                      }
                    />

                    <WithViewer attachments={collateral?.attachments ?? null} loanApplication={loanApplication}>
                      {({ setShownAttachment }) => {
                        const attachments = collateral.attachments;
                        const attachment = find(o => o.name === fileName, attachments);
                        return (
                          <If
                            condition={loanApplication && attachment}
                            do={
                              <Td width="4%">
                                <LinkButton as="button" onClick={() => setShownAttachment(attachment)}>
                                  <Text id="Collateral.view" />
                                </LinkButton>
                              </Td>
                            }
                          />
                        );
                      }}
                    </WithViewer>

                    <If
                      condition={withNarration}
                      do={
                        <Td width="4%" className={styles['nar-td']}>
                          <If
                            condition={collateralNarration != null}
                            do={
                              <InfoPanel>
                                <p className={styles['nar-td__p']}>{collateralNarration}</p>
                              </InfoPanel>
                            }
                          />
                        </Td>
                      }
                    />
                  </Lister.TRow>
                }
              />
            );
          });
        }}
      </Lister.Body>

      <Lister.Footer bases={BASES} />
    </Lister.Root>
  );
}
