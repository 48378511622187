import React from 'react';
import { Review } from './components/Review';
import { Accounts } from './components/Accounts';
import Deposits from './components/Deposits';
import Terms from './components/Terms';
import Note from './components/Note';
import { Summary } from './components/Summary';
import Reject from './components/Reject';
import { AppPermissionsType, ToHelperType } from '../../models/Permission/types';

export const steps = (to: ToHelperType, AppPermissions: AppPermissionsType, isV1: boolean) => {
  const canApproveMembers = to(AppPermissions.ApproveMembers);
  const canAddSavings = to(AppPermissions.AddSavings);

  const showAccountSelector = !isV1 && canAddSavings;

  return {
    review: {
      titleId: 'MemberApprove.title',
      subtitleId: 'MemberApprove.subtitle',
      Component: props => <Review {...props} isV1={isV1} />,
      validate: Review.validate,
      progress: false,
      actions: [
        {
          appearsAs: 'approve',
          behavesAs: 'next',
          destination: showAccountSelector ? 'accounts' : 'terms'
        },
        {
          appearsAs: 'reject',
          behavesAs: 'next',
          destination: 'reject'
        }
      ]
    },
    accounts: {
      isPermitted: canAddSavings || canApproveMembers,
      titleId: 'MemberApprove.Accounts.short',
      children: [
        {
          titleId: 'MemberApprove.Accounts.title',
          subtitleId: 'MemberApprove.Accounts.subtitle',
          Component: Accounts
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'deposits'
        }
      ]
    },
    deposits: {
      isPermitted: canApproveMembers,
      titleId: 'MemberApprove.Deposits.short',
      children: [
        {
          titleId: 'MemberApprove.Deposits.title',
          subtitleId: 'MemberApprove.Deposits.subtitle',
          Component: Deposits,
          validate: Deposits.validate
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'terms'
        }
      ]
    },
    terms: {
      isPermitted: canApproveMembers,
      titleId: 'MemberApprove.Terms.short',
      children: [
        {
          titleId: 'MemberApprove.Terms.title',
          Component: Terms,
          validate: Terms.validate
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: isV1 ? 'summary' : 'note'
        }
      ]
    },
    note: {
      isPermitted: canApproveMembers,
      titleId: 'MemberApprove.Note.short',
      children: [
        {
          titleId: 'MemberApprove.Note.title',
          Component: Note
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'summary'
        }
      ]
    },
    summary: {
      isPermitted: canApproveMembers,
      titleId: 'MemberApprove.Summary.short',
      children: [
        {
          titleId: 'MemberApprove.Summary.title',
          subtitleId: 'MemberApprove.Summary.subtitle',
          Component: props => <Summary {...props} isV1={isV1} />,
          progress: false
        }
      ],
      actions: [
        {
          appearsAs: 'approve',
          behavesAs: 'approve'
        }
      ]
    },
    reject: {
      isPermitted: to(AppPermissions.RejectMembers),
      titleId: 'MemberApprove.Reject.title',
      subtitleId: 'MemberApprove.Reject.subtitle',
      Component: props => <Reject {...props} isV1={isV1} />,
      validate: Reject.validate,
      progress: false,
      actions: [
        {
          appearsAs: 'reject',
          behavesAs: 'reject'
        }
      ]
    }
  };
};
