// @flow

import * as React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { type BaseRequestProps, EMPTY } from '@kwara/models/src/models/request';
import { BackButton } from '@kwara/components/src/Button';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import { Stack, Child } from '@kwara/components/src/Stack';
import { Loadable } from '@kwara/components/src/Loadable';

import { useAuth } from '../../../../hooks';

type ResponseShape = {
  errors: {
    detail: String,
    meta: { attribute: string },
    source: { pointer: string }
  }[]
};

export function Unlock() {
  const location = useLocation();
  const auth = useAuth();
  const [state, setState] = React.useState<BaseRequestProps<ResponseShape>>({
    isLoading: true,
    error: {},
    data: {}
  });

  const { t: token }: { t: string } = queryString.parse(location.search);

  React.useEffect(() => {
    let cancelled = false;
    const req = () =>
      auth
        .unlockAccount(token)
        .then((_: null) => {
          if (!cancelled) {
            setState({ isLoading: false, error: EMPTY, data: {} });
          }
        })
        .catch((res: ResponseShape) => {
          if (!cancelled) {
            setState({ isLoading: false, error: { messages: res.errors }, data: {} });
          }
        });

    if (token) {
      req();
    }

    return () => {
      cancelled = true;
    };
  }, [auth, token]);

  return (
    <div className="flex flex-column h-100 justify-center">
      <Stack size="medium">
        <Child size="regular">
          <BackButton to={{ url: '/', routeName: 'Login' }} type="secondary" />
          <Empty>
            <Loadable {...state}>
              <h2 className="grey-500 mt4 mb4">
                <Text id="LogIn.unlock.success" />
              </h2>
            </Loadable>
          </Empty>
        </Child>
      </Stack>
    </div>
  );
}
