import React from 'react';
import { DividendReports } from '../../../../models';
import { useOverviewList, fetchWithPagination } from '../../../../models/hooks/base';

const useStateFilter = () => {
  const [where, setWhere] = React.useState({
    state: ''
  });

  const onFilterChange = val => setWhere({ state: val });
  const filterValue = where.state;

  return { where, filterValue, onFilterChange };
};

async function fetchDividendReports(opts) {
  return fetchWithPagination(DividendReports, opts);
}

const defaultIncludes = ['user', 'product'];
export function useDividendReports(includes = defaultIncludes) {
  return useOverviewList({
    queryKey: 'dividend_reports',
    fetchFn: fetchDividendReports,
    includes,
    select: null,
    useWhere: useStateFilter
  });
}
