// @flow
import * as React from 'react';
import cx from 'classnames';
import getOr from 'lodash/fp/getOr';
import { Field as FinalFormField } from 'react-final-form';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { SubscribedTextField as TextField } from '@kwara/components/src/Form';
import { type GuaranteeType, type MemberType, type LoanProductType } from '@kwara/models/src';

import { type LoanSubstepProps } from '../../../..';
import { GuarantorInfo } from './GuarantorInfo';
import { Child as StackChild } from '../../../../../../../components/Stack';
import { useMemberLoanProductStanding } from '../../../../../../../models/hooks';
import { SKIP_WARNING_KEY } from '../hooks/useGuarantorValidation';

type GuarantorItemProps = {
  product: LoanProductType,
  member: MemberType,
  inputAmountName: string,
  canGuaranteeUpToKey?: string,
  addData?: (data: any) => void,
  isMarkedForDestruction?: boolean,
  inputIsMarkedForDestructionName?: string
};

export const GuarantorItem = (props: GuarantorItemProps) => {
  const {
    product,
    member,
    inputAmountName,
    canGuaranteeUpToKey,
    isMarkedForDestruction,
    inputIsMarkedForDestructionName,
    addData
  } = props;
  const guarantorsRequest = useMemberLoanProductStanding(member.id, product.id);
  const canGuaranteeUpTo: number = guarantorsRequest.data?.canGuaranteeUpTo ?? 0;
  const canIGuarantee = !!canGuaranteeUpTo;

  React.useEffect(() => {
    addData?.({
      [canGuaranteeUpToKey]: canGuaranteeUpTo
    });
  }, [addData, canGuaranteeUpTo, canGuaranteeUpToKey]);

  return (
    <li className={cx('pb3')}>
      <div
        className={cx('br3 t-1a', {
          'bg-semi-white-400': !isMarkedForDestruction,
          'bg-red-100 red-700': isMarkedForDestruction
        })}
      >
        <GuarantorInfo r={guarantorsRequest} product={product} member={member} />

        <div className="pa3">
          <div className="b--white">
            <TextField
              type="number"
              labelId="LoanAdd.Security.Guarantor.amount"
              leftGlyph="Currency.orgCurrency"
              name={inputAmountName}
              disabled={!canIGuarantee}
              errorBaseId="LoanAdd.SecurityAmount"
              info={
                !canIGuarantee && (
                  <span className="red-500">
                    <Text id="LoanAdd.SecurityAmount.error.cannotGuarantee" />
                  </span>
                )
              }
              onKeyUp={() => {
                addData?.({
                  [SKIP_WARNING_KEY]: false
                });
              }}
              required
              isCurrency
            />
          </div>
        </div>

        {inputIsMarkedForDestructionName ? (
          <div className="pa3 bt b--light-grey-400">
            <FinalFormField
              name={inputIsMarkedForDestructionName}
              type="checkbox"
              render={({ input }) => {
                if (input.checked) {
                  return (
                    <>
                      <Text id="LoanAdd.Security.Guarantor.undoWarning" />
                      <Link size="regular" type="primary" onClick={() => input.onChange(false)}>
                        <Text id="LoanAdd.Security.Guarantor.undo" />
                      </Link>
                    </>
                  );
                }

                return (
                  <Link size="regular" type="destructive" onClick={() => input.onChange(true)}>
                    <Text id="LoanAdd.Security.Guarantor.remove" />
                  </Link>
                );
              }}
            />
          </div>
        ) : null}
      </div>
    </li>
  );
};

type Props = {
  guarantors: GuaranteeType[],
  product: LoanProductType,
  disableNextButton: Function,
  enableNextButton: Function,
  addData: (data: any) => void,
  formProps: $PropertyType<LoanSubstepProps, 'formProps'>
};

export const GuarantorList = ({ product, guarantors, formProps, addData }: Props) => {
  const formState = formProps.form && formProps.form.getState();

  return (
    <StackChild>
      <ul className="list pa0">
        {mapIndexed((g: GuaranteeType, i: number) => {
          const formGuarantee = getOr({}, `values.guarantors[${i}]`, formState);
          const { member } = g;
          const isMarkedForDestruction = formGuarantee.isMarkedForDestruction;
          const inputAmountName = `guarantors[${i}].amount`;
          const canGuaranteeUpToKey = `guarantors[${i}].canGuaranteeUpTo`;
          const inputIsMarkedForDestructionName = `guarantors[${i}].isMarkedForDestruction`;

          return (
            <GuarantorItem
              key={member.id}
              product={product}
              member={member}
              inputAmountName={inputAmountName}
              canGuaranteeUpToKey={canGuaranteeUpToKey}
              isMarkedForDestruction={isMarkedForDestruction}
              inputIsMarkedForDestructionName={inputIsMarkedForDestructionName}
              addData={addData}
            />
          );
        }, guarantors)}
      </ul>
    </StackChild>
  );
};
