// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { savingPath } from '../../lib/urls';
import { steps } from './config';
import { type SavingPageProps } from '..';

import { useWithdrawalForm } from '../MemberWithdrawal/useWithdrawalForm';

type Props = SavingPageProps;

export const SavingWithdrawal = (props: Props) => {
  const { history, match } = props;
  const r = useSaving(match.params.savingId);
  const { makeWithdrawal, isTillOpen, isTillLoading } = useWithdrawalForm();

  return (
    <Loadable isLoading={isTillLoading}>
      <Loadable {...r}>
        {saving => {
          const { member } = saving;
          const redirectTo = savingPath({ id: saving.id });

          return saving.transactionsPermitted() ? (
            <Wizard
              analyticsId="SavingWithdrawal"
              baseUrl={redirectTo}
              history={history}
              initialData={{
                member,
                accountId: saving.id,
                saving,
                isTillOpen
              }}
              cancelReturnsTo={redirectTo}
              currentStep="withdrawal"
              onSubmit={makeWithdrawal}
              steps={steps}
              startId="withdrawal"
              titleId="MemberWithdrawal.title"
            />
          ) : (
            <Redirect to={redirectTo} />
          );
        }}
      </Loadable>
    </Loadable>
  );
};
