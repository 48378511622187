import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';

import {
  type BankAccountT,
  type MemberType,
  type LoanType,
  type CollateralT,
  type PeriodUnitsT,
  type LoanRemittanceT,
  type LoanProductType,
  type StandardIdDocumentType
} from '@kwara/models/src';

import { type SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';

export type GuaranteeType = {
  canGuaranteeUpTo?: number,
  member: MemberType,
  amount: string | null
};

export type LoanFormData = {
  guarantors: GuaranteeType[],
  collaterals: CollateralT[],
  member: MemberType,
  product: LoanProductType,
  amount: string,
  loanDuration: string,
  repaymentPeriod: string,
  repaymentPeriodUnit: PeriodUnitsT,
  purpose: string,
  specification: string,
  loans: LoanType[],
  loan?: LoanType,
  payOffLoans?: string[],
  remittance: LoanRemittanceT,
  bankAccounts: BankAccountT[],
  identityType: StandardIdDocumentType,
  identityValue: string,
  notes?: { [key: string]: { no_section: string } }
};

export type LoanSubstepProps = SubStepComponentProps<LoanFormData>;

export function getLoansfromLoanIds(loanIds, allLoans) {
  return filter(loan => includes(loan.id, loanIds), allLoans);
}
