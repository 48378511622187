import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconCancelElement = React.ElementRef<'svg'>;

interface IconCancelPropTypes extends PrimitiveSvgPropTypes {
  circleStrokeWidth?: string;
}

export const IconCancel = React.forwardRef<IconCancelElement, IconCancelPropTypes>(function IconCancel(
  { stroke, width, height, circleStrokeWidth, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="11" stroke={stroke} strokeWidth={circleStrokeWidth} />
      <path d="M9.5 9L15.5 15" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 15L15.5 9" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

IconCancel.defaultProps = {
  circleStrokeWidth: '2',
  stroke: '#262338',
  width: '25',
  height: '24'
};
