// @flow

import React from 'react';

import Modal from '@kwara/components/src/Modal';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { Panel } from '@kwara/components/src/Panel';
import { Currency } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

import styles from './GuarantorValidationModal.module.scss';
import { useGuarantorValidation } from '../hooks/useGuarantorValidation';

type PropTypes = {
  addDataAndContinue(updated: Record<string, any>): void,
  error: Error
};

export function GuarantorValidationModal(props: PropTypes) {
  const { onBypassWarning, onCloseWarning, showInfo, messages } = useGuarantorValidation(props.error);

  return (
    <Modal ariaLabel="Guaranteeable amount warning" isOpen={showInfo}>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel className={styles.Panel}>
          <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
            <Text id="LoanDetail.Security.Guarantor.ValidationHeading" />
          </h2>

          <ul className={`${styles['messages-wrapper']} ma0 pa1 list`}>
            {mapIndexed(
              ({ firstName, canGuaranteeUpTo }, index) => (
                <li key={index.toString()} className="kw-weight-light mb3">
                  <Text
                    id="LoanDetail.Security.Guarantor.ValidationMessage"
                    values={{ firstName, canGuaranteeUpTo: <Currency format="currency" value={canGuaranteeUpTo} /> }}
                  />
                </li>
              ),
              messages
            )}
          </ul>

          <ButtonBar
            className="mt4"
            left={
              <Button type="secondary" onClick={() => onBypassWarning(props.addDataAndContinue)}>
                <Text id="LoanDetail.Security.Guarantor.ValidationBypass" />
              </Button>
            }
            right={
              <Button type="destructive" onClick={onCloseWarning}>
                <Text id="LoanDetail.Security.Guarantor.ValidationUpdate" />
              </Button>
            }
          />
        </Panel>
      </div>
    </Modal>
  );
}
