import { useState, useMemo, useCallback } from 'react';

import {
  FilterName,
  FilterValue,
  ValueArgType,
  SelectionType,
  Filters,
  FilterContextType,
  UseFilterContextValuesArgType,
  SelectFilterValueArgType,
  DeselectFilterValueArgType,
  OnFilterValueArgType,
  Page,
  FilterVerb,
  UpdateFilterValueArgType
} from 'FilterTypes';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { useFilterUIPosition } from './useFilterUIPosition';
import { SELECTION_TYPES, FILTER_SELECTION_VERBS } from '../utils/filterTypes';

export function useFilterContextValues({
  filters: filtersArg,
  onReset,
  open: openArg,
  activeFilter: activeFilterArg,
  onRemoveFilterValue: onRemoveFilterValueArg,
  selectedFilters: selectedFiltersArg = null,
  onFilterValue: onFilterValueArg
}: UseFilterContextValuesArgType): FilterContextType {
  const { bodyPosition, headerRef } = useFilterUIPosition();
  const [open, { toggle: toggleFilter }] = useBoolean(openArg);
  const [selectedFilters, setSelectedFilters] = useState<Filters | null>(selectedFiltersArg);

  const flattenedSelectedFilters = Object.values(selectedFilters ?? {}).flat();
  const hasSelectedFilters = !!flattenedSelectedFilters.length;
  const filterNames = Object.keys(filtersArg) as FilterName[];

  const { filters, pages } = useMemo(() => {
    return Object.entries(filtersArg).reduce(
      (acc: { filters: Filters; pages: Record<string, Page> }, [key, val]) => {
        acc.filters[key] = val.data;
        acc.pages[key] = val.page;
        return acc;
      },
      { filters: {}, pages: {} }
    );
  }, [filtersArg]);

  const initializeActiveFilter = useCallback(() => activeFilterArg ?? filterNames[0], [activeFilterArg, filterNames]);
  const [activeFilter, setActiveFilter] = useState<FilterName>(initializeActiveFilter);

  const initializeCurrentlySelectedFilter = () => {
    if (selectedFiltersArg) {
      const selectedFiltersKeys = Object.keys(selectedFiltersArg);
      const lastFilter = selectedFiltersArg[selectedFiltersKeys[selectedFiltersKeys.length - 1]]?.slice(-1)[0];
      return lastFilter;
    }

    return null;
  };

  const [currentlySelectedFilter, setCurrentlySelectedFilter] = useState<FilterValue | null>(
    initializeCurrentlySelectedFilter
  );

  const onNavigateFilter = useCallback((name: FilterName) => setActiveFilter(name), []);

  const selectFilterValue = useCallback(
    ({ filterName, selectedValue, cachedFilterValues, selectionType }: SelectFilterValueArgType) => {
      const newFilter =
        selectionType === SELECTION_TYPES.MULTI ? [...cachedFilterValues, selectedValue] : [selectedValue];

      setSelectedFilters(prev => ({ ...prev, [filterName]: newFilter }));
      setCurrentlySelectedFilter(selectedValue);
      onFilterValueArg?.(selectedValue, { ...selectedFilters, [filterName]: newFilter }, filterName);
    },
    [onFilterValueArg, selectedFilters]
  );

  const deselectFilterValue = useCallback(
    (
      { filterName, selectedFilterIndex, cachedFilterValues }: DeselectFilterValueArgType,
      onDone?: (updatedCachedFilterValues: FilterValue[]) => void
    ) => {
      const newFilter = [...cachedFilterValues];

      newFilter.splice(selectedFilterIndex, 1);
      setSelectedFilters(prev => ({ ...prev, [filterName]: newFilter }));
      setCurrentlySelectedFilter(null);
      onFilterValueArg?.(null, { ...selectedFilters, [filterName]: newFilter }, filterName);
      onDone?.(newFilter);
    },
    [onFilterValueArg, selectedFilters]
  );

  const handleInvalidSelectedFilterValue = useCallback(
    (filterName: FilterName, selectionType: SelectionType) => {
      if (selectionType === SELECTION_TYPES.SINGLE) {
        setSelectedFilters(prev => ({ ...prev, [filterName]: [] }));
        setCurrentlySelectedFilter(null);
        onFilterValueArg?.(null, { ...selectedFilters, [filterName]: [] }, filterName);
      }
    },
    [onFilterValueArg, selectedFilters]
  );

  const getCachedFilterValues = useCallback(
    (filterName: FilterName) => {
      const hasInitialFilterValues = hasSelectedFilters && selectedFilters[filterName];

      return hasInitialFilterValues ? selectedFilters[filterName] : [];
    },
    [hasSelectedFilters, selectedFilters]
  );

  const getSelectedFilterIndex = (selectedValue: FilterValue, cachedFilterValues: FilterValue[]) => {
    return cachedFilterValues.findIndex(
      ({ belongsTo, name }) => name === selectedValue.name && belongsTo === selectedValue.belongsTo
    );
  };

  const updateFilterValue = useCallback(
    (arg: UpdateFilterValueArgType) => {
      const { filterName, selectionType, selectedFilterIndex, selectedValue, cachedFilterValues } = arg;

      deselectFilterValue({ filterName, selectedFilterIndex, cachedFilterValues }, function onUpdate(
        value: FilterValue[]
      ) {
        selectFilterValue({ filterName, selectedValue, selectionType, cachedFilterValues: value });
      });
    },
    [deselectFilterValue, selectFilterValue]
  );

  const onFilterValue = useCallback(
    ({
      filterName,
      selectedValue,
      selectionType = SELECTION_TYPES.SINGLE,
      verb = FILTER_SELECTION_VERBS.CREATE_OR_DELETE as FilterVerb
    }: OnFilterValueArgType) => {
      if (selectedValue == null) {
        return handleInvalidSelectedFilterValue(filterName, selectionType);
      }

      const cachedFilterValues = getCachedFilterValues(filterName);
      const selectedFilterIndex = getSelectedFilterIndex(selectedValue, cachedFilterValues);
      const shouldAdd = selectedFilterIndex < 0;
      const shouldUpdate = verb === FILTER_SELECTION_VERBS.UPDATE;

      if (shouldAdd) {
        return selectFilterValue({ filterName, selectedValue, cachedFilterValues, selectionType });
      }

      if (shouldUpdate) {
        return updateFilterValue({ filterName, selectionType, selectedFilterIndex, selectedValue, cachedFilterValues });
      }

      return deselectFilterValue({ filterName, selectedFilterIndex, cachedFilterValues });
    },
    [deselectFilterValue, getCachedFilterValues, handleInvalidSelectedFilterValue, selectFilterValue, updateFilterValue]
  );

  const onRemoveFilterValue = useCallback(
    (filterName: FilterName, selectedValue: FilterValue) => {
      const cachedFilterValues = getCachedFilterValues(filterName);
      const selectedFilterIndex = getSelectedFilterIndex(selectedValue, cachedFilterValues);

      deselectFilterValue({ filterName, selectedFilterIndex, cachedFilterValues });
      onRemoveFilterValueArg?.(filterName, selectedValue);
    },
    [deselectFilterValue, getCachedFilterValues, onRemoveFilterValueArg]
  );

  const onResetFilter = useCallback(() => {
    setSelectedFilters(null);
    setActiveFilter(initializeActiveFilter);
    setCurrentlySelectedFilter(null);
    onReset?.();
  }, [initializeActiveFilter, onReset]);

  const checkIsFilterValueSelected = useCallback(
    (filterName: FilterName, value: ValueArgType) =>
      hasSelectedFilters && selectedFilters[filterName]?.some(f => Object.is(f.value, value)),
    [hasSelectedFilters, selectedFilters]
  );

  /**
   * @getValueFor function is a callback function that takes
   * two parameters: `type` and `name`. It is used to retrieve
   * the value of a selected filter.
   * @param type:FilterName - is the filter category
   * @param name?:string - is the deep-matching-filter-name value to return, if not
   * passed,the first filter-value in the filter category is returned. Not passing in name
   * can happen when a filter-category is a single-type, i.e it holds only 1 filter
   */
  const getValueFor = useCallback(
    (type: FilterName, name?: string) => {
      if (!hasSelectedFilters) return '';

      if (!name) return selectedFilters[type]?.[0]?.value || '';

      return selectedFilters[type]?.find(f => f?.name === name)?.value || '';
    },
    [hasSelectedFilters, selectedFilters]
  );

  return useMemo<FilterContextType>(
    () => ({
      pages,
      filters,
      activeFilter,
      selectedFilters,
      currentlySelectedFilter,
      open,
      hasSelectedFilters,
      flattenedSelectedFilters,
      filterNames,
      headerRef,
      bodyPosition,
      onNavigateFilter,
      onFilterValue,
      onResetFilter,
      toggleFilter,
      onRemoveFilterValue,
      checkIsFilterValueSelected,
      getValueFor,
      setSelectedFilters,
      setCurrentlySelectedFilter
    }),
    [
      pages,
      filters,
      activeFilter,
      selectedFilters,
      currentlySelectedFilter,
      open,
      hasSelectedFilters,
      flattenedSelectedFilters,
      filterNames,
      headerRef,
      bodyPosition,
      onNavigateFilter,
      onFilterValue,
      onResetFilter,
      toggleFilter,
      onRemoveFilterValue,
      checkIsFilterValueSelected,
      getValueFor
    ]
  );
}
