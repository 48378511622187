import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { If } from '@kwara/components/src/If/If';

import { renderCustomizedTooltip, renderCustomizedLegendForPieCharts } from '../Customized';
import { formatPieData, abbreviateNumber } from '../../utils';
import { useDistributionOfDepositsStats } from './useDistributionOfDepositsStats';

import styles from '../../index.module.scss';

export function DistributionOfDeposits() {
  const r = useDistributionOfDepositsStats();

  return (
    <Loadable loading={<LoadingSkeleton />} {...r}>
      {data => {
        const sumOfBalancesPerProduct = get('sumOfBalancesPerProduct', data);
        const sumOfBalances = abbreviateNumber(Number(get('sumOfBalances', data)));
        const coloredData = formatPieData(sumOfBalancesPerProduct);

        return (
          <If
            condition={!!sumOfBalancesPerProduct}
            do={
              <div className={styles['pie-chart-container']}>
                <div className="h-100">
                  <span className={cx(styles['balance-sum'], 'primary-500 kw-text-xl')}>{sumOfBalances}</span>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={coloredData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      innerRadius={40}
                      outerRadius={80}
                      paddingAngle={2}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {coloredData.map((entry, index) => {
                        return <Cell key={`cell-${index}`} fill={entry.color} />;
                      })}
                    </Pie>
                    <Tooltip content={props => renderCustomizedTooltip({ ...props, isCurrency: true })} />
                  </PieChart>
                </div>
                {renderCustomizedLegendForPieCharts(coloredData)}
              </div>
            }
          />
        );
      }}
    </Loadable>
  );
}
