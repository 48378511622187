import { useState, useEffect, useMemo, useCallback } from 'react';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import merge from 'lodash/merge';
import first from 'lodash/fp/first';
import values from 'lodash/fp/values';

import { HttpErrorObject } from 'GlobalTypes';

import { appName } from '@kwara/lib/src/utils';
import { Logger } from '@kwara/lib/src/logger';
import { segmentTrack } from '@kwara/components/src/Segment';
import { throwError } from '@kwara/lib/src/utils/throwError';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';

import { ActionEvent, CompletionState } from '../types/sharedTypes';
import { WizardPropTypes, WizardInternalContextType } from '../types/wizard';
import { ActionConfig, VALID_WIZARD_ACTIONS_BEHAVIORS } from '../utils/action';
import { COMPLETION_STATE, UNAUTHORIZED_ERROR, parseCurrentSubStep } from '../utils/wizard';

type LocationStateType = {
  from?: { url: string; routeName: string };
};
type StateType<Data> = {
  data: Data;
  error: HttpErrorObject[] | null;
  showPinEntry: boolean;
  showConfirmCancel: boolean;
  completionState: CompletionState;
};
export interface ArgumentsType<DataType> extends Omit<WizardPropTypes<DataType>, 'cancelReturnsTo'> {
  returnsTo: Location | string;
}

const { Incomplete, Processing, Successful, Rejected, Error } = COMPLETION_STATE;

export function useWizardImplementation<DataType>(args: ArgumentsType<DataType>) {
  const {
    returnsTo,
    baseUrl,
    onReject,
    onSubmit,
    onSubmitCompletion,
    initialData,
    analyticsId,
    startId,
    titleId,
    steps,
    currentSubStep,
    currentStep,
    animated,
    bannerContent,
    notification,
    showCompletion,
    successButtonId,
    onAction,
    onRenderCustomCompletionScreen,
    rejectSubtitleId,
    successSubtitleId,
    completionAutoConfirm = true,
    requireCancelConfirm = true
  } = args;

  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const [{ error, showConfirmCancel, showPinEntry, data, completionState }, setState] = useState<StateType<DataType>>(
    () => ({
      error: null,
      showPinEntry: false,
      showConfirmCancel: false,
      data: initialData,
      completionState: Incomplete
    })
  );
  const [headerId, bodyId] = useUniqueIds(2);
  useEffect(() => Logger.log('Data', data), [data]);

  const cancelReturnsTo = get(location, 'state.from.url', returnsTo) as string;
  const hasCompleted = [Incomplete, Processing, Error].indexOf(completionState) === -1;

  const cancel = useCallback(() => {
    history.push(cancelReturnsTo);

    if (hasCompleted && onSubmitCompletion) onSubmitCompletion();
  }, [cancelReturnsTo, hasCompleted, history, onSubmitCompletion]);

  function onCompleted(completionState: CompletionState) {
    setState(prev => ({ ...prev, completionState }));
  }

  function confirmCancel() {
    setState(prev => ({ ...prev, showConfirmCancel: true }));
  }

  const onRemain = useCallback(() => {
    setState(prev => ({ ...prev, showConfirmCancel: false }));
  }, []);

  const onError = useCallback((err: HttpErrorObject[]) => {
    setState(prev => ({ ...prev, error: err }));
  }, []);

  function onClosePinEntry() {
    setState(prev => ({ ...prev, showPinEntry: false }));
  }

  const onChange = useCallback(
    <DataType = Record<string, any>>(updatedData: DataType) => {
      return new Promise(resolve => {
        setState(prev => ({ ...prev, data: merge(data, updatedData) }));

        resolve(null);
      });
    },
    [data]
  );

  const rejectAndComplete = useCallback(async () => {
    if (!onReject) {
      throwError('WizardRejectAndCompleteError', 'onReject not supplied', rejectAndComplete);
    }

    onCompleted(Processing);

    try {
      await onReject(data);

      onCompleted(Rejected);
    } catch (error) {
      onCompleted(Error);

      setState(prev => ({ ...prev, error }));
    }
  }, [data, onReject]);

  const submitAndComplete = useCallback(async () => {
    onCompleted(Processing);

    try {
      await onSubmit(data);

      onCompleted(Successful);
    } catch (errors) {
      const error: HttpErrorObject = first(values(errors));

      onCompleted(Error);

      const isMemberUserUnAuthorized = appName.isMember && error?.title === UNAUTHORIZED_ERROR;

      if (isMemberUserUnAuthorized) setState(prev => ({ ...prev, showPinEntry: true }));
      else setState(prev => ({ ...prev, error: errors, showPinEntry: false }));
    }
  }, [data, onSubmit]);

  const asyncNext = useCallback(
    async (action: ActionConfig) => {
      onCompleted(Processing);

      try {
        await action.onNext(data, onChange);

        onCompleted(Incomplete);

        setState(prev => ({ ...prev, error: null }));

        history.push(`${baseUrl}/${String(action.destinationPath)}`);
      } catch (error) {
        onCompleted(Incomplete);

        setState(prev => ({ ...prev, error }));
      }
    },
    [baseUrl, data, history, onChange]
  );

  const defaultOnAction = useCallback(
    ({ action, payload }: ActionEvent<{}>) => {
      switch (action.behavesAs) {
        case VALID_WIZARD_ACTIONS_BEHAVIORS.back:
          history.goBack();
          break;

        case VALID_WIZARD_ACTIONS_BEHAVIORS.nextWithPromise:
          asyncNext(action);
          break;

        case VALID_WIZARD_ACTIONS_BEHAVIORS.next:
        case VALID_WIZARD_ACTIONS_BEHAVIORS.skip:
          history.push(`${baseUrl}/${String(action.destinationPath)}`);
          break;

        default:
          Logger.warn(`No action for "${action.behavesAs}"`, action, payload);
      }
    },
    [asyncNext, baseUrl, history]
  );

  const onCancel = requireCancelConfirm ? confirmCancel : cancel;

  const handleAction = useCallback(
    (analyticsId?: string, onAction: (event: ActionEvent<{}>) => void = defaultOnAction) => {
      return function onHandleAction({ action }: { action: ActionConfig }) {
        switch (action.behavesAs) {
          case VALID_WIZARD_ACTIONS_BEHAVIORS.complete:
          case VALID_WIZARD_ACTIONS_BEHAVIORS.approve:
            segmentTrack(`${analyticsId} Submit Button Clicked`);
            submitAndComplete();
            break;

          case VALID_WIZARD_ACTIONS_BEHAVIORS.reject:
            rejectAndComplete();
            break;

          case VALID_WIZARD_ACTIONS_BEHAVIORS.cancel:
            onCancel();
            break;

          default:
            onAction({ action, payload: data });
        }
      };
    },
    [data, defaultOnAction, onCancel, rejectAndComplete, submitAndComplete]
  );

  /**
   * context value
   */
  const wizardInternalContextValues = useMemo<WizardInternalContextType>(
    () => ({
      titleId,
      error,
      steps,
      startId,
      data,
      baseUrl,
      notification,
      currentStep,
      currentSubStep: parseCurrentSubStep(currentSubStep),
      isProcessing: completionState === COMPLETION_STATE.Processing,
      showConfirmCancel,
      showCompletion,
      completionState,
      cancel,
      onRemain,
      onCancel,
      onError,
      onChange,
      animated,
      bannerContent,
      analyticsId,
      successButtonId,
      completionAutoConfirm,
      handleAction: handleAction(analyticsId, onAction),
      onRenderCustomCompletionScreen,
      rejectSubtitleId,
      successSubtitleId
    }),
    [
      analyticsId,
      animated,
      bannerContent,
      baseUrl,
      cancel,
      completionAutoConfirm,
      completionState,
      currentStep,
      currentSubStep,
      data,
      error,
      handleAction,
      notification,
      onAction,
      onCancel,
      onChange,
      onError,
      onRemain,
      onRenderCustomCompletionScreen,
      rejectSubtitleId,
      showCompletion,
      showConfirmCancel,
      startId,
      steps,
      successButtonId,
      successSubtitleId,
      titleId
    ]
  );

  return {
    headerId,
    bodyId,
    data,
    error,
    completionState,
    showPinEntry,
    showConfirmCancel,
    hasCompleted,
    rejectAndComplete,
    submitAndComplete,
    handleAction,
    confirmCancel,
    cancel,
    onCancel,
    onChange,
    onError,
    onRemain,
    onCompleted,
    onClosePinEntry,
    wizardInternalContextValues
  };
}
