import * as React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

export function WrapperWithInlineError({
  className,
  hasErrors,
  button
}: {
  className?: string;
  hasErrors: boolean;
  button: React.ReactElement;
}) {
  return (
    <div className={cx('relative', className)}>
      <div className="flex flex-column justify-center items-center">
        <div>{button}</div>
        <If
          condition={hasErrors}
          do={
            <div className="w-100 tc absolute bottom--1 left-0 red-500 h1">
              <Text id="Errors.Generic.short" />
            </div>
          }
        />
      </div>
    </div>
  );
}
