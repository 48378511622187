import { useMutation, useQueryClient } from 'react-query';
import { SasraReport } from '@kwara/models/src';

function createSasra({
  startDate,
  endDate,
  reportTemplateId
}: {
  startDate: string;
  endDate: string;
  reportTemplateId: string;
}) {
  const report = SasraReport.create({ startDate, endDate, reportTemplateId });
  return report.save();
}

export function useSasraCreate() {
  const queryClient = useQueryClient();
  const { mutate: onSasraCreate } = useMutation(createSasra, {
    onSettled(didSave: boolean) {
      if (didSave) {
        queryClient.invalidateQueries('sasra_reports');
      }
    }
  });

  return { onSasraCreate };
}
