import * as React from 'react';

import { TextField } from '@kwara/components/src/Form';

import { useFilterContext } from '../..';
import { FILTER_SELECTION_VERBS } from '../../utils/filterTypes';

type TextboxBaseFilterPropTypes = {
  name: string;
  filterName: string;
  placeholderId: string;
};

export function TextboxBaseFilter({ name, filterName, placeholderId }: TextboxBaseFilterPropTypes) {
  const { selectedFilters, onFilterValue } = useFilterContext();

  const value = selectedFilters?.[filterName]?.[0]?.value || '';

  return (
    <TextField
      name={filterName}
      value={value}
      placeholderId={placeholderId}
      size="medium"
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const hasValue = !!target.value?.length;

        onFilterValue({
          filterName: filterName,
          selectedValue: hasValue ? { name, value: target.value, belongsTo: filterName, renderWithValue: true } : null,
          verb: FILTER_SELECTION_VERBS.UPDATE
        });
      }}
    />
  );
}
