import * as React from 'react';

import { Text, Currency } from '@kwara/components/src/Intl';

import { Card } from '../../../../components/Card';
import { InfoPanel } from '../../../../components/InfoPanel';

type AnticipatedPayoutBreakdownPropTypes = {
  mambuFees: number | string;
  additionalFees: number | string;
  loanPayoffAmount: number | string;
  principalAmount: number | string;
  anticipatedPayout: number | string;
};

export function AnticipatedPayoutBreakdown(props: AnticipatedPayoutBreakdownPropTypes) {
  const { principalAmount, mambuFees, additionalFees, loanPayoffAmount, anticipatedPayout } = props;

  return (
    <InfoPanel>
      <Card>
        <table className="w-100 kw-weight-bold kw-text-small">
          <tbody>
            <tr className="b">
              <td>
                <Text id="Generic.AnticipatedPayout.principalAmount" />
              </td>
              <td className="tr">
                <Currency hideDecimals={false} value={principalAmount} />
              </td>
            </tr>

            <tr>
              <td>
                <Text id="Generic.AnticipatedPayout.mambuFees" />
              </td>
              <td className="tr red-600">
                <Currency hideDecimals={false} value={mambuFees} />
              </td>
            </tr>

            <tr>
              <td>
                <Text id="Generic.AnticipatedPayout.additionalFees" />
              </td>
              <td className="tr red-600">
                <Currency hideDecimals={false} value={additionalFees} />
              </td>
            </tr>

            <tr>
              <td>
                <Text id="Generic.AnticipatedPayout.loanPayoffAmount" />
              </td>
              <td className="tr red-600 bb b--black">
                <Currency hideDecimals={false} value={loanPayoffAmount} />
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr className="b">
              <td>
                <Text id="Generic.AnticipatedPayout.result" />
              </td>
              <td className="tr bb b--black">
                <Currency hideDecimals={false} value={anticipatedPayout} />
              </td>
            </tr>
          </tfoot>
        </table>
      </Card>
    </InfoPanel>
  );
}
