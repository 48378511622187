import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';

import { LoanType } from '@kwara/models/src/models/Loan';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';

import { LoanAddData } from '../../..';
import { store } from '../../../../../../models/Store/Store';

export class LoanConfigureUtils {
  public static matchingLoans({ product = {} as LoanProductType, loans = [] }: LoanAddData) {
    const canRefinance = (loan: LoanType) => store.refinanceEnabled && loan.canRefinance();
    const sameProduct = (loan: LoanType) => get('product.id', loan) === product.id;

    return pipe(filter(canRefinance), filter(sameProduct))(loans);
  }

  public static hasExactLoan(data: LoanAddData) {
    return !!size(LoanConfigureUtils.matchingLoans(data));
  }
}
