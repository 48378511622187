import * as React from 'react';
import map from 'lodash/fp/map';
import { Form } from 'react-final-form';
import cx from 'classnames';
import some from 'lodash/fp/some';

import createValidator from '@kwara/lib/src/validator';
import Button from '@kwara/components/src/Button';
import { Text, Date, DateTime } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { Link } from '@kwara/components/src/Link';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { AccountingReport, AccountingReportT, ReportTemplateT } from '@kwara/models/src';
import { formatIsoDate, getCurrentDate, isAfter, differenceInDays, addDays, min } from '@kwara/lib/src/dates';
import { If } from '@kwara/components/src/If/If';

import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';
import { ButtonMenu } from '../../../../components/ButtonMenu';
import { BodyContentWrapper } from '../../../../layouts';
import Table, { Row, Cell, Heading, Footer } from '../../../../components/Table';
import { useReportTemplates } from '../../../../models/hooks/useReportTemplates/useReportTemplates';
import { useAccountingReports } from '../../useAccountingReports';
import { useSasraDownload } from '../../useSasraDownload';

import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';
import { useThrottledCallback } from '../../../../hooks';

import styles from '../../index.module.scss';

const MAX_DATE_RANGE = 366;

interface SubmissionErrorT {
  ACCOUNTING_REPORT_GENERATION_ERROR: {
    code: 'ACCOUNTING_REPORT_GENERATION_ERROR';
  };
}

interface Payload {
  startDate: string;
  endDate: string;
}

async function createReport({ startDate, endDate }: Payload) {
  const report = new AccountingReport({ startDate: formatIsoDate(startDate), endDate: formatIsoDate(endDate) });
  const didSave = await report.save();

  if (!didSave) {
    throw report.errors;
  }
}

function GenerateReportForm({ onSubmit }: { onSubmit: (data: Payload) => Promise<void | SubmissionErrorT> }) {
  return (
    <Form
      validate={createValidator({
        startDate: {
          isRequired: () => true,
          isNotFuture: true
        },
        endDate: {
          isRequired: () => true,
          isNotFuture: true,
          custom: (endDate: string, { startDate }: { startDate?: string }) => {
            if (isAfter(startDate, endDate)) {
              return 'START_DATE_AFTER_END_DATE';
            }

            if (differenceInDays(startDate, endDate) > MAX_DATE_RANGE) {
              return 'MAXIMUM_RANGE_EXCEEDED';
            }

            return null;
          }
        }
      })}
      onSubmit={onSubmit}
      render={({ form, hasValidationErrors, handleSubmit, hasSubmitErrors, submitSucceeded, submitting, values }) => {
        const { startDate } = values;
        const daysInFuture = addDays(startDate, MAX_DATE_RANGE);
        const maxEndDate = min(getCurrentDate(), daysInFuture);

        if (submitSucceeded) {
          form.reset();
        }

        return (
          <form className="w-100 flex items-center" aria-label="Account Report Form" onSubmit={handleSubmit}>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: getCurrentDate() }}
                showInfo={false}
                compact
                margin={false}
                required
                name="startDate"
                leftGlyph="AccountingReports.Index.Form.StartDate.leftGlyph"
              />
            </div>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: maxEndDate }}
                showInfo={false}
                compact
                margin={false}
                required
                name="endDate"
                leftGlyph="AccountingReports.Index.Form.EndDate.leftGlyph"
              />
            </div>

            <div className={styles.submit}>
              <Button
                disabled={hasValidationErrors || submitting}
                isSubmit
                type="primary"
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="AccountingReports.Index.Form.submit.generate" />
              </Button>
              <If
                condition={hasSubmitErrors}
                do={
                  <span className={cx('red-500', styles.submit__error)}>
                    <Text id="AccountingReports.Index.Form.submit.error" />
                  </span>
                }
              />
            </div>
          </form>
        );
      }}
    />
  );
}

interface ReportsTableT {
  reports: AccountingReportT[];
  isLoading: boolean;
  hasMore: boolean;
  onNext: () => void;
  sasraTemplates: ReportTemplateT[];
  onSasraGenerate(template: ReportTemplateT, report: AccountingReportT): Promise<void>;
  isSasraDownloading: boolean;
  showSasraColumn: boolean;
}

function ReportsTable({
  reports,
  isLoading,
  hasMore,
  onNext,
  sasraTemplates = [],
  onSasraGenerate,
  isSasraDownloading = false,
  showSasraColumn
}: ReportsTableT) {
  const heading = (
    <Row>
      <Heading translationId="AccountingReports.Index.Table.header.startDate" />
      <Heading translationId="AccountingReports.Index.Table.header.endDate" />
      <Heading translationId="AccountingReports.Index.Table.header.user" />
      <Heading translationId="AccountingReports.Index.Table.header.createdAt" />
      <Heading translationId="AccountingReports.Index.Table.header.state" />
      {showSasraColumn ? <Heading translationId="AccountingReports.Index.Table.header.sasraShortcut" /> : null}
      <Heading width="100px" />
    </Row>
  );

  return (
    <Table
      heading={heading}
      footer={
        <Footer
          colSpan={heading.props.children.length}
          hasMore={hasMore}
          isLoading={isLoading}
          onNext={onNext}
          items={reports}
        />
      }
    >
      {map(report => {
        return (
          <Row key={report.id}>
            <Cell>
              <Date value={report.startDate} />
            </Cell>
            <Cell>
              <Date value={report.endDate} />
            </Cell>
            <Cell>{report.user.fullName()}</Cell>
            <Cell>
              <DateTime value={report.createdAt} />
            </Cell>
            <Cell>
              <StatusTag state={report.state} />
            </Cell>
            <If
              condition={showSasraColumn}
              do={
                <Cell style={{ overflow: 'visible' }}>
                  <If
                    condition={report.state === 'complete'}
                    do={
                      <ButtonMenu
                        disabled={isSasraDownloading}
                        Button={props => (
                          <span
                            {...props}
                            role="button"
                            className={cx({ 'o-70': isSasraDownloading, pointer: !isSasraDownloading })}
                          >
                            <Text id="AccountingReports.Index.Table.sasra.button.generate" />
                          </span>
                        )}
                      >
                        {sasraTemplates.map(template => (
                          <ButtonMenu.Item
                            disabled={isSasraDownloading}
                            onClick={() => onSasraGenerate(template, report)}
                            key={template.id}
                          >
                            {template.name}
                          </ButtonMenu.Item>
                        ))}
                      </ButtonMenu>
                    }
                  />
                </Cell>
              }
            />
            <Cell>
              <If
                condition={report.state === 'complete'}
                do={
                  <Link to={`/accounting/reports/${report.id}`}>
                    <Text id="AccountingReports.Index.Table.link.view" />
                  </Link>
                }
              />
            </Cell>
          </Row>
        );
      }, reports)}
    </Table>
  );
}

export function ReportsIndex() {
  const store = useSaccoProfileContext();
  const { refetch, ...r } = useAccountingReports();
  const { data: templates } = useReportTemplates();
  const { onGenerate, isSasraDownloading } = useSasraDownload();
  const { AppPermissions, permission } = usePermissions();

  function onSubmit(data: Payload) {
    return createReport(data)
      .then(() => {
        refetch();
      })
      .catch(e => e);
  }

  function refetchIfPending() {
    if (some(report => report.state === 'pending', r.allData)) {
      refetch();
    }
  }

  useThrottledCallback(refetchIfPending);

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <GenerateReportForm onSubmit={onSubmit} />
      </BodyContentWrapper.Row.Root>

      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id={'AccountingReports.ReportsIndex.title'} />
          </div>
          <ReportsTable
            reports={r.allData}
            isLoading={r.isFetching || r.isRefetching}
            onNext={r.fetchNextPage}
            hasMore={r.hasNextPage}
            sasraTemplates={templates}
            onSasraGenerate={onGenerate}
            isSasraDownloading={isSasraDownloading}
            showSasraColumn={store.isSasraDownloadLive && permission.to(AppPermissions.ManageSasraReports)}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
