import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { AutoDismissableCompletion } from '@kwara/components/src/Completion';

import { useWizardInternalContext } from '../..';
import { DelayedAnimation } from '../DelayedAnimation';
import { COMPLETION_STATE } from '../../utils/wizard';

const { Successful, Rejected } = COMPLETION_STATE;

/**
 * @CompletedSubtitle
 */
function CompletedSubtitle() {
  const { completionState, data, rejectSubtitleId, successSubtitleId } = useWizardInternalContext();

  const isRejected = completionState === Rejected && rejectSubtitleId != undefined;
  const isSuccessful = completionState === Successful && successSubtitleId != undefined;
  const textId = isRejected ? rejectSubtitleId : successSubtitleId;

  return <If condition={isRejected || isSuccessful} do={<Text id={textId} values={data} />} />;
}

/**
 * @RenderCompletion
 */
type RenderCompletionPropTypes = {
  onCancel(): void;
};
function RenderCompletion(props: RenderCompletionPropTypes) {
  const { analyticsId, successButtonId, completionAutoConfirm, completionState } = useWizardInternalContext();

  const wasFlowOperationSuccessful = completionState === Successful;
  const type = wasFlowOperationSuccessful ? AutoDismissableCompletion.Types.good : AutoDismissableCompletion.Types.bad;

  return (
    <AutoDismissableCompletion
      type={type}
      onConfirm={props.onCancel}
      buttonTextId={successButtonId}
      analyticsId={analyticsId}
      autoconfirmTimeoutSecs={4}
      autoconfirm={completionAutoConfirm}
      subtitle={<CompletedSubtitle />}
    />
  );
}

/**
 * @RenderAnimatedCompletion
 */
function RenderAnimatedCompletion() {
  const { cancel } = useWizardInternalContext();

  return <DelayedAnimation onDone={cancel}>{({ hide }) => <RenderCompletion onCancel={hide} />}</DelayedAnimation>;
}

/**
 * @RenderCompletionBanner
 */
function RenderCompletionBanner() {
  const { cancel, notification, bannerContent } = useWizardInternalContext();

  React.useEffect(() => {
    if (bannerContent) {
      notification.displayMessage(bannerContent);

      cancel();
    }
  }, [bannerContent, cancel, notification]);

  return null;
}

/**
 * @HandleCompletion
 */
export function HandleCompletion() {
  const {
    animated,
    bannerContent,
    data,
    cancel,
    showCompletion,
    onRenderCustomCompletionScreen
  } = useWizardInternalContext();

  if (showCompletion === false) return null;

  if (!!onRenderCustomCompletionScreen) return onRenderCustomCompletionScreen(cancel, data);

  if (animated) return <RenderAnimatedCompletion />;

  if (bannerContent) return <RenderCompletionBanner />;

  return <RenderCompletion onCancel={cancel} />;
}
