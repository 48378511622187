import React from 'react';
import get from 'lodash/fp/get';

import { If } from '@kwara/components/src/If/If';
import { SubscribedTextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';
import { Text } from '@kwara/components/src/Intl';
import { LinkButton } from '@kwara/components/src/LinkButton';

type Props = {
  name: string;
  className?: string;
  formValues: Record<string, any>;
  disabled?: boolean;
};

export function Notes({ name, className, formValues, disabled }: Props) {
  const hasNotes = !!get(name, formValues);

  const [showNoteInput, setShowNoteInput] = React.useState(hasNotes);

  return (
    <div className={className}>
      <If
        condition={showNoteInput}
        do={
          <SubscribedTextArea
            name={name}
            labelId="LoanAdd.PayOff.notes.label"
            placeholderId="LoanAdd.PayOff.notes.placeholder"
          />
        }
        else={
          <LinkButton as="button" disabled={disabled} onClick={() => setShowNoteInput(true)}>
            <Text id="LoanAdd.PayOff.addNotes" />
          </LinkButton>
        }
      />
    </div>
  );
}
