import * as React from 'react';
import cx from 'classnames';

import CardContainer from '@kwara/components/src/CardContainer';

type IndicatorsPropTypes = {
  printOverview?: boolean;
  children: React.ReactNode;
};
export function Indicators({ children, printOverview = false }: IndicatorsPropTypes) {
  return <div className={cx(!printOverview && 'hide-on-print')}>{children}</div>;
}

type TablePropTypes = {
  children: React.ReactNode;
};
export function Table(props: TablePropTypes) {
  return <CardContainer className="pa3">{props.children}</CardContainer>;
}
