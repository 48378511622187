import { useQuery } from 'react-query';
import { ReportTemplate } from '@kwara/models/src';

async function fetchTemplates() {
  return await ReportTemplate.all().then(res => res.data);
}

export function useReportTemplates() {
  return useQuery('report_templates', fetchTemplates, {
    placeholderData: []
  });
}
