import * as React from 'react';

import { If } from '@kwara/components/src/If/If';

import { usePermissions } from '../../hooks';

type CtoRbacPropTypes = {
  children: React.ReactNode;
};

/// Meant for only CTOs
export function CtoRbac(props: CtoRbacPropTypes) {
  const { permission } = usePermissions();

  return <If condition={permission.checkIsCTO()} do={props.children} />;
}

/// Meant for only non CTOs
export function NonCtoRbac(props: CtoRbacPropTypes) {
  const { permission } = usePermissions();

  return <If condition={!permission.checkIsCTO()} do={props.children} />;
}
