// @flow

import * as React from 'react';
import Downshift, { DownshiftState, DownshiftProps } from 'downshift';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import zIndices from '@kwara/lib/src/zIndices';
import { Text } from '@kwara/components/src/Intl';
import { Stack } from '@kwara/components/src/Stack';
import { Member, type MemberType, type IncludeT } from '@kwara/models/src';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { type StackChildSize } from '@kwara/components/src/Wizard/deprecated/Wizard';

import styles from './index.module.scss';
import ResultLabel from './ResultLabel';
import SearchManager from '../SearchManager';
import SearchTextField from './SearchTextField';
import ResultList, { type Props as ResultListProps } from './ResultList';

///UTILS BELOW
/***************************************************************************/
type SharedPropTypes = {
  floatResults: boolean,
  showResultCount: boolean,
  resultListClassName?: string,
  searchBarSize?: StackChildSize,
  labelProps?: {
    id: string,
    values?: {
      [key: string]: mixed
    }
  }
};

type ChangeHandler = (evt: SyntheticInputEvent<>) => void;
type GetInputProps = () => { onChange: ChangeHandler, value: string };

///COMPONENTS BELOW
/*********************************************************************/
type ControlledPropTypes = ResultListProps &
  SharedPropTypes & {
    value: string,
    searching: boolean,
    resultsVisible: boolean,
    getInputProps: GetInputProps
  };

export const Controlled = ({
  collapsed,
  floatResults,
  getInputProps,
  getItemProps,
  isItemDisabled,
  results,
  resultsVisible,
  resultType,
  highlightedIndex,
  searching,
  showResultCount,
  value,
  resultListClassName,
  searchBarSize = 'regular',
  labelProps,
  ...rest
}: ControlledPropTypes) => {
  const [labelId] = useUniqueIds(1);

  return (
    <div
      className={classnames(
        styles.MemberSearch,
        showResultCount ? styles.withResultCount : styles.withoutResultCount,
        floatResults ? styles.floatResults : null
      )}
      data-testid="MemberSearch"
    >
      <Stack align={floatResults ? 'start' : 'center'}>
        <Stack.Child size={searchBarSize}>
          {labelProps && (
            <label htmlFor={labelId} className={classnames(styles.Label, 'kw-text-medium kw-weight-bold')}>
              <Text {...labelProps} />
            </label>
          )}
          <SearchTextField
            {...getInputProps({})}
            id={labelId}
            data-cy={rest['data-cy']}
            name="member-search-input"
            searching={searching}
          />
        </Stack.Child>

        {resultsVisible && showResultCount && (
          <Stack.Child>
            <ResultLabel numResults={results.length} searchTerm={value} />
          </Stack.Child>
        )}

        {resultsVisible && (
          <div className={classnames(styles.ResultContainer, zIndices.Navigation, resultListClassName)}>
            <Stack.Child size="wide">
              <ResultList
                collapsed={collapsed}
                floatResults={floatResults}
                highlightedIndex={highlightedIndex}
                getItemProps={getItemProps}
                results={results}
                resultType={resultType}
                searching={searching}
                showResultCount={showResultCount}
                value={value}
                isItemDisabled={isItemDisabled}
              />
            </Stack.Child>
          </div>
        )}
      </Stack>
    </div>
  );
};

/*********************************************************************/
interface MemberSearchPropTypes extends SharedPropTypes {
  collapsed: boolean;
  'data-cy'?: string;
  resultListClassName?: string;
  resultType: 'financial' | 'nonFinancial';
  isItemDisabled?: (member: MemberType) => boolean;
  onSelect: (member: MemberType) => void | Promise<void>;
  includes?: IncludeT;
}

export default class MemberSearch extends React.Component<MemberSearchPropTypes, *> {
  static defaultProps = {
    collapsed: false,
    floatResults: false,
    resultType: 'nonFinancial',
    showResultCount: true,
    includes: []
  };

  static inputThrottleMs = 1000;

  constructor(props: Props) {
    super(props);

    this._searchManager = new SearchManager(Member);
    this._searchManager.results(this.receiveResults);
    this._searchManager.stateChange(this.searchStateChange);
  }

  _searchManager: SearchManager;

  state = {
    isSearchPending: false,
    items: []
  };

  receiveResults = (items: MemberType[]) => {
    this.setState({ items });
  };

  searchStateChange = ({ isSearchPending }: { isSearchPending: boolean }) => {
    this.setState({
      isSearchPending
    });
  };

  reset = () => {
    this._searchManager.cancelPendingSearches();
    this.setState({ items: [] });
  };

  _search = async (inputValue: string) => {
    if (inputValue == null || inputValue === '') {
      this.reset();
      return;
    }

    this._searchManager.search({ term: inputValue, limit: 20 });
  };

  search = throttle(this._search, MemberSearch.inputThrottleMs, {
    leading: true,
    trailing: true
  });

  select = (member: MemberType, { clearSelection }: { clearSelection: () => void }) => {
    if (member == null) {
      return;
    }

    if (this.props.returnFullMember) {
      this.props.onFetchStart && this.props.onFetchStart();

      return Member.select(this.props.selectedAttributes)
        .includes(this.props.includes)
        .find(member.id)
        .then(r => r.data)
        .then(fullMember => {
          this.props.onSelect(fullMember);
        })
        .finally(() => {
          this.reset();
          clearSelection();
          this.props.onFetchEnd && this.props.onFetchEnd();
        });
    }

    this.props.onSelect(member);
    this.reset();
    clearSelection();
  };

  getMemberName = (member: MemberType) => {
    return member ? member.fullName() : null;
  };

  stateChange = (changes: DownshiftState) => {
    if (changes.hasOwnProperty('inputValue')) {
      this.search(changes.inputValue);
    }
  };

  stateReducer = (state: DownshiftState, changes: DownshiftState) => {
    // Clear input when an item is selected
    if (changes.hasOwnProperty('selectedItem')) {
      return {
        ...changes,
        inputValue: ''
      };
      // Clears input when is closes (for example, Escape key is pressed)
    } else if (changes.hasOwnProperty('isOpen') && changes.isOpen === false) {
      return {
        ...changes,
        inputValue: '',
        isOpen: state.isOpen
      };
    }

    return changes;
  };

  renderSearch = ({ getInputProps, getItemProps, inputValue, isOpen, highlightedIndex }: DownshiftProps) => {
    return (
      <div>
        <Controlled
          resultListClassName={this.props.resultListClassName}
          collapsed={this.props.collapsed}
          floatResults={this.props.floatResults}
          getInputProps={getInputProps}
          highlightedIndex={highlightedIndex}
          resultType={this.props.resultType}
          results={this.state.items}
          resultsVisible={isOpen && !!inputValue?.length}
          value={inputValue}
          labelProps={this.props.labelProps}
          getItemProps={getItemProps}
          searching={this.state.isSearchPending}
          showResultCount={this.props.showResultCount}
          isItemDisabled={this.props.isItemDisabled}
          data-cy={this.props['data-cy']}
          searchBarSize={this.props.searchBarSize}
        />
      </div>
    );
  };

  render() {
    return (
      <Downshift
        itemToString={this.getMemberName}
        onChange={this.select}
        onStateChange={this.stateChange}
        stateReducer={this.stateReducer}
      >
        {this.renderSearch}
      </Downshift>
    );
  }
}
