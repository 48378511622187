// @flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { DocumentList } from '../../../components/DocumentList';
import { type SubStepComponentProps } from '../../../components/Wizard';
import { type WizardData } from '..';

const Terms = ({ Checkbox, StackChild }: SubStepComponentProps<WizardData>) => {
  return (
    <StackChild>
      <div className="mb5">
        <h4 className="kw-text-small kw-weight-normal grey-400">
          <Text id="MemberApprove.Terms.Document.title" />
        </h4>
        <DocumentList />
      </div>
      <Checkbox name="acceptsTerms" labelId="MemberApprove.Terms.Agree.label" />
    </StackChild>
  );
};

Terms.validate = {
  acceptsTerms: { isRequired: () => true }
};

export default Terms;
