import { useState, useEffect } from 'react';

import { ErrorType } from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import { OnControlNextButton } from '@kwara/components/src/Wizard/deprecated/SubStep';

import verifyIdentityInstance, { ERROR_CODES } from './utils/verifyIdentity';
import { useVerifyIdentityContext, BUTTONS_APPEAR_AS, VerifyIdentityContextType } from '../..';

export type UseIdentityInputArg = {
  isPrimaryId: boolean;
  setError: (err: ErrorType | null) => void;
  disableNextButton: (onControlledState?: OnControlNextButton) => void;
  enableNextButton: (onControlledState?: OnControlNextButton) => void;
  data: { type: string; firstName: string; lastName: string; documentId: string };
};

export function useIdentityInput(arg: UseIdentityInputArg) {
  const { isPrimaryId, data, setError, disableNextButton, enableNextButton } = arg;

  const [isRetrying, setIsRetrying] = useState(false);
  const verifyIdContext: VerifyIdentityContextType = useVerifyIdentityContext();

  const { error, cache } = verifyIdentityInstance.state;
  const itsDocumentError = ([ERROR_CODES.IDENTITY_TAKEN, ERROR_CODES.NOT_FOUND] as string[]).includes(error.code);
  const canReverify = error.code === ERROR_CODES.RETRY || itsDocumentError;
  const shouldEnableNextButton = error.code === ERROR_CODES.GENERIC;
  const isVerifying = isPrimaryId && isRetrying;
  const caughtError = isPrimaryId && error.hasError;

  useEffect(() => {
    if (canReverify) disableNextButton();
  }, [canReverify, disableNextButton]);

  useEffect(() => {
    if (shouldEnableNextButton) {
      verifyIdContext?.setAppearAs(BUTTONS_APPEAR_AS.NEXT);
      enableNextButton();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableNextButton, shouldEnableNextButton]);

  function onChangeHandler() {
    if (isPrimaryId) {
      verifyIdentityInstance.resetState();
      enableNextButton();
    }
  }

  function onIsVerifyingIdentity() {
    setIsRetrying(true);
  }

  function onVerifyIdentitySuccess() {
    setIsRetrying(false);
    setError(null);
  }

  function onVerifyIdentityFailure() {
    setIsRetrying(false);
  }

  async function onVerifyIdentity() {
    try {
      const { firstName, lastName, type, documentId } = data;

      await verifyIdentityInstance.startVerifying(
        { firstName, lastName, idDocuments: [{ type, documentId }] },
        {
          type: 'RETRY',
          onIsLoading: onIsVerifyingIdentity,
          onSuccess: onVerifyIdentitySuccess,
          onFailure: onVerifyIdentityFailure
        }
      );
    } catch (verifyIdError) {
      setError(verifyIdError);
    }
  }

  return {
    error,
    caughtError,
    isVerifying,
    cache,
    onChangeHandler,
    onVerifyIdentity
  };
}
