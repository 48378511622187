import React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

import { Cell } from '../../../../../../../components/Table';

import styles from './index.module.scss';

type ColumnPropTypes = {
  colSpan: number;
  listItems: { key: string; value: React.ReactNode; hidden?: boolean }[];
  titleTranslationId: string;
};

export function Column({ titleTranslationId, listItems, colSpan }: ColumnPropTypes) {
  return (
    <Cell className={styles['tbody-details-row__col']} colSpan={colSpan}>
      <h2>
        <Text id={`Finance.LoanDisbursement.Table.Body.Column.${titleTranslationId}`} />
      </h2>
      <ul>
        {listItems.map(item => (
          <If
            key={item.key}
            condition={item.hidden}
            do={<li key={item.key} />}
            else={
              <li>
                <section>
                  <h3>
                    <Text id={`Finance.LoanDisbursement.Table.Body.Column.${item.key}`} />
                  </h3>
                  <div>{item.value}</div>
                </section>
              </li>
            }
          />
        ))}
      </ul>
    </Cell>
  );
}
