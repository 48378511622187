import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import capitalize from 'lodash/fp/capitalize';

import { Text, Date, Percent, Currency, Number } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { Link } from '@kwara/components/src/Link';
import { If } from '@kwara/components/src/If/If';

import { BodyContentWrapper } from '../../../../../../layouts';
import { ButtonMenu } from '../../../../../../components/ButtonMenu';
import { DividendReportsT, DividendReportState } from '../../../../../../models/DividendReports/DividendReports';
import { HeaderSubHeader } from '../../../../../../components/HeaderSubHeader';
import OverviewList from '../../../../../../components/OverviewList';
import Table, { Row, Cell, Heading, Footer } from '../../../../../../components/Table';
import { useDividendReports } from '../../useDividendReports';

interface ReportsTableT {
  items: DividendReportsT[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

const STATUS_FILTERS = [
  { value: '', translationId: 'Finance.DividendReports.Filter.Status.all' },
  ...map(
    value => ({
      value,
      translationId: `Finance.DividendReports.Filter.Status.${value}`
    }),
    DividendReportState
  )
];

function ReportsTable(props: ReportsTableT) {
  const { items, hasNextPage, fetchNextPage, isLoading, ...rest } = props;

  return (
    <Table
      heading={
        <Row>
          <Heading width="210px" translationId="Finance.DividendReports.Table.header.date" />
          <Heading translationId="Finance.DividendReports.Table.header.product" />
          <Heading translationId="Finance.DividendReports.Table.header.percentage" />
          <Heading width="140px" translationId="Finance.DividendReports.Table.header.allocatedDividendAmount" />
          <Heading width="140px" translationId="Finance.DividendReports.Table.header.calculatedNetDividendAmount" />
          <Heading translationId="Finance.DividendReports.Table.header.totalNumberOfMembers" />
          <Heading translationId="Finance.DividendReports.Table.header.calculationMethod" />
          <Heading translationId="Finance.DividendReports.Table.header.createdAt" />
          <Heading translationId="Finance.DividendReports.Table.header.state" />
          <Heading width="100px" />
        </Row>
      }
      footer={
        <Footer
          items={items}
          colSpan={10}
          isLoading={isLoading}
          hasMore={hasNextPage}
          onNext={fetchNextPage}
          {...rest}
        />
      }
    >
      {map(report => {
        return (
          <Row key={report.id}>
            <Cell>
              <Date value={report.startDate} />-<Date value={report.endDate} />
            </Cell>
            <Cell>{get('name', report.product)}</Cell>
            <Cell>
              <Percent value={report.dividendPercentage} />
            </Cell>
            <Cell>
              <Currency value={report.allocatedDividendAmount} />
            </Cell>
            <Cell>
              <Currency value={report.calculatedNetDividendAmount} />
            </Cell>
            <Cell>
              <If condition={!!report.totalNumberOfMembers} do={<Number value={report.totalNumberOfMembers} />} />
            </Cell>
            <Cell>{capitalize(report.calculationMethod)}</Cell>
            <Cell>
              <Date value={report.createdAt} />
            </Cell>
            <Cell>
              <StatusTag state={report.state} />
            </Cell>
            <Cell>
              <If
                condition={report.state === 'completed'}
                do={
                  <Link to={`/finance/dividends/reports/${report.id}/contributions`}>
                    <Text id="Finance.DividendReports.Table.link.view" />
                  </Link>
                }
              />
            </Cell>
          </Row>
        );
      }, items)}
    </Table>
  );
}

export function DividendReportsIndex() {
  const { filterValue, onFilterChange, isLoading, isFetchingNextPage, allData, ...r } = useDividendReports();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root hasTabs>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.DividendReports.title" subtitleId="Finance.DividendReports.subtitle" />
          <OverviewList
            labelId="Finance.DividendReports.Table.List.label"
            headerId="Finance.DividendReports.Index.title"
            filterLabelId="OverviewList.filterByStatusLabel"
            filterBy={filterValue}
            filters={STATUS_FILTERS}
            onFilterChange={onFilterChange}
            items={allData}
            totalNumResults={r.totalResults}
            table={<ReportsTable items={allData} {...r} isLoading={isLoading || isFetchingNextPage} />}
            actions={[
              <ButtonMenu key="buttonmenu" title={<Text id="Finance.DividendReports.button.Generate" />}>
                <ButtonMenu.Item
                  className="flex-auto"
                  to="/finance/dividends/reports/dividend/generate"
                  key="generateDividends"
                >
                  <Text id="Finance.DividendReports.button.dividend.Generate" />
                </ButtonMenu.Item>
                <ButtonMenu.Item
                  className="flex-auto"
                  to="/finance/dividends/reports/interest/generate"
                  key="generateInterest"
                >
                  <Text id="Finance.DividendReports.button.interest.Generate" />
                </ButtonMenu.Item>
              </ButtonMenu>
            ]}
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
