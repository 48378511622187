import * as React from 'react';
import { LoanSubstepProps } from 'GlobalTypes';

import { useSchedule } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { Text } from '@kwara/components/src/Intl';

import { Schedule } from '../../../../../LoanDetail/components/Schedule';

import styles from '../index.module.scss';

export function ScheduleTable({ data }: LoanSubstepProps) {
  const { loan } = data;
  const scheduleR = useSchedule(loan.id);

  return (
    <Loadable {...scheduleR}>
      {schedule => (
        <section className={styles.section}>
          <div className="flex justify-between pb3">
            <h2 className="kw-text-x-large kw-weight-bold">
              <Text id="LoanAppraisal.Schedule.title" />
            </h2>
            <DownloadButton downloadFn={loan.downloadSchedulePdf} instance={loan} />
          </div>
          <Schedule schedule={schedule} />
        </section>
      )}
    </Loadable>
  );
}
