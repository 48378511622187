import { useRef, useEffect, useCallback, useMemo } from 'react';

import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';

import { StyleUtils } from './StyleUtils';

export function useButtonMenuActions() {
  const menuRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const { current: styleUtils } = useRef(new StyleUtils(parentRef, menuRef));
  const [isOpen, { toggle, setToTrue: open, setToFalse: close }] = useBoolean();
  const prevIsOpen = useRef(isOpen);

  const outsideClick = useCallback(
    (evt: MouseEvent) => {
      if (!parentRef.current) return;

      const isOutside = !parentRef.current.contains(evt.target as Node);

      if (isOutside) close();
    },
    [close]
  );

  const accessibleKeyboardActions = useMemo(() => ({ Escape: close }), [close]);

  const keyPress = useCallback(
    (evt: KeyboardEvent) => {
      accessibleKeyboardActions[evt.key]?.();
    },
    [accessibleKeyboardActions]
  );

  const addOutsideEventListener = useCallback(() => {
    if (document.body) {
      document.body.addEventListener('click', outsideClick, true);
      document.body.addEventListener('keyup', keyPress, true);
    }
  }, [keyPress, outsideClick]);

  const removeOutsideEventListener = useCallback(() => {
    if (document.body) {
      document.body.removeEventListener('click', outsideClick, true);
      document.body.removeEventListener('keyup', keyPress, true);
    }
  }, [keyPress, outsideClick]);

  const getPopupContainer = useCallback(() => parentRef.current, []);

  const onMenuDidOpen = useCallback(() => {
    const menuDidOpen = prevIsOpen.current === false && isOpen === true;

    if (menuDidOpen) {
      addOutsideEventListener();
      styleUtils.adjustMenuPosition();
    }
  }, [addOutsideEventListener, isOpen, styleUtils]);

  const onMenuDidClose = useCallback(() => {
    const menuDidClose = prevIsOpen.current === true && isOpen === false;

    if (menuDidClose) {
      removeOutsideEventListener();
    }
  }, [isOpen, removeOutsideEventListener]);

  useEffect(() => {
    onMenuDidOpen();
    onMenuDidClose();

    prevIsOpen.current = isOpen;

    return removeOutsideEventListener;
  }, [addOutsideEventListener, isOpen, onMenuDidClose, onMenuDidOpen, removeOutsideEventListener, styleUtils]);

  return {
    parentRef,
    menuRef,
    isOpen,
    toggle,
    open,
    close,
    getPopupContainer
  };
}
