// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';

import { settingPath } from '../../../../lib/urls';
import { useSavingProducts } from '../../../../hooks';

import { ProductTable } from './SavingProductsTable';
import { Header } from '../Header';
import { BodyContentWrapper } from '../../../../layouts';

export const Savings = () => {
  const r = useSavingProducts();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <Header
            titleId="Settings.Savings.titleId"
            subtitleId="Settings.Savings.subtitleId"
            action={
              <Button to={settingPath({ baseExtension: 'saving', action: 'new' })} type="primary">
                <Text id="Settings.Savings.add" />
              </Button>
            }
          />
          <Loadable {...r}>{products => <ProductTable products={products} />}</Loadable>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
