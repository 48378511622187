//@flow

import React from 'react';
import { Form } from 'react-final-form';

import { Text, Currency } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Button from '@kwara/components/src/Button';
import { SubscribedTextField } from '@kwara/components/src/Form';
import createValidator from '@kwara/lib/src/validator';

import { Panel } from '../../../../../components/ActionModal';

import { useTillUIContext } from '../../..';

export function ConfirmationClose() {
  const { updateModal, currentTillSession, endTillSession } = useTillUIContext();

  return (
    <Modal ariaLabel="Confirm End Session" isOpen>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel>
          <Form
            validate={createValidator({
              amountBackToVault: {
                isRequired: () => true,
                currency: true,
                max: currentTillSession.currentBalance
              }
            })}
            onSubmit={endTillSession}
            render={({ valid, form }) => {
              return (
                <>
                  <div className="flex justify-between">
                    <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                      <Text id="Till.Confirmation.Modal.main" />
                    </h2>
                  </div>
                  <p className="kw-weight-light">
                    <Text id="Till.Confirmation.Modal.subtitle" />
                  </p>
                  <SubscribedTextField
                    required
                    isCurrency
                    sensitiveValidation
                    name="amountBackToVault"
                    labelId="Till.Confirmation.Modal.amountBackToVault.label"
                    info={
                      <Text
                        id="Till.Confirmation.Modal.amountBackToVault.info"
                        values={{ val: <Currency format="currency" value={currentTillSession.currentBalance} /> }}
                      />
                    }
                  />
                  <ButtonBar
                    className="mt4"
                    left={
                      <Button onClick={() => updateModal({ activeModal: null })}>
                        <Text id="Till.Confirmation.Modal.cancel" />
                      </Button>
                    }
                    right={
                      <Button disabled={!valid} classNames="mr3" type="primary" onClick={form.submit}>
                        <Text id="Till.Confirmation.Modal.confirm" />
                      </Button>
                    }
                  />
                </>
              );
            }}
          />
        </Panel>
      </div>
    </Modal>
  );
}
