import React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { If } from '@kwara/components/src/If/If';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { Actions } from '@kwara/models/src/models/LoanApplication';
import { FloatBalanceType } from '@kwara/models/src/models/FloatBalance';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';
import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';
import { useFloatBalance } from '@kwara/models/src/models/FloatBalance/useFloatBalance';
import { LoanDisbursementAppraisalsType } from '@kwara/models/src/models/LoanDisbursementAppraisals';

import { LoanDisbursalPropTypes } from '../..';
import { ApprovedAmountMeta } from './ApprovedAmountMeta';
import { Notes } from '../../../../../components/Notes/Notes';
import { DisbursementMethodForm } from './DisbursementMethodForm';
import { FloatBalanceBreakdown } from '../../../components/FloatBalanceBreakdown';

import styles from './index.module.scss';

interface FirstDisburserPropTypes extends LoanDisbursalPropTypes {
  disbursementAmount: number;
  isFirstDisburserActive: boolean;
  firstDisburser: LoanDisbursementAppraisalsType;
}

export function FirstDisburser(props: FirstDisburserPropTypes) {
  const {
    TextField,
    data: { loan },
    formProps: { values },
    firstDisburser,
    disbursementAmount,
    isFirstDisburserActive,
    addData,
    onChange,
    enableNextButton,
    disableNextButton
  } = props;
  const { floatBalance, phoneNumberProvider, paymentMethod } = values;
  const isMobileMpesaInstant = paymentMethod === TransactionChannels.mobileMpesaInstant;
  const isRefinanceAction = loan.loanApplication?.action === Actions.REFINANCE;

  const { isLoading: isLoadingFloatBal, error: floatBalError } = useFloatBalance({
    onStart: disableNextButton,
    onError: _ => enableNextButton(),
    async onSuccess({ amount }: FloatBalanceType) {
      await addData({ floatBalance: amount });
      await onChange({ floatBalance: amount });
      enableNextButton();
    },
    enabled: isMobileMpesaInstant && isFirstDisburserActive
  });

  return (
    <>
      <If
        condition={isFirstDisburserActive}
        do={
          <>
            <div className={styles.payment}>
              <DisbursementMethodForm {...props} disbursementAmount={disbursementAmount} />
            </div>
            <div className={styles.date}>
              <SubscribedDatePicker
                name="valueDate"
                size="medium"
                labelId="LoanDisburse.dateLabel"
                disabled={isRefinanceAction}
                disabledDays={{ after: getCurrentDate() }}
                required
              />
            </div>
          </>
        }
      />
      <div aria-label="First Disburser">
        <TextField
          initialValue={get('amount', firstDisburser)}
          type="number"
          name="disburserOneAmount"
          labelId="LoanDisbursal.Approvals.firstDisbursementAmount.label"
          leftGlyph="Currency.orgCurrency"
          readOnly={!isFirstDisburserActive}
          disabled={!isFirstDisburserActive}
          margin={false}
          isCurrency
          required
        />
        <Loadable
          isLoading={isLoadingFloatBal}
          error={floatBalError}
          loading={
            <div className="mt2">
              <LoadingSkeleton shape="radius-horizone" width="150px" height="20px" />
            </div>
          }
        >
          <FloatBalanceBreakdown
            shown={isMobileMpesaInstant}
            floatBalance={floatBalance}
            phoneNumberProvider={phoneNumberProvider}
          />
        </Loadable>
        <If
          condition={!!firstDisburser}
          do={
            <ApprovedAmountMeta
              note={get('notes', firstDisburser)}
              approvedBy={invoke('user.fullName', firstDisburser)}
              approvedAt={get('createdAt', firstDisburser)}
            />
          }
          else={<Notes name="disburserOneNote" className="mt3" formValues={values} />}
        />
      </div>
    </>
  );
}
