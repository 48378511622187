import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { useNotificationSystemContext } from '../..';
import { IconNotification } from '../../assets/IconNotification.svg';

import styles from './index.module.scss';

export function Icon() {
  const {
    iconRef,
    onToggleShownAnnouncement,
    hasReadAllAnnouncement,
    shownAnnouncements: bool
  } = useNotificationSystemContext();

  return (
    <button
      ref={iconRef}
      type="button"
      className={styles['container']}
      onClick={onToggleShownAnnouncement}
      data-testid="notificationIcon"
    >
      <AccessibleIcon label={`Click to ${bool ? 'close' : 'open'} announcement`}>
        <IconNotification />
      </AccessibleIcon>

      <If condition={!hasReadAllAnnouncement} do={<span data-testid="dot" className={styles['dot']} />} />
    </button>
  );
}
