import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconTillsElement = React.ElementRef<'svg'>;
interface IconTillsPropTypes extends PrimitiveSvgPropTypes {}

export const IconTills = React.forwardRef<IconTillsElement, IconTillsPropTypes>(function IconTills(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6901 11.53C18.1201 11.38 17.4501 11.3 16.6501 11.3C15.5401 11.3 15.1301 11.57 14.5601 12C14.5301 12.02 14.5001 12.05 14.4701 12.08L13.5201 13.09C12.7301 13.94 11.2801 13.94 10.4801 13.09L9.53005 12.09C9.50005 12.05 9.47005 12.02 9.44005 12C8.86005 11.57 8.45005 11.3 7.35005 11.3C6.55005 11.3 5.88005 11.37 5.31005 11.53C2.93005 12.17 2.93005 14.06 2.93005 15.72V16.65C2.93005 19.16 2.93005 22 8.28005 22H15.7201C19.2701 22 21.0701 20.2 21.0701 16.65V15.72C21.0701 14.06 21.0701 12.17 18.6901 11.53Z"
        fill="#475569"
      />
      <path
        d="M14.79 2H9.21004C4.79004 2 4.79004 4.35 4.79004 6.42V10.12C4.83004 10.1 4.88004 10.09 4.92004 10.08C5.62004 9.89 6.41004 9.8 7.35004 9.8C8.89004 9.8 9.62004 10.25 10.34 10.8C10.44 10.87 10.54 10.96 10.63 11.06L11.57 12.05C11.67 12.16 11.83 12.22 12 12.22C12.17 12.22 12.33 12.16 12.42 12.06L13.38 11.05C13.46 10.97 13.55 10.88 13.65 10.81C14.39 10.25 15.11 9.8 16.65 9.8C17.59 9.8 18.38 9.89 19.08 10.08C19.12 10.09 19.17 10.1 19.21 10.12V6.42C19.21 4.35 19.21 2 14.79 2ZM13.55 8.5H10.45C10.07 8.5 9.75004 8.18 9.75004 7.8C9.75004 7.41 10.07 7.1 10.45 7.1H13.55C13.93 7.1 14.25 7.41 14.25 7.8C14.25 8.18 13.93 8.5 13.55 8.5ZM14.33 5.71H9.67004C9.29004 5.71 8.98004 5.39 8.98004 5.01C8.98004 4.62 9.29004 4.31 9.67004 4.31H14.33C14.71 4.31 15.02 4.62 15.02 5.01C15.02 5.39 14.71 5.71 14.33 5.71Z"
        fill="#475569"
      />
    </svg>
  );
});

IconTills.defaultProps = {
  width: '25',
  height: '24'
};
