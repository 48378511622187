// @flow

import * as React from 'react';

import { LogoLayout } from '@kwara/components/src/LogoLayout';
import { Text } from '@kwara/components/src/Intl';
import { segmentTrack } from '@kwara/components/src/Segment';

import { AuthMfaVerifyForm } from './Form';
import { useAuth } from '../../../hooks';

export const VerifyMfa = ({ onVerify }: { onVerify: (a: Auth) => any }) => {
  const auth = useAuth();

  const logout = React.useCallback(async () => {
    return await auth.logOut().then(() => (window.location.href = '/'));
  }, [auth]);
  const onBack = React.useCallback(async () => {
    await logout();
    segmentTrack('2FA Verify Clicked Back');
  }, [logout]);

  return (
    <LogoLayout classNames="pa4">
      <div className="flex flex-column">
        <h1 className="kw-text-2x-large kw-weight-bold mb2">
          <Text id="Verify2FA.title" />
        </h1>
        <p className="grey-400">
          <Text id="Verify2FA.subtitle" />
        </p>
        <AuthMfaVerifyForm onVerify={onVerify} onBack={onBack} auth={auth} />
      </div>
    </LogoLayout>
  );
};
