import * as React from 'react';

import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';
import { type MemberType } from '@kwara/models/src';

import { Currency } from '../Currency';
import { Panel } from '../ActionModal';

type Props = {
  member: MemberType,
  showContactInfo: boolean,
  showFinancialInfo: boolean
};

const MemberPanel = ({ member, showContactInfo = false, showFinancialInfo = false }: Props) => (
  <Panel>
    <StatisticRow widthClass="w-100" border={false}>
      <Statistic
        title={<Text id="Payment.member" values={{ id: member.id }} />}
        value={member.fullName()}
        size="large"
        padding={false}
      />
    </StatisticRow>

    {showContactInfo && (
      <StatisticRow widthClass="w-50" border={true}>
        <Statistic title={<Text id="Payment.phoneNumber" />} size="medium" value={member.phoneNumber} />
        <Statistic title={<Text id="Payment.emailAddress" />} size="medium" value={member.email} breakWord />
      </StatisticRow>
    )}

    {showFinancialInfo && (
      <StatisticRow widthClass="w-50" border={true}>
        <Statistic
          title={<Text id="Payment.deposits" />}
          color="teal-600"
          size="medium"
          value={<Currency value={member.totalSavings} />}
        />
        <Statistic
          title={<Text id="Payment.loans" />}
          color="red-600"
          size="medium"
          value={<Currency value={member.totalLoansBalance} />}
        />
      </StatisticRow>
    )}
  </Panel>
);

export default MemberPanel;
