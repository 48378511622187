//@flow

import React from 'react';

const { useEffect, useState } = React;

type ReturnType = { x: number, y: number };
export function useMouseCoordinates(): ReturnType {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleWindowMouseMove = event => {
      setCoordinates({
        x: event.screenX,
        y: event.screenY
      });
    };
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, []);

  return coordinates;
}
