import React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { If } from '@kwara/components/src/If/If';
import { LoanDisbursementAppraisalsType } from '@kwara/models/src/models/LoanDisbursementAppraisals';

import { LoanDisbursalPropTypes } from '../..';
import { ApprovedAmountMeta } from './ApprovedAmountMeta';
import { Notes } from '../../../../../components/Notes/Notes';

interface SecondDisburserPropTypes extends LoanDisbursalPropTypes {
  isSecondDisburserActive: boolean;
  isSecondDisburserRequired: boolean;
  isFirstDisburserActive: boolean;
  secondDisburser: LoanDisbursementAppraisalsType;
}

export function SecondDisburser(props: SecondDisburserPropTypes) {
  const {
    TextField,
    formProps: { values },
    isSecondDisburserActive,
    isSecondDisburserRequired,
    secondDisburser,
    isFirstDisburserActive
  } = props;

  return (
    <If
      condition={isSecondDisburserRequired}
      do={
        <div aria-label="Second Disburser">
          <TextField
            type="number"
            name="disburserTwoAmount"
            leftGlyph="Currency.orgCurrency"
            readOnly={!isSecondDisburserActive}
            disabled={!isSecondDisburserActive}
            initialValue={get('amount', secondDisburser)}
            labelId="LoanDisbursal.Approvals.secondDisbursementAmount.label"
            margin={false}
            isCurrency
            required
          />
          <If
            condition={!!secondDisburser}
            do={
              <ApprovedAmountMeta
                note={get('notes', secondDisburser)}
                approvedBy={invoke('user.fullName', secondDisburser)}
                approvedAt={get('createdAt', secondDisburser)}
              />
            }
            else={
              <Notes name="disburserTwoNote" className="mt3" disabled={isFirstDisburserActive} formValues={values} />
            }
          />
        </div>
      }
    />
  );
}
