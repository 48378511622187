import { useQuery } from 'react-query';

import { MemberBaseStats } from '@kwara/models/src';

const fetchMemberBaseStats = async ({ queryKey }) => {
  const [_, where] = queryKey;
  const res = await MemberBaseStats.where(where).find();
  return res.data;
};

export const useMemberBaseStats = (where: { start_date: string; end_date: string }) =>
  useQuery(['member_base_stats', where], fetchMemberBaseStats);
