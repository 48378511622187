import * as React from 'react';

import { AccountingReportT } from '@kwara/models/src';
import { AccountType } from '@kwara/models/src/models/AccountingReport';
import { Currency, Text } from '@kwara/components/src/Intl';

import Table, { Row, Heading, Cell } from '../../../../components/Table';
import { TableSection } from './components/TableSection';

function Assets({ assets = [], total }: { assets: AccountType[]; total: number }) {
  return (
    <TableSection accounts={assets} total={total} totalLabelId="AccountingReports.BalanceSheet.Table.totals.ASSET" />
  );
}

function Liabilities({ liabilities = [], total }: { liabilities: AccountType[]; total: number }) {
  return (
    <TableSection
      accounts={liabilities}
      total={total}
      totalLabelId="AccountingReports.BalanceSheet.Table.totals.LIABILITY"
    />
  );
}

function Equities({
  equities = [],
  total,
  currentEarnings
}: {
  equities: AccountType[];
  total: number;
  currentEarnings: number;
}) {
  return (
    <TableSection
      accounts={equities}
      total={total}
      totalLabelId="AccountingReports.BalanceSheet.Table.totals.EQUITY"
      additionalRows={[
        <Row key="currentEarnings">
          <Cell />
          <Cell />
          <Cell>
            <Text id="AccountingReports.BalanceSheet.Table.totals.currentEarnings" />
          </Cell>
          <Cell className="kw-text-medium b" align="right">
            <Currency hideDecimals={false} value={currentEarnings} />
          </Cell>
          <Cell />
        </Row>
      ]}
    />
  );
}

export function BalanceSheet({ data }: { data: AccountingReportT }) {
  const { assets, liabilities, equity, totals } = data.balanceSheet;

  return (
    <Table
      heading={
        <Row>
          <Heading width="150px" translationId="AccountingReports.BalanceSheet.Table.header.type" />
          <Heading width="350px" translationId="AccountingReports.BalanceSheet.Table.header.glCode" />
          <Heading translationId="AccountingReports.BalanceSheet.Table.header.accountName" />
          <Heading align="right" translationId="AccountingReports.BalanceSheet.Table.header.amount" />
          <Heading width="75px" />
        </Row>
      }
    >
      <Assets assets={assets} total={totals.assets} />
      <Liabilities liabilities={liabilities} total={totals.liabilities} />
      <Equities equities={equity} total={totals.equity} currentEarnings={totals.current_earnings} />
    </Table>
  );
}
