import { useCallback } from 'react';

import Saving from '@kwara/models/src/models/Saving';

import { Logger } from '@kwara/lib/src/logger';
import { MemberType } from '@kwara/models/src/models/Member';
import { throwError } from '@kwara/lib/src/utils/throwError';
import { setRemittanceDetails } from '@kwara/lib/src/remittance';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';
import { SavingProductType } from '@kwara/models/src/models/SavingProduct';

import { Data } from '.';
import { useAuth } from '../../hooks';
import { store } from '../../models/Store/Store';

function handleMissingMemberOrSavingProduct(member: MemberType, savingProduct: SavingProductType) {
  if (member == null || savingProduct == null) {
    throwError(
      'CreateSavingAccountError',
      'A member and saving product must be selected',
      handleMissingMemberOrSavingProduct
    );
  }
}

async function saveMember(member: MemberType, isV1?: boolean) {
  if (isV1) return;

  try {
    await member.save();
  } catch (e) {
    Logger.log('ERROR saving member on saving add', JSON.stringify(member));
  }
}

export function useCreateSavingAccount() {
  const auth = useAuth();
  const isV1 = auth.isV1();

  return useCallback(
    async (data: Data) => {
      const { depositAmount: amount, member, savingProduct, remittance, bankAccounts } = data;

      setRemittanceDetails({ remittance, data, bankAccounts, store });
      handleMissingMemberOrSavingProduct(member, savingProduct);

      const saving = new Saving(
        removeNullishValues({
          account_holder_id: member.id,
          product_id: savingProduct.id,
          amount: amount ? Number(amount) : null,
          remittance
        })
      );
      const success = await saving.save({ with: { remittance: ['bankAccount', 'collectingBank.id'] } });
      if (!success) throw saving.errors;

      await saveMember(member, isV1);
    },
    [isV1]
  );
}
