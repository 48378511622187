import React from 'react';

import invoke from 'lodash/fp/invoke';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { OperationalReportT } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Date, DateTime, Text } from '@kwara/components/src/Intl';
import { default as TableBase, Row, Heading, Footer, Cell } from '../../Table';

interface TableT {
  isLoading: boolean;
  hasMore: boolean;
  onNext: () => void;
  reports: OperationalReportT[];
  onDownload(report: OperationalReportT): void;
  isDownloading: boolean;
}

export function Table({ reports = [], isLoading, hasMore, onNext, onDownload, isDownloading }: TableT) {
  const heading = [
    <Heading key="report" translationId="OperationalReports.Table.header.report" />,
    <Heading key="toDate" translationId="OperationalReports.Table.header.toDate" />,
    <Heading key="user" translationId="OperationalReports.Table.header.user" />,
    <Heading key="createdAt" translationId="OperationalReports.Table.header.createdAt" />,
    <Heading key="state" translationId="OperationalReports.Table.header.state" />,
    <Heading key="actions" translationId="OperationalReports.Table.header.actions" />
  ];
  return (
    <TableBase
      heading={heading}
      footer={
        <Footer colSpan={heading.length} hasMore={hasMore} isLoading={isLoading} onNext={onNext} items={reports} />
      }
    >
      {reports.map(report => (
        <Row key={report.id}>
          <Cell>{report.name}</Cell>
          <Cell>
            <Date value={report.asOfDate()} />
          </Cell>
          <Cell>{invoke('createdBy.fullName', report)}</Cell>
          <Cell>
            <DateTime value={report.createdAt} />
          </Cell>
          <Cell>
            <StatusTag state={report.state} />
          </Cell>
          <Cell>
            <If
              condition={report.state === 'complete'}
              do={
                <button
                  disabled={isDownloading}
                  onClick={() => onDownload(report)}
                  className={cx('bn', { pointer: !isDownloading, 'o-70': isDownloading })}
                >
                  <Text id="OperationalReports.Table.button.download" />
                </button>
              }
            />
          </Cell>
        </Row>
      ))}
    </TableBase>
  );
}
