import * as React from 'react';
import get from 'lodash/fp/get';
import identity from 'lodash/fp/identity';

import every from 'lodash/fp/every';
import cx from 'classnames';

import { EventT } from '@kwara/models/src';
import { DateTime, Text, Currency } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import StatusTag from '@kwara/components/src/StatusTag';

import { AccountLink } from './AccountLink';
import styles from './index.module.scss';

type Props = {
  event: EventT;
};

function hasRequiredProperties(properties) {
  return every(identity, properties);
}

// Some events were originally tracked without any properties, so we cannot show the same message.
// We have to point to a different translation id, suffixed with `.fallback`.
// This function checks whether we have the properties or not.
// All "old" events without properties now point to the .fallback case.
function getEventTranslation(name, properties) {
  const eventTranslationPath = `EventItem.event.${name}`;
  const fallbackEventTranslationPath = `EventItem.event.${name}.fallback`;

  const { user_name, loan_amount, amount, member_name, loan_product_name, saving_product_name } = properties;

  switch (name) {
    case 'user.deactivate':
    case 'user.activate':
      if (hasRequiredProperties([user_name])) {
        return eventTranslationPath;
      }
      break;
    case 'loan.create':
    case 'loan_state.approve':
    case 'loan_state.reject':
    case 'loan_state.soft_reject':
    case 'loan_state.disburse':
    case 'loan_state.write_off':
      if (hasRequiredProperties([loan_amount, member_name, loan_product_name])) {
        return eventTranslationPath;
      }
      break;
    case 'members.create':
    case 'members_state.approve':
    case 'members_state.unapprove':
    case 'members_state.reject':
    case 'members_state.soft_reject':
    case 'members_state.exit':
    case 'members_state.restore':
      if (hasRequiredProperties([member_name])) {
        return eventTranslationPath;
      }
      break;
    case 'saving.create':
    case 'savings_state.close':
      if (hasRequiredProperties([saving_product_name, member_name])) {
        return eventTranslationPath;
      }
      break;
    case 'savings_transactions.deposit.create':
    case 'savings_transactions.withdrawal.create':
    case 'savings_transactions.fee.create':
    case 'savings_transactions.transfer.create':
    case 'pending_savings_transactions.deposit.create':
    case 'pending_savings_transactions.withdrawal.create':
    case 'pending_savings_transactions.fee.create':
    case 'pending_savings_transactions.transfer.create':
      if (hasRequiredProperties([saving_product_name, member_name, amount])) {
        return eventTranslationPath;
      }
      break;
    case 'savings_transactions.deposit.adjust':
    case 'savings_transactions.withdrawal.adjust':
    case 'savings_transactions.fee.adjust':
    case 'savings_transactions.transfer.adjust':
    case 'loan_transactions.repayment.adjust':
    case 'loan_transactions.fee.adjust':
      return eventTranslationPath;
    case 'loan_transactions.repayment.create':
    case 'loan_transactions.fee.create':
    case 'pending_loan_transactions.repayment.create':
    case 'pending_loan_transactions.fee.create':
      if (hasRequiredProperties([loan_product_name, member_name, amount])) {
        return eventTranslationPath;
      }
      break;
  }
  return fallbackEventTranslationPath;
}

export const EventItem = ({ event }: Props) => {
  const { user, time, name } = event;
  const entity = event.entity();

  const properties = get('properties', event) || {};
  const accountId = get('properties.account_id', event);

  return (
    <div className={cx('flex justify-start items-start', styles.EventItem)}>
      <div className={cx('flex flex-column', styles.Indicator)} />

      <div className="flex-m flex-l">
        <time className={cx('nowrap flex-none kw-text-small grey-300', styles.Timestamp)} dateTime={time} title={time}>
          <DateTime value={time} />
        </time>

        <div className="mr3 flex-none dn db-m db-l">
          <ProfilePhoto size="small" />
        </div>

        <div className={styles.Info}>
          <div className="mt2 kw-text-regular">
            <span className="b">{user.fullName()}</span>{' '}
            <Text
              id={getEventTranslation(name, properties)}
              values={{
                ...properties,
                b: chunk => <span className="b">{chunk}</span>,
                account_id_link: <AccountLink entity={entity} accountId={accountId} />,
                loan_amount: <Currency format="currency" value={properties.loan_amount} />,
                amount: <Currency format="currency" value={properties.amount} />,
                member_id: <AccountLink entity="members" accountId={properties.member_id} />,
                loan_status_tag: <StatusTag size="small" state={properties.loan_state} />,
                member_status_tag: <StatusTag size="small" state={properties.member_state} />,
                saving_status_tag: <StatusTag size="small" state={properties.saving_state} />,
                deposit_amount: <Currency format="currency" value={properties.deposit_amount} />
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
