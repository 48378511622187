import React from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { LoanType } from '@kwara/models/src/models/Loan';
import { Currency, Text } from '@kwara/components/src/Intl';

import { useGetWriteOffBalanceData } from './useGetWriteOffBalanceData';
import { validateLoanAmount } from '../../utils/validateLoanAmount';

const getTextFieldProps = (props: { isWriteOffable: boolean; value: number }) => ({
  required: true,
  isCurrency: true,
  type: 'number',
  leftGlyph: 'Currency.orgCurrency',
  info: props.isWriteOffable ? (
    <Text id="LoanTopup.Balances.writeOff" values={{ amount: <Currency format="currency" value={props.value} /> }} />
  ) : null
});

function Balances({
  formProps: {
    values: { loan, desiredInterestBalance, desiredFeesBalance, desiredPenaltiesBalance }
  },
  TextField
}: LoanSubstepProps) {
  const getWriteOffAmount = useGetWriteOffBalanceData();

  return (
    <>
      <TextField
        {...getTextFieldProps(getWriteOffAmount(loan.interest.balance, desiredInterestBalance))}
        name="desiredInterestBalance"
        labelId="LoanTopup.Balances.interest"
        max={desiredInterestBalance}
      />
      <TextField
        {...getTextFieldProps(getWriteOffAmount(loan.fees.balance, desiredFeesBalance))}
        name="desiredFeesBalance"
        labelId="LoanTopup.Balances.fee"
        max={desiredFeesBalance}
      />
      <TextField
        {...getTextFieldProps(getWriteOffAmount(loan.penalties.balance, desiredPenaltiesBalance))}
        name="desiredPenaltiesBalance"
        labelId="LoanTopup.Balances.penalty"
        max={desiredPenaltiesBalance}
      />

      <div className="mt2">
        <Text id="LoanTopup.Balances.explanatoryText" />
      </div>
    </>
  );
}

const sharedValidation = {
  isRequired: () => true,
  currency: true,
  custom: validateLoanAmount
};

export default {
  Component: Balances,
  validate: (data: { loan: LoanType }) => ({
    desiredInterestBalance: { ...sharedValidation, max: Number(data.loan.interest.balance) },
    desiredFeesBalance: { ...sharedValidation, max: Number(data.loan.fees.balance) },
    desiredPenaltiesBalance: { ...sharedValidation, max: Number(data.loan.penalties.balance) }
  })
};
