import { StepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import { Summary } from './components/Summary';
import { Details } from './components/Details';
import { ReportType } from '../../useReportType';

export const steps = (reportType: ReportType) =>
  ({
    details: {
      titleId: `Finance.DividendReports.Generate.Details.LeftNavigation.${reportType}.title`,
      subtitleId: `Finance.DividendReports.Generate.Details.LeftNavigation.${reportType}.subTitle`,
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'summary'
        }
      ],
      children: [
        {
          titleId: `Finance.DividendReports.Generate.Details.${reportType}.title`,
          Component: Details,
          validate: Details.validate
        }
      ]
    },
    summary: {
      titleId: 'Finance.DividendReports.Generate.Summary.LeftNavigation.title',
      subtitleId: 'Finance.DividendReports.Generate.Summary.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'submit',
          behavesAs: 'complete'
        }
      ],
      children: [
        {
          titleId: 'Finance.DividendReports.Generate.Summary.title',
          Component: Summary
        }
      ]
    }
  } as StepConfig);
