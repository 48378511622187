import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember, useMemberSavings } from '@kwara/models/src/models/request/hooks';
import { MemberType, SavingType } from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { WizardPageProps } from '..';
import { memberPath } from '../../lib/urls';
import { steps } from './steps';
import { useDepositForm } from './useDepositForm';

type Props = WizardPageProps<{ memberId: string }>;

export const MemberDeposit = (props: Props) => {
  const queryClient = useQueryClient();
  const memberId = props.match.params.memberId;
  const { addDeposit, isTillOpen, isTillLoading } = useDepositForm();
  const r = useMember(memberId);
  const savingsR = useMemberSavings(memberId);

  return (
    <Loadable data={isTillOpen} isLoading={isTillLoading}>
      <Loadable {...r}>
        {(member: MemberType) => (
          <Loadable {...savingsR}>
            {(savings: SavingType[]) => {
              member.savings = savings;
              return member.savingsEligibleForTransactions().length > 0 ? (
                <Wizard
                  analyticsId="MemberDeposit"
                  baseUrl={memberPath({ id: member.id })}
                  history={history}
                  initialData={{
                    member,
                    accounts: member.savingsEligibleForTransactions(),
                    accountId: member.savingsEligibleForTransactions()[0].id,
                    isTillOpen
                  }}
                  cancelReturnsTo={memberPath({ id: member.id })}
                  currentStep="deposit"
                  onSubmit={addDeposit}
                  onSubmitCompletion={() => {
                    r.refetch();
                    savingsR.refetch();
                    queryClient.invalidateQueries('member_details_metrics');
                  }}
                  steps={steps}
                  startId="deposit"
                  titleId="SavingPayment.title"
                />
              ) : (
                <Redirect to={memberPath({ id: member.id })} />
              );
            }}
          </Loadable>
        )}
      </Loadable>
    </Loadable>
  );
};
