import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import join from 'lodash/fp/join';

import { UserT, AppRoleT } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import Visible from '../../../../components/Visible';
import { settingPath } from '../../../../lib/urls';
import Table, { Cell, Row, Heading, Footer } from '../../../../components/Table';
import { ButtonMenu } from '../../../../components/ButtonMenu';
import { useTeamList } from './hooks';
import { usePermissions } from '../../../../hooks';

export function RolesSummary({ roles = [], threshold = 2 }: { roles: AppRoleT[]; threshold: number }) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const names = map(role => role.name, roles);
  const size = names.length;

  if (isExpanded) {
    return (
      <ul className="pointer list pl0" onClick={() => setIsExpanded(false)}>
        {map(
          appRole => (
            <li key={appRole.id}>{appRole.name}</li>
          ),
          roles
        )}
      </ul>
    );
  }

  if (size <= threshold) {
    return <span>{join(', ', names)}</span>;
  }

  const val = join(', ', names.splice(0, threshold));
  const rest = names.length;

  return (
    <span>
      {val}{' '}
      <span onClick={() => setIsExpanded(true)} className="pointer">
        <Text id="Settings.Team.Table.RolesSummary.content" values={{ val, rest }} />
      </span>
    </span>
  );
}

function IconDropDown({
  user,
  col = 'grey300',
  onDeactivate,
  onReactivate
}: {
  user: UserT;
  col?: string;
  onDeactivate: Function;
  onReactivate: Function;
}) {
  const { AppPermissions } = usePermissions();

  return (
    <ButtonMenu Button={props => <ActionButton {...props} hideBorder col={col} type="more" />}>
      <ButtonMenu.Item
        to={settingPath({
          baseExtension: 'team',
          action: 'edit',
          id: user.id
        })}
      >
        <Text id="Settings.Team.Table.IconMenu.edit" />
      </ButtonMenu.Item>
      {user.deactivatedAt ? (
        <Visible to={AppPermissions.ManageUserAccess}>
          <ButtonMenu.Item onClick={() => onReactivate(user)}>
            <Text id="Settings.Team.Table.IconMenu.reactivate" />
          </ButtonMenu.Item>
        </Visible>
      ) : (
        <Visible to={AppPermissions.ManageUserAccess}>
          <ButtonMenu.Item onClick={() => onDeactivate(user)}>
            <span className="red-600">
              <Text id="Settings.Team.Table.IconMenu.deactivate" />
            </span>
          </ButtonMenu.Item>
        </Visible>
      )}
    </ButtonMenu>
  );
}

export function UserTable({
  users,
  isLoading,
  hasMore,
  onNext,
  refetch
}: {
  users: UserT[];
  isLoading: boolean;
  hasMore: boolean;
  onNext: Function;
  refetch: () => void;
}) {
  const { onDeactivate, onReactivate } = useTeamList(refetch);

  const heading = (
    <Row className="grey-400">
      <Heading translationId="Settings.Team.Table.name" />
      <Heading translationId="Settings.Team.Table.email" />
      <Heading translationId="Settings.Team.Table.roles" />
      <Heading className="tr" translationId="Settings.Team.Table.2faActivated" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <Table
      heading={heading}
      footer={<Footer colSpan={5} hasMore={hasMore} isLoading={isLoading} onNext={onNext} items={users} />}
    >
      {map(user => {
        const deactivatedClassName = cx(user.deactivatedAt ? 'o-50' : null);

        return (
          <Row
            key={user.id}
            secondaryIcon={<IconDropDown user={user} onReactivate={onReactivate} onDeactivate={onDeactivate} />}
          >
            <Cell className={deactivatedClassName}>{user.fullName()}</Cell>
            <Cell className={deactivatedClassName}>{user.email}</Cell>
            <Cell className={deactivatedClassName}>
              <RolesSummary roles={user.appRoles} threshold={1} />
            </Cell>
            <Cell className={cx(deactivatedClassName, 'tr')}>
              <If
                condition={user.mfaActivated}
                do={
                  <span className="kw-text-2x-large" role="img" aria-label="MFA Secured">
                    &#x1F512;
                  </span>
                }
              />
            </Cell>
          </Row>
        );
      }, users)}
    </Table>
  );
}
