import includes from 'lodash/fp/includes';

import { CollateralT, CollateralTypes, CollateralFields } from '@kwara/models/src';

const {
  account,
  bank,
  branch,
  insuranceNumber,
  insuranceCompany,
  narration,
  type,
  landRegistration
} = CollateralFields;

const viewFields = {
  [CollateralTypes.LAND]: [type, landRegistration, narration],
  [CollateralTypes.STOCK]: [type, narration],
  [CollateralTypes.BANK_ACCOUNT]: [type, bank, branch, account, narration],
  [CollateralTypes.LIFE_INSURANCE]: [type, insuranceNumber, insuranceCompany, narration]
};

function excludedFields(collateral: CollateralT) {
  return function(fieldName: string) {
    return !includes(fieldName, viewFields[collateral.assetName]);
  };
}

export { viewFields, excludedFields };
