import * as React from 'react';
import { RequestProps } from '@kwara/components/src';
import { If } from '@kwara/components/src/If/If';
import {
  SavingsTransactionType,
  SavingsTransaction,
  SavingType,
  LoanType,
  LoanTransaction,
  LoanTransactionType
} from '@kwara/models/src';
import { TransactionViewModal } from './TransactionViewModal';
import { TransactionAdjustModal } from './TransactionAdjustModal';
import { useTransactionAdjustment } from '.';

type AdjustmentModalsPropTypes = {
  query: RequestProps<SavingType[] | SavingsTransactionType[] | LoanType[] | LoanTransactionType[]>;
  spraypaintModel: LoanTransaction | SavingsTransaction;
};
export const AdjustmentModals = React.memo(function AdjustmentModals({
  query,
  spraypaintModel
}: AdjustmentModalsPropTypes) {
  const {
    permission,
    adjustTransactionsPermission,
    currentTransaction,
    showView,
    showAdjust,
    setShowAdjust,
    setShowView
  } = useTransactionAdjustment();

  return (
    <>
      <If
        condition={showView}
        do={<TransactionViewModal transaction={currentTransaction} onClose={() => setShowView(false)} />}
      />
      <If
        condition={showAdjust && permission.to(adjustTransactionsPermission)}
        do={
          <TransactionAdjustModal
            spraypaintModel={spraypaintModel}
            transaction={currentTransaction}
            onClose={() => setShowAdjust(false)}
            onSuccess={query.refetch}
          />
        }
      />
    </>
  );
});
