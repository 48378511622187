import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import OverviewList from '../../../../components/OverviewList';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import { financePath } from '../../../../lib/urls';
import { BodyContentWrapper } from '../../../../layouts';
import { usePaginatedLoanClassificationReports } from '../../../../models/hooks';

import { ReportsTable } from './components/ReportsTable';

export const SasraReport = () => {
  const {
    allData,
    totalResults,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    ...rest
  } = usePaginatedLoanClassificationReports();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.Sasra.title" subtitleId="Finance.Sasra.subtitle" />
          <OverviewList
            actions={[
              <Button
                key="generate"
                type="primary"
                to={financePath({
                  baseExtension: 'sasra',
                  action: 'generate'
                })}
              >
                <Text id="Finance.Sasra.button.Generate" />
              </Button>
            ]}
            headerId="Finance.Sasra.List.header"
            labelId="Finance.Sasra.List.label"
            items={allData}
            totalNumResults={totalResults}
            table={
              <ReportsTable
                reports={allData}
                hasMore={hasNextPage}
                onNext={fetchNextPage}
                {...rest}
                isLoading={isLoading || isFetchingNextPage}
              />
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
