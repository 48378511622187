import { useQuery } from 'react-query';

import { ApiError } from 'GlobalTypes';

import { PhoneNumberInfo, PhoneNumberInfoType, Handlers } from '.';

export function useGetPhoneNumberInfo(phoneNumber: string, handlers: Handlers, enabled: boolean = true) {
  return useQuery<PhoneNumberInfoType, ApiError>(
    [PhoneNumberInfo.jsonapiType, phoneNumber],
    function queryPhoneNumberInfo() {
      return PhoneNumberInfo.getPhoneNumberInfo(phoneNumber, handlers);
    },
    { enabled: !!phoneNumber && enabled }
  );
}
