import * as React from 'react';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { GeneralLedgerAccountT } from '@kwara/models/src';
import { SubscribedCombobox } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';

import { useGlAccounts } from '../../hooks';

export const AllGeneralLedgerAccountCombobox = ({
  name,
  labelId,
  required
}: {
  name: string;
  labelId: string;
  required?: boolean;
}) => {
  const r = useGlAccounts();

  return (
    <Loadable {...r}>
      {(accounts: GeneralLedgerAccountT[]) => (
        <GeneralLedgerCombobox required={required} name={name} labelId={labelId} accounts={accounts} />
      )}
    </Loadable>
  );
};

export const GeneralLedgerCombobox = ({
  name,
  labelId,
  accounts,
  required
}: {
  name: string;
  labelId?: string;
  accounts: GeneralLedgerAccountT[];
  required: boolean;
}) => {
  const glAccounts = sortBy(account => account.id, accounts);
  const comboboxData = map(account => ({ value: account.id, label: `${account.id} - ${account.name}` }), glAccounts);

  return <SubscribedCombobox size={'medium'} name={name} labelId={labelId} data={comboboxData} required={required} />;
};
