import size from 'lodash/fp/size';

type ArgType<DataType> = {
  data: Array<DataType>;
  values: string[];
  filterBy: string;
  state: string;
};

export function getButtonUtils<DataType>({ data, values, filterBy, state }: ArgType<DataType>) {
  const noData = size(data) === 0;
  const numberSelected = size(values);
  const hasSelected = numberSelected > 0;
  const isFilterByPending = filterBy === state;
  const disable = !isFilterByPending || noData;

  return { disable, isFilterByPending, hasSelected, numberSelected };
}
