import { useQuery, QueryFunctionContext } from 'react-query';
import { ReportFields } from '@kwara/models/src/models/ReportField';

type QueryKey = ['report_fields', string];

function fetchReportFields({ queryKey }: QueryFunctionContext<QueryKey>) {
  const [_, templateId] = queryKey;
  return ReportFields(templateId)
    .includes('report_field_gl_account_links.gl_account')
    .all()
    .then(res => res.data);
}

export function useReportFieldsApiFetch(templateId: string) {
  return useQuery(['report_fields', templateId], fetchReportFields);
}
