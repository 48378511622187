import { useRef } from 'react';

import { useAuth } from '../useAuth/useAuth';
import { Permission } from '../../models/Permission';
import { AppPermissions } from '../../models/Permission';
import { KwaraPermissions } from '../../models/Permission/KwaraPermissions';

export function usePermissions() {
  const auth = useAuth();
  const { current: permission } = useRef(new Permission(auth.getPermissions));

  return { permission, AppPermissions, KwaraPermissions };
}
