import * as React from 'react';

import { SavingType, LoanType, GuaranteeType } from '@kwara/models/src';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';

import { Grid } from '../../../components/Grid';

type Props = {
  accounts: GuaranteeType[] | LoanType[] | SavingType[];
  columns: number;
  width: string;
  actionButton?: React.ReactNode;
  renderAccount: (account: React.ReactNode) => React.ReactNode;
};

export const AccountList = ({ accounts, columns = 2, width = 'w-50', renderAccount, actionButton }: Props) => {
  return accounts.length > 0 ? (
    <Grid columns={columns} width={width} border={false}>
      {accounts.map(account => renderAccount(account))}
    </Grid>
  ) : (
    <Empty>
      <div className="flex flex-column">
        <Text id="AccountList.empty" />
        {actionButton}
      </div>
    </Empty>
  );
};
