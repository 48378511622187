import React from 'react';
import { useLocation } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';

import { useTillUIContext } from '../..';
import { Success } from './components/Success';
import { SearchModal } from './components/SearchModal';
import { ConfirmationOpen } from './components/ConfirmationOpen';
import { ConfirmationClose } from './components/ConfirmationClose';

export const TillModal = () => {
  const location = useLocation<{ startTillSession?: boolean }>();
  const { activeModal } = useTillUIContext();

  const shouldRenderConfirmationOpen = activeModal === 'confirmOpen' || !!location.state?.startTillSession;
  const shouldRenderConfirmationClose = activeModal === 'confirmClose';
  const shouldRenderSuccess = activeModal === 'success';
  const shouldRenderSearchModal =
    activeModal === 'withdrawal' || activeModal === 'deposit' || activeModal === 'repayment';

  return (
    <>
      <If condition={shouldRenderConfirmationOpen} do={<ConfirmationOpen />} />
      <If condition={shouldRenderConfirmationClose} do={<ConfirmationClose />} />
      <If condition={shouldRenderSuccess} do={<Success />} />
      <If
        condition={shouldRenderSearchModal}
        do={
          <SearchModal
            titleId={`Till.Search.Modal.title.${activeModal}`}
            headerId={`Till.Search.Modal.header.${activeModal}`}
          />
        }
      />
    </>
  );
};
