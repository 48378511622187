import React from 'react';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';

export type ActionButtonsPropTypes = {
  disabled: Boolean;
  loansCount: number;
  onReject(): void;
  onApprove(): void;
  isPermitted: boolean;
};

export function ActionButtons({ disabled, loansCount, isPermitted, onApprove, onReject }: ActionButtonsPropTypes) {
  return (
    <If
      condition={isPermitted}
      do={
        <>
          <Button type="primary" disabled={disabled} onClick={onApprove}>
            <Text id="Finance.LoanDisbursement.List.approve" values={{ count: loansCount || 'all' }} />
          </Button>
          <Button type="destructive" disabled={disabled} onClick={onReject}>
            <Text id="Finance.LoanDisbursement.List.reject" values={{ count: loansCount || 'all' }} />
          </Button>
        </>
      }
    />
  );
}
