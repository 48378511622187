import * as React from 'react';

import { SubStepComponentProps } from '../../../components/Wizard';

import { WizardData } from '..';

const Reject = ({ RadioGroup, StackChild, TextArea, isV1 }: SubStepComponentProps<WizardData>) => {
  return (
    <StackChild>
      <RadioGroup
        flex={false}
        name="rejectType"
        items={[
          {
            labelId: 'MemberApprove.Reject.Type.soft.label',
            titleId: 'MemberApprove.Reject.Type.soft.title',
            value: 'SOFT'
          },
          {
            labelId: 'MemberApprove.Reject.Type.hard.label',
            titleId: 'MemberApprove.Reject.Type.hard.title',
            value: 'HARD'
          }
        ]}
      />

      {isV1 ? null : (
        <TextArea name="comment" labelId="MemberApprove.Reject.Note.label" infoId="MemberApprove.Reject.Note.info" />
      )}
    </StackChild>
  );
};

Reject.validate = {
  rejectType: {
    isRequired: () => true
  }
};

export default Reject;
