import React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { Text as I18n } from '@kwara/components/src/Intl';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { Completion, Types as CompletionTypes } from '@kwara/components/src/Completion';

import styles from './index.module.scss';
import { STATUS } from './Reducer';
import { usePasswordExpiryAlerterContext } from './PasswordExpiryAlerterImp';
import { PasswordExpiryAlerterContextType } from './PasswordExpiryAlerterImp';

type PasswordExpiryAlerterProps = {
  children: React.ReactNode;
};

export function PasswordExpiryAlerter(props: PasswordExpiryAlerterProps) {
  const [dialogId] = useUniqueIds(1);
  const {
    shown,
    status,
    daysCount,
    onChangePassword,
    onContinueWithoutPasswordChange,
    RESET
  }: PasswordExpiryAlerterContextType = usePasswordExpiryAlerterContext();
  const isPasswordExpired = status === STATUS.isExpired;
  const isPasswordAboutToExpire = status === STATUS.isAboutToExpire;

  return (
    <>
      <If
        condition={shown}
        do={
          <div
            id={dialogId}
            role="dialog"
            aria-modal="true"
            aria-atomic="true"
            aria-live="assertive"
            className={cx(styles.container, zIndices.Banner)}
          >
            <Completion
              analyticsId="passwordExpiry"
              isLoading={false}
              type={CompletionTypes.info}
              onConfirm={onChangePassword}
              buttonTextId="Login.PasswordToExpire.changePasswordText"
              titleId={`Login.${isPasswordExpired ? 'PasswordExpired' : 'PasswordToExpire'}.heading`}
              subtitle={
                <I18n
                  id={`Login.${isPasswordExpired ? 'PasswordExpired' : 'PasswordToExpire'}.subHeading`}
                  values={{ daysCount }}
                />
              }
            >
              <If
                condition={isPasswordAboutToExpire}
                do={
                  <div
                    role="button"
                    tabIndex={-1}
                    data-testid="notNowBtn"
                    className={cx(styles['not-now-btn'])}
                    onClick={() => {
                      onContinueWithoutPasswordChange();
                      RESET();
                    }}
                  >
                    <I18n id="Login.PasswordToExpire.notNowText" />
                  </div>
                }
              />
            </Completion>
          </div>
        }
      />

      {props.children}
    </>
  );
}
