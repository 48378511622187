// @flow

import * as React from 'react';

import { UtilityTransaction } from '@kwara/models/src';
import { UtilityTypes } from '@kwara/models/src/models/UtilityTransaction';

import { type PageProps } from '../..';
import Wizard from '../../../components/Wizard';
import { steps } from './config';
import { useTillUIContext } from '../../Till';

const { RECEIPT } = UtilityTypes;

export const UtilityReceipt = (props: PageProps<{}>) => {
  const { refetchTillData } = useTillUIContext();

  const { baseUrl, history } = props;

  const submit = async data => {
    const utility = new UtilityTransaction({ type: RECEIPT, ...data });

    const didSave = await utility.save();

    if (!didSave) {
      throw utility.errors;
    }

    refetchTillData();
  };

  return (
    <Wizard
      baseUrl={baseUrl}
      history={history}
      initialData={{ paymentMethod: 'cash' }}
      cancelReturnsTo={baseUrl}
      currentStep="receipt"
      onSubmit={submit}
      steps={steps}
      startId="receipt"
      titleId="Utilities.Receipt.title"
      type="submit"
    />
  );
};
