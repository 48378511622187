import * as React from 'react';
import get from 'lodash/fp/get';

import { ScheduleType } from '@kwara/models/src';

import RepaymentsTable from './RepaymentsTable';

export function Schedule({
  schedule,
  initiallyOpen = false,
  groupedByYear
}: {
  schedule: ScheduleType | null;
  initiallyOpen?: boolean;
  groupedByYear?: boolean;
}) {
  return (
    <RepaymentsTable
      repayments={get('repayments', schedule)}
      initiallyOpen={initiallyOpen}
      groupedByYear={groupedByYear}
    />
  );
}
