import keys from 'lodash/fp/keys';
import indexOf from 'lodash/fp/indexOf';

const UNAUTHORIZED_ERROR = 'Unauthorized';

const WIZARD_TYPE = {
  approval: 'approval',
  submission: 'submission'
};

const COMPLETION_STATE = {
  Incomplete: 'Incomplete',
  Processing: 'Processing',
  Successful: 'Successful',
  Rejected: 'Rejected',
  Error: 'Error'
};

function parseCurrentSubStep(subStep: string | number | null): number | null {
  return parseInt(subStep as string, 10);
}

function getSubstepFromMatchParams({ subStep }: { subStep: string }) {
  return subStep != null ? parseCurrentSubStep(subStep) : null;
}

function getStepNumber(currentStep: any, steps: any) {
  const currentIndex = indexOf(currentStep, keys(steps));
  return currentIndex + 1;
}

export {
  UNAUTHORIZED_ERROR,
  WIZARD_TYPE,
  COMPLETION_STATE,
  parseCurrentSubStep,
  getSubstepFromMatchParams,
  getStepNumber
};
