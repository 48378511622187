import * as React from 'react';
import { RouteProps } from 'react-router-dom';

import { isDev } from '@kwara/lib/src/utils';
import { NamedRoute } from '@kwara/components/src/Route';

interface Props extends RouteProps {
  fallback: React.ComponentType<any>;
  name: string;
}

export const DevOnlyRoute = ({ component: Component, fallback: Fallback, ...rest }: Props) => (
  <NamedRoute {...rest} render={props => (isDev ? <Component {...props} /> : <Fallback {...props} />)} />
);
