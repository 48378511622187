import React from 'react';
import lodashMap from 'lodash/map';

import { MemberType } from '@kwara/models/src/models/Member';

import Table, * as table from '../../../../components/Table';

import { GroupsWithMembers } from './GroupsWithMembers';
import { useMemberListTableUtils, Group, Order, Groups, Orders } from './useMemberListTableUtils';

type MemberListTablePropTypes = {
  hasMore: boolean;
  groupBy: Group | null;
  errors: Object[];
  loading: boolean;
  members: MemberType[];
  orderBy: Order | null;
  onLoadMoreData: () => void;
  rowsAsLink?: boolean;
  footerTranslationBaseId?: string;
  isV1?: boolean;
};

export default function MemberListTable({
  hasMore,
  groupBy,
  errors,
  loading,
  members,
  orderBy,
  onLoadMoreData,
  rowsAsLink,
  footerTranslationBaseId = 'MemberListTable',
  isV1
}: MemberListTablePropTypes) {
  const { membersGroupedByAttribute, orderMembersByAttribute } = useMemberListTableUtils();

  const groupedMembers = groupBy ? membersGroupedByAttribute(members, groupBy) : { none: members };

  const heading = (
    <table.Row>
      <table.Heading translationId="MemberListTable.id" />
      <table.Heading translationId="MemberListTable.name" />
      <table.Heading translationId="MemberListTable.phone" />
      <table.Heading translationId="MemberListTable.nationalId" />
      <table.Heading translationId="MemberListTable.lastUpdated" />
      {isV1 ? null : <table.Heading translationId="MemberListTable.source" />}
      <table.Heading translationId="MemberListTable.status" />
    </table.Row>
  );

  const colSpan = heading.props.children.filter(n => n).length;

  return (
    <Table
      heading={heading}
      footer={
        <table.Footer
          colSpan={colSpan}
          hasMore={hasMore}
          errors={errors}
          items={members}
          isLoading={loading}
          onNext={onLoadMoreData}
          translationBaseId={footerTranslationBaseId}
        />
      }
    >
      {lodashMap(groupedMembers, (members, group) => (
        <GroupsWithMembers
          key={group}
          columns={colSpan}
          group={group}
          members={orderMembersByAttribute(members, orderBy)}
          asLink={rowsAsLink}
          isV1={isV1}
        />
      ))}
    </Table>
  );
}

MemberListTable.defaultProps = {
  hasMore: false,
  groupBy: Groups.state,
  orderBy: Orders.updated
};
