import React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Text, TranslationValues } from '@kwara/components/src/Intl';

import styles from './Tabs.module.scss';

export type Config = {
  [key: string]: {
    titleId: string;
    component: JSX.Element;
    values?: TranslationValues;
  };
};

export type TabTypes = 'entries' | 'pending';
export type SetActiveTab = React.Dispatch<React.SetStateAction<TabTypes>>;

type TabsPropTypes = {
  config: Config;
  activeTab: TabTypes;
  setActiveTab: SetActiveTab;
};

export function Tabs({ config, activeTab, setActiveTab }: TabsPropTypes) {
  const tabKeys = Object.keys(config);

  return (
    <>
      <div className="absolute mb2">
        <ul className={cx(styles['tabs'], 'ma0 pa0 list kw-text-2x-large')}>
          {map((key: TabTypes) => {
            const tab = config[key];
            const values = get('values', tab);

            return (
              <li
                key={key}
                onClick={() => setActiveTab(key)}
                className={cx('pointer', { [styles['active']]: key === activeTab })}
              >
                <Text values={values} id={`Finance.JournalEntries.Tab.${tab.titleId}`} />
              </li>
            );
          }, tabKeys)}
        </ul>
      </div>
      <section>{get([activeTab, 'component'], config)}</section>
    </>
  );
}
