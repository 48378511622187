import * as React from 'react';

import { LoanType } from '@kwara/models/src';
import { Render } from '@kwara/components/src';

import styles from './index.module.scss';
import { IconNext } from './assets/iconNext.svg';
import { IconPrevious } from './assets/iconPrevious.svg';

function getPages(loans: LoanType[]) {
  if (!loans?.length) return 0;

  return Math.ceil(loans.length / 3);
}

const ITEMS_PER_PAGES = 3;

type Pagination = {
  startIndex: number;
  endingIndex: number;
  page: number;
};

const INITIAL_PAGINATION: Pagination = {
  startIndex: 0,
  endingIndex: 3,
  page: 1
};

type HorizontalScrollerPropTypes<DataType> = {
  data: DataType[];
  fallbackId: string;
  children: (props: Pagination) => React.ReactElement | React.ReactElement[];
};

/**
 * @HorizontalScroller
 *
 * ```ts
 * @prop {data: DataType[]} takes an array of generic dataStructure you decide to pass in
 * @prop {children: (props: Pagination) => React.ReactElement | React.ReactElement[]}
 * @prop {fallbackId: string}
 *
 * @example
 * this component is generic and could be used for any slidable items
 * ...
 * ```
 */
export function HorizontalScroller<DataType>({ data, fallbackId, children }: HorizontalScrollerPropTypes<DataType>) {
  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION);
  const pages = getPages(data);
  const shouldDisablePreviousButton = pagination.startIndex === 0;
  const shouldDisableNextButton = pagination.page === pages;

  function onNext() {
    setPagination(prev => {
      if (pages === prev.page) return INITIAL_PAGINATION;

      return {
        startIndex: prev.startIndex + ITEMS_PER_PAGES,
        endingIndex: prev.endingIndex + ITEMS_PER_PAGES,
        page: prev.page + 1
      };
    });
  }

  function onPrevious() {
    setPagination(prev => {
      if (prev.startIndex === 0) return INITIAL_PAGINATION;

      return {
        startIndex: prev.startIndex - ITEMS_PER_PAGES,
        endingIndex: prev.endingIndex - ITEMS_PER_PAGES,
        page: prev.page - 1
      };
    });
  }

  return (
    <Render condition={!!pages} fallbackId={fallbackId}>
      <div className={styles.container}>
        {children(pagination)}

        <div className={styles.buttonsWrapper}>
          <button type="button" disabled={shouldDisablePreviousButton} onClick={onPrevious}>
            <IconPrevious />
          </button>

          <button type="button" disabled={shouldDisableNextButton} onClick={onNext}>
            <IconNext />
          </button>
        </div>
      </div>
    </Render>
  );
}
