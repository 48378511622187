import { useQuery } from 'react-query';

import { DistributionOfLoansStats } from '@kwara/models/src/models/Dashboard/DistributionOfLoansStats';

const fetchDistributionOfLoansStats = async () => {
  const res = await DistributionOfLoansStats.find();
  return res.data;
};

export const useDistributionOfLoansStats = () => {
  return useQuery(['distribution_of_loans_stats'], fetchDistributionOfLoansStats);
};
