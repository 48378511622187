import * as React from 'react';

import Button from '@kwara/components/src/Button';
import config from '../../config';

import { type PageProps } from '..';

const { availableApiConfig, getApiConfig, getApiOverride, setApiOverride, unsetApiOverride } = config;

type Props = PageProps<{}>;

class ApiOverride extends React.Component {
  state = {
    showReload: false
  };

  componentDidUpdate() {
    if (this.state.showReload) {
      return;
    }

    this.setState({
      showReload: true
    });
  }

  change = evt => {
    const override = evt.target.value;
    if (override === 'none') {
      unsetApiOverride();
    } else {
      setApiOverride(override);
    }

    this.forceUpdate();
  };

  reload = () => window.location.reload();

  render() {
    const override = getApiOverride() || 'none';

    return (
      <section className="w-30">
        <h2>API connection</h2>
        <p>
          Currently connecting to: <br />
          <code>{getApiConfig()}</code>
        </p>

        <h3 className="mb0">Override connection</h3>
        <p className="mt0 grey-400">
          This will override the default, environment-based API connection and will always use what is specified here.
        </p>

        <form className="flex flex-column" onChange={this.change}>
          <label className="mv2">
            <input type="radio" value="none" checked={override === 'none'} /> <code>None</code>
            <span className="grey-400"> (uses default for environment)</span>
          </label>

          {availableApiConfig().map(name => (
            <label className="mv2" htmlFor={name}>
              <input name={name} type="radio" value={name} checked={override === name} /> <code> {name}</code>
            </label>
          ))}
        </form>

        {this.state.showReload && (
          <Button size="medium" type="primary" onClick={this.reload}>
            Reload
          </Button>
        )}
      </section>
    );
  }
}

export default class Debug extends React.Component<Props, *> {
  render() {
    return (
      <div className="justify-center flex">
        <ApiOverride />
      </div>
    );
  }
}
