import React from 'react';
import isFunction from 'lodash/fp/isFunction';

import { LoadableStatistic } from '@kwara/components/src/Statistic/LoadableStatistic';
import { RequestProps } from '@kwara/components/src';
import { MetricType } from '@kwara/models/src';

import { useDashboardMetricValues } from './useDashboardMetricValues';

type Props = {
  title: React.ReactNode;
  metric: MetricType;
  infoId?: string;
  values?: { [id: string]: any };
  color?: string;
  r?: RequestProps<MetricType>;
};

export function DashboardMetric({ infoId, values, metric, title, color, r }: Props) {
  const { value, info } = useDashboardMetricValues(metric);

  const LEGACY_DASHBOARD_METRIC_CLASSNAME = 'pl2 kw-text-extra-large grey-400 kw-weight-light';
  const infoWithClassName = isFunction(info) ? data => info(data, LEGACY_DASHBOARD_METRIC_CLASSNAME) : info;

  return (
    <LoadableStatistic
      r={r}
      title={title}
      info={infoWithClassName}
      infoId={infoId}
      values={values}
      size="4x-large"
      value={value}
      color={color}
    />
  );
}
