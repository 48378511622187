// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { SubscribedTextArea as TextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';
import { getTransactionTypes, contexts } from '@kwara/models/src/models/Transactions';
import { Panel } from '@kwara/components/src/Panel';
import { Statistic } from '@kwara/components/src/Statistic';
import { UtilityTypes } from '@kwara/models/src/models/UtilityTransaction';

import { AllGeneralLedgerAccountCombobox, Field as BasicField, DatePicker } from '../../../components/Form';
import { Grid } from '../../../components/Grid';
import { type SubStepComponentProps } from '../../../components/Wizard';
import { useTillUIContext } from '../../Till';

const { RECEIPT, PAYMENT } = UtilityTypes;

export const utilitiesConfig = {
  methods: getTransactionTypes(contexts.Utility).values
};

type UtilityType = 'payment' | 'receipt';
type Props = SubStepComponentProps<{}> & {
  type: UtilityType
};

const GlPanel = ({ type }: { type: UtilityType }) => {
  const { currentTillSession } = useTillUIContext();
  const till = currentTillSession.till;
  const isPayment = type === PAYMENT;
  const isReceipt = type === RECEIPT;

  return (
    <>
      {isPayment ? (
        <Panel>
          <BasicField>
            <Statistic
              size="medium"
              title={<Text id="Utilities.GlSelect.debit.label" />}
              value={
                <Text
                  id="Utilities.GlSelect.tillInfo"
                  values={{
                    glCode: get('glAccountId', till),
                    name: get('name', till)
                  }}
                />
              }
            />
          </BasicField>

          <AllGeneralLedgerAccountCombobox required name="glCode" labelId="Utilities.GlSelect.credit.label" />
        </Panel>
      ) : null}

      {isReceipt ? (
        <Panel>
          <AllGeneralLedgerAccountCombobox required name="glCode" labelId="Utilities.GlSelect.debit.label" />
          <BasicField>
            <Statistic
              size="medium"
              title={<Text id="Utilities.GlSelect.credit.label" />}
              value={
                <Text
                  id="Utilities.GlSelect.tillInfo"
                  values={{
                    glCode: get('glAccountId', till),
                    name: get('name', till)
                  }}
                />
              }
            />
          </BasicField>
        </Panel>
      ) : null}
    </>
  );
};

export const UtilityForm = ({ StackChild, TextField, type }: Props) => {
  const isPayment = type === PAYMENT;

  return (
    <StackChild>
      <Panel>
        <TextField name="name" labelId={`Utilities.${isPayment ? 'Beneficiary' : 'Payer'}.label`} required />
        <TextField name="invoiceNumber" labelId="Utilities.Invoice.label" errorBaseId="Utilities.Invoice" required />
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            name="amount"
            labelId="Utilities.Amount.label"
            errorBaseId="Utilities.Amount"
            leftGlyph="Currency.orgCurrency"
            required
            isCurrency
            type="number"
          />
          <BasicField required disabled name="date" size="medium" labelId="Utilities.Date.label">
            <DatePicker name="date" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>
      </Panel>

      <GlPanel type={type} />

      <Panel>
        <SubscribedPaymentSelectField
          required
          name="paymentMethod"
          labelId="Utilities.Method.label"
          config={utilitiesConfig}
        />

        <TextField name="reference" labelId="Utilities.Reference.label" />
      </Panel>
      <Panel>
        <TextArea
          name="notes"
          labelId="Utilities.Narration.label"
          placeholderId="Utilities.Narration.placeholder"
          errorBaseId="Utilities.Narration"
        />
      </Panel>
    </StackChild>
  );
};

const base = {
  name: {
    isRequired: () => true
  },
  invoiceNumber: {
    isRequired: () => true
  },
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  },
  paymentMethod: {
    isRequired: () => true
  },
  glCode: {
    isRequired: () => true
  }
};

export const UtilityPaymentForm = (props: SubStepComponentProps<{}>) => <UtilityForm {...props} type="payment" />;
export const UtilityReceiptForm = (props: SubStepComponentProps<{}>) => <UtilityForm {...props} type="receipt" />;

UtilityPaymentForm.validate = base;
UtilityReceiptForm.vailidate = base;
