import React from 'react';
import cx from 'classnames';
import { Field as FinalFormField } from 'react-final-form';

import { GuarantorCardElement, GuarantorCardPropTypes } from 'GuarantorCardTypes';

import { If } from '@kwara/components/src/If/If';
import { LoanProductStandingT } from '@kwara/models/src';
import { Currency, Text } from '@kwara/components/src/Intl';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { SubscribedTextField } from '@kwara/components/src/Form';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { LinkButton } from '@kwara/components/src/LinkButton';

import { useGuarantorCard } from './useGuarantorCard';
import { IconCheck, IconTrash, IconCancel } from '../../../../assets';

import styles from './index.module.scss';

export const GuarantorCard = React.forwardRef<GuarantorCardElement, GuarantorCardPropTypes>(function(
  props,
  forwardedRef
) {
  const {
    index,
    className,
    saveAs,
    member,
    product,
    addData,
    onClickHandler,
    amount,
    isSavingGuarantor,
    hasError,
    errorCodes,
    saveGuarantor,
    loanId,
    onHideEditor,
    isMarkedForDestruction: isGuarantorAlreadyMarkedForRemoval,
    hasBlockingError,
    ...restProps
  } = props;

  const [guarantorAccessibilityId, mainSavingsAccessibilityId, canGuaranteeUptoAccessibilityId] = useUniqueIds(3);
  const {
    shouldDisableRemoveBtn,
    shouldDisableSaveBtn,
    canGuaranteeUpto,
    inputIsMarkedForDestructionName,
    inputName,
    memberId,
    guarantorName,
    isCanGuaranteeUptoValid,
    invalidAmount,
    loanProductStandingRequest,
    onChangeHandler,
    onKeyUpHandler,
    onSaveHandler,
    onRemoveHandler
  } = useGuarantorCard({
    addData,
    index,
    amount,
    hasBlockingError,
    member,
    product,
    saveAs,
    isGuarantorAlreadyMarkedForRemoval,
    isSavingGuarantor,
    onClickHandler,
    saveGuarantor
  });

  return (
    <div
      {...restProps}
      ref={forwardedRef}
      aria-label={`${guarantorName} Guarantor form`}
      className={cx(
        styles['container'],
        { [styles['container--danger']]: isGuarantorAlreadyMarkedForRemoval },
        className
      )}
    >
      <If
        condition={hasError || invalidAmount}
        do={
          <button type="button" title="Cancel" className={styles['container__row1']} onClick={onHideEditor}>
            <AccessibleIcon label={<Text id="Accessibility.clickToCloseErrorDialog" />}>
              <IconCancel />
            </AccessibleIcon>
          </button>
        }
      />
      <div className={styles['container__row2']}>
        <div>
          <label className="kw-text-text kw-weight-regular" htmlFor={guarantorAccessibilityId}>
            <LinkButton as="a" target="_blank" href={`${window.location.origin}/members/${memberId}`}>
              {guarantorName}
            </LinkButton>
          </label>
          <span id={guarantorAccessibilityId} className="kw-weight-bold kw-text-medium">
            {memberId}
          </span>
        </div>
        <div>
          <label className="kw-text-text kw-weight-regular" htmlFor={mainSavingsAccessibilityId}>
            <Text id="Guarantor.mainSavings" />
          </label>
          <span id={mainSavingsAccessibilityId} className={cx('kw-weight-bold kw-text-medium', styles['currency'])}>
            <Loadable {...loanProductStandingRequest} loading={<LoadingSkeleton />}>
              {(data: LoanProductStandingT) => <Currency format="currency" value={data.deposits} />}
            </Loadable>
          </span>
        </div>
        <div>
          <label className="kw-text-text kw-weight-regular" htmlFor={canGuaranteeUptoAccessibilityId}>
            <Text id="Guarantor.standing" />
          </label>
          <span id={canGuaranteeUptoAccessibilityId} className={styles['currency']}>
            <If
              condition={loanProductStandingRequest.isLoading}
              do={<LoadingSkeleton />}
              else={<Currency format="currency" value={canGuaranteeUpto} />}
            />
          </span>
        </div>
      </div>
      <div className={styles['container__row3']}>
        <If
          condition={hasError}
          do={errorCodes?.map((error, index) => (
            <ErrorTextWithI18n key={index.toString()} id={`Errors.${error}`} />
          ))}
        />
        <SubscribedTextField
          required
          isCurrency
          type="number"
          name={inputName}
          leftGlyph="Currency.orgCurrency"
          errorBaseId="LoanAdd.SecurityAmount"
          labelId="Guarantor.amountToGuarantee"
          onChange={onChangeHandler}
          onKeyUp={onKeyUpHandler}
          values={{ canGuaranteeUpto: <Currency format="currency" value={canGuaranteeUpto} /> }}
          disabled={!isCanGuaranteeUptoValid}
        />
        <If
          condition={loanProductStandingRequest.isFetched && !isCanGuaranteeUptoValid}
          do={<ErrorTextWithI18n className="mt2" id="Guarantor.invalidCanGuaranteeUpto" />}
        />
      </div>
      <If
        condition={inputIsMarkedForDestructionName !== undefined}
        do={
          <FinalFormField
            type="checkbox"
            name={inputIsMarkedForDestructionName}
            render={({ input }) => {
              return (
                <div className={styles['container__row4']}>
                  <button
                    type="button"
                    title="Remove"
                    disabled={shouldDisableRemoveBtn}
                    onClick={onRemoveHandler({ input, onHideEditorHandler: hideEditor => hideEditor() })}
                  >
                    <Text id="Guarantor.remove" /> &nbsp;
                    <IconTrash />
                  </button>

                  <button
                    type="button"
                    title="Save"
                    disabled={shouldDisableSaveBtn}
                    onClick={onSaveHandler({ input, amount, memberId, loanId })}
                  >
                    <Text id="Guarantor.save" /> &nbsp;
                    <IconCheck />
                  </button>
                </div>
              );
            }}
          />
        }
      />
    </div>
  );
});
