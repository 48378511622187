import * as React from 'react';
import { useIntl } from 'react-intl';

import StatusTag from '@kwara/components/src/StatusTag';
import { LoanType } from '@kwara/models/src';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Duration, Percent, DateTime } from '@kwara/components/src/Intl';

import { Group } from './utils';
import { loanPath } from '../../../../lib/urls';
import { Currency } from '../../../../components/Currency';
import { useStatusTagProps, formatter, getDisbursers } from './utils';
import * as table from '../../../../components/Table';

type RowPropTypes = {
  item: LoanType;
  pathTo: string;
  isV1?: boolean;
};

function Row({ isV1, item, pathTo }: RowPropTypes) {
  const int = useIntl();

  const andSymbol = int.formatMessage({ id: 'General.Separators.and' });
  const { id, name, amount, loan, interestRate, duration, updated, state, appraisals } = formatter(item, isV1);
  const noOfAppraisals = appraisals?.length;

  return (
    <table.Row to={pathTo}>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {id}
      </table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {name}
      </table.Cell>
      <table.Cell to={pathTo}>{loan}</table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={amount} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Percent value={interestRate} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <Duration value={duration} />
      </table.Cell>
      {isV1 ? null : (
        <table.Cell to={pathTo}>
          <DateTime value={updated} />
        </table.Cell>
      )}
      <table.Cell to={pathTo}>
        <StatusTag<string> size="small" {...useStatusTagProps({ state, noOfAppraisals })} />
      </table.Cell>
      <table.Cell to={pathTo}>
        {noOfAppraisals ? getDisbursers(appraisals, andSymbol) : <Text id="General.Dash" />}
      </table.Cell>
    </table.Row>
  );
}

type CreateGroupWithLoansPropTypes = {
  group: Group;
  columns: number;
  loans: LoanType[];
  isV1?: boolean;
};

export function CreateGroupWithLoans({ group, columns, loans, isV1 }: CreateGroupWithLoansPropTypes) {
  return (
    <>
      <If
        condition={group !== 'none'}
        do={<table.GroupingRow cols={columns} translationId={`LoanListTableGroup.${group}`} />}
      />
      {loans.map(item => (
        <Row isV1={isV1} key={item.id} item={item} pathTo={loanPath({ id: item.id }) as string} />
      ))}
    </>
  );
}
