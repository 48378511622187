import * as React from 'react';

import { LoanProductType, LoanType, MemberType } from '@kwara/models/src';
import { totalBalance } from '@kwara/models/src/models/LoanApplication';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import { getLoansFromLoanIds } from '.';
import { useFeesForm } from '../LoanConfigure/useFeesForm';

import { useAnticipatedPayoutApiFetch } from '../../../hooks';

declare interface Props {
  member: MemberType;
  payOffLoans: string[];
  amount: string;
  product: LoanProductType;
  feeApplications: FeeApplicationT[];
  onChange: Function;
  loans: LoanType[];
  isV1?: boolean;
}

export function usePayoffValues({
  member,
  payOffLoans: payOffLoanIds = [],
  amount,
  onChange,
  product,
  feeApplications,
  loans = [],
  isV1
}: Props) {
  const { totalAdditionalFees } = useFeesForm({
    product,
    feeApplications,
    onChange,
    amount
  });
  const {
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    refetch,
    isFetching
  } = useAnticipatedPayoutApiFetch({
    member,
    product,
    amount,
    payOffLoans: payOffLoanIds,
    totalAdditionalFees,
    isV1
  });

  React.useEffect(() => {
    let mounted = true;
    if (mounted && !isV1) {
      refetch();
    }
    return () => {
      mounted = false;
    };
  }, [refetch, payOffLoanIds.length, totalAdditionalFees, isV1]);

  const payOffLoans = getLoansFromLoanIds(payOffLoanIds, loans);
  const totalAmountForPayOff = totalBalance(payOffLoans);

  return {
    anticipatedPayout,
    principalAmount,
    mambuFees,
    additionalFees,
    loanPayoffAmount,
    totalAmountForPayOff,
    isFetching
  };
}
