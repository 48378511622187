import * as React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import first from 'lodash/fp/first';

import { If } from '@kwara/components/src/If/If';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { mapIndexed } from '@kwara/lib/src/lodash';
import Banner from '@kwara/components/src/Banner';
import { Text, Percent } from '@kwara/components/src/Intl';
import { fields } from '@kwara/models/src/models/Loan';
import { totalBalance } from '@kwara/models/src/models/LoanApplication';
import { CollateralFields, LoanType } from '@kwara/models/src';
import { Currency } from '@kwara/components/src/Intl';
import { LoanSubstepProps, getLoansfromLoanIds } from '@kwara/components/src/pages/LoanAdd';

import { WizardSummary } from '../../../../../components/WizardSummary';
import { getTwoThirdsRuleValues } from '../../../utils/getTwoThirdsRuleValues';
import { useMemberIncome } from '../../../components/TwoThirdsModal/TwoThirdsModal';
import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';

const currency = value => (value == null ? null : <Currency format="currency" value={value} />);

const InfoBanner = ({ titleId, subtitleId }) => (
  <>
    <div className="kw-text kw-weight-bold neutral-700">
      <Text id={titleId} />
    </div>
    <div className="kw-text-medium neutral-700 pt2">
      <Text id={subtitleId} />
    </div>
  </>
);
export const SummaryAndReview = ({ data, parentUrl }: LoanSubstepProps) => {
  const {
    guarantors,
    collaterals,
    member,
    remittance,
    loans,
    product,
    amount,
    repaymentPeriodUnit,
    classifications,
    topLevelClassifications,
    payOffLoans: payOffLoanIds,
    selfGuaranteeAmount,
    notes
  } = data;

  const store = useSaccoProfileContext();
  const { data: memberIncome, isLoading } = useMemberIncome(member.id);

  const { netIncome, otherIncomeAmount, otherDeductibles } = member;
  const { amount: repaymentAmount } = remittance;

  const duration = data.loanDuration;
  const disbursementMode = get(fields.disbursement.mode, data);
  const classification = find(c => c.id === first(classifications), topLevelClassifications);
  const editUrl = path => `${parentUrl}/${path}`;
  const payOffLoans = getLoansfromLoanIds(payOffLoanIds, loans);
  const payOffLoanRecord = (loan: LoanType) => (
    <div className="dib">
      {get('name', loan)} - {currency(loan.totalBalance)}
    </div>
  );

  const args = {
    totalMonthlyLoanPayments: get('totalMonthlyLoanPayments', memberIncome),
    netIncome,
    otherIncomeAmount,
    otherDeductibles,
    repaymentAmount
  };
  const { passesTwoThirdsRule, totalAvailableForRepayment } = getTwoThirdsRuleValues(args);

  return (
    <>
      <Banner
        text={
          <InfoBanner
            titleId={'LoanAdd.SummaryAndReview.GuarantorsBanner.title'}
            subtitleId={'LoanAdd.SummaryAndReview.GuarantorsBanner.text'}
          />
        }
        state="warning"
      />
      <If
        condition={store.isCrbCheckEnabled}
        do={
          <Banner
            className={'mt4'}
            text={
              <InfoBanner
                titleId={'LoanAdd.SummaryAndReview.CreditCheckBanner.title'}
                subtitleId={'LoanAdd.SummaryAndReview.CreditCheckBanner.text'}
              />
            }
            state="warning"
          />
        }
      />

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.Member.title" editUrl={editUrl('member/1')}>
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Member.id" value={member.id} />
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Member.name" value={member.fullName()} />
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Member.nationalID" value={member.nationalId()} />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.Member.loans"
          value={currency(member.totalLoansBalance)}
        />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.Member.eligible"
          value={currency(member.eligibleAmount)}
        />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.Income.title" editUrl={editUrl('member/1')}>
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.Income.employment.type"
          value={<Text id={`Employment.Type.${member.employmentStatus}`} />}
        />
        <If
          condition={member.employer}
          do={<WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Income.employer.type" value={member.employer} />}
        />
        <If
          condition={member.business}
          do={<WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Income.business.type" value={member.business} />}
        />
        <If
          condition={member.profession}
          do={
            <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Income.profession.type" value={member.profession} />
          }
        />
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.Income.monthly" value={member.availableIncome} />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.Income.monthly.availableForRepayment"
          value={currency(totalAvailableForRepayment)}
        />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.Income.rule"
          value={
            <Loadable loading={<LoadingSkeleton />} isLoading={isLoading}>
              <Text id={`LoanAdd.SummaryAndReview.Income.rule.${passesTwoThirdsRule}`} />
            </Loadable>
          }
        />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.LoanProduct.title" editUrl={editUrl('product/1')}>
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.LoanProduct.name" value={product.name} />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.LoanProduct.interest"
          value={<Percent value={product.interestRate.percentage / 100} />}
        />
        <WizardSummary.Record labelId="LoanAdd.SummaryAndReview.LoanProduct.principal" value={currency(amount)} />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.LoanProduct.purpose"
          value={get('name', classification)}
        />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.LoanRepayment.title" editUrl={editUrl('product/2')}>
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.LoanRepayment.frequency"
          value={
            <Text
              values={{ val: data.repaymentPeriod }}
              id={`LoanAdd.SummaryAndReview.LoanRepayment.frequency.value.${data.repaymentPeriodUnit}`}
            />
          }
        />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.LoanRepayment.duration"
          valueId={`LoanAdd.SummaryAndReview.LoanRepayment.duration.${repaymentPeriodUnit}`}
          values={{ duration }}
        />
        <WizardSummary.Record
          labelId="LoanAdd.SummaryAndReview.LoanRepayment.disbursement.method"
          value={<Text id={`TransactionMethod.${disbursementMode}`} />}
        />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.LoanPayOff.title" editUrl={editUrl('payOff/1')}>
        {map(
          loan => (
            <WizardSummary.Record key={loan.id} value={payOffLoanRecord(loan)} />
          ),
          payOffLoans
        )}
        <WizardSummary.Record labelId="LoanAdd.Summary.LoanPayOff.total" value={currency(totalBalance(payOffLoans))} />
      </WizardSummary.Section>

      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.Security.title" editUrl={editUrl('security/1')}>
        <Text id="LoanAdd.Summary.Security.selfGuarantee" />
        <WizardSummary.Record
          labelId="LoanAdd.Summary.SecurityAmount.selfGuarantee"
          values={{ name: member.fullName(), id: member.id }}
          value={currency(selfGuaranteeAmount)}
        />
        <Text id="LoanAdd.Summary.Security.Guarantors" />
        {mapIndexed(({ member, amount: memberAmount, isMarkedForDestruction }, index) => {
          if (isMarkedForDestruction) {
            return null;
          }

          return (
            <WizardSummary.Record
              key={`${member.id}.${index}.heading`}
              labelId="LoanAdd.Summary.SecurityAmount.guarantor"
              values={{ name: member.fullName(), id: member.id }}
              value={currency(memberAmount)}
            />
          );
        }, guarantors)}
        <Text id="LoanAdd.Summary.Security.Collaterals" />
        {mapIndexed((collateral, i) => {
          if (collateral.isMarkedForDestruction) {
            return null;
          }

          return (
            <WizardSummary.Record
              key={`${collateral.amount}-${i}`}
              labelId={`LoanAdd.Security.Collateral.Type.${collateral[CollateralFields.type]}`}
              value={currency(collateral.amount)}
            />
          );
        }, collaterals)}
      </WizardSummary.Section>
      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.Notes.title" ariaLabel="Notes">
        <If
          condition={!!notes?.member_profile?.income}
          do={
            <>
              <Text id="LoanAdd.SummaryAndReview.Notes.MemberIncome.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.member_profile?.income} />
            </>
          }
        />
        <If
          condition={!!notes?.loan_product?.no_section}
          do={
            <>
              <Text id="LoanAdd.SummaryAndReview.Notes.LoanProduct.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.loan_product?.no_section} />
            </>
          }
        />
        <If
          condition={!!notes?.pay_off?.no_section}
          do={
            <>
              <Text id="LoanAdd.SummaryAndReview.Notes.PayOff.title" />
              <WizardSummary.Record colorClassName="primary-500" value={notes?.pay_off?.no_section} />
            </>
          }
        />
      </WizardSummary.Section>
    </>
  );
};
