/********************************************************
 * @about ```<EditableSection/> v2```
 * This components is the version 2 EditableSection
 * which helps to render wizard input fields in an
 * editable format with no "preview". This version
 * is heavily used in the New Loan application
 */

import * as React from 'react';
import noop from 'lodash/fp/noop';

import { MemberType } from '@kwara/models/src';
import { patchValues } from '@kwara/components/src/Wizard/latest/utils/patchValues';
import { SubStepContainer } from '@kwara/components/src/Wizard/latest/components/SubStep/SubStep';
import { StepConfig, RenderableStep } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

export type EditableConfig<DataType> = {
  config: StepConfig | RenderableStep;
  initialData: DataType;
};

const EditableContext = React.createContext({ onUpdate: noop });

type UpdaterProps = {
  children: React.ReactNode;
  value: { onUpdate: (...data: any) => Promise<void> };
};
// Wrap with this any component that needs to be re-fetch/re-render after an edit is successful
function Updater({ children, value }: UpdaterProps) {
  return <EditableContext.Provider value={value}>{children}</EditableContext.Provider>;
}

interface EditableSectionProps<DataType> extends EditableConfig<DataType> {}

function EditableSection(props: EditableSectionProps<MemberType>) {
  const [data, setData] = React.useState(props.initialData);

  return (
    <SubStepContainer
      hideActionBar
      as="monoView"
      onAction={noop}
      currentState={data}
      subStep={{ ...props.config, actions: [] } as RenderableStep}
      onChange={(updates: { [key: string]: string }) => {
        setData(patchValues(data, updates));

        return Promise.resolve(data);
      }}
    />
  );
}

export { EditableSection, Updater, EditableContext };
