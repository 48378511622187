//@flow

import React from 'react';

import { type OrganisationT } from '@kwara/models/src';
import { SubscribedSelectField as SelectPasswordPeriod } from '@kwara/components/src/Form';

import { SettingsSectionForm as Form } from '../shared';
import { passwordSegmentTrackingFacade, TYPES } from '../../../../LogIn/utils/passwordSegmentTrackingFacade';
import { usePermissions } from '../../../../../hooks';

const PASSWORD_NEVER_EXPIRE_TEXT = 'Never expire';
const PASSWORD_EXPIRY_DEFAULT_PERIOD = 0;
const PASSWORD_EXPIRY_PERIODS = [
  {
    id: 'ped01',
    name: 'Settings.Sacco.passwordExpiry.neverExpire',
    value: null
  },
  {
    id: 'ped02',
    name: 'Settings.Sacco.passwordExpiry.fourteenDays',
    value: 14
  },
  {
    id: 'ped03',
    name: 'Settings.Sacco.passwordExpiry.thirtyDays',
    value: 30
  },
  {
    id: 'ped04',
    name: 'Settings.Sacco.passwordExpiry.sixtyDays',
    value: 60
  },
  {
    id: 'ped05',
    name: 'Settings.Sacco.passwordExpiry.ninetyDays',
    value: 90
  }
];

type PasswordExpiryProps = {
  organisation: OrganisationT,
  refetch: () => void
};

export function PasswordExpiry({ refetch, organisation }: PasswordExpiryProps) {
  const { permission, AppPermissions } = usePermissions();

  const isPermitted = permission.to(AppPermissions.UpdateOrganisationSettings);
  const expiryPeriod = organisation.settings?.general?.password_expiry_period;
  const currentPasswordPeriod: number | string = expiryPeriod === null ? PASSWORD_NEVER_EXPIRE_TEXT : expiryPeriod;

  async function onSubmitPasswordExpiryPeriod({ password_expiry_period }: { password_expiry_period: string }) {
    const formattedExpiryPeriod =
      password_expiry_period === PASSWORD_NEVER_EXPIRE_TEXT ? null : Number(password_expiry_period);
    const params = {
      settings: {
        general: {
          password_expiry_period: formattedExpiryPeriod
        }
      }
    };

    try {
      await organisation.update(params);
      refetch();

      if (typeof formattedExpiryPeriod === 'number') {
        passwordSegmentTrackingFacade(TYPES.setWhenPasswordToExpireRequestResolved, {
          whenPasswordToExpire: `${formattedExpiryPeriod} days`
        });
      }
    } catch (_) {
      passwordSegmentTrackingFacade(TYPES.setWhenPasswordToExpireRequestRejected, {
        whenPasswordToExpire: `${formattedExpiryPeriod} days`
      });
    }
  }

  return (
    <Form
      titleId="Settings.Sacco.passwordExpiry.label"
      permitted={isPermitted}
      initialValues={{ password_expiry_period: currentPasswordPeriod }}
      onSubmit={onSubmitPasswordExpiryPeriod}
      render={() => (
        <SelectPasswordPeriod
          name="password_expiry_period"
          labelId="Settings.Sacco.PasswordExpiry.subTitle"
          value={currentPasswordPeriod}
          disabled={!isPermitted}
        >
          <SelectPasswordPeriod.Option
            value={PASSWORD_EXPIRY_DEFAULT_PERIOD}
            translationId="Settings.Sacco.passwordExpiry.default"
          />
          {PASSWORD_EXPIRY_PERIODS.map(day => (
            <SelectPasswordPeriod.Option key={day.id} value={day.value} translationId={day.name} />
          ))}
        </SelectPasswordPeriod>
      )}
    />
  );
}
