import React from 'react';
import map from 'lodash/fp/map';

import { Currency, ShortDate, Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import { DividendContributionSummaryT, DividendCalculationMethodT } from '../../../../../../../models';
import Table, { Row, Heading, Cell } from '../../../../../../../components/Table';
import { ReportType } from '../../../useReportType';

export function MonthlyBreakdownTable({
  summary,
  reportType,
  reportCalculationMethod
}: {
  summary: DividendContributionSummaryT;
  reportType: ReportType;
  reportCalculationMethod: undefined | DividendCalculationMethodT;
}) {
  const heading = (
    <Row>
      <Heading width="130px" translationId="Finance.DividendReports.Contributions.monthly.table.header.month" />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.monthly.table.header.contributions" />
      <Heading
        align="right"
        translationId={`Finance.DividendReports.Contributions.monthly.table.header.${reportCalculationMethod}.Contributions`}
      />
      <Heading
        align="right"
        translationId={`Finance.DividendReports.Contributions.monthly.table.header.${reportType}.grossDividends`}
      />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.monthly.table.header.taxesWithheld" />
      <Heading align="right" translationId="Finance.DividendReports.Contributions.monthly.table.header.netBalance" />
    </Row>
  );

  return (
    <Table heading={heading}>
      {map(
        monthData => (
          <Row key={monthData.date}>
            <Cell>
              <If
                condition={monthData.date === 'STARTING_BALANCE'}
                do={<Text id="Finance.DividendReports.Contributions.table.body.startingBalance" />}
                else={<ShortDate value={monthData.date} />}
              />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={monthData.value} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={monthData.weighted_contributions} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={monthData.gross_dividends} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={monthData.taxes_withheld} />
            </Cell>
            <Cell align="right">
              <Currency hideDecimals={false} value={monthData.net_dividends} />
            </Cell>
          </Row>
        ),
        summary.monthByMonth
      )}
    </Table>
  );
}
