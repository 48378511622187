import React, { useState } from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { Text, Currency } from '@kwara/components/src/Intl';

import { LoanSubstepProps } from 'GlobalTypes';

import styles from './index.module.scss';
import { getMainSavingsBalance } from './getMainSavingsBalance';
import Expander from '../../../../../../../components/Expander/Expander';
import { useSaccoProfileContext, SaccoProfileContextType } from '../../../../../../../models/Profile/ProfileProvider';

///UTILS BELOW
/**************************************************************/
type AmountNodePropTypes = {
  i18nTitleProps: {
    id: string;
    values?: { [key: string]: any };
  };
  amount: number | undefined | null;
  className?: string;
};

function AmountNode({ i18nTitleProps, amount, className }: AmountNodePropTypes) {
  return (
    <div className={className}>
      <label htmlFor={i18nTitleProps.id} className={cx(styles.amountNodeLabel, 'kw-text-small kw-weight-regular')}>
        <Text {...i18nTitleProps} />
      </label>

      <div id={i18nTitleProps.id} className={styles.amountNodeCurrency}>
        <Currency format="currency" value={amount} />
      </div>
    </div>
  );
}

///COMPONENT BELOW
/**************************************************************/
function SelfGuarantee({ data, TextArea, TextField }: LoanSubstepProps) {
  const [shownAddNotes, _] = useState(false);
  const profileContext: SaccoProfileContextType = useSaccoProfileContext();
  const { isMainSavings, balance, canGuaranteeUpTo } = getMainSavingsBalance(data);

  return (
    <Expander.Root aria-label="Self-guarantee">
      <Expander.Header>
        <Text id="LoanAdd.Security.selfGuarantee" />
      </Expander.Header>

      <Expander.Body>
        <div className={styles.row1}>
          <AmountNode
            amount={balance}
            i18nTitleProps={{
              id: isMainSavings
                ? 'LoanAdd.Security.SelfGuarantee.mainSavings'
                : 'LoanAdd.Security.SelfGuarantee.totalSavings'
            }}
          />

          <AmountNode
            amount={canGuaranteeUpTo}
            i18nTitleProps={{ id: 'LoanAdd.Security.SelfGuarantee.canSelfGuaranteeUpTo' }}
          />
        </div>

        <div className={styles.row2}>
          <TextField
            type="number"
            name="selfGuaranteeAmount"
            isCurrency={true}
            labelId="LoanAdd.Security.SelfGuarantee.amount"
            errorBaseId="LoanAdd.Security.SelfGuarantee.amount"
            leftGlyph={profileContext.currency}
            required
          />
        </div>

        <div>
          <If
            condition={shownAddNotes}
            do={
              <TextArea
                name="notes"
                labelId="LoanAdd.Security.SelfGuarantee.notes.label"
                placeholderId="LoanAdd.Security.SelfGuarantee.notes.placeholder"
              />
            }
            // else={
            //   <LinkButton as="button" onClick={() => setShownNotes(true)}>
            //     <Text id="LoanAdd.Security.SelfGuarantee.addNotes" />
            //   </LinkButton>
            // }
          />
        </div>
      </Expander.Body>
    </Expander.Root>
  );
}

export default {
  Component: SelfGuarantee,
  validate: {
    selfGuaranteeAmount: {
      currency: true,
      custom: (selfGuaranteeAmount, allData) => {
        const { canGuaranteeUpTo } = getMainSavingsBalance(allData);

        if (Number(selfGuaranteeAmount) > canGuaranteeUpTo) {
          return 'EXCEEDS_90_PERCENT';
        }

        return null;
      }
    }
  }
};
