// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { useInterval, useSmsPayment } from '@kwara/models/src/models/request/hooks';
import { SmsPaymentStatus } from '@kwara/models/src/models/SmsPayment';

import { Completion, Types as CompletionTypes } from '@kwara/components/src/Completion';

import { Text } from '@kwara/components/src/Intl';

const { useState } = React;

export const UiDataForState = {
  [SmsPaymentStatus.PENDING]: {
    titleId: 'SmsPayment.Completion.Pending.title',
    subtitleId: 'SmsPayment.Completion.Pending.subtitle',
    completionType: CompletionTypes.good
  },
  [SmsPaymentStatus.SUCCEEDED]: {
    titleId: 'SmsPayment.Completion.Succeeded.title',
    subtitleId: 'SmsPayment.Completion.Succeeded.subtitle',
    completionType: CompletionTypes.good
  },
  [SmsPaymentStatus.FAILED]: {
    titleId: 'SmsPayment.Completion.Failed.title',
    subtitleId: 'SmsPayment.Completion.Failed.subtitle',
    completionType: CompletionTypes.bad
  },
  [SmsPaymentStatus.TIMEOUT]: {
    titleId: 'SmsPayment.Completion.Timeout.title',
    subtitleId: 'SmsPayment.Completion.Timeout.subtitle',
    completionType: CompletionTypes.info
  },
  [SmsPaymentStatus.CANCELLED]: {
    titleId: 'SmsPayment.Completion.Cancelled.title',
    subtitleId: 'SmsPayment.Completion.Cancelled.subtitle',
    completionType: CompletionTypes.info
  },
  [SmsPaymentStatus.INSUFFICIENT_AMOUNT]: {
    titleId: 'SmsPayment.Completion.InsufficientAmount.title',
    subtitleId: 'SmsPayment.Completion.InsufficientAmount.subtitle',
    completionType: CompletionTypes.bad
  }
};

const useSmsPaymentPolling = id => {
  const TRANSACTION_POLL_INTERVAL = 1000;
  const [isPolling, setIsPolling] = useState(true);
  const [uiType, setUiType] = useState(SmsPaymentStatus.PENDING);
  const { refetch, data } = useSmsPayment(id);

  useInterval(
    () => {
      const { paymentStatus } = data;
      if (!paymentStatus) {
        return;
      }

      if (paymentStatus !== SmsPaymentStatus.PENDING) {
        setIsPolling(false);
        setUiType(paymentStatus);
      }
      refetch();
    },
    isPolling ? TRANSACTION_POLL_INTERVAL : null
  );

  return { uiType, isPolling };
};
type PollSmsPaymentProps = {
  id: ?string,
  onConfirm: () => {},
  analyticsId: string
};

export const PollSmsPayment = ({ id, onConfirm, analyticsId }: PollSmsPaymentProps) => {
  const { uiType, isPolling } = useSmsPaymentPolling(id);
  const { titleId, subtitleId, completionType } = get(uiType, UiDataForState);
  return (
    <Completion
      onConfirm={onConfirm}
      subtitle={<Text id={subtitleId} />}
      type={completionType}
      analyticsId={analyticsId}
      buttonTextId="SmsPayment.Completion.Success.button"
      titleId={titleId}
      isLoading={isPolling}
    />
  );
};
