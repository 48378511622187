import React from 'react';
import get from 'lodash/fp/get';

import { RequestProps } from '@kwara/components/src';
import { IndicatorsT } from '@kwara/models/src';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import { Currency } from '@kwara/components/src/Intl';

import { DashboardIndicatorCard } from '../../../../components/DashboardIndicatorCard';

type DashboardMetricPropTypes = { dataKey: string; titleId: string; r: RequestProps<IndicatorsT> };

export function DashboardCurrencyMetric({ dataKey, titleId, r }: DashboardMetricPropTypes) {
  return (
    <DashboardIndicatorCard
      titleId={titleId}
      value={
        <Loadable loading={<LoadingSkeleton />} {...r}>
          {data => <Currency format="currency" value={get(dataKey, data)} />}
        </Loadable>
      }
    />
  );
}
