import * as React from 'react';
import get from 'lodash/fp/get';

import { If } from '@kwara/components/src/If/If';
import { Percent, Number } from '@kwara/components/src/Intl';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';

import { useDashboardIndicators } from './useDashboardIndicators';
import { DashboardCurrencyMetric } from '../DashboardCurrencyMetric';
import { IndicatorsContainer } from '../../../../components/IndicatorsContainer';
import { DashboardIndicatorCard } from '../../../../components/DashboardIndicatorCard';

const HUNDRED_PERCENT = 100;

export function Indicators() {
  const r = useDashboardIndicators();

  return (
    <IndicatorsContainer>
      <DashboardIndicatorCard
        titleId="Dashboard.activeMembers"
        value={
          <Loadable {...r} loading={<LoadingSkeleton />}>
            {data => (
              <span>
                <Percent value={get('totalActiveClientsPercentage', data) / HUNDRED_PERCENT} />
              </span>
            )}
          </Loadable>
        }
        info={
          <If
            condition={!r.isLoading}
            do={
              <span>
                of <Number value={get('totalClients', r.data)} />
              </span>
            }
          />
        }
      />
      <DashboardCurrencyMetric dataKey="totalDeposits" titleId="Dashboard.totalSavings" r={r} />
      <DashboardCurrencyMetric dataKey="grossLoanPortfolio" titleId="Dashboard.grossLoanPortfolio" r={r} />
      <DashboardCurrencyMetric dataKey="portfolioValueAtRisk" titleId="Dashboard.portfolioAtRisk" r={r} />
    </IndicatorsContainer>
  );
}
