import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconReportsElement = React.ElementRef<'svg'>;
interface IconReportsPropTypes extends PrimitiveSvgPropTypes {}

export const IconReports = React.forwardRef<IconReportsElement, IconReportsPropTypes>(function IconReports(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02 4.62012H6.98C5.39 4.62012 4.75 5.22012 4.75 6.74012V18.6201H10.25V6.74012C10.24 5.22012 9.6 4.62012 8.02 4.62012Z"
        fill="#475569"
      />
      <path
        d="M16.52 9.62012H15.48C13.89 9.62012 13.25 10.2301 13.25 11.7401V18.6201H18.75V11.7401C18.75 10.2301 18.1 9.62012 16.52 9.62012Z"
        fill="#475569"
      />
      <path
        d="M2.75 17.8799H21.25C21.66 17.8799 22 18.2199 22 18.6299C22 19.0399 21.66 19.3799 21.25 19.3799H2.75C2.34 19.3799 2 19.0399 2 18.6199C2 18.1999 2.34 17.8799 2.75 17.8799Z"
        fill="#475569"
      />
    </svg>
  );
});

IconReports.defaultProps = {
  width: '25',
  height: '24'
};
