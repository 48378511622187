import * as React from 'react';
import { LoanSubstepProps } from 'GlobalTypes';

import { AttributesRow } from '../../../../../../components/AttributesRow';
import { useLoanDetailSection } from '../useLoanDetailSection';

export function LoanDetailSection({ data }: LoanSubstepProps) {
  const { attributesRow1, attributesRow2, attributesRow3, attributesRow4 } = useLoanDetailSection(data);

  return (
    <div className="flex flex-column">
      <AttributesRow attributes={attributesRow1} />
      <AttributesRow attributes={attributesRow2} />
      <AttributesRow attributes={attributesRow3} />
      <AttributesRow attributes={attributesRow4} />
    </div>
  );
}
