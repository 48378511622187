import * as React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import zIndices from '@kwara/lib/src/zIndices';

import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

export type Nav = {
  path: string;
  nameId: string;
  parentId: string;
};

type LinkTabPropTypes = {
  navItems: Nav[];
  pathname: string;
};

export const LinkTab = React.memo(function LinkTab({ navItems, pathname }: LinkTabPropTypes) {
  return (
    <ul className={cx(styles['tab-list'], zIndices.Modal)}>
      {navItems?.map(({ path, nameId, parentId }) => {
        const isActivePath = path === pathname;

        return (
          <li
            key={nameId}
            tabIndex={0}
            className={cx("kw-text-small", styles['tab-item'], { [styles['tab-item--active']]: isActivePath })}
          >
            <NavLink to={`${path}?parentId=${parentId}`}>
              <Text id={nameId} />
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
});
