import React from 'react';

import { Metric } from '@kwara/models/src';

import { useLoanOverviewMetrics } from '../../../../models/hooks';
import { DashboardMetricCard } from '../../../../components/DashboardMetric';
import { IndicatorsContainer } from '../../../../components/IndicatorsContainer';

export function Indicators() {
  const r = useLoanOverviewMetrics();

  return (
    <IndicatorsContainer>
      <DashboardMetricCard
        r={r}
        titleId="Loans.Dashboard.totalNoLoans"
        metric={metrics => Metric.findFromArray(metrics, 'num_active_loans')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Loans.Dashboard.grossPortfolio"
        metric={metrics => Metric.findFromArray(metrics, 'gross_loan_portfolio')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Loans.Dashboard.portfolioAtRisk"
        metric={metrics => Metric.findFromArray(metrics, 'portfolio_at_risk')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Loans.Dashboard.outstandingAmount"
        metric={metrics => Metric.findFromArray(metrics, 'outstanding_amount')}
      />
      <DashboardMetricCard
        r={r}
        titleId="Loans.Dashboard.lateRepayments"
        metric={metrics => Metric.findFromArray(metrics, 'late_repayments')}
      />
    </IndicatorsContainer>
  );
}
