import React from 'react';
import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';
import capitalize from 'lodash/fp/capitalize';

import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime, Currency } from '@kwara/components/src/Intl';

import Table, { Cell, Row, Footer, Heading } from '../../../../../components/Table';

export const MpesaTable = ({ transactions, onNext, hasMore, isLoading, hideType = false }) => {
  const heading = (
    <Row>
      {compact([
        <Heading key="reference" translationId="Finance.Mpesa.Table.header.reference" />,
        hideType ? null : <Heading key="type" translationId="Finance.Mpesa.Table.header.type" />,
        <Heading key="account" translationId="Finance.Mpesa.Table.header.account" />,
        <Heading key="amount" translationId="Finance.Mpesa.Table.header.amount" />,
        <Heading key="date" translationId="Finance.Mpesa.Table.header.date" />,
        <Heading key="state" translationId="Finance.Mpesa.Table.header.state" />
      ])}
    </Row>
  );

  const colSpan = heading.props.children.length;

  return (
    <Table
      footer={<Footer colSpan={colSpan} hasMore={hasMore} isLoading={isLoading} onNext={onNext} items={transactions} />}
      heading={heading}
    >
      {map(
        transaction => (
          <Row className="kw-text-small" key={transaction.id}>
            <Cell>{transaction.reference}</Cell>
            {hideType ? null : <Cell>{capitalize(transaction.type)}</Cell>}
            <Cell>{transaction.account}</Cell>
            <Cell>
              <Currency value={transaction.amount} />
            </Cell>
            <Cell>
              <DateTime value={transaction.createdAt} />
            </Cell>
            <Cell>
              <StatusTag state={transaction.state} />
            </Cell>
          </Row>
        ),
        transactions
      )}
    </Table>
  );
};
