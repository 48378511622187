import * as React from 'react';
import map from 'lodash/fp/map';

import { Titles } from '@kwara/models/src';
import { SubStepComponentProps, StackChildSize } from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import { ProfilePhotoUpload } from '../../../../../../../../components/ProfilePhotoUpload';
import * as recordValidations from '../../../../../../../../lib/recordValidations';
import style from '../index.module.scss';

type PropTypes = { size: StackChildSize } & SubStepComponentProps<{}>;
export function Name({ SelectField, TextField, StackChild, size, onChange, data }: PropTypes) {
  return (
    <StackChild size={size}>
      <div className="flex">
        <ProfilePhotoUpload onChange={onChange} data={data} />
        <div className="flex-auto">
          <span className={style.TitleSelect}>
            <SelectField name="member.title" labelId="AddMember.Profile.MemberTitle.label" required={true}>
              <SelectField.Option translationId="" value="" />
              {map(
                title => (
                  <SelectField.Option
                    key={title}
                    translationId={`AddMember.Profile.MemberTitle.${title}`}
                    value={title}
                  />
                ),
                Titles
              )}
            </SelectField>
          </span>
          <TextField
            name="member.firstName"
            errorBaseId="AddMember.Profile.Name"
            labelId="AddMember.Profile.FirstName.label"
            required={true}
            autoComplete="nope"
          />
          <TextField
            name="member.middleName"
            errorBaseId="AddMember.Profile.Name"
            labelId="AddMember.Profile.MiddleName.label"
            autoComplete="nope"
          />
          <TextField
            name="member.lastName"
            errorBaseId="AddMember.Profile.Name"
            labelId="AddMember.Profile.LastName.label"
            required={true}
            autoComplete="nope"
          />
        </div>
      </div>
    </StackChild>
  );
}

Name.validate = {
  'member.title': {
    isRequired: () => true
  },
  'member.firstName': {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  'member.middleName': { ...recordValidations.personName },
  'member.lastName': {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  }
};
