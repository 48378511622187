import * as React from 'react';

import { useFilterContext } from '../../../../components/Filter';
import { RadioBaseFilter } from '../../../../components/Filter/pages';

export function StatusFilter() {
  const { filters } = useFilterContext();

  return <RadioBaseFilter filters={filters['status']} />;
}
