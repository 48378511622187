// @flow
import * as React from 'react';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import { type LoanType, type GuaranteeType } from '@kwara/models/src';
import { Checkbox } from '@kwara/components/src/Form';

import { useDeactivatedGuarantees } from '../../../../models/hooks/Loans';
import { usePermissions } from '../../../../hooks';
import { memberPath } from '../../../../lib/urls';
import { Grid } from '../../../../components/Grid';
import GuaranteeCard from './GuaranteeCard';
import { GuarantorForm } from './GuarantorForm';

type Props = {
  loan: LoanType,
  isEditing: boolean,
  handleDelete: (g: GuaranteeType) => Promise<boolean>,
  onEdit: (g: GuaranteeType) => Promise<boolean>,
  guarantees: GuaranteeType[]
};

export const GuaranteeList = ({ loan, guarantees = [], handleDelete, onEdit, isEditing, ...rest }: Props) => {
  const r = useDeactivatedGuarantees(loan.id);
  const { permission, AppPermissions } = usePermissions();
  const [show, setShow] = React.useState(false);
  const deactivated = show ? r.data || [] : [];
  const allGuarantees = [...guarantees, ...deactivated];

  const toggle = () => setShow(prev => !prev);

  if (isEditing) {
    return <GuarantorForm loan={loan} {...rest} />;
  }

  return (
    <section>
      <Checkbox
        name="showDeactivatedGuarantors"
        labelId="LoanDetail.Security.showDeactivatedGuarantors.checkbox"
        checked={show}
        onChange={toggle}
      />

      {size(allGuarantees) === 0 ? (
        <Empty className="mb3">
          <Text id="LoanDetail.Security.none" />
        </Empty>
      ) : (
        <Grid columns={3}>
          {map(
            guarantee => (
              <GuaranteeCard
                loan={loan}
                key={guarantee.id}
                guarantee={guarantee}
                actionTo={memberPath({ id: guarantee.member.id })}
                onEdit={permission.to(AppPermissions.EditLoanDetails) ? () => onEdit(guarantee) : null}
                onDelete={
                  permission.to(AppPermissions.EditLoanDetails)
                    ? async () => {
                        await handleDelete(guarantee);
                        r.refetch();
                      }
                    : null
                }
              />
            ),
            guarantees
          )}
          {show
            ? map(
                g => <GuaranteeCard loan={loan} key={g.id} guarantee={g} actionTo={memberPath({ id: g.memberId })} />,
                deactivated
              )
            : null}
        </Grid>
      )}
    </section>
  );
};
