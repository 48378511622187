import { useQuery } from 'react-query';

import { DistributionOfDepositsStats } from '@kwara/models/src/models/Dashboard/DistributionOfDepositsStats';

const fetchDistributionOfDepositsStats = async () => {
  const res = await DistributionOfDepositsStats.find();
  return res.data;
};

export const useDistributionOfDepositsStats = () => {
  return useQuery(['distribution_of_deposits_stats'], fetchDistributionOfDepositsStats);
};
