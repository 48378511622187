// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import isEqual from 'lodash/fp/isEqual';

import { Statistic } from '@kwara/components/src/Statistic';
import { SubscribedTextField as TextField, SubscribedSelectField as SelectField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { FeeCalculationMethod, type FeeCalculationMethodT, type FeeType } from '@kwara/models/src/models/LoanProduct';

const OptionalFeesSelect = ({ name, optionalFees }: { name: string, optionalFees: FeeType[] }) => {
  return (
    <SelectField required name={name} labelId="PaymentForm.fee.labelId">
      <SelectField.Option translationId="PaymentForm.fee.options.default" disabled value="" />
      {map(
        fee => (
          <SelectField.Option key={fee.id} value={fee.id}>
            {fee.name}
          </SelectField.Option>
        ),
        optionalFees
      )}
    </SelectField>
  );
};

export const FeesList = ({
  optionalFees,
  name,
  thisFee,
  isRequiredFee
}: {
  optionalFees: FeeType[],
  name: string,
  thisFee: FeeType,
  isRequiredFee: boolean
}) => {
  return isRequiredFee ? (
    <Statistic title={<Text id="PaymentForm.fee.required.labelId" />} value={thisFee.name} size="large" padding />
  ) : (
    <OptionalFeesSelect optionalFees={optionalFees} name={`${name}.id`} />
  );
};

export const FeeAmount = ({
  thisFee,
  name,
  thisFeeId,
  feesMap
}: {
  thisFee: FeeType,
  name: string,
  thisFeeId: string,
  feesMap: { [id: string]: FeeType }
}) => {
  // a fee can be either flat, fixed fee or work as a percentage of the loan amount
  const isCalculationMethod = (type: FeeCalculationMethodT, id: string) =>
    pipe(get(`${id}.amountCalculationMethod`), isEqual(type))(feesMap);

  return (
    <>
      {isCalculationMethod(FeeCalculationMethod.FLAT, thisFeeId) ? (
        // If fee is FLAT, one can input the amount
        // if the amount is pre-set it will be prepopulated
        <>
          {get('amount', thisFee) ? (
            <Text
              id="PaymentForm.fee.amountHint"
              values={{
                amount: get('amount', thisFee)
              }}
            />
          ) : (
            <TextField
              name={`${name}.amount`}
              size="medium"
              hidden={!thisFeeId}
              leftGlyph="Currency.orgCurrency"
              labelId="PaymentForm.fee.amount"
              placeholderId="PaymentForm.fee.placeholder"
              isCurrency
              type="number"
            />
          )}
        </>
      ) : null}
      {isCalculationMethod(FeeCalculationMethod.LOAN_AMOUNT_PERCENTAGE, thisFeeId) ? (
        <Text
          id="PaymentForm.fee.percentageHint"
          values={{
            percentage: get('percentageAmount', thisFee)
          }}
        />
      ) : null}
    </>
  );
};
