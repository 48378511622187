//@flow

import * as React from 'react';

import { Link } from '@kwara/components/src/Link';

type Props = {
  children: React.Node,
  active?: boolean,
  disabled?: boolean,
  to: string
};

const NavigationItem = ({ children, active = false, disabled = false, to }: Props) => (
  <li className="h2">
    <Link className={`no-underline ${active ? 'kw-weight-bold' : ''}`} Component="a" disabled={disabled} to={to}>
      {children}
    </Link>
  </li>
);

export type NavigationItemType = typeof NavigationItem;

export default NavigationItem;
