//@flow

import React, { useState } from 'react';
import get from 'lodash/fp/get';

import { If } from '@kwara/components/src/If/If';
import { type MemberType, EmploymentStatusT } from '@kwara/models/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { useUniqueIds } from '@kwara/lib/src/hooks/useUniqueIds';
import { EmploymentStatuses as EMP_STATUS } from '@kwara/models/src';
import { Field as BasicField } from '@kwara/components/src/Form/Field';
import { LinkButton } from '@kwara/components/src/LinkButton';
import { type SubStepComponentProps } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import styles from './index.module.scss';
import { DocumentsUploader } from '../DocumentsUploader/DocumentsUploader';
import { getIncomeValues } from '../../../../../../../../../lib/loanUtils/getIncomeValues';
import { Notes } from '../../../../../../../../../components/Notes/Notes';

///UTILS BELOW
/********************************************************************************/
function isRequired(fieldName, config, formProps) {
  const fieldValidate = get(`validate.${fieldName}`, config);

  if (fieldValidate && fieldValidate.isRequired) {
    return fieldValidate.isRequired(formProps.values[fieldName], formProps.values);
  }

  return false;
}

function constructNumberInputProps() {
  return (extra = {}) => ({
    type: 'number',
    isCurrency: true,
    leftGlyph: 'Currency.orgCurrency',
    ...extra
  });
}

function TotalNode({ label, amount }: { label: string, amount: number }) {
  return (
    <section>
      <h4>
        <Text id={`LoanAdd.MemberProfile.Income.${label}.label`} />
      </h4>
      <p>
        <Currency format="currency" value={amount} />
      </p>
    </section>
  );
}

/************************
 * @PERSON_OF_INTEREST
 * ```key```` is the input```name``` and ```value``` is the input ```labelId```
 */
const PERSON_OF_INTEREST = {
  isGroup: 'AddMember.Employment.Group.label',
  isStaff: 'AddMember.Employment.Staff.label',
  isDelegate: 'AddMember.Employment.Delegate.label',
  isDirector: 'AddMember.Employment.Director.label',
  govEmployee: 'AddMember.Employment.GovEmployee.label'
};

const EMPLOYMENT_STATUS = [
  { labelId: 'Employment.Type.employed', value: EMP_STATUS.EMPLOYED },
  { labelId: 'Employment.Type.self_employed', value: EMP_STATUS.SELF_EMPLOYED },
  { labelId: 'Employment.Type.other', value: EMP_STATUS.OTHER }
];

///COMPONENT BELOW
/********************************************************************************/
interface IncomeFormPropTypes extends SubStepComponentProps<MemberType> {}

function IncomeForm(props: IncomeFormPropTypes) {
  const { config, formProps, RadioGroup, TextField, Checkbox, SelectField, onChange, data } = props;
  const [shownSecondaryIncomeFields, setShownSecondaryIncomeFields] = useState(
    !!data.member.otherIncomeAmount || !!data.member.incomeSource
  );
  const [secondarySourceOfIncomeId] = useUniqueIds(1);
  const getNumberProps = constructNumberInputProps();
  const { totalMonthlyNetIncome, totalAvailableForRepayment } = getIncomeValues(formProps.values.member);
  const employmentStatus: EmploymentStatusT = formProps.values.member.employmentStatus;

  return (
    <div className={styles.container}>
      <RadioGroup
        required
        name="member.employmentStatus"
        labelId="AddMember.Employment.Type.label"
        items={EMPLOYMENT_STATUS}
      />
      <If
        condition={employmentStatus !== EMP_STATUS.EMPLOYED}
        do={
          <If
            condition={employmentStatus === EMP_STATUS.SELF_EMPLOYED}
            do={
              <TextField
                name="member.business"
                labelId="LoanAdd.MemberProfile.Income.primaryBusinessType.label"
                placeholderId="LoanAdd.MemberProfile.Income.primaryBusinessType.placeholder"
              />
            }
            else={
              <TextField
                required
                name="member.profession"
                labelId="LoanAdd.MemberProfile.Income.primaryProfession.label"
                placeholderId="LoanAdd.MemberProfile.Income.primaryProfession.placeholder"
              />
            }
          />
        }
      />
      <If
        condition={employmentStatus === EMP_STATUS.EMPLOYED}
        do={
          <>
            <BasicField labelId="AddMember.Employment.PersonOfInterest.label">
              {Object.keys(PERSON_OF_INTEREST).map(key => (
                <Checkbox key={key} name={key} labelId={PERSON_OF_INTEREST[key]} margin={false} />
              ))}
            </BasicField>
            <TextField
              name="member.profession"
              errorBaseId="AddMember.Employment.Profession"
              labelId="AddMember.Employment.Profession.label"
              placeholderId="AddMember.Employment.Profession.placeholder"
            />
            <TextField
              required
              name="member.employer"
              errorBaseId="AddMember.Employment.Employer"
              labelId="AddMember.Employment.Employer.label"
            />
            <TextField
              required
              name="member.staffId"
              errorBaseId="AddMember.Employment.Employer"
              labelId="AddMember.Employment.StaffId.label"
            />
            <SelectField
              name="member.termsOfService"
              labelId="AddMember.Employment.tos.label"
              required={isRequired('termsOfService', config, formProps)}
            >
              <SelectField.Option translationId="AddMember.Employment.tos.NONE" value="" />
              <SelectField.Option translationId="AddMember.Employment.tos.Contract" value="Contract" />
              <SelectField.Option translationId="AddMember.Employment.tos.Permanent" value="Permanent" />
            </SelectField>
          </>
        }
      />
      <div className={styles.incomeWrapper}>
        <If
          condition={employmentStatus === EMP_STATUS.EMPLOYED}
          do={
            <TextField
              {...getNumberProps({
                name: 'member.netIncome',
                errorBaseId: 'LoanAdd.MemberProfile.NetIncome',
                infoId: 'LoanAdd.MemberProfile.monthlyNetIncome.info',
                labelId: 'LoanAdd.MemberProfile.monthlyNetIncome.label',
                required: isRequired('netIncome', config, formProps)
              })}
            />
          }
          else={
            <TextField
              {...getNumberProps({
                name: 'member.netIncome',
                labelId: 'LoanAdd.MemberProfile.monthlyIncome.label',
                required: isRequired('income', config, formProps)
              })}
            />
          }
        />
        <TextField
          {...getNumberProps({
            name: 'member.otherDeductibles',
            infoId: 'LoanAdd.MemberProfile.otherDeductibles.info',
            labelId: 'LoanAdd.MemberProfile.otherDeductibles.label',
            required: isRequired('otherDeductibles', config, formProps)
          })}
        />
      </div>

      <If
        condition={shownSecondaryIncomeFields}
        do={
          <fieldset className={styles.secondaryIncomeContainer}>
            <label htmlFor={secondarySourceOfIncomeId}>
              <Text id="LoanAdd.MemberProfile.SecondaryIncome.title" />
            </label>
            <div className={styles.secondaryIncomeRow2}>
              <TextField
                id={secondarySourceOfIncomeId}
                name="member.incomeSource"
                labelId="LoanAdd.MemberProfile.secondarySourceOfIncome.label"
                placeholderId="LoanAdd.MemberProfile.secondarySourceOfIncome.placeholder"
              />
              <TextField
                {...getNumberProps({
                  name: 'member.otherIncomeAmount',
                  labelId: 'LoanAdd.MemberProfile.SecondaryMonthlyIncome.title',
                  required: true
                })}
              />
            </div>
          </fieldset>
        }
        else={
          <LinkButton
            as="button"
            className={styles.addSecondaryIncomeLinkButton}
            onClick={() => setShownSecondaryIncomeFields(true)}
          >
            <Text id="LoanAdd.MemberProfile.addSecondaryIncome" />
          </LinkButton>
        }
      />
      <DocumentsUploader data={data} employmentStatus={employmentStatus} onChange={onChange} />
      <div className={styles.incomeFormBottom}>
        <div className={styles.totalsWrapper}>
          <TotalNode label="TotalMonthlyNetIncome" amount={totalMonthlyNetIncome} />
          <TotalNode label="TotalAvailableForRepayment" amount={totalAvailableForRepayment} />
        </div>
        <Notes name="notes.member_profile.income" formValues={formProps.values} />
      </div>
    </div>
  );
}

export default {
  Component: IncomeForm
};
