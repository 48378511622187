// @flow

import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import pick from 'lodash/fp/pick';
import keys from 'lodash/fp/keys';

import { Text } from '@kwara/components/src/Intl';
import Banner from '@kwara/components/src/Banner';
import { type MemberType, type LoanType } from '@kwara/models/src';

import { type LoanSubstepProps } from '../..';
import Bank from './components/Bank/Bank';
import Income from './components/Income/Income';
import Documents from './components/Documents/Documents';
import PersonalDetails from './components/PersonalDetails/PersonalDetails';
import { Updater } from '../../../../../components/EditableSection/v1/EditableSection';
import Expander from '../../../../../components/Expander/Expander';
import { Header, MissingDocumentsWarning, validateMember } from './components/Utils/Utils';

function MemberProfile(props: LoanSubstepProps) {
  const { data, formProps, addData, onChange } = props;
  const { errors = {} } = formProps;
  const { member = {} } = data;
  const valid = isEmpty(errors);

  async function onUpdate(editedMember: MemberType) {
    addData({ member: editedMember, loans: editedMember.loans });

    return await onChange({ member: editedMember, loans: editedMember.loans });
  }

  return (
    <Updater value={{ onUpdate }}>
      <Banner
        className="mb5"
        state={valid ? 'success' : 'error'}
        text={
          <Text id={`LoanAdd.MemberProfile.Banner.${valid ? 'ok' : 'warn'}`} values={{ firstName: member.firstName }} />
        }
      />

      <Expander.Root aria-label="Personal details">
        <Expander.Header>
          <Header when={pick(keys(PersonalDetails.validate), errors)}>
            <PersonalDetails.Title />
          </Header>
        </Expander.Header>

        <Expander.Body>
          <PersonalDetails.Body {...props} />
        </Expander.Body>
      </Expander.Root>

      <Expander.Root aria-label="Bank">
        <Expander.Header>
          <Header when={errors.bank}>
            <Bank.Title />
          </Header>
        </Expander.Header>

        <Expander.Body>
          <Bank.Body member={member} />
        </Expander.Body>
      </Expander.Root>

      <Expander.Root aria-label="Documents">
        <Expander.Header>
          <Header when={errors.documents}>
            <Documents.Title />
          </Header>
        </Expander.Header>

        <Expander.Body>
          <MissingDocumentsWarning member={member} />
          <Documents.Body {...props} />
        </Expander.Body>
      </Expander.Root>

      <Expander.Root aria-label="Income">
        <Expander.Header>
          <Header when={pick(keys(Income.validate), errors)}>
            <Income.Title />
          </Header>
        </Expander.Header>

        <Expander.Body>
          <Income.Body {...props} />
        </Expander.Body>
      </Expander.Root>
    </Updater>
  );
}

export default {
  Component: MemberProfile,
  validate({ member, loans, isV1 }: { member: MemberType, loans: LoanType[] }) {
    if (!loans) {
      return { loans: { isRequired: () => true } };
    }

    return validateMember(member, isV1);
  }
};
