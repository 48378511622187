import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconNotificationElement = React.ElementRef<'svg'>;

export const IconNotification = React.forwardRef<IconNotificationElement, PrimitiveSvgPropTypes>(
  function IconNotification({ fill = '#1E293B', ...restProps }, forwardedRef) {
    return (
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...restProps}
      >
        <path
          d="M22.555 13.662L20.655 6.826C20.0981 4.82333 18.8877 3.06428 17.2163 1.82847C15.5449 0.592648 13.5084 -0.049018 11.4305 0.00546213C9.35251 0.0599422 7.3524 0.807442 5.74805 2.12914C4.1437 3.45085 3.02719 5.2709 2.57598 7.3L1.10498 13.915C0.942497 14.6459 0.946254 15.404 1.11597 16.1333C1.2857 16.8626 1.61705 17.5445 2.08556 18.1285C2.55408 18.7126 3.1478 19.184 3.8229 19.508C4.49799 19.8319 5.23721 20 5.98599 20H7.09999C7.3295 21.1303 7.94272 22.1465 8.83573 22.8764C9.72874 23.6063 10.8466 24.005 12 24.005C13.1533 24.005 14.2712 23.6063 15.1643 22.8764C16.0573 22.1465 16.6705 21.1303 16.9 20H17.738C18.5088 20.0001 19.2692 19.8219 19.9598 19.4794C20.6503 19.137 21.2523 18.6395 21.7188 18.0259C22.1853 17.4122 22.5036 16.699 22.6488 15.942C22.794 15.185 22.7623 14.4047 22.556 13.662H22.555ZM12 22C11.3817 21.9974 10.7794 21.8039 10.2753 21.4459C9.77122 21.0879 9.39007 20.5829 9.18399 20H14.816C14.6099 20.5829 14.2288 21.0879 13.7247 21.4459C13.2206 21.8039 12.6183 21.9974 12 22ZM20.126 16.815C19.8473 17.1847 19.4863 17.4843 19.0716 17.69C18.6569 17.8957 18.1999 18.0018 17.737 18H5.98599C5.53677 17.9999 5.09331 17.899 4.68833 17.7046C4.28335 17.5102 3.92719 17.2273 3.64615 16.8769C3.3651 16.5265 3.16634 16.1174 3.06455 15.6798C2.96275 15.2423 2.9605 14.7875 3.05798 14.349L4.52798 7.733C4.88234 6.1392 5.75929 4.7096 7.01944 3.67144C8.27959 2.63329 9.85061 2.04617 11.4828 2.00341C13.1149 1.96065 14.7145 2.46471 16.0273 3.43546C17.3401 4.40622 18.2907 5.78794 18.728 7.361L20.628 14.197C20.7535 14.6424 20.7735 15.1109 20.6864 15.5653C20.5993 16.0198 20.4074 16.4477 20.126 16.815Z"
          fill={fill}
        />
      </svg>
    );
  }
);
