//@flow

import { useState, useCallback, useEffect } from 'react';

import { INELIGIBLE_GUARANTOR_ERROR } from '../..';

const SKIP_WARNING_KEY = 'skipGuarantorValidationWarning';

export type Message = {
  firstName: string,
  canGuaranteeUpTo: number
};

export interface CustomError extends Error {
  message: Array<Message>;
}

function freeUpErrorSpace(error: CustomError) {
  error.name = null;
  error.message = null;
  error.stack = undefined;
}

const InitialMessages: Message[] = [];

function useGuarantorValidation(error: CustomError) {
  const [showInfo, setShowInfo] = useState(false);
  const [messages, setMessages] = useState(InitialMessages);

  useEffect(() => {
    if (error?.name === INELIGIBLE_GUARANTOR_ERROR) {
      setMessages(error.message);
      setShowInfo(true);
    }
  }, [error]);

  const onBypassWarning = useCallback(
    (addDataAndContinueToNextView: (updated: Record<string, any>) => void) => {
      addDataAndContinueToNextView({ [SKIP_WARNING_KEY]: true });
      setShowInfo(false);
      freeUpErrorSpace(error);
    },
    [error]
  );

  const onCloseWarning = useCallback(() => {
    setShowInfo(false);
    freeUpErrorSpace(error);
  }, [error]);

  return {
    messages,
    showInfo,
    onCloseWarning,
    onBypassWarning
  };
}

export { useGuarantorValidation, SKIP_WARNING_KEY };
