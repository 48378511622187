//@flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Currency } from '../../../components/Currency';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';

import { type TillWithStatsT } from '..';

export type Props = {
  actions: React.Node,
  navigation: React.Node,
  showBack: boolean,
  tillSession: TillWithStatsT
};

export const Overview = (props: Props) => {
  const { tillSession, navigation } = props;

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata tillSession={tillSession} {...props} />}
      secondary={navigation}
      title={<Text id="TillSessionDetail.title" values={{ tellerName: tillSession.tellerName }} />}
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="TillSessionDetail.Overview.currentBalance" />}
            value={<Currency value={tillSession.currentAmount} />}
            size={'5x-large'}
          />
        </StatisticRow>
        <StatisticRow border widthClass="w-25">
          <Statistic
            title={<Text id="TillSessionDetail.Overview.deposits" />}
            value={<Currency value={tillSession.deposits} />}
            size={'big'}
          />
          <Statistic
            title={<Text id="TillSessionDetail.Overview.withdrawals" />}
            value={<Currency value={tillSession.withdrawals} />}
            size={'big'}
          />
          <Statistic
            title={<Text id="TillSessionDetail.Overview.payments" />}
            value={<Currency value={tillSession.payments} />}
            size={'big'}
          />
          <Statistic
            title={<Text id="TillSessionDetail.Overview.receipts" />}
            value={<Currency value={tillSession.receipts} />}
            size={'big'}
          />
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
