import React, { useMemo } from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';

import { AttributesRow } from '../../../../components/AttributesRow';

function AccountMemberAndDetails({ data: { loan, fullName, product } }: LoanSubstepProps) {
  const attributes = useMemo(
    () => [
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.memberName" />
          </div>
        ),
        value: (
          <Link type="primary" target="_blank" underline={false} to={`/members/${loan.member.id}`}>
            {fullName}
          </Link>
        )
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.productName" />
          </div>
        ),
        value: <div className="kw-weight-bold kw-text-small">{product.name}</div>
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.productType" />
          </div>
        ),
        value: (
          <div className="kw-weight-bold kw-text-small">
            <Text id={`LoanProductAdd.Product.type.${product.type}`} />
          </div>
        )
      },
      {
        title: (
          <div>
            <Text id="LoanTopup.AccountMemberAndDetails.accountId" />
          </div>
        ),
        value: <div className="kw-weight-bold kw-text-small">{loan.id}</div>
      }
    ],

    [loan.member.id, loan.id, fullName, product.name, product.type]
  );

  return (
    <>
      <AttributesRow attributes={attributes} useBackground={false} />
      {/*  <div className="mt3">
        <Checkbox name="keepSameAccount" labelId="LoanTopup.AccountAndMemberDetails.keepSameAccount" />
      </div> */}
    </>
  );
}

export default {
  Component: AccountMemberAndDetails
};
