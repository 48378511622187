import { useQuery } from 'react-query';
import { AccountingReport } from '@kwara/models/src';

async function fetchAccountingReport({ queryKey }) {
  const [_, id] = queryKey;

  const res = await AccountingReport.find(id);
  return res.data;
}
export function useAccountingReport(id: string) {
  return useQuery(['accounting_report', id], fetchAccountingReport);
}
