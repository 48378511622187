import React from 'react';
import get from 'lodash/fp/get';

import { Duration, Percent } from '@kwara/components/src/Intl';
import Tag, { valueToStatus } from '@kwara/components/src/Tag';
import { Statistic } from '@kwara/components/src/Statistic';
import { DeprecatedLoadable, Loading } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import { Card } from '@kwara/components/src/Card';
import Banner from '@kwara/components/src/Banner';

import creditReport from '../../../models/CreditReport/CreditReport';
import ModelErrorBanner from '../../../components/ModelErrorBanner';
import { Grid } from '../../../components/Grid';
import { Currency } from '../../../components/Currency';

import pdfPlaceholder from './CreditCheckPDF.jpg';

function getBannerState(code: string): 'error' | 'warning' | 'error' {
  switch (code) {
    case '001': // Identity not found
      return 'error';
    case '002': // Identity found, but no account info
      return 'warning';
    case '003': // Identity found, no Delinquency reports
      return 'success';
    case '004': // Identity found, at least one CURRENT Delinquency report
    case '005': // Identity found, at least one HISTORICAL  Delinquency report
    default:
      return 'warning';
  }
}

export default class CreditReport extends React.Component {
  state = {
    request: null
  };
  componentDidMount() {
    const { data } = this.props;
    this.setState({
      request: creditReport.get({
        identityType: data.identityType,
        identityValue: data.identityValue,
        loanAmount: get('loan.amount', data)
      })
    });
  }

  render() {
    const { StackChild, data } = this.props;
    const { request } = this.state;

    return (
      <StackChild size="widest">
        <DeprecatedLoadable
          loader={request}
          loaded={result => {
            if (result == null) {
              return <Loading />;
            }

            if (result.errors) {
              return (
                <Grid columns={1} width="w-100">
                  <ModelErrorBanner errors={result.errors} />
                </Grid>
              );
            }

            const report = result.data || {};

            // NOTE: The minimum possible credit score is 200
            const creditStatus = valueToStatus([200, 900], report.creditScore);
            return (
              <React.Fragment>
                <h3>Results</h3>
                <Grid columns={1} width="w-100">
                  <div className="grey-400 mb4">
                    <Banner
                      state={getBannerState(report.delinquencyCode)}
                      text={<Text id={`LoanApprove.CreditCheck.Delinquency.${report.delinquencyCode}`} />}
                    />
                  </div>
                </Grid>

                <Grid columns={4} width="w-25">
                  <div>
                    <Card border={false} header="CRB Credit Score">
                      <Statistic
                        value={
                          <span>
                            <div>
                              <span>{report.formattedCreditScore}</span>
                              <span className="kw-text-large"> / 900</span>
                            </div>
                            <div className="kw-text-regular">
                              <Tag status={creditStatus} id={`LoanApprove.CreditCheck.status.${creditStatus}`} />
                            </div>
                          </span>
                        }
                        color="info-700"
                        size="4x-large"
                        compact={true}
                      />
                    </Card>
                    <div className="mv4" />
                    <Card border={false} header="Internal Credit Score">
                      <Statistic
                        value={
                          <span>
                            <div>
                              <span>4</span>
                              <span className="kw-text-large"> / 5</span>
                            </div>
                            <div className="kw-text-regular">
                              <Tag status="neutral" id={`LoanApprove.CreditCheck.status.neutral`} />
                            </div>
                          </span>
                        }
                        color="info-700"
                        size="4x-large"
                        compact={true}
                      />
                    </Card>
                  </div>
                  <Card border={false} header="Member">
                    <Statistic title="Full name" value={data.loan.member.fullName()} size="small" compact={true} />
                    <Statistic title="Identification" value={data.identityValue} size="small" compact={true} />
                    <Statistic
                      title="Loan requested"
                      value={<Currency value={data.loan.amount} format="currency" />}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title="Loan repayment period"
                      alue={<Duration value={data.loan.duration} />}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title="Interest rate"
                      value={<Percent value={data.loan.product.interestRate.percentage / 100} />}
                      size="small"
                      compact={true}
                    />
                  </Card>

                  <Card border={false} header="Credit performance">
                    <Statistic title="Total accounts" value={report.totalAccounts} size="small" compact={true} />
                    <Statistic
                      title="Non-performing accounts"
                      value={report.nonPerformingAccounts}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title="Performing accounts with default history"
                      value={report.performingAccountsWithDefault}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title="Performing accounts without default history"
                      value={report.performingAccountsWithoutDefault}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title="Total outstanding balance"
                      value={<Currency value={report.totalOutstandingBalance} format="currency" />}
                      size="small"
                      compact={true}
                    />
                  </Card>

                  <Card border={false} header={<Text id="LoanApprove.CreditCheck.stats.creditEvents" />}>
                    <Statistic
                      title={<Text id="LoanApprove.CreditCheck.stats.lessThan6" />}
                      value={get('creditApplications.last6Months', report)}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title={<Text id="LoanApprove.CreditCheck.stats.moreThan6" />}
                      value={get('creditApplications.last12Months', report)}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title={<Text id="LoanApprove.CreditCheck.stats.bouncedCheques" />}
                      value={get('bouncedChqeues.last12Months', report)}
                      size="small"
                      compact={true}
                    />
                    <Statistic
                      title={<Text id="LoanApprove.CreditCheck.stats.reportedFraud" />}
                      value={report.hasReportedFraud}
                      size="small"
                      compact={true}
                    />
                  </Card>
                </Grid>

                <div className="mt4 mb3">
                  <h3>
                    <Text id="LoanApprove.CreditCheck.stats.creditReport" />
                  </h3>
                  <img className="w-100" src={pdfPlaceholder} alt="Credit report" />
                </div>
              </React.Fragment>
            );
          }}
        />
      </StackChild>
    );
  }
}
