import * as React from 'react';

import { Date, Time, Text } from '@kwara/components/src/Intl';
import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import StatusTag from '@kwara/components/src/StatusTag';
import { type BatchTransactionImportT } from '@kwara/models/src';

const back = (
  <BackButton
    type="secondary"
    to={{
      url: '/batch_transaction_imports',
      routeName: 'BatchTransactionImports'
    }}
  />
);

type Props = {
  actions: React.Node,
  batchImport: BatchTransactionImportT
};

export const OverviewMetadata = ({ actions, batchImport }: Props) => {
  return (
    <>
      <ButtonBar className="mb4 pb3" left={[back]} right={actions} />
      <div className="mb2 grey-400">
        <StatusTag state={batchImport.state.current} /> &middot; <Text id="BatchImportDetails.Overview.createdAt" />{' '}
        <Date value={batchImport.createdAt} /> <Time value={batchImport.createdAt} /> &middot;{' '}
        <Text id="BatchImportDetails.Overview.batchId" values={{ batchId: batchImport.id }} />
      </div>
    </>
  );
};
