import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';

import TabNavigation from '../../../../components/TabNavigation';

import { Main } from '../../..';
import { usePermissions } from '../../../../hooks';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';
import { generateTabNavigationRoutes } from '../LeftNavigation/utils/generateTabNavigationRoutes';

import styles from './index.module.scss';

type BodyPropTypes = {
  children: React.ReactNode;
};

export function Body(props: BodyPropTypes) {
  const { pathname } = useLocation();
  const { permission, AppPermissions } = usePermissions();
  const store = useSaccoProfileContext();

  const navItems = generateTabNavigationRoutes(permission.to, AppPermissions, store)[pathname];
  const showTabNav = navItems != undefined;

  React.useEffect(() => {
    if (window) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Main className={styles['container']}>
      <If condition={showTabNav} do={<TabNavigation.LinkTab navItems={navItems} pathname={pathname} />} />

      {props.children}
    </Main>
  );
}
