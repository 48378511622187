import React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import StatusTag from '@kwara/components/src/StatusTag';
import { LoanType } from '@kwara/models/src/models/Loan';
import { Text, Currency } from '@kwara/components/src/Intl';
import { useMemberGuarantees } from '@kwara/models/src/models/request/hooks';
import { Loadable } from '@kwara/components/src/Loadable';
import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { ProductCard } from '../../components/ProductCard';
import { AttributesRow } from '../../components/AttributesRow';
import { HorizontalScroller } from '../HorizontalScroller';
import { useLoanProductStandingWithMemberIdOnly } from '../../hooks';

import styles from './index.module.scss';

interface PropTypes {
  memberId: string;
}

export const GuaranteedLoansHorizontalScroller = ({ memberId }: PropTypes) => {
  const guaranteesR = useMemberGuarantees({ memberId, includes: 'loan.member' });
  const loanStandingResponse = useLoanProductStandingWithMemberIdOnly(memberId);

  return (
    <section className={styles.section}>
      <WizardSectionTitle id="LoanAdd.LoanPortfolio.LoanCategories.GuaranteedLoans.title" />
      <Loadable {...loanStandingResponse}>
        {productStanding => {
          const attributesRow = [
            {
              title: <Text id="LoanAdd.LoanPortfolio.LoanCategories.GuaranteedLoans.canGuaranteeUpto" />,
              value: <Currency format="currency" value={get('canGuaranteeUpTo', productStanding)} />
            },
            {
              title: <Text id="LoanAdd.LoanPortfolio.LoanCategories.GuaranteedLoans.hasGuaranteed" />,
              value: <Currency format="currency" value={get('alreadyGuaranteedAmount', productStanding)} />
            }
          ];
          return <AttributesRow attributes={attributesRow} useBackground={false} />;
        }}
      </Loadable>

      <Loadable {...guaranteesR}>
        {guaranteedLoans => {
          const filteredLoans = guaranteedLoans.filter(g => Number(g.liability) > 0);

          return (
            <HorizontalScroller<LoanType> data={filteredLoans} fallbackId="LoanAdd.LoanPortfolio.Loans.noLoans">
              {({ startIndex, endingIndex }) => {
                const currentGuarantor = filteredLoans.slice(startIndex, endingIndex);

                return (
                  <ul className={styles.loansListbox}>
                    {currentGuarantor.map(guarantee => (
                      <li key={guarantee.id}>
                        <ProductCard
                          title={invoke('loan.member.fullName', guarantee)}
                          amount={<Currency format="currency" value={get('liability', guarantee)} />}
                          state={<StatusTag size="small" state={guarantee.loan.state.current} />}
                        />
                      </li>
                    ))}
                  </ul>
                );
              }}
            </HorizontalScroller>
          );
        }}
      </Loadable>
    </section>
  );
};
