import { usePaginatedOperationalReports } from './usePaginatedOperationalReports';
import { useReportCreate } from './useReportCreate';
import { useReportDownload } from './useReportDownload';

export function useOperationalReportsView(templateKey: string) {
  const cacheKey = templateKey.concat('s');

  const r = usePaginatedOperationalReports(templateKey, cacheKey);
  const { onGenerate } = useReportCreate(templateKey, cacheKey);
  const { onDownload, isDownloading } = useReportDownload();

  return {
    allReports: r.allData,
    isLoadingReports: r.isLoading || r.isFetchingNextPage || r.isFetching,
    hasMoreReports: r.hasNextPage,
    onNext: r.fetchNextPage,
    onGenerate,
    onDownload,
    isReportDownloading: isDownloading
  };
}
