import { useQuery } from 'react-query';

import { MemberMetric } from '@kwara/models/src';

async function fetchMemberOverviewMetrics() {
  const res = await MemberMetric.all();
  return res.data;
}

export const useMemberOverviewMetrics = () => useQuery(['member_overview_metrics'], fetchMemberOverviewMetrics);
