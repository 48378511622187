import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { LinkButton } from '@kwara/components/src/LinkButton';

type DocumentItemPropTypes = {
  name: string;
  onClickHandler: React.MouseEventHandler;
};

export function DocumentItem(props: DocumentItemPropTypes) {
  return (
    <div className={'flex justify-between align-center pa3 mb3 b--dashed br3 bw1 b--light-gray'}>
      <span>
        <Text id={`DocumentUploads.${props.name}.label`} />
      </span>
      <LinkButton as="button" type="button" onClick={props.onClickHandler}>
        <Text id="General.View" />
      </LinkButton>
    </div>
  );
}
