import * as React from 'react';

import * as pages from '../../../pages';
import { ROUTES } from '../../utils/routes';
import { usePermissions } from '../../../hooks';
import { PermittedRoute } from '../PermittedRoute/PermittedRoute';

export type SavingsRoutesParams = {
  savingId: string;
};

export function SavingsRoutes() {
  const { AppPermissions } = usePermissions();

  return (
    <>
      <PermittedRoute<SavingsRoutesParams>
        exact
        name="SavingStatementGenerate"
        permission={AppPermissions.ViewSavings}
        path={ROUTES.savingStatementGenerate}
        render={props => (
          <pages.SavingStatementGenerate baseUrl={`/savings/${props.match.params.savingId}/statement`} {...props} />
        )}
      />
      <PermittedRoute<SavingsRoutesParams>
        exact
        name="SavingClose"
        permission={AppPermissions.CloseSavings}
        path={ROUTES.savingClose}
        render={props => <pages.SavingClose baseUrl={`/savings/${props.match.params.savingId}/close`} {...props} />}
      />
      <PermittedRoute<SavingsRoutesParams>
        exact
        name="MakeTransfer"
        permission={AppPermissions.MakeTransfer}
        path={ROUTES.makeTransfer}
        render={props => <pages.MakeTransfer baseUrl={`/savings/${props.match.params.savingId}/transfer`} {...props} />}
      />
      <PermittedRoute
        exact
        name="SavingDeposit"
        permission={AppPermissions.AddDeposits}
        path={ROUTES.savingDeposit}
        render={props => <pages.SavingPayment {...props} />}
      />
      <PermittedRoute
        exact
        name="SavingFee"
        permission={AppPermissions.AddSavingsFee}
        path={ROUTES.savingFee}
        render={props => <pages.SavingFee {...props} />}
      />
      <PermittedRoute
        exact
        name="SavingWithdraw"
        permission={AppPermissions.AddWithdrawal}
        path={ROUTES.savingWithdraw}
        render={props => <pages.SavingWithdrawal {...props} />}
      />
      {/************************************************************************
       * @SavingDetail is a Component that opens behind any of the routes above
       * whether to redirect to MemberApprove or MemberDetail
       *************************************************************************/}
      <PermittedRoute
        name="SavingDetail"
        permission={AppPermissions.ViewSavings}
        path={ROUTES.savingDetail}
        component={props => <pages.SavingDetail {...props} />}
      />
    </>
  );
}
