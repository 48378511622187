import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import { LoanSubstepProps, ApiError } from 'GlobalTypes';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { BankSelect, BranchSelect } from '@kwara/components/src/Form';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';
import { CollateralTypes, CollateralTypeT, CollateralFields } from '@kwara/models/src';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';

import styles from './index.module.scss';

import { composeErrors } from '../../../../services';
import { IconCheck, IconTrash, IconCancel } from '../../../../assets';
import { CollateralUploadWidget } from '../CollateralUploadWidget/CollateralUploadWidget';

export const IS_DONE_ADDING_COLLATERAL = 'isDoneAddingCollateral';

const COLLATERAL_TYPES_VALUES: { value: CollateralTypeT }[] = pipe(
  values,
  map(value => ({ value }))
)(CollateralTypes);

type PrimitiveDivPropTypes = React.ComponentPropsWithoutRef<'div'>;
type CollateralCardElement = React.ElementRef<'div'>;
export type CollateralCardPropTypes = PrimitiveDivPropTypes &
  Partial<LoanSubstepProps> & {
    index: number;
    isEditingACollateral: boolean;
    hideCollateralEditor(): void;
    withUploadWidget?: boolean;
    onCollateralRemove: (index: number, isEditingACollateral?: boolean) => Promise<void>;
    onCollateralSave: (index: number, isEditingACollateral?: boolean) => Promise<void>;
    onFileInputChange: (e, index) => Promise<void>;
    onFileRemove: (e, index) => Promise<void>;
    fileName: string;
    checked: boolean;
    isLoading?: boolean;
    reset?: () => void;
    patchError?: ApiError;
    path: string;
  };

export const CollateralCard = React.forwardRef<CollateralCardElement, CollateralCardPropTypes>(function CollateralCard(
  props,
  forwardedRef
) {
  const {
    Condition,
    TextField,
    TextArea,
    SelectField,
    className,
    index,
    data,
    formProps,
    hideCollateralEditor,
    isEditingACollateral,
    withUploadWidget,
    onCollateralRemove,
    onCollateralSave,
    isLoading,
    patchError,
    reset,
    path,
    onFileRemove,
    onFileInputChange,
    fileName,
    checked
  }: CollateralCardPropTypes = props;

  const { hasError, errorCodes, resetError } = composeErrors({ errors: { ...patchError }, resetters: [reset] });

  const collaterals = get('values.collaterals', formProps);
  const collateral = get(index, collaterals);
  const bankId = get('assetBankName', collateral);
  const filteredErrors = omit([IS_DONE_ADDING_COLLATERAL, 'securities'], formProps.errors);
  const isFormValid = isEmpty(filteredErrors);
  const disabledSaveBtn = !isFormValid || isLoading;

  function onCancel() {
    resetError();
    hideCollateralEditor();
  }

  async function onRemove(index: number, isEditingACollateral: boolean) {
    await onCollateralRemove(index, isEditingACollateral);
    hideCollateralEditor();
  }

  async function onSave(index: number) {
    await onCollateralSave(index);
    hideCollateralEditor();
  }

  return (
    <div ref={forwardedRef} className={cx(styles['container'], className)}>
      <If
        condition={hasError}
        do={
          <button type="button" title="Close error" className={styles['container__row1']} onClick={onCancel}>
            <AccessibleIcon label={<Text id="Accessibility.clickToCloseErrorDialog" />}>
              <IconCancel />
            </AccessibleIcon>
          </button>
        }
      />

      <div className={styles['container__row2']}>
        <If
          condition={hasError}
          do={errorCodes?.map((error, i) => (
            <ErrorTextWithI18n key={i.toString()} id={`Errors.${error}`} />
          ))}
        />
      </div>
      <div className={styles['container__row3']}>
        <SelectField labelId="Collateral.typeOfAsset" name={path + CollateralFields.type} required>
          <SelectField.Option translationId="Collateral.Type.NONE" value="" />
          {map(
            type => (
              <SelectField.Option translationId={`Collateral.Type.${type.value}`} value={type.value} key={type.value} />
            ),
            COLLATERAL_TYPES_VALUES
          )}
        </SelectField>

        <TextField
          type="number"
          name={path + CollateralFields.amount}
          leftGlyph="Currency.orgCurrency"
          labelId="Collateral.amount"
          required
          isCurrency
        />
      </div>

      <Condition when={path + CollateralFields.type} is={CollateralTypes.BANK_ACCOUNT}>
        <BankSelect name={path + CollateralFields.bank} />
        <BranchSelect
          required
          bankId={bankId}
          name={path + CollateralFields.branch}
          labelId="Forms.TransactionForm.Branch.label"
        />
        <TextField
          name={path + CollateralFields.account}
          labelId="Forms.TransactionForm.Account.label"
          placeholderId="Forms.TransactionForm.Account.placeholder"
          required
        />
      </Condition>

      <Condition when={path + CollateralFields.type} is={CollateralTypes.LAND}>
        <TextField
          labelId={`Collateral.LAND.landRegistration`}
          placeholderId={`Collateral.LAND.landRegistration.placeholder`}
          name={path + CollateralFields.landRegistration}
        />
      </Condition>

      <Condition when={path + CollateralFields.type} is={CollateralTypes.LIFE_INSURANCE}>
        <TextField
          labelId={`Collateral.LIFE_INSURANCE.insuranceCompany`}
          name={path + CollateralFields.insuranceCompany}
        />
        <TextField
          labelId={`Collateral.LIFE_INSURANCE.insuranceNumber`}
          name={path + CollateralFields.insuranceNumber}
        />
      </Condition>

      <Condition when={path + CollateralFields.type} is={CollateralTypes.STOCK}>
        <TextField
          labelId="Collateral.STOCK.label"
          placeholderId="Collateral.STOCK.placeholder"
          name={path + CollateralFields.stockCds}
        />
      </Condition>

      <div className={styles['container__row4']}>
        <TextArea
          name={path + CollateralFields.narration}
          labelId="Collateral.Narration.label"
          placeholderId="Collateral.Narration.placeholder"
        />
      </div>

      <If
        condition={withUploadWidget}
        do={
          <div className={styles['container__row5']}>
            <CollateralUploadWidget
              loanApplication={get('loan.loanApplication', data)}
              collateral={collateral}
              labelId={`Collateral.FileInput.label`}
              fileName={fileName}
              onFileInputChange={e => onFileInputChange(e, index)}
              onRemove={e => onFileRemove(e, index)}
              checked={checked}
            />
          </div>
        }
      />

      <div className={styles['container__row6']}>
        <button
          type="button"
          title="Remove"
          data-testid="collRemoveBtn"
          disabled={isLoading}
          onClick={() => onRemove(index, isEditingACollateral)}
        >
          <Text id="Collateral.remove" /> &nbsp;
          <IconTrash />
        </button>

        <button
          type="button"
          title="Save"
          data-testid="collSaveBtn"
          disabled={disabledSaveBtn}
          onClick={() => onSave(index)}
        >
          <Text id="Collateral.save" /> &nbsp;
          <IconCheck />
        </button>
      </div>
    </div>
  );
});
