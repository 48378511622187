import * as React from 'react';
import cx from 'classnames';

import { zIndices } from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';

import { Card } from '../../../Card';
import { useNotificationSystemContext } from '../..';
import iconNoAnnouncement from '../../../../assets/imgs/IconKwaraBrand.png';

import styles from './index.module.scss';

type AnnouncementPropTypes = {
  children: React.ReactElement[];
};

export function Announcement(props: AnnouncementPropTypes) {
  const {
    headingId,
    bodyId,
    shownAnnouncements,
    iconRef,
    onHideAnnouncements,
    hasAnnouncements
  } = useNotificationSystemContext();

  return (
    <If
      condition={shownAnnouncements}
      do={
        <Overlay opacityPercentage={20}>
          <ModalWrapper
            elementType="notificationsystem"
            id={bodyId}
            isOpen={shownAnnouncements}
            onClose={onHideAnnouncements}
            aria-labelledby={headingId}
            exemptMouseEventRefs={[iconRef]}
            closeOnOutClick
          >
            <section>
              <Card className={cx(styles['card'], zIndices.Max)}>
                <If
                  condition={hasAnnouncements}
                  do={props.children}
                  else={
                    <div className={styles['no-notification']}>
                      <img src={iconNoAnnouncement} alt="No announcement" />
                      <p>
                        <Text id="NotificationSystem.noNotification" />
                      </p>
                    </div>
                  }
                />
              </Card>
            </section>
          </ModalWrapper>
        </Overlay>
      }
    />
  );
}
