import * as React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';

import { FILTER_TYPES } from '../../../../components/Filter/utils/filterTypes';
import { TextboxBaseFilter, DateBaseFilter, ComboboxBaseFilter } from '../../../../components/Filter/pages';

///
const TransactionSearchFilter = () => (
  <TextboxBaseFilter
    name="Search by Staff name, member or account ID"
    filterName={FILTER_TYPES.TRANSACTION_SEARCH}
    placeholderId="Finance.Transactions.Filter.Search.placeholder"
  />
);

///
const TransactionDateFilter = () => (
  <DateBaseFilter
    filterType={FILTER_TYPES.DATE}
    disabledDays={{ from: { after: getCurrentDate() }, to: { after: getCurrentDate() } }}
  />
);

///
const TransactionStatusFilter = () => (
  <ComboboxBaseFilter filterType={FILTER_TYPES.STATUS} placeholderId="Finance.Transactions.Filter.Status.placeholder" />
);

export { TransactionSearchFilter, TransactionDateFilter, TransactionStatusFilter };
