// @flow

import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import { ActionButton } from '@kwara/components/src/Button';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { type LoanType } from '@kwara/models/src';
import { Tooltip } from '@kwara/components/src/Tooltip';
import { Card } from '@kwara/components/src/Card';

import { Currency } from '../../../../../components/Currency';
import { loanPath } from '../../../../../lib/urls';
import styles from '../../index.module.scss';

const CardTitle = ({ member, total }) => (
  <div className={cx(styles.container, 'pv2')}>
    <div className={cx(styles.a, 'kw-text-large kw-weight-bold')}>
      <Text id="LoanApprove.Overview.loans" />
    </div>
    <div className={cx(styles.b, 'kw-text-x-small grey-400')}>
      <Text id="LoanApprove.Overview.loans.LoansCard.amount" values={{ value: total }} />
    </div>
    <div className={cx(styles.c, 'kw-text-large kw-weight-bold')}>
      <Currency format="currency" value={member.totalLoansBalance} />
    </div>
    <div className={cx(styles.d, 'kw-text-x-small grey-400')}>
      <Text id="LoanApprove.Overview.loans.LoansCard.totalBalance" />
    </div>
  </div>
);

const LoanCardItem = ({ loan }) => (
  <div className="pv2">
    <div className={cx(styles.container, 'pv2')}>
      <div className={cx(styles.a, 'kw-text-medium kw-weight-bold')}>{get('product.name', loan)}</div>
      <div className={cx(styles.b, 'kw-text-2x-small grey-400')}>{loan.id}</div>
      <div className={cx(styles.c)}>
        <StatusTag size="small" state={get('state.current', loan)} />
      </div>
    </div>
    <StatisticRow widthClass="w-50" compact={true}>
      <Statistic
        compact
        size="small"
        title={<Text id="LoanApprove.Overview.loans.LoansCard.principalAmount" />}
        value={<Currency format="currency" value={loan.amount} />}
      />
      <Statistic
        compact
        size="small"
        title={<Text id="LoanApprove.Overview.loans.LoansCard.outstandingBalance" />}
        value={<Currency format="currency" value={loan.totalBalance} />}
      />
    </StatisticRow>
    <StatisticRow widthClass="w-50" compact={true}>
      <Statistic
        compact
        size="small"
        title={<Text id="LoanApprove.Overview.loans.LoansCard.arrearsAmount" />}
        value={<Currency format="currency" value={loan.principal.due} />}
      />
      <Statistic
        compact
        size="small"
        title={
          <Tooltip tooltipId={'LoanApprove.Overview.loans.LoansCard.nextInstallment.tooltip'}>
            <Text id="LoanApprove.Overview.loans.LoansCard.nextInstallment" />
          </Tooltip>
        }
        value={<Currency format="currency" value={get('loanApplication.installmentAmount', loan)} />}
      />
    </StatisticRow>
    <ActionButton className="mt2" to={loanPath({ id: loan.id })} />
  </div>
);

const filterAccounts = (loans: LoanType[]) =>
  pipe(
    filter(l => Number(l.totalBalance)),
    sortBy(l => -Number(l.totalBalance))
  )(loans);

export const LoansCard = ({ member, loans, className }) => {
  const accounts = filterAccounts(loans);

  return (
    <Card border={true} className={className}>
      <CardTitle member={member} total={size(accounts)} />
      {map(
        loan => (
          <LoanCardItem key={loan.id} loan={loan} />
        ),
        accounts
      )}
    </Card>
  );
};
