// @flow

import * as React from 'react';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { type InvitationModelT } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime } from '@kwara/components/src/Intl';

import Table, { Row, Cell, Heading, Footer } from '../../../../../components/Table';
import { useInvitations, useDeprecatedPagination } from '../../../../../hooks';

const includes = ['role'];

export const Invites = () => {
  const { allData, onNext, hasMore, ...r } = useDeprecatedPagination<InvitationModelT>(useInvitations, currentPage => [
    includes,
    currentPage
  ]);

  const heading = (
    <Row className="grey-400">
      <Heading translationId="Settings.Workspace.Invites.name" />
      <Heading translationId="Settings.Workspace.Invites.role" />
      <Heading translationId="Settings.Workspace.Invites.sent" />
      <Heading translationId="Settings.Workspace.Invites.expires" />

      <Heading width="115px" translationId="Settings.Workspace.Invites.status" />
    </Row>
  );

  const footer = (
    <Footer
      isLoading={r.isLoading}
      onNext={onNext}
      errors={get('error.messages', r)}
      colSpan={size(get('props.children', heading))}
      items={allData}
      hasMore={hasMore}
    />
  );

  return (
    <Table heading={heading} footer={footer}>
      {map(invite => {
        return (
          <Row key={invite.id}>
            <Cell title={invite.email}>{invite.email}</Cell>
            <Cell>{get('role.name', invite)}</Cell>
            <Cell>
              <DateTime value={invite.createdAt} />
            </Cell>
            <Cell>
              <DateTime value={invite.expiresAt} />
            </Cell>
            <Cell>
              <StatusTag state={invite.getStatus()} />
            </Cell>
          </Row>
        );
      }, allData)}
    </Table>
  );
};
