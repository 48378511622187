type State = {
  step: 'scan' | 'confirm' | 'success';
};

export const initialState = {
  step: 'scan'
};

export const reducer = (state: State, action: { type: 'ON_SCAN' | 'ON_CONFIRMED' | 'ON_BACK_TO_QR' }) => {
  switch (action.type) {
    case 'ON_SCAN':
      return {
        ...state,
        step: 'confirm'
      };

    case 'ON_CONFIRMED':
      return {
        ...state,
        step: 'success'
      };

    case 'ON_BACK_TO_QR':
      return {
        ...state,
        step: 'scan'
      };

    default:
      return state;
  }
};
