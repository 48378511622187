import * as React from 'react';

import { Text, TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.scss';

type SectionProps = {
  titleId: TranslationId;
  children?: React.ReactNode;
  editUrl?: string;
  ariaLabel?: string;
};

export function isEmptyRecord(element: React.ReactElement) {
  return (
    element.props.do == null &&
    element.props.else == null &&
    element.props.value == null &&
    element.props.valueId == null
  );
}

export function NoRecordsMessage({ children }: { children: React.ReactNode }): any {
  return React.Children.toArray(children).every(isEmptyRecord) ? '-' : children;
}

export function Section({ titleId, children, editUrl, ariaLabel }: SectionProps) {
  return (
    <section className={styles.Section} aria-label={ariaLabel}>
      <h3 className={`ma0 kw-text-large kw-weight-bold pb2 ${styles.Heading}`}>
        <Text id={titleId} />
      </h3>
      <div className="kw-text-medium neutral-500">
        <div>
          <NoRecordsMessage>{children}</NoRecordsMessage>
        </div>
      </div>

      <div className={styles.EditColumn}>
        {editUrl ? (
          <Link to={editUrl} type="primary" underline active>
            <Text id="General.Edit" />
          </Link>
        ) : null}
      </div>
    </section>
  );
}
