// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import type { WhereT } from '@kwara/models/src';
import { type requestParamsT } from '@kwara/models/src/models/request';
import type { LoadableBasePropsT } from '@kwara/components/src/Loadable';

import { EMPTY } from '.';

// This hook is deprecated in favour of using useInfiniteRequest from react-query
//
export function useDeprecatedPagination<T>(
  useFn: (p: any) => LoadableBasePropsT<T>,
  getArgs: (currentPage: number) => requestParamsT,
  filterValue?: WhereT
) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [allData, setAllData] = React.useState([]);
  const args = getArgs(currentPage);
  const r = useFn(...args);

  const totalResults = get('meta.stats.total.count', r);
  const totalPages = get('meta.stats.total.pages', r);
  // If the meta data is not availablee in the response,
  // we always assume more pages.
  const hasMore = totalPages ? totalPages > currentPage : true;

  React.useEffect(() => {
    setCurrentPage(1);
    setAllData([]);
  }, [filterValue]);

  React.useEffect(() => {
    setAllData(d => [...d, ...(r.data !== EMPTY ? r.data : [])]);
  }, [r.data]);

  const onNext = React.useCallback(() => {
    if (hasMore) {
      setCurrentPage(currentPage + 1);
    }
  }, [hasMore, currentPage]);

  const refetch = () => {
    setCurrentPage(1);
    setAllData([]);
    r.refetch();
  };

  return {
    ...r,
    refetch,
    allData,
    onNext,
    hasMore,
    totalResults,
    currentPage
  };
}
