import * as React from 'react';
import size from 'lodash/fp/size';

import { Render } from '@kwara/components/src';
import { AttachmentT, MemberType } from '@kwara/models/src';
import { AttachmentContext } from '@kwara/models/src/models/Attachment';
import { allowedAttachments, WithViewer } from '@kwara/components/src/MemberUploadWidget';

function getFilteredAttachments(contexts: AttachmentContext[], attachments: AttachmentT[]) {
  return allowedAttachments.filterByContext(contexts, attachments);
}

export function getAttachmentsSizeByContexts(contexts: AttachmentContext[], attachments: AttachmentT[]) {
  return size(getFilteredAttachments(contexts, attachments));
}

export type GenericPropTypes = {
  size?: 'tiny' | 'default';
};

export interface DocumentListPropTypes extends GenericPropTypes {
  member: MemberType;
  contexts: AttachmentContext[];
  attachments?: AttachmentT[];
  fallbackId?: string;
}

interface DocumentListBasePropTypes extends GenericPropTypes, DocumentListPropTypes {
  children(props: { attachments: AttachmentT[]; setShownAttachment: (attachment?: AttachmentT) => void }): JSX.Element;
}

export function DocumentListBase(props: DocumentListBasePropTypes) {
  const { member, contexts, children, attachments = [], fallbackId = 'DocumentUploads.noDocuments' } = props;
  const filteredAttachments = contexts ? getFilteredAttachments(contexts, attachments) : attachments;

  return (
    <Render condition={!!filteredAttachments.length} fallbackId={fallbackId}>
      <WithViewer member={member}>
        {({ setShownAttachment }) => children({ attachments: filteredAttachments, setShownAttachment })}
      </WithViewer>
    </Render>
  );
}
