import merge from 'lodash/merge';

// Some forms rely on Spraypaint instances as models.
// We use merge so that when we pass an instance (of Member, Loan or similar) we
// 1. mutate the instance itself by applying the new props, so that Spraypaint
//    will detect these changes (important when PATCHing)
// 2. recursively copy all nested relationships and instances,
//    so we don't lose track of nested relationships needed by Spraypaint
// 3. finally, we return a clone so that React detects the new model as a new object.
// Note that this will not override arrays, but will merge them, so that data: [1,2,3] updates: [1, 4]
// Will result in [1, 4, 3], see " Array and plain object properties are merged recursively" on
// https://lodash.com/docs/4.17.13#merge
// If you need to overwrite arrays atm the only way is mutating "data". Overwriting can be achieved
// here by using mergeWith and configuring a specific behaviour with arrays, but we must account
// for Spraypaint relationships when doing it. See ch6500
export const patchValues = (data: Record<string, any>, updates: Record<string, any>) => {
  // if the base data is an instance...
  if (data.isSpraypaintInstance) {
    // ...we mutate the instance to notify Spraypaint...
    // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
    /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
    const merged = merge(data, updates);
    // ...and we return a copy so that React state diffing always detects the change
    return merged.dup();
  }

  // For simple objects we just merge the two objects into a new one
  return {
    ...data,
    ...updates
  };
};
