import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconTellersElement = React.ElementRef<'svg'>;
interface IconTellersPropTypes extends PrimitiveSvgPropTypes {}

export const IconTellers = React.forwardRef<IconTellersElement, IconTellersPropTypes>(function IconTellers(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.48 5.85869C21.4164 5.41415 21.0645 5.06646 20.6191 5.00848C20.49 4.99139 20.3611 4.97172 20.2324 4.95147C20.0833 4.92802 19.8977 4.89737 19.6863 4.85942C19.5451 4.83409 19.3922 4.80551 19.2311 4.77365C18.4146 4.61227 17.4279 4.37565 16.636 4.06798C15.2035 3.51144 13.8156 2.82416 12.4535 2.11397C12.1538 1.9573 11.7893 1.96544 11.4954 2.13101C10.2139 2.84808 8.89722 3.53579 7.52747 4.06798C6.74195 4.37311 5.71534 4.60971 4.85035 4.77177C4.42493 4.85148 4.05189 4.91076 3.78577 4.95002C3.64722 4.97047 3.50855 4.99028 3.36964 5.00738C2.92054 5.06184 2.56397 5.41092 2.50001 5.85869C1.97803 9.51317 2.39197 12.8028 3.91444 15.5689C5.43983 18.3407 8.01758 20.4762 11.6159 21.9275C11.739 21.9772 11.8696 22.0014 12 22.0001C12.1237 21.9988 12.2472 21.9746 12.364 21.9275C15.9624 20.4762 18.54 18.3407 20.0656 15.5689C21.5879 12.8029 22.0021 9.51317 21.48 5.85869ZM8.00001 10.0001C8.00001 7.79092 9.79087 6.00006 12 6.00006C14.2091 6.00006 16 7.79092 16 10.0001C16 12.2092 14.2091 14.0001 12 14.0001C9.79087 14.0001 8.00001 12.2092 8.00001 10.0001ZM8.39716 17.4814C9.14334 17.1585 10.2655 17.0001 12 17.0001C13.7753 17.0001 14.9078 17.1543 15.6538 17.4801C16.16 17.7011 16.7494 17.47 16.9705 16.9639C17.1915 16.4578 16.9604 15.8683 16.4543 15.6472C15.3044 15.145 13.8147 15.0001 12 15.0001C10.2123 15.0001 8.74384 15.1522 7.60286 15.6459C7.096 15.8652 6.86291 16.4539 7.08225 16.9608C7.30159 17.4677 7.8903 17.7007 8.39716 17.4814Z"
        fill="#475569"
      />
      <path
        d="M12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8Z"
        fill="#475569"
      />
    </svg>
  );
});

IconTellers.defaultProps = {
  width: '25',
  height: '24'
};
