//@flow
import { useReducer } from 'react';

import STORE from './Reducer';
import type { OnPasswordExpiredPayloadType, OnPasswordAboutToExpirePayloadType } from './Reducer';

const initialState = {
  shown: false,
  token: null,
  status: null,
  daysCount: undefined,
  onContinueWithoutPasswordChange: undefined
};

function usePasswordExpiryAlerter() {
  const [state, dispatch] = useReducer(STORE.Reducer, initialState);

  function _dispatch<PayloadType>(type: string, payload: PayloadType) {
    dispatch({ type, payload });
  }

  function dispatchOnPasswordExpired(token: string) {
    _dispatch<OnPasswordExpiredPayloadType>(STORE.Types.ON_PASSWORD_EXPIRED, { token });
  }

  function dispatchOnPasswordAboutToExpire(
    token: string,
    daysCount: string,
    onContinueWithoutPasswordChange: Function
  ) {
    _dispatch<OnPasswordAboutToExpirePayloadType>(STORE.Types.ON_PASSWORD_ABOUT_TO_EXPIRE, {
      token,
      daysCount,
      onContinueWithoutPasswordChange
    });
  }

  function dispatchOnReset() {
    _dispatch(STORE.Types.ON_RESET);
  }

  return {
    state,
    actions: {
      dispatchOnPasswordExpired,
      dispatchOnPasswordAboutToExpire,
      dispatchOnReset
    }
  };
}

export { usePasswordExpiryAlerter };
