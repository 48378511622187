// @flow

import React from 'react';
import cx from 'classnames';
import type { RouterHistory, LocationShape } from 'react-router-dom';
import join from 'lodash/fp/join';

import { ActionButton } from '@kwara/components/src/Button';
import { FileDropzone } from '@kwara/components/src/FileDropzone';
import { Text, FileSize } from '@kwara/components/src/Intl';
import { BatchTransactionImport, type ModelErrors, createModelErrors } from '@kwara/models/src';

import Wizard from '../../../components/Wizard';
import { type SubstepProps } from '..';
import ModelErrorBanner from '../../../components/ModelErrorBanner';

import style from '../index.module.scss';

const accept = join(', ', [
  ...BatchTransactionImport.SupportedMimeTypes,
  ...BatchTransactionImport.SupportedExtensions
]);
const Content = ({ StackChild, addData, data, formProps }: SubstepProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ModelErrors>({});
  const onDownloadTemplate = () => {
    setErrors({});
    setIsLoading(true);
    BatchTransactionImport.getTemplate()
      .catch(() => {
        setErrors(
          createModelErrors({
            base: 'UI_BATCH_TEMPLATE_DOWNLOAD_FAILURE'
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <StackChild>
      <ModelErrorBanner errors={errors} />
      <div className={cx('pa3 br2 mb4', style.Wrapper)}>
        <div className="black kw-weight-regular">
          <div className="grey-400 kw-text-regular">
            <FileSize value={6000} />
          </div>
          <span className="kw-text-medium">
            <Text id={`BatchTransactionModal.excelTemplate`} />
          </span>
        </div>
        <div>
          <ActionButton
            onClick={onDownloadTemplate}
            type={isLoading ? 'spinner' : 'download'}
            aria-label="download template"
            disabled={isLoading}
          />
        </div>
      </div>
      <FileDropzone
        name="file"
        accept={accept}
        file={data.file}
        onChange={async e => {
          const file = e.target.files[0];
          if (file) {
            addData({ file });
            formProps.form.submit();
          }
        }}
      />
    </StackChild>
  );
};

const config = {
  add: {
    titleId: 'BatchTransactionModal.title',
    subtitleId: 'BatchTransactionModal.subtitle',
    Component: Content,
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    validate: {
      file: {
        isRequired: () => true
      }
    }
  }
};

export const UploadAdd = ({ history, location }: { history: RouterHistory, location: LocationShape }) => {
  const onComplete = ({ file }: { file: File }) => BatchTransactionImport.uploadTemplate(file);

  return (
    <Wizard
      analyticsId="UploadAdd"
      baseUrl="/batch_transaction_imports"
      currentStep="add"
      history={history}
      initialdata={{}}
      cancelReturnsTo="/batch_transaction_imports"
      onSubmit={onComplete}
      steps={config}
      startId="add"
      titleId="BatchTransactionModal.headerTitle"
      location={location}
    />
  );
};
