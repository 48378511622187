import * as React from 'react';
import cx from 'classnames';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Banner from '@kwara/components/src/Banner';
import { If } from '@kwara/components/src/If/If';
import { Stack } from '@kwara/components/src/Stack';
import createValidator from '@kwara/lib/src/validator';
import ScrollIntoFocus from '@kwara/components/src/ScrollIntoFocus';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import {
  Condition,
  SubscribedTextField,
  SubscribedSelect,
  SubscribedSelectField,
  SubscribedCheckbox,
  SubscribedPhoneField,
  SubscribedDatePicker,
  SubscribedCombobox
} from '@kwara/components/src/Form';

import Action from '../../utils/action';
import { Header } from './components/Header';
import { WizardFooter } from '../WizardFooter';
import { SubscribedRadioGroup, SubscribedTextArea } from '../FormFields/FormFields';
import { ActionEvent, RenderableStep, OnControlNextButton } from '../../types/sharedTypes';

import styles from './index.module.scss';

export type ComponentProps<Data> = {
  data: Data;
  parentUrl: string;
  Condition: typeof Condition;
  StackChild: typeof Stack.Child;
  PhoneField: typeof SubscribedPhoneField;
  Select: typeof SubscribedSelect;
  Checkbox: typeof SubscribedCheckbox;
  TextArea: typeof SubscribedTextArea;
  TextField: typeof SubscribedTextField;
  RadioGroup: typeof SubscribedRadioGroup;
  SelectField: typeof SubscribedSelectField;
  DatePicker: typeof SubscribedDatePicker;
  Combobox: typeof SubscribedCombobox;
  addData: (updated: Partial<Data>) => Promise<void>;
  onChange: (data: Partial<Data>) => Promise<Data>;
  addDataAndContinue: (updated: Partial<Data>) => void;
};

type SubStepContainerPropTypes<Data> = {
  as?: 'monoView' | 'diView'; //monoView means with no <LeftNavigation/> while diView means with <LeftNavigation/>
  error?: any;
  parentUrl?: string;
  className?: string;
  fullPage?: boolean;
  currentState: Data;
  isProcessing?: boolean;
  hideActionBar?: boolean;
  subStep: RenderableStep;
  onChange: (data: Data) => Promise<Data>;
  setError?: (err: typeof Error | null) => void;
  onAction: (action: ActionEvent<Data>) => void;
  leftNavigation?: React.ReactElement;
} & Partial<RouteComponentProps<{}>>;

export function SubStepContainer<TProps>({
  className = '',
  currentState,
  error,
  setError,
  history,
  isProcessing,
  onAction,
  onChange,
  parentUrl,
  subStep,
  leftNavigation,
  hideActionBar,
  as = 'diView'
}: SubStepContainerPropTypes<TProps>) {
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(false);

  ///
  function generateValidator() {
    if (typeof subStep.validate === 'function') {
      return createValidator(subStep.validate(currentState));
    }

    if (subStep.validate) {
      return createValidator(subStep.validate);
    }

    return undefined;
  }

  /***
   * When the form is submitted, and the validations
   * pass, we call onChange to update the state of
   * the Wizard with new data.
   */
  async function dispatchChangeEvent(patch: TProps) {
    await onChange(patch);
  }

  /***
   * Calls onAction with the action provided and a
   * data payload supplied at call-time
   */
  function createActionHandler(action: Action) {
    return async (payload?: TProps) => {
      onAction({ payload, action });
    };
  }

  ///
  const disableNextButton = React.useCallback(
    (onControlledState?: OnControlNextButton) => {
      if (onControlledState) {
        onControlledState(isNextButtonDisabled, setIsNextButtonDisabled);
      } else {
        setIsNextButtonDisabled(true);
      }
    },
    [isNextButtonDisabled]
  );

  ///
  const enableNextButton = React.useCallback(
    (onControlledState?: OnControlNextButton) => {
      if (onControlledState) {
        onControlledState(isNextButtonDisabled, setIsNextButtonDisabled);
      } else {
        setIsNextButtonDisabled(false);
      }
    },
    [isNextButtonDisabled]
  );

  ///
  function getErrorBanner() {
    if (subStep.hideErrorBanner) {
      return null;
    }

    if (!isEmpty(error) && (error instanceof Error || error.message)) {
      return (
        <ScrollIntoFocus>
          <Banner className="mt4" text={error.message} state="error" />
        </ScrollIntoFocus>
      );
    }

    if (!isEmpty(error)) {
      return (
        <ScrollIntoFocus>
          <ModelErrorBanner className="mt4" errors={error} state="error" />
        </ScrollIntoFocus>
      );
    }

    return null;
  }

  return (
    <Form
      initialValues={currentState}
      onSubmit={dispatchChangeEvent}
      validate={generateValidator()}
      mutators={arrayMutators as any}
      render={formProps => {
        const { handleSubmit, invalid, form, values } = formProps;
        const canProgress = !invalid;
        const errorBanner = getErrorBanner();
        const hideActions = subStep.hideActions === true;

        /***
         * When resetValidation is called, the validation ruleset object
         * is recalculated based on the form's live values.
         * Warning: There must be a condition to prevent the form
         * from re-rendering in an infite loop
         */

        async function resetValidation() {
          if (typeof subStep.validate === 'function') {
            form.setConfig('validate', createValidator(subStep.validate(values)));
          }
        }

        /***
         * When addDataAndContinue is called to programmatically
         * move to the next step, we find an action in the array
         * that behavesAs 'next' or 'approve'.
         */
        const handleNext = createActionHandler(
          find(subStep.actions, { behavesAs: 'next' }) ||
            find(subStep.actions, { behavesAs: 'nextWithPromise' }) ||
            find(subStep.actions, { behavesAs: 'approve' })
        );

        /***
         * Sets this data on the Wizard programmatically
         * without requiring a form input. This is useful
         * for more complex components such as product selectors
         * or member search.
         */
        async function addData(data: any) {
          form.batch(function() {
            forEach(data, function(value: any, name: any) {
              form.change(name, value);
            });
          });
        }

        /***
         * Adds data programatically and then proceeds immedietely
         * to the next Wizard step.
         */
        async function addDataAndContinue(data: any) {
          await addData(data);
          await form.submit();

          if (typeof handleNext === 'function') {
            handleNext();
          } else {
            throw new Error('addDataAndContinue called but handleNext is null');
          }
        }

        const component = (
          <subStep.Component
            error={error}
            config={subStep}
            history={history}
            addData={addData}
            data={currentState}
            setError={setError}
            onChange={onChange}
            parentUrl={parentUrl}
            formProps={formProps}
            Condition={Condition}
            StackChild={Stack.Child}
            Select={SubscribedSelect}
            TextArea={SubscribedTextArea}
            Checkbox={SubscribedCheckbox}
            TextField={SubscribedTextField}
            RadioGroup={SubscribedRadioGroup}
            PhoneField={SubscribedPhoneField}
            SelectField={SubscribedSelectField}
            Combobox={SubscribedCombobox}
            DatePicker={SubscribedDatePicker}
            customProps={subStep.customProps}
            resetValidation={resetValidation}
            enableNextButton={enableNextButton}
            disableNextButton={disableNextButton}
            addDataAndContinue={addDataAndContinue}
          />
        );

        return (
          <form
            data-testid="wizardFormVisible"
            onSubmit={handleSubmit}
            className={cx(styles.SubStep, hideActions ? styles.actionsHidden : null, className)}
          >
            <If
              condition={as === 'monoView'}
              do={component}
              else={
                <div className={styles.BodyContainer}>
                  <div className={styles.Column1}>
                    {leftNavigation}
                    <div className={styles.VerticalDivider} />
                  </div>

                  <div className={styles.Column2}>
                    <If
                      condition={!!subStep.titleId || !!subStep.subtitleId || !!errorBanner}
                      do={
                        <Header
                          titleId={subStep.titleId!}
                          subtitleId={subStep.subtitleId}
                          values={(currentState as unknown) as Record<string, string>}
                        >
                          {errorBanner}
                        </Header>
                      }
                    />

                    {component}
                  </div>
                </div>
              }
            />

            <If
              condition={!hideActionBar}
              do={
                <WizardFooter
                  hideActions={hideActions}
                  actions={compact([...subStep.actions])}
                  onAction={async (action: Action) => {
                    if (action.behavesAs !== 'cancel') {
                      // We do not dispatchChangeEvent on cancel, in case the
                      // user-does not confirm the cancelation and returns the
                      // form, the data will be as is (and not marked invalid)
                      // see ch10634
                      await form.submit();
                    }
                    onAction({ action, payload: null });
                  }}
                  isProcessing={isProcessing}
                  nextDisabled={!canProgress || isNextButtonDisabled}
                />
              }
            />
          </form>
        );
      }}
    />
  );
}

export default withRouter(SubStepContainer as any) as any;
