import React from 'react';
import find from 'lodash/fp/find';
import capitalize from 'lodash/fp/capitalize';

import { If } from '@kwara/components/src/If/If';
import { Text, Percent, Currency } from '@kwara/components/src/Intl';
import { WizardSummary } from '../../../../../../../components/WizardSummary';

export function Summary({ data, parentUrl }) {
  const {
    dividendPercentage,
    witholdingTaxPercentage,
    accountStatus,
    productId,
    beginningOfFinancialYear,
    endOfFinancialYear,
    allocatedDividendAmount,
    calculationMethod,
    notes,
    products,
    reportType
  } = data;

  const selectedProduct = find(product => product.id === productId, products);

  const editUrl = (path: string) => `${parentUrl}/${path}`;
  return (
    <>
      <WizardSummary.Section
        titleId={`Finance.DividendReports.Generate.Details.${reportType}.title`}
        editUrl={editUrl('details/1')}
        ariaLabel="Details"
      >
        <WizardSummary.Record
          labelId={`Finance.DividendReports.Generate.Details.Section.${reportType}.approvedDividendPercentage`}
          value={<Percent value={dividendPercentage / 100} />}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.witholdingTaxPercentage"
          value={<Percent value={witholdingTaxPercentage / 100} />}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.accountStatus"
          value={accountStatus}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.product"
          value={selectedProduct.name}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.beginningOfFinancialYear"
          value={beginningOfFinancialYear}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.endOfFinancialYear"
          value={endOfFinancialYear}
        />
        <WizardSummary.Record
          labelId={`Finance.DividendReports.Generate.Details.Section.${reportType}.allocatedDividendAmount`}
          value={<Currency value={allocatedDividendAmount} format="currency" />}
        />
        <WizardSummary.Record
          labelId="Finance.DividendReports.Generate.Details.Section.calculationMethod"
          value={capitalize(calculationMethod)}
        />
      </WizardSummary.Section>
      <WizardSummary.Section titleId="LoanAdd.SummaryAndReview.Notes.title" ariaLabel="Notes">
        <If
          condition={!!notes}
          do={
            <>
              <Text id={`Finance.DividendReports.Generate.Details.LeftNavigation.${reportType}.title`} />
              <WizardSummary.Record colorClassName="primary-500" value={notes} />
            </>
          }
        />
      </WizardSummary.Section>
    </>
  );
}
