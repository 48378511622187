// @flow

import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';

import { type HttpError } from '../../../../services';
import { LoanProductStanding, type LoanProductStandingT } from '@kwara/models/src';

async function getMemberLoanProductStanding({ queryKey }) {
  const [_, memberId, loanProductId] = queryKey;

  const scope = LoanProductStanding(memberId, loanProductId);
  const res = await scope.find();
  return res.data;
}

export type ReturnType = UseQueryResult<LoanProductStandingT, HttpError>;

export const useMemberLoanProductStanding = (
  memberId: string,
  loanProductId: string,
  options?: UseQueryOptions
): ReturnType =>
  useQuery(['member_loan_product_standing', memberId, loanProductId], getMemberLoanProductStanding, options);
