import * as React from 'react';
import cx from 'classnames';

import { useIntl } from 'react-intl';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { AccessibleIcon } from '@kwara/components/src/AccessibleIcon/AccessibleIcon';

import { useFilterContext } from '../..';
import { IconAdd } from '../../assets/IconAdd';
import { IconCancel } from '../../../../assets';
import { FilterButton } from './FilterButton';

import styles from './index.module.scss';

type HeaderPropTypes = {
  disabled?: boolean;
};

export function Header({ disabled }: HeaderPropTypes) {
  const intl = useIntl();
  const {
    hasSelectedFilters,
    flattenedSelectedFilters,
    toggleFilter,
    headerRef,
    open,
    onRemoveFilterValue,
    onResetFilter
  } = useFilterContext();

  return (
    <div ref={headerRef} className={cx(styles['container'], { [styles['container--disabled']]: !!disabled })}>
      <FilterButton disabled={disabled} onClick={toggleFilter}>
        <span>
          <AccessibleIcon label={<Text id="Filter.Accessibility.toggle" />}>
            <If condition={open} do={<IconCancel circleStrokeWidth="0" />} else={<IconAdd />} />
          </AccessibleIcon>
        </span>
        <span>
          <Text id={open ? 'Filter.closeFilter' : 'Filter.addFilter'} />
        </span>
      </FilterButton>

      <If
        condition={hasSelectedFilters}
        do={
          <ul className={styles['filters-list']}>
            {flattenedSelectedFilters?.map((filter, index) => {
              const name = String(filter.renderWithValue ? `${filter.name}: ${filter.value}` : filter.name);

              return (
                <li key={index.toString()} title={intl.formatMessage({ id: `Filter.${filter.belongsTo}` })}>
                  <Button
                    className={cx(styles['button'], styles['button--filter'])}
                    onClick={() => onRemoveFilterValue(filter.belongsTo, filter)}
                  >
                    <span>{name}</span>
                    <span>
                      <AccessibleIcon
                        label={<Text id="Filter.Accessibility.removeFilter" values={{ name: filter.name }} />}
                      >
                        <IconCancel circleStrokeWidth="0" />
                      </AccessibleIcon>
                    </span>
                  </Button>
                </li>
              );
            })}
          </ul>
        }
        else={
          <p className={styles['no-filters']}>
            <Text id="Filter.noFilersApplied" />
          </p>
        }
      />

      <If
        condition={hasSelectedFilters}
        do={
          <Button
            className={cx(styles['button'], styles['button-reset'])}
            onClick={onResetFilter}
            disabled={!hasSelectedFilters}
          >
            <Text id="Filter.resetFilters" />
          </Button>
        }
      />
    </div>
  );
}
