import get from 'lodash/fp/get';

import { LoanProductType } from '@kwara/models/src';

export function getRepaymentTerms(data: { product: LoanProductType }) {
  const product: LoanProductType = get('product', data);

  if (!product) {
    return {};
  }

  const { unit: maxDurationUnit, value: maxDurationValue } = product.maxDurationUI();
  const { unit: minDurationUnit, value: minDurationValue } = product.minDurationUI();
  const fixedRepayment = product.hasFixedRepaymentFrequency();

  return {
    maxDurationUnit,
    maxDurationValue,
    minDurationUnit,
    minDurationValue,
    fixedRepayment
  };
}
