// @flow

import * as React from 'react';

import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime, Text } from '@kwara/components/src/Intl';

import { type Props } from './Overview';

export const OverviewMetadata = ({ actions, saving }: Props) => {
  const back = <BackButton />;
  const noButtons = actions == null && back == null;

  return noButtons ? null : (
    <>
      <ButtonBar className="mb4 pb3" left={[back]} right={actions} />
      <div className="mb2 grey-400">
        <StatusTag state={saving.state.current} /> &middot; <Text id="SavingDetail.updated" />{' '}
        <DateTime value={saving.updatedAt} />
      </div>
    </>
  );
};
