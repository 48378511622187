import { Review } from './components/Review';

export const steps = {
  review: {
    Component: Review,
    validate: Review.validate,
    actions: [
      { appearsAs: 'approve', behavesAs: 'approve' },
      { appearsAs: 'reject', behavesAs: 'reject' }
    ]
  }
};
