import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DeprecatedLoadable, LoadingFullScreen } from '@kwara/components/src/Loadable';
import {
  MemberType,
  SavingRemittance,
  SavingType,
  BankAccountT,
  SavingProductType,
  SavingRemittanceT
} from '@kwara/models/src';

import Wizard from '../../components/Wizard';

import { steps } from './config';
import { WizardPageProps } from '..';
import { useAuth } from '../../hooks';
import { savingPath } from '../../lib/urls';
import { SubStepComponentProps } from '../../components/Wizard';
import { useSavingAddPromises } from './useSavingAddPromises';
import { useCreateSavingAccount } from './useCreateSavingAccount';

export interface Data extends SavingType {
  member: MemberType;
  depositAmount?: string | null;
  remittance: SavingRemittanceT;
  bankAccounts: BankAccountT[];
  savingProduct?: SavingProductType | null;
}

type SavingsPropTypes = WizardPageProps<Data>;
type Params = { step: string; subStep: string };
export type SavingsSubStepProps = SubStepComponentProps<Data>;

export default function SavingAdd({ baseUrl }: SavingsPropTypes) {
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const auth = useAuth();
  const createSavingAccount = useCreateSavingAccount();
  const { memberPromise, savingProductsPromise } = useSavingAddPromises();

  return (
    <DeprecatedLoadable
      Loading={LoadingFullScreen}
      loader={memberPromise}
      loaded={(member: MemberType | null) => (
        <Wizard
          baseUrl={baseUrl}
          history={history}
          analyticsId="SavingAdd"
          titleId="SavingAdd.title"
          onSubmit={createSavingAccount}
          cancelReturnsTo={savingPath()}
          currentStep={match.params.step}
          startId={member == null ? 'member' : 'product'}
          steps={steps({ savingProductsPromise, isV1: auth.isV1() })}
          initialData={{ member, remittance: new SavingRemittance() }}
          currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
        />
      )}
    />
  );
}
