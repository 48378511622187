//@flow
import * as React from 'react';

import 'intersection-observer'; // polyfill

import Asset from '@kwara/components/src/Asset';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

// See: https://github.com/w3c/IntersectionObserver/tree/master/polyfill
if ('USE_MUTATION_OBSERVER' in IntersectionObserver.prototype) {
  // $FlowFixMe: Only exists on Polyfill to help performance
  IntersectionObserver.prototype.USE_MUTATION_OBSERVER = false;
}

type Props = {
  hasMore: boolean,
  loading: boolean,
  onNext: () => void,
  pageSize?: number
};

export default class extends React.Component<Props, *> {
  observer: ?IntersectionObserver;

  componentDidMount() {
    if (this.ref.current == null) {
      return;
    }

    this.observer = new IntersectionObserver(this.intersected, {
      threshold: 0.1
    });
    this.observer.observe(this.ref.current);
  }

  componentWillUnmount() {
    delete this.observer;
  }

  intersected = (entries: IntersectionObserverEntry[]) => {
    if (!this.props.hasMore) {
      return;
    }

    const isVisible = entries.some(({ intersectionRatio }) => intersectionRatio > 0);

    if (isVisible && !this.props.loading) {
      this.props.onNext();
    }
  };

  renderContent = () => {
    const { hasMore, loading, onNext, pageSize = 10 } = this.props;

    if (loading) {
      return (
        <div className="w2 h2">
          <Asset id={Asset.Glyphs.Spinner} col={Asset.Colours.grey300} />
        </div>
      );
    } else if (hasMore === false) {
      return null;
    } else {
      return (
        <Link disabled={loading} onClick={onNext}>
          <Text id="Pagination.loadMore" values={{ pageSize }} />
        </Link>
      );
    }
  };

  ref = React.createRef();

  render() {
    return (
      <div className="w-100 h3 flex justify-center align-center" ref={this.ref}>
        {this.renderContent()}
      </div>
    );
  }
}
