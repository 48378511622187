import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { checkable_item_ids } from '@kwara/models/src/models/CheckableItem';
import StatusTag from '@kwara/components/src/StatusTag';
import { Currency, Date, DateTime } from '@kwara/components/src/Intl';
import { SubscribedCheckbox } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';

import Table, { Heading, Row, Cell, Footer, Collapsible } from '../../../../components/Table';
import { usePermissions } from '../../../../hooks';

const SubRow = ({ credit, debit, cellStyle }) => {
  const glNameCredit = get('gl_name', credit);
  const glIdCredit = get('credit_account', credit);
  const amountCredit = get('credit_amount', credit);

  const glNameDebit = get('gl_name', debit);
  const glIdDebit = get('debit_account', debit);
  const amountDebit = get('debit_amount', debit);

  const name = glNameCredit || glNameDebit;
  const id = glIdCredit || glIdDebit;

  return (
    <Row className="bg-light-grey-300">
      <Cell style={cellStyle} />
      <Cell style={cellStyle} />
      <Cell style={cellStyle} colSpan={2}>
        <span className="grey-400">{name}</span>
      </Cell>
      <Cell style={cellStyle}>{id}</Cell>
      <Cell style={cellStyle} align="right">
        {debit ? <Currency hideDecimals={false} value={amountDebit} /> : null}
      </Cell>
      <Cell style={cellStyle} align="right">
        {credit ? <Currency hideDecimals={false} value={amountCredit} /> : null}
      </Cell>
      <Cell style={cellStyle} />
      <Cell style={cellStyle} />
    </Row>
  );
};

export const CheckableTable = ({ transactions, ...r }) => {
  const { permission, AppPermissions } = usePermissions();

  return (
    <Table
      heading={
        <Row>
          <Heading iconSpacer />
          <Heading width="80px" translationId="Finance.JournalEntries.Table.header.id" />
          <Heading translationId="Finance.JournalEntries.Table.header.valueDate" />
          <Heading translationId="Finance.JournalEntries.Table.header.createdAt" />
          <Heading translationId="Finance.JournalEntries.Table.header.glAccount" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.debit" />
          <Heading align="right" translationId="Finance.JournalEntries.Table.header.credit" />
          <Heading translationId="Finance.JournalEntries.Table.header.state" />
        </Row>
      }
      footer={<Footer colSpan={9} {...r} items={transactions} />}
    >
      {map(item => {
        const t = item.checkable;
        const showCheckbox = item.isPending() && permission.to(AppPermissions.ReviewJournalEntries);

        const { createdBy, reviewedBy } = item;

        // Using style here too override
        // the Cell component classes
        const cellStyleSubRow = {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem'
        };
        const cellStyle = { paddingTop: '0.25rem', paddingBottom: '0.25rem' };

        return (
          <Collapsible key={item.id}>
            <Row>
              <Cell>{showCheckbox ? <SubscribedCheckbox name={checkable_item_ids} value={item.id} /> : null}</Cell>
              <Cell>{t.id}</Cell>
              <Cell>
                <Date value={t.date} />
              </Cell>
              <Cell>
                <DateTime value={t.createdAt} />
              </Cell>
              <Cell />
              <Cell align="right">
                <Currency hideDecimals={false} value={t.total()} />
              </Cell>
              <Cell align="right">
                <Currency hideDecimals={false} value={t.total()} />
              </Cell>
              <Cell>
                <StatusTag state={item.state} />
              </Cell>
            </Row>
            {map(
              credit => (
                <SubRow credit={credit} cellStyle={cellStyleSubRow} key={credit.credit_account} />
              ),
              t.credits
            )}
            {map(
              debit => (
                <SubRow debit={debit} cellStyle={cellStyleSubRow} key={debit.debit_account} />
              ),
              t.debits
            )}
            {createdBy || reviewedBy ? (
              <Row className="bg-light-grey-300">
                <Cell style={cellStyle} />
                <Cell style={cellStyle} colSpan={3}>
                  {createdBy ? (
                    <Text id="Finance.JournalEntries.Table.createdBy" values={{ val: createdBy.fullName() }} />
                  ) : null}
                </Cell>
                <Cell style={cellStyle} colSpan={5}>
                  {reviewedBy ? (
                    <Text id="Finance.JournalEntries.Table.reviewedBy" values={{ val: reviewedBy.fullName() }} />
                  ) : null}
                </Cell>
              </Row>
            ) : null}
          </Collapsible>
        );
      }, transactions)}
    </Table>
  );
};
