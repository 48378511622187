import * as React from 'react';
import { useQueryClient } from 'react-query';

import { LoanSubstepProps } from 'GlobalTypes';

import Wizard from '@kwara/components/src/Wizard/latest';

import { steps } from './config';
import { onUndoTopup } from './onUndoTopup';

export interface LoanTopupUndoStepPropTypes extends Partial<LoanSubstepProps> {}

export default function LoanUndoTopup({ match }) {
  const queryClient = useQueryClient();

  const params = match.params;
  const loanId = params.loanId;

  return (
    <div>
      <Wizard
        steps={steps}
        startId="notes"
        analyticsId="LoanUndoTopup"
        titleId="LoanTopup.Undo.title"
        baseUrl={`/loans/${loanId}/undoTopup`}
        onSubmit={onUndoTopup}
        currentStep={params.step}
        completionAutoConfirm={false}
        initialData={{ loanId }}
        onSubmitCompletion={() => queryClient.removeQueries('loan')}
        currentSubStep={params.subStep != undefined ? parseInt(params.subStep, 10) : null}
      />
    </div>
  );
}
