import * as React from 'react';
import get from 'lodash/fp/get';

import { type LoanType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic, PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatusTag from '@kwara/components/src/StatusTag';
import { Card } from '@kwara/components/src/Card';

import LoanGuarantors from './LoanGuarantors';
import { Currency } from '../../../components/Currency';

type Props = {
  account: LoanType,
  actionTo?: string,
  isSelected?: boolean
};

export default ({ account, actionTo, isSelected }: Props) => {
  const fees = get('fees.balance', account);

  return (
    <Card
      header={account.product?.name || account?.name || 'Unnamed'}
      metadata={
        <>
          <StatusTag state={account.state.current} /> &middot; {account.id}
        </>
      }
      actionLabelId="LoanAccountCard.action"
      isHoverable
      actionTo={actionTo}
      highlighted={isSelected}
    >
      <Statistic
        compact
        title={<Text id="LoanAccountCard.amount" />}
        size="small"
        value={<Currency value={account.amount} />}
      />

      <Statistic
        compact
        title={<Text id="LoanAccountCard.totalBalance" />}
        size="small"
        border={true}
        value={<Currency value={account.totalBalance} />}
      />

      <PenaltyStatistic
        compact
        title={<Text id="LoanAccountCard.fees" />}
        size="small"
        border={true}
        errorValue={fees}
      />
      <Statistic
        compact
        title={<Text id="LoanAccountCard.guarantors" />}
        size="small"
        border={true}
        value={<LoanGuarantors loan={account} />}
      />
    </Card>
  );
};
