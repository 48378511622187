import React, { useMemo } from 'react';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { Form as ReactFinalForm } from 'react-final-form';

import Button from '@kwara/components/src/Button';

import { MemberStates } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { SubscribedCombobox } from '@kwara/components/src/Form';

export type Payload = { filters: { state?: string | null } };

type FormPropTypes = { onGenerate(data: Payload): void };

export function Form({ onGenerate }: FormPropTypes) {
  const { formatMessage } = useIntl();
  const states = useMemo<Array<{ label: string; value: string }>>(
    () => [
      {
        label: formatMessage({ id: 'MemberStatus.ALL' }),
        value: ' '
      },
      ...map(MemberStates, (value: string) => ({
        label: formatMessage({ id: `MemberStatus.${value}` }),
        value
      }))
    ],
    [formatMessage]
  );

  return (
    <ReactFinalForm
      onSubmit={(data: Payload, form) => {
        onGenerate(data);
        form.reset();
      }}
      render={({ values, form, handleSubmit, valid, submitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="w-100 flex justify-left items-center"
            aria-label="Create Member Listing Report Form"
          >
            <div className="relative dib mr3">
              <SubscribedCombobox
                compact
                margin={false}
                size="medium"
                placeholderId="MemberListingReports.Form.SelectStatus"
                name="filters.state"
                data={states}
              />
            </div>

            <Button
              disabled={!valid || submitting || !values.filters?.state?.length}
              type="primary"
              onClick={form.submit}
            >
              <Text id="MemberListingReports.Form.submit.generate" />
            </Button>
          </form>
        );
      }}
    />
  );
}
