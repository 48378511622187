import * as React from 'react';
import { Text, TranslationId } from '@kwara/components/src/Intl';

type RecordProps = {
  hidden?: boolean;
  labelId?: TranslationId;
  value?: React.ReactNode;
  valueId?: TranslationId;
  values?: { [id: string]: any };
  colorClassName?: 'neutral-700' | 'primary-500';
};

export function Record({
  hidden = false,
  labelId,
  value,
  valueId,
  values,
  colorClassName = 'neutral-700'
}: RecordProps) {
  if (hidden) {
    return null;
  }

  const label = labelId ? <Text id={labelId} values={values} /> : null;
  const join = label ? ': ' : null;
  const data = valueId != null ? <Text id={valueId} values={values} /> : value;

  if (data == null) {
    return null;
  }

  return (
    <li className="ma0 mb1">
      {label}
      {join}
      <span className={colorClassName}>{data}</span>
    </li>
  );
}
