// @flow

import React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { appName } from '@kwara/lib/src/utils';
import { fields as remittanceFields } from '@kwara/models/src/models/Remittance';
import { SubscribedPaymentSelectField, CollectingBank } from '@kwara/components/src/Form';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';

import { SubscribedFrequencySelectFields, SubscribedDatePicker } from '../../../../../components/Form';
import { type LoanSubstepProps, type LoanFormData } from '../..';

const fields = remittanceFields.remittance;

type Props = LoanSubstepProps;

// Payroll Deduction fields are only shown
// during LoanAdd > Repayment step, not
// in the Repayment Editable Sections.
// We likely won't need this once ch16865
// is  actioned.
//
function showPayrollFields(fieldName, config) {
  return !!get(fieldName, config.validate);
}

const showBankAccountSelectionFor = [TransactionChannels.directDebit, TransactionChannels.bankTransfer];

export function Repayment({ StackChild, Condition, TextField, addData, config, data }: Props) {
  const { member } = data;
  const transactionContext = appName.isSacco ? contexts.LoanRemittance : contexts.LoanRemittance_Connect;

  return (
    <StackChild>
      {appName.isSacco ? (
        <TextField name={fields.amount} labelId="LoanAdd.Repayment.Amount.label" isCurrency type="number" />
      ) : null}

      <SubscribedPaymentSelectField
        required
        addData={addData}
        name={fields.mode}
        labelId="LoanAdd.Configure.RepaymentMode.label"
        config={{
          bank: fields.bank,
          bankLabelId: 'LoanAdd.Configure.DirectDebitBank.label',
          bankBranch: fields.branch,
          accountNumber: fields.account,
          memberBankAccountId: fields.bankAccount,
          methods: getTransactionTypes(transactionContext).values,
          showBankAccountSelectionFor,
          showBanksFieldsFor: [],
          memberId: member.id
        }}
      />

      <Condition when={fields.mode} is={TransactionChannels.directDebit}>
        {appName.isSacco ? <CollectingBank fields={fields} /> : null}
        <SubscribedDatePicker required name={fields.startDate} labelId="LoanAdd.Repayment.RemittanceStartDate.label" />
      </Condition>

      <Condition when={fields.mode} is={TransactionChannels.payrollDeduction}>
        {showPayrollFields('member.employer', config) ? (
          <TextField name="member.employer" labelId="LoanAdd.Configure.RepaymentMode.Employer.title" required />
        ) : null}
        {showPayrollFields('member.staffId', config) ? (
          <TextField name="member.staffId" labelId="LoanAdd.Configure.RepaymentMode.StaffId.title" required />
        ) : null}
      </Condition>

      <div className="pt4">
        <SubscribedFrequencySelectFields addData={addData} frequency={fields.frequency} day={fields.collectionDate} />
      </div>
    </StackChild>
  );
}

const requiredForBankFields = {
  isRequired: (_, allData: LoanFormData): boolean => includes(get(fields.mode, allData), showBankAccountSelectionFor)
};

const requiredForDirectDebitSacco = {
  isRequired: (_, allData: LoanFormData): boolean =>
    appName.isSacco && get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForDirectDebit = {
  isRequired: (_, allData: LoanFormData): boolean => get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForPayrollDeduction = {
  isRequired: (_, allData: LoanFormData): boolean => get(fields.mode, allData) === TransactionChannels.payrollDeduction
};

const base = {
  [fields.mode]: { isRequired: () => true },
  [fields.amount]: {
    currency: true
  },
  'remittance.bankAccount.id': requiredForBankFields,
  [fields.collectingBank]: requiredForDirectDebitSacco,
  [fields.startDate]: requiredForDirectDebit,
  ...SubscribedFrequencySelectFields.validate(fields.frequency, fields.collectionDate)
};

const loanAdd = {
  ...base,
  'member.employer': requiredForPayrollDeduction,
  'member.staffId': requiredForPayrollDeduction
};

Repayment.validateConfig = {
  loanAdd,
  loanEdit: base
};
