import * as React from 'react';

import { JournalEntryT } from '@kwara/models/src';

import { JournalEntriesList } from './components/List';

type Props = {
  transactions: JournalEntryT[];
  filterValue: string;
  totalResults: number;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage(): void;
  where?: { from: string; to: string; gl_code?: string };
};

export const JournalEntries = function JournalEntries({
  isFetchingNextPage,
  isLoading,
  hasNextPage,
  fetchNextPage,
  ...props
}: Props) {
  return (
    <JournalEntriesList
      isLoading={isFetchingNextPage || isLoading}
      hasMore={hasNextPage}
      onNext={fetchNextPage}
      {...props}
    />
  );
};
