import React, { useState, useMemo } from 'react';

import { createContext } from '@kwara/lib/src/utils/createContext';
import { SavingsTransactionType, LoanTransactionType } from '@kwara/models/src';

import { AdjustmentButtons } from './AdjustmentButtons';
import { AdjustmentModals } from './AdjustmentModals';
import { Permission, AppPermissions } from '../../models/Permission';
import { usePermissions } from '../../hooks';

export type AdjustmentTransactionType = SavingsTransactionType | LoanTransactionType;

type AdjustTransactionsPermission =
  | typeof AppPermissions.AdjustLoanTransactions
  | typeof AppPermissions.AdjustSavingsTransactions;
type TransactionAdjustmentContextType = {
  showView: boolean;
  showAdjust: boolean;
  permission: Permission;
  currentTransaction: AdjustmentTransactionType | null;
  setShowAdjust: React.Dispatch<React.SetStateAction<boolean>>;
  setShowView: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTransaction: React.Dispatch<React.SetStateAction<AdjustmentTransactionType | null>>;
  adjustTransactionsPermission: AdjustTransactionsPermission;
};

const [TransactionAdjustmentProvider, useTransactionAdjustment] = createContext<TransactionAdjustmentContextType>(
  'TransactionAdjustmentContext'
);

type TransactionAdjustmentPropTypes = {
  children: React.ReactNode;
  adjustTransactionsPermission: AdjustTransactionsPermission;
};

function TransactionAdjustment({ children, adjustTransactionsPermission }: TransactionAdjustmentPropTypes) {
  const { permission } = usePermissions();
  const [showView, setShowView] = useState(false);
  const [showAdjust, setShowAdjust] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState<AdjustmentTransactionType>(null);

  const values = useMemo(
    () => ({
      permission,
      showAdjust,
      setShowAdjust,
      showView,
      setShowView,
      currentTransaction,
      setCurrentTransaction,
      adjustTransactionsPermission
    }),
    [currentTransaction, permission, showAdjust, showView, adjustTransactionsPermission]
  );

  return <TransactionAdjustmentProvider value={values}>{children}</TransactionAdjustmentProvider>;
}

export { useTransactionAdjustment };

export default {
  Root: TransactionAdjustment,
  AdjustmentButtons,
  AdjustmentModals
};
