import * as React from 'react';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { Date } from '@kwara/components/src/Intl';
import Actionable from '@kwara/components/src/Actionable';

import Table, { Row, Heading, Cell, Footer } from '../../../../../components/Table';

const buttonStyle = 'link bw0 underline-hover pointer indigo-500 bg-white';

export const ReportsTable = ({ reports, ...rest }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="Finance.Sasra.Table.header.id" />
          <Heading translationId="Finance.Sasra.Table.header.financialYear" />
          <Heading translationId="Finance.Sasra.Table.header.startDate" />
          <Heading translationId="Finance.Sasra.Table.header.endDate" />
          <Heading translationId="Finance.Sasra.Table.header.actions" />
          <Heading translationId="Finance.Sasra.Table.header.status" />
        </Row>
      }
      footer={<Footer items={reports} colSpan={6} {...rest} />}
    >
      {map(report => {
        return (
          <Row key={report.id}>
            <Cell>{report.id}</Cell>
            <Cell>{report.financialYear}</Cell>
            <Cell>
              <Date value={report.startDate} />
            </Cell>
            <Cell>
              <Date value={report.endDate} />
            </Cell>
            <Cell>
              {report.isGenerated() ? (
                <Actionable className={buttonStyle} onClick={() => report.generate()}>
                  <Text id="FileType.XLSX" />
                </Actionable>
              ) : null}
            </Cell>
            <Cell>
              <StatusTag state={report.state} />
            </Cell>
          </Row>
        );
      }, reports)}
    </Table>
  );
};
