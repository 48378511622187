// @flow

import * as React from 'react';
import cx from 'classnames';

import { LoadableStatistic } from '@kwara/components/src/Statistic/LoadableStatistic';
import { Currency, Text } from '@kwara/components/src/Intl';
import { type RequestProps } from '@kwara/components/src';
import { type LoanProductStandingT } from '@kwara/models/src';

import styles from './GuarantorBreakdownPanel.module.scss';

export const GuaranteeBreakdownPanel = ({
  loanProductStandingR
}: {
  loanProductStandingR: RequestProps<LoanProductStandingT>
}) => (
  <div className={cx('flex flex-column pa2', styles.Panel)}>
    <LoadableStatistic
      compact
      containerClassName="bb b--light-gray"
      r={loanProductStandingR}
      title={<Text id="LoanAdd.Security.Guarantor.BreakdownPanel.mainAccount" />}
      value={standing => <Currency format="currency" value={standing.deposits} />}
      size="small-design-system-bold"
    />
    <LoadableStatistic
      compact
      containerClassName="bb b--light-gray"
      r={loanProductStandingR}
      title={<Text id="LoanAdd.Security.Guarantor.BreakdownPanel.factor" values={{ val: <span>&#215;</span> }} />}
      value={standing => <span>&#215;{standing.factor}</span>}
      size="small-design-system-bold"
    />
    <LoadableStatistic
      compact
      r={loanProductStandingR}
      color="red-600"
      title={
        <Text
          id="LoanAdd.Security.Guarantor.BreakdownPanel.alreadyGuaranteed"
          values={{ val: <span>&#x2212;</span> }}
        />
      }
      value={standing => (
        <span>
          &#x2212;
          <Currency format="currency" value={standing.alreadyGuaranteedAmount} />
        </span>
      )}
      size="small-design-system-bold"
    />
  </div>
);
