import * as React from 'react';
import cx from 'classnames';

import { zIndices } from '@kwara/lib/src/zIndices';
import { Loadable } from '@kwara/components/src/Loadable';
import { Overlay } from '@kwara/components/src/Overlay/Overlay';
import { createContext } from '@kwara/lib/src/utils/createContext';
import { ModalWrapper } from '@kwara/components/src/ModalWrapper/ModalWrapper';
import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';

import { History } from './History';
import { Statement } from './Statement';
import { Card } from '../../../../components/Card';
import { SavingsHistoryT } from '../../../../models';
import { useGetSavingsHistory } from './useGetSavingsHistory';

import styles from './index.module.scss';

function Loader() {
  return (
    <Card className={styles['loader']}>
      {[...new Array(6)].map((_, i) => (
        <LoadingSkeleton key={i.toString()} shape="radius-horizone" width="100%" height="30px" noChildren />
      ))}
    </Card>
  );
}

export type Current = 'last_six_months' | 'last_twelve_months';

type SavingsContextType = {
  data: SavingsHistoryT | null;
  current: Current;
  send(current: Current): void;
};

const [SavingsProvider, useSavings] = createContext<SavingsContextType>('SavingsHistoryStatementContext');

type SavingsPropTypes = {
  savingsId: string;
  isOpen: boolean;
  onClose(): void;
  children: React.ReactElement[];
};

function Savings({ savingsId, isOpen, onClose, children }: SavingsPropTypes) {
  const [current, setCurrent] = React.useState<Current>('last_six_months');
  const { isLoading, isError, error, data } = useGetSavingsHistory(savingsId);

  function send(curr: Current) {
    setCurrent(curr);
  }

  const values = React.useMemo(() => ({ data, current, send }), [current, data]);

  return (
    <SavingsProvider value={values}>
      <Overlay>
        <ModalWrapper
          isOpen={isOpen}
          closeOnOutClick={!isLoading}
          elementType="savingshistorystatement"
          aria-label="Saving History and Statement"
          meta="Tap outside to close the view"
          className={cx(zIndices.Modal, styles['savings-container'])}
          onClose={onClose}
        >
          <Loadable isLoading={isLoading} isError={isError} error={error} loading={<Loader />}>
            {children}
          </Loadable>
        </ModalWrapper>
      </Overlay>
    </SavingsProvider>
  );
}

export { useSavings };

export default {
  Root: Savings,
  History,
  Statement
};
