import Invitation from '@kwara/models/src/models/Invitation';
import { UserTypes } from '@kwara/models/src';

import config from '../config';
import IdVerification from './IdVerification/IdVerification';

export type { ConfigType } from '@kwara/lib/src/config';
export type { IDCheckResponse, CheckPayload } from './IdVerification/IdVerification';

export const invitation = new Invitation({ config, userType: UserTypes.USER });
export const idVerification = new IdVerification({ config });

export { DividendReports } from './DividendReports/DividendReports';
export type { DividendReportsT, DividendCalculationMethodT } from './DividendReports/DividendReports';

export { DividendContributionSummary, DividendContributionSummaries } from './DividendContributionSummary/DividendContributionSummary';
export type { DividendContributionSummaryT } from './DividendContributionSummary/DividendContributionSummary';

export { SavingsHistory } from './SavingsHistory';
export type { SavingsStatement, SavingsCalculations, SavingsHistoryT, SavingsHistoryData } from './SavingsHistory';