import * as React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import { If } from '@kwara/components/src/If/If';
import { isDev, isSandbox } from '@kwara/lib/src/utils';

import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';

import styles from './index.module.scss';

/**********************************************************
 * @deriveEnvironmentalData this is an initial time branch
 * IIFE that derives environmental data which is needed at
 * the initial time of visiting the app
 *********************************************************/
export const envs: { name: string; color: string } = (function deriveEnvironmentalData() {
  if (isSandbox) {
    return { name: 'Sandbox', color: 'bg-info-100 info-700' };
  }

  if (isDev) {
    return { name: 'Development', color: 'bg-teal-100 teal-700' };
  }

  return { name: '', color: '' };
})();

type EnvironmentBannerPropTypes = {
  children: React.ReactNode;
};

export const EnvironmentBanner = observer(({ children }: EnvironmentBannerPropTypes) => {
  const store = useSaccoProfileContext();
  const { profile = {} } = store || {};

  return (
    <>
      <If
        condition={!!envs.name}
        do={
          <div className={cx(envs.color, styles.Banner, zIndices.Banner)}>
            {envs.name}
            <If condition={profile.apiInstance != undefined} do={`- ${profile.apiInstance}`} />
          </div>
        }
      />
      {children}
    </>
  );
});
