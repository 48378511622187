import get from 'lodash/fp/get';
import { LoanDisbursementType, DisbursementStates } from '@kwara/models/src/models/LoanDisbursement';
import { useHasAlreadyDisbursed } from './components/Approvals/useHasAlreadyDisbursed';

export const useCanSubmit = (disbursement: LoanDisbursementType | undefined) => {
  const hasAlreadyDisbursed = useHasAlreadyDisbursed(disbursement);

  if (!disbursement) {
    return true;
  }

  if (hasAlreadyDisbursed) {
    return false;
  }

  const disbursementState = get('state', disbursement);
  return disbursementState === DisbursementStates.PENDING_INITIAL_APPROVALS;
};
