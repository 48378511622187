import * as React from 'react';
import map from 'lodash/fp/map';

import Empty from '@kwara/components/src/Empty';
import { Text, Date, Time } from '@kwara/components/src/Intl';
import { SpinningLoading } from '@kwara/components/src/Loadable';

import Table, { Cell, Heading, Row } from '../../../components/Table';
import { Currency } from '../../../components/Currency';
import styles from './index.module.scss';

export const MemberTransactionsTable = ({ transactions, ...r }) => {
  return (
    <div className={styles.scrollable}>
      <Table
        heading={
          <Row>
            <Heading sticky={true} translationId="TransactionsTable.id" />
            <Heading sticky={true} translationId="TransactionsTable.date" />
            <Heading sticky={true} className="w-25" translationId="TransactionsTable.type" />
            <Heading sticky={true} align="right" translationId="TransactionsTable.amount" />
            <Heading sticky={true} align="right" translationId="TransactionsTable.balance" />
            <Heading sticky={true} iconSpacer />
          </Row>
        }
        emptyState={
          <Row>
            <Cell colSpan={6}>
              <Empty>
                <Text id="TransactionsTable.empty" />
              </Empty>
            </Cell>
          </Row>
        }
      >
        {r.isLoading ? (
          <Row>
            <Cell colSpan={6}>
              <SpinningLoading />
            </Cell>
          </Row>
        ) : (
          map(transaction => {
            return (
              <Row key={transaction.id} className="bg-light-grey-300">
                <Cell style={{ position: 'static' }}>
                  <div className="grey-300">{transaction.id}</div>
                </Cell>
                <Cell style={{ position: 'static' }}>
                  <div>
                    <Date value={transaction.createdAt} />
                  </div>
                  <div>
                    <Time value={transaction.createdAt} />
                  </div>
                </Cell>
                <Cell style={{ position: 'static' }}>
                  <Text id={`TransactionType.${transaction.type}`} />
                </Cell>
                <Cell align="right" style={{ position: 'static' }}>
                  <Currency value={transaction.amount} />
                </Cell>
                <Cell align="right" style={{ position: 'static' }}>
                  <Currency value={transaction.balance} />
                </Cell>
                <Cell />
              </Row>
            );
          }, transactions)
        )}
      </Table>
    </div>
  );
};
