import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { GenericPolymorphic } from '@kwara/components/src/GenericPolymorphic';

import { Heading } from '../../components/Heading';

type ContainerPropTypes = {
  className?: string;
  children: React.ReactNode;
  as?: string;
};

function Container({ as = 'div', className, children }: ContainerPropTypes) {
  return (
    <GenericPolymorphic as={as} className={cx('mt3 mb4', className)}>
      {children}
    </GenericPolymorphic>
  );
}

/******************************************************************************************************
 * @default This is loosely coupled and highly reusable, you don't need to use all part together e.g
 *
 * ```ts
 *   <Container.Root>
 *      <Container.Heading id="I18n.heading" />
 *      <Container.Body>
 *       Hello world
 *      </Container.Body>
 *   </Container.Root>
 *
 *         OR
 *
 *   <Container.Root>
 *      <Container.Heading id="I18n.heading" />
 *   </Container.Root>
 *
 *        OR
 *   <Container.Root>
 *      <Container.Body>
 *        Hello world
 *      </Container.Body>
 *   </Container.Root>
 * ```
 *
 * @reference : this is heavily used in the ```New Loan flows``` for coupling ```sub-sections```
 */
export default {
  Root: Container,
  Heading: (props: { id: string }) => (
    <Heading className="mb3 mt3">
      <Text id={props.id} />
    </Heading>
  ),
  Body: (props: { children: React.ReactNode }) => <>{props.children}</>
};
