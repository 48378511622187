//@flow

import React from 'react';

import forEach from 'lodash/fp/forEach';

import { Loan, LoanStates, type LoanType } from '@kwara/models/src';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';

import { DataViewWrapper, type ContentProps } from '../../../../components/DataViewWrapper';

import styles from '../../index.module.scss';
import SubmitList from '../SubmitList';

type FormData = {
  [id: string]: boolean
};

const Content = ({
  hasMore,
  loading,
  errors,
  // filterBy,
  data,
  pristine,
  onLoadMoreData,
  changeSearchValue,
  totalNumberResults,
  // onFilterChange,
  searchValue,
  addData
}: ContentProps<FormData, LoanType>) => {
  const [toggleAll, setToggleAll] = React.useState(false);

  const toggleAllCheckboxes = () => {
    const toggled = !toggleAll;
    forEach(r => {
      addData({ [r.id]: toggled });
    }, data);
    setToggleAll(toggled);
  };

  return (
    <div className={styles.CreditSubmit}>
      <DeprecatedLoadable
        loader={pristine ? new Promise(() => {}) : Promise.resolve(data)}
        loaded={loans => (
          <SubmitList
            loading={loading}
            loans={loans}
            totalNumResults={totalNumberResults}
            hasMore={hasMore}
            // filterBy={filterBy}
            // onFilterChange={onFilterChange}
            searchValue={searchValue}
            disabledSearch={true}
            onSearchChange={changeSearchValue}
            onLoadMoreData={onLoadMoreData}
            errors={errors}
            isSelectAllCheckboxActive={toggleAll}
            toggleAllCheckboxes={toggleAllCheckboxes}
          />
        )}
      />
    </div>
  );
};

export const Submit = ({ addData }: { addData: (updated: Data) => void }) => {
  return (
    <DataViewWrapper
      addData={addData}
      component={Content}
      scope={Loan.includes(['member', 'product']).where({
        state: LoanStates.ACTIVE_IN_ARREARS
      })}
      pathname="/submit"
      enableSearch
    />
  );
};
