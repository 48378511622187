import { AccountingReportT, ReportTemplateT } from '@kwara/models/src';

import { useWaitingCursor } from '../../hooks/useWaitingCursor';

export function useSasraDownload() {
  const { onWaitingStart, onWaitingEnd, isWaiting } = useWaitingCursor();

  function onGenerate(template: ReportTemplateT, report: AccountingReportT) {
    onWaitingStart();
    return template.generate(report).finally(() => {
      onWaitingEnd();
    });
  }

  return { onGenerate, isSasraDownloading: isWaiting };
}
