import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { LoanSubstepProps } from 'GlobalTypes';
import { LoanAppraisalLocationParams } from 'LoanAppraisalTypes';

import Wizard from '@kwara/components/src/Wizard/latest';

import { Loadable } from '@kwara/components/src/Loadable';
import { CollateralT } from '@kwara/models/src/models/Collateral';
import { GuaranteeType } from '@kwara/models/src/models/Guarantee';
import { PeriodUnitsT } from '@kwara/models/src/models/LoanProduct';
import { useFullLoan } from '@kwara/models/src/models/request/hooks';
import { LoanType, DEFAULT_REPAYMENT_PERIOD, DEFAULT_REPAYMENT_PERIOD_UNIT } from '@kwara/models/src/models/Loan';

import { steps } from './config';
import { loanPath } from '../../lib/urls';
import { useSubmit } from './useSubmit';

type LoanTopupLocationParams = LoanAppraisalLocationParams;

export interface LoanTopupFormTypes extends Omit<LoanSubstepProps, 'loans' | 'member'>, Record<string, any> {
  desiredInterestBalance: string;
  desiredFeesBalance: string;
  desiredPenaltiesBalance: string;
  repaymentPeriod: number;
  repaymentPeriodUnit: PeriodUnitsT;
  keepSameAccount: boolean;
  guarantors?: GuaranteeType[];
  collaterals?: CollateralT[];
  securities: (CollateralT & GuaranteeType)[];
}

export default function LoanTopup() {
  const queryClient = useQueryClient();
  const params = useParams<LoanTopupLocationParams>();
  const loanR = useFullLoan(params.loanId);
  const { id, onSubmit } = useSubmit();

  return (
    <Loadable {...loanR}>
      {(loan: LoanType) => (
        <Wizard
          type="topup"
          steps={steps}
          startId="accountAndMemberDetails"
          analyticsId="LoanTopup"
          titleId="LoanTopup.title"
          baseUrl={`/loans/${params.loanId}/topup`}
          onSubmit={onSubmit}
          currentStep={params.step}
          cancelReturnsTo={loanPath({ id: id ?? params.loanId })}
          completionAutoConfirm={false}
          currentSubStep={params.subStep != undefined ? parseInt(params.subStep, 10) : null}
          onSubmitCompletion={() => queryClient.removeQueries('loan')}
          initialData={{
            loan,
            fullName: loan.member.fullName(),
            product: loan.product,
            collaterals: loan.collaterals,
            guarantors: loan.guarantors,
            securities: [],
            remittance: loan.remittance,
            desiredInterestBalance: loan.interest.balance,
            desiredFeesBalance: loan.fees.balance,
            desiredPenaltiesBalance: loan.penalties.balance,
            repaymentPeriod: loan.repaymentPeriod ?? DEFAULT_REPAYMENT_PERIOD,
            repaymentPeriodUnit: loan.repaymentPeriodUnit ?? DEFAULT_REPAYMENT_PERIOD_UNIT,
            loanDuration: loan.loanDuration,
            keepSameAccount: true
          }}
        />
      )}
    </Loadable>
  );
}
