//@flow
import * as React from 'react';
import classnames from 'classnames';

import { ActionButton, Attribute } from '@kwara/components/src';
import { type MemberType } from '@kwara/models/src';

import Column from './Column';

import styles from './index.module.scss';

type Props = {
  highlighted: boolean,
  member: MemberType
};

export default function NonFinancialResult({ member, highlighted = false, ...props }: Props) {
  return (
    <div
      data-testid="SearchResult"
      className={classnames(styles.Result, 'flex', highlighted ? styles.isHighlighted : null)}
      {...props}
    >
      <Column border={false}>
        <div className={classnames(styles.NonFinancialResultName, 'mr4')}>
          <Attribute labelSize="small" value={member.fullName()} valueClassName="truncate" />
        </div>
      </Column>
      <Column border={false}>
        <div className={classnames(styles.NonFinancialResultNumber, 'mr4')}>
          <Attribute labelSize="small" value={member.phoneNumber} valueClassName="truncate" />
        </div>
      </Column>
      <Column border={false}>
        <div className={classnames(styles.NonFinancialResultID, 'grey-400')}>
          <Attribute labelSize="small" value={member.id} valueClassName="truncate" />
        </div>
      </Column>
      <Column border={false} right>
        <ActionButton />
      </Column>
    </div>
  );
}
