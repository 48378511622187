//@flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { Member, type LoanType } from '@kwara/models/src';
import { Logger } from '@kwara/lib/src/logger';
import { ClientCache } from '@kwara/lib/src/clientCache';
import { EMPTY } from '@kwara/models/src/models/request';

import SearchManager from '../SearchManager';
import DataView from '../../lib/DataView';

type SupportedDataType = LoanType;

export type ContentProps<FormData, Data> = {
  hasMore: boolean,
  loading: boolean,
  pristine: boolean,
  error: Object,
  searchValue: string,
  data: Data[],
  onLoadMoreData: () => void,
  changeSearchValue: (term: string) => void,
  onFilterChange: (filterBy: string) => void,
  totalNumberResults: number,
  addData: (updated: FormData) => void,
  resetData: () => void
};
type Props = {
  component: (props: ContentProps<any, SupportedDataType>) => React.Node,
  currentPathname?: string,
  enableSearch?: boolean,
  scope: any, // TO DO: Fix this type
  addData?: (updated: any) => void
};

type State = {
  filterBy: ?LoanState,
  loading: boolean,
  pristine: boolean,
  error: Object,
  searchValue: string,
  data: Object[]
};

const initialState = {
  filterBy: null,
  loading: false,
  pristine: true,
  error: EMPTY,
  searchValue: '',
  data: []
};

export class DataViewWrapper extends React.Component<Props, State> {
  dataView: DataView;

  constructor(props: Props) {
    super(props);

    this.state = initialState;

    this.dataView = new DataView({ Model: props.scope });

    this.dataView.on('change', this.newData);

    // ClientCache is only configured for members at the moment
    if (props.scope.model === Member) {
      this.dataView.on('responseReceived', evt => ClientCache.bulkAdd('members', evt.data));
    }
  }

  componentDidMount() {
    const { enableSearch, scope } = this.props;

    if (enableSearch) {
      this.searchManager = new SearchManager(scope.model);
      this.searchManager.results(this.searchResults);
      this.searchManager.stateChange(this.searchStateChange);
    }

    this.dataView.next();
  }

  newData = ({ loading, error }) => {
    this.setState({
      data: this.dataView.data,
      loading,
      error,
      pristine: false
    });
  };

  loadMoreData = () => {
    this.dataView.next();
  };

  changeFilter = filterBy => {
    const hasEnteredSearchTerm = !!this.state.searchValue;

    const updated = () => {
      this.dataView.state = filterBy === '' ? null : filterBy;

      if (hasEnteredSearchTerm) {
        this.performSearch();
      } else {
        this.dataView.next();
      }
    };

    this.setState(
      {
        filterBy
      },
      updated
    );
  };

  componentWillUnmount() {
    this.dataView.destroy();
    if (this.searchManager) {
      this.searchManager.destroy();
    }
  }

  changeSearchValue = (term: string) => {
    const updated = () => {
      term === '' ? this.dataView.next() : this.performSearch();
    };

    this.setState(
      {
        searchValue: term
      },
      updated
    );
  };

  performSearch = () => {
    const { searchValue, filterBy } = this.state;

    Logger.log(`performSearch: ${filterBy}`);

    const states = filterBy === '' ? undefined : [filterBy];
    this.searchManager.search({ term: searchValue, states });
  };

  searchStateChange = ({ isSearchPending }: { isSearchPending: boolean }) => {
    this.setState({
      loading: isSearchPending
    });
  };

  searchResults = data => {
    this.setState({
      data,
      pristine: false
    });
  };

  reset = () => {
    this.setState(initialState);

    this.dataView.state = null;
    this.dataView.reset();
    this.loadMoreData();
  };

  componentDidUpdate({ location }: Props) {
    const { pathname } = this.props;
    const didNavigate = location !== this.props.location;
    const pageInForeground = get('location.pathname', this.props) === pathname;

    // This page is rendered underneath the Add modal
    // so this checks this page is now the active one
    if (didNavigate && pageInForeground) {
      this.reset();
    }
  }

  render() {
    const { component: Component, enableSearch, addData } = this.props;

    const { loading, error, pristine, filterBy, searchValue, data } = this.state;

    const hasEnteredSearchTerm = !!searchValue;

    return (
      <Component
        addData={addData}
        data={data}
        errors={error.messages}
        resetData={this.reset}
        filterBy={filterBy}
        hasMore={hasEnteredSearchTerm ? false : this.dataView.hasMore}
        loading={loading}
        pristine={pristine}
        searchValue={searchValue}
        changeSearchValue={enableSearch ? this.changeSearchValue : null}
        onFilterChange={this.changeFilter}
        onLoadMoreData={this.loadMoreData}
        totalNumberResults={this.dataView.totalResults}
      />
    );
  }
}
