import * as React from 'react';
import cx from 'classnames';

import Button from '@kwara/components/src/Button';

import styles from './index.module.scss';

export function FilterButton({
  disabled,
  children,
  onClick
}: {
  disabled?: boolean;
  onClick(): void;
  children: React.ReactNode;
}) {
  return (
    <Button disabled={disabled} className={cx(styles['button'], styles['button--add'])} onClick={onClick}>
      {children}
    </Button>
  );
}
