// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import isEqual from 'lodash/fp/isEqual';
import some from 'lodash/fp/some';
import cx from 'classnames';

import { SpinningLoading } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import { type MemberType, type IncludesT } from '@kwara/models/src';
import { Link } from '@kwara/components/src/Link';

import { Child as StackChild } from '../../../../../../../components/Stack';
import MemberSearch from '../../../../../../../components/MemberSearch';

import styles from '../index.module.scss';

type Props = {
  onSelect: (member: MemberType) => Promise<void>,
  onCancel: () => void,
  data: any,
  size?: 'regular' | 'medium' | 'widest',
  onFetchStart?: Function,
  onFetchEnd?: Function,
  includes: IncludesT
};

function alreadyAdded(data, chosenMember) {
  const sameId = pipe(get('member.id'), isEqual(chosenMember.id));
  return pipe(get('guarantors'), some(sameId))(data);
}

export const Loader = ({ isLoading }: { isLoading: boolean }) =>
  isLoading ? (
    <div className={cx(styles.loading)}>
      <SpinningLoading />
    </div>
  ) : null;

export const GuarantorSearch = ({
  onCancel,
  onSelect,
  data = {},
  size = 'widest',
  onFetchStart,
  onFetchEnd,
  includes
}: Props) => {
  return (
    <>
      <StackChild>
        <div className="pb2 flex justify-between items-center">
          <p className="ma0 kw-text-regular grey-400">
            <Text id="LoanAdd.Security.Guarantor.searchTitle" />
          </p>
          <Link className="mla" type="secondary" onClick={onCancel}>
            <Text id="LoanAdd.Security.Guarantor.cancel" />
          </Link>
        </div>
      </StackChild>
      <StackChild size={size}>
        <MemberSearch
          resultType="nonFinancial"
          onSelect={onSelect}
          data-cy="guarantor-search"
          isItemDisabled={member => alreadyAdded(data, member)}
          returnFullMember
          onFetchStart={onFetchStart}
          onFetchEnd={onFetchEnd}
          selectedAttributes={['id', 'first_name', 'middle_name', 'last_name', 'total_self_guaranteed']}
          includes={includes}
        />
      </StackChild>
    </>
  );
};
