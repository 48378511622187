import React from 'react';

import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text, TranslationId, TranslationValues } from '@kwara/components/src/Intl';

import Visible from '../../Visible';

import styles from './ActionableHeading.module.scss';

type HeadingPropTypes = {
  translationId?: TranslationId;
  heading?: React.ReactNode;
  values?: TranslationValues;
};

/**
 * @Heading function is a React component that renders an `<h1>` element with a text content that
 * can be either a translation ID or a heading string.
 * @param {HeadingPropTypes}  - - `translationId`: A string representing the translation ID for the
 * heading. This is used to retrieve the translated text for the heading.
 * @returns a JSX element, specifically an `<h1>` element with the class name "kw-text-extra-large
 * kw-weight-bold mb0". Inside the `<h1>` element, there is a conditional rendering using the `<If>`
 * component. If the `translationId` prop is truthy, it renders a `<Text>` component with the
 * `translationId` and other props passed to it
 */
export function Heading({ translationId, heading, ...props }: HeadingPropTypes) {
  return (
    <h1 className="kw-text-extra-large kw-weight-bold mb0">
      <If condition={!!translationId} do={<Text id={translationId} {...props} />} else={heading} />
    </h1>
  );
}

///
type ActionableHeadingPropTypes = {
  headerId?: string;
  headerValues?: Record<string, any>;
  heading?: React.ReactNode;
  actions: (React.ReactElement<typeof Button> | React.ReactElement<typeof Visible>)[] | React.ReactElement;
  subheading?: React.ReactNode;
  backButton?: React.ReactElement<typeof Button>;
};

/**
 * @ActionableHeading function is a React component that renders a header with optional
 * subheading, back button, and actions.
 * @param {ActionableHeadingPropTypes}  - - `headerId`: The translation ID for the header text. This is
 * used to retrieve the translated text from a localization file.
 * @returns a JSX element.
 */
export function ActionableHeading({
  headerId,
  headerValues,
  heading,
  actions,
  subheading,
  backButton
}: ActionableHeadingPropTypes) {
  return (
    <div className={`flex items-center ${styles['actionable-heading']}`}>
      <div>
        <div className="mb4">{backButton}</div>

        <If
          condition={!!headerId}
          do={<Heading translationId={headerId} values={headerValues} />}
          else={<If condition={!!heading} do={<Heading heading={heading} />} />}
        />
        {subheading}
      </div>

      <If condition={!!actions} do={<div className={`flex ml-auto ${styles['actions-bar']}`}>{actions}</div>} />
    </div>
  );
}
