import * as React from 'react';

import { If } from '@kwara/components/src/If/If';

type IconCollapsablePropTypes = {
  isCollapsed: boolean;
};

export function IconCollapsable(props: IconCollapsablePropTypes) {
  return (
    <If
      condition={props.isCollapsed}
      do={
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.21588 7.76869C9.63981 8.16407 9.63981 8.83593 9.21588 9.23131L1.93205 16.0245C1.29273 16.6208 0.25 16.1674 0.25 15.2932L0.25 1.70679C0.25 0.832587 1.29274 0.379234 1.93205 0.975483L9.21588 7.76869Z"
            fill="#475569"
          />
        </svg>
      }
      else={
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.784123 9.23131C0.360187 8.83593 0.360188 8.16407 0.784123 7.76869L8.06795 0.97548C8.70727 0.379232 9.75 0.832585 9.75 1.70679V15.2932C9.75 16.1674 8.70726 16.6208 8.06795 16.0245L0.784123 9.23131Z"
            fill="#334155"
          />
        </svg>
      }
    />
  );
}
