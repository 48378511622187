// @flow

import * as React from 'react';
import compact from 'lodash/fp/compact';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { BodyContentWrapper } from '../../../../layouts';
import { HeaderSubHeader } from '../../../../components/HeaderSubHeader';
import OverviewList from '../../../../components/OverviewList';

import { usePaginatedReconciliationStatements } from '../../../../models/hooks';
import { ReconciliationTable } from './Table';
import { usePermissions } from '../../../../hooks';

export const ReconcileTransactions = () => {
  const {
    allData,
    totalResults,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    ...rest
  } = usePaginatedReconciliationStatements();
  const { permission, AppPermissions } = usePermissions();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <HeaderSubHeader titleId="Finance.Reconciliations.title" subtitleId="Finance.Reconciliations.subtitle" />
          <OverviewList
            actions={compact([
              permission.to(AppPermissions.CreateReconciliationStatements) ? (
                <Button to="/finance/reconciliations/create" type="primary">
                  <Text id="Finance.Reconciliations.button.create" />
                </Button>
              ) : null
            ])}
            headerId="Finance.Reconciliations.List.header"
            labelId="Finance.Reconciliations.List.label"
            items={allData}
            totalNumResults={totalResults}
            table={
              <ReconciliationTable
                reports={allData}
                hasMore={hasNextPage}
                onNext={fetchNextPage}
                {...rest}
                isLoading={isLoading || isFetchingNextPage}
              />
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
