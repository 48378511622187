import { useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { useDebounce } from '.';
import { _api } from '../services';

type UseServerValidationArgs = {
  resource: string;
  queryKey: any[];
  onError?: Function;
  onSuccess?: Function;
  onIsLoading?: Function;
  options?: UseQueryOptions<void, unknown, void, any[]>;
};

export function useServerValidation({
  resource,
  queryKey,
  onError,
  onSuccess,
  onIsLoading,
  options
}: UseServerValidationArgs) {
  const [isValid, setIsValid] = useState(true);
  const debouncedValue = useDebounce<typeof resource>(resource);
  const $queryKey = [...queryKey, debouncedValue];

  useQuery(
    $queryKey,
    async ({ signal }) => {
      onIsLoading?.();

      try {
        await _api.httpGetRequest(resource, { signal });
        setIsValid(false);
        onSuccess?.();
      } catch (_) {
        setIsValid(true);
        onError?.();
      }
    },
    { enabled: !resource.includes(undefined), retry: false, ...options }
  );

  return isValid;
}
