// @flow

import React from 'react';
import get from 'lodash/fp/get';

import { SmsPlanT } from '@kwara/models/src/models/SmsPlan';
import { Text, Percent } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import Button from '@kwara/components/src/Button';
import { Currency as CurrencyIntl } from '@kwara/components/src/Intl';
import { Card } from '@kwara/components/src/Card';

import { settingPath } from '../../../../../lib/urls';
import { useSaccoProfileContext } from '../../../../../models/Profile/ProfileProvider';

export const BundleCard = ({ bundle }: { bundle: SmsPlanT }) => {
  const store = useSaccoProfileContext();
  const org = get('profile.organisation', store);

  const smsBalance = get('smsBalance', org);
  const smsBalancePercentage = get('smsBalancePercentage', org);
  const totalSmsSent = get('totalSmsSent', org);

  const { costPerSmsCents, currency } = bundle;
  return (
    <Card border={false}>
      <StatisticRow widthClass="w-50" border={false}>
        <Statistic
          compact
          title={<Text id="Settings.smsSettings.smsBalance" />}
          size="small"
          value={
            <span className={'teal-600'}>
              <CurrencyIntl value={smsBalance} />
            </span>
          }
        />
        <Statistic
          containerClassName={'fr'}
          size="small"
          value={
            smsBalancePercentage ? (
              <div className={'teal-600'}>
                <Percent value={smsBalancePercentage / 100} />
              </div>
            ) : (
              ''
            )
          }
        />
      </StatisticRow>

      <Statistic
        compact
        title={<Text id="Settings.smsSettings.smsSent" />}
        size="small"
        border={true}
        value={totalSmsSent}
      />
      <Statistic
        compact
        title={<Text id="Settings.smsSettings.smsCost" />}
        size="small"
        border={true}
        value={
          <CurrencyIntl currencyCode={currency} value={costPerSmsCents / 100} format="currency" hideDecimals={false} />
        }
      />
      <Button
        to={settingPath({
          baseExtension: 'sms',
          action: 'renew'
        })}
        type="primary"
        size="regular"
        className="mt4"
      >
        <Text id="Settings.smsSettings.renewSms" />
      </Button>
    </Card>
  );
};
