import React from 'react';

import { LoanRecovery } from './components/LoanRecovery';
import { PasswordExpiry } from './components/PasswordExpiry';
import { SaccoSocietyNumber } from './components/SaccoSocietyNumber';
import { DefaultSavingsAccounts } from './components/DefaultSavingsAccounts';

export const SaccoSettings = ({ refetch, organisation, settings }) => {
  return (
    <>
      <SaccoSocietyNumber settings={settings} />
      <LoanRecovery refetch={refetch} organisation={organisation} />
      <DefaultSavingsAccounts refetch={refetch} organisation={organisation} />
      <PasswordExpiry refetch={refetch} organisation={organisation} />
    </>
  );
};
