import React from 'react';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import values from 'lodash/fp/values';
import capitalize from 'lodash/fp/capitalize';

import { If } from '@kwara/components/src/If/If';
import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { DividendCalculationMethod } from '../../../../../../../models/DividendReports/DividendReports';
import { Notes } from '../../../../../../../components/Notes/Notes';
import styles from './index.module.scss';
export function Details({ TextField, SelectField, DatePicker, formProps, data }) {
  const { products, reportType } = data;

  return (
    <>
      <WizardSectionTitle id="Finance.DividendReports.Generate.Details.Section.title" />
      <div className={styles.details}>
        <TextField
          name="dividendPercentage"
          labelId={`Finance.DividendReports.Generate.Details.Section.${reportType}.approvedDividendPercentage`}
          required
          leftGlyph="General.%"
          type="number"
        />
        <SelectField
          compact
          showInfo={false}
          name="calculationMethod"
          labelId="Finance.DividendReports.Generate.Details.Section.calculationMethod"
          required
        >
          <SelectField.Option
            translationId="Finance.DividendReports.Generate.Details.Section.calculationMethod.select"
            value=""
          />
          {map(
            type => (
              <SelectField.Option value={type} key={type}>
                {capitalize(type)}
              </SelectField.Option>
            ),
            values(DividendCalculationMethod)
          )}
        </SelectField>
        <TextField
          name="accountStatus"
          labelId="Finance.DividendReports.Generate.Details.Section.accountStatus"
          initialValue="All"
          disabled
        />
        <SelectField
          showInfo={false}
          name="productId"
          labelId="Finance.DividendReports.Generate.Details.Section.product"
          required
        >
          <SelectField.Option
            translationId="Finance.DividendReports.Generate.Details.Section.product.select"
            value=""
          />
          <If
            condition={size(products) > 0}
            do={map(
              product => (
                <SelectField.Option value={product.id} key={product.id}>
                  {product.name}
                </SelectField.Option>
              ),
              products
            )}
            else={
              <SelectField.Option
                disabled={true}
                value=""
                translationId="Finance.DividendReports.Generate.Details.Section.product.select.empty"
              />
            }
          />
        </SelectField>
        <DatePicker
          labelId="Finance.DividendReports.Generate.Details.Section.beginningOfFinancialYear"
          showInfo={false}
          margin={false}
          name="beginningOfFinancialYear"
          disabled
        />
        <DatePicker
          labelId="Finance.DividendReports.Generate.Details.Section.endOfFinancialYear"
          showInfo={false}
          margin={false}
          name="endOfFinancialYear"
          disabled
        />
        <TextField
          name="allocatedDividendAmount"
          labelId={`Finance.DividendReports.Generate.Details.Section.${reportType}.allocatedDividendAmount`}
          required
          isCurrency
          type="number"
        />
        <TextField
          name="witholdingTaxPercentage"
          labelId="Finance.DividendReports.Generate.Details.Section.witholdingTaxPercentage"
          required
          leftGlyph="General.%"
          type="number"
        />
      </div>
      <Notes name="notes" className="mt3" formValues={formProps.values} />
    </>
  );
}

Details.validate = {
  dividendPercentage: {
    isRequired: () => true,
    percentage: true
  },
  calculationMethod: {
    isRequired: () => true
  },
  productId: {
    isRequired: () => true
  },
  allocatedDividendAmount: {
    isRequired: () => true,
    currency: true
  },
  witholdingTaxPercentage: {
    isRequired: () => true,
    percentage: true
  }
};
