import React from 'react';
import get from 'lodash/fp/get';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { useDownloadButton } from '@kwara/components/src/DownloadButton';
import { DividendReportsT } from '@kwara/models/src';

import OverviewList from '../../../../../../components/OverviewList';
import { ButtonMenu } from '../../../../../../components/ButtonMenu';
import { Indicators } from './components/Indicators';
import { ContributionsTable } from './components/ContributionsTable';
import { BodyContentWrapper } from '../../../../../../layouts';
import { useDividendContributionSummaries } from './useDividendContributionSummaries';
import { useDividendReport } from './useDividendReport';
import { MonthlyBreakdownPanel } from './components/MonthlyBreakdownPanel';
import { useSummarySidePanel } from './useSummarySidePanel';
import { useReportType } from '../../useReportType';

function DownloadCSVButton({
  report,
  isLoading,
  reportType
}: {
  reportType: string;
  report?: DividendReportsT;
  isLoading: boolean;
}) {
  const { isDisabled, onDownload, isLoading: isDownloading } = useDownloadButton({
    downloadFn: () => report?.downloadCSV(reportType),
    instance: report,
    disabled: false
  });

  return (
    <ButtonMenu.Item disabled={isDisabled || isLoading || isDownloading} onClick={onDownload}>
      <Text id="Finance.DividendReports.Contributions.overviewList.button.downloadCSV" />
    </ButtonMenu.Item>
  );
}

function DownloadXLSXButton({
  report,
  isLoading,
  reportType
}: {
  reportType: string;
  report?: DividendReportsT;
  isLoading: boolean;
}) {
  const { isDisabled, onDownload, isLoading: isDownloading } = useDownloadButton({
    downloadFn: () => report?.downloadXLSX(reportType),
    instance: report,
    disabled: false
  });

  return (
    <ButtonMenu.Item disabled={isDisabled || isLoading || isDownloading} onClick={onDownload}>
      <Text id="Finance.DividendReports.Contributions.overviewList.button.downloadXLSX" />
    </ButtonMenu.Item>
  );
}

function DownloadButtonMenu({ reportResponse, reportType }) {
  return (
    <ButtonMenu type="secondary" title="Download">
      <DownloadXLSXButton
        reportType={reportType}
        key={'XLSX'}
        report={reportResponse.data}
        isLoading={reportResponse.isLoading}
      />
      <DownloadCSVButton
        reportType={reportType}
        key={'CSV'}
        report={reportResponse.data}
        isLoading={reportResponse.isLoading}
      />
    </ButtonMenu>
  );
}

export function DividendReportView(props) {
  const reportId = props.match.params.reportId;

  const reportResponse = useDividendReport(reportId);
  const {
    allData,
    totalResults,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    onSearchChange,
    searchValue
  } = useDividendContributionSummaries(reportId);

  const { ref, onSetSummaryId, summary, onClose } = useSummarySidePanel({ summaries: allData });
  const reportType = useReportType(reportResponse.data?.productId);
  const reportCalculationMethod = get('calculationMethod', reportResponse.data);

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Indicators>
          <Indicators reportResponse={reportResponse} reportType={reportType} />
        </BodyContentWrapper.Row.Indicators>
      </BodyContentWrapper.Row.Root>

      <BodyContentWrapper.Row.Root>
        <MonthlyBreakdownPanel
          ref={ref}
          summary={summary}
          onClose={onClose}
          reportType={reportType}
          reportCalculationMethod={reportCalculationMethod}
        />
        <BodyContentWrapper.Row.Table>
          <OverviewList
            headerId={`Finance.DividendReports.Contributions.overviewList.${reportType}.headerId`}
            labelId="Finance.DividendReports.Contributions.overviewList.labelId"
            totalNumResults={totalResults || 0}
            actions={[<DownloadButtonMenu reportType={reportType} key="download" reportResponse={reportResponse} />]}
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            searchClassNames="w-30"
            searchPlaceholderId="Finance.DividendReports.Contributions.overviewList.search.placeholderId"
            items={allData}
            backButton={
              <Button glyphLeftId={Button.Glyphs.ArrowLeft} className="black" type="secondary" to="/finance/dividends">
                <Text id="Back.Generic" />
              </Button>
            }
            table={
              <ContributionsTable
                reportType={reportType}
                reportCalculationMethod={reportCalculationMethod}
                contributionSummaries={allData}
                onRowClick={onSetSummaryId}
                report={reportResponse.data}
                onNext={fetchNextPage}
                hasMore={hasNextPage}
                isLoading={isLoading || isFetchingNextPage || reportResponse.isLoading}
              />
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
