import * as React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';

import { FILTER_TYPES } from '../../../../../../../components/Filter/utils/filterTypes';
import { TextboxBaseFilter, DateBaseFilter, ComboboxBaseFilter } from '../../../../../../../components/Filter/pages';

///
const LoanDisbursementSearchFilter = () => (
  <TextboxBaseFilter
    name="Search by loan ID or member"
    filterName={FILTER_TYPES.LOAN_DISBURSEMENT_SEARCH}
    placeholderId="Finance.LoanDisbursement.Filter.Search.placeholder"
  />
);

///
const LoanDisbursementDateFilter = () => (
  <DateBaseFilter
    filterType={FILTER_TYPES.LOAN_DISBURSEMENT_DATE}
    disabledDays={{ from: { after: getCurrentDate() }, to: { after: getCurrentDate() } }}
  />
);

///
const LoanDisbursementStatusFilter = () => (
  <ComboboxBaseFilter
    filterType={FILTER_TYPES.LOAN_DISBURSEMENT_STATUS}
    placeholderId="Finance.LoanDisbursement.Filter.Status.placeholder"
  />
);

export { LoanDisbursementSearchFilter, LoanDisbursementDateFilter, LoanDisbursementStatusFilter };
