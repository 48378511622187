import React from 'react';

import { useIntl } from 'react-intl';

import { If } from '@kwara/components/src/If/If';
import { Currency } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { ErrorTextWithI18n } from '@kwara/components/src/ErrorText';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';
import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';
import { InstantMobileMoneyValidator } from '@kwara/components/src/InstantMobileMoneyValidator';

import { useBaseLogic } from './useBaseLogic';
import { useAttributes } from './useAttributes';
import { LoanTopupDisbursalStepPropTypes } from '..';
import { Notes } from '../../../components/Notes/Notes';
import { AttributesRow } from '../../../components/AttributesRow';
import { FloatBalanceBreakdown } from '../../Loan/components/FloatBalanceBreakdown';
import { validateDisbursementAmount } from '../../Loan/utils/validateDisbursementAmount';
import { AddFeeForm, SelectedFees } from '../../Loan/LoanAdd/components/LoanConfigure/Fees';
import {
  DisbursementMethodForm,
  DisbursementMethodFormPropTypes
} from '../../Loan/LoanDisbursal/components/Approvals/DisbursementMethodForm';

import styles from './Review.module.scss';

function Review(props: LoanTopupDisbursalStepPropTypes) {
  const {
    formProps: { values },
    data: { loan, fullName, payoutProvider, transactionAmountLimit },
    TextField,
    DatePicker
  } = props;

  const intl = useIntl();
  const attributes = useAttributes(fullName, loan);
  const {
    isMobileMpesaInstant,
    feesToShow,
    selectedFees,
    disbursementAmount,
    isLoadingFloatBalance,
    floatBalanceError,
    showMobileMoneyAmountTopError,
    getFee,
    onFeeAdd,
    onRemove,
    getFeeApplication
  } = useBaseLogic(props);

  const disbursementMethodFormProps = ({
    ...props,
    disbursementAmount
  } as unknown) as DisbursementMethodFormPropTypes;

  return (
    <>
      <AttributesRow attributes={attributes} useBackground={false} />
      <div className="mt3">
        <div className="flex justify-between items-center">
          <div className="w-50">
            <TextField
              type="number"
              name="loan.pendingDisbursementAmount"
              labelId="LoanTopup.Disbursal.Review.amount"
              leftGlyph="Currency.orgCurrency"
              required
              disabled
              readOnly
              compact
              isCurrency
            />
            <If
              condition={showMobileMoneyAmountTopError}
              do={
                <ErrorTextWithI18n
                  id="LoanTopup.Disbursal.Review.amount.error"
                  values={{ maximumAmount: <Currency format="currency" value={values.floatBalance} /> }}
                  className={styles['amount-error']}
                />
              }
            />
          </div>
          <Loadable
            isLoading={isLoadingFloatBalance}
            error={floatBalanceError}
            loading={
              <div className="mt2">
                <LoadingSkeleton shape="radius-horizone" width="150px" height="20px" />
              </div>
            }
          >
            <div className="w-50 pl3 mb3">
              <FloatBalanceBreakdown
                shown={isMobileMpesaInstant}
                phoneNumberProvider={values.phoneNumberProvider}
                floatBalance={values.floatBalance}
              />
            </div>
          </Loadable>
        </div>
        <div>
          <SelectedFees fees={selectedFees} getFeeApplication={getFeeApplication} onRemoveFee={onRemove} />
          <div className="mt2">
            <AddFeeForm fees={feesToShow} getFee={getFee} onAddFee={onFeeAdd} />
          </div>
        </div>
        <DatePicker
          labelId="LoanTopup.Disbursal.Review.anticipatedDisbursementDate"
          name="loan.anticipatedDisbursementDate"
          inputPlaceholder={intl.formatMessage({ id: 'LoanTopup.DisbursementAndRepayment.datePlaceholder' })}
          value={loan.anticipatedDisbursementDate ? formatIsoDate(new Date(loan.anticipatedDisbursementDate)) : null}
          disabledDays={{ after: getCurrentDate(), before: new Date(loan.createdAt) }}
          showInfo={false}
          required
        />
        <InstantMobileMoneyValidator
          payoutProvider={payoutProvider}
          phoneNumberProvider={values.phoneNumberProvider}
          transactionAmountLimit={transactionAmountLimit}
          loanAmount={disbursementAmount}
        />
        <DisbursementMethodForm {...disbursementMethodFormProps} />
        <Notes name="note" className="mt3" formValues={values} />
      </div>
    </>
  );
}

export default {
  Component: Review,
  validate: {
    ['loan.pendingDisbursementAmount']: {
      isRequired: () => true,
      currency: true,
      nonZero: true,
      custom: validateDisbursementAmount
    },
    ['loan.anticipatedDisbursementDate']: {
      isRequired: () => true
    },
    ...DisbursementMethodForm.validate
  }
};
