import React from 'react';
import some from 'lodash/fp/some';

import { OperationalReport } from '@kwara/models/src/models/OperationalReport';

import { useOverviewList, fetchWithPagination } from '../../models/hooks/base';
import { useThrottledCallback } from '../../hooks';

export function usePaginatedOperationalReports(reportKey: string, cacheKey: string) {
  async function fetchOperationalReports(opts) {
    return fetchWithPagination(OperationalReport.includes(['created_by']).where({ report_key: reportKey }), opts);
  }

  const result = useOverviewList({
    queryKey: cacheKey,
    fetchFn: fetchOperationalReports,
    includes: [],
    select: null
  });

  const refetchIfPending = React.useCallback(() => {
    if (some(report => report.state === 'pending', result.allData)) {
      result.refetch();
    }
  }, [result]);

  useThrottledCallback(refetchIfPending);

  return result;
}
