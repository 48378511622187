import { TransactionChannels } from '@kwara/models/src/models/Transactions';
import { InstantMobileMoneyValidatorUtil } from '@kwara/components/src/InstantMobileMoneyValidator/utils/validator';

type SharedArgType = {
  loanAmount: number;
  transactionAmountLimit: number;
  phoneNumberProvider: string;
  payoutProvider: string;
};

type FilterDisbursementMethodsArgType = SharedArgType & {
  methods: Array<{ value: string }>;
};

export class DisbursementMethodsFilter {
  private static isMobileMpesaInstantMethodPermitted({
    loanAmount,
    transactionAmountLimit,
    phoneNumberProvider,
    payoutProvider
  }: SharedArgType) {
    return (
      InstantMobileMoneyValidatorUtil.isPhoneNumberProviderSupported(phoneNumberProvider) &&
      InstantMobileMoneyValidatorUtil.isTransactionAmountLimitValid(loanAmount, transactionAmountLimit) &&
      InstantMobileMoneyValidatorUtil.isPayoutProviderSupported(payoutProvider)
    );
  }

  public static filterDisbursementMethods({ methods, ...rest }: FilterDisbursementMethodsArgType) {
    return methods.filter(({ value }) => {
      if (value === TransactionChannels.mobileMpesaInstant) {
        return this.isMobileMpesaInstantMethodPermitted(rest);
      }

      return true;
    });
  }
}
