import * as React from 'react';
import cx from 'classnames';

import { GenericPolymorphic } from '@kwara/components/src/GenericPolymorphic';

import styles from './index.module.scss';

type PrimitiveElementPropTypes = React.ComponentPropsWithoutRef<'a' | 'button' | 'link'>;
type LinkButtonElement = React.ElementRef<'a' | 'button' | 'link'>;
type LinkButtonPropTypes = PrimitiveElementPropTypes & {
  as: 'button' | 'Link' | 'NavLink' | 'a';
  to?: string;
};

export const LinkButton = React.forwardRef<LinkButtonElement, LinkButtonPropTypes>(function LinkButton(
  { className, ...restProps },
  forwardedRef
) {
  return <GenericPolymorphic {...restProps} className={cx(styles.container, className)} ref={forwardedRef} />;
});
