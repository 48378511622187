import * as React from 'react';
import pickBy from 'lodash/fp/pickBy';

import { SavingType, SavingsTransaction } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { savingPath } from '../../lib/urls';

import { steps } from './config';

import { PaymentData } from './components/PaymentForm';

type FormData = PaymentData & {
  notes?: string;
  saving: SavingType;
  predefinedFeeKey?: string;
};

const createTransaction = async (data: FormData) => {
  const { amount, notes, saving, predefinedFeeKey } = data;

  const transaction = SavingsTransaction.create(
    pickBy((v, k) => !(k === 'predefinedFeeKey' && v === 'OTHER'), {
      type: 'FEE',
      savingsId: saving.id,
      amount,
      notes,
      predefinedFeeKey
    })
  );

  const didSave = await transaction.save();
  if (!didSave) {
    throw saving.errors;
  }
};

export function SavingFee(props) {
  const r = useSaving(props.match.params.savingId);

  return (
    <Loadable {...r}>
      {saving => {
        return (
          <Wizard
            analyticsId="SavingFee"
            baseUrl={savingPath({ id: saving.id })}
            history={history}
            initialData={{ saving, member: saving.member }}
            cancelReturnsTo={savingPath({ id: saving.id })}
            currentStep="fee"
            onSubmit={createTransaction}
            steps={steps}
            startId="fee"
            titleId="SavingPenalty.title"
          />
        );
      }}
    </Loadable>
  );
}
