import * as React from 'react';
import noop from 'lodash/fp/noop';

import { useSchedule } from '@kwara/models/src/models/request/hooks';
import { PrintButton } from '@kwara/components/src';
import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';
import { Text } from '@kwara/components/src/Intl';
import { LoanType } from '@kwara/models/src';
import { useLoansGuaranteed } from '@kwara/models/src/models/request/hooks';
import { DownloadButton } from '@kwara/components/src/DownloadButton';
import { If } from '@kwara/components/src/If/If';

import { useLoanTransactions, usePermissions } from '../../hooks';
import { loanPath } from '../../lib/urls';
import { Activity } from './components/Activity';
import { AsNavigation, BackToTopNavigation } from '../../components/DetailNavigation';
import { DetailSection } from '../../components/Detail/DetailSection';
import DetailSubsection from '../../components/DetailSubsection';
import { Updater } from '../../components/EditableSection/v1/EditableSection';

import { Disbursement } from './components/Disbursement';
import FeesAndPenalties from './components/FeesAndPenalties';
import Head from '../../components/Head';
import { Overview } from './components/Overview';
import { Repayment } from './components/Repayment';
import { Documents } from './components/Documents';
import { Securities } from './components/Securities';
import { Schedule } from './components/Schedule';
import { Transactions } from './components/Transactions';
import { Income } from './components/Income';
import { Classification } from './components/Classification';
import { Details } from './components/Details';
import { SettlementAccount } from './components/SettlementAccount';
import { LoanButtonMenu } from './components/LoanButtonMenu';
import { AppPermissionsType, ToHelperType } from '../../models/Permission/types';
import { useMaxDisbursers } from '../Loan/LoanDisbursal/useMaxDisbursers';
import { DetailPageProps } from '..';

type Props = DetailPageProps<{ loanId: string }> & {
  hiddenSections: string[];
  showCommentCreate: boolean;
  showMargins: boolean;
  showOverviewActions: boolean;
  readOnly?: boolean;
};

const Hidden = ({ when, within, children }) => (within.includes(when) ? null : children);

const primaryButtonForLoan = (
  loan: LoanType,
  maxDisbursers: number,
  to: ToHelperType,
  AppPermissions: AppPermissionsType
) => {
  if (loan.canDisburse(maxDisbursers) && to(AppPermissions.DisburseLoans)) {
    return {
      title: 'LoanDetail.disburse',
      url: loanPath({ id: loan.id, action: 'disbursal' }),
      disabled: false
    };
  }

  if (loan.canMakeRepayment() && to(AppPermissions.AddRepayments)) {
    return {
      title: 'LoanDetail.add',
      url: loanPath({ id: loan.id, action: 'repayment' }),
      disable: false
    };
  }

  if (loan.canDisburseTopedUpLoan()) {
    return {
      title: 'LoanDetail.disburse',
      url: loanPath({ id: loan.id, action: 'topupDisbursal' }),
      disabled: false
    };
  }

  return {};
};

const renderBody = ({
  hiddenSections,
  loan,
  showCommentCreate,
  refetch = noop,
  scheduleR,
  transactionsR,
  loanSecurityR
}: Props & { loan: LoanType; refetch: () => void }) => {
  const { member } = loan;

  return (
    <Updater value={{ onUpdate: refetch }}>
      <>
        <Head titleId="LoanDetail.title" values={{ id: loan.id }} />
        <DetailSection id="details" title={<Text id="LoanDetail.details" />} subtitle={<BackToTopNavigation />}>
          <DetailSubsection title="Details">
            <Details loan={loan} />
          </DetailSubsection>
        </DetailSection>

        <DetailSection id="applicant" title={<Text id="LoanDetail.applicant" />} subtitle={<BackToTopNavigation />}>
          <DetailSubsection title={<Text id="LoanDetail.income" />}>
            <Income loan={loan} />
          </DetailSubsection>
          <DetailSubsection title={<Text id="LoanDetail.classification" />}>
            <Classification loan={loan} />
          </DetailSubsection>
        </DetailSection>

        <DetailSection
          id="transactions"
          title={<Text id="LoanDetail.transactions" />}
          subtitle={<BackToTopNavigation />}
        >
          <Hidden when="transactions" within={hiddenSections}>
            <DetailSubsection
              title="Transactions"
              headerRight={<PrintButton size={'medium'} to={loanPath({ id: loan.id, action: 'statement' })} />}
            >
              <Loadable {...transactionsR}>
                {transactions => <Transactions transactions={transactions} transactionsR={transactionsR} />}
              </Loadable>
            </DetailSubsection>
          </Hidden>

          <Hidden when="feesAndPenalties" within={hiddenSections}>
            <DetailSubsection title={<Text id="LoanDetail.feesAndPenalties" />} classNames="hide-on-print">
              <FeesAndPenalties loan={loan} />
            </DetailSubsection>
          </Hidden>
        </DetailSection>

        <DetailSection id="schedule" title={<Text id="LoanDetail.schedule" />} subtitle={<BackToTopNavigation />}>
          <Loadable {...scheduleR}>
            {schedule => (
              <DetailSubsection
                title="Schedule"
                headerRight={<DownloadButton downloadFn={loan.downloadSchedulePdf} instance={loan} />}
              >
                <Schedule schedule={schedule} />
              </DetailSubsection>
            )}
          </Loadable>
        </DetailSection>

        <DetailSection
          id="security"
          title={<Text id="LoanDetail.security" />}
          subtitle={<BackToTopNavigation />}
          classNames="hide-on-print"
        >
          <Loadable {...loanSecurityR}>
            {loan => (
              <>
                <Securities loan={loan} />
                <div className="cf" />
              </>
            )}
          </Loadable>
        </DetailSection>

        <DetailSection
          id="documents"
          title={<Text id="LoanDetail.documents" />}
          subtitle={<BackToTopNavigation />}
          classNames="hide-on-print"
        >
          <If condition={!!member} do={<Documents member={member} readOnly={!loan.isEditable()} />} />
        </DetailSection>

        <DetailSection
          id="repayment"
          title={<Text id="LoanDetail.repayment" />}
          subtitle={<BackToTopNavigation />}
          classNames="hide-on-print"
        >
          <DetailSubsection title={<Text id="LoanDetail.details" />} classNames="hide-on-print">
            <Repayment loan={loan} refetch={refetch} readOnly={!loan.isEditable()} />
          </DetailSubsection>

          <DetailSubsection title={<Text id="LoanDetail.settlementAccount" />}>
            <SettlementAccount loan={loan} />
          </DetailSubsection>
        </DetailSection>

        <DetailSection
          id="disbursement"
          title={<Text id="LoanDetail.disbursement" />}
          subtitle={<BackToTopNavigation />}
          classNames="hide-on-print"
        >
          <Disbursement loan={loan} />
        </DetailSection>

        <DetailSection
          id="activity"
          title={<Text id="LoanDetail.activity" />}
          subtitle={<BackToTopNavigation />}
          classNames="hide-on-print"
        >
          <Activity loanId={loan.id} showCommentCreate={showCommentCreate} />
        </DetailSection>
      </>
    </Updater>
  );
};

const LoanDetail = ({
  loan,
  refetchLoan,
  hiddenSections = [],
  showCommentCreate = true,
  showMargins = true,
  showOverviewActions = true,
  readOnly = true,
  ...props
}: Props & { loan: LoanType; refetch: () => void }) => {
  const { permission, AppPermissions } = usePermissions();
  const scheduleR = useSchedule(loan.id);
  const transactionsR = useLoanTransactions(loan.id);
  const loanSecurityR = useLoansGuaranteed(props.match.params.loanId);
  const maxDisbursers = useMaxDisbursers();
  const refetch = () => {
    refetchLoan && refetchLoan();
    scheduleR.refetch();
    transactionsR.refetch();
  };

  const printButton = <DownloadButton className="mr2" instance={loan} downloadFn={loan.downloadApplicationPdf} />;
  const { title, url, disabled } = primaryButtonForLoan(loan, maxDisbursers, permission.to, AppPermissions);

  const primaryButton = url ? (
    <Button type="primary" to={url} disabled={disabled} size="medium">
      <Text id={title} />
    </Button>
  ) : null;

  const body = renderBody({
    ...props,
    hiddenSections,
    showCommentCreate,
    loan,
    refetch,
    scheduleR,
    transactionsR,
    loanSecurityR
  });
  const Navigation = AsNavigation(body.props.children);

  return (
    <PageLayoutWithOverview
      className={showMargins ? 'pt5' : ''}
      overview={
        <Overview
          navigation={<Navigation />}
          loan={loan}
          showBack={showOverviewActions}
          printOverview={false}
          actions={showOverviewActions ? [printButton, <LoanButtonMenu loan={loan} />, primaryButton] : null}
          linkToMember={showOverviewActions}
          readOnly={readOnly}
        />
      }
    >
      {body}
    </PageLayoutWithOverview>
  );
};

export default LoanDetail;
