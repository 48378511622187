import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconCheckElement = React.ElementRef<'svg'>;

interface IconCheckPropTypes extends PrimitiveSvgPropTypes {}

/**************************************************************
 * @IconCheck
 * This component is a check svg icon that can be
 * reused to suit your use cases e.g changing the```stroke```,
 * ```width```, ```height```, targeting the element and etc
 **/

export const IconCheck = React.forwardRef<IconCheckElement, IconCheckPropTypes>(function IconCheck(
  { stroke, width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" stroke={stroke} strokeWidth="2" />
      <path d="M9 11.9497L11.1213 14.071L15.364 9.82839" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
});

IconCheck.defaultProps = {
  stroke: '#FFFFFF',
  width: '24',
  height: '24'
};
