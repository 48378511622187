import React from 'react';

import Banner from '@kwara/components/src/Banner';

import { Grid } from '../../../../components/Grid';

export const SubmissionBanner = ({ error, success }) => {
  return error || success ? (
    <Grid columns={2} width="w-50" border={false}>
      {error ? <Banner state="error" text="Could not save" /> : null}
      {success ? <Banner state="success" text="Changes saved!" /> : null}
    </Grid>
  ) : null;
};
