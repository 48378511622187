import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { retry } from '../../../../../../services';
import {
  asyncGetPdfCreditReport,
  CreditReportParams,
  PdfCreditReportData
} from '../../../../../../models/CreditReport/CreditReport';

function b64toBlob(b64Data: string, sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const chunkCharacter = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(chunkCharacter.length);

    for (let i = 0; i < chunkCharacter.length; i++) {
      byteNumbers[i] = chunkCharacter.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'application/pdf' });

  return blob;
}

export function useGetPdfCreditReport(queryParams: CreditReportParams) {
  const { error, isLoading, data } = useQuery<PdfCreditReportData>(
    ['pdf_credit_report'],
    () => asyncGetPdfCreditReport(queryParams) as any,
    { refetchOnWindowFocus: false, retry }
  );

  const memoizedPdfUrl = useMemo(() => {
    let pdfUrl = null;

    if (data?.data && data.data.attributes?.report) {
      const reportBlob = b64toBlob(data.data.attributes.report);
      pdfUrl = URL.createObjectURL(reportBlob);
    }

    return pdfUrl;
  }, [data?.data]);

  return {
    error,
    isLoading,
    data,
    pdfUrl: memoizedPdfUrl
  };
}
