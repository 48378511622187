import * as React from 'react';

import { MemberType } from '@kwara/models/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { excludedEmploymentField } from '@kwara/lib/src/modelUtils/member';

import { Grid } from '../../Grid';
import { EditableSection, EditableConfig } from '../../EditableSection/v1/EditableSection';
import {
  Employment as EmploymentBaseForm,
  EmploymentExtras,
  validateConfig
} from '../../../pages/MemberAdd/components/Employment';

// Editable forms configs
const base = (isV1?: boolean) => {
  return {
    Component: props => (
      <>
        <EmploymentBaseForm {...props} />
        <EmploymentExtras {...props} isV1={isV1} />
      </>
    )
  };
};
const memberDetail = (isV1?: boolean) => {
  return {
    ...base(isV1),
    validate: validateConfig.memberDetail
  };
};
const loanAdd = (isV1?: boolean) => {
  return {
    ...base(isV1),
    validate: validateConfig.loanAdd
  };
};

export const Employment = ({
  config = memberDetail,
  member,
  readOnly,
  isEditing,
  onEditStart,
  onEditEnd,
  isV1
}: EditableConfig<MemberType>) => {
  const shouldHide = excludedEmploymentField(member);

  return (
    <EditableSection
      config={config(isV1)}
      initialData={member}
      readOnly={readOnly}
      isEditing={isEditing}
      onEditStart={onEditStart}
      onEditEnd={onEditEnd}
    >
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="AddMember.Employment.Profession.label" />}
          value={member.profession}
          size="medium"
          hidden={shouldHide('profession')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.Type.label" />}
          value={member.employmentStatus ? <Text id={`Employment.Type.${member.employmentStatus}`} /> : null}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Employer.label" />}
          value={member.employer}
          size="medium"
          hidden={shouldHide('employer')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.StaffId.label" />}
          value={member.staffId}
          size="medium"
          hidden={shouldHide('staffId')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.Business.label" />}
          value={member.business}
          size="medium"
          hidden={shouldHide('business')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.EmployerEmail.label" />}
          value={member.employerEmail}
          size="medium"
          hidden={shouldHide('employerEmail') || isV1}
        />
        <Statistic
          title={<Text id="AddMember.Employment.EmployerPhoneNumber.label" />}
          value={member.employerPhoneNumber}
          size="medium"
          hidden={shouldHide('employerPhoneNumber') || isV1}
        />
        <Statistic
          title={<Text id="AddMember.Employment.MemberWorkEmail.label" />}
          value={member.workEmail}
          size="medium"
          hidden={shouldHide('workEmail') || isV1}
        />
        <Statistic
          title={<Text id="AddMember.Employment.GrossIncome.label" />}
          value={<Currency format="currency" value={member.grossIncome} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.NetIncome.label" />}
          value={<Currency format="currency" value={member.netIncome} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.OtherDeductibles.label" />}
          value={<Currency format="currency" value={member.otherDeductibles} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.OtherIncomeAmount.label" />}
          value={<Currency format="currency" value={member.otherIncomeAmount} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.IncomeSource.label" />}
          value={member.incomeSource}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.DisposableIncomeAmount.label" />}
          value={<Currency format="currency" value={member.disposableIncomeAmount} />}
          size="medium"
          hidden={isV1}
        />
        <Statistic
          title={<Text id="AddMember.Employment.tos.label" />}
          hidden={shouldHide('termsOfService')}
          value={member.termsOfService ? <Text id={`AddMember.Employment.tos.${member.termsOfService}`} /> : null}
          size="medium"
        />
      </Grid>
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="AddMember.Employment.Group.label" />}
          value={<Text id={`AddMember.Employment.Group.${member.isGroup}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Staff.label" />}
          value={<Text id={`AddMember.Employment.Staff.${member.isStaff}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Director.label" />}
          value={<Text id={`AddMember.Employment.Director.${member.isDirector}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Delegate.label" />}
          value={<Text id={`AddMember.Employment.Delegate.${member.isDelegate}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.GovEmployee.label" />}
          value={<Text id={`AddMember.Employment.GovEmployee.${member.govEmployee}`} />}
          size="medium"
        />
      </Grid>
    </EditableSection>
  );
};

Employment.Title = () => <Text id="MemberDetail.employment" />;
Employment.editConfig = { memberDetail, loanAdd };
