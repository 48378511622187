import * as React from 'react';

import Wizard, { getSubstepFromMatchParams } from '@kwara/components/src/Wizard/latest';
import { StepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';
import { getStartOfLastYear, getEndOfLastYear } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';

import { WizardPageProps } from '../../../../..';
import { DividendReports } from '../../../../../../models/DividendReports/DividendReports';
import { steps } from './config';
import { useFetchAndPartitionSavingProducts } from './useFetchAndPartitionSavingProducts';
import { REPORT_TYPE } from '../../useReportType';

async function generateReport(data) {
  const {
    productId,
    calculationMethod,
    dividendPercentage,
    witholdingTaxPercentage,
    allocatedDividendAmount,
    notes
  } = data;

  const dividendReport = new DividendReports({
    productId,
    calculationMethod,
    dividendPercentage,
    witholdingTaxPercentage,
    allocatedDividendAmount,
    notes
  });

  const didSave = await dividendReport.save();

  if (!didSave) {
    throw dividendReport.errors;
  }
}

export function DividendReportGenerate({ baseUrl, reportType, match }: WizardPageProps<{}>) {
  const beginningOfFinancialYear = getStartOfLastYear();
  const endOfFinancialYear = getEndOfLastYear();
  const productsResponse = useFetchAndPartitionSavingProducts();

  return (
    <Loadable {...productsResponse}>
      {data => {
        const products = reportType === REPORT_TYPE.INTEREST ? data.savingProducts : data.shareCapitalProducts;

        return (
          <Wizard
            analyticsId="DividendReportGenerate"
            startId="details"
            titleId={`Finance.DividendReports.Generate.${reportType}.title`}
            steps={steps(reportType) as StepConfig}
            baseUrl={baseUrl}
            cancelReturnsTo={'/finance/dividends'}
            currentStep={match.params.step}
            initialData={{ beginningOfFinancialYear, endOfFinancialYear, products, reportType }}
            onSubmit={generateReport}
            currentSubStep={getSubstepFromMatchParams(match.params)}
          />
        );
      }}
    </Loadable>
  );
}
