// @flow

import React from 'react';

import { LoanTermsFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanTermsFields';
import { LoanClassificationFields } from '@kwara/components/src/pages/LoanAdd/components/Configure/LoanClassificationFields';

import { RescheduleAmountField } from './RescheduleAmountField';
import { WarningBanner } from './WarningBanner';

import { type ComponentPropsLoanReschedule } from '..';

export const Configure = (props: ComponentPropsLoanReschedule) => {
  const { StackChild, data } = props;
  const { product } = data;
  return (
    <StackChild>
      <WarningBanner className="mb4" {...props} />
      <h3>{product.name}</h3>
      <RescheduleAmountField {...props} />
      <LoanClassificationFields {...props} />
      <LoanTermsFields {...props} />
    </StackChild>
  );
};

Configure.validate = {
  ...RescheduleAmountField.validate,
  ...LoanClassificationFields.validate,
  ...LoanTermsFields.validate
};
