import React from 'react';
import cx from 'classnames';

import Banner from '@kwara/components/src/Banner';

import { useValidate, ArgType as InstantMobileMoneyValidatorPropTypes } from './hooks/useValidate';

import styles from './index.module.scss';

export function InstantMobileMoneyValidator(props: InstantMobileMoneyValidatorPropTypes) {
  const { isValid, invalidTitle, invalidDetails } = useValidate(props);

  return (
    <Banner
      hidden={isValid}
      state="success"
      className="mb2"
      text={
        <>
          <h3 className="kw-weight-bold kw-text-regular">{invalidTitle}</h3>
          <ul className={cx('pl3', styles['details'])}>
            {invalidDetails.map((detail, index) => (
              <li key={index.toString()}>{detail}</li>
            ))}
          </ul>
        </>
      }
    />
  );
}
