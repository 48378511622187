//@flow
import * as React from 'react';

import { Comment as CommentModel } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';

import ActivityTimeline from '../../../components/Activity';
import { usePermissions } from '../../../hooks';
import { useActivity } from '../../../hooks';

type Props = {
  memberId: string,
  showCommentCreate: boolean
};

const includes = [];
export const Activity = ({ memberId, showCommentCreate = true }: Props) => {
  const [errors, setErrors] = React.useState(null);
  const { refetch, ...r } = useActivity({ id: memberId, includes, entity: 'member' });
  const { AppPermissions, permission } = usePermissions();

  const onComment = async data => {
    setErrors(null);
    const Comment = CommentModel.member(memberId);
    const url = Comment.url();
    const opts = Comment.fetchOptions();

    const comment = new Comment(data);
    const didSave = await comment.post({ url, opts });

    if (didSave) {
      refetch();
    } else {
      setErrors(comment.errors);
    }
  };

  return (
    <Loadable {...r}>
      {activities => {
        return (
          <ActivityTimeline
            activities={activities}
            disableComments={false}
            onComment={onComment}
            errors={errors}
            showCommentCreate={showCommentCreate && permission.to(AppPermissions.AddComments)}
          />
        );
      }}
    </Loadable>
  );
};
