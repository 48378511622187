import * as React from 'react';
import { Redirect } from 'react-router-dom';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { useQueryClient } from 'react-query';
import last from 'lodash/fp/last';
import compact from 'lodash/fp/compact';
import invoke from 'lodash/fp/invoke';

import { useFullLoan } from '@kwara/models/src/models/request/hooks';
import { onlyTruthyValues, isEmpty } from '@kwara/lib/src/lodash';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { PeriodUnitsT } from '@kwara/models/src';

import {
  Loan,
  Collateral,
  Guarantor,
  LoanType,
  MemberType,
  GuaranteeType,
  CollateralT,
  LoanProductType,
  LoanClassification
} from '@kwara/models/src';
import { DisbursementBankDetailsT } from '@kwara/models/src/models/Loan';

import { SubStepComponentProps } from '../../components/Wizard';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { steps } from './config';
import { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }>;

export type FormDataLoanReschedule = {
  loan: LoanType;
  productId: string;
  product: LoanProductType;
  member: MemberType;
  amount: string;
  loanDuration: string;
  loanInstallments?: string;
  guarantors: GuaranteeType[];
  collaterals: CollateralT[];
  repaymentPeriodUnit: PeriodUnitsT;
  disbursementMode: string;
  disbursementBankDetails: DisbursementBankDetailsT;
  repaymentPeriod: string;
  firstRepaymentDate: Date;
  writeOffAmount?: string | number;
  classifications: LoanClassification[];
};

export type ComponentPropsLoanReschedule = SubStepComponentProps<FormDataLoanReschedule>;

export function filterDeletedSecurities(securities: (GuaranteeType | CollateralT)[]) {
  return filter(security => !security.isMarkedForDestruction, securities);
}

function hasUpdatedInfo(details) {
  return !isEmpty(onlyTruthyValues(details));
}

const rescheduleLoan = async (data: FormDataLoanReschedule) => {
  const {
    loan,
    productId,
    loanDuration,
    loanInstallments,
    guarantors: persistedGuarantors,
    collaterals: persistedCollaterals,
    repaymentPeriodUnit,
    repaymentPeriod,
    firstRepaymentDate,
    disbursementMode: disbursementModeUpdated,
    disbursementBankDetails: disbursementBankDetailsUpdated,
    amount,
    writeOffAmount,
    classifications
  } = data;

  const loanClassificationId = last(compact(classifications));

  // Access the original loan's disbursement and repayment details
  const { disbursementMode: disbursementModeOriginal, disbursementBankDetails: disbursementBankDetailsOriginal } = loan;

  // Compare with the current form's values, prioritizing the current form's values.
  const disbursementMode = disbursementModeUpdated || disbursementModeOriginal;
  const disbursementBankDetails = hasUpdatedInfo(disbursementBankDetailsUpdated)
    ? disbursementBankDetailsUpdated
    : disbursementBankDetailsOriginal;

  const collaterals = map(c => {
    const collateral = onlyTruthyValues(c);
    return new Collateral({ ...collateral, isPersisted: false });
  }, filterDeletedSecurities(persistedCollaterals));

  const guarantors = map(g => {
    const guarantor = onlyTruthyValues(g);
    return new Guarantor({ ...guarantor, isPersisted: false });
  }, filterDeletedSecurities(persistedGuarantors));

  // Mambu does not want 0 values so we filter
  // here for the non-zero data prior to sending
  const restructureDetails = onlyTruthyValues({
    principal_write_off: Number(writeOffAmount)
  });

  const rescheduleData = {
    firstRepaymentDate: formatIsoDate(firstRepaymentDate),
    productId,
    guarantors,
    collaterals,
    loanDuration,
    loanInstallments,
    repaymentPeriod,
    repaymentPeriodUnit,
    amount,
    disbursementMode,
    disbursementBankDetails,
    restructureDetails,
    loanClassificationId
  };

  const RescheduledLoan = Loan.reschedule(loan.id);
  const rescheduledLoan = new RescheduledLoan(rescheduleData);

  const didSave = await rescheduledLoan.save({
    with: ['collaterals', 'guarantors']
  });

  if (!didSave) {
    throw rescheduledLoan.errors;
  }
};

export const LoanReschedule = (props: Props) => {
  const { baseUrl, match, history } = props;
  const r = useFullLoan(match.params.loanId);
  const queryClient = useQueryClient();

  return (
    <Loadable {...r}>
      {loan => {
        const classifications = invoke('loanPurpose.classifications', loan);

        return loan.canReschedule() ? (
          <Wizard
            analyticsId="LoanReschedule"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan,
              loanId: loan.id,
              member: loan.member,
              guarantors: loan.guarantors,
              collaterals: loan.collaterals,
              firstName: loan.member.firstName,
              amount: loan.principal.balance,
              loanDuration: loan.loanDuration,
              product: loan.product,
              productId: loan.product.id,
              repaymentPeriod: loan.repaymentPeriod,
              repaymentPeriodUnit: loan.repaymentPeriodUnit,
              fullName: loan.member.fullName(),
              disbursementMode: loan.disbursementMode,
              classifications
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep={match.params.step}
            currentSubStep={match.params.subStep != null ? parseInt(match.params.subStep, 10) : null}
            onSubmit={rescheduleLoan}
            onSubmitCompletion={() => {
              // LoanRouteDecider uses useLoan hook, but LoanReschedule
              // uses useFullLoan. This ensures Loan data is
              // fetched again with useLoan after loan is rescheduled.
              queryClient.refetchQueries('loan');
            }}
            steps={steps(loan)}
            startId="review"
            titleId="LoanReschedule.shortTitle"
            type="approval"
          />
        ) : (
          <Redirect to={loanPath({ id: loan.id })} />
        );
      }}
    </Loadable>
  );
};
