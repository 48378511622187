import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useMember, useMemberLoans } from '@kwara/models/src/models/request/hooks';

import { Loadable } from '@kwara/components/src/Loadable';
import { MemberType, LoanType } from '@kwara/models/src';

import { WizardPageProps } from '..';
import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { useRepaymentForm } from './useRepaymentForm';

type Props = WizardPageProps<{ memberId: string }>;

export const MemberRepayment = (props: Props) => {
  const queryClient = useQueryClient();
  const r = useMember(props.match.params.memberId);
  const { makeRepayment, isTillLoading, isTillOpen } = useRepaymentForm();
  const loanR = useMemberLoans(props.match.params.memberId);

  return (
    <Loadable isLoading={isTillLoading}>
      <Loadable {...r}>
        {(member: MemberType) => (
          <Loadable {...loanR}>
            {(loans: LoanType[]) => {
              member.loans = loans;

              const redirectTo = memberPath({ id: member.id });
              const loansEligibleForRepayment = member.loansEligibleForRepayment();
              return loansEligibleForRepayment.length > 0 ? (
                <Wizard
                  analyticsId="MemberRepayment"
                  baseUrl={redirectTo}
                  history={history}
                  initialData={{
                    member,
                    accounts: loansEligibleForRepayment,
                    accountId: loansEligibleForRepayment[0].id,
                    isTillOpen
                  }}
                  cancelReturnsTo={redirectTo}
                  currentStep="repayment"
                  onSubmit={makeRepayment}
                  onSubmitCompletion={() => {
                    loanR.refetch();
                    queryClient.invalidateQueries('member_details_metrics');
                  }}
                  steps={steps}
                  startId="repayment"
                  titleId="LoanRepayment.title"
                />
              ) : (
                <Redirect to={redirectTo} />
              );
            }}
          </Loadable>
        )}
      </Loadable>
    </Loadable>
  );
};
