//@flow

import * as React from 'react';

import { AuthType } from '../../hooks';
import { VerifyMfa } from '../../components/Auth/VerifyMFA';
import type { SaccoProfileContextType } from './components/AuthorizedProfileProvider';
import {
  AuthorizedProfileProvider,
  SaccoProfileConsumer,
  useSaccoProfileContext
} from './components/AuthorizedProfileProvider';

type Props = {
  children: React.ReactNode,
  auth: AuthType
};

function SaccoProfileProvider({ auth, children }: Props) {
  const [isMfaTrusted, setIsMfaTrusted] = React.useState(auth.isMfaVerified());
  const onVerify = auth => setIsMfaTrusted(auth.isMfaVerified());

  if (!auth.isLoggedIn()) {
    return children;
  }

  if (auth.isMfaActivated() && !isMfaTrusted) {
    return <VerifyMfa onVerify={onVerify} />;
  }

  return <AuthorizedProfileProvider>{children}</AuthorizedProfileProvider>;
}

/*For Legacy purpose, we will leave this here for like 6months<march 2022 to august 2022>
then we can erase it ones we are done with all refactoring*/
const withProfile = Component => props => (
  <SaccoProfileConsumer>{profileData => <Component {...profileData} {...props} />}</SaccoProfileConsumer>
);

export type { SaccoProfileContextType };
export { SaccoProfileProvider, withProfile, useSaccoProfileContext };
