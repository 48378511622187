import * as React from 'react';

export function IconSearch() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0001 14L11.1048 11.1047M11.1048 11.1047C11.6001 10.6094 11.9929 10.0215 12.261 9.37438C12.529 8.72729 12.6669 8.03375 12.6669 7.33335C12.6669 6.63295 12.529 5.9394 12.261 5.29232C11.9929 4.64523 11.6001 4.05727 11.1048 3.56202C10.6095 3.06676 10.0216 2.6739 9.3745 2.40586C8.72742 2.13783 8.03387 1.99988 7.33347 1.99988C6.63307 1.99988 5.93953 2.13783 5.29244 2.40586C4.64535 2.6739 4.0574 3.06676 3.56214 3.56202C2.56192 4.56224 2 5.91882 2 7.33335C2 8.74787 2.56192 10.1045 3.56214 11.1047C4.56236 12.1049 5.91895 12.6668 7.33347 12.6668C8.748 12.6668 10.1046 12.1049 11.1048 11.1047Z"
        stroke="#1E293B"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
