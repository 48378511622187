import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import Visible from '../../components/Visible';
import { Indicators } from './components/Indicators';
import { Deposits } from './components/Deposits';
import { BodyContentWrapper } from '../../layouts';
import { MemberBase } from './components/MemberBase';
import { AppPermissions } from '../../models/Permission';
import { MobileBankingUsers } from './components/MobileBankingUsers';
import { LoanDisbursements } from './components/LoanDisbursements';
import { DistributionOfDeposits } from './components/DistributionOfDeposits';
import { DistributionOfLoans } from './components/DistributionOfLoans';
import { IncomeAndExpense } from './components/IncomeAndExpense';
import { AgeDemographic } from './components/AgeDemographic';
import { VisibleOnlyToSandbox } from '../../components/VisibleOnlyToSandbox';

import styles from './index.module.scss';

function ChartContainer({
  title,
  children,
  ariaLabel
}: {
  title: string;
  children: React.ReactNode;
  ariaLabel: string;
}) {
  return (
    <div className={styles['chart-container']}>
      <div className="kw-text-x-large kw-weight-bold neutral-600" aria-label={ariaLabel}>
        <Text id={title} />
      </div>
      {children}
    </div>
  );
}

export function Dashboard() {
  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Indicators>
          <Indicators />
        </BodyContentWrapper.Row.Indicators>
      </BodyContentWrapper.Row.Root>

      <BodyContentWrapper.Row.Root>
        <div className={styles['container']}>
          <div className={styles['big-charts']}>
            <ChartContainer title="Dashboard.MemberBase.title" ariaLabel="Member base">
              <Visible to={AppPermissions.ViewMemberBaseStats} fallback={<PermissionDenied />}>
                <MemberBase />
              </Visible>
            </ChartContainer>

            <ChartContainer title="Dashboard.Deposits.title" ariaLabel="Deposits">
              <Visible to={AppPermissions.ViewSavingsTransactionsStats} fallback={<PermissionDenied />}>
                <Deposits />
              </Visible>
            </ChartContainer>

            <VisibleOnlyToSandbox>
              <ChartContainer title="Dashboard.IncomeAndExpense.title" ariaLabel="Income and Expense">
                <Visible to={AppPermissions.ViewDashboardIndicators} fallback={<PermissionDenied />}>
                  <IncomeAndExpense />
                </Visible>
              </ChartContainer>
            </VisibleOnlyToSandbox>

            <ChartContainer title="Dashboard.LoanDisbursements.title" ariaLabel="Loan disbursements">
              <Visible to={AppPermissions.ViewLoanDisbursementsStats} fallback={<PermissionDenied />}>
                <LoanDisbursements />
              </Visible>
            </ChartContainer>
          </div>
          <div className={styles['small-charts']}>
            <ChartContainer title="Dashboard.AgeDemographic.title" ariaLabel="Age demographic">
              <Visible to={AppPermissions.ViewDashboardIndicators} fallback={<PermissionDenied />}>
                <AgeDemographic />
              </Visible>
            </ChartContainer>

            <ChartContainer title="Dashboard.DistributionOfDeposits.title" ariaLabel="Distribution of deposits">
              <Visible to={AppPermissions.ViewDashboardIndicators} fallback={<PermissionDenied />}>
                <DistributionOfDeposits />
              </Visible>
            </ChartContainer>

            <ChartContainer title="Dashboard.DistributionOfLoans.title" ariaLabel="Distribution of loans">
              <Visible to={AppPermissions.ViewDashboardIndicators} fallback={<PermissionDenied />}>
                <DistributionOfLoans />
              </Visible>
            </ChartContainer>

            <ChartContainer title="Dashboard.MobileBankingUsers.title" ariaLabel="Mobile banking users">
              <Visible to={AppPermissions.ViewMobileBankingUsersStats} fallback={<PermissionDenied />}>
                <MobileBankingUsers />
              </Visible>
            </ChartContainer>
          </div>
        </div>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
