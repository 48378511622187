import React from 'react';

export function useCloseOnOutsideClick(onClose: () => void) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const body = document.body;
    const outsideClick = (e: MouseEvent) => {
      if (ref.current) {
        const isOutside = !ref.current.contains(e.target);
        if (isOutside) {
          onClose();
        }
      }
    };

    if (body) {
      body.addEventListener('click', outsideClick, true);
    }

    return () => {
      if (body) {
        body.removeEventListener('click', outsideClick, true);
      }
    };
  }, [onClose]);

  return ref;
}
