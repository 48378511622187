// @flow
import * as React from 'react';

import { appName } from '@kwara/lib/src/utils';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import typeof { Child } from '../../../../components/Stack';

type Props = {
  titleId: string,
  subtitleId: string,
  buttonTextId: string,
  enabled: boolean,
  isSearching: boolean,
  onAdd: () => void,
  memberName: string,
  StackChild: Child
};

const getAddButton = (onAdd, isSearching, buttonTextId) => {
  return appName.isMember ? (
    <div className={isSearching ? 'dn' : 'db'}>
      <Link size="regular" type="primary" onClick={onAdd} disabled={isSearching}>
        <Text id={buttonTextId} />
      </Link>
    </div>
  ) : (
    <Button className="flex-none ml3" disabled={isSearching} size="regular" type="secondary" onClick={onAdd}>
      <Text id={buttonTextId} />
    </Button>
  );
};
export const Header = ({
  titleId,
  subtitleId,
  buttonTextId,
  StackChild,
  enabled = false,
  isSearching,
  onAdd,
  memberName
}: Props) => (
  <StackChild>
    <div className="pb4 flex justify-between items-center">
      {appName.isSacco ? (
        <div>
          <h3 className="ma0">
            <Text id={titleId} values={{ memberName }} />
          </h3>
          <p className="ma0 grey-400">
            <Text id={subtitleId} />
          </p>
        </div>
      ) : null}
      {enabled && getAddButton(onAdd, isSearching, buttonTextId)}
    </div>
  </StackChild>
);
