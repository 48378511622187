import * as React from 'react';
import map from 'lodash/fp/map';

import { Currency } from '@kwara/components/src/Intl';

import Table, { Row, Cell, Heading, Footer } from '../../../components/Table';

export const TablePreview = ({ data }) => {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="DirectDebitReportGenerate.Table.payerName.header" />
          <Heading translationId="DirectDebitReportGenerate.Table.total.header" />
          <Heading translationId="DirectDebitReportGenerate.Table.memberNumber.header" />
          <Heading translationId="DirectDebitReportGenerate.Table.account.header" />
          <Heading translationId="DirectDebitReportGenerate.Table.bankName.header" />
          <Heading translationId="DirectDebitReportGenerate.Table.branch.header" />
        </Row>
      }
      footer={<Footer colSpan={6} items={data} />}
    >
      {map(
        dd => (
          <Row key={dd.id}>
            <Cell>{dd.fullName()}</Cell>
            <Cell>
              <Currency value={dd.amountPlusFee()} />
            </Cell>
            <Cell>{dd.memberNo}</Cell>
            <Cell>{dd.bankAccount.accountNumber}</Cell>
            <Cell>{dd.bankAccount.bankBranch.bank.name}</Cell>
            <Cell>{dd.bankAccount.bankBranch.name}</Cell>
          </Row>
        ),
        data
      )}
    </Table>
  );
};
