// @flow

import React from 'react';
import cx from 'classnames';

import styles from './Toggle.module.scss';

export type TogglePropsT = {
  name: string,
  hidden?: boolean,
  checked?: boolean,
  disabled?: boolean,
  onChange?: (evt: { target: { value?: string, checked?: boolean } }) => void,
  onBlur?: (evt: { target: { value: string } }) => void
};

export const Toggle = ({ name, hidden, checked, disabled, onChange, onBlur }: TogglePropsT) => {
  return (
    <label className={styles.switch}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        hidden={hidden}
      />
      <span className={cx(styles.slider, styles.round)}></span>
    </label>
  );
};
