// @flow

import * as React from 'react';

import classnames from 'classnames';

import styles from './index.module.css';

type Props = {
  className?: string,
  children: any,
  widthClass: string,
  border?: boolean,
  compact?: boolean,
  childClasses?: string
};

export default ({
  className,
  children,
  widthClass,
  childClasses = 'pt3 mr4',
  border = false,
  compact = false
}: Props) => {
  const classes = ['flex', compact ? styles.compact : null];
  const borderClasses = [border ? 'bt b--light-grey-400' : null];

  return (
    <div className={classnames(styles.StatisticRow, classes, className)}>
      {React.Children.toArray(children)
        .filter(stat => stat != null)
        .map((stat, index) => (
          <div key={index} className={classnames(widthClass, childClasses, borderClasses)}>
            {stat}
          </div>
        ))}
    </div>
  );
};
