import * as React from 'react';

import { MemberType } from '@kwara/models/src';

import { Container } from '../../../../../../../layouts';
import { DocumentList } from '../../../../../../../components/DocumentList';

export function Attachments({ member }: { member: MemberType }) {
  return (
    <Container.Root>
      <Container.Heading id="LoanAppraisal.IncomeAndSecurity.Attachments.heading" />

      <Container.Body>
        <DocumentList
          member={member}
          contexts={['income']}
          attachments={member.attachments}
          fallbackId="LoanAppraisal.IncomeAndSecurity.Attachments.noAttachment"
        />
      </Container.Body>
    </Container.Root>
  );
}
