import * as React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import isEmpty from 'lodash/fp/isEmpty';

import Empty from '@kwara/components/src/Empty';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text, Currency } from '@kwara/components/src/Intl';

import { SubsectionTitle } from '../../../components/DetailSubsection';
import Table, { Cell, Row, Heading, Collapsible } from '../../../components/Table';

export const DirectDebitOrders = ({ ddOrders }) => {
  if (isEmpty(ddOrders)) {
    return (
      <Empty>
        <Text id="MemberDetail.ddOrders.empty" />
      </Empty>
    );
  }

  return (
    <>
      <SubsectionTitle>
        <Text values={{ val: size(ddOrders) }} id="MemberDetail.ddOrders.title" />
      </SubsectionTitle>
      <Table
        ariaLabel="Direct debit orders table"
        heading={
          <Row>
            <Heading translationId="MemberDetail.ddOrders.header.collectingBank" />
            <Heading width="100px" translationId="MemberDetail.ddOrders.header.day" />
            <Heading translationId="MemberDetail.ddOrders.header.account" />
            <Heading align="right" width="80px" translationId="MemberDetail.ddOrders.header.total" />
            {/* <Heading iconSpacer /> */}
          </Row>
        }
      >
        {map(ddOrder => {
          return (
            <Collapsible key={ddOrder.id}>
              <Row>
                <Cell>{get('collectingBank.name', ddOrder)}</Cell>
                <Cell>{ddOrder.collectionDay}</Cell>
                <Cell>{invoke('bankAccount.display', ddOrder)}</Cell>
                <Cell align="right">
                  <Currency value={ddOrder.amount} />
                </Cell>
              </Row>
              {map(remittance => {
                return (
                  <Row className="bg-light-grey-300" key={remittance.id}>
                    <Cell align="right" colSpan={2}>
                      <span className="grey-400">{remittance.accountId}</span>
                    </Cell>
                    <Cell>{remittance.productName}</Cell>
                    <Cell align="right">
                      <Currency value={remittance.amount} />
                    </Cell>
                    <Cell align="right">
                      <StatusTag state={remittance.accountState} />
                    </Cell>
                  </Row>
                );
              }, ddOrder.remittances)}
            </Collapsible>
          );
        }, ddOrders)}
      </Table>
    </>
  );
};
