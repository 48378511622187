import * as React from 'react';

import { ReconciliationStatement } from '@kwara/models/src';
import { getCurrentDate } from '@kwara/lib/src/dates';

import Wizard from '../../components/Wizard';
import { steps } from './config';

const onGenerate = async data => ReconciliationStatement.uploadStatement(data);

export const ReconciliationsAdd = ({ baseUrl }) => {
  return (
    <Wizard
      analyticsId="ReconciliationsAdd"
      baseUrl={baseUrl}
      initialData={{
        from: getCurrentDate(),
        to: getCurrentDate()
      }}
      cancelReturnsTo={baseUrl}
      currentStep="create"
      onSubmit={onGenerate}
      steps={steps}
      startId="create"
      titleId="ReconciliationsAdd.title"
    />
  );
};
