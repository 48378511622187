import { useState, useEffect } from 'react';

import { ERROR_TYPES } from '../../../../../lib/errorTypes';

export function useTwoThirdsRuleModal(error: Partial<Record<'message', string>>, clearError: () => void) {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    if (error?.message === ERROR_TYPES.INCOME_TOO_LOW) {
      setIsOpen(true);
      clearError();
    }
  }, [error, clearError]);

  return { isOpen, onClose };
}
