import * as React from 'react';
import { Contact as ContactBase } from '../../../../../../../MemberAdd/components/Contact';

export function Contact(props) {
  return <ContactBase {...props} size="stretch" prefix="member" />;
}

Contact.validate = {
  'member.phoneNumber': { isRequired: () => true, phoneNumber: true },
  'member.secondaryPhoneNumber': { phoneNumber: true },
  'member.email': { isRequired: () => false, email: true }
};
