import * as React from 'react';
import compact from 'lodash/fp/compact';

import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';
import { Tooltip } from '@kwara/components/src/Tooltip';
import { TransactionState } from '@kwara/models/src/models/Transaction';

import OverviewListV2 from '../../../../components/OverviewListV2';

import { getButtonUtils } from '../utils/getButtonUtils';
import { TransactionsTable } from './TransactionTable';

const EventButton = ({ isFilterByPending, disable, hasSelected, numberSelected, onApprove, onReject }) => {
  const isApproval = !!onApprove;
  const onClick = onApprove || onReject;

  if (!onClick) {
    throw 'Missing onClick for transaction approvals';
  }

  return (
    <Tooltip
      noIcon
      tooltipId={!isFilterByPending ? `Finance.Transactions.List.${isApproval ? 'Confirm' : 'Reject'}.warning` : null}
    >
      <Button type={isApproval ? 'primary' : 'destructive'} disabled={disable} onClick={onClick}>
        <Text
          id={`Finance.Transactions.List.${onApprove ? 'Confirm' : 'Reject'}${hasSelected ? '' : '.all'}`}
          values={{ selected: numberSelected }}
        />
      </Button>
    </Tooltip>
  );
};

export const TransactionsList = ({
  transactions,
  values,
  filterBy,
  onApprove,
  onReject,
  totalResults,
  isLoading,
  ...rest
}) => {
  const { disable, isFilterByPending, hasSelected, numberSelected } = getButtonUtils({
    filterBy,
    data: transactions,
    values: values.transactions,
    state: TransactionState.PENDING
  });

  const primaryConfirmButton = onApprove ? (
    <EventButton
      key="confirm"
      isFilterByPending={isFilterByPending}
      disable={disable}
      hasSelected={hasSelected}
      numberSelected={numberSelected}
      onApprove={onApprove}
    />
  ) : null;

  const primaryRejectButton = onReject ? (
    <EventButton
      key="reject"
      isFilterByPending={isFilterByPending}
      disable={disable}
      hasSelected={hasSelected}
      numberSelected={numberSelected}
      onReject={onReject}
    />
  ) : null;

  return (
    <OverviewListV2
      withFilter
      items={transactions}
      filterDisabled={isLoading}
      labelId="Finance.Transactions.List.label"
      totalNumResults={totalResults || transactions.length}
      actions={compact([primaryConfirmButton, primaryRejectButton])}
      table={<TransactionsTable canReview={onApprove || onReject} transactions={transactions} {...rest} />}
    />
  );
};
