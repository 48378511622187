import React from 'react';
import some from 'lodash/fp/some';

import { IncludesT } from '@kwara/models/src';
import { SasraReport } from '@kwara/models/src/models/SasraReport';

import { useOverviewList, fetchWithPagination } from '../../models/hooks/base';
import { useThrottledCallback } from '../../hooks';

const defaultIncludes = ['created_by'];
async function fetchSasraReports(opts) {
  return fetchWithPagination(SasraReport, opts);
}

export function usePaginatedSasraReports(includes: IncludesT = defaultIncludes) {
  const result = useOverviewList({
    queryKey: 'sasra_reports',
    fetchFn: fetchSasraReports,
    includes,
    select: null
  });

  const refetchIfPending = React.useCallback(() => {
    if (some(report => report.state === 'pending', result.allData)) {
      result.refetch();
    }
  }, [result]);

  useThrottledCallback(refetchIfPending);

  return result;
}
