import * as React from 'react';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';

import { type SavingType, type SavingState, SavingStates } from '@kwara/models/src';

import { savingPath } from '../../../../lib/urls';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import OverviewList from '../../../../components/OverviewList';
import Visible from '../../../../components/Visible';
import ListTable from '../ListTable';
import { usePermissions } from '../../../../hooks';
import { useAuth } from '../../../../hooks';

type Props = {
  errors: Object[],
  filterBy: ?SavingState,
  hasMore: boolean,
  items: SavingType[],
  loading: boolean,
  onFilterChange: (filterBy: SavingState) => void,
  onLoadMoreData: () => void,
  totalNumResults: ?number
};

const SavingStateFilters = [
  { value: '', translationId: 'SavingList.noFilter' },
  ...map(SavingStates, value => ({
    value,
    translationId: `StatusTag.${value}`
  }))
];

// TODO: What's the best way to handle application-level
//       routing?
const AddSavingButton = withRouter(() => (
  <Button size="regular" type="primary" to={savingPath({ action: 'create' })}>
    <Text id="SavingList.addSaving" />
  </Button>
));

const AddSavingButtonWithPermission = () => {
  const { AppPermissions } = usePermissions();

  return (
    <Visible to={AppPermissions.AddSavings}>
      <AddSavingButton />
    </Visible>
  );
};

export default function List({
  errors,
  filterBy,
  hasMore = false,
  items = [],
  loading = false,
  onLoadMoreData,
  onFilterChange,
  totalNumResults
}: Props) {
  const auth = useAuth();
  const isV1 = auth.isV1();

  return (
    <OverviewList
      actions={[<AddSavingButtonWithPermission key="create-saving-button" />]}
      filters={isV1 ? undefined : SavingStateFilters}
      filterBy={filterBy}
      filterDisabled={loading}
      headerId="SavingList.heading"
      labelId="SavingList.filteredCount"
      items={items}
      onFilterChange={onFilterChange}
      table={
        <ListTable
          groupBy={/*Groups.state*/ null}
          hasMore={hasMore}
          loading={loading}
          errors={errors}
          savings={items}
          orderBy={/*Orders.updated*/ null}
          onLoadMoreData={onLoadMoreData}
        />
      }
      totalNumResults={totalNumResults}
    />
  );
}
