import React from 'react';

import { ReportTemplateT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import { BodyContentWrapper } from '../../layouts';
import { TemplatesTable } from './components/Table/TemplatesTable';
import { useReportTemplates } from '../../models/hooks/useReportTemplates/useReportTemplates';

export function SasraReportTemplatesLayout({
  isLoading,
  templates = []
}: {
  isLoading: boolean;
  templates: ReportTemplateT[];
}) {
  return (
    <BodyContentWrapper.Root headId="SasraReportTemplates.title">
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <div className="kw-text-x-large kw-weight-bold pa3">
            <Text id="SasraReportTemplates.title" />
          </div>
          <TemplatesTable templates={templates} isLoading={isLoading} />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

export function SasraReportTemplates() {
  const r = useReportTemplates();

  return <SasraReportTemplatesLayout templates={r.data} isLoading={r.isLoading} />;
}
