import React from 'react';

import { Metric } from '@kwara/models/src';

import { useSavingsOverviewMetrics } from '../../../../models/hooks';
import { DashboardMetricCard } from '../../../../components/DashboardMetric';
import { IndicatorsContainer } from '../../../../components/IndicatorsContainer';

export function Indicators() {
  const r = useSavingsOverviewMetrics();

  return (
    <IndicatorsContainer>
      <DashboardMetricCard
        r={r}
        titleId={'Savings.Dashboard.totalSavings'}
        metric={metrics => Metric.findFromArray(metrics, 'total_savings')}
      />
      <DashboardMetricCard
        r={r}
        titleId={'Savings.Dashboard.accruedInterest'}
        metric={metrics => Metric.findFromArray(metrics, 'accrued')}
      />
      <DashboardMetricCard
        r={r}
        titleId={'Savings.Dashboard.savingFrequency'}
        metric={metrics => Metric.findFromArray(metrics, 'saving_frequency')}
      />
      <DashboardMetricCard
        r={r}
        titleId={'Savings.Dashboard.averageDeposit'}
        metric={metrics => Metric.findFromArray(metrics, 'average_deposit')}
      />
    </IndicatorsContainer>
  );
}
