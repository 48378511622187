import React from 'react';
import cx from 'classnames';
import get from 'lodash/fp/get';

import { If } from '@kwara/components/src/If/If';
import StatusTag from '@kwara/components/src/StatusTag';
import { LoanType, LoanProductType } from '@kwara/models/src';
import { Currency, Text, Duration, Percent } from '@kwara/components/src/Intl';

import { InfoPanel } from '../InfoPanel';
import { ProductCard } from '../ProductCard';

import styles from './index.module.scss';

const LoanDetails = ({ loanProduct, isV1 = false }: LoanProductType) => (
  <div className={styles.LoanDetails}>
    <div className="pl4 pt4">{loanProduct.name}</div>
    <ul className={cx('kw-text-small mobile-neutral-600 tl pv3')}>
      <li>
        <Text
          id="LoanCard.maxPrincipal"
          values={{ maxPrincipal: <Currency format={'currency'} value={loanProduct?.amount?.maximum} /> }}
        />
      </li>
      <li>
        <Text
          id="LoanCard.minPrincipal"
          values={{ minPrincipal: <Currency format={'currency'} value={loanProduct?.amount?.minimum} /> }}
        />
      </li>
      {isV1 ? null : (
        <li>
          <Text
            id="LoanCard.eligibleAmount"
            values={{
              eligibleAmount: <Currency format={'currency'} value={get('eligibility.eligibleAmount', loanProduct)} />
            }}
          />
        </li>
      )}
      <li>
        <Text
          id="LoanCard.interestRate"
          values={{ interestRate: <Percent value={loanProduct.interestRate.percentage / 100} /> }}
        />
      </li>
      <li>
        <Text
          id="LoanCard.maxDuration"
          values={{ maxDuration: loanProduct.maxDuration ? <Duration value={loanProduct.maxDuration} /> : '' }}
        />
      </li>
      <li>
        <Text
          id="LoanCard.minDuration"
          values={{ minDuration: loanProduct.minDuration ? <Duration value={loanProduct.minDuration} /> : '' }}
        />
      </li>
      {isV1 ? null : (
        <li>
          <Text id={`LoanCard.guarantors.${loanProduct.security.guarantors_enabled}`} />
        </li>
      )}
      {isV1 ? null : (
        <li>
          <Text id={`LoanCard.collaterals.${loanProduct.security.collaterals_enabled}`} />
        </li>
      )}
    </ul>
  </div>
);

type Props = {
  loan: LoanType;
  amount?: string | number;
  isLoanProductData?: boolean;
  showState?: boolean;
  showInfo?: boolean;
  className?: string;
  isV1?: boolean;
};

export const LoanCard = ({
  loan,
  amount,
  showState = false,
  showInfo = false,
  isLoanProductData = false,
  className,
  isV1 = false
}: Props) => {
  const defaultDisplayAmount = isLoanProductData ? loan.amount.default : loan.totalBalance;

  return (
    <ProductCard
      amount={<Currency format="currency" value={amount || defaultDisplayAmount} />}
      className={className}
      title={loan.name}
      state={<If condition={showState} do={<StatusTag state={loan.state?.current} size="small" />} />}
      info={
        <If
          condition={showInfo}
          do={
            <InfoPanel>
              <LoanDetails loanProduct={isLoanProductData ? loan : loan.product} isV1={isV1} />
            </InfoPanel>
          }
        />
      }
    />
  );
};
