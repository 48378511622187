import * as React from 'react';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';

import { useAppRoles } from '../../../../hooks';
import { settingPath } from '../../../../lib/urls';

import { Header } from '../Header';
import { RoleCard } from './RoleCard';
import { ConfirmDelete } from './ConfirmDelete';
import { BodyContentWrapper } from '../../../../layouts';

const includes = ['app_permissions', 'users'];
export const AppRoles = props => {
  const { refetch, ...r } = useAppRoles(includes);

  const [errors, setErrors] = React.useState(null);
  const [candidateToDelete, setCandidateToDelete] = React.useState(null);

  const onClose = () => {
    setCandidateToDelete(null);
    setErrors(null);
  };

  const deleteAppRole = async appRole => {
    const didDestroy = await appRole.destroy();

    if (!didDestroy) {
      setErrors(appRole.errors);
    } else {
      refetch();
      onClose();
    }
  };

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <ConfirmDelete
            isOpen={!!candidateToDelete}
            onClose={onClose}
            onSubmit={() => deleteAppRole(candidateToDelete)}
            errors={errors}
          />
          <Header
            titleId="Settings.AppRoles.titleId"
            subtitleId="Settings.AppRoles.subtitleId"
            action={
              <Button to={settingPath({ baseExtension: 'roles', action: 'new' })} type="primary">
                <Text id="Settings.AppRoles.add" />
              </Button>
            }
          />
          <Loadable {...r}>
            {appRoles => (
              <div className="mt4">
                {map(
                  role => (
                    <RoleCard
                      deleteAppRole={() => setCandidateToDelete(role)}
                      key={role.id}
                      appRole={role}
                      {...props}
                    />
                  ),
                  appRoles
                )}
              </div>
            )}
          </Loadable>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
