import React from 'react';
import map from 'lodash/fp/map';
import { useIntl } from 'react-intl';

import { LoanSubstepProps } from 'GlobalTypes';

import { Field } from '@kwara/components/src/Form';
import { PeriodUnits } from '@kwara/models/src';
import { ChargeFrequencies } from '@kwara/models/src/models/LoanProduct';

import { validateLoanAmount } from '../../utils/validateLoanAmount';
import { LoanRepaymentFields } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields';
import { DurationField } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields/DurationField';
import { useLoanRepaymentFields } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields/useLoanRepaymentFields';

const sharedCurrencyProps = {
  required: true,
  isCurrency: true,
  type: 'number',
  leftGlyph: 'Currency.orgCurrency'
};

function TopupAmountAndAccountTerms(props: LoanSubstepProps) {
  const intl = useIntl();

  const {
    data: {
      loan: { product, amount, loanDuration, feeApplications, repaymentPeriodUnit }
    },
    SelectField,
    TextField
  } = props;

  const { fixedRepayment } = useLoanRepaymentFields({
    product,
    amount,
    feeApplications,
    loanDuration,
    repaymentPeriodUnit
  });

  return (
    <>
      <TextField
        {...sharedCurrencyProps}
        name="topupAmount"
        labelId="LoanTopup.TopupAmountAndAccountTerms.topupAmount"
      />
      <TextField
        {...sharedCurrencyProps}
        name="loan.principal.balance"
        labelId="LoanTopup.TopupAmountAndAccountTerms.currentPrincipalBal"
        readOnly
        disabled
      />

      <TextField
        name="loan.product.interestRate.percentage"
        labelId="LoanTopup.TopupAmountAndAccountTerms.interestRate"
        required={true}
        rightGlyph={intl.formatMessage({ id: 'General.%' })}
        values={{
          frequency: intl.formatMessage({
            id: `LoanProductAdd.Product.interestChargeFrequency.${product.interestRate.interest_charge_frequency ??
              ChargeFrequencies.ANNUALIZED}`
          })
        }}
        disabled
        readOnly
      />

      <Field
        margin={false}
        name="repaymentPeriod"
        size="medium"
        required={true}
        disabled={fixedRepayment}
        labelId="LoanAdd.Configure.Repayment.label"
      >
        <div className="flex">
          <TextField disabled={fixedRepayment} size="medium" name="repaymentPeriod" />
          <SelectField disabled={fixedRepayment} name="repaymentPeriodUnit" size="medium">
            {map(
              unit => (
                <SelectField.Option key={unit} translationId={`LoanAdd.Configure.Repayment.${unit}`} value={unit} />
              ),
              PeriodUnits
            )}
          </SelectField>
        </div>
      </Field>

      <DurationField {...props} />
    </>
  );
}

export default {
  Component: TopupAmountAndAccountTerms,
  validate: data => ({
    topupAmount: {
      isRequired: () => true,
      currency: true,
      nonZero: true,
      custom: validateLoanAmount
    },
    repaymentPeriod: LoanRepaymentFields.validate(data).repaymentPeriod,
    loanDuration: LoanRepaymentFields.validate(data).loanDuration
  })
};
