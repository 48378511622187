import { attr, hasMany } from 'spraypaint';
import map from 'lodash/fp/map';
import queryString from 'query-string';

import Base from './Base';
import { PeriodUnitsT } from '..';
import Repayment, { RepaymentType } from './Repayment';
import { snakeCaseObjectKeys } from '../lib/snakeCaseObjectKeys';

function toRepayments(arr: any[]) {
  return map<any, RepaymentType>(r => new Repayment(r.attributes), arr);
}

type PreviewParams = {
  amount: string;
  firstRepaymentDate: string;
  anticipatedDisbursementDate: string;
  repaymentInstallments: number;
  repaymentPeriod: number;
  repaymentPeriodUnit: PeriodUnitsT;
  interestRate: number;
};

export const SchedulesPreview = Base.extend({
  static: {
    jsonapiType: 'schedules_preview',
    async generate(productId: string, params: PreviewParams) {
      const queries = queryString.stringify(snakeCaseObjectKeys(params));
      const url = `${SchedulesPreview.url()}/${productId}?${queries}`;

      const options = {
        ...SchedulesPreview.fetchOptions(),
        method: 'GET'
      };

      try {
        const res = await window.fetch(url, options);
        const json = await res.json();
        if (!res.ok) {
          throw json;
        }
        const { relationships } = json.data;
        const { repayments } = relationships;
        return new SchedulesPreview({
          repayments: toRepayments(repayments.data)
        });
      } catch (err) {
        err.messages = err.errors;
        throw err;
      }
    }
  },

  attrs: {
    totalDue: attr(),
    repayments: hasMany()
  }
});

export type SchedulePreviewT = {
  totalDue: string;
  repayments: Array<RepaymentType>;
};
