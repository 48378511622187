import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';

import { Text } from '@kwara/components/src/Intl';
import { LoanType } from '@kwara/models/src';

import { loanPath } from '../../../lib/urls';
import { ButtonMenu } from '../../../components/ButtonMenu';
import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';
import { usePermissions } from '../../../hooks';

export const LoanButtonMenu = ({ loan }: { loan: LoanType }) => {
  const store = useSaccoProfileContext();
  const { permission, AppPermissions } = usePermissions();

  const disableWriteOff = !loan.canWriteOff();
  const disableReschedule = !loan.canReschedule();
  const disableRefinance = !loan.canRefinance();
  const disablePayOff = !loan.canMakeRepayment();
  const disableAddFee = !loan.canAddFee();
  const disableTopup = !loan.canTopup();
  const disableUndoTopup = !loan.canUndoTopup();

  const menuItems = [
    permission.to(AppPermissions.WriteOffLoans) ? (
      <ButtonMenu.Item key="loanWriteOff" disabled={disableWriteOff} to={loanPath({ id: loan.id, action: 'writeoff' })}>
        <Text id="LoanDetail.more.WriteOff" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.RescheduleLoans) ? (
      <ButtonMenu.Item
        key="loanReschedule"
        disabled={disableReschedule}
        to={loanPath({ id: loan.id, action: 'reschedule' })}
      >
        <Text id="LoanDetail.more.Reschedule" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.RefinanceLoans) && store.refinanceEnabled ? (
      <ButtonMenu.Item
        key="loanRefinance"
        disabled={disableRefinance}
        to={loanPath({ id: loan.id, action: 'refinance' })}
      >
        <Text id="LoanDetail.more.Refinance" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.PayOffLoan) ? (
      <ButtonMenu.Item key="payOff" disabled={disablePayOff} to={loanPath({ id: loan.id, action: 'payOff' })}>
        <Text id="LoanDetail.more.PayOff" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddPenalties) ? (
      <ButtonMenu.Item key="addPenaltyFee" disabled={disableAddFee} to={loanPath({ id: loan.id, action: 'fee' })}>
        <Text id="LoanDetail.FeesAndPenalties.addPenaltyFee" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.TopupLoan) ? (
      <ButtonMenu.Item key="topup" disabled={disableTopup} to={loanPath({ id: loan.id, action: 'topup' })}>
        <Text id="LoanDetail.more.Topup" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.TopupLoan) ? (
      <ButtonMenu.Item key="undoTopup" disabled={disableUndoTopup} to={loanPath({ id: loan.id, action: 'undoTopup' })}>
        <Text id="LoanDetail.more.UndoTopup" />
      </ButtonMenu.Item>
    ) : null
  ];

  const allActionsDisabled =
    disableWriteOff &&
    disableReschedule &&
    disableRefinance &&
    disablePayOff &&
    disableAddFee &&
    disableTopup &&
    disableUndoTopup;
  const disableButtonMenu = allActionsDisabled || isEmpty(compact(menuItems));

  return (
    <ButtonMenu disabled={disableButtonMenu} titleId="LoanDetail.more" type="secondary">
      {menuItems}
    </ButtonMenu>
  );
};
