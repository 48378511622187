// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import createValidator from '@kwara/lib/src/validator';

import { TransactionsList } from './TransactionsList';
import { Confirm } from './Modal';

const rules = {
  transactions: {
    custom: transactions => {
      if (size(transactions) > 0) {
        return null;
      }
      return 'empty';
    }
  }
};

const formEvents = Object.freeze({
  CLOSE: 'ON_CLOSE',
  APPROVE: 'ON_APPROVE',
  REJECT: 'ON_REJECT',
  ERROR: 'ON_ERROR',
  SUCCESS: 'ON_SUCCESS'
});

const initialState = {
  confirm: false,
  error: {},
  event: null
};

interface StateShape {
  applyToAll?: boolean;
  confirm: boolean;
  error: {};
  event: $Values<typeof formEvents>;
  totalResults?: number;
}

function reducer(state: StateShape, evt) {
  const { type, error } = evt;

  if (type === formEvents.APPROVE) {
    return {
      ...state,
      confirm: true,
      event: 'approve'
    };
  }

  if (type === formEvents.REJECT) {
    return {
      ...state,
      confirm: true,
      event: 'reject'
    };
  }

  if (type === formEvents.CLOSE) {
    return {
      confirm: false,
      event: null,
      error: {}
    };
  }

  if (type === formEvents.SUCCESS) {
    return {
      confirm: false,
      event: null,
      error: {}
    };
  }

  if (type === formEvents.ERROR) {
    return {
      ...state,
      error
    };
  }
}

export const TransactionsForm = ({
  transactions,
  filterBy,
  onRefetch,
  onNext,
  isLoading,
  hasMore,
  model: TransactionModel,
  totalResults,
  canReview
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { event } = state;

  const handleSubmit = async ({ transactions, notes }, form) => {
    const t = new TransactionModel();

    try {
      await t.transition({
        event,
        [TransactionModel.transactionsKey]: transactions,
        notes,
        apply_to_all: isEmpty(transactions)
      });
      onRefetch();
      dispatch({ type: 'ON_SUCCESS' });
      form.restart();
    } catch (e) {
      dispatch({ type: 'ON_ERROR', e });
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={createValidator(rules)}
      render={({ form, submitting, ...rest }) => {
        const selectedTransactions = get('transactions', rest.values);

        return (
          <>
            <Confirm
              isOpen={state.confirm}
              onClose={() => dispatch({ type: 'ON_CLOSE' })}
              onSubmit={form.submit}
              event={event}
              submitting={!!submitting}
              numberSelected={size(selectedTransactions)}
            />
            <TransactionsList
              onApprove={canReview ? () => dispatch({ type: 'ON_APPROVE' }) : null}
              onReject={canReview ? () => dispatch({ type: 'ON_REJECT' }) : null}
              transactions={transactions}
              onNext={onNext}
              hasMore={hasMore}
              isLoading={isLoading}
              totalResults={totalResults}
              filterBy={filterBy}
              {...rest}
            />
          </>
        );
      }}
    />
  );
};
