import React from 'react';
import ReactDOM from 'react-dom';
import focusWithin from 'ally.js/esm/style/focus-within';
import { pdfjs } from 'react-pdf';

import 'babel-polyfill';
import 'raf/polyfill'; // React and old IE support
import 'url-search-params-polyfill';
import 'globalthis/polyfill';

import App from './App';
import { SaccoStore } from './saccostore';

// Polyfills the :focus-within CSS selector on IE/Edge
focusWithin();

//To setup appropriate pdf worker: https://www.npmjs.com/package/react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

ReactDOM.render(
  <React.StrictMode>
    <SaccoStore>
      <App />
    </SaccoStore>
  </React.StrictMode>,
  document.getElementById('root')
);
