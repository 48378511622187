import * as React from 'react';
import find from 'lodash/fp/find';

import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { DirectDebit } from '@kwara/models/src';
import { BankSelect } from '@kwara/components/src/Form/BankSelect';
import Button from '@kwara/components/src/Button';

import { Grid } from '../../../components/Grid';
import { SubscribedDatePicker as DatePicker, DayOfMonthPicker } from '../../../components/Form';
import { TablePreview } from './Table';

export const DDForm = ({ StackChild, TextField, data, onChange, addData, formProps }) => {
  const { values, valid } = formProps;
  const invalid = !valid;

  const [previewData, setData] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const r = { data: previewData, isLoading, errors };

  async function onPreview() {
    const { collectingBankId, dayOfMonth, fee, originatingReferencePrefix, startDate } = values;

    try {
      setIsLoading(true);
      setData([]);
      const res = await DirectDebit.where(
        snakeCaseObjectKeys({
          collectingBankId,
          dayOfMonth,
          fee,
          originatingReferencePrefix,
          startDate: formatIsoDate(startDate)
        })
      )
        .includes('bank_account.bank_branch.bank')
        .all();

      setData(res.data);
    } catch (err) {
      setErrors(err);
    } finally {
      setIsLoading(false);
    }
  }

  const { collectingBanks } = data;

  const getBank = bankId => find(bank => bank.id === bankId, collectingBanks);
  const handleChange = async e => {
    const bank = getBank(e.target.value);
    const newData = {
      fee: bank.directDebitFee,
      originatingReferencePrefix: bank.directDebitOriginatingReferencePrefix
    };
    await addData(newData);
    return await onChange(newData);
  };

  return (
    <StackChild size="wide">
      <BankSelect
        name="collectingBankId"
        banks={collectingBanks}
        labelId="DirectDebitReportGenerate.Form.collectingBank.label"
        inputOnChange={handleChange}
      />
      <Grid columns={2} border={false} width="w-50">
        <DatePicker required name="startDate" labelId="DirectDebitReportGenerate.Form.startDate.label" />
        <DayOfMonthPicker required name="dayOfMonth" />
      </Grid>
      <Grid columns={2} border={false} width="w-50">
        <TextField name="fee" labelId="DirectDebitReportGenerate.Form.fee.label" isCurrency type="number" />
        <TextField name="originatingReferencePrefix" labelId="DirectDebitReportGenerate.Form.prefix.label" />
      </Grid>
      <div className="mb3 tr">
        <Button aria-label="Preview" disabled={invalid} onClick={onPreview}>
          <Text id="DirectDebitReportGenerate.button.Preview" />
        </Button>
      </div>
      {previewData ? <Loadable {...r}>{directDebits => <TablePreview data={directDebits} />}</Loadable> : null}
    </StackChild>
  );
};

DDForm.validate = {
  collectingBankId: {
    isRequired: () => true
  },
  startDate: {
    isRequired: () => true
  },
  dayOfMonth: {
    isRequired: () => true
  }
};
