import * as React from 'react';

export function IconAdd() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6668 8.66536H8.66683V12.6654H7.3335V8.66536H3.3335V7.33203H7.3335V3.33203H8.66683V7.33203H12.6668V8.66536Z"
        fill="#1E293B"
      />
    </svg>
  );
}
