import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconKwaraLogoElement = React.ElementRef<'svg'>;
interface IconKwaraLogoPropTypes extends PrimitiveSvgPropTypes {}

export const IconKwaraLogo = React.forwardRef<IconKwaraLogoElement, IconKwaraLogoPropTypes>(function IconKwaraLogo(
  { width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      name="kwaraLogo"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1724 12.0863C13.4974 10.8736 14.0445 9.12424 13.8749 7.47578C13.7709 6.41703 13.3279 5.09098 12.579 4.25167C11.4142 2.93186 9.74807 2.54185 8.11729 2.74986C7.06997 2.8809 5.79385 3.15131 4.9431 3.9303C3.62017 5.14194 3.07727 6.38998 3.24264 8.03844C3.40801 9.6869 3.57753 10.7519 4.32844 11.5985C5.41424 12.8226 7.12198 13.4082 8.64563 13.3125C10.1693 13.2168 11.2312 12.9474 12.1724 12.0863ZM8.52499 15.95C6.68412 15.95 5.27799 15.1929 3.54113 13.7909C1.80427 12.3889 0.525024 10.0696 0.525024 7.67547C0.525024 6.01973 1.36329 4.69784 2.7611 2.75818C4.15891 0.818509 5.94465 0 8.53019 0C10.1942 0 11.8323 0.968274 13.0918 1.85855C15.0523 3.24491 16.475 5.31459 16.475 7.89076C16.475 9.54026 15.6794 11.4082 14.7839 12.6572C13.382 14.599 11.1168 15.95 8.52499 15.95Z"
        fill="#5D555C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33434 7.61946L7.22637 10.1563H6.40887L5.22827 7.37716L6.41094 7.37302L6.81762 8.33911L7.73678 6.2278L8.93294 6.23402L9.84173 8.33911L10.9227 5.79395H12.1127L10.2598 10.1563H9.44232L8.33434 7.61946Z"
        fill="#5D555C"
      />
    </svg>
  );
});

IconKwaraLogo.defaultProps = {
  width: '32',
  height: '32'
};
