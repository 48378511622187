import * as React from 'react';

import { LoanSubstepProps } from 'GlobalTypes';
import { If } from '@kwara/components/src/If/If';
import { isLower } from '@kwara/lib/src/validator/validators';

import { Container } from '../../../../layouts';
import { useCreditCheckContext } from './CreditCheckImp';
import { identityDocumentValue } from '../../../../lib/recordValidations';

///*********************************************************************************************/
type PassportId = 'PASSPORT' | 'passport';
type NationalId = 'NATIONAL' | 'national';

const ID_TYPES = {
  national: {
    lowercase: () => 'national',
    uppercase: () => 'NATIONAL'
  },
  passport: {
    lowercase: () => 'passport',
    uppercase: () => 'PASSPORT'
  }
};

export const checkIsNationalId = (identityType: NationalId) => identityType.toLowerCase() === 'national';

export const checkIsPassportId = (identityType: PassportId) => identityType.toLowerCase() === 'passport';

export const getRightNationalIdCasing = (identityType: NationalId | PassportId) => {
  if (isLower(identityType)) {
    return ID_TYPES.national.lowercase();
  }
  return ID_TYPES.national.uppercase();
};

export const getRightPassportIdCasing = (identityType: string) => {
  if (isLower(identityType)) {
    return ID_TYPES.passport.lowercase();
  }
  return ID_TYPES.passport.uppercase();
};

///*********************************************************************************************/
function CreditCheck({ TextField, RadioGroup, Checkbox, Condition, data }: LoanSubstepProps) {
  const { hasError } = useCreditCheckContext();
  return (
    <If
      condition={!hasError}
      do={
        <Container.Root>
          <Container.Heading id="LoanAppraisal.CreditCheck.metropolCreditReferenceBureau" />

          <Container.Body>
            <TextField name="fullName" labelId="LoanAppraisal.CreditCheck.memberName" required disabled />
            <RadioGroup
              name="identityType"
              labelId="LoanAppraisal.CreditCheck.idDoc"
              items={[
                { labelId: 'AddMember.Identity.Type.NATIONAL', value: getRightNationalIdCasing(data.identityType) },
                { labelId: 'AddMember.Identity.Type.PASSPORT', value: getRightPassportIdCasing(data.identityType) }
              ]}
              required
              disabled
            />
            <Condition when="identityType" evalCondition={checkIsNationalId}>
              <TextField
                name="identityValue"
                labelId="LoanAppraisal.CreditCheck.nationalIdNumber"
                placeholderId="AddMember.Identity.Value.NATIONAL.placeholder"
                required
                disabled
              />
            </Condition>
            <Condition when="identityType" evalCondition={checkIsPassportId}>
              <TextField
                name="identityValue"
                labelId="LoanAppraisal.CreditCheck.passportNumber"
                placeholderId="AddMember.Identity.Value.PASSPORT.placeholder"
                required
                disabled
              />
            </Condition>
            <Condition when="identityType" evalCondition={checkIsNationalId}>
              <Checkbox name="identityDetailsConfirm" labelId="LoanAppraisal.CreditCheck.SubmissionConfirm" required />
            </Condition>
            <Condition when="identityType" evalCondition={checkIsPassportId}>
              <Checkbox name="identityDetailsConfirm" labelId="LoanAppraisal.CreditCheck.SubmissionConfirm" required />
            </Condition>
          </Container.Body>
        </Container.Root>
      }
    />
  );
}

export default {
  Component: CreditCheck,
  validate: {
    identityType: {
      isRequired: () => true
    },
    identityValue: {
      isRequired: () => true,
      ...identityDocumentValue
    },
    identityDetailsConfirm: {
      isRequired: () => true
    }
  }
};
