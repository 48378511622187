// @flow

import * as React from 'react';

import { useQuery } from 'react-query';

import { LoadableBasePropsT } from '@kwara/components/src/Loadable';
import {
  Activity,
  AppPermission,
  AppRole,
  BatchTransaction,
  BatchTransactionImport,
  ConnectUser,
  CrbDataSubmission,
  DirectDebitReport,
  DirectDebitOrder,
  GeneralLedgerAccount,
  InvitationModel,
  JournalEntry,
  DisbursedLoan,
  LoanTransaction,
  LoanApprovalSetting,
  LoginActivity,
  MemberReport,
  Organisation,
  Profile,
  Role,
  Saving,
  SavingProduct,
  SavingsTransaction,
  TillSession,
  TillMetric,
  TillTransaction,
  TopupRequest,
  MambuTransactionChannel,
  MemberEligibility,
  MemberSavingsHabit,
  User,
  type AppPermissionT,
  type AppRoleT,
  type BatchTransactionT,
  type BatchTransactionImportT,
  type CrbDataSubmissionT,
  type DirectDebitReportT,
  type GeneralLedgerAccountT,
  type RoleT,
  type SavingType,
  type SavingProductType,
  type InvitationModelT,
  type JournalEntryT,
  type DisbursedLoanT,
  type LoanApprovalSettingT,
  type LoginActivityT,
  type MemberReportT,
  type OrganisationT,
  type TillMetricT,
  type TillSessionT,
  type TillTransactionType,
  type UserT,
  type IncludesT,
  type WhereT,
  type TopupRequestT,
  type MemberEligibilityT,
  type MambuTransactionChannelT
} from '@kwara/models/src';

import { SelectT } from '@kwara/models/src/models/Base';

import { TopupRequestStatus } from '@kwara/models/src/models/TopupRequest';
import { defaultSavingIncludes } from '@kwara/models/src/models/request/hooks';
import { useModelsRequest, useModelRequest } from '@kwara/models/src/models/request';

export { useLoan, useSaving } from '@kwara/models/src/models/request/hooks';
export { hasErrors, EMPTY, isEMPTY, getFirstError } from '@kwara/models/src/models/request';

type UseMemberEligibilityArgs = {
  memberId: string,
  includes?: IncludesT,
  enabled?: boolean
};

export const defaultUseMemberEligibilityIncludes = ['product'];
export const useMemberEligibility = ({
  memberId,
  includes = defaultUseMemberEligibilityIncludes,
  enabled = true
}: UseMemberEligibilityArgs) => {
  const scope = React.useMemo(() => MemberEligibility.id(memberId), [memberId]);
  return useModelsRequest<MemberEligibilityT>({ entity: scope, includes }, enabled);
};

async function fetchSavingsHabit({ queryKey }) {
  const [_, memberId] = queryKey;
  const res = await MemberSavingsHabit(memberId).find();
  return res.data;
}

export const useMemberSavingHabits = (memberId: string) => useQuery(['savings_habits', memberId], fetchSavingsHabit);

export const useSavings = (
  includes: IncludesT = defaultSavingIncludes,
  where: WhereT = null,
  page: number = 1
): LoadableBasePropsT<SavingType> => useModelsRequest<SavingType>({ entity: Saving, includes, where, per: 10, page });

const defaultTillTransactionIncludes = ['member'];
export const useTillTransactions = (till_id: string, includes: IncludesT = defaultTillTransactionIncludes) => {
  const [where] = React.useState({ till_id });
  return useModelsRequest<TillTransactionType>({ entity: TillTransaction, includes, where });
};

export const useTillTransaction = (id: string, includes: IncludesT = defaultTillTransactionIncludes) => {
  return useModelRequest<TillTransactionType>(TillTransaction, id, includes);
};

const defaultTillIncludes = [];
export const useTillSession = (id: string, includes: IncludesT = defaultTillIncludes) =>
  useModelRequest<TillSessionT>(TillSession, id, includes);

const defaultTillMetricsIncludes = [];
export const useTillMetrics = (till_id: string, includes: IncludesT = defaultTillMetricsIncludes) => {
  const [where] = React.useState({ till_id });
  return useModelsRequest<TillMetricT>({ entity: TillMetric, includes, where });
};

const defaultBatchTransactionImportsIncludes = ['user'];
export const useBatchTransactionImports = (
  includes: IncludesT = defaultBatchTransactionImportsIncludes,
  currentPage: number | null = null
) =>
  useModelsRequest<BatchTransactionImportT>({
    entity: BatchTransactionImport,
    includes,
    where: null,
    per: 10,
    page: currentPage
  });

const defaultBatchTransactionImportIncludes = ['batch_transactions', 'user'];
export const useBatchTransactionImport = (id: string, includes: IncludesT = defaultBatchTransactionImportIncludes) =>
  useModelRequest<BatchTransactionImportT>(BatchTransactionImport, id, includes);

const defaultBatchTransactionsIncludes = [];
export const useBatchTransactions = (
  batchId: string,
  includes: IncludesT = defaultBatchTransactionsIncludes,
  where: WhereT,
  currentPage: number | null = null
) => {
  const scope = React.useMemo(() => BatchTransaction.from(batchId), [batchId]);
  return useModelsRequest<BatchTransactionT>({ entity: scope, includes, where, per: 10, page: currentPage });
};

export const useTransactionChannels = () =>
  useModelsRequest<MambuTransactionChannelT>({ entity: MambuTransactionChannel });

const defaultProfileIncludes = [
  'role',
  'branch.address',
  'app_roles.app_permissions',
  'organisation.branches',
  'organisation.organisation_setting'
];
export const useProfile = (shouldRunNow = true, includes: IncludesT = defaultProfileIncludes) => {
  return useModelsRequest<UserT>({ entity: Profile, includes }, shouldRunNow);
};

const defaultUserIncludes = ['app_roles'];
export const useUser = (userId: string, includes: IncludesT = defaultUserIncludes) =>
  useModelRequest<UserT>(User, userId, includes);

export const useUsers = (includes: IncludesT = defaultUserIncludes) =>
  useModelsRequest<UserT>({ entity: User, includes });

const defaultOrganisationIncludes = ['users.app_roles'];
export const useOrganisation = (includes: IncludesT = defaultOrganisationIncludes) => {
  return useModelsRequest<OrganisationT>({ entity: Organisation, includes });
};

export const useOrganisations = (includes: IncludesT = defaultOrganisationIncludes) => {
  return useModelsRequest<OrganisationT>(Organisation, includes);
};

async function fetchActivity({ queryKey }) {
  const [_, entity, id, includes] = queryKey;
  const res = await Activity[entity](id)
    .includes(includes)
    .all();

  return res.data;
}

type SupportedEntity = 'loans' | 'savings' | 'member';
type UseActivityArgs = {
  id: string,
  enabled?: boolean,
  includes: IncludesT | null,
  entity: SupportedEntity
};
const defaultActivityIncludes = [];
export const useActivity = ({
  id,
  enabled = true,
  includes = defaultActivityIncludes,
  entity = 'loans'
}: UseActivityArgs) => useQuery(['activity', entity, id, includes], fetchActivity, { enabled });

type UseSavingsActivityArgs = {
  id: string,
  enabled?: boolean,
  includes: IncludesT | null
};
export const useSavingsActivity = ({ id, enabled = true, includes }: UseSavingsActivityArgs) =>
  useActivity({ id, enabled, includes, entity: 'savings' });

const defaultSavingProductsIncludes = [];
export const useSavingProducts = (includes: IncludesT = defaultSavingProductsIncludes) => {
  return useModelsRequest<SavingProductType[]>({ entity: SavingProduct, includes });
};

export const useSavingProduct = (id: string, includes: IncludesT = defaultSavingProductsIncludes) =>
  useModelRequest<SavingProductType>(SavingProduct, id, includes);

const defaultTopupRequestsIncludes = ['till_session'];
const defaultWhere = { state: TopupRequestStatus.PENDING };
export const useTopupRequests = (includes: IncludesT = defaultTopupRequestsIncludes, where: WhereT = defaultWhere) =>
  useModelsRequest<TopupRequestT>({ entity: TopupRequest, includes, where });

export const useTopupRequest = (id: string, includes: IncludesT = defaultTopupRequestsIncludes) =>
  useModelRequest<TopupRequestT>(TopupRequest, id, includes);

const defaultLoginIncludes = [];
export const useLoginActivities = (currentPage: number | null = null): LoadableBasePropsT<LoginActivityT[]> =>
  useModelsRequest<LoginActivityT[]>({
    entity: LoginActivity,
    includes: defaultLoginIncludes,
    where: null,
    per: 10,
    page: currentPage
  });

const defaultHistoricalCreditSubmissionsIncludes = ['user'];
export const useHistoricalCreditSubmissions = (
  includes: IncludesT = defaultHistoricalCreditSubmissionsIncludes,
  currentPage: number | null = null
) =>
  useModelsRequest<CrbDataSubmissionT>({
    entity: CrbDataSubmission,
    includes,
    where: null,
    per: 10,
    page: currentPage
  });

export const useHistoricalCreditSubmission = (
  id: string,
  includes: IncludesT = defaultHistoricalCreditSubmissionsIncludes
) => useModelRequest<CrbDataSubmissionT>(CrbDataSubmission, id, includes);

export const useLoanApprovalSetting = () => useModelsRequest<LoanApprovalSettingT>({ entity: LoanApprovalSetting });

export const useRoles = () => useModelsRequest<RoleT>({ entity: Role });

const useAppRolesIncludes = [];
export const useAppRoles = (includes: IncludesT = useAppRolesIncludes) =>
  useModelsRequest<AppRoleT>({ entity: AppRole, includes });

const defaultUseAppRoleIncludes = ['app_permissions'];
export const useAppRole = (roleId, includes: IncludesT = defaultUseAppRoleIncludes) =>
  useModelRequest<AppRoleT>(AppRole, roleId, includes);

const defaultInvitationsIncludes = ['role'];
export const useInvitations = (includes: IncludesT = defaultInvitationsIncludes, currentPage: number | null = null) =>
  useModelsRequest<InvitationModelT>({ entity: InvitationModel, includes, where: null, per: 10, page: currentPage });

const defaultMemberReportsIncludes = [];
export const useMemberReports = (
  includes: IncludesT = defaultMemberReportsIncludes,
  currentPage: number | null = null
) => useModelsRequest<MemberReportT>({ entity: MemberReport, includes, where: null, per: 10, page: currentPage });

const defaultEventIncludes = ['user'];
export const useEvents = (includes: IncludesT = defaultEventIncludes, page: number | null) =>
  useModelsRequest({ entity: Event, includes, where: null, per: 10, page });

async function fetchSavingTransactions({ queryKey }) {
  const [_, savingId, includes] = queryKey;
  const res = await SavingsTransaction.includes(includes)
    .where({ savings_id: savingId })
    .all();

  return res.data;
}

async function fetchFilteredSavingTransactions({ queryKey }) {
  const [_, includes, where] = queryKey;
  if (where.savings_id) {
    const res = await SavingsTransaction.includes(includes)
      .where(where)
      .all();

    return res.data;
  }
}

const defaultSavingTransactionIncludes = [];
type UseSavingTransactionsArgs = {
  id: string,
  enabled?: boolean,
  includes: IncludesT
};
export const useSavingTransactions = ({
  id,
  enabled,
  includes = defaultSavingTransactionIncludes
}: UseSavingTransactionsArgs) => useQuery(['saving_transactions', id, includes], fetchSavingTransactions, { enabled });

const defaultFilteredSavingTransactions = {};
export const useFilteredSavingTransactions = (
  includes: IncludesT = defaultSavingTransactionIncludes,
  where: WhereT = defaultFilteredSavingTransactions
) => useQuery(['filtered_savings_transactions', includes, where], fetchFilteredSavingTransactions);

async function fetchLoanTransactions({ queryKey }) {
  const [_, loanId, includes] = queryKey;
  const res = await LoanTransaction.includes(includes)
    .where({ loan_id: loanId })
    .all();

  return res.data;
}

async function fetchFilteredLoanTransactions({ queryKey }) {
  const [_, includes, where] = queryKey;
  if (where.loan_id) {
    const res = await LoanTransaction.includes(includes)
      .where(where)
      .all();

    return res.data;
  }
}

const defaultLoanTransactionIncludes = [];
export const useLoanTransactions = (loanId: string, includes: IncludesT = defaultLoanTransactionIncludes) =>
  useQuery(['loan_transactions', loanId, includes], fetchLoanTransactions);

const defaultFilteredLoanTransactions = {};
export const useFilteredLoanTransactions = (
  includes: IncludesT = defaultLoanTransactionIncludes,
  where: WhereT = defaultFilteredLoanTransactions
) => useQuery(['filtered_loan_transactions', includes, where], fetchFilteredLoanTransactions);

const defaultGlAccountIncludes = [];
const defaultGlAccountWhere = { allow_manual_journal_entries: 'true' };

export const useGlAccounts = (includes: IncludesT = defaultGlAccountIncludes, where: WhereT = defaultGlAccountWhere) =>
  useModelsRequest<GeneralLedgerAccountT>({ entity: GeneralLedgerAccount, includes, where });

const defaultAppPermissionsIncludes = [];
export const useAppPermissions = () =>
  useModelsRequest<AppPermissionT>({
    entity: AppPermission,
    includes: defaultAppPermissionsIncludes,
    where: null,
    per: 200
  });

const defaultDirectDebitReportsIncludes = ['collecting_bank'];
export const useDirectDebitReports = (includes: IncludesT = defaultDirectDebitReportsIncludes, page: number) =>
  useModelsRequest<DirectDebitReportT>({ entity: DirectDebitReport, includes, where: null, per: 10, page });

const defaultDisbursedLoansIncludes = [];
const defaultDisbursedLoansWhere = {};
export const useDisbursedLoans = (
  includes: IncludesT = defaultDisbursedLoansIncludes,
  where: WhereT = defaultDisbursedLoansWhere,
  currentPage: number = 1
) => {
  return useModelsRequest<DisbursedLoanT>({ entity: DisbursedLoan, includes, where, per: 10, page: currentPage });
};

const defaultJournalEntriesIncludes = [];
const defaultJournalEntriesWhere = {};
export const useJournalEntries = (
  includes: IncludesT = defaultJournalEntriesIncludes,
  where: WhereT = defaultJournalEntriesWhere,
  currentPage: number = 1
) => {
  return useModelsRequest<JournalEntryT>({ entity: JournalEntry, includes, where, per: 10, page: currentPage });
};

const defaultJournalEntryIncludes = ['gl_account', 'transaction.account.member'];
const defaultJournalEntrySelect = { members: ['first_name', 'last_name'] };
export const useJournalEntry = (
  transactionId: string,
  select: SelectT = defaultJournalEntrySelect,
  includes: IncludesT = defaultJournalEntryIncludes
) => {
  return useModelRequest<JournalEntryT>(JournalEntry, transactionId, includes, true, select);
};

async function fetchDirectDebitOrders({ queryKey }) {
  const [_, memberId, includes] = queryKey;
  const res = await DirectDebitOrder.includes(includes)
    .where({ member_id: memberId })
    .all();

  return res.data;
}

type UseDirectDebitOrdersArgs = {
  memberId: string,
  includes: IncludesT,
  enabled?: boolean
};

const defaultDirectDebitOrderIncludes = ['collecting_bank', 'remittances', 'bank_account.bank_branch.bank'];
export const useDirectDebitOrders = ({
  memberId,
  includes = defaultDirectDebitOrderIncludes,
  enabled = true
}: UseDirectDebitOrdersArgs) =>
  useQuery(['direct_debit_orders', memberId, includes], fetchDirectDebitOrders, { enabled });

async function fetchConnectUser({ queryKey }) {
  const [_, memberId] = queryKey;

  if (!memberId) {
    return;
  }

  const ConnectUserModel = ConnectUser(memberId);

  try {
    const res = await ConnectUserModel.find();
    return res.data;
  } catch {
    return null;
  }
}

export const useConnectUser = (memberId: string) => {
  return useQuery(['connect_user', memberId], fetchConnectUser);
};
