import { attr, belongsTo } from 'spraypaint';
import axios from 'axios';

import { ValueOf } from 'GlobalTypes';
import Base, { BaseModel, SavingProductType } from '@kwara/models/src/models/Base';
import { csvHeaders, blobHeaders, handleDownload, blobToFileDownload } from '@kwara/lib/src/fileDownload';

const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
});
const mimeTypes = Object.freeze(Object.keys(mimeTypesMaps));

export const DividendReports = Base.extend({
  static: {
    jsonapiType: 'dividend_reports',
    endpoint: '/dividends/reports'
  },
  attrs: {
    createdAt: attr(),
    id: attr(),
    productId: attr(),
    allocatedDividendAmount: attr(),
    calculatedNetDividendAmount: attr(),
    totalNumberOfMembers: attr(),
    calculationMethod: attr(),
    product: belongsTo('savings_products'),
    notes: attr(),
    dividendPercentage: attr(),
    witholdingTaxPercentage: attr(),
    state: attr(),
    startDate: attr(),
    endDate: attr()
  },
  methods: {
    fileName(reportType: string) {
      return `${reportType}_${this.id}_${this.calculationMethod}`;
    },
    downloadCSV(reportType: string) {
      const url = `${DividendReports.url()}/${this.id}/contributions.csv`;
      const opts = DividendReports.fetchOptions();
      const options = csvHeaders(opts);

      return axios.get(url, options as any).then(res => {
        const blob = new Blob(['\ufeff' + res.data], {
          type: 'text/csv;charset=utf-8;'
        });

        try {
          blobToFileDownload(blob, `${this.fileName(reportType)}.csv`);
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    },
    downloadXLSX(reportType: string) {
      const url = `${DividendReports.url()}/${this.id}/contributions.xlsx`;
      const opts = DividendReports.fetchOptions();
      const options = blobHeaders(opts);
      const fileName = `${this.fileName(reportType)}.xlsx`;

      return axios.get(url, options as any).then(res => {
        try {
          handleDownload({
            data: res.data,
            fileName,
            mimeTypes
          });
        } catch (e) {
          return Promise.reject(e);
        }

        return Promise.resolve();
      });
    }
  }
});

export const DividendReportState = Object.freeze({
  COMPLETED: 'completed',
  FAILED: 'failed',
  PENDING: 'pending'
});

export type DividendReportStateT = ValueOf<typeof DividendReportState>;

export const DividendCalculationMethod = Object.freeze({
  WEIGHTED: 'weighted',
  FLAT: 'flat'
});

export type DividendCalculationMethodT = ValueOf<typeof DividendCalculationMethod>;

export interface DividendReportsT extends BaseModel<DividendReportsT> {
  createdAt: string;
  id: string;
  productId: string;
  allocatedDividendAmount: number;
  calculatedNetDividendAmount: number;
  totalNumberOfMembers: number;
  calculationMethod: DividendCalculationMethodT;
  notes: string;
  dividendPercentage: number;
  witholdingTaxPercentage: number;
  state: DividendReportStateT;
  startDate: string;
  endDate: string;
  product: SavingProductType;
}
