// @flow

import * as React from 'react';

import { type MemberType } from '@kwara/models/src';

import { type Location } from '@kwara/components/src/Actionable';
import { Card } from '@kwara/components/src/Card';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';

import { Currency } from '../../../components/Currency';

type Props = {
  member: MemberType,
  actionTo: Location
};

export default ({ member, actionTo }: Props) => {
  const metadata = <Text id="MemberCard.joinedDate" values={{ createdAt: member.createdAtDate() }} />;

  return (
    <Card
      border={false}
      header={member.fullName()}
      metadata={metadata}
      actionLabelId="MemberCard.action"
      actionTo={actionTo}
    >
      <Statistic
        title={<Text id="MemberCard.deposits" />}
        size="small"
        value={<Currency value={member.totalSavings} />}
        color="teal-600"
      />
      <Statistic
        title={<Text id="MemberCard.loans" />}
        size="small"
        value={<Currency value={member.totalLoansBalance} />}
        color="red-600"
      />
      <Statistic
        title={<Text id="MemberCard.eligibleAmount" />}
        size="small"
        border={true}
        value={<Currency value={member.eligibleAmount} />}
      />
    </Card>
  );
};
