import React from 'react';

import { useInterval } from '@kwara/models/src/models/request/hooks';

const MIN_INDEX = 0;

export function useDynamicPlaceholder(placeholders: string[], timer: number = 2500) {
  const [index, setIndex] = React.useState(MIN_INDEX);
  const size = placeholders.length - 1;

  useInterval(function delay() {
    setIndex(prevIndex => {
      if (prevIndex === size) {
        return MIN_INDEX;
      }
      return prevIndex + 1;
    });
  }, timer);

  return placeholders[index];
}
