import React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';
import { Metric } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';

import { ROUTES } from '../../../../routes';
import Dashboard from '../../../../components/Dashboard';
import { DashboardMetric } from '../../../../components/DashboardMetric';
import Visible from '../../../../components/Visible';
import { useSupervisorMetrics } from '../../useSupervisorMetrics';

import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

export function TellerDashboard({ messages = [], inboxAction }) {
  const result = useSupervisorMetrics();
  const { AppPermissions } = usePermissions();

  return (
    <Loadable {...result}>
      {metrics => {
        return (
          <Dashboard
            messages={messages}
            inboxAction={inboxAction}
            actions={[
              <Visible key="till-management" to={AppPermissions.ManageTillAccess}>
                <Button to={ROUTES.tillsManagement}>
                  <Text id="Tellers.Dashboard.button.tillManagement" />
                </Button>
              </Visible>
            ]}
          >
            <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.currentBalance" />}
                metric={Metric.findFromArray(metrics, 'current_balance')}
              />
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.utilities" />}
                metric={Metric.findFromArray(metrics, 'utility_payments')}
              />
            </StatisticRow>
            <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.deposits" />}
                metric={Metric.findFromArray(metrics, 'deposits')}
              />

              <DashboardMetric
                title={<Text id="Tellers.Dashboard.withdrawals" />}
                metric={Metric.findFromArray(metrics, 'cash_withdrawals')}
              />
            </StatisticRow>
          </Dashboard>
        );
      }}
    </Loadable>
  );
}
