import React from 'react';
import noop from 'lodash/fp/noop';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { ReportTemplateT } from '@kwara/models/src';

import Table, { Row, Heading, Footer, Cell } from '../../../../components/Table';

interface TemplatesTableT {
  templates: ReportTemplateT[];
  isLoading: boolean;
}

export function TemplatesTable({ templates = [], isLoading }: TemplatesTableT) {
  return (
    <Table
      heading={
        <Row>
          <Heading translationId="SasraReportTemplates.Table.header.name" />
          <Heading translationId="SasraReportTemplates.Table.header.actions" />
        </Row>
      }
      footer={<Footer colSpan={2} isLoading={isLoading} hasMore={false} onNext={noop} items={templates} />}
    >
      {templates.map(template => {
        return (
          <Row key={template.id}>
            <Cell>{template.name}</Cell>
            <Cell>
              <Link to={`/sasra/config/${template.id}`}>
                <Text id="SasraReportTemplates.Table.link.edit" />
              </Link>
            </Cell>
          </Row>
        );
      })}
    </Table>
  );
}
