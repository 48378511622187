import React from 'react';

import { ReportFieldT, GlAccountT, ReportFieldGlAccountLinkT, ReportTemplateT } from '@kwara/models/src';

import { Loadable } from '@kwara/components/src/Loadable';

import { BodyContentWrapper } from '../../layouts';
import { ReportConfigTable } from './components/Table/ReportConfigTable';
import OverviewList from '../../components/OverviewList';

import { useGlAccounts } from './useGlAccounts';
import { useReportFieldsApiFetch } from './useReportFieldsApiFetch';
import { useReportFields } from './useReportFields';
import { useReportTemplate } from './useReportTemplate';

type Props = {
  fields: ReportFieldT[];
  glAccounts: GlAccountT[];
  template: ReportTemplateT;
  isLoading: boolean;
  onRemoveLink({ link, field }: { link: ReportFieldGlAccountLinkT; field: ReportFieldT }): void;
  onAddLink({ glAccountId, field, scalar }: { glAccountId: number; field: ReportFieldT; scalar: number }): void;
};

export function SasraReportConfigLayout({
  template,
  fields = [],
  glAccounts,
  isLoading,
  onRemoveLink,
  onAddLink
}: Props) {
  return (
    <BodyContentWrapper.Root headId="SasraReportConfig.title">
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <OverviewList
            heading={<span>{template.name}</span>}
            items={fields}
            table={
              <ReportConfigTable
                isLoading={isLoading}
                fields={fields}
                onRemoveLink={onRemoveLink}
                onAdd={onAddLink}
                glAccounts={glAccounts}
              />
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}

function SasraReportConfig(props) {
  const templateId = props.match.params.reportTemplateId;

  const templateResponse = useReportTemplate(templateId);
  const fieldsResponse = useReportFieldsApiFetch(templateId);
  const glsResponse = useGlAccounts();

  const { onAddLink, onRemoveLink } = useReportFields(templateId);

  return (
    <Loadable {...templateResponse}>
      {template => (
        <SasraReportConfigLayout
          isLoading={fieldsResponse.isLoading}
          template={template}
          fields={fieldsResponse.data || []}
          glAccounts={glsResponse.data}
          onAddLink={onAddLink}
          onRemoveLink={onRemoveLink}
        />
      )}
    </Loadable>
  );
}

export default SasraReportConfig;
