import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { SmsPlanT } from '@kwara/models/src/models/SmsPlan';
import { Loadable } from '@kwara/components/src/Loadable';
import { useSmsPlan } from '@kwara/models/src/models/request/hooks';
import Button from '@kwara/components/src/Button';

import DetailSubsection from '../../../../components/DetailSubsection';
import { BodyContentWrapper } from '../../../../layouts';
import { BundleCard } from './components/BundleCard';
import { Grid } from '../../../../components/Grid';
import { Header } from '../Header';

export const BundleContent = ({ bundle }: { bundle: SmsPlanT }) => {
  return bundle ? (
    <>
      <DetailSubsection
        title={<Text id="Settings.smsSettings.subscription" />}
        headerRight={
          <Button to="/settings/sms/edit">
            <Text id="Settings.smsSettings.editTriggers" />
          </Button>
        }
      />
      <Grid className={'nt4'} columns={2} width="w-50">
        <BundleCard bundle={bundle} />
        <></>
      </Grid>
    </>
  ) : null;
};

export const SmsSettings = () => {
  const r = useSmsPlan();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <Header titleId="Settings.smsSettings.titleId" />
          <Loadable {...r}>{sms => <BundleContent bundle={sms} />}</Loadable>
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
};
