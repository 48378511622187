import React from 'react';
import map from 'lodash/fp/map';

import { SubscribedCheckbox } from './Checkbox';

import styles from './CheckboxGroup.module.scss';

export const SubscribedCheckboxGroup = ({ name, options, compact, widthClass = 'w-50' }) => {
  return (
    <div className={styles.wrapper}>
      {map(
        option => (
          <div className={widthClass} key={option.value}>
            <SubscribedCheckbox
              compact={compact}
              margin={false}
              name={name}
              value={option.value}
              labelId={option.labelId}
              labelContent={option.labelContent}
              itemsCenter={false}
              showInfo={false}
            />
          </div>
        ),
        options
      )}
    </div>
  );
};
