// @flow

import React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { type OrganisationT } from '@kwara/models/src';
import { SubscribedSelectCheckboxes } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';

import { useSavingProducts, usePermissions } from '../../../../../hooks';
import { SettingsSectionForm } from '../shared';

const toOptions = products => map(p => ({ value: p.id, labelId: p.name }), products);

export const DefaultSavingsAccounts = ({
  refetch,
  organisation
}: {
  organisation: OrganisationT,
  refetch: Function
}) => {
  const r = useSavingProducts();
  const { permission, AppPermissions } = usePermissions();

  const permitted = permission.to(AppPermissions.UpdateOrganisationSettings);
  const initialValues = get('settings.member_creation', organisation) || {
    default_accounts: []
  };

  const onSubmit = async data => {
    const params = {
      settings: {
        member_creation: data
      }
    };

    await organisation.update(params);
    refetch();
  };

  return (
    <SettingsSectionForm
      titleId="Settings.Sacco.defaultSavingsAccounts.title"
      onSubmit={onSubmit}
      permitted={permitted}
      initialValues={initialValues}
      render={({ formProps, values }) => (
        <Loadable {...r}>
          {products => (
            <SubscribedSelectCheckboxes
              labelId="Settings.Sacco.defaultSavingsAccounts.accounts.label"
              name="default_accounts"
              options={toOptions(products)}
              formProps={formProps}
              currentValues={values}
            />
          )}
        </Loadable>
      )}
    />
  );
};
