import * as React from 'react';
import { Text } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';

import OverviewList from '../../../../components/OverviewList';
import { usePaginatedUsers } from '../../../../models/hooks';
import { BodyContentWrapper } from '../../../../layouts';
import { UserTable } from './UserTable';

const includes = ['app_roles'];
export function Team() {
  const {
    allData,
    totalResults,
    fetchNextPage,
    refetch,
    hasNextPage,
    onSearchChange,
    searchValue,
    isLoading,
    isFetchingNextPage
  } = usePaginatedUsers(includes);

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <BodyContentWrapper.Row.Table>
          <PageHeading translationId="Settings.Team.titleId" />
          <p className="grey-400 kw-text-medium mb1">
            <Text id="Settings.Team.subtitle" />
          </p>
          <OverviewList
            labelId="Settings.Team.List.label"
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            searchPlaceholderId="Settings.Team.List.searchPlaceholder"
            totalNumResults={totalResults}
            items={allData}
            table={
              <UserTable
                users={allData}
                onNext={fetchNextPage}
                hasMore={hasNextPage}
                isLoading={isLoading || isFetchingNextPage}
                refetch={refetch}
              />
            }
          />
        </BodyContentWrapper.Row.Table>
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
