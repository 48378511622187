// @flow

import React from 'react';
import reject from 'lodash/fp/reject';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';
import round from 'lodash/round';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { Loadable, LoadingSkeleton } from '@kwara/components/src/Loadable';
import StatusTag from '@kwara/components/src/StatusTag';
import { Currency, Text } from '@kwara/components/src/Intl';
import { Attribute, type RequestProps } from '@kwara/components/src';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { type MemberType, type LoanProductStandingT, type GuaranteeType } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';

import { GuaranteeBreakdownPanel } from './GuarantorBreakdownPanel';

export const GuarantorInfoBase = ({
  r,
  member,
  guarantees
}: {
  r: RequestProps<LoanProductStandingT>,
  member: MemberType,
  guarantees: GuaranteeType[]
}) => (
  <>
    <div className="pa3 flex justify-between">
      <div className="w-50 flex">
        <div className="dib pt3">
          <ProfilePhoto className="dib" size="small" />
        </div>
        <Attribute
          className="ml2 dib"
          labelId="LoanAdd.Security.Guarantor.member"
          labelSize="x-small"
          size="medium"
          valueClassName="kw-weight-bold"
          values={{ id: member.id }}
          value={member.fullName()}
        />
      </div>
      <Attribute
        className="ml2 w-40"
        labelContent={
          <>
            <Text id="LoanAdd.Security.Guarantor.standing" />{' '}
            <InfoPanel>
              <GuaranteeBreakdownPanel loanProductStandingR={r} />
            </InfoPanel>
          </>
        }
        labelSize="x-small"
        size="medium"
        valueClassName="kw-weight-bold"
        value={
          <Loadable {...r} loading={<LoadingSkeleton />}>
            {standing => <Currency format="currency" value={standing.canGuaranteeUpTo} />}
          </Loadable>
        }
      />
    </div>
    <div className="pa3 bt b--light-grey-400 flex justify-between items-center">
      <div className="kw-text-medium kw-weight-bold">
        <Text id="LoanAdd.Security.Guarantor.loansGuaranteed" />
      </div>
      <div className="kw-text-medium kw-weight-bold">
        <Loadable {...r} loading={<LoadingSkeleton />}>
          {standing => <Currency format="currency" value={standing.alreadyGuaranteedAmount} />}
        </Loadable>
      </div>
    </div>
    <div className="pa3 flex justify-between flex-wrap">
      <Statistic
        containerClassName="w-33"
        title={<Text id="LoanAdd.Security.Guarantor.selfGuarantor" />}
        value={<Currency format="currency" value={member.totalSelfGuaranteed} />}
        size="small-design-system-bold"
      />
      {mapIndexed(
        (g, index) => (
          <Statistic
            key={index.toString()}
            containerClassName="w-33"
            title={get('loan.id', g)}
            value={<Currency format="currency" value={round(Number(g.liability), 2)} />}
            size="small-design-system-bold"
            info={
              <>
                <div className="grey-400 kw-text-small">{invoke('loan.member.fullName', g)}</div>
                <div className="grey-400 kw-text-small">{get('loan.name', g)}</div>
                <StatusTag size="small" state={get('loan.state.current', g)} />
              </>
            }
          />
        ),
        guarantees
      )}
    </div>
  </>
);

export const GuarantorInfo = ({ r, member }: { r: RequestProps<LoanProductStandingT>, member: MemberType }) => {
  const { dbGuarantees } = member;
  const guarantees = reject(g => invoke('loan.isClosed', g) || !g.loan, dbGuarantees);

  return <GuarantorInfoBase r={r} member={member} guarantees={guarantees} />;
};
