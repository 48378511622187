import { useEffect, useState, useCallback } from 'react';
import { FieldInputProps } from 'react-final-form';

import { OnClickHandler, SaveGuarantor, SaveAs } from 'GuarantorCardTypes';

import { MemberType } from '@kwara/models/src/models/Member';
import { LoanProductType } from '@kwara/models/src/models/LoanProduct';

import { useMemberLoanProductStanding } from '../../../../models/hooks';

const MIN_GUARANTOR_AMOUNT = 1;
const SKIP_WARNING_KEY = 'skipGuarantorValidationWarning';

export enum STATUS {
  DEFAULT = 'NULLISH',
  CHANGE_IS_NOT_SAVED = 'CHANGE_IS_NOT_SAVED',
  CHANGE_IS_SAVED = 'CHANGE_IS_SAVED'
}
export enum SAVE_AS {
  CLIENT = 'CLIENT',
  SERVER = 'SERVER'
}

type OnHideEditor = () => void;
type Input = FieldInputProps<boolean, HTMLInputElement>;
type OnHideEditorHandler = (onHideEditor: OnHideEditor) => void;
type OnSaveHandlerArgType = { input: Input; amount: string; memberId: string; loanId: string };
type OnRemoveHandlerArgType = { input: Input; onHideEditorHandler?: OnHideEditorHandler };
export type UseGuarantorCardArgType = {
  index: number;
  saveAs: SaveAs;
  product: LoanProductType;
  member: MemberType;
  amount: string;
  hasBlockingError: boolean;
  isSavingGuarantor: boolean;
  isGuarantorAlreadyMarkedForRemoval: boolean;
  addData(data: Record<string, any>): void;
  onClickHandler: OnClickHandler;
  saveGuarantor: SaveGuarantor;
};
export function useGuarantorCard({
  addData,
  index,
  amount,
  hasBlockingError,
  member,
  product,
  saveAs,
  isGuarantorAlreadyMarkedForRemoval,
  isSavingGuarantor,
  onClickHandler,
  saveGuarantor
}: UseGuarantorCardArgType) {
  const [status, setStatus] = useState(STATUS.DEFAULT);

  const memberId = member.id;

  const loanProductStandingRequest = useMemberLoanProductStanding(memberId, product.id, {
    enabled: memberId != undefined && product.id != undefined
  });

  const inputName = `guarantors[${index}].amount`;
  const inputIsMarkedForDestructionName = `guarantors[${index}].isMarkedForDestruction`;
  const canGuaranteeUptoKey = `guarantors[${index}].canGuaranteeUpTo`;
  const guarantorName = member.fullName();
  const canGuaranteeUpto: number | null = loanProductStandingRequest.data?.canGuaranteeUpTo ?? null;
  const isCanGuaranteeUptoValid = canGuaranteeUpto >= MIN_GUARANTOR_AMOUNT;

  useEffect(() => addData?.({ [canGuaranteeUptoKey]: canGuaranteeUpto }), [
    addData,
    canGuaranteeUpto,
    canGuaranteeUptoKey
  ]);

  const shouldDisableRemoveBtn = isGuarantorAlreadyMarkedForRemoval || isSavingGuarantor;
  const invalidAmount = Number(amount) > canGuaranteeUpto;
  const shouldDisableSaveBtn =
    isSavingGuarantor || status !== STATUS.CHANGE_IS_NOT_SAVED || hasBlockingError || invalidAmount;

  const onChangeHandler = useCallback(() => setStatus(STATUS.CHANGE_IS_NOT_SAVED), []);

  const onKeyUpHandler = useCallback(() => addData?.({ [SKIP_WARNING_KEY]: false }), [addData]);

  const onRemoveHandler = useCallback(
    ({ input, onHideEditorHandler }: OnRemoveHandlerArgType) => {
      return () => {
        input.onChange(true);
        setStatus(STATUS.CHANGE_IS_NOT_SAVED);
        onClickHandler({
          type: 'REMOVE',
          onSuccess(onHideEditor) {
            onHideEditorHandler?.(onHideEditor);
          }
        });
      };
    },
    [onClickHandler]
  );

  const saveSuccessHandler = (onChange: (event: any) => void, onHideEditor: OnHideEditor) => {
    onChange(false);
    setStatus(STATUS.CHANGE_IS_SAVED);
    onHideEditor();
  };

  const saveOnServer = useCallback(
    async ({
      amount,
      memberId,
      loanId,
      input,
      onHideEditor
    }: OnSaveHandlerArgType & { onHideEditor: OnHideEditor }) => {
      try {
        await saveGuarantor({ amount, memberId, loanId });
        saveSuccessHandler(input.onChange, onHideEditor);
      } catch (_) {
        onRemoveHandler({ input })();
      }
    },
    [onRemoveHandler, saveGuarantor]
  );

  const onSaveHandler = useCallback(
    ({ input, amount, memberId, loanId }: OnSaveHandlerArgType) => {
      return () => {
        onClickHandler({
          type: 'SAVE',
          onSuccess(onHideEditor) {
            if (saveAs === SAVE_AS.CLIENT) return saveSuccessHandler(input.onChange, onHideEditor);

            saveOnServer({ amount, memberId, loanId, input, onHideEditor });
          }
        });
      };
    },
    [onClickHandler, saveAs, saveOnServer]
  );

  return {
    shouldDisableRemoveBtn,
    shouldDisableSaveBtn,
    canGuaranteeUpto,
    inputIsMarkedForDestructionName,
    inputName,
    invalidAmount,
    memberId,
    guarantorName,
    isCanGuaranteeUptoValid,
    loanProductStandingRequest,
    onChangeHandler,
    onKeyUpHandler,
    onSaveHandler,
    onRemoveHandler
  };
}
