// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import { SubscribedTextArea } from '@kwara/components/src/Wizard/deprecated/FormFields';

import { Panel } from '../../../../components/ActionModal';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => ?Promise<?Object>,
  numberSelected: number,
  event: 'approve' | 'reject' | null,
  error?: any,
  submitting: boolean
};

function getEvent(formEvt, numberSelected) {
  switch (formEvt) {
    case 'approve':
      return numberSelected ? 'approve' : 'approveAll';
    case 'reject':
      return numberSelected ? 'reject' : 'rejectAll';
    default:
      throw 'Unsupported event';
  }
}

export const Confirm = ({ isOpen, onClose, onSubmit, numberSelected, event: formEvt, submitting, error }: Props) => {
  if (!formEvt) {
    return null;
  }

  const isReject = formEvt === 'reject';
  const event = getEvent(formEvt, numberSelected);

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <div className="w-100 h-100 flex justify-center items-center">
        <div>
          <div className="mb3">
            <ModelErrorBanner errors={error} />
          </div>
          <Panel>
            <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
              <Text id={`Finance.Modal.title.${event}`} values={{ val: numberSelected }} />
            </h2>
            <p className="kw-weight-light mb3">
              <Text id={`Finance.Modal.subtitle.${event}`} values={{ val: numberSelected, br: <br /> }} />
            </p>
            <SubscribedTextArea
              name="notes"
              labelId="Finance.Modal.notes.label"
              placeholderId="Finance.Modal.notes.placeholder"
            />
            <ButtonBar
              className="mt4"
              left={
                <Button onClick={onClose}>
                  <Text id="Finance.Modal.actionsApproval.cancel" />
                </Button>
              }
              right={
                <Button
                  classNames="mr3"
                  type={isReject ? 'destructive' : 'primary'}
                  onClick={onSubmit}
                  glyphRightId={submitting ? Button.Glyphs.Spinner : null}
                  disabled={submitting}
                >
                  <Text id={`Finance.Modal.actionsApproval.${event}`} />
                </Button>
              }
            />
          </Panel>
        </div>
      </div>
    </Modal>
  );
};
