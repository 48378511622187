import repeat from 'lodash/fp/repeat';
export const maskStringUntilLastNCharacters = (value: string, n: number) => {
  if (value == null) {
    return '';
  }
  if (n === 0) {
    return value;
  }

  const stringLength = value.length;
  const maskChar = '*';

  if (n >= stringLength) {
    return repeat(stringLength, maskChar);
  }

  const lastNChars = value.slice(-n);
  return `${repeat(stringLength - n, maskChar)}${lastNChars}`;
};
