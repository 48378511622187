import * as React from 'react';

import { LoanSubstepProps, WithRequired } from 'GlobalTypes';

import { Text } from '@kwara/components/src/Intl';
import { LoanType } from '@kwara/models/src/models/Loan';

import TabNavigation from '../../../../../components/TabNavigation';

import { Container } from '../../../../../layouts';
import { DocumentList } from '../../../../../components/DocumentList';
import { CollateralDocumentList } from '../../../../../components/Collaterals/components/CollateralDocumentList';

export function Attachments({ data: { loan } }: WithRequired<Partial<LoanSubstepProps>, 'data'>) {
  const { member, loanApplication } = loan as LoanType;

  const tabConfig = [
    {
      title: <Text id="LoanDisbursal.Attachments.NavigationTabs.income" />,
      content: <DocumentList member={member} contexts={['income']} attachments={member.attachments} />
    },
    {
      title: <Text id="LoanDisbursal.Attachments.NavigationTabs.collaterals" />,
      content: (
        <CollateralDocumentList
          loanApplication={loanApplication}
          attachments={loanApplication?.loanApplicationAttachments}
        />
      )
    }
  ];

  return (
    <Container.Root>
      <Container.Body>
        <TabNavigation.SwitchTab tabConfig={tabConfig} />
      </Container.Body>
    </Container.Root>
  );
}

export default {
  Component: Attachments
};
