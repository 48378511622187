import get from 'lodash/fp/get';
import { Metric } from '@kwara/models/src';
import { useCurrentTillMetrics } from '../../models/hooks';
import { extractValue } from '../../components/DashboardMetric';

export const useCurrentTillBalance = () => {
  const { data: metrics } = useCurrentTillMetrics(true);

  const metric = Metric.findFromArray(metrics, 'current_balance');
  const currency = extractValue(get('value', metric));

  return { currentBalance: currency };
};
