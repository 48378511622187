import React from 'react';

import { LoanType } from '@kwara/models/src/models/Loan';
import { Loadable } from '@kwara/components/src/Loadable';
import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { LoanCard } from '../LoanCard';
import { HorizontalScroller } from '../HorizontalScroller';
import { useLoansForMember } from '../../hooks';

import styles from './index.module.scss';

interface PropTypes {
  memberId: string;
}

export const DefaultedLoansHorizontalScroller = ({ memberId }: PropTypes) => {
  const defaultedLoansR = useLoansForMember(memberId, { where: { state: 'ACTIVE_IN_ARREARS,CLOSED_WRITTEN_OFF' } });
  return (
    <section className={styles.section}>
      <WizardSectionTitle id="LoanAdd.LoanPortfolio.LoanCategories.DefaultedLoans.title" />
      <Loadable {...defaultedLoansR}>
        {defaultedLoans => (
          <HorizontalScroller<LoanType> data={defaultedLoans} fallbackId="LoanAdd.LoanPortfolio.Loans.noLoans">
            {({ startIndex, endingIndex }) => {
              const currentLoans = defaultedLoans.slice(startIndex, endingIndex);

              return (
                <ul className={styles.loansListbox}>
                  {currentLoans.map(loan => (
                    <li key={loan.id}>
                      <LoanCard loan={loan} showState />
                    </li>
                  ))}
                </ul>
              );
            }}
          </HorizontalScroller>
        )}
      </Loadable>
    </section>
  );
};
