//@flow
import * as React from 'react';
import invoke from 'lodash/fp/invoke';

import { type TillTransactionType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { TopupRequestStatus } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { OverviewMetadata } from './OverviewMetadata';
import { Currency } from '../../../components/Currency';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';

export type Props = {
  actions: React.Node,
  navigation?: React.Node,
  showBack: boolean,
  transaction: TillTransactionType
};

export default (props: Props) => {
  const { transaction, navigation } = props;
  const { member } = transaction;

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata {...props} />}
      secondary={navigation}
      headerRight={
        member ? (
          <ProfilePhoto memberId={member.id} attachments={member.attachments} size="regular" isExpandable />
        ) : null
      }
      title={
        <Text
          id="TransactionDetail.title"
          values={{
            displayName: invoke('fullName', transaction.member) || transaction.name
          }}
        />
      }
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="TransactionDetail.Overview.amount" />}
            value={
              <Currency
                value={
                  transaction.status === TopupRequestStatus.APPROVED
                    ? transaction.approvedAmount
                    : transaction.requestedAmount
                }
              />
            }
            size={'5x-large'}
          />
          <Statistic
            title={<Text id="TransactionDetail.Overview.transactionId" />}
            size={'5x-large'}
            value={transaction.id}
          />
        </StatisticRow>
        <StatisticRow border={true} widthClass="w-25">
          <Statistic title={<Text id="TransactionDetail.Overview.type" />} size="large" value={transaction.type} />
          <Statistic
            title={<Text id="TransactionDetail.Overview.method" />}
            size="large"
            value={transaction.paymentMethod}
          />
          <Statistic
            title={<Text id="TransactionDetail.Overview.invoiceNumber" />}
            size="large"
            value={transaction.invoiceNumber}
          />
          <Statistic
            title={<Text id="TransactionDetail.Overview.reference" />}
            size="large"
            value={transaction.reference}
          />
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
