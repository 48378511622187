import * as React from 'react';
import cx from 'classnames';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.scss';
import { IconDone } from '../../../assets/iconDone.svg';
import { IconActive } from '../../../assets/iconActive.svg';
import { IconPending } from '../../../assets/iconPending.svg';
import { RenderableStepConfig } from '../../../types/sharedTypes';
import { AccessibleIcon } from '../../../../../AccessibleIcon/AccessibleIcon';

const MAPPED_PROGRESS_ICONS = {
  active: <IconActive />,
  done: <IconDone />,
  pending: <IconPending />
};

function stepState(currentStep: number, targetStep: number) {
  if (currentStep === targetStep) {
    return 'current';
  } else if (currentStep > targetStep) {
    return 'past';
  }
  return 'future';
}

export type LeftNavigationPropTypes = {
  parentUrl: string;
  // Each array item is a step, containing another array of substep ids
  // e.g. [ ["about/1", "about/2"], ["contact/1"]]
  ordered: Array<string[]>;
  subSteps: RenderableStepConfig;
  currentStep: number | string;
  currentSubStep?: number | null;
};

export function LeftNavigation(props: LeftNavigationPropTypes) {
  const { currentStep, currentSubStep, subSteps, ordered, parentUrl } = props;
  const stepOrdering: Record<string, number> = {};

  //Mutate the array
  ordered.forEach((subStepIds, parentIndex) => {
    const parent = subSteps[subStepIds[0]].parent;
    stepOrdering[parent!.id] = parentIndex;
  });

  const mappedSteps = (
    <ul className={styles.List}>
      {ordered.map((subStepIds, index) => {
        const parent = subSteps[subStepIds[0]].parent;
        const state = stepState(stepOrdering[currentStep!], index);
        const itsLastItem = index === ordered.length - 1;
        let statusNode: React.ReactNode = null;

        if (state === 'future') {
          statusNode = MAPPED_PROGRESS_ICONS.pending;
        } else if (subStepIds.length && state === 'current' && typeof currentSubStep === 'number') {
          statusNode = MAPPED_PROGRESS_ICONS.active;
        } else {
          statusNode = MAPPED_PROGRESS_ICONS.done;
        }

        return (
          <li key={index.toString()} className={styles.Item}>
            <section className={styles.ItemSection}>
              <div className={styles.SectionColumn1}>
                <Link
                  to={`${parentUrl}/${parent!.id}/1`}
                  type="primary"
                  disabled={state === 'future'}
                  className={styles.Link}
                  underline
                  active
                >
                  <AccessibleIcon
                    label={
                      <Text
                        id="Wizard.Header.Accessibility.Progress.Label"
                        values={{
                          message: (
                            <>
                              You are currently on <Text id={parent!.titleId} /> page
                            </>
                          )
                        }}
                      />
                    }
                  >
                    {statusNode}
                  </AccessibleIcon>
                </Link>
                <h2
                  className={cx(
                    styles.Title,
                    'kw-weight-bold kw-text-medium',
                    state === 'current' ? styles.TitleActive : styles.TitleInActive
                  )}
                >
                  <Text id={parent!.titleId} />
                </h2>
              </div>
              <div className={styles.SectionColumn2}>
                <If
                  condition={!itsLastItem}
                  do={<div className={cx(styles.VerticalConnector, state === 'past' ? styles.Past : styles.NotPast)} />}
                />
                <If
                  condition={parent!.subtitleId != undefined}
                  do={
                    <p
                      className={cx(
                        styles.SubTitle,
                        'kw-weight-regular kw-text-medium',
                        state === 'current' ? styles.SubTitleActive : styles.SubTitleInActive
                      )}
                    >
                      <Text id={parent!.subtitleId} />
                    </p>
                  }
                />
              </div>
            </section>
          </li>
        );
      })}
    </ul>
  );

  return <nav className={styles.LeftNavigationContainer}>{mappedSteps}</nav>;
}
