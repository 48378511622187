import { UserT } from '@kwara/models/src';
import { useMaxDisbursers } from '../../useMaxDisbursers';

export function useDisbursersActiveStates(firstDisburser: UserT | undefined, secondDisburser: UserT | undefined) {
  const maxDisbursers = useMaxDisbursers();

  const firstDisburserDisbursed = !!firstDisburser;
  const secondDisburserDisbursed = !!secondDisburser;

  const isFirstDisburserActive = !firstDisburserDisbursed;
  const isSecondDisburserRequired = maxDisbursers == 2;
  const isSecondDisburserActive = isSecondDisburserRequired && firstDisburserDisbursed && !secondDisburserDisbursed;

  return { isFirstDisburserActive, isSecondDisburserActive, isSecondDisburserRequired };
}
