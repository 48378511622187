import React from 'react';
import sortBy from 'lodash/fp/sortBy';
import get from 'lodash/fp/get';
import { UseQueryResult } from 'react-query';

import { If } from '@kwara/components/src/If/If';
import { Currency } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { SavingType } from '@kwara/models/src/models/Saving';

import { WizardSectionTitle } from '@kwara/components/src/Wizard/latest/components/WizardSectionTitle';

import { ProductCard } from '../ProductCard';
import { Savings } from '../../pages/Loan/components';
import { HorizontalScroller } from '../HorizontalScroller';

import styles from './index.module.scss';

interface PropTypes {
  savingsR: UseQueryResult<SavingType[]>;
}

export const SavingsHorizontalScroller = ({ savingsR }: PropTypes) => {
  const [shownSavingHistory, { setToTrue, setToFalse }] = useBoolean();
  const mainSavingsId = React.useRef<string | null>(null);

  return (
    <section className={styles['section']}>
      <WizardSectionTitle id="LoanAdd.LoanPortfolio.LoanCategories.AllDeposits.title" />
      <Loadable {...savingsR}>
        {(savings: SavingType[]) => {
          const sortedSavingsByDefaultSavingsProduct = sortBy(
            saving => !get('product.isDefaultSavingsProduct', saving),
            savings
          );
          return (
            <HorizontalScroller<SavingType> data={savings} fallbackId="LoanAdd.LoanPortfolio.Loans.noLoans">
              {({ startIndex, endingIndex }) => {
                const currentSavings = sortedSavingsByDefaultSavingsProduct.slice(startIndex, endingIndex);

                return (
                  <ul className={styles.loansListbox}>
                    {currentSavings.map(saving => {
                      function onInfo() {
                        const isMainSavings = get('product.isDefaultSavingsProduct', saving);

                        if (!isMainSavings) return undefined;

                        return function onInfoHandler() {
                          mainSavingsId.current = saving.id;
                          setToTrue();
                        };
                      }

                      return (
                        <li key={saving.id}>
                          <ProductCard
                            amount={<Currency format="currency" value={saving.balance} />}
                            title={saving.name}
                            onInfo={onInfo()}
                          />
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            </HorizontalScroller>
          );
        }}
      </Loadable>

      <If
        condition={shownSavingHistory}
        do={
          <Savings.Root isOpen={shownSavingHistory} savingsId={mainSavingsId.current} onClose={setToFalse}>
            <Savings.History />
            <Savings.Statement />
          </Savings.Root>
        }
      />
    </section>
  );
};
