//@flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { fields as loanFields } from '@kwara/models/src/models/Loan';
import { SubscribedPaymentSelectField } from '@kwara/components/src/Form';
import { getTransactionTypes, contexts, TransactionChannels } from '@kwara/models/src/models/Transactions';

import { type SubStepComponentProps } from '../../../../components/Wizard';

const fields = loanFields.disbursement;

export const Disbursement = ({ StackChild }: SubStepComponentProps<>) => (
  <StackChild>
    <div className="flex">
      <div className="flex-auto">
        <SubscribedPaymentSelectField
          labelId="LoanAdd.Disbursement.Method.label"
          config={{
            bank: fields.bank,
            bankBranch: fields.branch,
            accountNumber: fields.account,
            showBanksFieldsFor: [TransactionChannels.bankTransfer],
            methods: getTransactionTypes(contexts.LoanDisbursement).values
          }}
          name={fields.mode}
          required
        />
      </div>
    </div>
  </StackChild>
);

const requiredIfBank = {
  isRequired: (_, allData) => get(fields.mode, allData) === TransactionChannels.bankTransfer
};

Disbursement.validate = {
  [fields.mode]: {
    isRequired: () => true
  },
  [fields.bank]: requiredIfBank,
  [fields.branch]: requiredIfBank,
  [fields.account]: requiredIfBank
};
