// @flow

import * as React from 'react';
import cx from 'classnames';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { type EventT } from '@kwara/models/src';

import { EventItem } from './EventItem';
import styles from './index.module.scss';

type Props = {
  events: EventT[]
};

const EventGroup = ({ events }: { events: EventT[] }) => {
  return (
    <ul className={cx('list ma0 pa0 mb4')}>
      {map(
        event => (
          <li key={event.id} className={styles.EventItemWrapper}>
            <EventItem event={event} />
          </li>
        ),
        orderBy<EventT>(events, ['timestamp'], ['desc'])
      )}
    </ul>
  );
};

export const EventTimeline = ({ events }: Props) => {
  const dateGroups = groupBy(event => formatIsoDate(new Date(event.time)), events);

  const dates = Object.keys(dateGroups);

  return (
    <>
      {map<string, React.Node>(date => {
        const events = dateGroups[date];
        return <EventGroup key={date} events={events} />;
      }, dates)}
    </>
  );
};
