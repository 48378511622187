// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';

import { loanPath } from '../../lib/urls';
import Wizard from '../../components/Wizard';
import { steps } from './config';
import { useRepaymentForm } from '../MemberRepayment/useRepaymentForm';

export const LoanPayment = props => {
  const r = useLoan(props.match.params.loanId);
  const queryClient = useQueryClient();
  const { makeRepayment, isTillLoading, isTillOpen } = useRepaymentForm();

  return (
    <Loadable isLoading={isTillLoading}>
      <Loadable {...r}>
        {loan => {
          const { member } = loan;
          const returnTo = loanPath({ id: loan.id });
          return loan.canMakeRepayment() ? (
            <Wizard
              analyticsId="LoanRepayment"
              baseUrl={returnTo}
              history={history}
              initialData={{
                member,
                accountId: loan.id,
                loan,
                isTillOpen
              }}
              cancelReturnsTo={returnTo}
              currentStep="repayment"
              onSubmit={makeRepayment}
              onSubmitCompletion={() => {
                r.refetch();
                queryClient.invalidateQueries('loan_transactions');
                queryClient.invalidateQueries('loan_extras');
                queryClient.invalidateQueries('schedule');
              }}
              steps={steps}
              startId="repayment"
              titleId="LoanRepayment.title"
            />
          ) : (
            <Redirect to={returnTo} />
          );
        }}
      </Loadable>
    </Loadable>
  );
};
