import * as React from 'react';

import { If } from '@kwara/components/src/If/If';
import { Text, DateTime } from '@kwara/components/src/Intl';

type ApprovedAmountMetaPropTypes = {
  note: string;
  approvedBy: string;
  approvedAt: string;
};

export function ApprovedAmountMeta({ note, approvedBy, approvedAt }: ApprovedAmountMetaPropTypes) {
  return (
    <>
      <If
        condition={!!note}
        do={<p className="pa2 b--solid br3 bw1 b--light-gray neutral-900 kw-text-small ma0 mt3">{note}</p>}
      />

      <p className="neutral-600">
        <If
          condition={!!approvedBy && !!approvedAt}
          do={
            <Text
              id="LoanDisbursal.Approvals.approvalMeta"
              values={{
                approvedBy,
                approvedAt: <DateTime value={approvedAt} />
              }}
            />
          }
        />
      </p>
    </>
  );
}
