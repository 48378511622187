import { Lister } from './Lister';
import { Head } from './components/Head';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { TRow } from './components/Trow';
import { COMMON_TABLE_SPANS, COMMON_TABLE_WIDTHS } from './utils';

export default {
  Root: Lister,
  Header: Head,
  Body,
  Footer,
  TRow,
  SPANS: COMMON_TABLE_SPANS,
  WIDTHS: COMMON_TABLE_WIDTHS
};
