//@flow
import React from 'react';
import type { RouterHistory, LocationShape } from 'react-router-dom';
import keys from 'lodash/fp/keys';
import pipe from 'lodash/fp/pipe';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';

import { PageHeading } from '@kwara/components/src/text';
import { CrbDataSubmission, type CrbDataSubmissionT } from '@kwara/models/src';
import { onlyTruthyValues } from '@kwara/lib/src/lodash';
import Button from '@kwara/components/src/Button';
import { Text, DateTime } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import Table, * as table from '../../components/Table';
import OverviewList from '../../components/OverviewList';
import { useDeprecatedPagination, useHistoricalCreditSubmissions } from '../../hooks';
import Wizard from '../../components/Wizard';
import { steps } from './config';
import { creditPath } from '../../lib/urls';
import { CreditSubmitDetails } from './components/SubmitDetails';

type FormData = { [id: string]: boolean };

type CRBHistory = {
  history: RouterHistory
};

type CRBAdd = {
  location: LocationShape
} & CRBHistory;

type CRBProps = {
  match: {
    params: {
      action: ?string
    }
  }
} & CRBAdd;

// i.e. {KB5343RS: true, RJ3714CS: true} => ["KB5343RS", "RJ3714CS"]
export function truthyKeysToArray(c: FormData) {
  return pipe(onlyTruthyValues, keys)(c);
}

const includes = ['user', 'loan_account_crb_submissions'];

export const CreditSubmitHistory = () => {
  const { allData, onNext, hasMore, totalResults, ...r } = useDeprecatedPagination<CrbDataSubmissionT>(
    useHistoricalCreditSubmissions,
    currentPage => [includes, currentPage]
  );

  const sortedItems = orderBy(['createdAt'], ['desc'], allData);

  return (
    <>
      <div className="flex items-center flex-column mt2 mb5">
        <PageHeading translationId="CrbSubmissions.pageHeading" />
      </div>
      <OverviewList
        headerId="CrbSubmissions.heading"
        labelId="CrbSubmissions.entity"
        totalNumResults={totalResults}
        items={sortedItems}
        actions={[
          <Button data-testid="add-new-upload" type="primary" key="upload" to="/credit/submit">
            <Text id="CrbSubmissions.submitCTA" />
          </Button>
        ]}
        table={
          <Table
            heading={
              <table.Row>
                <table.Heading width="50px" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.id" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.submitterName" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.submitterId" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.date" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.size" />
                <table.Heading textSize="regular" translationId="CrbSubmissions.heading.status" />
              </table.Row>
            }
            footer={
              <table.Footer
                colSpan={7}
                isLoading={r.isLoading}
                onNext={onNext}
                hasMore={hasMore}
                errors={r.errors}
                items={sortedItems}
                translationBaseId="LoanListTable"
              />
            }
          >
            {map(entry => {
              const pathTo = creditPath({ id: entry.id });
              return (
                <table.Row to={pathTo} key={entry.id}>
                  <table.Cell to={pathTo} className="grey-400 kw-numeric" />
                  <table.Cell to={pathTo} className="grey-400 kw-numeric">
                    {entry.id}
                  </table.Cell>
                  <table.Cell to={pathTo}>{entry.user.fullName()}</table.Cell>
                  <table.Cell to={pathTo}>{entry.user.id}</table.Cell>
                  <table.Cell to={pathTo}>
                    <DateTime value={entry.createdAt} />
                  </table.Cell>
                  <table.Cell to={pathTo}>{size(entry.loanIds)}</table.Cell>
                  <table.Cell to={pathTo}>
                    <StatusTag state={get('state.current', entry)} />
                  </table.Cell>
                </table.Row>
              );
            }, sortedItems)}
          </Table>
        }
      />
    </>
  );
};

export const CreditSubmitAdd = (props: CRBAdd) => {
  const { location, history } = props;

  const submit = async (data: FormData) => {
    const loanIds = truthyKeysToArray(data);

    const submission = new CrbDataSubmission({ loanIds });

    const didSave = await submission.save();

    if (!didSave) {
      throw submission.errors;
    }
  };

  return (
    <Wizard
      analyticsId="CreditSubmit"
      baseUrl={'/credit'}
      history={history}
      currentStep="submit"
      initialData={{}}
      cancelReturnsTo="/credit"
      onSubmit={submit}
      steps={steps}
      startId="submit"
      titleId="CreditSubmit.title"
      location={location}
    />
  );
};

export const CreditSubmit = (props: CRBProps) => {
  const { action } = props.match.params;

  if (action == null) {
    return <CreditSubmitHistory history={props.history} />;
  }

  if (action === 'submit') {
    return <CreditSubmitAdd history={props.history} location={props.location} />;
  }

  // If the `action` parameter is not `submit` we assume it
  // is the id  of a particular loan submission

  return <CreditSubmitDetails id={action} />;
};
