import { differenceInDays } from '@kwara/lib/src/dates';

import notificationData from '../data/index.json';
import { clientStorage } from '../utils/clientStorage';

export type Announcement = {
  id: string;
  heading: string;
  paragraph: string;
  linkPath: string | null;
  imgSrc: string | null;
  date: string;
  permissions: string[];
};

export type DataType = {
  announcements: Announcement[] | null;
};

interface NotificationSystemI {
  data: DataType;
  getReadIds(): null | string[];
}

const KWARA_WEBAPP_DELETED_NOTIFICATION_IDS_LOCAL_STORAGE_KEY = 'kwaraWebappSaccoDeletedNotificationIds';
const KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY = 'kwaraWebappSaccoReadNotificationIds';
const KWARA_WEBAPP_NOTIFICATION_PERSISTENCY_START_DATE_LOCAL_STORAGE_KEY =
  'kwaraWebappSaccoNotificationPersistencyStartDateLocalStorageService';

/**
 * @final is a replica of final in other OOP language
 * to restricting modification of parent source
 */
function final(_: Object, __: string | symbol, descriptor: PropertyDescriptor) {
  descriptor.writable = false;
}
export class NotificationSystem implements NotificationSystemI {
  private _data: DataType = notificationData;

  constructor(data?: DataType) {
    if (data !== undefined) this._data = data;
  }

  /**
   * @getDeletedAnnouncementsIds
   */
  public static getDeletedAnnouncementsIds(): string[] | null {
    return clientStorage.get<string[]>(KWARA_WEBAPP_DELETED_NOTIFICATION_IDS_LOCAL_STORAGE_KEY);
  }

  /**
   * @data
   */
  public set data(data: DataType) {
    this._data = data;
  }

  public get data(): DataType {
    const deletedIds = NotificationSystem.getDeletedAnnouncementsIds();

    if (deletedIds == null) return { ...this._data, announcements: this._data.announcements };

    return {
      ...this._data,
      announcements: this._data.announcements.filter(announcement => !deletedIds.includes(announcement.id))
    };
  }

  /**
   * @getReadIds as past tense like red
   */
  @final
  public getReadIds(): null | string[] {
    return clientStorage.get<string[]>(KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY);
  }

  /**
   * @onSetNotificationSystemPersistencyStartDate
   */
  private static onSetNotificationSystemPersistencyStartDate() {
    clientStorage.set<Date>(KWARA_WEBAPP_NOTIFICATION_PERSISTENCY_START_DATE_LOCAL_STORAGE_KEY, new Date());
  }

  /**
   * @isOver31DaysNotificationStatusGotPersisted this function
   * simply checks if its over 31 days we stored the notification state
   * in the memory
   */
  private static isOver31DaysNotificationStatusGotPersisted(persistencyStartDateStr: string) {
    return differenceInDays(persistencyStartDateStr, new Date()) > 31;
  }

  /**
   * @shouldResetNotificationSystem
   */
  private static shouldResetNotificationSystem() {
    const persistencyStartDateStr = clientStorage.get<string>(
      KWARA_WEBAPP_NOTIFICATION_PERSISTENCY_START_DATE_LOCAL_STORAGE_KEY
    );

    if (persistencyStartDateStr == null) return false;

    return NotificationSystem.isOver31DaysNotificationStatusGotPersisted(persistencyStartDateStr);
  }

  /**
   * @onResetNotificationSystem
   */
  public static onResetNotificationSystem() {
    if (NotificationSystem.shouldResetNotificationSystem()) {
      clientStorage.remove(KWARA_WEBAPP_DELETED_NOTIFICATION_IDS_LOCAL_STORAGE_KEY);
      clientStorage.remove(KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY);
      clientStorage.remove(KWARA_WEBAPP_NOTIFICATION_PERSISTENCY_START_DATE_LOCAL_STORAGE_KEY);
    }
  }

  /**
   * @onDeleteAnnouncement
   */
  public static onDeleteAnnouncement(id: string, onDeletedSuccess: (deletedIds: string[]) => void) {
    const deletedIds = NotificationSystem.getDeletedAnnouncementsIds();
    const allDeletableIds = deletedIds != null ? [...deletedIds, id] : [id];

    clientStorage.set<string[]>(KWARA_WEBAPP_DELETED_NOTIFICATION_IDS_LOCAL_STORAGE_KEY, allDeletableIds);
    NotificationSystem.onSetNotificationSystemPersistencyStartDate();
    onDeletedSuccess(allDeletableIds);
  }

  /**
   * @onReadAnnouncement
   */
  public static onReadAnnouncement(id: string, onReadSuccess: (id: string) => void) {
    const readIds = clientStorage.get<string[]>(KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY);
    const allReadIds = readIds != null ? [...readIds, id] : [id];

    clientStorage.set<string[]>(KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY, allReadIds);
    NotificationSystem.onSetNotificationSystemPersistencyStartDate();
    onReadSuccess(id);
  }

  /**
   * @onMarkAllAsRead
   */
  public static onMarkAllAnnouncementAsRead(
    announcements: Announcement[],
    onMarkAllAnnouncementAsReadSuccess: (ids: string[]) => void
  ) {
    const ids = announcements.map(a => a.id);

    clientStorage.set<string[]>(KWARA_WEBAPP_READ_NOTIFICATION_IDS_LOCAL_STORAGE_KEY, ids);
    onMarkAllAnnouncementAsReadSuccess(ids);
    NotificationSystem.onSetNotificationSystemPersistencyStartDate();
  }
}
