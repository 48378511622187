import React from 'react';
import compact from 'lodash/fp/compact';

import { Text } from '@kwara/components/src/Intl';
import { FileDropzone } from '@kwara/components/src/FileDropzone';

import { Grid } from '../../../components/Grid';
import type { SubStepComponentProps } from '@kwara/components/src/Wizard/deprecated/Wizard';
import { AllGeneralLedgerAccountCombobox, SubscribedDatePicker } from '../../../components/Form';

const allowedExtensions = ['.CSV', '.XLS', '.XLSX'].join();

const fileTypes = compact([
  <Text id="FileTypes.csv" />,
  '/',
  <Text id="FileTypes.xls" />,
  '/',
  <Text id="FileTypes.xlsx" />
]);

const disabledDays = {
  after: new Date()
};

type Data = {
  from: string,
  to: string,
  glAccountId?: string
};

interface ReconciliationFormPropTypes extends SubStepComponentProps<Data> {}

export const ReconciliationForm = (props: ReconciliationFormPropTypes) => {
  const { data, addData, formProps, StackChild } = props;
  const values: Data = formProps.values;
  const disableFileInput = !values.from || !values.to || !values.glAccountId;

  return (
    <StackChild>
      <Grid className="mt3" columns={3} width="w-33" border={false}>
        <AllGeneralLedgerAccountCombobox
          required
          name="glAccountId"
          labelId="Finance.Reconciliations.glAccountId.label"
        />
        <SubscribedDatePicker
          required
          name="from"
          labelId="Finance.Reconciliations.from.label"
          disabledDays={disabledDays}
        />
        <SubscribedDatePicker
          required
          name="to"
          labelId="Finance.Reconciliations.to.label"
          disabledDays={disabledDays}
        />
      </Grid>
      <FileDropzone
        name="file"
        classNames="mt4"
        file={data.file}
        accept={allowedExtensions}
        disabled={disableFileInput}
        title={`Allowed file types are ${allowedExtensions}`}
        label={
          <>
            <Text id="FileDropzone.selectFileLabel" /> ({fileTypes})
          </>
        }
        onChange={async e => {
          const file = e.target.files[0];
          if (file) {
            addData({ file });
            formProps.form.submit();
          }
        }}
      />
    </StackChild>
  );
};

ReconciliationForm.validate = {
  glAccountId: {
    isRequired: () => true
  },
  from: {
    isRequired: () => true
  },
  to: {
    isRequired: () => true
  },
  file: {
    isRequired: () => true
  }
};
