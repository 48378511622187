import * as React from 'react';
import cx from 'classnames';

import { GenericPolymorphic } from '@kwara/components/src/GenericPolymorphic';

type HeadingPropTypes = {
  className?: string;
  children: React.ReactNode;
  as?: HTMLHeadingElement;
};

export function Heading({ as, className, children }: HeadingPropTypes) {
  return (
    <GenericPolymorphic as={as ?? 'h2'} className={cx('neutral-600 kw-text-large kw-weight-bold', className)}>
      {children}
    </GenericPolymorphic>
  );
}
