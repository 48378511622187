import * as React from 'react';
import cx from 'classnames';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import zIndices from '@kwara/lib/src/zIndices';
import Logo from '@kwara/components/src/Logo';

import { Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';
import { Accordion } from '@kwara/components/src/Accordion/Accordion';

import { Header as BaseHeader } from '../../..';
import { memberPath } from '../../../../lib/urls';
import { NonCtoRbac } from '../../../../components/RBAC/CTO';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';
import { useNotificationSystemCommands } from '../../../../components/NotificationSystem';
import { usePermissions } from '../../../../hooks/usePermissions/usePermissions';

import MemberSearch from '../../../../components/MemberSearch';
import NotificationSystem from '../../../../components/NotificationSystem';

import styles from './index.module.scss';

type HeaderPropTypes = {
  logOut(): Promise<void>;
};

export function Header(props: HeaderPropTypes) {
  const { AppPermissions, permission } = usePermissions();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    profile: {
      email,
      branch: { name: branchName }
    }
  } = useSaccoProfileContext();
  const { onResetNotificationSystem } = useNotificationSystemCommands();

  function onLogout() {
    props.logOut();

    onResetNotificationSystem();

    queryClient.clear();
  }

  return (
    <BaseHeader className={cx(styles['container'], zIndices.Navigation)}>
      <div className={styles['container__column1']}>
        <Logo className="mr3" />

        <NonCtoRbac>
          <If
            condition={permission.to(AppPermissions.ShowMember)}
            do={
              <MemberSearch
                collapsed
                floatResults
                resultType="nonFinancial"
                showResultCount={false}
                data-cy="member-search-navbar"
                onSelect={member => history.push(memberPath({ id: member.id }))}
              />
            }
          />
        </NonCtoRbac>
      </div>

      <div className={styles['container__column2']}>
        <span className={styles['notification-wrapper']}>
          <NotificationSystem.Root>
            <NotificationSystem.Icon />
            <NotificationSystem.Announcement.Root>
              <NotificationSystem.Announcement.Header />
              <NotificationSystem.Announcement.Body />
            </NotificationSystem.Announcement.Root>
          </NotificationSystem.Root>
        </span>

        <Accordion
          title={<If condition={email != undefined} do={email} else={branchName} />}
          className={styles['email-accordion']}
          headerClassName={cx(styles['email-accordion__header'], 'kw-text-medium')}
          bodyClassName={cx(styles['email-accordion__body'], zIndices.OverlayMenus)}
        >
          <ul role="menu" tabIndex={0}>
            <li className="pointer">{branchName}</li>

            <li tabIndex={0}>
              <button type="button" onClick={onLogout}>
                <Text id="navigation.logOut" />
              </button>
            </li>
          </ul>
        </Accordion>
      </div>
    </BaseHeader>
  );
}
