import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';

import { ActionButton } from '@kwara/components/src/Button';
import { Currency, Text, Percent } from '@kwara/components/src/Intl';
import { FeeType } from '@kwara/models/src/models/LoanProduct';
import { If } from '@kwara/components/src/If/If';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import styles from '../index.module.scss';

type Props = {
  fees: FeeType[];
  getFeeApplication: (id: string) => FeeApplicationT;
  onRemoveFee: (id: string) => Promise<void>;
  className?: string;
};
export function SelectedFees({ fees, getFeeApplication, onRemoveFee, className }: Props) {
  return (
    <div aria-label="Selected Fees" className={cx(styles.feeList, className)}>
      {map(fee => {
        const feeApplication = getFeeApplication(fee.id);

        return (
          <div className="flex" key={fee.name}>
            <span className={styles.square}></span>
            <span className={'ml2'}>{fee.name}</span>
            <div className={cx(styles.FeeAmount, 'kw-text-x-small neutral-400')}>
              <div className="ph1">
                <If
                  condition={fee.amountCalculationMethod === 'FLAT'}
                  do={<Currency format="currency" value={fee.amount || feeApplication.amount} />}
                  else={
                    <Text
                      id="LoanAdd.LoanConfigure.Fees.checkbox.amount"
                      values={{
                        percentage: <Percent value={Number((fee.percentageAmount as unknown) as number) / 100} />
                      }}
                    />
                  }
                />
              </div>
            </div>
            <span className={cx('ml2', styles.icon)}>
              <ActionButton
                size="tiny"
                hideBorder
                col="red500"
                type="cancel"
                onClick={async () => await onRemoveFee(fee.id)}
              />
            </span>
          </div>
        );
      }, fees)}
    </div>
  );
}
