import filter from 'lodash/fp/filter';
import size from 'lodash/fp/size';

import { useMemo } from 'react';

import { CheckableItem, CheckableItemT } from '@kwara/models/src/models/CheckableItem';

type ArgType = {
  isLoading: boolean;
  filterState: string;
  checkableItems: Array<CheckableItemT>;
};

export function usePendingEntriesCount({ filterState, checkableItems, isLoading }: ArgType) {
  const pendingCount = useMemo(() => {
    const pendingItems = filter(item => item.isPending(), checkableItems);
    const shouldCountPendingEntries = !isLoading && filterState === CheckableItem.states.pending;

    return shouldCountPendingEntries ? size(pendingItems) : 0;
  }, [checkableItems, filterState, isLoading]);

  return pendingCount;
}
