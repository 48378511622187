// @flow
import isEmpty from 'lodash/fp/isEmpty';

import { type LoanType, type UserT } from '@kwara/models/src';

import { type StepConfig } from '../../components/Wizard';
import Overview from './components/Overview';
import { LoanDetail } from './components/LoanDetail';
import Member from './components/Member';
import CreditCheck, { IdentityCheckResult } from './components/CreditCheck';
import { Approve } from './components/Approve';
import Reject from './components/Reject';
import { AppPermissionsType, ToHelperType } from '../../models/Permission/types';

export const createSteps = (
  loan: LoanType,
  currentUser: UserT,
  to: ToHelperType,
  AppPermissions: AppPermissionsType
): StepConfig => {
  const { loanApplication } = loan;

  const canApprove = to(AppPermissions.ApproveLoans);
  const canReject = to(AppPermissions.RejectLoans);

  const canReview = canApprove || canReject;

  const alreadyApproved = loanApplication && loanApplication.alreadyApprovedBy(currentUser);

  const wrongRole = loanApplication && !loanApplication.canBeApprovedBy(currentUser);

  const notPermitted = !canReview || alreadyApproved || wrongRole;

  const isDecidingVote = !loanApplication || loanApplication.isFinalReview();

  const security = isEmpty(loan.guarantors)
    ? null
    : {
        titleId: 'LoanApprove.Overview.security',
        children: loan.guarantors.map(guarantor => ({
          Component: Member,
          actions: [
            {
              appearsAs: 'skip',
              behavesAs: 'skip',
              destination: 'creditCheck'
            }
          ],
          customProps: {
            type: Member.Type.guarantor,
            memberId: guarantor.member.id
          }
        })),
        actions: [
          {
            appearsAs: 'review',
            behavesAs: 'next',
            destination: 'creditCheck'
          }
        ]
      };

  const steps: StepConfig = {
    overview: {
      Component: Overview,
      progress: false,
      actions: [
        {
          appearsAs: 'review',
          behavesAs: 'next',
          destination: 'details',
          isPermitted: !notPermitted
        }
      ]
    },
    details: {
      titleId: 'LoanApprove.Overview.loan',
      children: [
        {
          Component: LoanDetail,
          customProps: {
            loanId: loan.id
          }
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'applicant'
        }
      ]
    },
    applicant: {
      titleId: 'LoanApprove.Overview.applicant',
      children: [
        {
          Component: Member,
          customProps: {
            type: Member.Type.applicant,
            memberId: loan.member.id
          }
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: security ? 'security' : 'creditCheck'
        }
      ]
    },
    creditCheck: {
      titleId: 'LoanApprove.CreditCheck.shortTitle',
      children: [
        {
          titleId: 'LoanApprove.CreditCheck.title',
          subtitleId: 'LoanApprove.CreditCheck.subtitle',
          Component: CreditCheck,
          validate: CreditCheck.validate,
          actions: [
            {
              appearsAs: 'skip',
              behavesAs: 'skip',
              destination: 'review'
            }
          ]
        },
        {
          titleId: 'LoanApprove.CreditCheck.title',
          subtitleId: 'LoanApprove.CreditCheck.subtitle',
          Component: IdentityCheckResult
        }
      ],
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'review'
        }
      ]
    },
    review: {
      titleId: 'LoanApprove.Review.shortTitle',
      children: [
        {
          Component: Overview,
          progress: false
        }
      ],
      actions: [
        {
          appearsAs: 'approve',
          behavesAs: 'next',
          destination: 'approve'
        },

        {
          appearsAs: 'reject',
          behavesAs: 'next',
          destination: 'reject'
        }
      ]
    },
    approve: {
      isPermitted: canApprove,
      titleId: 'LoanApprove.Approve.title',
      subtitleId: isDecidingVote ? 'LoanApprove.Approve.subtitle.stillPending' : 'LoanApprove.Approve.subtitle',
      Component: Approve,
      progress: false,
      actions: [
        {
          appearsAs: 'approve',
          behavesAs: 'approve'
        }
      ]
    },
    reject: {
      isPermitted: canReject,
      titleId: 'LoanApprove.Reject.title',
      subtitleId: 'LoanApprove.Reject.subtitle',
      Component: Reject,
      progress: false,
      validate: Reject.validate,
      actions: [
        {
          appearsAs: 'reject',
          behavesAs: 'reject'
        }
      ]
    }
  };

  if (security != null) {
    steps.security = security;
  }

  return steps;
};
