import { useQuery } from 'react-query';
import { RepaymentAmount } from '@kwara/models/src';

async function getRepaymentAmount({ queryKey }) {
  const [_, loanProductId, payload] = queryKey;

  const scope = RepaymentAmount(loanProductId);
  const res = await scope.where(payload).find();
  return res.data;
}

export const useRepaymentAmount = (loanProductId: string, payload, enabled: boolean = true) => {
  const allParamsPresent = !!payload.principal_amount;
  return useQuery(['calculated_loan_repayment', loanProductId, payload], getRepaymentAmount, {
    enabled: allParamsPresent && enabled
  });
};
