import { ApiError, ValueOf } from 'GlobalTypes';

import { TYPES } from './types';

export type NamesPayload = { namesOfPeopleUsingPhoneNumber: Array<string> | null };

export type ErrorPayload = { error: ApiError | null };

type ActionType = { type: ValueOf<typeof TYPES>; payload?: NamesPayload | ErrorPayload };

type StateType = {
  isLoading: boolean;
  error: ApiError | null;
  isPhoneNumberInUse: boolean;
  namesOfPeopleUsingPhoneNumber: Array<string> | null;
};

export function verifyPhoneNumberReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case TYPES.POSTING_STARTED:
      return { ...state, isLoading: true };
    case TYPES.POSTING_SUCCESSFUL:
      return {
        isLoading: false,
        error: null,
        isPhoneNumberInUse: true,
        namesOfPeopleUsingPhoneNumber: (action.payload as NamesPayload).namesOfPeopleUsingPhoneNumber
      };
    case TYPES.POSTING_FAILED:
      return {
        isLoading: false,
        error: (action.payload as ErrorPayload).error,
        isPhoneNumberInUse: false,
        namesOfPeopleUsingPhoneNumber: null
      };
    default:
      return state;
  }
}
