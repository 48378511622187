import React from 'react';
import head from 'lodash/fp/head';
import pick from 'lodash/fp/pick';

import Banner from '@kwara/components/src/Banner';
import Button from '@kwara/components/src/Button';

import { Text } from '@kwara/components/src/Intl';

import { LoanAddPropTypes } from '../../..';
import { loanPath } from '../../../../../../lib/urls';
import { store } from '../../../../../../models/Store/Store';
import { LoanConfigureUtils } from '../utils/loanConfigureUtils';

export const LoanBanner = ({ data }: LoanAddPropTypes) => {
  if (store.refinanceEnabled && LoanConfigureUtils.hasExactLoan(data)) {
    const firstLoan = head(LoanConfigureUtils.matchingLoans(data));
    const { id, name } = pick(['id', 'product.name'], firstLoan);

    return (
      <Banner
        state="warning"
        className="mb4"
        text={
          <div>
            <div className="mb3">
              <Text id="LoanAdd.Configure.Banner.ExactLoanMatch" values={{ id, name }} />
            </div>
            <div className="flex justify-center">
              <Button to={loanPath({ id })} type="primary">
                <Text id="LoanAdd.Configure.Banner.RefinanceButton" values={{ id }} />
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  return null;
};
