import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import reject from 'lodash/fp/reject';
import pipe from 'lodash/fp/pipe';

import Empty from '@kwara/components/src/Empty';
import createValidator from '@kwara/lib/src/validator';
import { SubscribedSelectField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import Banner from '@kwara/components/src/Banner';

import { ROUTES } from '../../../../../routes';
import { Panel } from '../../../../../components/ActionModal';
import { useTills } from '../../../../../models/hooks';
import { useTillUIContext } from '../../..';
import styles from './index.module.scss';

interface LocationState {
  startTillSession?: boolean;
}

function getUnoccupied(tills) {
  return reject(till => !!till.occupant, tills);
}

function getPermitted(tills) {
  return filter(till => till.isPermitted, tills);
}

function getAvailable(tills) {
  return pipe(getUnoccupied, getPermitted)(tills);
}

export function ConfirmationOpen() {
  const history = useHistory();
  const location = useLocation();
  const { updateModal, startTillSession } = useTillUIContext();
  const r = useTills();

  return (
    <Modal ariaLabel="Confirm Start Session" isOpen>
      <div className="flex w-100 h-100 items-center justify-center">
        <Panel className={styles.Panel}>
          <Loadable {...r}>
            {tills => (
              <Form
                onSubmit={startTillSession}
                initialValues={{ tillId: get('[0].id', getAvailable(tills)) }}
                validate={createValidator({
                  tillId: {
                    isRequired: () => true
                  }
                })}
                render={({ valid, form, submitting }) => {
                  return (
                    <>
                      <Loadable {...r}>
                        {tills => {
                          if (isEmpty(tills)) {
                            return (
                              <>
                                <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                                  <Text id="Till.Confirmation.Modal.Start.empty.subtitle" />
                                </h2>
                                <Empty>
                                  <Text id="Till.Confirmation.Modal.Start.empty.text" />
                                </Empty>
                              </>
                            );
                          }

                          if (isEmpty(getPermitted(tills))) {
                            return (
                              <>
                                <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                                  <Text id="Till.Confirmation.Modal.Start.unpermitted.subtitle" />
                                </h2>
                                <Empty>
                                  <Text id="Till.Confirmation.Modal.Start.unpermitted.text" />
                                </Empty>
                              </>
                            );
                          }

                          return (
                            <>
                              <div className="flex justify-between">
                                <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                                  <Text id="Till.Confirmation.Modal.Start.main" />
                                </h2>
                              </div>

                              {isEmpty(getUnoccupied(tills)) ? (
                                <Banner
                                  className="mb2"
                                  state="warning"
                                  text={<Text id="Till.Confirmation.Modal.Start.allTillsOccupied" />}
                                />
                              ) : (
                                <p className="kw-weight-light">
                                  <Text id="Till.Confirmation.Modal.Start.subtitle" />
                                </p>
                              )}

                              <SubscribedSelectField name="tillId" labelId="Till.Confirmation.Modal.Start.tillId.label">
                                {map(till => {
                                  return (
                                    <SubscribedSelectField.Option
                                      disabled={!!till.occupant}
                                      value={till.id}
                                      key={till.id}
                                    >
                                      {till.name} {till.occupant ? `(${till.occupant.fullName()})` : null}
                                    </SubscribedSelectField.Option>
                                  );
                                }, getPermitted(tills))}
                              </SubscribedSelectField>
                            </>
                          );
                        }}
                      </Loadable>

                      <ButtonBar
                        className="mt4"
                        left={
                          <Button
                            onClick={() => {
                              updateModal({ activeModal: null });

                              if (!!(location.state as LocationState)?.startTillSession) {
                                history.push(ROUTES.till);
                              }
                            }}
                          >
                            <Text id="Till.Confirmation.Modal.Start.cancel" />
                          </Button>
                        }
                        right={
                          <Button disabled={!valid || submitting} classNames="mr3" type="primary" onClick={form.submit}>
                            <Text id="Till.Confirmation.Modal.Start.confirm" />
                          </Button>
                        }
                      />
                    </>
                  );
                }}
              />
            )}
          </Loadable>
        </Panel>
      </div>
    </Modal>
  );
}
