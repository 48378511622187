import * as React from 'react';

import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';

import { LoanProductType } from '@kwara/models/src';
import { FeeType } from '@kwara/models/src/models/LoanProduct';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

export function getSelectedFeeApplications({
  feeApplications = [],
  fees = []
}: {
  feeApplications: FeeApplicationT[];
  fees: FeeType[];
}) {
  const validProductFeeIds = fees.map(fee => fee.id);
  return feeApplications
    .filter((fee: FeeApplicationT) => !fee.isMarkedForDestruction)
    .filter((fee: FeeApplicationT) => includes(fee.feeId, validProductFeeIds));
}

export function getTotalAdditionalFees({
  selectedFeeApplications,
  getFee,
  principalAmount
}: {
  selectedFeeApplications: FeeApplicationT[];
  getFee: (id: string) => FeeType;
  principalAmount: string;
}) {
  return selectedFeeApplications.reduce((prev: number, currentFeeApplication: FeeApplicationT) => {
    if (currentFeeApplication.amount) {
      return Number(prev) + Number(currentFeeApplication.amount);
    }

    const fee = getFee(currentFeeApplication.feeId);

    if (fee?.amount) {
      return Number(prev) + Number(fee.amount);
    }

    if (fee?.percentageAmount) {
      const principal = Number(principalAmount) || 0;
      const percentage = Number(fee.percentageAmount) / 100;

      return Number(prev) + Number(principal * percentage);
    }

    return Number(prev);
  }, 0);
}

declare interface ArgsT {
  product: LoanProductType;
  feeApplications: FeeApplicationT[];
  amount: string;
}

function getFeeApplication(feeApplications: FeeApplicationT[]) {
  return (id: string) => {
    return find(feeApplication => feeApplication.feeId === id, feeApplications);
  };
}

export function feeValues({ product, feeApplications, amount }: ArgsT) {
  const { fees = [] } = product;
  const getFee = (id: string) => find((fee: FeeType) => fee.id === id, fees);

  const selectedFeeApplications = getSelectedFeeApplications({ feeApplications, fees });
  const selectedFeeIds = selectedFeeApplications.map((fee: FeeApplicationT) => fee.feeId);
  const selectedFees = fees.filter((fee: FeeType) => includes(fee.id, selectedFeeIds));

  const isFeeNotSelected = (fee: FeeType) => !includes(fee.id, selectedFeeIds);
  const isFeeOptional = (fee: FeeType) => fee.feeApplication === 'OPTIONAL';

  const totalAdditionalFees = getTotalAdditionalFees({ selectedFeeApplications, getFee, principalAmount: amount });

  return {
    fees,
    feeApplications,
    selectedFees,
    selectedFeeApplications,
    getFee,
    getFeeApplication: getFeeApplication(feeApplications),
    totalAdditionalFees,
    isFeeNotSelected,
    isFeeOptional
  };
}

export function useFeeValues({ product, feeApplications, amount }: ArgsT) {
  return React.useMemo(() => feeValues({ product, feeApplications, amount }), [product, feeApplications, amount]);
}
