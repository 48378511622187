import { useRef } from 'react';

import Api from '@kwara/models/src/lib/Api';
import Auth, { TOKEN_KEY } from '@kwara/models/src/lib/Auth';

import config from '../../config';
import { KwaraPermissionsConfigKeyType } from '../../models/Permission/types';

export type AuthType = Auth<KwaraPermissionsConfigKeyType>;
export type ApiType = Api;

const api = new Api({ apiRoot: config.API_ROOT });
const auth = new Auth<KwaraPermissionsConfigKeyType>({ api });

function useAuth(authArg?: AuthType) {
  const { current } = useRef(authArg || auth);

  return current;
}

export { TOKEN_KEY, useAuth, auth };
