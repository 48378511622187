import * as React from 'react';

type PrimitiveSvgPropTypes = React.ComponentPropsWithoutRef<'svg'>;
type IconDownloadElement = React.ElementRef<'svg'>;

interface IconDownloadPropTypes extends PrimitiveSvgPropTypes {}

/**************************************************************
 * @IconDownload
 * This component is a download symbol svg icon that can be
 * reused to suit your use cases e.g changing the```fill```,
 * ```width```, ```height```, targeting the element and etc
 **/

export const IconDownload = React.forwardRef<IconDownloadElement, IconDownloadPropTypes>(function IconDownload(
  { fill, width, height, ...restProps },
  forwardedRef
) {
  return (
    <svg
      {...restProps}
      ref={forwardedRef}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 7.99996V9.99996H1.99999V7.99996H0.666656V9.99996C0.666656 10.7333 1.26666 11.3333 1.99999 11.3333H9.99999C10.7333 11.3333 11.3333 10.7333 11.3333 9.99996V7.99996H9.99999ZM9.33332 5.33329L8.39332 4.39329L6.66666 6.11329V0.666626H5.33332V6.11329L3.60666 4.39329L2.66666 5.33329L5.99999 8.66663L9.33332 5.33329Z"
        fill={fill}
      />
    </svg>
  );
});

IconDownload.defaultProps = {
  fill: '#1E293B',
  width: '12',
  height: '12'
};
