import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';

import { appName } from '@kwara/lib/src/utils';
import { configureModels } from '@kwara/models/src';
import { configureStores } from '@kwara/components/src/configureStores';
import { prepareTranslations } from '@kwara/components/src/Intl/prepareTranslations';
import { Internationalization as I18nProvider } from '@kwara/components/src/Intl/Internationalization';
import { NotificationProvider } from '@kwara/components/src/Notification/NotificationProvider';

import config from '../config';
import en from '../translations/en.json';
import { store } from '../models/Store/Store';
import { TOKEN_KEY, AuthType, useAuth } from '../hooks';
import { TillUIProvider } from '../pages/Till/TillProvider';
import { SaccoProfileProvider } from '../models/Profile/ProfileProvider';
import { PasswordExpiryListener } from '../pages/LogIn/components/PasswordExpiryAlerter';

configureModels({ apiBaseUrl: config.API_BASE_URL, jwtStorageKey: TOKEN_KEY });
configureStores(store);

appName.current = config.appName;

const $saccoClient = new QueryClient();

type Props = {
  auth?: AuthType;
  children: React.ReactElement;
};

export function SaccoStore({ auth: authProps, children }: Props) {
  const child = React.Children.only(children);
  const auth = useAuth(authProps);

  return (
    <QueryClientProvider client={$saccoClient}>
      <I18nProvider messages={prepareTranslations(en)}>
        <NotificationProvider>
          <PasswordExpiryListener>
            <SaccoProfileProvider auth={auth}>
              <TillUIProvider auth={auth}>{child}</TillUIProvider>
            </SaccoProfileProvider>
          </PasswordExpiryListener>
        </NotificationProvider>
      </I18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
