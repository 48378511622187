import { useState } from 'react';
import last from 'lodash/fp/last';
import compact from 'lodash/fp/compact';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { CollateralT, GuaranteeType } from '@kwara/models/src';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { removeNullishValues } from '@kwara/lib/src/utils/removeNullishValues';

import { LoanTopupFormTypes } from '.';
import { useGetWriteOffBalanceData } from './components/Balance/useGetWriteOffBalanceData';

///
type FormattedCollateral = {
  type: 'collaterals';
  id: string;
  attributes: {
    asset_name?: string;
    amount?: string;
    insurance_company?: string;
    insurance_policy_number?: string;
    collateral_narration?: string;
    //attachments: LoanAttachmentT[];
  };
};
function formatCollaterals(collaterals?: CollateralT[]): null | FormattedCollateral[] {
  if (!collaterals) return null;

  return collaterals.reduce((acc: FormattedCollateral[], curr) => {
    if (!curr.isMarkedForDestruction) {
      acc.push({
        type: 'collaterals',
        id: curr.id,
        attributes: removeNullishValues({
          asset_name: curr.assetName,
          amount: curr.amount,
          insurance_company: curr.insuranceCompany,
          insurance_policy_number: curr.insurancePolicyNumber,
          collateral_narration: curr.collateralNarration
          //attachments: curr.attachments
        })
      });
    }
    return acc;
  }, []);
}

///
type FormattedGuarantors = {
  type: 'guarantors';
  id: string;
  attributes: {
    amount: string;
    member_id: string;
  };
};
function formatGuarantors(guarantors?: GuaranteeType[]): null | FormattedCollateral[] {
  if (!guarantors) return null;

  return guarantors.reduce((acc: FormattedGuarantors[], curr) => {
    if (!curr.isMarkedForDestruction) {
      acc.push({
        type: 'guarantors',
        id: curr.id,
        attributes: {
          amount: curr.amount,
          member_id: curr.memberId
        }
      });
    }
    return acc;
  }, []);
}

/**
 * @useSubmit
 */
export function useSubmit() {
  const [id, setId] = useState(null);
  const getWriteOffAmount = useGetWriteOffBalanceData();

  async function onSubmit(values: LoanTopupFormTypes) {
    const {
      loan,
      topupAmount,
      notes,
      anticipatedDisbursementDate,
      loanDuration,
      repaymentPeriod,
      repaymentPeriodUnit,
      desiredInterestBalance,
      desiredFeesBalance,
      desiredPenaltiesBalance,
      collaterals,
      guarantors,
      classifications
    } = values;

    const loanClassificationId = last(compact(classifications));

    const payload = {
      data: {
        attributes: removeNullishValues({
          topup_amount: topupAmount,
          expected_disbursement_date: formatIsoDate(new Date(anticipatedDisbursementDate)),
          first_repayment_date: formatIsoDate(new Date(loan.firstRepaymentDate)),
          repayment_installments: calculateNumberInstallments({
            loanDuration: Number(loanDuration),
            period: Number(repaymentPeriod)
          }),
          repayment_period: repaymentPeriod,
          repayment_period_unit: repaymentPeriodUnit,
          write_off_amounts: {
            fee: getWriteOffAmount(loan.fees.balance, desiredFeesBalance).value,
            interest: getWriteOffAmount(loan.interest.balance, desiredInterestBalance).value,
            penalty: getWriteOffAmount(loan.penalties.balance, desiredPenaltiesBalance).value
          },
          collaterals: formatCollaterals(collaterals),
          guarantors: formatGuarantors(guarantors),
          loan_classification_id: loanClassificationId,
          notes
        })
      }
    };

    const response = await loan.topup(payload);

    if (response) setId(response.id);
    else throw loan.errors;
  }

  return { id, onSubmit };
}
