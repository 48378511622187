import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import _values from 'lodash/fp/values';

import { Text } from '@kwara/components/src/Intl';
import { groupPermissions, groups } from '@kwara/models/src/models/AppPermission';

import { Panel as BasicPanel } from '../../../components/ActionModal';

const Panel = ({ titleId, children }) => {
  return (
    <BasicPanel>
      <div className="pb4 kw-text-extra-large kw-weigt-bold">
        <Text id={titleId} />
      </div>
      {children}
    </BasicPanel>
  );
};

export const AppRoleForm = ({ StackChild, TextField, TextArea, Checkbox, data }) => {
  const { allAppPermissions } = data;

  const groupedPermissions = groupPermissions(allAppPermissions);

  return (
    <StackChild>
      <Panel titleId="AppRoleWizard.descriptionPanel.title">
        <TextField name="appRole.name" labelId="AppRoleWizard.name.label" required />
        <TextArea
          name="appRole.description"
          labelId="AppRoleWizard.description.label"
          infoId="AppRoleWizard.description.info"
        />
      </Panel>

      {map(group => {
        return (
          <Panel key={group} titleId={`AppRoleWizard.permissionGroup.${group}`}>
            {map(permission => {
              return (
                <Checkbox
                  key={permission.id}
                  margin={false}
                  labelId={`AppRoleWizard.permissions.${permission.name}`}
                  name={`permissions.${permission.name}`}
                  showInfo={false}
                />
              );
            }, get(group, groupedPermissions))}
          </Panel>
        );
      }, groups)}
    </StackChild>
  );
};

AppRoleForm.validate = {
  'appRole.name': {
    isRequired: () => true,
    noSpecialCharacters: true,
    maxlength: 80
  },
  'appRole.description': {
    maxlength: 200
  },
  atLeastOneChecked: {
    isRequired: () => true,
    custom: (_, allData) => {
      const { permissions } = allData;

      if (some(v => v, _values(permissions))) {
        return null;
      }
      return 'Error';
    }
  }
};
