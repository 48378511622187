// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import PageLayoutWithOverview from '@kwara/components/src/PageLayoutWithOverview';

import { Overview } from './components/Overview';
import { useBatchTransactionImport } from '../../hooks';
import { BatchTransactionsForm } from './components/BatchTransactionsForm';

type Props = {
  batchId: string
};

export const ImportDetails = ({ batchId }: Props) => {
  const r = useBatchTransactionImport(batchId);

  return (
    <Loadable {...r}>
      {batchImport => (
        <PageLayoutWithOverview className="pt5" overview={<Overview batchImport={batchImport} />}>
          <BatchTransactionsForm batchImport={batchImport} {...r} />
        </PageLayoutWithOverview>
      )}
    </Loadable>
  );
};
