import { useHistory, useLocation } from 'react-router-dom';

import { SearchParams } from '../../../LeftNavigation';
import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';
import { SubNavigation } from '../../utils/generateLeftNavigationRoutes';

export function useWithSubNav(
  subNavigations: SubNavigation[],
  isLeftNavigationCollapsed: boolean,
  onToggleCollapsable: () => void
) {
  const history = useHistory();
  const { pathname } = useLocation();
  const searchParams = useSearchParams<SearchParams>();

  const parentId = searchParams.get('parentId'); // this is used to map tab navigations to their parent
  const itsActiveLink = subNavigations.some(({ refId, path }) => refId === parentId || path === pathname);
  const lowerBoundPath = `${subNavigations[0].path}?parentId=${subNavigations[0].refId}`;
  const automaticallyCloseAccordion = !itsActiveLink || isLeftNavigationCollapsed;
  const automaticallyOpenAccordion = itsActiveLink && !isLeftNavigationCollapsed;

  function navigateToFirstSubNavLink() {
    if (itsActiveLink) return;

    history.push(lowerBoundPath);
  }

  function onClickAccordionHandler() {
    if (isLeftNavigationCollapsed) onToggleCollapsable();

    navigateToFirstSubNavLink();
  }

  function checkIsPathActive(path: string, refId: string) {
    const isPathActive = path === pathname || refId === parentId;

    return isPathActive;
  }

  return {
    automaticallyCloseAccordion,
    automaticallyOpenAccordion,
    itsActiveLink,
    checkIsPathActive,
    onClickAccordionHandler
  };
}
