import * as React from 'react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import cx from 'classnames';
import { mapIndexed } from '@kwara/lib/src/lodash';

import { If } from '@kwara/components/src/If/If';
import { LoadingSkeleton } from '@kwara/components/src/Loadable';

import styles from './index.module.scss';

type AttributeType = {
  ariaLabel?: string;
  isLoading?: boolean;
} & ({ title: React.ReactNode } | { value: React.ReactNode });

interface AttributesRowPropTypes {
  attributes: AttributeType[];
  useBackground?: boolean;
}
export const AttributesRow = ({ attributes, useBackground = true }: AttributesRowPropTypes) => {
  const isLessThanThree = attributes.length <= 2;
  return (
    <If
      condition={!isEmpty(attributes)}
      do={
        <div
          role="row"
          className={cx(
            { [styles['section__grid--template']]: isLessThanThree },
            styles['section__grid'],
            useBackground ? styles.background : null
          )}
        >
          {mapIndexed((attribute: AttributeType, index: number) => {
            const value = get('value', attribute);

            return (
              <div className={styles['grid__item']} key={index} aria-label={get('ariaLabel', attribute)}>
                <div className={'kw-text-medium neutral-500'}>{get('title', attribute)}</div>

                <div className={'neutral-900 kw-text-large kw-weight-bold'}>
                  <If condition={!!attribute.isLoading} do={<LoadingSkeleton />} else={value || '-'} />
                </div>
              </div>
            );
          }, attributes)}
        </div>
      }
    />
  );
};
