import * as React from 'react';
import reject from 'lodash/fp/reject';

import { LoanType, LoanApplicationFee } from '@kwara/models/src';
import { FeeApplicationT } from '@kwara/models/src/models/LoanApplication';

import { Subtitle } from '../../../../components';
import { RepaymentFieldsBase } from '../../../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields';
import { AddFeeForm, SelectedFees } from '../../../../../Loan/LoanAdd/components/LoanConfigure/Fees';
import { useLoanRepaymentFields } from '../../../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields/useLoanRepaymentFields';
import { useFeeValues } from '../../../../hooks/useFeeValues';

type Props = {
  formProps: {
    values: {
      loanDuration: number;
      repaymentPeriodUnit: 'DAYS' | 'WEEKS' | 'MONTHS';
      feeApplications: FeeApplicationT[];
    };
    form: {
      change: (key: string, val: any) => void;
    };
  };
  data: {
    loan: LoanType;
  };
};

export function EditableForm(props: Props) {
  const loan = props.data.loan;
  const formProps = props.formProps;
  const { values, form } = formProps;
  const { product, amount } = loan;
  const { loanDuration, repaymentPeriodUnit, feeApplications } = values;

  const args = { product, amount, feeApplications, loanDuration, repaymentPeriodUnit };
  const { fixedRepayment, repaymentAmountR } = useLoanRepaymentFields(args);

  const { fees, isFeeOptional, isFeeNotSelected, getFee, selectedFees, getFeeApplication } = useFeeValues({
    product,
    feeApplications,
    amount
  });
  const feesToShow = fees.filter(isFeeOptional).filter(isFeeNotSelected);

  async function onRemove(feeId: string) {
    const feeToRemove = getFeeApplication(feeId).dup();
    feeToRemove.isMarkedForDestruction = true;
    const otherFees = reject(feeApplication => feeApplication.feeId === feeId, feeApplications);
    return await form.change('feeApplications', [...otherFees, feeToRemove]);
  }

  return (
    <div>
      <Subtitle id="LoanAppraisal.LoanDetails.Fees.subtitle" />
      <SelectedFees className="mb4" fees={selectedFees} getFeeApplication={getFeeApplication} onRemoveFee={onRemove} />
      <AddFeeForm
        fees={feesToShow}
        getFee={getFee}
        onAddFee={(data: { feeId: string; amount?: string }) => {
          const fee = LoanApplicationFee.create(data);
          form.change('feeApplications', [...feeApplications, fee]);
        }}
      />
      <RepaymentFieldsBase
        {...props}
        fixedRepayment={fixedRepayment}
        repaymentAmountR={repaymentAmountR}
        formProps={formProps}
      />
    </div>
  );
}
