import React from 'react';

import { OperationalReport } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Panel } from '@kwara/components/src/Panel';
import Modal from '@kwara/components/src/Modal';
import { LoadingSkeleton } from '@kwara/components/src/Loadable/LoadingSkeleton';

import { ButtonMenu } from '../../../../components/ButtonMenu';
import { FilterButton } from '../../../../components/Filter/components/Header/FilterButton';
import { usePollReportDownload } from '../usePollReportDownload';
import { IconDownload } from '../../../../assets/IconDownload';
import Visible from '../../../../components/Visible';
import { usePermissions } from '../../../../hooks';

export function PollingModal({ isPolling }: { isPolling: boolean }) {
  return (
    <Modal isOpen={isPolling}>
      <div className="w-100 h-100 flex justify-center items-center">
        <Panel>
          <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
            <Text id="LoanList.export.modal.title" />
          </h2>
          <p className="kw-weight-light">
            <Text id="LoanList.export.modal.subtitle" />
          </p>
          <LoadingSkeleton />
        </Panel>
      </div>
    </Modal>
  );
}

export function ExportButton() {
  const { AppPermissions } = usePermissions();
  const { setCurrentReportId, isPolling } = usePollReportDownload();
  const [isCreating, setIsCreating] = React.useState(false);

  async function onExport(key: string) {
    setIsCreating(true);
    const report = OperationalReport.create({ reportTemplateKey: key, filters: {} });
    const didSave = await report.save();
    if (didSave) {
      setCurrentReportId(report.id);
    }
    setIsCreating(false);
  }

  return (
    <Visible to={AppPermissions.CreateLoanAgeingReports}>
      <ButtonMenu
        Button={props => (
          <FilterButton {...props}>
            <span>
              <IconDownload />
            </span>
            <span>
              <Text id="LoanList.export.button" />
            </span>
          </FilterButton>
        )}
      >
        <ButtonMenu.Item onClick={() => onExport('loan_ageing_report')}>
          <Text id="LoanList.export.button.loanAgeingReport" />
        </ButtonMenu.Item>
      </ButtonMenu>
      <PollingModal isPolling={isPolling || isCreating} />
    </Visible>
  );
}
